import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    search: {
        adiantamento: "0",
        bancoId: "0",
        produtoId: "0",
        lojaId: "0",
        status: "0",
    },
    item: {
        status: false,
        inicioVigencia: '',
        fimVigencia: '',
        bancoId: "0",
        produtos: [],
        lojas: []
    },
    modalFilial: {
        id: '',
        cnpj: '',
        nome: ''
    },
    getAllFilial: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    copy: {},
    invalid: {
        descricao: false,
        flagAdiantamento: false,
        produtos: false,
        lojas: false
    },
    items: [],
    activeTab: "Search",
    seeRegister: false,
    filiais: [],
    instituicoesPesquisa: [],
    instituicoes: [],
    produtos: [],
    lojas: [],
    lojasPesquisa: [],
    produtosPesquisa: [],
    pageSelected: 1,
    pageSize: 10,
    quantidade: 0,
    quantidadeTotal: 0,
    totalPaginas: 0,
    permitions: {
        criar: false,
        deletar: false
    }
}

export default function assocUsuarioGrupo(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.ANT_CONTAS_RECEBER:
                    return { ...state, items: action.value }
                case TYPES.ITEM:
                    return { ...state, item: action.value }
                case TYPES.ANT_CONTA_RECEBER:
                    return { ...state, item: { ...state.item, [action.field]: action.value } }
                case TYPES.ANT_CONTA_RECEBER_PRODUTO:
                    return { ...state, item: { ...state.item, produtos: action.value } }
                case TYPES.ANT_CONTA_RECEBER_LOJA:
                    return { ...state, item: { ...state.item, lojas: action.value } }
                case TYPES.INVALID_ANT_CONTA_RECEBER:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_ANT_CONTA_RECEBER:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.CLEAN_ALL_SEARCH:
                    return {
                        ...state,
                        search: INITIAL_STATE.search,
                        items: INITIAL_STATE.items,
                        copy: INITIAL_STATE.copy,
                        pageSelected: INITIAL_STATE.pageSelected,
                        pageSize: INITIAL_STATE.pageSize,
                        quantidade: INITIAL_STATE.quantidade,
                        quantidadeTotal: INITIAL_STATE.quantidadeTotal,
                        totalPaginas: INITIAL_STATE.totalPaginas
                    }
                case TYPES.ANT_CONTA_RECEBER_COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, item: INITIAL_STATE.item, invalid: INITIAL_STATE.invalid }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                case TYPES.SEARCH:
                    return {
                        ...state,
                        search: action.value
                    }
                case TYPES.SET_MODAL_FILIAL:
                    return { ...state, modalFilial: { ...state.modalFilial, [action.campo]: action.value } }
                case TYPES.SET_FILIAIS:
                    return { ...state, filiais: action.value }
                case TYPES.SET_GET_FILIAL:
                    return { ...state, getAllFilial: action.value }
                case TYPES.ADD_PRODUTO:
                    return {
                        ...state,
                        item: {
                            ...state.item,
                            produtos: action.value
                        }
                    }
                case TYPES.REMOVE_PRODUTO:
                    let produtos = [...state.item.produtos]
                    produtos.splice(action.index, 1)
                    return {
                        ...state,
                        item: { ...state.item, produtos: produtos }
                    }
                case TYPES.ADD_LOJA:
                    return {
                        ...state,
                        item: {
                            ...state.item,
                            lojas: action.value
                        }
                    }
                case TYPES.REMOVE_LOJA:
                    let lojas = [...state.item.lojas]
                    lojas.splice(action.index, 1)
                    return {
                        ...state,
                        item: { ...state.item, lojas: lojas }
                    }
                case TYPES.SET_INSTITUICOES_PESQUISA:
                    return {
                        ...state,
                        instituicoesPesquisa: action.value
                    }
                case TYPES.SET_INSTITUICOES:
                    return {
                        ...state,
                        instituicoes: action.value
                    }
                case TYPES.SET_PRODUTOS:
                    return {
                        ...state,
                        produtos: action.value
                    }
                case TYPES.CLEAN_PRODUTOS:
                    return {
                        ...state,
                        item: { ...state.item, produtos: [] },
                        produtos: INITIAL_STATE.produtos
                    }
                case TYPES.SET_LOJAS:
                    return {
                        ...state,
                        lojas: action.value
                    }
                case TYPES.CLEAN_LOJAS:
                    return {
                        ...state,
                        item: { ...state.item, lojas: [] },
                        lojas: INITIAL_STATE.lojas
                    }
                case TYPES.SET_LOJAS_PESQUISA:
                    return {
                        ...state,
                        lojasPesquisa: action.value
                    }
                case TYPES.SET_PRODUTOS_PESQUISA:
                    return {
                        ...state,
                        produtosPesquisa: action.value
                    }
                case TYPES.SET_PAGE_SIZE:
                    return {
                        ...state,
                        pageSize: action.value
                    }
                case TYPES.SET_QUANTIDADE_TOTAL:
                    return {
                        ...state,
                        quantidadeTotal: action.value
                    }
                case TYPES.SET_TOTAL_PAGINAS:
                    return {
                        ...state,
                        totalPaginas: action.value
                    }
                case TYPES.SET_PAGE_SELECTED:
                    return {
                        ...state,
                        pageSelected: action.value
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}