import React from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import { Input } from 'reactstrap';
import { useEffect } from 'react';

const useStyles = makeStyles({
    ul: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
    },
    button: {
        border: 'solid 1px var(--color-cinza-medio)',
        borderRadius: '10px',
        outline: 'none',
        cursor: 'pointer',
        margin: '0px 5px 0px 5px',
        display: 'inline-block',
        padding: '6px 12px'

    }
});
export default function Paginate({ pageCount, onPageChange, setPageSize, handleInitialPage, pageSize, setPageSelected, pageSelected }) {
    const classes = useStyles();
    const { items } = usePagination({
        count: pageCount ? pageCount : 0,
    });

    useEffect(() => {
        if (pageSelected === 1) {
            items.forEach(({ page, onClick }) => {
                if (page === 1) {
                    onClick();
                    setPageSelected(page);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSelected])

    return (
        <>
            <nav>
                <ul className={classes.ul}>
                    {items.map(({ page, type, selected, disabled, onClick }, index) => {
                        let children = null;
                        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                            children = '…';
                        } else if (type === 'page') {
                            children = (
                                <div
                                    className={classes.button}
                                    style={{ backgroundColor: selected ? 'var(--color-verde-escuro)' : undefined, color: selected ? 'white' : undefined }}
                                    onClick={() => {
                                        onClick();
                                        onPageChange(page);
                                        setPageSelected(page);
                                    }}>
                                    {page}
                                </div>
                            );
                        } else {
                            if (type === "next") {
                                children = (
                                    <div
                                        className={classes.button}
                                        style={{ backgroundColor: disabled ? 'var(--color-cinza-medio)' : undefined, color: disabled ? 'white' : undefined }}
                                        onClick={() => {
                                            if (!disabled) {
                                                onClick();
                                                onPageChange(page);
                                                setPageSelected(page);
                                            };
                                        }}>
                                        {`>`}
                                    </div>
                                );
                            }
                            if (type === "previous") {
                                children = (
                                    <div
                                        className={classes.button}
                                        style={{ backgroundColor: disabled ? 'var(--color-cinza-medio)' : undefined, color: disabled ? 'white' : undefined }}
                                        onClick={() => {
                                            if (!disabled) {
                                                onClick();
                                                onPageChange(page);
                                                setPageSelected(page);
                                            };
                                        }}>
                                        {`<`}
                                    </div>
                                );
                            }
                        }
                        return <li key={index}>{children}</li>;
                    })}
                </ul>
            </nav>
            <Input
                type="select"
                className="pageSize"
                value={pageSize}
                onChange={(e) => {
                    setPageSize(e.target.value);
                    handleInitialPage(e.target.value);
                    items.forEach(({ page, onClick }) => {
                        if (page === 1) {
                            onClick();
                            setPageSelected(page);
                        }
                    })
                }}>
                {
                    [10, 25, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))
                }
            </Input>
        </>
    );
}