import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    search: {
        descricao: '',
        bancoId: '0',
        produtoId: '0',
        canalVendaId: '0',
        dataInicio: '',
        dataFim: '',
    },
    bancosPesquisa: [],
    produtosPesquisa: [],
    conveniosPesquisa: [],
    canalVendasPesquisa: [],
    datasIniciaisPesquisa: [],
    produtoId: [],
    convenioId: [],
    bancoId: [],
    tipoOperacaoId: [],
    tipoParceriaId: [],
    canalVendasId: [],
    hierarquiaId: [],
    grupoId: [],
    items: [],
    item: {
        regraRepasseComissao: [],
        tipoParceriaId: '0',
        produtoId: '0',
        bancoId: '0',
        canalVendaId: '0',
        dataFinal: '',
        dataInicio: '',
        descricao: '',
        id: ''
    },
    copy: {},
    tiposComissao: [],
    invalid: {
        regraRepasseComissao: false,
        tipoParceriaId: false,
        produtoId: false,
        bancoId: false,
        canalVendaId: false,
        dataFim: false,
        dataInicio: false,
        descricao: false,
    },
    datasFinaisPesquisa: [],
    activeTab: "Search",
    getAllItems: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'

    },
    seeRegister: false,
    permitions: {
        criar: false,
        deletar: false,
        autorizar: false,
    }
}

export default function regrasRepasse(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state,
                        item: {
                            regraRepasseComissao: [],
                            tipoParceriaId: '0',
                            produtoId: '0',
                            bancoId: '0',
                            canalVendaId: '0',
                            dataFinal: '',
                            dataInicio: '',
                            descricao: '',
                            id: ''
                        },
                        invalid: INITIAL_STATE.invalid,
                    }
                case TYPES.SET_ACTIVE_TAB:
                    return {
                        ...state,
                        activeTab: action.value
                    }
                case TYPES.SET_SEE:
                    return {
                        ...state,
                        seeRegister: action.value
                    }
                case TYPES.SEARCH:
                    return {
                        ...state,
                        search: action.value
                    }
                case TYPES.SET_PRODUTOS_PESQUISA:
                    return {
                        ...state,
                        produtosPesquisa: action.value
                    }
                case TYPES.SET_BANCOS_PESQUISA:
                    return {
                        ...state,
                        bancosPesquisa: action.value
                    }
                case TYPES.SET_CONVENIOS_PESQUISA:
                    return {
                        ...state,
                        conveniosPesquisa: action.value
                    }
                case TYPES.SET_CANAL_VENDAS_PESQUISA:
                    return {
                        ...state,
                        canalVendasPesquisa: action.value
                    }
                case TYPES.CLEAN_SEARCH:
                    return {
                        ...state,
                        search: INITIAL_STATE.search
                    }
                case TYPES.CLEAN_ALL_SEARCH:
                    return {
                        ...state,
                        search: INITIAL_STATE.search,
                        items: INITIAL_STATE.items,
                        copy: INITIAL_STATE.copy,
                        getAllItems: INITIAL_STATE.getAllItems
                    }
                case TYPES.SET_PRODUTOS:
                    return {
                        ...state,
                        produtoId: action.value
                    }
                case TYPES.SET_CONVENIO:
                    return {
                        ...state,
                        convenioId: action.value
                    }
                case TYPES.SET_INSTITUICAO:
                    return {
                        ...state,
                        bancoId: action.value
                    }
                case TYPES.SET_TIPO_OPERACAO:
                    return {
                        ...state,
                        tipoOperacaoId: action.value
                    }
                case TYPES.SET_ALL_ITEMS:
                    return { ...state, getAllItems: action.value }
                case TYPES.SET_TIPO_PARCERIA:
                    return {
                        ...state,
                        tipoParceriaId: action.value
                    }
                case TYPES.SET_CANAL_VENDAS:
                    return {
                        ...state,
                        canalVendasId: action.value
                    }
                case TYPES.SET_NIVEL_HIERARQUICO:
                    return {
                        ...state,
                        hierarquiaId: action.value
                    }
                case TYPES.SET_GRUPO_PERSONAS:
                    return {
                        ...state,
                        grupoId: action.value
                    }
                case TYPES.SET_REGRA_REPASSE:
                    return {
                        ...state,
                        item: action.value
                    }
                case TYPES.SET_TIPOS_COMISSAO:
                    return {
                        ...state,
                        tiposComissao: action.value
                    }
                case TYPES.SET_TABELA_COMISSAO:
                    let arrAux = state.item
                    arrAux.regraRepasseComissao[action.index].regraRepasseComissaoPmt = action.value
                    return { ...state, item: arrAux }
                case TYPES.REGRA_REPASSE_COPY:
                    return {
                        ...state,
                        copy: action.value
                    }
                case TYPES.REGRAS_REPASSE:
                    return {
                        ...state,
                        items: action.value
                    }
                case TYPES.GET_ALL_ITEMS:
                    return {
                        ...state,
                        getAllItems: action.value
                    }
                case TYPES.INVALID_REGRA_REPASSE:
                    return {
                        ...state,
                        invalid: {
                            ...state.invalid,
                            [action.field]: true
                        }
                    }
                case TYPES.CLEAN_INVALID:
                    return {
                        ...state,
                        invalid: {
                            ...state.invalid,
                            [action.field]: false
                        }
                    }
                default:
                    return {
                        ...state
                    }
            }
        default:
            return {
                ...state
            }
    }
}