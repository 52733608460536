import React from 'react';
import Chart from 'react-google-charts';

export default function Grafico({data}){
    return(
        <div style={{boxShadow: '3px 3px 1em #0003', borderRadius: '3px'}}>
            <Chart
             options={{
                    colors: ['#7559FF', '#C7BEFD'],
                    pieHole: 0.65,
                    pieSliceText: 'none',
                    width: 635  
                    }
                }
                chartLanguage='pt'
                height='300px'
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={data}
            />
        </div>
    )
}