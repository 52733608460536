import React, {  useMemo, useState } from "react";
import { Col, Input, Row } from 'reactstrap'
import { notifySuccess } from '../../../components/message/message'
import * as  divisaoComissaoController from '../../../controllers/divisaoComissaoController'
import * as  divisaoComissaoActions from '../../../store/modules/divisaoComissao/actions'
import './style.css'

import * as BiIcons from "react-icons/bi";
import { useTable, useSortBy, usePagination } from "react-table";
import * as BsIcons from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { COLUMNS_LOJA } from "../constants/columns"

const TableLoja = ({ items }) => {
    const columns = useMemo(() => COLUMNS_LOJA, []);
    const data = items;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
        rows
    } = useTable({
        columns,
        data
    },
        useSortBy,
        usePagination);

    const {  pageSize } = state;
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
    }

    function handleInitialPage() {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }

    async function handleDelete(itemId, index){

        let hasSuccessed = itemId ? false : true
        if(itemId){
            hasSuccessed = await divisaoComissaoController.deleteItemDivisaoComissao(itemId)
        }
        if (hasSuccessed) {
            notifySuccess('Item removido com sucesso.')
            divisaoComissaoActions.removeLoja(index)
        }
        }

    const pageCount = Math.ceil(rows.length / pageSize);
    return (
        <>
         <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th>
                                                {column.render("Header")}
                                                <span className="float-right">
                                                    {column.isSorted ?
                                                        (column.isSortedDesc ?
                                                            <BsIcons.BsArrowDown
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />
                                                            :
                                                            <BsIcons.BsArrowUp
                                                                id="OrdenarAsc"
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />)
                                                        :
                                                        <BsIcons.BsArrowDownUp
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />}
                                                </span>
                                            </th>
                                        ))}
                                        <th style={{ textAlign: "center" }}>
                                            Ações
                                 </th>
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row, index) => {
                                    prepareRow(row)
                                    return (
                                        <tr className="text-center" {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                            })}
                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                <>
                                                    <BiIcons.BiTrash
                                                        style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA" }}
                                                        onClick={() => handleDelete(row.original.id, index)}
                                                    />
                                                </>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Row className="pt-4">
                            <Col>
                            <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {page.length} de {items.length}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { setPageSize(Number(e.target.value)); handleInitialPage() }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>
          </>
    )
}

export default TableLoja
