import { STORE_ID, TYPES } from './types'

const INITIAL_STATE = {
    search: [],
    itens: [],
    activeTab: 'Search',
    permitions: {
        criar: false,
    }
}

export default function agrupamentoPropostasCentroCusto(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.SET_SEARCH:
                    return { ...state, search: action.value }
                case TYPES.SET_ITENS:
                    return { ...state, itens: action.value }             
                case TYPES.CLEAN_SEARCH:
                    return { ...state, search: [] }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, itens: [] }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}