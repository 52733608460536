const STORE_ID = 'TABELA_COMISSAO';

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    PESQUISAR: 'PESQUISAR',
    SET_ALL_ITEMS: 'SET_ALL_ITEMS',
    REGISTROS: 'REGISTROS',
    UPDATE: 'UPDATE',
    COPIA: 'COPIA',
    INSTITUICOES: 'INSTITUICOES',
    PRODUTOS: 'PRODUTOS',
    PRODUTOS_RIBER: 'PRODUTOS_RIBER',
    CONVENIOS: 'CONVENIOS',
    COMISSOES: 'COMISSOES',
    TIPOS_OPERACAO: 'TIPOS_OPERACAO',
    TIPOS_COMISSAO: 'TIPOS_COMISSAO',
    TIPOS_COMISSAO_BANCO: 'TIPOS_COMISSAO_BANCO',
    CLEAN_TIPOS_COMISSAO_BANCO: 'CLEAN_TIPOS_COMISSAO_BANCO',
    CLEAN_SEARCH: 'CLEAN_SEARCH',
    CLEAN_REGISTER: 'CLEAN_REGISTER',
    CLEAN_EVENTOS: 'CLEAN_EVENTOS',
    SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
    SET_SEE: 'SET_SEE',
    OPERACOES_CADASTRO: 'OPERACOES_CADASTRO',
    CONVENIOS_CADASTRO: 'CONVENIOS_CADASTRO',
    PRODUTOS_CADASTRO: 'PRODUTOS_CADASTRO',
    INVALID: 'INVALID',
    CLEAN_INVALID: 'CLEAN_INVALID',
    EVENTOS: 'EVENTOS',
    SET_PRODUTOS_INSTITUICAO: 'SET_PRODUTOS_INSTITUICAO',
    SET_CONVENIOS_INSTITUICAO: 'SET_CONVENIOS_INSTITUICAO',
    SET_TIPO_INSTITUICAO: 'SET_TIPO_INSTITUICAO',
}

export { STORE_ID, TYPES }