import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    item: {
        descricao: "",
        pessoas: [],
        id: "",
        idPessoa: '',
        cpfCnpj: "",
        nome: "",
        flagJuridica: false
    },
    modal: {
        nome: '',
        cpfCnpj: ''
    },
    getAll: {
        quantidade: '', 
        quantidadeTotal: '', 
        totalPaginas: ''
    },
    copy: {},
    invalid: {
        descricao: false,
        nome: false,
        cpfCnpj: false,
    },
    items: [],
    pessoasPesquisa: [],
    pessoas: [],
    activeTab: "Search",
    seeRegister: false,
    loadingModal: false,
    permitions: {
        criar: false,
        deletar: false
    }
}

export default function grupo(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.GRUPOS:
                    return { ...state, items: action.value }
                case TYPES.SET_GET_ALL:
                    return { ...state, getAll: action.value }
                case TYPES.GRUPO:
                    return { ...state, item: action.value }
                case TYPES.PESSOA:
                    return { ...state, item: { ...state.item, [action.campo]: action.value } }
                case TYPES.INVALID_GRUPO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_GRUPO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.GRUPO_COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state, item: {
                            descricao: "",
                            pessoas: [],
                            id: "",
                            idPessoa: '',
                            cpfCnpj: "",
                            nome: "",
                            flagJuridica: false
                        }
                    }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                case TYPES.SET_PESSOA:
                    return { ...state, item: { ...state.item, pessoas: action.value } }
                case TYPES.SET_PESSOAS_MODAL:
                    return { ...state, pessoasPesquisa: action.value }
                case TYPES.SET_MODAL:
                    return { ...state, modal: { ...state.modal, [action.campo]: action.value } }
                case TYPES.SET_TIPO_PESSOA:
                    return { ...state, item: { ...state.item, flagJuridica: action.value } }
                case TYPES.SET_LOADING_MODAL:
                    return { ...state, loadingModal: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}