import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import * as BiIcons from "react-icons/bi"
import * as BsIcons from "react-icons/bs"
import * as AiIcons from "react-icons/ai"
import ReactPaginate from "react-paginate"
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table"
import { Button, Card, Col, Input, Label, Modal, ModalHeader, ModalBody, Row, Form, FormGroup } from "reactstrap"
import { COLUMNS } from "../constants/columns"
import * as actions from '../../../store/modules/contasReceber/actions'
import * as  controller from "../../../controllers/contasReceberController"
import { maskCNPJ } from "../../../util/masks"
import { removeAccentsAndUppercase } from "../../../util/masks";

import ModalFornecedor from '../components/modalFornecedor'
const Search = () => {
    const { search, items, allItems, permitions } = useSelector(state => state.contasReceber)
    const columns = useMemo(() => COLUMNS, [])
    const data = items;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const { pageSize } = state;
    const [itemSelected, setItem] = useState(null);
    const [modalDelete, setModalDelete] = useState(false);
    const [rows, setRows] = useState(0);
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    const [modalFornecedor, setModalFornecedor] = useState(false)
    const toggleFornecedor = () => setModalFornecedor(!modalFornecedor)

    useEffect(() => {
        setRows(page.length);
    }, [page])

    useEffect(() => {
        if(allItems.tamanhoPagina){
            setPageSize(allItems.tamanhoPagina);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allItems])

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);

        controller.get(search, pageSize, pageSelected+1)
    }


    async function handleUpdate(id) {
        await controller.getById(id);
        actions.setActiveTab("Register");
    }

    function seeRegister(id) {
        actions.setSeeRegister(true);
        handleUpdate(id);

    }
    function handleDelete(id) {
        setItem(id);
        toggleModalDelete();
    }

    function toggleModalDelete() {
        setModalDelete(!modalDelete);
    }

    function deleteComissao() {
        controller.remove(itemSelected);
    }

    function handleInitialPage(pageSize) {
        const pageSelected = 0;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        controller.get(search, pageSize, pageSelected+1)
    }

    function onChange(campo, value) {
        actions.search({...search, [campo]: value});
    }
    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalDelete}
                toggle={toggleModalDelete}
                backdrop={false}
            >
                <ModalHeader toggle={toggleModalDelete} style={{ height: '10px', border: 'none' }} />
                <ModalBody>
                    <p style={{ fontSize: '18px', color: 'var(--color-preto)' }}>Tem certeza que deseja <strong style={{ fontSize: '17px' }}>remover</strong> esse item?</p>
                </ModalBody>
                <div className="m-3">
                    <Button onClick={() => toggleModalDelete()} color="danger" style={{ width: "100px", marginRight: "15px", }}> Cancelar </Button>
                    <Button onClick={() => { deleteComissao(); toggleModalDelete() }} style={{ width: "100px", marginLeft: "15px", background: 'var(--color-verde)', border: 'none' }}> Confirmar </Button>
                </div>
            </Modal>

            <Col>
                <Card className="pt-3 pb-2 mb-5">
                    <Col>
                        <div style={{ width: "25%" }} className="pb-3">
                            <Label style={{ fontWeight: '400', fontSize: '18px', margin: '5px 0 8px 15px' }}>Pesquisar</Label>
                        </div>
                        <Form style={{ margin: '0px 0px 15px 15px' }}>
                            <Row>
                                <Col md={2}>
                                    <FormGroup>
                                        <Label>CNPJ</Label>
                                        <Input
                                            placeholder="Digite aqui..."
                                            value={(search.cnpjPesquisa)}
                                            onChange={e => onChange('cnpjPesquisa', maskCNPJ(e.target.value))}
                                            maxLength="18"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Título</Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={search.titulo}
                                            onChange={e => onChange('titulo', removeAccentsAndUppercase(e.target.value))}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup >
                                        <Label>Empresa</Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={search.empresaPesquisa}
                                            onChange={e => onChange('empresaPesquisa', removeAccentsAndUppercase(e.target.value))}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup >
                                        <Label>Fornecedor</Label>
                                        <Input
                                            type="text"
                                            placeholder="Busque um fornecedor..."
                                            value={search.fornecedor}
                                            onChange={e => onChange('fornecedor', e.target.value)}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <AiIcons.AiOutlineSearch
                                    style={{ color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer' }}
                                    onClick={() => toggleFornecedor()}
                                />
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup >
                                        <Label>Data de Entrada</Label>
                                        <Input
                                            type="date"
                                            value={search.dataEntrada}
                                            max="9999-12-31"
                                            onChange={e => onChange('dataEntrada', e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Data de emissão</Label>
                                        <Input
                                            type="date"
                                            value={search.dataEmissao}
                                            max="9999-12-31"
                                            onChange={e => onChange('dataEmissao', e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Data de vencimento</Label>
                                        <Input
                                            type="date"
                                            value={search.dataVencimento}
                                            max="9999-12-31"
                                            onChange={e => onChange('dataVencimento', e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Button className="mr-4"
                                        style={{
                                            width: "150px",
                                            background: "var(--color-branco)",
                                            borderColor: "var(--color-laranja)",
                                            color: "var(--color-laranja)",
                                            borderRadius: "7px"
                                        }}
                                        onClick={actions.cleanSearch}>
                                        Limpar
                                        </Button>
                                    <Button
                                        className="purpleButton"
                                        style={{ width: "150px" }}
                                        onClick={() => {controller.get(search, pageSize, 1);
                                            gotoPage(0);
                                            setInitialPage(true);
                                            setSelectedPage(0);}}>
                                        Buscar
                                            </Button>
                                </Col>
                            </Row>
                        </Form>
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th>
                                                {column.render("Header")}
                                                <span className="float-right">
                                                    {column.isSorted ?
                                                        (column.isSortedDesc ?
                                                            <BsIcons.BsArrowDown
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />
                                                            :
                                                            <BsIcons.BsArrowUp
                                                                id="OrdenarAsc"
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />)
                                                        :
                                                        <BsIcons.BsArrowDownUp
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />}
                                                </span>
                                            </th>
                                        ))}
                                        <th style={{ textAlign: "center" }}>
                                            Ações
                                 </th>
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr className="text-center" {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                            })}
                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                <>
                                                    {
                                                        permitions.criar && (
                                                            <BiIcons.BiEdit
                                                                style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}
                                                                onClick={() => handleUpdate(row.original.id)}
                                                            />
                                                        )
                                                    }
                                                    <AiIcons.AiOutlineEye
                                                        style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}
                                                        onClick={() => seeRegister(row.original.id)}
                                                    />
                                                    {
                                                        permitions.deletar && (
                                                            <BiIcons.BiTrash
                                                                style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA" }}
                                                                onClick={() => handleDelete(row.original.id)}
                                                            />
                                                        )
                                                    }
                                                </>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Row className="pt-4">
                            <Col>
                                <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {rows} de {items.length}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={allItems.totalPaginas}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { handleInitialPage(e.target.value); setPageSize(Number(e.target.value)) }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Card >
            </Col >
            {
                <ModalFornecedor
                    modalPessoa={modalFornecedor}
                    toggle={toggleFornecedor}
                    onChange={e => actions.search(e)}
                    dados={search}
                />
            }
        </>
    );
}


export default Search;