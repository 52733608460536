import moment from 'moment';
export const COLUMNS = [
    {
        Header: "Título",
        accessor: "titulo",
    },
    {
        Header: "Empresa",
        accessor: "empresa",
    },
    {
        Header: "Fornecedor",
        accessor: "fornecedor",
    },
    {
        Header: "CNPJ",
        accessor: "cnpj",
    },
    {
        Header: "Data de Entrada",
        accessor: "dataEntrada",
        Cell: ({ value }) => {
            return moment(value).format("DD/MM/YYYY");
        },
        sortType: "datetime",
    }
]

