import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import ReactPaginate from "react-paginate";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Button, Col, Input, Label, Row, Form, FormGroup } from "reactstrap";
import { InputCpfCnpj, Select } from "../../../../../components/Inputs/Input";
import { COLUMNS } from "../../../constants/columns";
import * as actions from "../../../../../store/modules/propostas/actions";
import * as propostasController from "../../../../../controllers/propostasController";
import { maskNumber } from "../../../../../util/masks";
import { removeAccentsAndUppercase } from "../../../../../util/masks";
import {
  notifyError,
  notifyWarn,
} from "../../../../../components/message/message";

const SearchP = () => {
  const {
    pesquisar,
    propostas,
    getAll,
    instituicoes,
    produtos,
    convenios,
    status,
    tipoOperacao,
  } = useSelector((state) => state.propostas);
  const columns = useMemo(() => COLUMNS, []);
  const data = propostas;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageSize } = state;
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  useEffect(() => {
    propostasController.getConvenios();
    propostasController.getInstituicoes();
    propostasController.getProdutos();
    propostasController.getStatus();
    propostasController.getTipoOperacao();
  }, []);

  useEffect(() => {
    if (getAll.tamanhoPagina) {
      setPageSize(getAll.tamanhoPagina);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAll]);

  const handlePageClick = (e) => {
    const pageSelected = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);

    propostasController.get(pesquisar, pageSize, pageSelected + 1);
  };

  async function handleUpdate(id) {
    await propostasController.getById(id);
    actions.setActiveTab("Register");
  }

  function seeRegister(id) {
    actions.setSeeRegister(true);
    handleUpdate(id);
  }

  function handleInitialPage(e) {
    const pageSelected = 0;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
    propostasController.get(pesquisar, e, pageSelected + 1);
  }

  function onChange(value, field) {
    actions.pesquisar({ ...pesquisar, [field]: value });
  }

  return (
    <Col style={{ overflowX: "auto" }}>
      <div style={{ width: "25%" }} className="pb-3">
        <Label
          style={{
            fontWeight: "400",
            fontSize: "18px",
            margin: "5px 0 8px 15px",
          }}
        >
          Pesquisar
        </Label>
      </div>
      <Form style={{ margin: "0px 0px 15px 15px" }}>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label>Número da proposta</Label>
              <Input
                type="text"
                placeholder="Digite aqui..."
                value={maskNumber(pesquisar.numeroProposta)}
                onChange={(e) => onChange(e.target.value, "numeroProposta")}
                style={{ height: "35px" }}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Instituição Financeira</Label>
              <Select
                first="SELECIONE"
                value={{ value: pesquisar.codInstituicao }}
                select={{ value: "id", label: "nome" }}
                options={instituicoes}
                onChange={(e) => onChange(e.value, "codInstituicao")}
                isSearchable={true}
                autoComplete="off"
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: "35px",
                    height: "35px",
                  }),
                }}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Produto</Label>
              <Select
                first="SELECIONE"
                value={{ value: pesquisar.codProduto }}
                select={{ value: "id", label: "nome" }}
                options={produtos}
                onChange={(e) => onChange(e.value, "codProduto")}
                isSearchable={true}
                autoComplete="off"
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: "35px",
                    height: "35px",
                  }),
                }}
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label>Convênio</Label>
              <Select
                first="SELECIONE"
                value={{ value: pesquisar.codConvenio }}
                select={{ value: "id", label: "descricao" }}
                options={convenios}
                onChange={(e) => onChange(e.value, "codConvenio")}
                isSearchable={true}
                autoComplete="off"
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: "35px",
                    height: "35px",
                  }),
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label>Nome do cliente</Label>
              <Input
                type="text"
                placeholder="Digite aqui..."
                value={pesquisar.nomeCliente}
                onChange={(e) =>
                  onChange(
                    removeAccentsAndUppercase(e.target.value),
                    "nomeCliente"
                  )
                }
                style={{ height: "35px" }}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup className="papel">
              <Label>CPF</Label>
              <InputCpfCnpj
                id="CPF"
                tipo="cpf"
                value={pesquisar.cpf}
                placeholder="Digite aqui..."
                onChange={(e) => onChange(e.target.value, "cpf")}
                style={{ height: "35px" }}
                maxLength="14"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Tipo de operação</Label>
              <Select
                first="SELECIONE"
                value={{ value: pesquisar.tipoOperacaoId }}
                select={{ value: "id", label: "descricao" }}
                options={tipoOperacao}
                onChange={(e) => onChange(e.value, "tipoOperacaoId")}
                isSearchable={true}
                autoComplete="off"
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: "35px",
                    height: "35px",
                  }),
                }}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Status da proposta</Label>
              <Select
                first="SELECIONE"
                value={{ value: pesquisar.statusPropostaId }}
                select={{ value: "id", label: "descricao" }}
                options={status}
                onChange={(e) => onChange(e.value, "statusPropostaId")}
                isSearchable={true}
                autoComplete="off"
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: "35px",
                    height: "35px",
                  }),
                }}
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label style={{ fontWeight: "600" }}>Data Entrada</Label>
              <Input
                type="radio"
                name="radio1"
                style={{ marginLeft: "20px" }}
                checked={pesquisar.tipoData === "0"}
                onClick={() => onChange("0", "tipoData")}
              />
            </FormGroup>
            <FormGroup>
              <Label style={{ fontWeight: "600" }}>Data Status</Label>
              <Input
                type="radio"
                style={{ marginLeft: "28px" }}
                name="radio1"
                checked={pesquisar.tipoData === "1"}
                onClick={() => onChange("1", "tipoData")}
              />
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label style={{ fontWeight: "300" }}>Data Início:</Label>
              <Input
                style={{ maxWidth: "500px" }}
                type="date"
                value={pesquisar.dataEntrada}
                max="9999-12-31"
                onChange={(e) => onChange(e.target.value, "dataEntrada")}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label style={{ fontWeight: "300" }}>Data Fim:</Label>
              <Input
                style={{ maxWidth: "500px" }}
                type="date"
                value={pesquisar.dataStatus}
                max="9999-12-31"
                onChange={(e) => onChange(e.target.value, "dataStatus")}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row style={{ paddingTop: "30px" }}>
          <Col>
            <Button
              className="mr-4"
              style={{
                width: "150px",
                background: "var(--color-branco)",
                borderColor: "var(--color-laranja)",
                color: "var(--color-laranja)",
                borderRadius: "7px",
                marginTop: "30",
              }}
              onClick={actions.cleanSearch}
            >
              Limpar
            </Button>
            <Button
              className="purpleButton"
              style={{ width: "150px" }}
              onClick={() => {
                const { dataEntrada, dataStatus } = pesquisar;

                if (
                  (dataEntrada !== "" && dataStatus !== "") ||
                  (dataEntrada === "" && dataStatus === "")
                ) {
                  propostasController.get(pesquisar, pageSize, 1);
                  gotoPage(0);
                  setInitialPage(true);
                  setSelectedPage(0);
                } else {
                  notifyError(
                    "Preencha ambas as datas ou deixe ambas vazias para realizar a pesquisa!"
                  );
                }
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          width: "99%",
          overflow: `auto`,
          maxHeight: "700px",
          // minWidth: "1400px",
        }}
      >
        <table className="custom-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th style={{ minWidth: "160px" }}>
                    {column.render("Header")}
                    <span className="float-right">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <BsIcons.BsArrowDown
                            {...column.getHeaderProps(
                              column.getSortByToggleProps({ title: undefined })
                            )}
                          />
                        ) : (
                          <BsIcons.BsArrowUp
                            id="OrdenarAsc"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps({ title: undefined })
                            )}
                          />
                        )
                      ) : (
                        <BsIcons.BsArrowDownUp
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({ title: undefined })
                          )}
                        />
                      )}
                    </span>
                  </th>
                ))}
                <th style={{ minWidth: "150px" }}>Ações</th>
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            style={{ textAlign: "left", padding: "20px" }}
          >
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr className="text-center" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{ minWidth: "150px", textAlign: "left" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  <td style={{ minWidth: "150px" }}>
                    <>
                      <AiIcons.AiOutlineEye
                        style={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          color: "#C4C7CA",
                          marginRight: "7px",
                        }}
                        onClick={() => seeRegister(row.original.id)}
                      />
                    </>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Row className="pt-4">
        <Col>
          <span style={{ color: "var(--color-cinza-medio)" }}>
            Mostrando{" "}
            {getAll.quantidade !== undefined ? getAll.quantidade : "0"} de{" "}
            {getAll.quantidadeTotal !== undefined
              ? getAll.quantidadeTotal
              : "0"}
          </span>
        </Col>
        <Col md={8}>
          <Row className="float-right">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              initialPage={0}
              pageCount={getAll.totalPaginas}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(e) => handlePageClick(e)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={initialPage ? 0 : selectedPage}
            />
            <Input
              type="select"
              className="pageSize"
              value={pageSize}
              onChange={(e) => {
                handleInitialPage(e.target.value);
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 25, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Input>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default SearchP;
