import { STORE_ID, TYPES } from "./types";
import store from "../../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setIdRegra(value) {
    dispatch({
        type: TYPES.SET_ID_REGRA,
        value
    })
}

export function setRegrasElegiveis(value) {
    dispatch({
        type: TYPES.SET_REGRAS_ELEGIVEIS,
        value
    })
}

export function setParametrosMedida(value) {
    dispatch({
        type: TYPES.SET_PARAMETROS_MEDIDA,
        value
    })
}

export function setMedidasPremiacao(value) {
    dispatch({
        type: TYPES.SET_MEDIDAS_PREMIACAO,
        value
    })
}

export function item(value) {
    dispatch({
        type: TYPES.ITEM,
        value
    });
}

export function copy(value) {
    dispatch({
        type: TYPES.COPY,
        value
    });
}


export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_ITEM,
        field
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_ITEM,
        field
    });
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function cleanAllRegister() {
    dispatch({
        type: TYPES.CLEAN_ALL_REGISTER
    })
}

export function setRegrasPremiacao(value) {
    dispatch({
        type: TYPES.REGRAS_PREMIACAO,
        value
    });
}

export function cleanMetaProdutividade() {
    dispatch({
        type: TYPES.CLEAN_META_PRODUTIVIDADE
    });
}

export function setCanais(value) {
    dispatch({
        type: TYPES.CANAIS,
        value
    });
}

export function setMetaProdutividade(value) {
    dispatch({
        type: TYPES.SET_META_PRODUTIVIDADE,
        value
    });
}

export function metaProdutividade(value) {
    dispatch({
        type: TYPES.META_PRODUTIVIDADE,
        value
    });
}

export function setTiposOperacao(value) {
    dispatch({
        type: TYPES.TIPOS_OPERACAO,
        value
    });
}

export function setTiposParceria(value) {
    dispatch({
        type: TYPES.TIPOS_PARCERIA,
        value
    });
}

export function setConvenios(value) {
    dispatch({
        type: TYPES.CONVENIOS,
        value
    });
}

export function setBancos(value) {
    dispatch({
        type: TYPES.BANCOS,
        value
    });
}

export function setProdutos(value) {
    dispatch({
        type: TYPES.PRODUTOS,
        value
    });
}

export function setGrupos(value) {
    dispatch({
        type: TYPES.GRUPOS,
        value
    });
}

export function setHierarquiasProprio(value) {
    dispatch({
        type: TYPES.HIERARQUIAS_PROPRIO,
        value
    });
}

export function setHierarquiasTerceiro(value) {
    dispatch({
        type: TYPES.HIERARQUIAS_TERCEIRO,
        value
    });
}

export function setAllHierarquias(value) {
    dispatch({
        type: TYPES.ALL_HIERARQUIAS,
        value
    });
}

export function setRegrasVinculadas(value) {
    dispatch({
        type: TYPES.SET_REGRAS_VINCULADAS,
        value
    })
}

export function setAllRegras(value) {
    dispatch({
        type: TYPES.SET_ALL_REGRAS,
        value
    })
}