import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    filtros:{
        instituicaoId: '0',
        lojaId: '0',
        loja: '',
        grupoId: '0',
        consultorId: '0',
        tipoOperacaoId: '0',
        parceriaId: '0',
        convenioId: '0',
        periodo: '3',
        periodoTabela: '3',
        filtrosAvancados: false
    },
    modalLojas:{
        id: '',
        nome: '',
        cnpj: '',
    },
    getAllLojas: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    modalConsultores: {
        id: '',
        nome: '',
        cpf: '',
    },
    getAllConsultores: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    bigNumbers: [],
    tabelaProducao: [],
    tabelaProd: [],
    graficoProduto: [],
    graficoProjetado: [],
    filtrosSelecionados: [],
    instituicoes: [],
    lojas: [],
    tiposOperacao: [],
    parcerias: [],
    convenios: [],
    grupos: [],
    consultores: [],
    loadingLojas: false,
    loadingConsultores: false,
}

export default function dashboardReceita(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_FILTROS:
                    return { ...state, filtros:{...state.filtros, [action.campo] : action.value} }
                case TYPES.SET_MODAL_LOJAS:
                    return { ...state, modalLojas:{...state.modalLojas, [action.campo] : action.value} }
                case TYPES.SET_MODAL_CONSULTORES:
                    return { ...state, modalConsultores: { ...state.modalConsultores, [action.campo]: action.value } }
                case TYPES.SET_FILTROS_SELECIONADOS:
                    return { ...state, filtrosSelecionados: action.value }
                case TYPES.SET_BIG_NUMBERS:
                    return { ...state, bigNumbers: action.value }
                case TYPES.SET_TABELA_PRODUCAO:
                    return { ...state, tabelaProducao: action.value }
                case TYPES.SET_TABELA_PROD:
                    return { ...state, tabelaProd: action.value }
                case TYPES.SET_GRAFICO_PRODUTO:
                    return { ...state, graficoProduto: action.value }
                case TYPES.SET_GRAFICO_PROJETADO:
                    return { ...state, graficoProjetado: action.value }
                case TYPES.SET_INSTITUICOES:
                    return { ...state, instituicoes: action.value }
                case TYPES.SET_LOJAS:
                    return { ...state, lojas: action.value }
                case TYPES.SET_GET_ALL_LOJAS:
                    return { ...state, getAllLojas: action.value }
                case TYPES.SET_GET_ALL_CONSULTORES:
                    return { ...state, getAllConsultores: action.value }
                case TYPES.SET_TIPOS_OPERACAO:
                    return { ...state, tiposOperacao: action.value }
                case TYPES.SET_PARCERIAS:
                    return { ...state, parcerias: action.value }
                case TYPES.SET_CONVENIOS:
                    return { ...state, convenios: action.value }
                case TYPES.SET_GRUPOS:
                    return { ...state, grupos: action.value }
                case TYPES.SET_CONSULTORES:
                    return { ...state, consultores: action.value }
                case TYPES.CLEAN_FILTROS:
                    return { ...state, filtros: INITIAL_STATE.filtros}
                case TYPES.SET_LOADING_LOJAS:
                    return { ...state, loadingLojas: action.value }
                case TYPES.SET_LOADING_CONSULTORES:
                    return { ...state, loadingConsultores: action.value }
                
                    default:
                        return { ...state }
            }
        default:
            return { ...state }
    }
}