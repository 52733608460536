import { STORE_ID, TYPES } from "./types";
import store from "../../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}
export function cleanTelefone() {
    dispatch({
        type: TYPES.CLEAN_TELEFONE
    })
}

export function cleanEmail() {
    dispatch({
        type: TYPES.CLEAN_EMAIL
    })
}

export function setTelefones(value) {
    dispatch({
        type: TYPES.TELEFONES,
        value
    })
}

export function setEmails(value) {
    dispatch({
        type: TYPES.EMAILS,
        value
    })
}

export function getItemsTiposTelefones(value) {
    dispatch({
        type: TYPES.GET_TIPOS_TELEFONE,
        value
    })
}

export function getItemsTiposEmails(value) {
    dispatch({
        type: TYPES.GET_TIPOS_EMAIL,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}
export function setEmail(value) {
    dispatch({
        type: TYPES.SET_EMAIL,
        value
    })
}
export function setTelefone(field, value) {
    dispatch({
        type: TYPES.SET_TELEFONE,
        field,
        value
    })
}