import React, { useEffect } from "react";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Select } from "../../../components/Inputs/Input";
import { useSelector } from "react-redux";
import * as inconsistenciaActions from "../../../store/modules/inconsistencias/actions";
import * as inconsistenciaController from "../../../controllers/inconsistenciasController";
import { removeAccentsAndUppercase } from "../../../util/masks";

const Register = () => {
  const { item, bancos, invalid, copy, seeRegister, responsaveis } =
    useSelector((state) => state.inconsistencias);

  useEffect(() => {
    inconsistenciaController.getBancos();
    inconsistenciaController.getResponsaveisPorAcao();
  }, []);

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      if (item.id === "") {
        inconsistenciaController.create(item);
      } else {
        inconsistenciaController.update(item, copy);
      }
    }
  }

  function onChangeItem(value, field) {
    inconsistenciaActions.cleanInvalid(field);
    inconsistenciaActions.item({ ...item, [field]: value });
  }

  const sortedBancos = bancos.sort((a, b) => a.nome.localeCompare(b.nome));
  const sortedResponsaveis = responsaveis.sort((a, b) =>
    a.descricao.localeCompare(b.descricao)
  );

  return (
    <>
      <Col>
        <Card className="radius pb-5 pl-2 pr-2 pt-4">
          <Col className="pt-2">
            <p style={{ fontSize: "18px", color: "var(--color-preto)" }}>
              Preencha os campos para cadastrar uma nova Consistência
            </p>
          </Col>
          <Col className="pt-2" md="6">
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Descrição da Consistência</Label>
                    <Input
                      type="text"
                      id="inconsistencia"
                      placeholder="Digite aqui..."
                      value={item.descricao}
                      onChange={(e) =>
                        onChangeItem(
                          removeAccentsAndUppercase(e.target.value),
                          "descricao"
                        )
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                      className={invalid.descricao ? "is-invalid" : ""}
                      disabled={seeRegister}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Código da Consistência</Label>
                    <Input
                      type="text"
                      id="inconsistencia"
                      placeholder="Digite aqui..."
                      value={item.codigoConsistencia}
                      onChange={(e) =>
                        onChangeItem(
                          removeAccentsAndUppercase(e.target.value),
                          "codigoConsistencia"
                        )
                      }
                      onKeyPress={(e) => handleKeyPress(e)}
                      className={invalid.codigoConsistencia ? "is-invalid" : ""}
                      disabled={item.id !== "" ? true : seeRegister}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Instituição Financeira</Label>
                    <Select
                      first="SELECIONE"
                      value={{ value: item.bancoId }}
                      select={{ value: "id", label: "nome" }}
                      options={sortedBancos}
                      onChange={(e) => onChangeItem(e.value, "bancoId")}
                      disabled={seeRegister}
                      invalid={invalid.bancoId}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Responsável por ação</Label>
                    <Select
                      first="SELECIONE"
                      value={{
                        value: item.responsavelPorAcao
                          ? item.responsavelPorAcao
                          : "0",
                      }}
                      select={{ value: "id", label: "descricao" }}
                      options={sortedResponsaveis}
                      onChange={(e) =>
                        onChangeItem(e.value, "responsavelPorAcao")
                      }
                      disabled={seeRegister}
                      invalid={invalid.responsavelPorAcao}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Card>
      </Col>
    </>
  );
};

export default Register;
