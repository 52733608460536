import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}
export function setTab(value) {
    dispatch({
        type: TYPES.SET_TAB,
        value
    })
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}


export function setIsFirstPage(value) {
    dispatch({
        type: TYPES.SET_IS_FIRST_PAGE,
        value
    })
}


export function getAllItems(value) {
    dispatch({
        type: TYPES.GET_ALL_ITEMS,
        value
    });
}

export function setTabs(value) {
    dispatch({
        type: TYPES.SET_TABS,
        value
    })
}

export function setLojaBanco(value) {
    dispatch({
        type: TYPES.SET_LOJAS_BANCO,
        value
    })
}

export function setProposta(value) {
    dispatch({
        type: TYPES.SET_PROPOSTAS,
        value
    })
}

export function setTiposOperacao(value) {
    dispatch({
        type: TYPES.SET_TIPOS_OPERACAO,
        value
    })
}

export function setTiposcomissao(value) {
    dispatch({
        type: TYPES.SET_TIPOS_COMISSAO,
        value
    })
}

export function setProdutoBanco(value) {
    dispatch({
        type: TYPES.SET_PRODUTO_BANCO,
        value
    })
}

export function cleanTabs() {
    dispatch({
        type: TYPES.CLEAN_TABS
    })
}