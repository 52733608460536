import api from '../util/api'
import * as  divisaoComissaoActions from '../store/modules/divisaoComissao/actions'
import { notifySuccess, notifyWarn } from '../components/message/message'
import { maskDate, maskMoeda, removeMaskMoeda } from '../util/masks'
import { setLoading } from '../store/modules/globalLoading/actions'

export async function get(search, pageSize, page) {

    var url = `divisaoComissao?pagina=${page}&registros=${pageSize}`

    if (search.banco !== '0') url = url + `&bancoId=${search.banco}`
    if (search.lojaId !== '0') url = url + `&lojaId=${search.lojaId}`
    if (search.lojaMatrizId !== '0') url = url + `&lojaMatrizId=${search.lojaMatrizId}`
    if (search.dataInicio) url = url + `&dtInicio=${search.dataInicio}`
    if (search.dataFim) url = url + `&dtFim=${search.dataFim}`

    if (search.dataInicio && search.dataFim) {
        if (search.dataFim < search.dataInicio) {
            return notifyWarn('Data inicial não pode ser maior que data final')
        }
    }

    setLoading(true)
    const data = await api.get(url)
    setLoading(false)

    if (!data.success) {
        return
    }

    data.content.registros.forEach((registro) => {
        registro.dtInicio = maskDate(registro.dtInicio, 'DD-MM-YYYY')
        registro.dtFim = registro.dtFim && maskDate(registro.dtFim, 'DD-MM-YYYY')
    })

    divisaoComissaoActions.getItems(data.content.registros)
    divisaoComissaoActions.getAllItems({ 
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas,
        tamanhoPagina: data.content.tamanhoPagina })
}

export async function getBancos() {
    const data = await api.get(`bancos`)

    if (!data.success) {
        return
    }

    divisaoComissaoActions.setBancos(data.content)
}

export async function getLojas(filtros) {
    let url = `lojas/lojas-que-possui-master-divisao?`
    
    if (filtros.bancoId === '0') delete filtros.bancoId
  
    let searchParams = new URLSearchParams(filtros).toString();
    url = url + searchParams
  
    divisaoComissaoActions.setLoadingLoja(true)
    const data = await api.get(url)
    divisaoComissaoActions.setLoadingLoja(false)

    if (!data.success) {
        return
    }
    divisaoComissaoActions.setLojas(data.content ?? [])
}

export async function getLojasMatrizes(filtros) {
    let url = `lojas/lojas-master-que-possui-divisao?`

    if (filtros.bancoId === '0') delete filtros.bancoId
  
    let searchParams = new URLSearchParams(filtros).toString();
    url = url + searchParams
  
    divisaoComissaoActions.setLoadingLojaMatriz(true)
    const data = await api.get(url)
    divisaoComissaoActions.setLoadingLojaMatriz(false)

    if (!data.success) {
        return
    }
    divisaoComissaoActions.setLojasMatrizes(data.content ?? [])
}

export async function getLojasMatrizesRegister(idBanco) {
    setLoading(true)
    const data = await api.get(`lojas/matriz?bancoId=${idBanco}`)
    setLoading(false)

    if (!data.success) {
        return
    }

    divisaoComissaoActions.setLojasMatrizesRegister(data.content ?? [])
}

export async function getLojasSemDivisaoRegister(idBanco) {
    setLoading(true)
    const data = await api.get(`lojas/lojas-que-possuem-master/${idBanco}`)
    setLoading(false)

    if (!data.success) {
        return
    }

    divisaoComissaoActions.setLojasBanco(data.content ?? [])
}

export async function getTiposComissao(idBanco) {
    setLoading(true)
    const data = await api.get(`TipoComissao/GetByInstituicao?idInstituicao=${idBanco}`)
    setLoading(false)

    if (!data.success) {
        return
    }

    divisaoComissaoActions.setTiposComissao(data.content)
    return data.content
}

export async function getLojasRegister(idBanco) {
    setLoading(true)
    const data = await api.get(`lojas/filiais?bancoId=${idBanco}`)
    setLoading(false)

    if (!data.success) {
        return
    }

    divisaoComissaoActions.setLojasRegister(data.content ?? [])
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`divisaoComissao/${id}`)
    setLoading(false)

    if (!data.success) {
        return
    }

    const tiposComissao = await getTiposComissao(data.content.banco.id)


    data.content.divisaoComissaoItens.forEach((item) => {
        var tipoComissao = tiposComissao.find((tipoComissao)=> tipoComissao.id === item.tiposComissaoId)
        item.percentual = maskMoeda(item.percentual.toFixed(2));
        item.tipoComissaoId= item.tiposComissaoId;
        item.tipoComissao = tipoComissao?.descricao
    })

    let dataFormated = {
        id: data.content.id,
        bancoId: data.content.banco.id,
        lojaBancoId: data.content.lojaBanco.id,
        matriz: data.content.lojaBanco.nomeMaster,
        dataInicio: maskDate(data.content.dtInicio,'YYYY-MM-DD'),
        dataFim: maskDate(data.content.dtFim, 'YYYY-MM-DD'),
        divisaoComissaoItens: data.content.divisaoComissaoItens
    }

    divisaoComissaoActions.item(dataFormated)
}

export async function create(item) {

    if (item.bancoId === '0') {
        return notifyWarn('Selecione uma instituição')
    }

    if (item.dataInicio === '') {
        return notifyWarn('Selecione uma data de início da vigência')
    }

    if (item.dataFim === '') {
        return notifyWarn('Selecione uma data de fim da vigência')
    }

    if (item.dataInicio > item.dataFim) {
        return notifyWarn('Data inicial não pode ser maior que data final')
    }

    if (item.lojaBancoId === '0') {
      return notifyWarn('O campo Loja Banco é obrigatório')
    }
  
    item.divisaoComissaoItens.forEach((item) => {
       item.percentual = removeMaskMoeda(item.percentual)
    })

    let dataCreate = {
        bancoId: item.bancoId,
        lojaBancoId: item.lojaBancoId,
        dtInicio: item.dataInicio,
        dtFim: item.dataFim,
        divisaoComissaoItens: item.divisaoComissaoItens
    }

    setLoading(true)
    const data = await api.post('divisaoComissao', dataCreate)
    setLoading(false)

    if (!data.success) {
        return
    }

    divisaoComissaoActions.cleanRegister()
    notifySuccess('Parametrização de divisão de comissão cadastrada')
    get({
        banco: '0',
        loja: '',
        lojaId: '0',
        lojaMatriz: '',
        lojaMatrizId: '0',
        dataInicio: '',
        dataFim: '',
        codLoja: '',
        codLojaMatriz: ''
    }, 10, 1)
}

export async function remove(id) {
    setLoading(true)
    const data = await api.delete(`divisaoComissao?id=${id}`)
    setLoading(false)

    if (!data.success) {
        return
    }

    notifySuccess('Registro excluído')
    get({
        banco: '0',
        loja: '',
        lojaId: '0',
        lojaMatriz: '',
        lojaMatrizId: '0',
        dataInicio: '',
        dataFim: '',
        codLoja: '',
        codLojaMatriz: ''
    }, 10, 1)
}

export async function update(item) {

    if (item.dataInicio === '') {
        return notifyWarn('Selecione uma data de início da vigência')
    }

    if (item.dataFim === '') {
        return notifyWarn('Selecione uma data de fim da vigência')
    }

    if (item.dataInicio > item.dataFim) {
        return notifyWarn('Data inicial não pode ser maior que data final')
    }

    let divisoesComissao = []

    item.divisaoComissaoItens.forEach((loja) => {
        divisoesComissao.push({
            id: loja.id ? loja.id : null,
            tiposComissaoId: loja.tiposComissaoId,
            percentual: removeMaskMoeda(loja.percentual),
        })
    })

    let dataUpdate = {
        id: item.id,
        bancoId: item.bancoId,
        dtInicio: item.dataInicio,
        dtFim: item.dataFim,
        divisaoComissaoItens: divisoesComissao
    }

    setLoading(true)
    const data = await api.put(`divisaoComissao/${item.id}`, dataUpdate)
    setLoading(false)

    if (!data.success) {
        return
    }

    divisaoComissaoActions.cleanRegister()
    divisaoComissaoActions.setActiveTab('Search')
    notifySuccess('Parametrização de divisão de comissão atualizada')
    get({
        banco: '0',
        loja: '',
        lojaId: '0',
        lojaMatriz: '',
        lojaMatrizId: '0',
        dataInicio: '',
        dataFim: '',
        codLoja: '',
        codLojaMatriz: ''
    }, 10, 1)
}

export async function deleteItemDivisaoComissao(id) {
    setLoading(true)
    const data = await api.delete(`divisaoComissao/deleteItem?id=${id}`)
    setLoading(false)

    if (!data.success) {
        return false
    }

    return true
}

export async function adicionaItemDivisaoComissao(item, divisaoComissaoId) {
  setLoading(true)
  
  const data = await api.post(`divisaoComissao/item`, {
    divisaoComissaoId: divisaoComissaoId,
    tiposComissaoId: item.tiposComissaoId,
    percentual: removeMaskMoeda(item.percentual)
  })
  setLoading(false)
  
  if (!data.success) {
      return
  }

  notifySuccess('Item adicionado com sucesso.')
  return data.content
}