import * as actions from "../../../../../store/modules/personas/papeis_store/actions"
import * as instituicaoFinanceiraActions from "../../../../../store/modules/personas/instituicaoFinanceira/actions"
import * as controller from "../../../../../controllers/personasController/papeisController"
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Switch } from '../../../../../components/Inputs/Input'
import ClienteCPF from './components/clienteCpf'
import Funcionario from './components/funcionario'
import ClienteCNPJ from './components/clienteCnpj'
import Matriz from './components/matriz'
import Fornecedor from './components/fornecedor'
import InstituicaoFinanceira from './components/instituicaoFinanceira';
import * as personasController from "../../../../../controllers/personasController/personasController";

import '../../../styles/papeis.css'
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"

export default function Papeis() {

    const { copy } = useSelector(state => state.personas);
    const { isJuridica, informacao } = useSelector(state => state.personasInformacoes);
    const { cliente_cpf, cliente_cnpj, clienteCPF, clienteCNPJ, tipoLoja, fornecedor, seeRegister } = useSelector(state => state.personasPapeis)

    function onChangeItemCpf(value, field) {
        actions.setIsClienteCpfActive(value, field);
    }

    function onChangeItemCnpj(value, field) {
        actions.setIsClienteCnpjActive(value, field);
    }

    const [modalDelete, setModalDelete] = useState(false);
    const [tipoRelacionamentoSelected, setTipoRelacionamentoSelected] = useState("");

    function toggleModalDelete(tipoRelacionamento) {
        setTipoRelacionamentoSelected(tipoRelacionamento)
        setModalDelete(!modalDelete);
    }

    function deleteItem(relacionamento) {
        if (isJuridica) {
            switch (relacionamento) {
                case "cliente":
                    if(clienteCNPJ.id) controller.desvincularClienteCNPJ(clienteCNPJ.id)
                    actions.cleanClienteCnpjRelacionamento()
                    onChangeItemCnpj(!cliente_cnpj.isClienteCnpjActive, "isClienteCnpjActive")
                    break;
                case "loja":
                    if(tipoLoja.id) controller.desvincularLoja(tipoLoja.id)
                    actions.cleanLojaRelacionamento()
                    onChangeItemCnpj(!cliente_cnpj.isMatrizActive, "isMatrizActive")
                    break;
                case "instituicao":
                    controller.desvincularInstituicao(informacao.id)
                    onChangeItemCnpj(!cliente_cnpj.isInstituicaoActive, "isInstituicaoActive");
                    break;
                case "fornecedor":
                    if(fornecedor.id) controller.desvincularFornecedor(fornecedor.id)
                    actions.cleanFornecedorRelacionamento()
                    onChangeItemCnpj(!cliente_cnpj.isFornecedor, "isFornecedor")
                    break;
                default:
                    break;
            }
        } else {
            switch (relacionamento) {
                case "cliente":
                    if(clienteCPF.id) controller.desvincularClienteCPF(clienteCPF.id)
                    actions.cleanClienteCpfRelacionamento()
                    onChangeItemCpf(!cliente_cpf.isClienteCpfActive, "isClienteCpfActive");
                    break;
                case "funcionario":
                    if(copy.funcionario) controller.desvincularFuncionario(informacao.id)
                    actions.cleanFuncionarioRelacionamento()
                    actions.cleanUserInstituicaoRelacionamento()
                    onChangeItemCpf(!cliente_cpf.isFuncionarioActive, "isFuncionarioActive");
                    break;              
                default:
                    break;
            }
        }
        toggleModalDelete();
    }

    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalDelete}
                toggle={toggleModalDelete}
                backdrop={false}
            >
                <ModalHeader toggle={toggleModalDelete} style={{ height: '10px', border: 'none' }} />
                <ModalBody>
                    <p style={{ fontSize: '18px', color: 'var(--color-preto)' }}>Tem certeza que deseja <strong style={{ fontSize: '17px' }}>desvincular</strong> este relacionamento? As informações incluídas serão perdidas.</p>
                </ModalBody>
                <div className="m-3">
                    <Button onClick={() => toggleModalDelete()} color="danger" style={{ width: "100px", marginRight: "15px", border: 'none' }}> Não </Button>
                    <Button onClick={() => deleteItem(tipoRelacionamentoSelected)} style={{ width: "100px", marginLeft: "15px", background: 'var(--color-verde)', border: 'none' }}> Sim </Button>
                </div>
            </Modal>
            <div style={{ margin: '25px 0 0 25px' }}>
                <p style={{ color: 'var(--color-preto)', fontSize: '18px' }}>Selecione os relacionamentos vinculados a essa persona, preencha os dados se necessário</p>

                <div className="container-papeis">
                    <section>
                        <div>
                            {!isJuridica ?
                                <>
                                    <div
                                        className={cliente_cpf.isClienteCpfActive ? 'atributos atributosActive' : 'atributos'}>
                                        <p>Cliente</p>
                                        <Switch
                                            className='switch'
                                            checked={cliente_cpf.isClienteCpfActive}
                                            onChange={() => {
                                                if (cliente_cpf.isClienteCpfActive) {
                                                    toggleModalDelete("cliente")
                                                } else {
                                                    onChangeItemCpf(!cliente_cpf.isClienteCpfActive, "isClienteCpfActive");
                                                }
                                            }}
                                            disabled={seeRegister}
                                        />
                                    </div>
                                    <div
                                        className={cliente_cpf.isFuncionarioActive ? 'atributos atributosActive' : 'atributos'}>
                                        <p>Funcionário / Parceiro </p>
                                        <Switch
                                            className='switch'
                                            checked={cliente_cpf.isFuncionarioActive}
                                            onChange={() => {
                                                if (cliente_cpf.isFuncionarioActive) {
                                                    toggleModalDelete("funcionario")
                                                } else {
                                                    onChangeItemCpf(!cliente_cpf.isFuncionarioActive, "isFuncionarioActive");
                                                }
                                            }}
                                            disabled={seeRegister}
                                        />
                                    </div>
                                </>
                                :
                                <>
                                    <div
                                        className={cliente_cnpj.isClienteCnpjActive ? 'atributos atributosActive' : 'atributos'}>
                                        <p>Cliente</p>
                                        <Switch
                                            className='switch'
                                            checked={cliente_cnpj.isClienteCnpjActive}
                                            onChange={() => {
                                                if (cliente_cnpj.isClienteCnpjActive) {
                                                    toggleModalDelete("cliente")
                                                } else {
                                                    onChangeItemCnpj(!cliente_cnpj.isClienteCnpjActive, "isClienteCnpjActive")
                                                }
                                            }}
                                            disabled={seeRegister}
                                        />
                                    </div>
                                    <div
                                        className={cliente_cnpj.isMatrizActive ? 'atributos atributosActive' : 'atributos'}>
                                        <p>  Ponto de Venda  </p>
                                        <Switch
                                            className='switch'
                                            checked={cliente_cnpj.isMatrizActive}
                                            onChange={() => {
                                                if (cliente_cnpj.isMatrizActive) {
                                                    toggleModalDelete("loja")
                                                } else {
                                                    onChangeItemCnpj(!cliente_cnpj.isMatrizActive, "isMatrizActive")
                                                }
                                            }}
                                            disabled={seeRegister}
                                        />
                                    </div>
                                    <div
                                        className={cliente_cnpj.isInstituicaoActive ? 'atributos atributosActive' : 'atributos'}
                                    >
                                        <p>Instituição Financeira</p>
                                        <Switch
                                            className='switch'
                                            checked={cliente_cnpj.isInstituicaoActive}
                                            onChange={() => {
                                                if (cliente_cnpj.isInstituicaoActive) {
                                                    toggleModalDelete("instituicao")
                                                } else {
                                                    personasController.isIstituicao(informacao.id);
                                                    actions.setInstituicaoFinanceira(!cliente_cnpj.isInstituicaoActive);
                                                    onChangeItemCnpj(!cliente_cnpj.isInstituicaoActive, "isInstituicaoActive");
                                                    instituicaoFinanceiraActions.cleanInstituicoesFinanceiras();

                                                }
                                            }}
                                            disabled={seeRegister}
                                        />
                                    </div>
                                    <div
                                        className={cliente_cnpj.isFornecedor ? 'atributos atributosActive' : 'atributos'}
                                    >
                                        <p> Fornecedor</p>
                                        <Switch
                                            className='switch'
                                            checked={cliente_cnpj.isFornecedor}
                                            onChange={() => {
                                                if (cliente_cnpj.isFornecedor) {
                                                    toggleModalDelete("fornecedor")
                                                } else {
                                                    onChangeItemCnpj(!cliente_cnpj.isFornecedor, "isFornecedor")
                                                }

                                            }}
                                            disabled={seeRegister}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                        <div style={{ marginLeft: '-60px'}}>
                            {(cliente_cpf.isClienteCpfActive && !isJuridica) && <ClienteCPF />}
                            {(cliente_cpf.isFuncionarioActive && !isJuridica) && <Funcionario />}

                            {(cliente_cnpj.isClienteCnpjActive && isJuridica) && <ClienteCNPJ />}
                            {(cliente_cnpj.isMatrizActive && isJuridica) && <Matriz />}
                            {(cliente_cnpj.isFornecedor && isJuridica) ? <Fornecedor /> : null}
                            {(cliente_cnpj.isInstituicaoActive) ? <InstituicaoFinanceira /> : null}
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}