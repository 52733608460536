import { STORE_ID, TYPES } from "./types";

const INITIAL_STATE = {
  item: {
    id: null,
    bancoId: "0",
    lojaId: "0",
    tiposComissaoId: "0",
    contaCorrenteId: "0",
    principal: false,
  },
  search: {
    bancoId: "0",
    lojaId: "0",
    tiposComissaoId: "0",
    contaCorrenteId: "0",
  },
  bancos: [],
  lojasArrecadadoras: [],
  contas: [],
  tiposComissao: [],
  items: [],
  activeTab: "Search",
  seeRegister: false,
  getAllItems: {
    quantidade: '0',
    quantidadeTotal: '0',
    totalPaginas: '0'
},
  permitions: {
    criar: false,
    deletar: false,
  },
};

export default function vinculoLoja(state = INITIAL_STATE, action) {
  switch (action.storeId) {
    case STORE_ID:
      switch (action.type) {
        case TYPES.SET_ITEMS:
          return { ...state, items: action.value };
          case TYPES.GET_ALL_ITEMS:
            return { ...state, getAllItems: action.value }
        case TYPES.SET_ITEM:
          return { ...state, item: action.value };
        case TYPES.SET_PERMITIONS:
          return { ...state, permitions: action.value };
        case TYPES.SET_BANCOS:
          return { ...state, bancos: action.value };
        case TYPES.SET_LOJAS_ARRECADADORAS:
          return { ...state, lojasArrecadadoras: action.value };
        case TYPES.SET_CONTAS:
          return { ...state, contas: action.value };
        case TYPES.SET_TIPOS_COMISSAO:
          return { ...state, tiposComissao: action.value };
        case TYPES.CLEAN_REGISTER:
          return {
            ...state,
            item: INITIAL_STATE.item,
            lojasArrecadadoras: INITIAL_STATE.lojasArrecadadoras,
            contas: INITIAL_STATE.contas,
            tiposComissao: INITIAL_STATE.tiposComissao,
          };
        case TYPES.CLEAN_SEARCH:
          return { ...state, search: INITIAL_STATE.search };
        case TYPES.SET_SEARCH:
          return { ...state, search: action.value };
        case TYPES.CLEAN_SEARCH_FIELD:
          return {
            ...state,
            search: {
              ...state.search,
              [action.value]: INITIAL_STATE.search[action.value],
            },
          };
        case TYPES.CLEAN_ITEM_FIELD:
          return {
            ...state,
            item: {
              ...state.item,
              [action.value]: INITIAL_STATE.item[action.value],
            },
          };
        case TYPES.SET_ACTIVE_TAB:
          return { ...state, activeTab: action.value };
        case TYPES.SET_SEE_REGISTER:
          return { ...state, seeRegister: action.value };
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
}
