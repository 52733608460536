import React from 'react'
import { Badge } from 'reactstrap'


export default function FiltroSelecionado({element, index}){

    return(
        <Badge pill style={{padding: '7px', color: 'black', background: 'white', border: '1px solid var(--color-roxo)'}}>
            {element.descricao === '1' ? 'Data status da Proposta' : element.descricao === '0' ? 'Data entrada' : element.descricao}
        </Badge>
       
    )
}