const STORE_ID = "CONFERENCIA_CR";

const TYPES = {
  SET_PERMITIONS: "SET_PERMITIONS",
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  SEARCH: "SEARCH",
  SET_LOJAS_BANCO: "SET_LOJAS_BANCO",
  SET_INSTITUICOES: "SET_INSTITUICOES",
  SET_CENTROS_CUSTO: "SET_CENTROS_CUSTO",
  SET_LOJAS: "SET_LOJAS",
  SET_LOJAS_MATRIZES: "SET_LOJAS_MATRIZES",
  SET_ALL_LOJA: "SET_ALL_LOJA",
  SET_All_CONTA_CORRENTE: "SET_All_CONTA_CORRENTE",
  SET_TIPOS_COMISSAO: "SET_TIPOS_COMISSAO",
  SET_CONTAS_BANCARIA: "SET_CONTAS_BANCARIA",
  SET_COMISSOES_AGRUPADO: "SET_COMISSOES_AGRUPADO",
  SET_ALL_AGRUPADO: "SET_ALL_AGRUPADO",
  SET_COMISSOES: "SET_COMISSOES",
  SET_DOWNLOAD: "SET_DOWNLOAD",
  SET_DOWNLOAD_CP: "SET_DOWNLOAD_CP",
  INTEGRADO: "INTEGRADO",
  SET_CANAL_ID: "SET_CANAL_ID",
  SET_ALL: "SET_ALL",
  CLEAN_SEARCH: "CLEAN_SEARCH",
  CLEAN_ALL_SEARCH: "CLEAN_ALL_SEARCH",
  SET_STATUS_COMISSAO_RECEBIDA: "SET_STATUS_COMISSAO_RECEBIDA",
};

export { STORE_ID, TYPES };
