export const COLUMNS = [
    {
        Header: "Descrição",
        accessor: "descricao"
    },
    {
        Header: "Tipo formalização ribercred",
        accessor: "tipoFormalizacaoDescricao"
    },
    {
        Header: "Instituição Financeira",
        accessor: "nomeBanco"
    },
]
