const STORE_ID = "PERSONAS_DOCUMENTACAO";

const TYPES = {
    DOCUMENTOS: "DOCUMENTOS",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    CLEAN_DOCUMENT: "CLEAN_DOCUMENT",
    SET_SEE: "SET_SEE",
    GET_TIPOS_DOCUMENTO: "GET_TIPOS_DOCUMENTO",
    SETDOCUMENTO: 'SETDOCUMENTO'
}

export { STORE_ID, TYPES }