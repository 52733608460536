import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Input, Label, Modal, ModalHeader, Row, Form, FormGroup, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";
import * as BiIcons from "react-icons/bi";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { COLUMNS } from "../constants/columns";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import ReactPaginate from "react-paginate";
import * as actions from "../../../store/modules/campanhaPremiacao/campanha/actions";
import * as controller from "../../../controllers/premiacaoController/campanhaPremiacao";
import { Select } from "../../../components/Inputs/Input";
import { maskMonthYear, removeAccentsAndUppercase } from "../../../util/masks";

const Search = () => {
    const { items, search, canais, tiposOperacao, tiposPendencia, periodos, tiposParceria, convenios, bancos, centrosCusto, produtos, getAllItems, permitions } = useSelector(state => state.campanhaPremiacao);

    const columns = useMemo(() => COLUMNS, []);
    const data = items;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const { pageSize } = state;
    const [itemSelected, setItem] = useState(null);
    const [modalDelete, setModalDelete] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [rows, setRows] = useState(0);
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    useEffect(() => {
        controller.getPeriodicidades();
        controller.getBancos();
        controller.getCanais();
        controller.getTiposOperacao();
        controller.getTiposParceria();
        controller.getConvenios();
        controller.getCentrosCusto();
    }, []);

    useEffect(() => {
        setRows(page.length);
    }, [page])

    useEffect(() => {
        if(getAllItems.tamanhoPagina){
            setPageSize(getAllItems.tamanhoPagina);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllItems])

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        controller.get(search, pageSize, pageSelected + 1);
    }

    async function handleUpdate(id) {
        let content = await controller.getById(id);
        let campanhaPremiacaoRegras = []
        content.campanhaPremiacaoRegras.forEach((regra)=>{
            let instituição = bancos.find((banco) => banco.id === regra.bancoId) ?? ''
            let convenio = convenios.find((convenio) => convenio.id === regra.convenioId) ?? '' 
            let tipoOperacao = tiposOperacao.find((operacao) => operacao.id === regra.tipoOperacaoId) ?? '' 
            let tipoParceria = tiposParceria.find((parceria) => parceria.id === regra.tipoParceriaId) ?? '' 
            let produto = produtos.find((produto) => produto.id === regra.produtoId) ?? '' 
            let centroCusto = centrosCusto.find((centroCusto) => centroCusto.id === regra.centroCustoId) ?? '' 

            campanhaPremiacaoRegras.push({
                ...regra,
                instituição : instituição.nome,
                convenio: convenio.descricao,
                tipoOperacao : tipoOperacao.descricao,
                tipoParceria: tipoParceria.nome,
                produto: produto.nome,
                centroCusto: centroCusto.descricao
            })
        })
        actions.setItem('campanhaPremiacaoRegras', campanhaPremiacaoRegras)

        let campanhaPremiacaoRegraQualidade = []
        content.campanhaPremiacaoRegraQualidade.forEach((regra)=>{
            let tipoPendencia = tiposPendencia.find((tipoPendencia) => tipoPendencia.id === regra.tipoPendenciaId) ?? ''
            let periodo = periodos.find((periodo) => periodo.id === regra.periodoId) ?? '' 

            campanhaPremiacaoRegraQualidade.push({
                ...regra,
                tipoPendencia: tipoPendencia.descricao,
                periodo: periodo.descricao,
            })
        })
        actions.setItem('campanhaPremiacaoRegraQualidade', campanhaPremiacaoRegraQualidade)

        actions.setActiveTab("Register");
    }

    function seeRegister(id) {
        actions.setSeeRegister(true);
        handleUpdate(id);
    }

    function handleDelete(id) {
        setItem(id);
        toggleModalDelete();
    }

    function toggleModalDelete() {
        setModalDelete(!modalDelete);
    }

    async function deleteItem() {
        await controller.remove(itemSelected);
        toggleModalDelete();
    }

    function handleInitialPage(pageSize) {
        const pageSelected = 0;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        controller.get(search, pageSize, pageSelected + 1);
    }

    function onChangeSearch(value, field) {
        actions.setSearch({ ...search, [field]: value });
    }

    function getAll() {
        controller.get(search, pageSize, 1);
    }

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            getAll();
        }
    }

    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalDelete}
                toggle={toggleModalDelete}
                backdrop={false}
            >
                <ModalHeader toggle={toggleModalDelete} style={{ height: '10px', border: 'none' }} />
                <ModalBody>
                    <p style={{ fontSize: '18px', color: 'var(--color-preto)' }}>Tem certeza que deseja <strong style={{ fontSize: '17px' }}>remover</strong> esse item?</p>
                </ModalBody>
                <div className="m-3">
                    <Button onClick={() => toggleModalDelete()} color="danger" style={{ width: "100px", marginRight: "15px", border: 'none' }}> Cancelar </Button>
                    <Button onClick={() => deleteItem()} style={{ width: "100px", marginLeft: "15px", background: 'var(--color-verde)', border: 'none' }}> Confirmar </Button>
                </div>
            </Modal>

            <Col>
                <Card className="pt-3 pb-2 mb-5">
                    <Col>
                        <div className="pb-3">
                            <Label>Pesquisar</Label>
                            <p></p>
                            <Form>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="descriptionSearch">Descrição</Label>
                                            <Input
                                                type="text"
                                                id="descriptionSearch"
                                                placeholder="Digite aqui"
                                                value={search.descricao}
                                                maxLength="150"
                                                onChange={e => onChangeSearch(removeAccentsAndUppercase(e.target.value), "descricao")}
                                                onKeyPress={e => handleKeyPress(e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Canal de Venda</Label>
                                            <Select
                                                first="SELECIONE"
                                                value={{ value: search.canalVendaId }}
                                                select={{ value: "id", label: "nome" }}
                                                options={canais}
                                                onChange={e => onChangeSearch(e.value, 'canalVendaId')}
                                                isSearchable={true}
                                                autoComplete="off"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Tipo de Parceria</Label>
                                            <Select
                                                first="SELECIONE"
                                                value={{ value: search.tipoParceriaId }}
                                                select={{ value: "id", label: "nome" }}
                                                options={tiposParceria}
                                                onChange={e => onChangeSearch(e.value, 'tipoParceriaId')}
                                                isSearchable={true}
                                                autoComplete="off"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Instituição Financeira</Label>
                                            <Select
                                                first="SELECIONE"
                                                value={{ value: search.bancoId }}
                                                select={{ value: "id", label: "nome" }}
                                                options={bancos}
                                                onChange={e => onChangeSearch(e.value, 'bancoId')}
                                                isSearchable={true}
                                                autoComplete="off"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Convênio</Label>
                                            <Select
                                                first="SELECIONE"
                                                value={{ value: search.convenioId }}
                                                select={{ value: "id", label: "descricao" }}
                                                options={convenios}
                                                onChange={e => onChangeSearch(e.value, 'convenioId')}
                                                isSearchable={true}
                                                autoComplete="off"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Tipo de Operação</Label>
                                            <Select
                                                first="SELECIONE"
                                                value={{ value: search.tipoOperacaoId }}
                                                select={{ value: "id", label: "descricao" }}
                                                options={tiposOperacao}
                                                onChange={e => onChangeSearch(e.value, 'tipoOperacaoId')}
                                                isSearchable={true}
                                                autoComplete="off"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Período</Label>
                                            <Input
                                                type="text"
                                                placeholder='mm/aaaa'
                                                value={search.periodo}
                                                onChange={e => onChangeSearch(maskMonthYear(e.target.value), 'periodo')}
                                                style={{ height: '35px' }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Button className="mr-4"
                                            style={{
                                                width: "150px",
                                                background: "var(--color-branco)",
                                                borderColor: "var(--color-laranja)",
                                                color: "var(--color-laranja)",
                                                borderRadius: "7px"
                                            }}
                                            onClick={() => actions.cleanSearch()}
                                        >
                                            Limpar
                                        </Button>
                                        <Button
                                            className="purpleButton"
                                            style={{ width: "150px" }}
                                            onClick={() => {controller.get(search, pageSize, 1);
                                                gotoPage(0);
                                                setInitialPage(true);
                                                setSelectedPage(0);}}>
                                            Buscar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column, index) => (
                                            <th key={index}>
                                                {column.render("Header")}
                                                <span className="float-right">
                                                    {column.isSorted ?
                                                        (column.isSortedDesc ?
                                                            <BsIcons.BsArrowDown
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />
                                                            :
                                                            <BsIcons.BsArrowUp
                                                                id="OrdenarAsc"
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />)
                                                        :
                                                        <BsIcons.BsArrowDownUp
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />}
                                                </span>
                                            </th>
                                        ))}
                                        <th style={{ textAlign: "center" }}>
                                            Ações
                                 </th>
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr className="text-center" {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                            })}
                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                <>{ permitions.criar &&(
                                                    <BiIcons.BiEdit
                                                        style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}
                                                        onClick={() => handleUpdate(row.original.id)}
                                                    />
                                                    )
                                                    }
                                                    <AiIcons.AiOutlineEye
                                                        style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}
                                                        onClick={() => seeRegister(row.original.id)}
                                                    />
                                                    { permitions.deletar && !row.original.usuarioAprovador &&(
                                                        <BiIcons.BiTrash
                                                            style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA" }}
                                                            onClick={() => handleDelete(row.original.id)}
                                                        />
                                                    )
                                                    }
                                                </>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Row className="pt-4">
                            <Col>
                            <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {getAllItems.quantidade ? getAllItems.quantidade : "0"} de {getAllItems.quantidadeTotal ? getAllItems.quantidadeTotal : "0"}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={getAllItems.totalPaginas}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={e => handlePageClick(e)}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { handleInitialPage(e.target.value); setPageSize(Number(e.target.value)) }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Card >
            </Col >
        </>
    );
}


export default Search;
