import React, {useEffect} from 'react';
import {IoIosArrowBack} from "react-icons/io";
import {Card, Col, Form, Row, Button, TabContent, TabPane} from "reactstrap";
import Search from "./tabs/search";
import Register from "./tabs/register";
import {useSelector} from "react-redux";
import * as controller from "../../controllers/centroCustoController";
import * as actions from "../../store/modules/centroCusto/actions"
import Breadcrumb from '../../components/breadcrumbs';

const CentroCusto = () => {

    const {item, activeTab, copy, seeRegister, permitions} = useSelector(state => state.centroCusto);

    useEffect(() => {
        actions.setActiveTab("Search")
    }, [])

    async function saveItem() {
        if (item.id === "") {
            controller.create(item);
        } else {
            await controller.update(item, copy);
        }
    }

    function changeTab(tab) {
        actions.setActiveTab(tab);
        actions.cleanRegister();
    }

    function seeRegisterF() {
        if (item.id !== "") {
            actions.cleanRegister();
        }
        actions.setSeeRegister(false);
    }

    return (
        <>
            <Col>
                <Breadcrumb activeTab={activeTab}/>
                <Card>
                    <Form>
                        {
                            activeTab === "Search"
                                ?
                                permitions.criar && (
                                    <Col md={12}>
                                        <Row className="pt-2 pb-2">
                                            <Col md={8}>
                                                <Button
                                                    onClick={() => changeTab("Register")}
                                                    style={{width: "210px"}}
                                                    className="purpleButton"
                                                >
                                                    Cadastrar Centro de Custo
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                                :
                                <Col className="pt-2 pb-2">
                                    <Button
                                        onClick={() => {
                                            changeTab("Search");
                                            seeRegisterF()
                                        }}
                                        style={{width: "127px", color: "white"}}
                                        className="purpleButton"
                                    >
                                        <IoIosArrowBack style={{marginTop: '3px', float: 'left'}}/> Voltar
                                    </Button>
                                    {!seeRegister &&
                                        <Button
                                            onClick={() => saveItem()}
                                            style={{width: "242px", float: 'right'}}
                                            className="purpleButton"
                                        >
                                            Salvar Cadastro
                                        </Button>
                                    }
                                </Col>
                        }
                    </Form>
                </Card>
            </Col>

            <TabContent style={{width: "100%"}} activeTab={activeTab}>
                <TabPane tabId="Search">
                    <br/>
                    <Search/>
                </TabPane>
                <TabPane tabId="Register">
                    <br/>
                    <Register/>
                </TabPane>
            </TabContent>
        </>
    );
}

export default CentroCusto;