import React, {useEffect, useMemo, useState} from "react";
import {
    Button,
    Card,
    Col,
    Input,
    Label,
    Row,
    Form,
    FormGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import SearchConferenciaCr from "./panels/search/searchConferenciaCr";
import LogW3Erp from "./panels/logW3Erp/logW3Erp";
import {useSelector} from "react-redux";
import logW3Erp from "../../../store/modules/conferenciaCr/logW3Erp";
import classnames from 'classnames';
import * as actionsLog from "../../../store/modules/conferenciaCr/logW3Erp/actions";

const Search = () => {

    const { tab } = useSelector(state => state.logW3Erp);

    useEffect(() => {
        changeTab('1');
    }, []);

    function changeTab(value) {
        actionsLog.setTab(value);
    }

    return (
        <>
            <Col>
                <Card className="pt-3 pb-2 mb-5 ml-4">
                    <Nav>
                        <NavItem style={{marginLeft: '25px'}}>
                            <NavLink
                                className={classnames({ active: tab === '1' })}
                                onClick={() => { changeTab('1'); }}
                                style={
                                    tab === '1' ?
                                        { color: 'var(--color-preto', fontWeight: '700', borderBottom: '3px solid var(--color-verde-claro)', transition: '0.1s', cursor: 'pointer' }
                                        : { color: 'var(--color-preto', fontWeight: '700', cursor: 'pointer' }}
                            >
                                Pesquisar
                            </NavLink>
                        </NavItem>
                        <NavItem style={{ padding: '0 15px 0 15px' }}>
                            <NavLink
                                className={classnames({ active: tab === '2' })}
                                onClick={() => { changeTab('2'); }}
                                style={
                                    tab === '2' ?
                                        { color: 'var(--color-preto', fontWeight: '700', cursor: 'pointer', borderBottom: '3px solid var(--color-verde-claro)', transition: '0.1s' }
                                        : { color: 'var(--color-preto', cursor: 'pointer', fontWeight: '700' }}
                            >
                                Logs
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={tab}>
                        <TabPane tabId="1">
                            <SearchConferenciaCr />
                        </TabPane>
                        <TabPane tabId="2">
                            <LogW3Erp />
                        </TabPane>
                    </TabContent>
                </Card>
            </Col>
        </>
    );
}


export default Search;
