import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    item: {
        administrativo: false,
        descricao: "",
        id: ""
    },
    copy: {},
    invalid: {
        descricao: false,
    },
    items: [],
    activeTab: "Search",
    seeRegister: false
}

export default function grupoDeAcesso(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.GRUPOS:
                    return { ...state, items: action.value }
                case TYPES.GRUPO:
                    return { ...state, item: action.value }
                case TYPES.INVALID_GRUPO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_GRUPO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.GRUPOS_COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, item: INITIAL_STATE.item, invalid: INITIAL_STATE.invalid }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}