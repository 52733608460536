const STORE_ID = "INCONSISTENCIA_COMISSAO";

const TYPES = {
    SET_PERMITIONS: "SET_PERMITIONS",
    SET_TAB: "SET_TAB",
    SET_TABS: "SET_TABS",
    SET_COMISSOES: "SET_COMISSOES",
    SET_PRODUTOS: "SET_PRODUTOS",
    SET_TIPOS_OPERACAO: "SET_TIPOS_OPERACAO",
    SET_CONVENIOS: "SET_CONVENIOS",
    SET_TIPOS_COMISSAO: "SET_TIPOS_COMISSAO",
    CLEAN_TABS: "CLEAN_TABS",
}

export { STORE_ID, TYPES }