import { TYPES, STORE_ID } from "./types";
import store from "../../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setGetAll(value) {
    dispatch({
        type: TYPES.SET_GET_ALL,
        value
    });
}

export function setLog(value) {
    dispatch({
        type: TYPES.LOG,
        value
    });
}

export function setTab(value) {
    dispatch({
        type: TYPES.SET_TAB,
        value
    });
}
