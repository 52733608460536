import axios from "axios";
import { notifyError } from "../components/message/message";

export const client = axios.create({
  baseURL: "https://api.riber.app.br/api",
  
});

const api = {
  get: async (url, params) => {
    try {
      const { data, status } = await client.get(url, getConfigs(params));
      let newData = data;
      newData.status = status;
      return newData;
    } catch (error) {
      handleError(error);
      return { success: false };
    }

  },
  post: async (url, body) => {
    try {
      const { data } = await client.post(url, body, getConfigs());
      return data;
    } catch (error) {
      handleError(error);
      return { success: false };
    }
  },
  put: async (url, body) => {
    try {
      const { data } = await client.put(url, body, getConfigs());
      return data;
    } catch (error) {
      handleError(error);
      return { success: false };
    }
  },
  delete: async (url) => {
    try {
      const { data } = await client.delete(url, getConfigs());
      return data;
    } catch (error) {
      handleError(error);
      return { success: false };
    }
  },
};

function getConfigs(params) {
  return {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("TOKEN_KEY"),
      "Content-Type": "application/json",
    },
    params,
  };
}

function handleError(error) {
  const messages = error?.response?.data?.messages;

  if (!messages) {
    notifyError("Não foi possível realizar a ação.");
    return;
  }

  messages.forEach((msg) => {
    notifyError(msg);
  });
}

export default api;
