const STORE_ID = "INSTPRODUCTS";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    PRODUTOINST: "PRODUTOINST",
    SET_OPERACOES: "SET_OPERACOES",
    SET_PRODUTOSRC: "SET_PRODUTOSRC",
    SET_BANCOS: "SET_BANCOS",
    PRODUTOINST_COPY: "PRODUTOINST_COPY",
    INVALID_PRODUTOINST: "INVALID_PRODUTOINST",
    PRODUTOSINST: "PRODUTOSINST",
    CLEAN_INVALID_PRODUTOINST: "CLEAN_INVALID_PRODUTOINST",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE"
}

export { STORE_ID, TYPES }