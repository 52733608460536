import React, { useEffect } from "react";
import { IoIosArrowBack } from 'react-icons/io';
import { Card, Col, Form, Row, Button, TabContent, TabPane } from "reactstrap";
import Search from "./tabs/search";
import Register from "./tabs/register";
import { useSelector } from "react-redux";
import * as actions from "../../store/modules/campanhaPremiacao/campanha/actions";
import * as actionsEventos from "../../store/modules/campanhaPremiacao/eventos/actions";
import * as controller from '../../controllers/premiacaoController/campanhaPremiacao'

import Breadcrumb from '../../components/breadcrumbs'
import { useState } from "react";
import ModalAgenda from "./components/ModalAgenda";

const CampanhaPremiacao = () => {

    const { item, activeTab, seeRegister,permitions,periodicidades, bancos, convenios, tiposOperacao, tiposParceria, produtos, centrosCusto, tiposPendencia, periodos } = useSelector(state => state.campanhaPremiacao);

    const [isModalAgendaOpen, setIsModalAgendaOpen] = useState(false)

    const toggleModalAgenda = () => setIsModalAgendaOpen(!isModalAgendaOpen)

    useEffect(() => {
        actions.setActiveTab("Search")
    }, [])

    async function aprovarCampanha() {
        if(Boolean(item.campanhaPremiacaoAgenda.length === 0)){
            await controller.getAgenda(item.id)
        }
        toggleModalAgenda()
    }

    async function finishTask(){
        let agenda = item.campanhaPremiacaoAgenda
        if(Boolean(agenda.length === 0)){
            agenda = await controller.getAgenda(item.id)
        }
        await controller.encerrarCampanha(item.id, agenda)
        const content = await controller.getById(item.id)

        let campanhaPremiacaoRegras = []
            content.campanhaPremiacaoRegras.forEach((regra)=>{
            let instituição = bancos.find((banco) => banco.id === regra.bancoId) ?? ''
            let convenio = convenios.find((convenio) => convenio.id === regra.convenioId) ?? '' 
            let tipoOperacao = tiposOperacao.find((operacao) => operacao.id === regra.tipoOperacaoId) ?? '' 
            let tipoParceria = tiposParceria.find((parceria) => parceria.id === regra.tipoParceriaId) ?? '' 
            let produto = produtos.find((produto) => produto.id === regra.produtoId) ?? '' 
            let centroCusto = centrosCusto.find((centroCusto) => centroCusto.id === regra.centroCustoId) ?? '' 

            campanhaPremiacaoRegras.push({
                ...regra,
                instituição : instituição.nome,
                convenio: convenio.descricao,
                tipoOperacao : tipoOperacao.descricao,
                tipoParceria: tipoParceria.nome,
                produto: produto.nome,
                centroCusto: centroCusto.descricao
            })
        })
        actions.setItem('campanhaPremiacaoRegras', campanhaPremiacaoRegras)

        let campanhaPremiacaoRegraQualidade = []
        content.campanhaPremiacaoRegraQualidade.forEach((regra)=>{
            let tipoPendencia = tiposPendencia.find((tipoPendencia) => tipoPendencia.id === regra.tipoPendenciaId) ?? ''
            let periodo = periodos.find((periodo) => periodo.id === regra.periodoId) ?? '' 

            campanhaPremiacaoRegraQualidade.push({
                ...regra,
                tipoPendencia: tipoPendencia.descricao,
                periodo: periodo.descricao,
            })
        })
        actions.setItem('campanhaPremiacaoRegraQualidade', campanhaPremiacaoRegraQualidade)

        let diaPagamento = periodicidades.find((periodo) => periodo.id === item.periodicidadePagamentoId)
        let diaApuracao = periodicidades.find((periodo) => periodo.id === item.periodicidadeApuracaoId)

        actions.setItem('descricaoLabel', diaPagamento.descricao)
        actions.setItem('descricaoLabelApuracao', diaApuracao.descricao)
    }

    function changeTab(tab) {
        actions.setActiveTab(tab);
        actions.cleanRegister();
        actionsEventos.cleanAllRegister();
    }

    function seeRegisterF() {
        if (item.id !== "0") {
            actionsEventos.cleanAllRegister();
            actions.cleanRegister();
        }
        actions.setSeeRegister(false);
    }

    return (
        <>
                <Col>
                <Breadcrumb activeTab={activeTab} />
                    <Card>
                        <Form>
                            {
                                activeTab === "Search"
                                    ?
                                     permitions.criar && (
                                        <Col md={12}>
                                        <Row className="pt-2 pb-2">
                                            <Col md={8}>
                                                <Button
                                                    onClick={() => changeTab("Register")}
                                                    style={{ width: "200px" }}
                                                    className='purpleButton'
                                                >
                                                    Cadastrar Campanha
                                                </Button>
                                            </Col>
                                            </Row>
                                        </Col>
                                        )
                                    :
                                    <Col className="pt-2 pb-2">
                                        <Button
                                            onClick={() => { changeTab("Search"); seeRegisterF() }}
                                            style={{ width: "150px", color: "white" }}
                                            className='purpleButton'
                                        >
                                            <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar
                                        </Button>
                                        { permitions.autorizar && !seeRegister && item.id !== '0'  && !item.hasAproved && (
                                            <>
                                            <Button
                                                onClick={() => aprovarCampanha()}
                                                style={{ width: "242px", float: 'right' }}
                                                className="purpleButton"
                                            >
                                                Aprovar Campanha
                                            </Button>
                                            </>
                                        )}
                                        { permitions.autorizar && !seeRegister && item.id !== '0'  && item.hasAproved && !item.encerrado &&(
                                            <>
                                            <Button
                                                onClick={() => finishTask()}
                                                style={{ width: "242px", float: 'right', marginLeft: '30px',
                                                background: "var(--color-branco)",
                                                borderColor: "var(--color-laranja)",
                                                color: "var(--color-laranja)",
                                                borderRadius: "7px" }}
                                            >
                                                Encerrar campanha
                                            </Button>
                                            </>
                                        )}
                                        {
                                            item.id !== '0'  && item.hasAproved &&(
                                                <Button
                                                onClick={() => aprovarCampanha()}
                                                style={{ width: "242px", float: 'right' }}
                                                className="purpleButton"
                                                >
                                                    Agenda
                                                </Button>
                                            )
                                        }
                                    </Col>
                            }
                        </Form>
                    </Card>
                </Col>
                <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <br />
                        <Search />
                    </TabPane>
                    <TabPane tabId="Register">
                        <br />
                        <Register />
                    </TabPane>
                </TabContent>
                    <ModalAgenda
                    isOpen={isModalAgendaOpen}
                    toggle= {toggleModalAgenda}
                    />
        </>
    );
}

export default CampanhaPremiacao;