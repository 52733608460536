import api from "../util/api";
import { notifyWarn, notifySuccess } from "../components/message/message";
import * as comissaoActions from "../store/modules/tipoComissao/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("tipoComissao");
    setLoading(false)

    if (!data.success) {
        return;
    }

    comissaoActions.getItems(data.content);
}

export async function getInstituicoes() {

    const data = await api.get("Bancos");

    if (!data.success) {
        return;
    }

    const bancos = []

    data.content.forEach(banco => {
        bancos.push({
            nome: banco.nome,
            id: banco.id
        })
    })


    comissaoActions.setInstituicoes(bancos);
}

export async function getBasesCalculos() {

    const data = await api.get("baseCalculo");

    if (!data.success) {
        return;
    }

    comissaoActions.getItemsBasesCalculos(data.content);
}

export async function getProdutosBanco(bancoId) {

    setLoading(true)
    const data = await api.get(`ProdutoBanco/ProdutoInstituicao?idInstituicao=${bancoId}`);

    if (!data.success) {
        return;
    }
    setLoading(false)
    comissaoActions.setProdutosBanco(data.content);
}

export async function getAllProdutosBanco() {

    setLoading(true)
    const data = await api.get(`ProdutoBanco`);
    setLoading(false)
    if (!data.success) {
        return;
    }
    comissaoActions.setAllProdutosBanco(data.content);
}

export async function getFiltroPrudutosBanco(id, idProduto) {

    setLoading(true)
    const data = await api.get(`TipoComissao/${id}/GetByProduto/${idProduto}`);

    if (!data.success) {
        return;
    }

    var newData = []
    if(data.content.length > 0){
        data.content[0].tiposComissaoBanco.forEach((e)=>{
            newData.push({
                id: e.id,
                bancoId: e.banco.id,
                produtoBancoId: e.produtoBanco.id,
                baseCalculoId: e.baseCalculo.id,
                periodicidadeId: e.periodicidade.id,
                unidadeComissaoId: e.unidadeComissao.id,
            })
        })
    }
    setLoading(false)
    comissaoActions.setItem('tipoComissaoBanco', newData)
}

export async function getFiltroBanco(id, idBanco) {

    setLoading(true)
    const data = await api.get(`TipoComissao/${id}/GetByInstituicao/${idBanco}`);

    if (!data.success) {
        return;
    }

    var newData = []
    if(data.content.length > 0){
        data.content[0].tiposComissaoBanco.forEach((e)=>{
            newData.push({
                id: e.id,
                bancoId: e.banco.id,
                produtoBancoId: e.produtoBanco.id,
                baseCalculoId: e.baseCalculo.id,
                periodicidadeId: e.periodicidade.id,
                unidadeComissaoId: e.unidadeComissao.id,
            })
        })
    }
    setLoading(false)
    comissaoActions.setItem('tipoComissaoBanco', newData)
}

export async function getProdutosBancoII(bancoId) {

    setLoading(true)
    const data = await api.get(`ProdutoBanco/ProdutoInstituicao?idInstituicao=${bancoId}`);

    if (!data.success) {
        return;
    }
    setLoading(false)
    comissaoActions.setProdutosBancoII(data.content);
}

export async function getPeriodicidades() {

    const data = await api.get("periodicidade");

    if (!data.success) {
        return;
    }

    comissaoActions.getItemsPeriodicidades(data.content);
}

export async function getUnidadeComissao() {

    const data = await api.get("unidadeComissao");

    if (!data.success) {
        return;
    }

    comissaoActions.getItemsUnidadeComissao(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`tipoComissao/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    let tiposComissaoFormated = []

    data.content.tiposComissaoBanco.forEach((comissao) => {
        tiposComissaoFormated.push({
            id: comissao.id,
            bancoId: comissao.banco.id,
            produtoBancoId: comissao.produtoBanco.id,
            baseCalculoId: comissao.baseCalculo.id,
            periodicidadeId: comissao.periodicidade.id,
            unidadeComissaoId: comissao.unidadeComissao.id,
        })
    })

    const dataUpdate = {
        descricao: data.content.descricao,
        tipoComissaoBanco: tiposComissaoFormated,
        id: data.content.id,
        geraParcela: data.content.geraParcela,
    }

    comissaoActions.item(dataUpdate);
    comissaoActions.copy(dataUpdate);
}

export async function create(item) {

    if (item.descricao.trim() === "") {
        comissaoActions.setInvalid("descricao");
        return notifyWarn("Descrição do tipo de comissão não pode estar em branco");
    }


    const body = {
        descricao: item.descricao,
        geraParcela: item.geraParcela,
        tiposComissaoBanco: item.tipoComissaoBanco,
    }

    setLoading(true)
    const data = await api.post("tipoComissao", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Tipo de comissão cadastrada");
    comissaoActions.cleanRegister();
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`tipoComissao/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Tipo de comissão removida");
    get();
}

export async function update(item, copy) {

    if (item.descricao.trim() === "") {
        comissaoActions.setInvalid("descricao");
        return notifyWarn("Descrição da comissão não pode estar em branco");
    }

    let tipoComissaoBancoDiff = item.tipoComissaoBanco
    .filter(x => !copy.tipoComissaoBanco.includes(x))
    .concat(copy.tipoComissaoBanco.filter(x => !item.tipoComissaoBanco.includes(x)));
    
    const body = {
        descricao: item.descricao,
        geraParcela: item.geraParcela,
        tiposComissaoBanco: tipoComissaoBancoDiff
    }

    setLoading(true)
    const data = await api.put(`tipoComissao/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Tipo de comissão atualizada");
    comissaoActions.cleanRegister();
    comissaoActions.setActiveTab("Search");
    get();
}

export async function deleteTipoComissao(id, idComissao) {
    setLoading(true)
    const data = await api.delete(`tipoComissao/${id}/${idComissao}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Comissão removida")
    return data.success
}