const STORE_ID = "PERSONAS_CONTATO";

const TYPES = {
    CLEAN_REGISTER: "CLEAN_REGISTER",
    TELEFONES: "TELEFONES",
    EMAILS: "EMAILS",
    GET_TIPOS_TELEFONE: "GET_TIPOS_TELEFONE",
    GET_TIPOS_EMAIL: "GET_TIPOS_EMAIL",
    SET_SEE: "SET_SEE",
    SET_EMAIL: 'SET_EMAIL',
    SET_TELEFONE: 'SET_TELEFONE',
    CLEAN_TELEFONE: 'CLEAN_TELEFONE',
    CLEAN_EMAIL: 'CLEAN_EMAIL'
}

export { STORE_ID, TYPES }