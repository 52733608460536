import React, { useEffect } from "react";
import { IoIosArrowBack } from 'react-icons/io';
import { Card, Col, Form, Row, Button, TabContent, TabPane } from "reactstrap";
import Search from "./tabs/search";
import Register from "./tabs/register";
import { useSelector } from "react-redux";
import * as  centroCustoController from "../../controllers/vinculoCentroCustoController";
import * as centroCustoActions from "../../store/modules/vinculoCentroDeCusto/actions";
import history from "../../util/history";
import Breadcrumb from "../../components/breadcrumbs";

const CentroDeCusto = () => {

    const { item, activeTab, copy, seeRegister, activePersona, permitions } = useSelector(state => state.vinculoCentroDeCusto);

    useEffect(() => {
        centroCustoActions.setActiveTab("Search")
    }, [])

    async function saveItem() {
        if (item.id === "") {
            centroCustoController.create(item);
        } else {
            await centroCustoController.update(item, copy);
        }
    }

    function changeTab(tab) {
        centroCustoActions.setActiveTab(tab);
        centroCustoActions.cleanRegister();
    }

    function seeRegisterF() {
        if (item.id !== "") {
            centroCustoActions.cleanRegister();
        }
        centroCustoActions.setSeeRegister(false);
    }

    function changeView() {
        history.push("personas");
        centroCustoActions.setActivePersona(false);
        centroCustoActions.setActiveTab("Search");
    }

    return (
        <>
                <Col>
                    <Breadcrumb activeTab={activeTab} />
                    <Card>
                        <Form>
                            {
                                activeTab === "Search"
                                    ?
                                    permitions.criar && (
                                    <Col md={12}>
                                        <Row className="pt-2 pb-2">
                                            <Col md={8}>
                                                <Button
                                                    onClick={() => changeTab("Register")}
                                                    style={{ width: "210px" }}
                                                    className='purpleButton'
                                                >
                                                    Cadastrar Centro de Custo
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    )
                                    :
                                    <Col className="pt-2 pb-2">
                                        {
                                            !activePersona ?
                                                <Button
                                                    onClick={() => { changeTab("Search"); seeRegisterF() }}
                                                    style={{ width: "150px", color: "white" }}
                                                    className="purpleButton"
                                                >
                                                    <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar
                                        </Button>
                                                :
                                                <Button
                                                    onClick={() => changeView()}
                                                    style={{ width: "200px", color: "white" }}
                                                    className="purpleButton"
                                                >
                                                    <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar para Personas
                                        </Button>
                                        }
                                        {!seeRegister && (
                                            <Button
                                                onClick={() => saveItem()}
                                                style={{ width: "242px", float: 'right' }}
                                                className="purpleButton"
                                            >
                                                Salvar Cadastro
                                            </Button>
                                        )}
                                    </Col>
                            }
                        </Form>
                    </Card>
                </Col>

                <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <br />
                        <Search />
                    </TabPane>
                    <TabPane tabId="Register">
                        <br />
                        <Register />
                    </TabPane>
                </TabContent>
        </>
    );
}

export default CentroDeCusto;