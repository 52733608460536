import React, { useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  Button,
  Card,
  Col,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Form,
  FormGroup,
} from "reactstrap";
import { useSelector } from "react-redux";
import * as BiIcons from "react-icons/bi";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { COLUMNS } from "../constants/columns";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import { Select } from "../../../components/Inputs/Input";
import * as actions from "../../../store/modules/usuario/actions";
import * as usuarioController from "../../../controllers/usuarioController";
import {
  removeAccentsAndUppercase,
  formatarDocumento,
} from "../../../util/masks";
import { CgLock } from "react-icons/cg";
import ModalUnblockUser from "../components/UnlockUserModal";
import { item } from "../../../store/modules/vinculoCentroDeCusto/actions";

const Search = () => {
  const { search, items, getAllItems, permitions, statusUsers } = useSelector(
    (state) => state.usuario
  );

  const columns = useMemo(() => COLUMNS, []);
  const data = items;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageSize } = state;
  const [itemSelected, setItem] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalActivate, setModalActivate] = useState(false);
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [modalUnlock, setModalUnlock] = useState(false);

  const handlePageClick = (e) => {
    const pageSelected = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
    usuarioController.getAll(pageSelected, pageSize, search);
  };

  async function handleUpdate(id) {
    await usuarioController.getById(id, items);
    actions.setSeeUpdate(true);
    actions.setActiveTab("Register");
  }

  function seeRegister(id) {
    actions.setSeeRegister(true);
    handleUpdate(id);
  }

  function handleDelete(id) {
    setItem(id);
    toggleModalDelete();
  }

  function handleActivate(id) {
    setItem(id);
    toggleModalActivate();
  }

  function toggleModalDelete() {
    setModalDelete(!modalDelete);
  }

  function toggleModalActivate() {
    setModalActivate(!modalActivate);
  }

  function toggleModalUnblock() {
    setModalUnlock(!modalUnlock);
  }

  function deleteUsuario() {
    usuarioController.deleteById(itemSelected, 0, pageSize, search);
  }

  function activateUsuario() {
    usuarioController.activateById(itemSelected, 0, pageSize, search);
  }
  function unlockUser() {
    usuarioController.unblockUser(itemSelected);
  }

  function handleInitialPage(pageSize) {
    gotoPage(0);
    setInitialPage(true);
    setSelectedPage(0);
    usuarioController.getAll(0, pageSize, search);
  }

  async function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      await getAll();
    }
  }

  async function getAll() {
    setPageSize(10);
    await usuarioController.getAll(0, pageSize, search);
    handleInitialPage(pageSize);
  }

  function onChangeSearch(field, value) {
    actions.setSearch({ ...search, [field]: value });
  }

  return (
    <>
      <Modal
        className="text-center"
        isOpen={modalDelete}
        toggle={toggleModalDelete}
        backdrop={false}
      >
        <ModalHeader
          toggle={toggleModalDelete}
          style={{ height: "10px", border: "none" }}
        />
        <ModalBody>
          <p style={{ fontSize: "18px", color: "var(--color-preto)" }}>
            Tem certeza que deseja{" "}
            <strong style={{ fontSize: "17px" }}>remover</strong> esse item?
          </p>
        </ModalBody>
        <div className="m-3">
          <Button
            onClick={() => toggleModalDelete()}
            color="danger"
            style={{ width: "100px", marginRight: "15px" }}
          >
            {" "}
            Cancelar
          </Button>
          <Button
            onClick={() => {
              deleteUsuario();
              toggleModalDelete();
            }}
            style={{
              width: "100px",
              marginLeft: "15px",
              background: "var(--color-verde)",
              border: "none",
            }}
          >
            {" "}
            Confirmar
          </Button>
        </div>
      </Modal>

      <Modal
        className="text-center"
        isOpen={modalActivate}
        toggle={toggleModalActivate}
        backdrop={false}
      >
        <ModalHeader
          toggle={toggleModalActivate}
          style={{ height: "10px", border: "none" }}
        />
        <ModalBody>
          <p style={{ fontSize: "18px", color: "var(--color-preto)" }}>
            Tem certeza que deseja{" "}
            <strong style={{ fontSize: "17px" }}>ativar</strong> esse item?
          </p>
        </ModalBody>
        <div className="m-3">
          <Button
            onClick={() => toggleModalActivate()}
            color="danger"
            style={{ width: "100px", marginRight: "15px" }}
          >
            {" "}
            Cancelar
          </Button>
          <Button
            onClick={() => {
              activateUsuario();
              toggleModalActivate();
            }}
            style={{
              width: "100px",
              marginLeft: "15px",
              background: "var(--color-verde)",
              border: "none",
            }}
          >
            {" "}
            Confirmar
          </Button>
        </div>
      </Modal>
      <ModalUnblockUser
        isOpen={modalUnlock}
        toggle={toggleModalUnblock}
        itemId={itemSelected}
       
      />
      <Col>
        <Card className="pt-3 pb-2 mb-5">
          <Col>
            <div className="pb-3">
              <Label style={{ fontWeight: "700" }}>Pesquisar</Label>
              <Form>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label> CPF/CNPJ </Label>
                      <Input
                        id="cpf"
                        placeholder="Digite aqui..."
                        value={search.cpf}
                        onChange={(e) => onChangeSearch("cpf", e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Nome</Label>
                      <Input
                        id="nome"
                        placeholder="Digite aqui..."
                        value={search.nome}
                        onChange={(e) =>
                          onChangeSearch(
                            "nome",
                            removeAccentsAndUppercase(e.target.value)
                          )
                        }
                        onKeyPress={(e) => handleKeyPress(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>E-mail</Label>
                      <Input
                        placeholder="Digite aqui..."
                        value={search.email}
                        onChange={(e) =>
                          onChangeSearch("email", e.target.value)
                        }
                        maxLength="80"
                        onKeyPress={(e) => handleKeyPress(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup className="papel">
                      <Label>Status</Label>
                      <Select
                        first="Selecione"
                        onChange={(e) => onChangeSearch("status", e.value)}
                        options={statusUsers}
                        select={{ value: "id", label: "descricao" }}
                        value={{ value: search.status }}
                        isSearchable={false}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="mr-4"
                      style={{
                        width: "150px",
                        background: "var(--color-branco)",
                        borderColor: "var(--color-laranja)",
                        color: "var(--color-laranja)",
                        borderRadius: "7px",
                      }}
                      onClick={() => actions.cleanSearch()}
                    >
                      Limpar
                    </Button>
                    <Button
                      className="purpleButton mr-4"
                      style={{ width: "150px" }}
                      onClick={() => getAll()}
                    >
                      Buscar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th>
                        {column.render("Header")}
                        <span className="float-right">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BsIcons.BsArrowDown
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps({
                                    title: undefined,
                                  })
                                )}
                              />
                            ) : (
                              <BsIcons.BsArrowUp
                                id="OrdenarAsc"
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps({
                                    title: undefined,
                                  })
                                )}
                              />
                            )
                          ) : (
                            <BsIcons.BsArrowDownUp
                              {...column.getHeaderProps(
                                column.getSortByToggleProps({
                                  title: undefined,
                                })
                              )}
                            />
                          )}
                        </span>
                      </th>
                    ))}
                    <th style={{ textAlign: "center" }}>Ações</th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="text-center" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        if (cell.column.id === "cpf") {
                          const formattedValue = formatarDocumento(cell.value);
                          return (
                            <td {...cell.getCellProps()}>{formattedValue}</td>
                          );
                        }

                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                      <td style={{ textAlign: "center", width: "10%" }}>
                        <>
                          {permitions.criar && (
                            <BiIcons.BiEdit
                              style={{
                                fontSize: "1.2rem",
                                cursor: "pointer",
                                color: "#C4C7CA",
                                marginRight: "7px",
                              }}
                              onClick={() => handleUpdate(row.original.id)}
                            />
                          )}
                          <AiIcons.AiOutlineEye
                            style={{
                              fontSize: "1.2rem",
                              cursor: "pointer",
                              color: "#C4C7CA",
                              marginRight: "7px",
                            }}
                            onClick={() => seeRegister(row.original.id)}
                          />
                          {permitions.deletar && (
                            <BiIcons.BiTrash
                              style={{
                                fontSize: "1.2rem",
                                cursor: "pointer",
                                color: "#C4C7CA",
                                marginRight: "7px",
                              }}
                              onClick={() => handleDelete(row.original.id)}
                            />
                          )}
                          {permitions.deletar && !row.original.ativo && (
                            <BiIcons.BiCheckCircle
                              style={{
                                fontSize: "1.2rem",
                                cursor: "pointer",
                                color: "#C4C7CA",
                              }}
                              onClick={() => handleActivate(row.original.id)}
                            />
                          )}
                          {permitions.desbloquear && row.original.bloqueado && (
                            <CgLock
                              style={{
                                fontSize: "1.2rem",
                                cursor: "pointer",
                                color: "#C4C7CA",
                              }}
                              onClick={() => {
                                console.log("ID do item selecionado:", row.original.id);
                                setItem(row.original.id);
                                toggleModalUnblock();
                              }}
                            />
                          )}
                        </>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Row className="pt-4">
              <Col>
                <span style={{ color: "var(--color-cinza-medio)" }}>
                  Mostrando{" "}
                  {getAllItems.quantidade ? getAllItems.quantidade : "0"} de{" "}
                  {getAllItems.quantidadeTotal
                    ? getAllItems.quantidadeTotal
                    : "0"}
                </span>
              </Col>
              <Col md={8}>
                <Row className="float-right">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    initialPage={0}
                    pageCount={getAllItems.totalPaginas}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => handlePageClick(e)}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={initialPage ? 0 : selectedPage}
                  />
                  <Input
                    type="select"
                    className="pageSize"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                      handleInitialPage(Number(e.target.value));
                    }}
                  >
                    {[10, 25, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Input>
                </Row>
              </Col>
            </Row>
          </Col>
        </Card>
      </Col>
    </>
  );
};

export default Search;
