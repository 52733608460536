export const COLUMNS = [
    {
        Header: "Descrição",
        accessor: "descricao",
    },
    {
        Header: "Instituição Financeira",
        accessor: "banco.nome",
    },
    {
        Header: "Status",
        accessor: "status.descricao",
    }
]


