import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, Col, Form, Input, Label, Row } from 'reactstrap'
import { Select } from '../../../components/Inputs/Input'
import * as IoIcons from "react-icons/io"
import * as antContasReceberActions from "../../../store/modules/antecipacaoContasReceber/actions"
import * as antContasReceberController from "../../../controllers/antecipacaoContasReceberController"
import CardCollapse from '../components/CardCollapse'
import Loja from '../components/Loja'
import Produto from '../components/Produto'
import { removeAccentsAndUppercase } from "../../../util/masks";
import { notifyWarn } from '../../../components/message/message'

const Register = () => {

    const { item, instituicoes, produtos, lojas, seeRegister } = useSelector(state => state.antContasReceber)

    const [lojasDiff, setLojasDiff] = useState([])
    const [produtosDiff, setProdutosDiff] = useState([])
    const [produtoId, setProdutoId] = useState('0')
    const [lojaId, setLojaId] = useState('0')

    useEffect(() => {
        antContasReceberController.getInstituicoes()
    }, [])

    useEffect(() => {
        setLojasDiff(lojas.filter(o => !item.lojas.some(i => i.id === o.id)));
        setProdutosDiff(produtos.filter(o => !item.produtos.some(i => i.id === o.id)));
        if (item.bancoId !== '0') {
            antContasReceberController.getProdutos(item.bancoId)
            antContasReceberController.getFiliais(item.bancoId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.id])

    const addProduto = async () => {
        if(produtoId === '0'){
            return notifyWarn('Escolha um produto para adicionar')
        }
        if(item.id){
            let isSuccess = await antContasReceberController.putProduto(item.id, produtoId)
            if(isSuccess){
                let newProdutos = item.produtos
                 newProdutos.push({id: produtoId})
                antContasReceberActions.addProduto(newProdutos)
                setProdutoId('0')
            }
        }else{
            let newProdutos = item.produtos
            newProdutos.push({id: produtoId})
            antContasReceberActions.addProduto(newProdutos)
            setProdutoId('0')
        }
    }

    const removeProduto = async (produtoId, index) => {
        let isSuccess = false
        if (item.id && !produtosDiff.includes(produtoId)) {
            isSuccess = await antContasReceberController.removeProduto(item.id, produtoId)
        }else{
            isSuccess = true
        }
        if(isSuccess){
            antContasReceberActions.removeProduto(index)
        }
    }

    const addLoja = async () => {
        if(lojaId === '0'){
            return notifyWarn('Escolha uma loja para adicionar')
        }
        if(item.id){
            let isSuccess = await antContasReceberController.putLoja(item.id, lojaId)
            if(isSuccess){
                let newLojas = item.lojas
                 newLojas.push({id: lojaId})
                antContasReceberActions.addLoja(newLojas)
                setLojaId('0')
            }
        }else{
            let newLojas = item.lojas
            newLojas.push({id: lojaId})
            antContasReceberActions.addLoja(newLojas)
            setLojaId('0')
        }
    }

    const removeLoja = async (lojaId, index) => {
        let isSuccess = false 
        if (item.id && !lojasDiff.includes({ id: lojaId })) {
            isSuccess = await antContasReceberController.removeLoja(item.id, lojaId)
        }else{
            isSuccess = true
        }
        if(isSuccess){
            antContasReceberActions.removeLoja(index)
        }
    }

    const onChange = (value, field) => {
        antContasReceberActions.cleanInvalid(field)
        antContasReceberActions.item(field, value )
        if (field === 'bancoId') {
            antContasReceberActions.cleanProdutos()
            antContasReceberActions.cleanLojas()
            if (value !== "0") {
                antContasReceberController.getProdutos(value)
                antContasReceberController.getFiliais(value)
            }
        }
    }


    return (
        <>
            <Col>
                <Card className="radius pl-5 pr-5 pb-5 pt-4">
                    <Row>
                        <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <p
                                    style={{
                                        fontSize: "18px",
                                        color: 'var(--color-preto)'
                                    }}
                                >
                                    Preencha os campos para cadastrar um novo parâmetro de antecipação
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form>
                                <Row>
                                    <Col md={4}>
                                        <Label>
                                            Início Vigência*
                                        </Label>
                                        <Input
                                            type='date'
                                            value={item.inicioVigencia}
                                            max="9999-12-31"
                                            onChange={(e) => { onChange(removeAccentsAndUppercase(e.target.value), 'inicioVigencia') }}
                                            style={{ height: '35px' }}
                                            disabled={item.id ? true : seeRegister}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Label>
                                            Fim Vigência
                                        </Label>
                                        <Input
                                            type='date'
                                            max="9999-12-31"
                                            value={item.fimVigencia}
                                            onChange={(e) => { onChange(removeAccentsAndUppercase(e.target.value), 'fimVigencia') }}
                                            style={{ height: '35px' }}
                                            disabled={seeRegister ? true : item.hasDataFim}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Label>
                                            Instituição Financeira*
                                        </Label>
                                        <Select
                                            first="SELECIONE"
                                            disabled={item.id ? true : seeRegister}
                                            onChange={(e) => { onChange(e.value, 'bancoId') }}
                                            value={{ value: item.bancoId }}
                                            select={{ value: "id", label: "nome" }}
                                            options={instituicoes}
                                            isSearchable
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <CardCollapse
                                            title="Selecione o produto instituição para a parametrização"
                                        >
                                            <Row>
                                                <Col>
                                                    <Label>
                                                        Produto
                                                    </Label>
                                                    <Select
                                                    first="SELECIONE"
                                                    disabled={ seeRegister}
                                                    onChange={(e) => { setProdutoId(e.value) }}
                                                    value={{ value: produtoId }}
                                                    select={{ value: "id", label: "nome" }}
                                                    options={produtos}
                                                    isSearchable
                                                    />
                                                </Col>
                                                {!seeRegister && <Col className="p-4">
                                                    <IoIcons.IoIosAddCircleOutline
                                                        onClick={() => { addProduto() }}
                                                        style={{ fontSize: "1.5rem", color: "#ff894b", marginRight: "10px", cursor: "pointer" }}
                                                    />
                                                    Adicionar produto
                                                </Col>}
                                                </Row>
                                            {item.produtos.length >= 1 &&
                                                item.produtos.map((produto, i) => {
                                                    return (
                                                        <Row key={i} className="mt-2">
                                                            <Produto value={produto.id} options={produtos} index={i}  disabled={produto.id !== '0' ? true : seeRegister} />
                                                            {!seeRegister && <Col className="p-4">
                                                                <span >
                                                                    <IoIcons.IoMdRemoveCircleOutline
                                                                        onClick={async () => {
                                                                            removeProduto(produto.id, i)
                                                                        }}
                                                                        style={{ fontSize: "1.5rem", marginRight: "10px", color: "#ff894b" }}
                                                                    />
                                                                </span>
                                                            </Col>}
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </CardCollapse>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <CardCollapse
                                            title="Selecione a loja para a parametrização"
                                        >
                                             <Row>
                                                <Col>
                                                    <Label>
                                                        Loja
                                                    </Label>
                                                    <Select
                                                    first="SELECIONE"
                                                    disabled={ seeRegister}
                                                    onChange={(e) => { setLojaId(e.value) }}
                                                    value={{ value: lojaId }}
                                                    select={{ value: "id", label: "nome" }}
                                                    options={lojas}
                                                    isSearchable
                                                    />
                                                </Col>
                                                {!seeRegister && <Col className="p-4">
                                                    <IoIcons.IoIosAddCircleOutline
                                                        onClick={() => { addLoja() }}
                                                        style={{ fontSize: "1.5rem", color: "#ff894b", marginRight: "10px", cursor: "pointer" }}
                                                    />
                                                    Adicionar Loja
                                                </Col>}
                                                </Row>
                                            {
                                                item.lojas.map((loja, i) => {
                                                    return (
                                                        <Row key={i} className="mt-2">
                                                            <Loja value={loja.id} options={lojas} index={i} disabled={loja.id ? true : seeRegister} />
                                                            {!seeRegister && <Col className="p-4">
                                                                <span>
                                                                    <IoIcons.IoMdRemoveCircleOutline
                                                                        onClick={() => {
                                                                            removeLoja(loja.id, i)
                                                                        }}
                                                                        style={{ fontSize: "1.5rem", marginRight: "10px", color: "#ff894b" }}
                                                                    />
                                                                </span>
                                                            </Col>}
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </CardCollapse>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </>
    )
}

export default Register
