import { STORE_ID, TYPES } from "./types";

const INITIAL_STATE = {
  layoutBeingCreated: {
    descricao: "",
    tipoArquivoId: "0",
    bancoId: "0",
    nomeAba: "",
    isIndividual: false,
    isQuitacao: false,
    isNegativo: false,
    validaCpf: true,
    validaProduto: false,
    tipoComissaoBancoId: "0",
    layoutPlanilhaParametros: [],
  },
  layoutBeingEdited: {
    id: "",
    descricao: "",
    tipoArquivoId: "0",
    bancoId: "0",
    nomeAba: "",
    isIndividual: false,
    isQuitacao: false,
    isNegativo: false,
    validaCpf: false,
    validaProduto: false,
    tipoComissaoBancoId: "0",
    layoutPlanilhaParametros: [],
  },
  layoutBeingEditedInitialValueBackup: {
    id: "",
    descricao: "",
    tipoArquivoId: "0",
    bancoId: "0",
    nomeAba: "",
    isIndividual: false,
    isQuitacao: false,
    isNegativo: false,
    tipoComissaoBancoId: "0",
    layoutPlanilhaParametros: [],
  },
  layoutBeingVisualized: {
    id: "",
    descricao: "",
    tipoArquivoId: "0",
    bancoId: "0",
    nomeAba: "",
    isIndividual: false,
    isQuitacao: false,
    isNegativo: false,
    validaCpf: false,
    validaProduto: false,
    tipoComissaoBancoId: "0",
    layoutPlanilhaParametros: [],
  },
  layoutBeingEditedId: "",
  layoutBeingVisualizedId: "",
  isInEditMode: false,
  isInVisualizeMode: false,
  isModalDetalhamentoOpen: false,
  isModalStatusOpen: false,
  mapeamento: {
    descricao: "",
    campoModel: "",
    nomeColuna: "",
    layoutPlanilhaCamposEquivalenteId: "0",
    layoutPlanilhaCamposEquivalente: "",
    default: "",
    camposEquivalentesOpcionais: [],
    detalhes: [],
    status: [],
  },
  detalhamento: {
    id: null,
    valorCampo: "",
    aceitaVazio: false,
    tiposComissaoId: "0",
  },
  status: {
    id: null,
    descricao: "",
    aceitaVazio: false,
  },
  layoutStatus:{
  true:'Ativo',
  false:'Inativo'
  },
  layouts: [],
  instituicoesFinanceira: [],
  tiposArquivo: [],
  tiposComissao: [],
  layoutsCamposEquivalentes: [],
  allLayoutsCamposEquivalentes: [],
  activeTab: "Search",
  permitions: {
    criar: false,
    deletar: false,
    autorizar: false,
  },
};

export default function layoutParametrizacao(state = INITIAL_STATE, action) {
  switch (action.storeId) {
    case STORE_ID:
      switch (action.type) {
        case TYPES.SET_LAYOUT_BEING_CREATED:
          return {
            ...state,
            layoutBeingCreated: action.value,
          };
        case TYPES.CLEAR_LAYOUT_BEING_CREATED:
          return {
            ...state,
            layoutBeingCreated: INITIAL_STATE.layoutBeingCreated,
          };
        case TYPES.SET_LAYOUT_BEING_EDITED:
          return {
            ...state,
            layoutBeingEdited: action.value,
          };
        case TYPES.CLEAR_LAYOUT_BEING_EDITED:
          return {
            ...state,
            layoutBeingEdited: INITIAL_STATE.layoutBeingEdited,
          };
        case TYPES.SET_LAYOUT_BEING_VISUALIZED:
          return {
            ...state,
            layoutBeingVisualized: action.value,
          };
        case TYPES.CLEAR_LAYOUT_BEING_VISUALIZED:
          return {
            ...state,
            layoutBeingVisualized: INITIAL_STATE.layoutBeingVisualized,
          };
       
        case TYPES.SET_LAYOUT_BEING_EDITED_ID:
          return { ...state, layoutBeingEditedId: action.value };
        case TYPES.SET_LAYOUT_BEING_VISUALIZED_ID:
          return { ...state, layoutBeingVisualizedId: action.value };
        case TYPES.SET_IS_IN_EDIT_MODE:
          return { ...state, isInEditMode: action.value };
        case TYPES.SET_IS_IN_VISUALIZE_MODE:
          return { ...state, isInVisualizeMode: action.value };
        case TYPES.ADD_LAYOUT_PLANILHA_PARAMETROS:
          if (action.value.isInEditMode) {
            return {
              ...state,
              layoutBeingEdited: {
                ...state.layoutBeingEdited,
                layoutPlanilhaParametros: [
                  ...state.layoutBeingEdited.layoutPlanilhaParametros,
                  action.value.mapeamento,
                ],
              },
            };
          } else {
            return {
              ...state,
              layoutBeingCreated: {
                ...state.layoutBeingCreated,
                layoutPlanilhaParametros: [
                  ...state.layoutBeingCreated.layoutPlanilhaParametros,
                  action.value.mapeamento,
                ],
              },
            };
          }
        case TYPES.REMOVE_LAYOUT_PLANILHA_PARAMETROS:
          let layoutsPlanilha;

          if (action.value.isInEditMode) {
            layoutsPlanilha = [
              ...state.layoutBeingEdited.layoutPlanilhaParametros,
            ];
            layoutsPlanilha.splice(action.value.index, 1);
            return {
              ...state,
              layoutBeingEdited: {
                ...state.layoutBeingEdited,
                layoutPlanilhaParametros: layoutsPlanilha,
              },
            };
          } else {
            layoutsPlanilha = [
              ...state.layoutBeingCreated.layoutPlanilhaParametros,
            ];
            layoutsPlanilha.splice(action.value.index, 1);
            return {
              ...state,
              layoutBeingCreated: {
                ...state.layoutBeingCreated,
                layoutPlanilhaParametros: layoutsPlanilha,
              },
            };
          }
        case TYPES.CLEAR_MAPEAMENTO:
          return {
            ...state,
            mapeamento: INITIAL_STATE.mapeamento,
          };
        case TYPES.CLEAR_DETALHAMENTO:
          return {
            ...state,
            detalhamento: INITIAL_STATE.detalhamento,
          };
        case TYPES.CLEAR_STATUS:
          return {
            ...state,
            status: INITIAL_STATE.status,
          };
        case TYPES.SET_IS_MODAL_DETALHAMENTO_OPEN:
          return {
            ...state,
            isModalDetalhamentoOpen: action.value,
          };
        case TYPES.SET_IS_MODAL_STATUS_OPEN:
          return {
            ...state,
            isModalStatusOpen: action.value,
          };
        case TYPES.SET_LAYOUT_BEING_EDITED_INITIAL_VALUE_BACKUP:
          return {
            ...state,
            layoutBeingEditedInitialValueBackup: action.value,
          };

        case TYPES.SET_PERMITIONS:
          return { ...state, permitions: action.value };
        case TYPES.TIPOS_COMISSAO:
          return { ...state, tiposComissao: action.value };

        case TYPES.SET_MAPEAMENTO:
          return {
            ...state,
            mapeamento: { ...state.mapeamento, [action.campo]: action.value },
          };
        case TYPES.SET_DETALHAMENTO:
          return {
            ...state,
            detalhamento: {
              ...state.detalhamento,
              [action.campo]: action.value,
            },
          };
        case TYPES.SET_STATUS:
          return {
            ...state,
            status: { ...state.status, [action.campo]: action.value },
          };
        case TYPES.SET_LAYOUTS:
          return { ...state, layouts: action.value };
        case TYPES.SET_TIPOS_ARQUIVO:
          return { ...state, tiposArquivo: action.value };
        case TYPES.SET_INSTITUICOES:
          return { ...state, instituicoesFinanceira: action.value };
        case TYPES.SET_LAYOUTS_CAMPOS_EQUIVALENTES:
          return { ...state, layoutsCamposEquivalentes: action.value };
        case TYPES.SET_ALL_LAYOUTS_CAMPOS_EQUIVALENTES:
          return { ...state, allLayoutsCamposEquivalentes: action.value };
        case TYPES.CLEAN_LAYOUTS_CAMPOS_EQUIVALENTES:
          return {
            ...state,
            layoutsCamposEquivalentes: INITIAL_STATE.layoutsCamposEquivalentes,
          };
        case TYPES.SET_ACTIVE_TAB:
          return { ...state, activeTab: action.value };
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
}
