
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';

import { green } from '@material-ui/core/colors';

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


export const CheckBoxChangePass = (props) => {

    return (
        <>
            <FormControlLabel
                disabled={props.isDisabled}
                control={
                    <GreenCheckbox
                        checked={props.checked}
                        onChange={props.onChange}
                        name="checkedG" />}
                label={props.label}
            />

        </>
    )
}