import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Input, Label, Modal, ModalHeader, Row, Form, FormGroup, ModalBody } from "reactstrap";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { useSelector } from "react-redux";
import { InputCpfCnpj, Select } from "../../../components/Inputs/Input";
import { COLUMNS } from "../constants/columns";
import ReactPaginate from "react-paginate";
import * as documentacaoActions from "../../../store/modules/personas/documentacao_store/actions";
import * as  personasController from "../../../controllers/personasController/personasController";
import * as contaCorrenteController from "../../../controllers/personasController/contaCorrenteController";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as informacoesActions from "../../../store/modules/personas/informacoes_store/actions";
import * as  personasActions from "../../../store/modules/personas/personas_store/actions";
import * as papeisActions from "../../../store/modules/personas/papeis_store/actions";
import * as enderecosActions from "../../../store/modules/personas/endereco_store/actions";
import * as contatosActions from "../../../store/modules/personas/contato_store/actions";
import * as contaActions from "../../../store/modules/personas/conta_corrente_store/actions";
import * as parametrosActions from "../../../store/modules/personas/parametros_store/actions";

const Search = ({ selectedPage, setSelectedPage }) => {
    const { items, search, estados, getAllItems, papeis, permitions, canais, tiposLoja } = useSelector(state => state.personas);

    const columns = useMemo(() => COLUMNS, []);
    const data = items;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const { pageSize } = state;
    const [itemSelected, setItem] = useState(null);
    const [modalDelete, setModalDelete] = useState(false);
    const [initialPage, setInitialPage] = useState(false);

    useEffect(() => {
        personasController.getEstados();
        personasController.getCanais();
        personasController.getTiposLoja();
    }, []);

    useEffect(() => {
        personasController.getPapeis();
    }, []);

    useEffect(() => {
        if (getAllItems.tamanhoPagina) {
            setPageSize(getAllItems.tamanhoPagina);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllItems])

    function handlePageClick(e) {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        if (search.razaoSocialNome) {
            personasController.getComNome(pageSelected, pageSize, search)
        } else {
            personasController.get(pageSelected, pageSize, search);
        }
    }

    async function handleUpdate(id) {
        await personasController.getById(id);
        await contaCorrenteController.buscaMatriculas(id);
        papeisActions.clearFilterPessoa()
        papeisActions.clearFilterPessoaApplied()
        personasActions.setActiveTab("Register");
    }
    function seeRegister(id) {
        informacoesActions.setSeeRegister(true);
        enderecosActions.setSeeRegister(true);
        contatosActions.setSeeRegister(true);
        papeisActions.setSeeRegister(true);
        documentacaoActions.setSeeRegister(true);
        contaActions.setSeeRegister(true);
        parametrosActions.setSeeRegister(true);
        handleUpdate(id);
    }

    function handleDelete(id) {
        setItem(id);
        toggleModalDelete();
    }

    function toggleModalDelete() {
        setModalDelete(!modalDelete);
    }

    function deleteItem() {
        personasActions.cleanSearch();
        personasController.remove(itemSelected, selectedPage, pageSize, search);
        toggleModalDelete();
    }

    function onChangeSearch(value, field) {
        personasActions.setSearch({ ...search, [field]: value });
    }

    function getAll() {
        if (search.razaoSocialNome) {
            personasController.getComNome(0, pageSize, search);
        } else {
            personasController.get(0, pageSize, search);
        }
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }

    function handleInitialPage(e) {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
        if (search.razaoSocialNome) {
            personasController.getComNome(0, e, search);
        } else {
            personasController.get(0, e, search);
        }
    }

    function onChangeUF(value, label) {
        personasActions.setSearch({ ...search, "ufId": value, "uf": label });
    }

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            getAll();
        }
    }

    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalDelete}
                toggle={toggleModalDelete}
                backdrop={false}
            >
                <ModalHeader toggle={toggleModalDelete} style={{ height: '10px', border: 'none' }} />
                <ModalBody>
                    <p style={{ fontSize: '18px', color: 'var(--color-preto)' }}>Tem certeza que deseja <strong style={{ fontSize: '17px' }}>remover</strong> esse item?</p>
                </ModalBody>
                <div className="m-3">
                    <Button onClick={() => toggleModalDelete()} color="danger" style={{ width: "100px", marginRight: "15px", border: 'none' }}> Cancelar </Button>
                    <Button onClick={() => deleteItem()} style={{ width: "100px", marginLeft: "15px", background: 'var(--color-verde)', border: 'none' }}> Confirmar </Button>
                </div>
            </Modal>

            <Col>
                <Card className="pt-3 pb-2 mb-5">
                    <Col>
                        <div className="pb-3">
                            <Label>Pesquisar</Label>
                            <p></p>
                            <Form>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="cpfCnpj">CPF / CNPJ</Label>
                                            <InputCpfCnpj
                                                type="text"
                                                id="cpfCnpj"
                                                tipo={search.cpfCnpj.length > 0 ? (search.cpfCnpj.length <= 14 ? 'cpf' : 'cnpj') : ""}
                                                placeholder="Digite aqui..."
                                                value={search.cpfCnpj}
                                                onChange={e => onChangeSearch(e.target.value, "cpfCnpj")}
                                                maxLength="18"
                                                onKeyPress={e => handleKeyPress(e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Nome / Razão Social</Label>
                                            <Input
                                                type="text"
                                                id="razaoSocial"
                                                placeholder="Digite aqui.."
                                                value={search.razaoSocialNome}
                                                onChange={e => onChangeSearch(e.target.value, "razaoSocialNome")}
                                                onKeyPress={e => handleKeyPress(e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup >
                                            <Label>Nascimento / Criação</Label>
                                            <Input
                                                type="date"
                                                name="select"
                                                value={search.criacaoNascimento}
                                                max="9999-12-31"
                                                onChange={e => onChangeSearch(e.target.value, "criacaoNascimento")}
                                                maxLength="6"
                                                onKeyPress={e => handleKeyPress(e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Usuário da Instituição</Label>
                                            <Input
                                                type="text"
                                                placeholder="Digite aqui..."
                                                value={search.usuarioInstituicao}
                                                onChange={e => onChangeSearch(e.target.value, "usuarioInstituicao")}
                                                onKeyPress={e => handleKeyPress(e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Cidade</Label>
                                            <Input
                                                type="text"
                                                id="cidade"
                                                placeholder="Digite aqui..."
                                                value={search.cidade}
                                                onChange={e => onChangeSearch(e.target.value, "cidade")}
                                                onKeyPress={e => handleKeyPress(e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label>UF</Label>
                                            <Select
                                                first="UF"
                                                onChange={e => onChangeUF(e.value, e.label)}
                                                options={estados}
                                                select={{ value: "id", label: 'sigla' }}
                                                value={{ value: search.ufId }}
                                                isSearchable={true}
                                            />
                                        </FormGroup>

                                    </Col>
                                    <Col md={3}>
                                        <FormGroup className="papel">
                                            <Label>Relacionamento</Label>
                                            <Select
                                                first="Selecione"
                                                onChange={e => onChangeSearch(e.value, 'codigoPapel')}
                                                options={papeis}
                                                select={{ value: "codigoPapel", label: "descricao" }}
                                                value={{ value: search.codigoPapel }}
                                                isSearchable={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup className="papel">
                                            <Label>Ramal</Label>
                                            <Input
                                                type="text"
                                                id="ramal"
                                                placeholder="Digite aqui..."
                                                value={search.ramal}
                                                onChange={e => onChangeSearch(e.target.value, "ramal")}
                                                onKeyPress={e => handleKeyPress(e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup className="papel">
                                            <Label>Canal</Label>
                                            <Select
                                                first="Selecione"
                                                onChange={e => onChangeSearch(e.value, 'canalId')}
                                                options={canais}
                                                select={{ value: "id", label: "nome" }}
                                                value={{ value: search.canalId }}
                                                isSearchable={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup className="papel">
                                            <Label>Tipo loja</Label>
                                            <Select
                                                first="Selecione"
                                                onChange={e => onChangeSearch(e.value, 'tipoLojaId')}
                                                options={tiposLoja}
                                                select={{ value: "id", label: "nome" }}
                                                value={{ value: search.tipoLojaId }}
                                                isSearchable={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup className="papel">
                                            <Label>Código de loja da instituição</Label>
                                            <Input
                                                type="text"
                                                placeholder="Digite aqui..."
                                                value={search.codigoLojaBanco}
                                                onChange={(e) =>
                                                    onChangeSearch(e.target.value, 'codigoLojaBanco')
                                                }
                                                onKeyPress={e => handleKeyPress(e)}
                                                maxLength="150"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Button className="mr-4"
                                            style={{
                                                width: "150px",
                                                background: "var(--color-branco)",
                                                borderColor: "var(--color-laranja)",
                                                color: "var(--color-laranja)",
                                                borderRadius: "7px"
                                            }}
                                            onClick={() => personasActions.cleanAllSearch()}
                                        >
                                            Limpar
                                        </Button>
                                        <Button
                                            className="purpleButton"
                                            style={{ width: "150px" }}
                                            onClick={() => getAll()}
                                        >
                                            Buscar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th>
                                                {column.render("Header")}
                                                <span className="float-right">
                                                    {column.isSorted ?
                                                        (column.isSortedDesc ?
                                                            <BsIcons.BsArrowDown
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />
                                                            :
                                                            <BsIcons.BsArrowUp
                                                                id="OrdenarAsc"
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />)
                                                        :
                                                        <BsIcons.BsArrowDownUp
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />}
                                                </span>
                                            </th>
                                        ))}
                                        <th style={{ textAlign: "center" }}>
                                            Ações
                                        </th>
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr className="text-center" {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                            })}
                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                <>
                                                    {
                                                        permitions.criar && (
                                                            <BiIcons.BiEdit
                                                                style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}
                                                                onClick={() => handleUpdate(row.original.id)}
                                                            />
                                                        )
                                                    }
                                                    <AiIcons.AiOutlineEye
                                                        style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}
                                                        onClick={() => seeRegister(row.original.id)}
                                                    />
                                                    {
                                                        permitions.deletar && (
                                                            <BiIcons.BiTrash
                                                                style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA" }}
                                                                onClick={() => handleDelete(row.original.id)}
                                                            />
                                                        )
                                                    }
                                                </>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Row className="pt-4">
                            <Col>
                                <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {getAllItems.quantidade !== undefined ? getAllItems.quantidade : "0"} de {getAllItems.quantidadeTotal !== undefined ? getAllItems.quantidadeTotal : "0"}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={getAllItems.totalPaginas}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={e => handlePageClick(e)}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { setPageSize(Number(e.target.value)); handleInitialPage(Number(e.target.value)) }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Card >
            </Col >
        </>
    );
}


export default Search;
