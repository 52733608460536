import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as statusActions from "../store/modules/statusInstituicao/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("status-propostas-banco");
    setLoading(false)

    if (!data.success) {
        return;
    }

    statusActions.getItems(data.content);
}

export async function getStatusRc() {

    const data = await api.get("status-propostas");

    if (!data.success) {
        return;
    }

    statusActions.setStatusRc(data.content);
}

export async function getBancos() {

    const data = await api.get("bancos");

    if (!data.success) {
        return;
    }

    statusActions.setBancos(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`status-propostas-banco/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    const dataUpdate = {
        descricao: data.content.descricao,
        bancoId: data.content.banco.id,
        statusRcId: data.content.status.id,
        id: data.content.id
    }

    statusActions.copy(dataUpdate);
    statusActions.item(dataUpdate);
}

export async function create(item) {

    if (item.descricao.trim() === "") {
        statusActions.setInvalid("descricao");
        return notifyWarn("Descrição do status não pode estar em branco");
    }

    if (item.bancoId === "0") {
        statusActions.setInvalid("bancoId");
        return notifyWarn("Selecione uma instituição");
    }

    if (item.statusRcId === "0") {
        statusActions.setInvalid("statusRcId")
        return notifyWarn("Selecione um status");
    }

    const body = {
        descricao: item.descricao,
        bancoId: item.bancoId,
        statusId: item.statusRcId
    }

    setLoading(true)
    const data = await api.post("status-propostas-banco", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Status cadastrado");
    statusActions.cleanRegister();
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`status-propostas-banco/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Status instituição removido");
    get();
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (item.descricao.trim() === "") {
        statusActions.setInvalid("descricao");
        return notifyWarn("Descrição do status instituição não pode estar em branco");
    }

    if (item.bancoId === "0") {
        statusActions.setInvalid("bancoId");
        return notifyWarn("Selecione uma instituição financeira");
    }

    if (item.statusRcId === "0") {
        statusActions.setInvalid("statusRcId")
        return notifyWarn("Selecione um status ribercred");
    }

    const body = {
        descricao: item.descricao,
        bancoId: item.bancoId,
        statusId: item.statusRcId
    }

    setLoading(true)
    const data = await api.put(`status-propostas-banco/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Status instituição atualizado");
    statusActions.cleanRegister();
    statusActions.setActiveTab("Search");
    get();
}
