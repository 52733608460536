import api from "../util/api";
import * as antContasReceberActions from "../store/modules/antecipacaoContasReceber/actions";
import { notifySuccess, notifyWarn } from "../components/message/message";
import { maskDate } from "../util/masks";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get(search, page, pageSize) {
    var url = `antecipacoes?pagina=${page === 0 ? 1 : page}&registros=${pageSize}`

    if (search.bancoId !== "0") url += `&BancoId=${search.bancoId}`
    if (search.produtoId !== "0") url += `&ProdutoId=${search.produtoId}`
    if (search.lojaId !== "0") url += `&LojaId=${search.lojaId}`

    setLoading(true)
    const data = await api.get(url);
    setLoading(false)

    if (!data.success) {
        return;
    }

    data.content.registros.forEach((data) =>{
        data.dataFimVigencia = new Date(Date.parse(data.dataFimVigencia));
        data.dataInicioVigencia = new Date(Date.parse(data.dataInicioVigencia));
    })

    antContasReceberActions.getItems(data.content.registros);
    antContasReceberActions.setPageSelected(page);
    antContasReceberActions.setQuantidadeTotal(data.content.quantidadeTotal)
    antContasReceberActions.setTotalPaginas(data.content.totalPaginas)
}

export async function getById(id) {
    setLoading(true)
    const data = await api.get(`antecipacoes/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    const dataFormated = {
        id: data.content.id,
        bancoId: data.content.banco.id,
        produtos: data.content.produtos,
        lojas: data.content.lojas,
        inicioVigencia: maskDate(data.content.dataInicioVigencia, 'YYYY-MM-DD'),
        fimVigencia: maskDate(data.content.dataFimVigencia, 'YYYY-MM-DD'),
        hasDataFim: data.content.dataFimVigencia ? true : false
    }

    antContasReceberActions.setItem(dataFormated);
    antContasReceberActions.copy(dataFormated);
}

export async function getInstituicoesPesquisa() {
    const data = await api.get(`antecipacoes/bancos`);

    if (!data.success) {
        return;
    }

    antContasReceberActions.setInstituicoesPesquisa(data.content)
}

export async function getInstituicoes() {
    const data = await api.get(`bancos`);

    if (!data.success) {
        return;
    }

    const bancos = []

    data.content.forEach(banco => {
        bancos.push({
            nome: banco.nome,
            id: banco.id
        })
    })

    antContasReceberActions.setInstituicoes(bancos)
}

export async function getProdutos(id) {
    setLoading(true)
    const data = await api.get(`Produtos/bancos/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    antContasReceberActions.setProdutos(data.content)
}

export async function getFiliais(id) {
    setLoading(true)
    const data = await api.get(`lojas/bancos/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    antContasReceberActions.setLojas(data.content);
}

export async function getProdutosPesquisa() {

    const data = await api.get(`antecipacoes/produtos`);

    if (!data.success) {
        return;
    }

    antContasReceberActions.setProdutosPesquisa(data.content)
}

export async function getLojasPesquisa() {

    const data = await api.get(`antecipacoes/lojas`);

    if (!data.success) {
        return;
    }

    antContasReceberActions.setLojasPesquisa(data.content)
}

export async function create(item) {

    if (item.bancoId === "0") {
        antContasReceberActions.setInvalid("bancoId")
        return notifyWarn("Selecione uma instituição")
    }

    if (item.inicioVigencia === "") {
        antContasReceberActions.setInvalid("inicioVigencia")
        return notifyWarn("Selecione a data de início vigência")
    }

    if (item.produtos.length === 0) {
        antContasReceberActions.setInvalid("produtos")
        return notifyWarn("É necessário ter pelo menos um produto vínculado")
    }

    item.produtos.forEach((produto) => {
        if (produto.id === "0") {
            return notifyWarn("Selecione um produto")
        }
    })

    if (item.lojas.length === 0) {
        antContasReceberActions.setInvalid("lojas")
        return notifyWarn("É necessário ter pelo menos uma loja vínculada")
    }

    item.lojas.forEach((loja) => {
        if (loja.id === "0") {
            return notifyWarn("Selecione uma loja")
        }
    })

    const body = {
        dataInicioVigencia: item.inicioVigencia,
        dataFimVigencia: item.fimVigencia === '' ? null : item.fimVigencia,
        bancoId: item.bancoId,
        produtos: item.produtos,
        lojas: item.lojas
    }

    setLoading(true)
    const data = await api.post(`antecipacoes`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Antecipação cadastrada");
    antContasReceberActions.cleanRegister();
    antContasReceberActions.setActiveTab("Search");
    get({
        bancoId: "0",
        produtoId: "0",
        lojaId: "0",
    }, 1, 10)
}

export async function update(item, copy) {

    if (item.bancoId === "0") {
        antContasReceberActions.setInvalid("bancoId")
        return notifyWarn("Selecione uma instituição")
    }

    if (item.inicioVigencia === "") {
        antContasReceberActions.setInvalid("inicioVigencia")
        return notifyWarn("Selecione a data de início vigência")
    }

    if (item.fimVigencia === "") {
        antContasReceberActions.setInvalid("fimVigencia")
        return notifyWarn("Selecione a data de fim de vigência")
    }

    if (item.produtos.length === 0) {
        antContasReceberActions.setInvalid("produtos")
        return notifyWarn("É necessário ter pelo menos um produto vínculado")
    }

    item.produtos.forEach((produto) => {
        if (produto.id === "0") {
            return notifyWarn("Selecione um produto")
        }
    })

    if (item.lojas.length === 0) {
        antContasReceberActions.setInvalid("lojas")
        return notifyWarn("É necessário ter pelo menos uma loja vínculada")
    }

    item.lojas.forEach((loja) => {
        if (loja.id === "0") {
            return notifyWarn("Selecione uma loja")
        }
    })

    let produtosDiff = item.produtos
        .filter(x => !copy.produtos.includes(x))
        .concat(copy.produtos.filter(x => !item.produtos.includes(x)));

    let lojasDiff = item.lojas
        .filter(x => !copy.lojas.includes(x))
        .concat(copy.lojas.filter(x => !item.lojas.includes(x)));

    const body = {
        dataInicioVigencia: item.inicioVigencia,
        dataFimVigencia: item.fimVigencia === '' ? null : item.fimVigencia,
        bancoId: item.bancoId,
        produtos: produtosDiff,
        lojas: lojasDiff
    }

    setLoading(true)
    const data = await api.put(`antecipacoes/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Antecipação atualizada");
    antContasReceberActions.cleanRegister();
    antContasReceberActions.setActiveTab("Search");
    get({
        bancoId: "0",
        produtoId: "0",
        lojaId: "0",
    }, 1, 10);
}

export async function remove(id) {
    setLoading(true)
    const data = await api.delete(`antecipacoes/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Antecipação removida");
    get({
        bancoId: "0",
        produtoId: "0",
        lojaId: "0",
    }, 1, 10);
}

export async function removeLoja(id, idLoja) {
    setLoading(true)
    const data = await api.delete(`antecipacoes/${id}/lojas/${idLoja}`);
    setLoading(false)

    if (!data.success) {
        return false;
    }

    notifySuccess("Loja removida");
    return true
}

export async function putLoja(id, idLoja) {
    setLoading(true)
    const data = await api.put(`antecipacoes/${id}/lojas/${idLoja}`);
    setLoading(false)

    if (!data.success) {
        return false;
    }

    notifySuccess("Loja adicionada com sucesso");
    return true
}

export async function removeProduto(id, idProduto) {
    setLoading(true)
    const data = await api.delete(`antecipacoes/${id}/produtos/${idProduto}`);
    setLoading(false)

    if (!data.success) {
        return false;
    }

    notifySuccess("Produto removido");
    return true
}
export async function putProduto(id, idProduto) {
    setLoading(true)
    const data = await api.put(`antecipacoes/${id}/produtos/${idProduto}`);
    setLoading(false)

    if (!data.success) {
        return false;
    }

    notifySuccess("Produto adicionado com sucesso");
    return true
}

