import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';

import * as controller from '../../../../controllers/dashboardController/receitaController'

import { maskMoeda } from '../../../../util/masks';

import './style.css'
export default function Table2({data}){
    return(
        <table style={{maxWidth: '100%', marginTop: '40px'}}>
            <thead style={{maxWidth: '100%'}}>
                <tr style={{maxWidth: '100%'}}>
                    <th className='colunaReceita' style={{width: '30%'}}>Produto</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Receita Total</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Pago</th>
                    <th className='colunaReceita' style={{width: '7%'}}>À Vista</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Taxa manutenção diferida</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Taxa manutenção antecipada</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Rotativo</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Ativação</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Diferido turbinado</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Bonus</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Share</th>
                </tr>
            </thead>
            <tbody style={{maxWidth: '100%'}}>
                {data.map((e, i)=>{
                    return(
                       <FirstLevelTableRow data={e} index={i}/>
                    )
                })}
            </tbody>
        </table>
    )
}

function FirstLevelTableRow({data,index}){

    const { filtros, tabelaProd} = useSelector(state => state.dashboardReceita);

    const [isTipoOperacaoActive, setIsTipoOperacaoActive] = useState(false)

    function handleOnClick(){
        if(isTipoOperacaoActive === false){
            var isSuccessed = controller.getTabelaReceitaTiposOperacoes(filtros, data.id, tabelaProd)
            isSuccessed && setIsTipoOperacaoActive(!isTipoOperacaoActive)
        }else{
            setIsTipoOperacaoActive(!isTipoOperacaoActive)
        }
    }

    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen'>
                    <div style={{display: 'flex'}}>
                        <div className='button' onClick={(e) => handleOnClick()}>{!isTipoOperacaoActive ? '+' : '-'}</div>
                        {data.nome}
                    </div>
                </td>
                {data.tiposComissaoReceita.map((e)=>{
                    return(
                        <td className={e.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}>
                            <div>
                            <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {`(${e.quantidade})`} {e.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> }
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                    {`R$${maskMoeda(e.valor.toFixed(2))}`}
                                </p> 
                            </div>
                        </td>
                    )
                })}
            </tr>
            {isTipoOperacaoActive &&
            data.tiposOperacao.map((e, i) =>{
                return(
                    <SecondLevelTableRow data={e} index={i} produtoId={data.id}/>
                )
            })
            }
        </>
    )
}


function SecondLevelTableRow({data,index, produtoId}){
    const { filtros, tabelaProd} = useSelector(state => state.dashboardReceita);

    const [isLojaActive, setIsLojaActive] = useState(false)

    var elemento = {tipoOperacaoId: data.id, produtoId: produtoId}

    function handleOnClick(){
        if(isLojaActive === false){
            var isSuccessed = controller.getTabelaReceita2Lojas(filtros, elemento, tabelaProd)
            isSuccessed && setIsLojaActive(!isLojaActive)
        }else{
            setIsLojaActive(!isLojaActive)
        }
    }

    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen'>
                    <div style={{display: 'flex', marginLeft: '30px'}}>
                        <div className='button' onClick={(e) => handleOnClick()}>{!isLojaActive ? '+' : '-'}</div>
                        {data.nome}
                    </div>
                </td>
                {data.tiposComissaoReceita.map((e)=>{
                    return(
                        <td className={e.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}>
                            <div>
                            <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {`(${e.quantidade})`} {e.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> }
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                    {`R$${maskMoeda(e.valor.toFixed(2))}`}
                                </p> 
                            </div>
                        </td>
                    )
                })}
            </tr>
            {isLojaActive &&
            data.lojas.map((e, i) =>{
                return(
                    <ThirdLevelTableRow data={e} index={i} e={elemento}/>
                )
            })
            }
        </>
    )
}

function ThirdLevelTableRow({data,index, e}){
    const { filtros, tabelaProd} = useSelector(state => state.dashboardReceita);

    const [isConsultoresActive, setIsConsultoresActive] = useState(false)

    var elemento = {tipoOperacaoId: e.tipoOperacaoId, produtoId: e.produtoId, lojaId : data.id}

    function handleOnClick(){
        if(isConsultoresActive === false){
            var isSuccessed = controller.getTabelaReceita2Consultores(filtros, elemento, tabelaProd)
            isSuccessed && setIsConsultoresActive(!isConsultoresActive)
        }else{
            setIsConsultoresActive(!isConsultoresActive)
        }
    }

    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen'>
                    <div style={{display: 'flex', marginLeft: '60px'}}>
                        <div className='button' onClick={(e) => handleOnClick()}>{!isConsultoresActive ? '+' : '-'}</div>
                        {data.nome}
                    </div>
                </td>
                {data.tiposComissaoReceita.map((e)=>{
                    return(
                        <td className={e.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}>
                            <div>
                            <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {`(${e.quantidade})`} {e.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> }
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                    {`R$${maskMoeda(e.valor.toFixed(2))}`}
                                </p> 
                            </div>
                        </td>
                    )
                })}
            </tr>
            {isConsultoresActive &&
            data.consultores.map((e, i) =>{
                return(
                    <FourthLevelTableRow data={e} index={i}/>
                )
            })
            }
        </>
    )
}

function FourthLevelTableRow({data,index}){
    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen'>
                    <div style={{display: 'flex', marginLeft: '90px'}}>
                        {data.nome}
                    </div>
                </td>
                {data.tiposComissaoReceita.map((e)=>{
                    return(
                        <td className={e.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}>
                            <div>
                            <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {`(${e.quantidade})`} {e.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> }
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                    {`R$${maskMoeda(e.valor.toFixed(2))}`}
                                </p> 
                            </div>
                        </td>
                    )
                })}
            </tr>
        </>
    )
}