const STORE_ID = 'AGRUPAMENTO_PROPOSTAS_CENTRO_CUSTO';

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    CLEAN_SEARCH: 'CLEAN_SEARCH',
    CLEAN_REGISTER: 'CLEAN_REGISTER',
    SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
    SET_ITENS: 'SET_ITENS',
    SET_SEARCH: 'SET_SEARCH',
}

export { STORE_ID, TYPES }