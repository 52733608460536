import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
  data = { ...data, storeId: STORE_ID };
  store.dispatch(data);
}

export function setLayoutBeingCreated(value) {
  dispatch({
    type: TYPES.SET_LAYOUT_BEING_CREATED,
    value,
  });
}

export function clearLayoutBeingCreated() {
  dispatch({
    type: TYPES.CLEAR_LAYOUT_BEING_CREATED,
  });
}

export function setLayoutBeingEdited(value) {
  dispatch({
    type: TYPES.SET_LAYOUT_BEING_EDITED,
    value,
  });
}

export function setLayoutBeingEditedInitialValueBackup(value) {
  dispatch({
    type: TYPES.SET_LAYOUT_BEING_EDITED_INITIAL_VALUE_BACKUP,
    value,
  });
}

export function clearLayoutBeingEdited() {
  dispatch({
    type: TYPES.CLEAR_LAYOUT_BEING_EDITED,
  });
}

export function setLayoutBeingVisualized(value) {
  dispatch({
    type: TYPES.SET_LAYOUT_BEING_VISUALIZED,
    value,
  });
}

export function clearLayoutBeingVisualized() {
  dispatch({
    type: TYPES.CLEAR_LAYOUT_BEING_VISUALIZED,
  });
}

export function setLayoutBeingEditedId(value) {
  dispatch({
    type: TYPES.SET_LAYOUT_BEING_EDITED_ID,
    value,
  });
}

export function setLayoutBeingVisualizedId(value) {
  dispatch({
    type: TYPES.SET_LAYOUT_BEING_VISUALIZED_ID,
    value,
  });
}

export function setIsInEditMode(value) {
  dispatch({
    type: TYPES.SET_IS_IN_EDIT_MODE,
    value,
  });
}

export function setIsInVisualizeMode(value) {
  dispatch({
    type: TYPES.SET_IS_IN_VISUALIZE_MODE,
    value,
  });
}

export function clearMapeamento() {
  dispatch({
    type: TYPES.CLEAR_MAPEAMENTO,
  });
}

export function clearDetalhamento() {
  dispatch({
    type: TYPES.CLEAR_DETALHAMENTO,
  });
}

export function clearStatus() {
  dispatch({
    type: TYPES.CLEAR_STATUS,
  });
}

export function setIsModalDetalhamentoOpen(value) {
  dispatch({
    type: TYPES.SET_IS_MODAL_DETALHAMENTO_OPEN,
    value,
  });
}

export function setIsModalStatusOpen(value) {
  dispatch({
    type: TYPES.SET_IS_MODAL_STATUS_OPEN,
    value,
  });
}

export function setPermitions(value) {
  dispatch({
    type: TYPES.SET_PERMITIONS,
    value,
  });
}

export function setTiposComissao(value) {
  dispatch({
    type: TYPES.TIPOS_COMISSAO,
    value,
  });
}

export function setLayoutParametros(campo, value) {
  dispatch({
    type: TYPES.SET_LAYOUT_PARAMETROS,
    value,
  });
}

export function setMapeamento(campo, value) {
  dispatch({
    type: TYPES.SET_MAPEAMENTO,
    campo,
    value,
  });
}

export function setDetalhamento(campo, value) {
  dispatch({
    type: TYPES.SET_DETALHAMENTO,
    campo,
    value,
  });
}

export function setStatus(campo, value) {
  dispatch({
    type: TYPES.SET_STATUS,
    campo,
    value,
  });
}

export function setLayouts(value) {
  dispatch({
    type: TYPES.SET_LAYOUTS,
    value,
  });
}

export function setTipoArquivos(value) {
  dispatch({
    type: TYPES.SET_TIPOS_ARQUIVO,
    value,
  });
}

export function setInstituicoes(value) {
  dispatch({
    type: TYPES.SET_INSTITUICOES,
    value,
  });
}

export function setLayoutsCamposEquivalentes(value) {
  dispatch({
    type: TYPES.SET_LAYOUTS_CAMPOS_EQUIVALENTES,
    value,
  });
}

export function setAllLayoutsCamposEquivalentes(value) {
  dispatch({
    type: TYPES.SET_ALL_LAYOUTS_CAMPOS_EQUIVALENTES,
    value,
  });
}

export function cleanLayoutsCamposEquivalentes() {
  dispatch({
    type: TYPES.CLEAN_LAYOUTS_CAMPOS_EQUIVALENTES,
  });
}

export function setActiveTab(value) {
  dispatch({
    type: TYPES.SET_ACTIVE_TAB,
    value,
  });
}

export function addLayoutPlanilhaParametros(value) {
  dispatch({
    type: TYPES.ADD_LAYOUT_PLANILHA_PARAMETROS,
    value,
  });
}

export function removeLayoutPlanilhaParametros(value) {
  dispatch({
    type: TYPES.REMOVE_LAYOUT_PLANILHA_PARAMETROS,
    value,
  });
}


