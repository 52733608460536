const STORE_ID = "STATUS_INST";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    STATUS: "STATUS",
    SET_STATUS_RC_ALL: "SET_STATUS_RC_ALL",
    STATUS_INST: "STATUS_INST",
    STATUS_COPY: "STATUS_COPY",
    INVALID_STATUS: "INVALID_STATUS",
    SET_BANCOS: "SET_BANCOS",
    CLEAN_INVALID_STATUS: "CLEAN_INVALID_STATUS",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE"
}

export { STORE_ID, TYPES }