import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as docActions from "../store/modules/tipoDocumento/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("tipoDocumento");
    setLoading(false)

    if (!data.success) {
        return;
    }

    docActions.items(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`tipoDocumento/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    docActions.copy(data.content);
    docActions.item(data.content);
}

export async function create(item) {

    if (item.descricao.trim() === "") {
        docActions.setInvalid("descricao");
        return notifyWarn("Descrição do tipo de documento não pode estar em branco");
    }

    const body = {
        descricao: item.descricao
    }

    setLoading(true)
    const data = await api.post("tipoDocumento", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Documento cadastrado");
    docActions.cleanRegister();
    get();
}

export async function update(item, copy) {

    if (item.descricao === copy.descricao) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (item.descricao === "") {
        docActions.setInvalid("descricao");
        return notifyWarn("Descrição do tipo de documento não pode estar em branco");
    }

    const body = {
        descricao: item.descricao
    }

    setLoading(true)
    const data = await api.put(`tipoDocumento/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Documento atualizado");
    docActions.cleanRegister();
    docActions.setActiveTab("Search");
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`tipoDocumento/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Documento removido");
    get();
}
