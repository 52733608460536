import React from 'react';

import logo from '../../assets/images/logo.svg'

const Home = () => {
    return (
        <div style={{display: 'flex',flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '90vh'}}>
            <img src={logo} alt="logo" style={{width: '10%', marginBottom: '5%'}} />
            <h3>Bem-vindo ao sistema satélite</h3>
        </div>
    );
};

export default Home;
