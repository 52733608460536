import { STORE_ID, TYPES } from './types'

const INITIAL_STATE = {
    tab: '1',
    search: {
        descricao: '',
        canalVendaId: '0',
        tipoParceriaId: '0',
        convenioId: '0',
        bancoId: '0',
        tipoOperacaoId: '0',
        periodo: '',
        codigoSuperacao: '',
        codigoProdutividade: '',
    },
    item: {
        id: '0',
        periodo: '',
        periodoInicio: '',
        periodoFim: '',
        dataFinal: '',
        diaPagamento: '0',
        periodicidadeApuracaoId: '0',
        periodicidadePagamentoId: '0',
        tipoFuncionarioId:'0',
        diaApuracao: '',
        usuarioAprovadorId: null,
        descricaoLabel: '',
        descricaoLabelApuracao: '',
        codigoSuperacao: '',
        codigoProdutividade: '',
        codigoRepasse: '',
        campanhaPremiacaoRegras: [],
        campanhaPremiacaoRegraQualidade: [],
        campanhaPremiacaoAgenda: [],
        hasAproved: false
    },
    regraQualidade:{
        id: null,
        tipoPendenciaId: '0',
        periodoId: '0',
        valorMaximo: ''
    },
    regraProdutividade: {
        id: null,
        descricao: '',
        bancoId: '0',
        convenioId: '0',
        tipoOperacaoId: '0',
        tipoParceriaId: '0',
        produtoId: '0',
        centroCustoId: '0',
        metaGlobal: false,
        metaPrincipal: false,
        headCount: false,
        campanhaPremiacaoMetaProdutividade : [
            {
                id: null,
                hierarquia: {
                    value: '0',
                    label: ''
                },
                parametroMedidaCampanhaId: '0',
                valorMeta : '',
                medidaPremiacaoCampanhaId: '0',
                valorPremio: '',
                campanhaPremiacaoMetaSuperacao: [],
                campanhaPremiacaoMetaProdutividadeRegraRepasse: [],
            }
        ]
    },
    campanhaPremiacaoMetaProdutividade: {
        id: null,
        hierarquia: {
            value: '0',
            label: ''
        },
        canalId: '0',
        parametroMedidaCampanhaId: '0',
        valorMeta : '',
        medidaPremiacaoCampanhaId: '0',
        valorPremio: '',
        campanhaPremiacaoMetaSuperacao: [],
        campanhaPremiacaoMetaProdutividadeRegraRepasse: [],
    },
    regraRepasse:{
        id: null,
        pmt: '',
        tiposComissaoId: '0',
        valor: ''
    },
    metaSuperacao: {
        id: null,
        descricao: '',
        medidaPremiacaoSuperacaoId: '0',
        valorMeta: '',
        valorPremio: '',
    },
    copy: {},
    invalid: {
        descricao: false,
        periodo: false,
        periodoInicio: false,
        periodoFim: false,
        diaPagamento: false,
        periodoPagamentoId: false,
        apuracao: false
    },
    items: [],
    canais: [],
    tiposOperacao: [],
    tiposFuncionario: [],
    periodicidades: [],
    tiposComissao: [],
    tiposComissaoProduto: [],
    tiposParceria: [],
    tiposPendencia: [],
    periodos: [],
    hierarquias: [],
    hierarquiasProdutividade: [],
    hierarquiasRegra: [],
    grupos: [],
    produtos: [],
    convenios: [],
    centrosCusto: [],
    bancos: [],
    activeTab: 'Search',
    getAllItems: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    seeRegister: false,
    permitions: {
        criar: false,
        deletar: false,
        autorizar: false,
    }
}

export default function campanhaPremiacao(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.ITEMS:
                    return { ...state, items: action.value }
                case TYPES.SET_ITEM:
                    return { ...state, item: { ...state.item, [action.field]: action.value } }
                case TYPES.SET_REGRA_QUALIDADE:
                    return { ...state, regraQualidade: { ...state.regraQualidade, [action.field]: action.value } }
                case TYPES.REGRA_QUALIDADE:
                    return { ...state, regraQualidade: action.value }
                case TYPES.CLEAN_REGRA_QUALIDADE:
                    return { ...state, regraQualidade: INITIAL_STATE.regraQualidade }
                case TYPES.SET_REGRA_PRODUTIVIDADE:
                    return { ...state, regraProdutividade: { ...state.regraProdutividade, [action.field]: action.value } }
                case TYPES.REGRA_PRODUTIVIDADE:
                    return { ...state, regraProdutividade: action.value }
                case TYPES.CLEAN_REGRA_PRODUTIVIDADE:
                    return { ...state, regraProdutividade: {
                        id: null,
                        descricao: '',
                        bancoId: '0',
                        convenioId: '0',
                        tipoOperacaoId: '0',
                        tipoParceriaId: '0',
                        produtoId: '0',
                        centroCustoId: '0',
                        metaGlobal: false,
                        metaPrincipal: false,
                        headCount: false,
                        campanhaPremiacaoMetaProdutividade : [
                            {
                                id: null,
                                hierarquia: {
                                    value: '0',
                                    label: ''
                                },
                                parametroMedidaCampanhaId: '0',
                                valorMeta : '',
                                medidaPremiacaoCampanhaId: '0',
                                valorPremio: '',
                                campanhaPremiacaoMetaSuperacao: [],
                                campanhaPremiacaoMetaProdutividadeRegraRepasse: [],
                            }
                        ]
                    } }
                case TYPES.SET_META_PRODUTIVIDADE:
                    return { ...state, campanhaPremiacaoMetaProdutividade: { ...state.campanhaPremiacaoMetaProdutividade, [action.field]: action.value } }
                case TYPES.META_PRODUTIVIDADE:
                    return { ...state, campanhaPremiacaoMetaProdutividade: action.value }
                case TYPES.CLEAN_META_PRODUTIVIDADE:
                    return { ...state, campanhaPremiacaoMetaProdutividade: { id: null,
                        hierarquia: {
                            value: '0',
                            label: ''
                        },
                        parametroMedidaCampanhaId: '0',
                        valorMeta : '',
                        medidaPremiacaoCampanhaId: '0',
                        valorPremio: '',
                        campanhaPremiacaoMetaSuperacao: [],
                        campanhaPremiacaoMetaProdutividadeRegraRepasse: []} }
                case TYPES.SET_REGRA_REPASSE:
                    return { ...state, regraRepasse: { ...state.regraRepasse, [action.field]: action.value } }
                case TYPES.REGRA_REPASSE:
                    return { ...state, regraRepasse: action.value }
                case TYPES.CLEAN_REGRA_REPASSE:
                    return { ...state, regraRepasse: INITIAL_STATE.regraRepasse }
                case TYPES.SET_META_SUPERACAO:
                    return { ...state, metaSuperacao: { ...state.metaSuperacao, [action.field]: action.value } }
                case TYPES.META_SUPERACAO:
                    return { ...state, metaSuperacao: action.value }
                case TYPES.CLEAN_META_SUPERACAO:
                    return { ...state, metaSuperacao: INITIAL_STATE.metaSuperacao }
                case TYPES.SET_TAB:
                    return { ...state, tab: action.value }
                case TYPES.ITEM:
                    return { ...state, item: action.value }
                case TYPES.INVALID_ITEM:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_ITEM:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.CLEAN_INVALIDS:
                    return { ...state, invalid: INITIAL_STATE.invalid }
                case TYPES.ITEM_COPY:
                    return { ...state, copy: action.value }
                case TYPES.PERIODICIDADES:
                    return { ...state, periodicidades: action.value }
                case TYPES.TIPOS_COMISSAO:
                    return { ...state, tiposComissao: action.value }
                case TYPES.TIPOS_FUNCIONARIO:
                    return { ...state, tiposFuncionario: action.value }
                case TYPES.TIPOS_COMISSAO_PRODUTO:
                    return { ...state, tiposComissaoProduto: action.value }
                case TYPES.BANCOS:
                    return { ...state, bancos: action.value }
                case TYPES.CONVENIOS:
                    return { ...state, convenios: action.value }
                case TYPES.CENTROS_CUSTO:
                    return { ...state, centrosCusto: action.value }
                case TYPES.TIPOS_PENDENCIA:
                    return { ...state, tiposPendencia: action.value }
                case TYPES.PERIODOS:
                    return { ...state, periodos: action.value }
                case TYPES.HIERARQUIAS:
                    return { ...state, hierarquias: action.value }
                case TYPES.HIERARQUIAS_REGRA:
                    return { ...state, hierarquiasRegra: action.value }
                case TYPES.HIERARQUIAS_PRODUTIVIDADE:
                    return { ...state, hierarquiasProdutividade: action.value }
                case TYPES.GRUPOS:
                    return { ...state, grupos: action.value }
                case TYPES.PRODUTOS:
                    return { ...state, produtos: action.value }
                case TYPES.TIPOS_OPERACAO:
                    return { ...state, tiposOperacao: action.value }
                case TYPES.TIPOS_PARCERIA:
                    return { ...state, tiposParceria: action.value }
                case TYPES.CANAIS:
                    return { ...state, canais: action.value }
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state,
                       item: { id: '0',
                        descricao: '',
                        periodo: '',
                        diaPagamento: '0',
                        periodicidadeApuracaoId: '0',
                        periodicidadePagamentoId: '0',
                        tipoFuncionarioId:'0',
                        diaApuracao: '',
                        usuarioAprovadorId: null,
                        descricaoLabel: '',
                        descricaoLabelApuracao: '',
                        codigoSuperacao: '',
                        codigoProdutividade: '',
                        codigoRepasse: '',
                        campanhaPremiacaoRegras: [],
                        campanhaPremiacaoRegraQualidade: [],
                        campanhaPremiacaoMetaProdutividadeId: [],
                        campanhaPremiacaoAgenda: [],
                        hasAproved: false},
                        regraQualidade: INITIAL_STATE.regraQualidade,
                        regraProdutividade: { id: null,
                            descricao: '',
                            bancoId: '0',
                            convenioId: '0',
                            tipoOperacaoId: '0',
                            tipoParceriaId: '0',
                            produtoId: '0',
                            centroCustoId: '0',
                            metaGlobal: false,
                            metaPrincipal: false,
                            headCount: false,
                            campanhaPremiacaoMetaProdutividade : [
                                {
                                    id: null,
                                    hierarquia: {
                                        value: '0',
                                        label: ''
                                    },
                                    parametroMedidaCampanhaId: '0',
                                    valorMeta : '',
                                    medidaPremiacaoCampanhaId: '0',
                                    valorPremio: '',
                                    campanhaPremiacaoMetaSuperacao: [],
                                    campanhaPremiacaoMetaProdutividadeRegraRepasse: [],
                                }
                            ]},
                            campanhaPremiacaoMetaProdutividade: INITIAL_STATE.campanhaPremiacaoMetaProdutividade,
                        regraRepasse: INITIAL_STATE.regraRepasse,
                        metaSuperacao: INITIAL_STATE.metaSuperacao,
                        invalid: INITIAL_STATE.invalid,
                    }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                case TYPES.SEARCH:
                    return { ...state, search: action.value }
                case TYPES.CLEAN_SEARCH:
                    return { ...state, search: INITIAL_STATE.search, items: [], getAllItems: INITIAL_STATE.getAllItems }
                case TYPES.GET_ALL_ITEMS:
                    return { ...state, getAllItems: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}