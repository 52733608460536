const STORE_ID = "TIPOPARCERIAS";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    TIPOPARCERIAS: "TIPOPARCERIAS",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    SET_ITEMS: 'SET_ITEMS',
    SET_ITEM: "SET_ITEM",
    TIPOPARCERIA_COPY: 'TIPOPARCERIA_COPY',
    INVALID_TIPOPARCERIA: 'INVALID_TIPOPARCERIA',
    CLEAN_INVALID_TIPOPARCERIA: 'CLEAN_INVALID_TIPOPARCERIA',

}

export { STORE_ID, TYPES }