const STORE_ID = "ASSOC_USUARIO_GRUPO";

const TYPES = {
    ASSOC_USUARIO_GRUPO: "ASSOC_USUARIO_GRUPO",
    SET_ALL_ITEMS: "SET_ALL_ITEMS",
    ASSOC_USUARIO_GRUPO_COPY: "ASSOC_USUARIO_GRUPO_COPY",
    SET_GRUPOS: "SET_GRUPOS",
    GET_ALL_ITEMS: "GET_ALL_ITEMS",
    INVALID_ASSOC_USUARIO_GRUPO: "INVALID_ASSOC_USUARIO_GRUPO",
    ASSOC_USUARIO_GRUPOS: "ASSOC_USUARIO_GRUPOS",
    CLEAN_INVALID_ASSOC_USUARIO_GRUPO: "CLEAN_INVALID_ASSOC_USUARIO_GRUPO",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    CLEAN_SEARCH: "CLEAN_SEARCH",
    CLEAN_ALL_SEARCH: "CLEAN_ALL_SEARCH",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_USUARIOS: "SET_USUARIOS",
    SET_USUARIO: "SET_USUARIO",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    SEARCH: "SEARCH",
    SET_CHECK_USUARIOS: "SET_CHECK_USUARIOS",
    SET_USUARIOS_SEARCH: "SET_USUARIOS_SEARCH",
    SET_USUARIOS_ASSOCIADOS_SEARCH: "SET_USUARIOS_ASSOCIADOS_SEARCH",
    SET_LOADING_USUARIOS: "SET_LOADING_USUARIOS"
}

export { STORE_ID, TYPES }