/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useLayoutEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {
    Label,
    Card,
    Row,
    Col,
    FormGroup,
    Input,
    Form
} from 'reactstrap'
import {maskCPF, maskNumber} from '../../../../../util/masks'
import {Select} from '../../../../../components/Inputs/Input'
import TableContasCorrente from './components/tableContasCorrente'
import * as AiIcons from 'react-icons/ai'
import * as contaActions from '../../../../../store/modules/personas/conta_corrente_store/actions'
import * as contaController from '../../../../../controllers/personasController/contaCorrenteController.js'
import * as personasController from '../../../../../controllers/personasController/personasController'
import * as contaCorrenteController from "../../../../../controllers/personasController/contaCorrenteController.js";

const ContaCorrente = () => {

    const [matriculasOp, setMatriculasOp] = useState([])
    const {matriculasSelected, cliente_cpf, matriculasSelectBox} = useSelector(state => state.personasPapeis)
    const {
        contasCorrenteSelected,
        conta,
        tiposConta,
        seeRegister,
        bancos,
        conta: {matriculasContaCorrente},
        tiposPix,
        isEditando,
        isExcluindo
    } = useSelector(state => state.contaCorrente)
    const {informacao, isJuridica} = useSelector(state => state.personasInformacoes)

    useEffect(() => {
        contaController.getBancos()
        contaController.getTiposPix()
        contaController.getTiposConta()
    }, []);

    async function salveContaCorrente(e) {
        e.preventDefault();
        await personasController.salveContaCorrente(informacao.id, conta, matriculasContaCorrente);
        await contaCorrenteController.buscaMatriculas(informacao.id);
    }

    async function updateContaCorrente(e){
        e.preventDefault();
        await personasController.updateContaCorrente(informacao.id,conta);
        await contaCorrenteController.buscaMatriculas(informacao.id);
    }

    function onChange(value, field) {
        contaActions.setCampoConta(field, value)
    }

    useLayoutEffect(() => {
        const matriculasMap = matriculasSelected.map(matricula => {
            return {
                id: matricula.id,
                nome: matricula.numero
            }
        })

        let matriculasOptions = [];
        matriculasMap.forEach(matricula => {
            matriculasSelectBox.forEach((m) => {
                if (m.id === matricula.id){
                    matriculasOptions.push(matricula);
                }
            })
        })

        setMatriculasOp(matriculasOptions)

    }, [matriculasSelected, matriculasSelectBox, conta]);



    function handleChavePixChange(value) {
        switch (tipoChavePixSelected) {
            case 'CPF':
                onChange(maskCPF(value), 'chavePix')
                break;
            case 'CELULAR':
                onChange(maskNumber(value), 'chavePix')
                break;
            default:
                onChange(value, 'chavePix')
        }
    }


    const tipoChavePixSelected = tiposPix?.find(tipoPix => tipoPix.id === conta.tipoPixId)?.nome

    const chavesPixMaxLength = {
        CPF: 14,
        CELULAR: 11,
        EMAIL: 320,
        ALEATORIA: 524288 // HTML default maxlength
    }

    function handleLimpaCampos(){
        contaActions.clean();
        contaActions.setIsExcluindo(false);
        contaActions.setIsEditando(false);
    }

    async function handleTipoPix(e){
        await onChange(e.value, 'tipoPixId')
        onChange('', 'chavePix')
    }

    const matriculasOptions = conta.matricula !== '0'
        ? [...matriculasOp, {id: conta.matricula, nome: conta.matriculaNome}]
        : matriculasOp;


    return (
        <>
            <Card className='p-3 m-4' style={{backgroundColor: 'var(--color-quase-branco)'}}>
                <Form className="m-2">
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Banco</Label>
                                <Select
                                    first='Selecione'
                                    value={{value: conta.instituicaoFinanceiraId}}
                                    select={{value: 'id', label: 'descricao'}}
                                    options={bancos}
                                    onChange={e => onChange(e.value, 'instituicaoFinanceiraId')}
                                    isSearchable={true}
                                    autoComplete='off'
                                    disabled={seeRegister}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <FormGroup>
                                <Label for={`agencia`}>Tipo da Conta</Label>
                                <Select
                                    first='Selecione'
                                    value={{value: conta.tipoConta}}
                                    select={{value: 'id', label: 'descricaoTipoConta'}}
                                    options={tiposConta}
                                    onChange={e => {
                                        onChange(e.value, 'tipoConta')
                                    }}
                                    isSearchable={true}
                                    autoComplete='off'
                                    disabled={seeRegister}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <Row style={{flexWrap: 'nowrap'}}>
                                <Col md={8} style={{paddingRight: '1px'}}>
                                    <Label for={`agencia`}>Agência</Label>
                                    <Input
                                        type='text'
                                        placeholder='Digite aqui...'
                                        value={conta.nomeAgencia}
                                        onChange={e => onChange(maskNumber(e.target.value), 'nomeAgencia')}
                                        disabled={seeRegister}
                                        id={`agencia`}
                                        maxLength='6'
                                    />
                                </Col>
                                <Col style={{paddingLeft: '1px', maxWidth: '6rem'}}>
                                    <Label for={`agencia`}>DV</Label>
                                    <Input
                                        type='text'
                                        placeholder='Digite aqui...'
                                        value={conta.digitoAgencia}
                                        onChange={e => onChange(maskNumber(e.target.value), 'digitoAgencia')}
                                        disabled={seeRegister}
                                        maxLength='1'
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3}>
                            <Row style={{flexWrap: 'nowrap'}}>
                                <Col md={8} style={{paddingRight: '1px'}}>
                                    <Label>Conta Corrente</Label>
                                    <Input
                                        type='text'
                                        placeholder='Digite aqui...'
                                        value={conta.numero}
                                        onChange={e => onChange(maskNumber(e.target.value), 'numero')}
                                        maxLength='15'
                                        disabled={seeRegister}
                                        id={`numeroConta`}
                                    />
                                </Col>
                                <Col style={{paddingLeft: '1px', maxWidth: '6rem'}}>
                                    <FormGroup>
                                        <Label>DV</Label>
                                        <Input
                                            type='text'
                                            placeholder='Digite aqui...'
                                            value={conta.digitoConta}
                                            onChange={e => onChange(maskNumber(e.target.value), 'digitoConta')}
                                            maxLength='1'
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for={`numeroConta`}>Tipo de chave</Label>
                                <Select
                                    first='Selecione'
                                    value={{value: conta.tipoPixId}}
                                    select={{value: 'id', label: 'nome'}}
                                    options={tiposPix}
                                    disabled={seeRegister}
                                    onChange={e => {
                                        handleTipoPix(e);
                                    }}
                                    isSearchable
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for={`agencia`}>Chave Pix</Label>
                                <Input
                                    type='text'
                                    placeholder='Digite aqui...'
                                    value={conta.chavePix}
                                    onChange={e => handleChavePixChange(e.target.value)}
                                    disabled={seeRegister}
                                    maxLength={chavesPixMaxLength[tipoChavePixSelected] ?? 524288}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for={`ContaCorrente`}>Id Conta Corrente W3ERP</Label>
                                <Input
                                    type='text'
                                    placeholder='Digite aqui...'
                                    value={conta.cdContaCorrenteW3ERP}
                                    onChange={e => onChange(e.target.value, 'cdContaCorrenteW3ERP')}
                                    disabled={seeRegister}
                                />
                            </FormGroup>
                        </Col>
                        {
                            !isJuridica ?
                                cliente_cpf.isClienteCpfActive ?
                                    <>
                                        <Col>
                                            <Row>
                                                <FormGroup style={{width: '20%'}}>
                                                    <Label>Matricula</Label>
                                                    <Select
                                                        first='Selecione'
                                                        value={{value: conta.matricula}}
                                                        select={{value: 'id', label: 'nome'}}
                                                        options={matriculasOptions}
                                                        disabled={seeRegister}
                                                        onChange={e => onChange(e.value, 'matricula')}
                                                        isSearchable={true}
                                                    />
                                                </FormGroup>
                                            </Row>
                                        </Col>
                                    </>
                                    :
                                    null
                                :
                                null
                        }
                    </Row>
                </Form>
                {
                    !seeRegister && !isEditando && !isExcluindo ? (
                        <Row>
                            <Col md={3}>
                                <Label className='mt-4'>
                                    <AiIcons.AiOutlinePlusCircle
                                        style={{fontSize: '1.5rem', marginRight: '7px', color: 'green', cursor: 'pointer'}}
                                        onClick={(e) => salveContaCorrente(e)}
                                    />
                                    Adicionar Conta
                                </Label>
                            </Col>
                        </Row>
                    ) : null
                }
                {
                    (isEditando && !seeRegister) && (
                        <Row>
                            {
                                isEditando && (
                                    <Col md={3}>
                                        <Label className='mt-4'>
                                            <AiIcons.AiOutlinePlusCircle
                                                style={{fontSize: '1.5rem', marginRight: '7px', color: 'green', cursor: 'pointer'}}
                                                onClick={(e) => updateContaCorrente(e)}
                                            />
                                            Atualizar Conta Corrente
                                        </Label>
                                    </Col>
                                )
                            }
                            <Col md={3}>
                                <Label className='mt-4'>
                                    <AiIcons.AiFillEdit
                                        style={{fontSize: '1.5rem', marginRight: '7px', color: 'green', cursor: 'pointer'}}
                                        onClick={(e) => handleLimpaCampos()}
                                    />
                                    Limpar Campos
                                </Label>
                            </Col>
                        </Row>
                    )
                }
            </Card>
            {
                contasCorrenteSelected.length > 0 &&
                <TableContasCorrente/>
            }
        </>
    )
}

export default ContaCorrente

