import React from 'react'
import { useSelector } from 'react-redux';
import { Card, Col, Row, Label, Input } from "reactstrap";
import { InputCpfCnpj } from '../../../components/Inputs/Input';

export default function Proposta(){
    const { item } = useSelector(state => state.propostas)
    return(
        <Col>
                <Card style={{ padding: '25px 25px 20px 25px' }} className="radius">
                    <p style={{fontSize:'20px', marginBottom: '20px'}}>Informações principais</p>
                    <Row style={{ marginBottom: '15px' }}>
                        <Col>
                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Proposta</Label>
                            <Input
                                type='text'
                                value={item.proposta}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Nome do Cliente</Label>
                            <Input
                                type='text'
                                value={item.nomeCliente}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>CPF Cliente</Label>
                            <InputCpfCnpj
                                id="CPF"
                                tipo="cpf"
                                value={item.cpfCliente}
                                disabled={true}
                            />
                        </Col>
                        
                    </Row>
                    <Row style={{ marginBottom: '15px' }}>
                        <Col>
                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Loja</Label>
                            <Input
                                type='text'
                                value={item.loja}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Convênio</Label>
                            <Input
                                type='text'
                                value={item.convenio}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Instituição Financeira</Label>
                            <Input
                                type='text'
                                value={item.instituicao}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                                <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>CPF do Digitador</Label>
                                <InputCpfCnpj
                                id="CPF"
                                tipo="cpf"
                                value={item.cpfDigitador}
                                disabled={true}
                            />
                            </Col>
                            <Col>
                                <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Código de digitador</Label>
                                <Input
                                    type='text'
                                    value={item.codDigitador}
                                    disabled={true}
                                />
                            </Col>
                            <Col>
                                <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Nome do Digitador</Label>
                                <Input
                                    type='text'
                                    value={item.nomeDigitador}
                                    disabled={true}
                                />
                            </Col>
                    </Row>
                   { item.produtos.map((produto, i)=>{
                       return(
                        <div style={{marginTop: '20px'}} key={i}>
                        <Row style={{ marginBottom: '15px' }}>
                                    <Col>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Produto</Label>
                                        <Input
                                            type='text'
                                            value={produto.produtoBanco}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Tipo de operação</Label>
                                        <Input
                                            type='text'
                                            value={produto.tipoOperacao}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Tabela</Label>
                                        <Input
                                            type='text'
                                            value={produto.tabela}
                                            disabled={true}
                                        />
                                    </Col>                   
                                </Row>
                                <Row style={{ marginBottom: '15px' }}>
                                <Col>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Código da tabela</Label>
                                        <Input
                                            type='text'
                                            value={produto.codtabela}
                                            disabled={true}
                                        />
                                    </Col>   
                                    <Col>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Status proposta</Label>
                                        <Input
                                            type='text'
                                            value={produto.status}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Data status proposta</Label>
                                        <Input
                                            type='date'
                                            max="9999-12-31"
                                            value={produto.dataStatusProposta }
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col>
                                  
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Data da entrada proposta</Label>
                                        <Input
                                            type='date'
                                            max="9999-12-31"
                                            value={produto.dataEntradaProposta}
                                            disabled={true}
                                        />
                                    </Col>                      
                                </Row>
                        <Row style={{ marginBottom: '15px' }}>
                                    <Col>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Quantidade de parcelas</Label>
                                        <Input
                                            type='text'
                                            value={produto.qtdParcelas}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Valor Parcelas</Label>
                                        <Input
                                            type='text'
                                            value={`R$${produto.valorParcela}`}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Base de cálculo</Label>
                                        <Input
                                            type='text'
                                            value={`R$${produto.baseCalculo}`}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Tipo de formalização</Label>
                                        <Input
                                            type='text'
                                            value={produto.tipoFormalizacao}
                                            disabled={true}
                                        />
                                    </Col>                      
                                </Row>
                        <div style={{borderBottom: '1px solid #C4C7CA'}}/>
                    </div>
                       )
                    })}
                </Card>
            </Col>
    )
}