import api from "../../util/api";
import * as actions from "../../store/modules/dashboard/esteiraProducao/actions";
import { setLoading } from "../../store/modules/globalLoading/actions";
import { notifyWarn } from "../../components/message/message";
import { removeMaskCNPJ } from "../../util/masks";

export async function getInconsistenciasDaProposta(propostaId) {
    setLoading(true);
    const data = await api.get(`Inconsistencia/GetInconsistenciasDaProposta/${propostaId}`);
    setLoading(false);

    if (!data.success) {
        return []
    }

    return data.content
}

export async function updateFlagDaInconsistenciaProposta(propostaInconsistenciaId, newStatusInconsistencia) {
    setLoading(true);
    const data = await api.put(`Inconsistencia/AtualizaFlagDaInconsistenciaProposta/${propostaInconsistenciaId}?corrigido=${newStatusInconsistencia}`);
    setLoading(false);

    if (!data.success) {
        return false
    }

    return true
}

export async function get(filtros) {
    const currentDate = new Date().toISOString().slice(0, 10)
    for (let filtro of filtros) {
        if (filtro.chave === 'dataInicio' && filtro.descricao === '') {
            return notifyWarn('É necessário informar data de início ')
        }
        if (filtro.chave === 'dataFim' && filtro.descricao === '') {
            return notifyWarn('É necessário informar data de fim')
        }

        if (filtro.chave === 'dataInicio' && filtro.descricao > currentDate) {
            return notifyWarn('A data de início é maior do que a data atual')
        }
        if (filtro.chave === 'dataFim' && filtro.descricao > currentDate) {
            return notifyWarn('A data fim é maior do que a data atual')
        }
        if (filtro.chave === 'dataInicio' && filtro.descricao < '1900-01-01') {
            return notifyWarn('Filtro de data inválido')
        }
    }

    setLoading(true);
    var isBigNumbersSuccessed = await getBigNumbers(filtros)

    var isTabelaEsteiraSuccessed = await getTabelaEsteiraProducao(filtros)

    var isBigNumbersCanceladosSuccessed = await getBigNumbersCancelados(filtros)

    var isTabelaEsteiraCanceladoSuccessed = await getTabelaEsteiraProducaoCancelados(filtros)
    setLoading(false);

    if (isBigNumbersSuccessed || isTabelaEsteiraSuccessed || isBigNumbersCanceladosSuccessed || isTabelaEsteiraCanceladoSuccessed) {
        return true
    } else {
        return false
    }
}

export async function getBigNumbers(filtros) {
    var url = `EsteiraProducao/GetBigNumbers`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }

        if (e.chave === 'produtoRibercredId') {
            url = url + `&produtoId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&ParceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }
    })

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    actions.setBigNumbers(data.content);
    return true
}

export async function getTabelaEsteiraProducao(filtros) {
    var url = `EsteiraProducao/GetTabela`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }

        if (e.chave === 'produtoRibercredId') {
            url = url + `&produtoId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }
    })

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) => {
        e.propostas = []
    })
    actions.setTabelaEsteiraProducao(data.content);
    return true
}

export async function getTabelaEsteiraProducaoPropostas(filtros, lojaId, tabela) {
    var url = `EsteiraProducao/GetPropostasTabela`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }

        if (e.chave === 'produtoRibercredId') {
            url = url + `&produtoId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }
    })

    url = url + `&lojaId=${lojaId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    tabela.forEach((e) => {
        if (e.id === lojaId) {
            e.propostas = data.content
        }
    })
    actions.setTabelaEsteiraProducao(tabela);
    return true

}

export async function getBigNumbersCancelados(filtros) {
    var url = `EsteiraProducao/GetBigNumbersCancelado`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }

        if (e.chave === 'produtoRibercredId') {
            url = url + `&produtoId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&ParceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }
    })

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    actions.setBigNumbersCancelados([data.content]);
    return true
}

export async function getTabelaEsteiraProducaoCancelados(filtros) {
    var url = `EsteiraProducao/GetTabelaCancelado`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }

        if (e.chave === 'produtoRibercredId') {
            url = url + `&produtoId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }
    })

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) => {
        e.propostas = []
    })
    actions.setTabelaEsteiraProducaoCancelados(data.content);
    return true
}

export async function getTabelaEsteiraProducaoPropostasCancelados(filtros, lojaId, tabela) {
    var url = `EsteiraProducao/GetTabelaPropCancelado`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }

        if (e.chave === 'produtoRibercredId') {
            url = url + `&produtoId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }
    })

    url = url + `&lojaId=${lojaId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    tabela.forEach((e) => {
        if (e.id === lojaId) {
            e.propostas = data.content
        }
    })
    actions.setTabelaEsteiraProducaoCancelados(tabela);
    return true

}

export async function getInstituicoes() {
    const data = await api.get(`Bancos`);

    if (!data.success) {
        return;
    }
    actions.setInsituicoes(data.content);
}

export async function getCanais() {
    const data = await api.get(`canal`);

    if (!data.success) {
        return;
    }
    actions.setCanais(data.content);
}

export async function getHierarquias(canalId) {
    const data = await api.get(`hierarquias/hierarquia-canal/${canalId}`);

    if (!data.success) {
        return;
    }
    actions.setHierarquias(data.content);
}

export async function getLojas(pesquisa, page, pageSize, canalId) {
    var url = `CentrosCusto/paginado?pagina=${page}&registros=${pageSize}&nome=${pesquisa.nome}`

    actions.setLoadingLojas(true);
    const data = await api.get(url);
    actions.setLoadingLojas(false);

    if (!data.success) {
        return;
    }

    actions.setLojas(data.content.registros);
    actions.setGetAllLojas({
        quantidade: data.content.quantidade,
        quantidadeTotal: data.content.quantidadeTotal,
        totalPaginas: data.content.totalPaginas
    })
}

export async function getProdutos() {
    const data = await api.get(`Produtos`);

    if (!data.success) {
        return;
    }
    actions.setProdutosRiber(data.content);
}

export async function getTiposOperacao() {
    const data = await api.get(`tipos-operacao`);

    if (!data.success) {
        return;
    }
    actions.setTiposOperacao(data.content);
}

export async function getParcerias() {
    const data = await api.get(`TipoParceira`);

    if (!data.success) {
        return;
    }
    actions.setParcerias(data.content);
}

export async function getConvenios() {
    const data = await api.get(`Convenios`);

    if (!data.success) {
        return;
    }
    actions.setConvenios(data.content);
}

export async function getGrupos() {
    const data = await api.get(`grupos`);

    if (!data.success) {
        return;
    }
    actions.setGrupos(data.content);
}

export async function getConsultores(pesquisa, page, pageSize) {
    var url = `lojas/consultores?pagina=${page}&registros=${pageSize}`

    if (pesquisa.cpf !== '') url = url + `&cpf=${pesquisa.cpf}`
    if (pesquisa.nome !== '') url = url + `&nome=${pesquisa.nome}`

    actions.setLoadingConsultores(true);
    const data = await api.get(url);
    actions.setLoadingConsultores(false);

    if (!data.success) {
        return;
    }

    actions.setGetAllConsultores({
        quantidade: data.content.quantidade,
        quantidadeTotal: data.content.quantidadeTotal,
        totalPaginas: data.content.totalPaginas
    })
    actions.setConsultores(data.content.registros);
}