import React, { useEffect } from "react";
import { Card, Col, Row, Form, FormGroup, Input, Label } from "reactstrap";
import { Select, Switch } from '../../../components/Inputs/Input'
import * as  comissaoActions from "../../../store/modules/tipoComissao/actions";
import * as comissaoController from "../../../controllers/tipoComissaoController";
import { useSelector } from "react-redux";
import { removeAccentsAndUppercase } from "../../../util/masks";
import CardTipoComissaoBanco from "../components/CardTipoComissaoBanco";
import { BiTrash } from "react-icons/bi";


import { notifySuccess } from "../../../components/message/message";

const Register = () => {

    const { item, invalid, copy, seeRegister,  produtosBanco, filtros,allProdutosBanco, produtosBancoII,  basesCalculo, periodicidades, unidadeComissoes, tipoComissaoBanco, instituicoes } = useSelector(state => state.tipoComissao);
  
    function getTipoDeComissionamentoDescricao(id) {
      return unidadeComissoes.find(unidadeComissao => unidadeComissao.id === id)?.descricao
    }

    function getPeriodicidadeDescricao(id) {
      return periodicidades.find(periodicidade => periodicidade.id === id)?.descricao
    }

    function getBaseCalculoDescricao(id) {
      return basesCalculo.find(baseCalculo => baseCalculo.id === id)?.descricao
    }

    function getInstituicaoFinanceiraNome(id) {
      return instituicoes.find(instituicao => instituicao.id === id)?.nome
    }

    function getProdutoBancoDescricao(id, index) {
      return (index !== undefined ? allProdutosBanco : produtosBancoII)?.find(produtoBanco => produtoBanco.id === id)?.descricao
    }
  
    useEffect(() => {
        comissaoController.getInstituicoes();
    }, []);

    useEffect(() => {
        if(filtros.instituicaoId !== '0'){
            comissaoController.getProdutosBanco(filtros.instituicaoId);
            comissaoController.getFiltroBanco(item.id, filtros.instituicaoId)
            onChangeFiltro('0', "produtoBancoId")
        }
        if(item.id && filtros.instituicaoId === '0'){
            comissaoActions.cleanProdutosBanco()
            comissaoController.getById(item.id)
            onChangeFiltro('0', "produtoBancoId")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtros.instituicaoId])

    useEffect(() => {
        if(filtros.produtoBancoId !== '0'){
            comissaoController.getFiltroPrudutosBanco(item.id, filtros.produtoBancoId)
        }
        if(filtros.instituicaoId !== '0' && filtros.produtoBancoId === '0'){
            comissaoController.getFiltroBanco(item.id, filtros.instituicaoId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtros.produtoBancoId]);

    useEffect(() => {
        comissaoController.getPeriodicidades();
    }, []);

    useEffect(() => {
        comissaoController.getBasesCalculos();
    }, []);

    useEffect(() => {
        comissaoController.getUnidadeComissao();
    }, []);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                comissaoController.create(item);
            } else {
                comissaoController.update(item, copy);
            }
        }
    }

    function onChangeItem(value, field) {
        comissaoActions.cleanInvalid(field);
        comissaoActions.item({ ...item, [field]: value });
    }

    function onChangeFiltro(value, field) {
        comissaoActions.setFiltros(field, value);
    }
  
    async function handleRemoveTipoComissaoBanco(e, index, rowId) {
      e.preventDefault()
      let tiposComissaoBanco = [...item.tipoComissaoBanco]
      tiposComissaoBanco.splice(index, 1)
      if (rowId) {
          var isSuccessed = await comissaoController.deleteTipoComissao(item.id, rowId)
          if (isSuccessed) {
            comissaoActions.item({ ...item, tipoComissaoBanco: tiposComissaoBanco })
            comissaoActions.copy({ ...item, tipoComissaoBanco: tiposComissaoBanco })
          }
      }else{
          comissaoActions.item({ ...item, tipoComissaoBanco: tiposComissaoBanco })
          comissaoActions.copy({ ...item, tipoComissaoBanco: tiposComissaoBanco })
          notifySuccess("Comissão removida")
      }
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2" style={{ margin: '25px 0 0 25px' }}>
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>
                            Preencha os campos para cadastrar um Tipo de Comissão
                        </p>
                    </Col>
                    <Form style={{ margin: '0 80px 0 15px' }}>
                        <Row style={{ margin: '10px 0 0 15px' }}>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="convenio" style={{ color: 'var(--color-preto)' }}>
                                        Descrição do Tipo de Comissão
                                    </Label>
                                    <Input
                                        type="text"
                                        name="convenio"
                                        id="convenio"
                                        placeholder="Digite aqui..."
                                        value={item.descricao}
                                        onChange={e => onChangeItem(removeAccentsAndUppercase(e.target.value), "descricao")}
                                        onKeyPress={e => handleKeyPress(e)}
                                        className={invalid.descricao ? "is-invalid" : ""}
                                        disabled={seeRegister}
                                    />
                                </FormGroup>
                            </Col>
                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                <Switch
                                    className='switch'
                                    componentRight="Comissão parcelada"
                                    checked={item.geraParcela}
                                    disabled={seeRegister}
                                    onChange={() => {onChangeItem(!item.geraParcela, "geraParcela")}}
                                />
                            </Col>
                        </Row>
                        {item.id && (
                            <Row style={{ margin: '10px 0 0 15px' }}>
                            <div style={{ background: ' var(--color-quase-branco)', minHeight: '166px', width: '60%', border: '1px solid #CCCFDE', borderRadius: '5px', padding: '15px 20px', color: 'var(--color-preto)' }}>
                                <h3 style={{fontSize: '16px', fontWeight: '700', marginBottom: '20px'}}>Filtros</h3>
                                <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="convenio" style={{ color: 'var(--color-preto)' }}>
                                            Instituição
                                        </Label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: filtros.instituicaoId }}
                                            select={{ value: "id", label: "nome" }}
                                            onChange={(e) => onChangeFiltro(e.value, "instituicaoId")}
                                            options={instituicoes}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="convenio" style={{ color: 'var(--color-preto)' }}>
                                            Produto Instituição
                                        </Label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: filtros.produtoBancoId }}
                                            select={{ value: "id", label: "descricao" }}
                                            onChange={(e) => onChangeFiltro(e.value, "produtoBancoId")}
                                            options={produtosBanco}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                                </Row>
                            </div>
                            </Row>
                        )}
                        { !seeRegister &&
                            <Col className="mt-4">
                                <CardTipoComissaoBanco
                                    dados={tipoComissaoBanco}
                                />
                            </Col>
                        }
                        
                        <table className="mt-4 mx-3">
                          <thead>
                            <tr>
                              <th>Tipo de comissionamento</th>
                              <th>Periodicidade</th>
                              <th>Base de Cálculo</th>
                              <th>Instituição Financeira</th>
                              <th>Produto Instituição</th>
                              {!seeRegister ? (
                                <th style={{ textAlign: "center" }}>
                                  Ações
                                </th>  
                              ): null}
                            </tr>
                        </thead>
                            
                        <tbody>
                        {item.tipoComissaoBanco?.map((row, index) => {
                            return (
                              <tr key={index}>
                                <td index={row.id}>{getTipoDeComissionamentoDescricao(row.unidadeComissaoId)}</td>
                                <td index={row.id}>{getPeriodicidadeDescricao(row.periodicidadeId)}</td>
                                <td index={row.id}>{getBaseCalculoDescricao(row.baseCalculoId)}</td>
                                <td index={row.id}>{getInstituicaoFinanceiraNome(row.bancoId)}</td>
                                <td index={row.id}>{getProdutoBancoDescricao(row.produtoBancoId, index)}</td>
                                {!seeRegister ? (
                                <td style={{ textAlign: 'center' }}>
                                    <BiTrash
                                        style={{ fontSize: '1.2rem', cursor: 'pointer', color: '#C4C7CA' }}
                                        onClick={(e) => handleRemoveTipoComissaoBanco(e, index, row.id)}
                                    />
                                </td>
                                ): null}
                              </tr>
                          )
                           })}
                        </tbody>
                      </table>
                    </Form>
                </Card>
            </Col>
        </>
    );
}

export default Register;