import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    tab: {
        tab: '1',
        id: '5f442aa7-9cd0-4257-a9c6-566aff716199'
    },
    tabs:[
        {
            tab: '1',
            id: '5f442aa7-9cd0-4257-a9c6-566aff716199',
            quantidade: '0'
        },
       {
            tab: '2',
            id: 'eb29bd8d-db03-4f69-b255-dcd89b46b59e',
            quantidade: '0'
        },
        {
            tab: '3',
            id: 'd4f84993-664c-4dbe-8858-206a8f4b1acf',
            quantidade: '0'
        },
        {
            tab: '4',
            id: '98d7e227-ef90-436c-b288-3bbbb1df1fee',
            quantidade: '0'
        },
        {
            tab: '5',
            id: '76222b28-aa5f-46bc-9c1d-9afde2786835',
            quantidade: '0'
        },
        {
            tab: '6',
            id: 'fb6aca33-d063-476d-8fd7-06df63d8fa60',
            quantidade: '0'
        },
        {
            tab: '7',
            id: 'abb5157f-04c7-46f2-901c-b83706c58318',
            quantidade: '0'
        },
        {
            tab: '8',
            id: '4a701bbb-5b78-4d3f-bcde-963a9370d4df',
            quantidade: '0'
        },
    ],
    usuarios: [],
    produtos: [],
    tiposOperacao: [],
    statusProposta: [],
    convenios: [],
    tiposFormalizacao: [],
    clientes: [],
    lojas:[],
    permitions:{
        criar: false
    }
}

export default function incosistenciaProducao(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.SET_TABS:
                    return { ...state, tabs: action.value }
                case TYPES.SET_TAB:
                    return { ...state, tab: action.value }
                case TYPES.SET_USUARIOS:
                    return { ...state, usuarios: action.value }
                case TYPES.SET_PRODUTOS:
                    return { ...state, produtos: action.value }
                case TYPES.SET_TIPOS_OPERACAO:
                    return { ...state, tiposOperacao: action.value }
                case TYPES.SET_STATUS:
                    return { ...state, statusProposta: action.value }
                case TYPES.SET_CONVENIO:
                    return { ...state, convenios: action.value }
                case TYPES.SET_FORMALIZACOES:
                    return { ...state, tiposFormalizacao: action.value }
                case TYPES.SET_CLIENTES:
                    return { ...state, clientes: action.value }
                case TYPES.SET_LOJAS:
                    return { ...state, lojas:
                         action.value }
                case TYPES.CLEAN_TABS:
                return { ...state, usuarios: INITIAL_STATE.usuarios,
                    produtos: INITIAL_STATE.produtos,
                    tiposOperacao: INITIAL_STATE.tiposOperacao,
                    statusProposta: INITIAL_STATE.statusProposta,
                    convenios: INITIAL_STATE.convenios,
                    tiposFormalizacao: INITIAL_STATE.tiposFormalizacao,
                    clientes: INITIAL_STATE.clientes,
                    lojas:INITIAL_STATE.lojas, }
                default:
                    return { ...state }
                }
                default:
                    return { ...state }
            }
        }