import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    item: {
        centroCustoId: "0",
        lojaId: '0',
        codigo: "",
        id: "",
        lojaPessoas: []
    },
    copy: {},
    invalid: {
        nome: false,
        codigo: false
    },
    items: [],
    lojas: [],
    centrosCusto: [],
    activeTab: "Search",
    seeRegister: false,
    activePersona: false,
    permitions: {
        criar: false,
        deletar: false,
    }
}

export default function vinculoCentroDeCusto(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.CENTROS:
                    return { ...state, items: action.value }
                case TYPES.SET_LOJAS:
                    return { ...state, lojas: action.value }
                case TYPES.SET_CENTROS_CUSTO:
                    return { ...state, centrosCusto: action.value }
                case TYPES.CENTROCUSTO:
                    return { ...state, item: action.value }
                case TYPES.INVALID_CENTROCUSTO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_CENTROCUSTO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.CENTROCUSTO_COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, item: INITIAL_STATE.item, invalid: INITIAL_STATE.invalid }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                case TYPES.SET_PERSONA:
                    return { ...state, activePersona: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}
