import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    tab: '1',
    permitions: {
        campanha: false,
        producao: false,
        esteira: false,
        receita: false,
        previsao: false,
    },
    permitionsTabs: {
        producao: {
            tabelaCanal: false
        },
    },
}

export default function dashboard(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: {...state.permitions, [action.label] : action.value} }
                case TYPES.SET_PERMITIONS_TABLES:
                    return { ...state, 
                        permitionsTabs: 
                        {...state.permitionsTabs, 
                            [action.tab]: {
                                ...state.permitionsTabs[action.tab],
                                [action.label] : action.value
                            }
                        } 
                    }
                case TYPES.SET_TAB:
                    return { ...state, tab: action.value }
                default:
                    return { ...state }
                }
                default:
                    return { ...state }
            }
        }