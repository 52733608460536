/* eslint-disable default-case */
/* eslint-disable no-use-before-define */
import { RiCloseFill } from "react-icons/ri";
import { VscDiscard } from "react-icons/vsc";
import { GiCheckMark } from "react-icons/gi";
import { AiOutlineFilter } from "react-icons/ai";
import * as BiIcons from "react-icons/bi";

import { Col, Label, Row, Input } from "reactstrap";
import { InputAdornment, OutlinedInput } from "@material-ui/core";

import { useSelector } from "react-redux";

import * as actions from "../../../../store/modules/perfilAcesso/actions";
import * as controller from "../../../../controllers/perfilAcessoController";
import { TbLockOpen } from "react-icons/tb";
import LoadingIcon from "../../../../components/Loading/LoadingIcon";

import { telasSistema } from "../../../../util/telas";

export function TelasAssociadasList() {
  const {
    telasAssociadas,
    telasDisponiveis,
    telasAssociadasSelecionadas,
    telasAssociadasSelectedSearchResults,
    activeTab,
    telasAssociadasSearchResults,
    telasAssociadasSearchTerm,
    perfilDeAcessoBeingEditedId,
    telasDisponiveisInitialValueBackup,
    isInEditMode,
    loadingTelas,
  } = useSelector((state) => state.perfilAcesso);

  const telas =
    telasAssociadasSearchTerm.length > 0
      ? telasAssociadasSearchResults
      : telasAssociadas;

  const isAllSearchResultsSelected =
    telasAssociadasSearchResults.length > 0 &&
    telasAssociadasSearchResults.length ===
      telasAssociadasSelectedSearchResults.length;

  const isAllSelected =
    telasAssociadasSearchTerm.length > 0
      ? isAllSearchResultsSelected
      : telasAssociadasSelecionadas.length > 0 &&
        telasAssociadasSelecionadas.length === telasAssociadas.length;

  const isTelasAssociadasEmpty = telasAssociadas.length === 0;

  const isSelectAllVisible =
    telasAssociadasSearchTerm.length > 0
      ? telasAssociadasSearchResults.length > 0
      : !(isTelasAssociadasEmpty || activeTab === "Visualize");

  function handleSearchTelasAssociadas(inputValue) {
    actions.setTelasAssociadasSearchTerm(inputValue);

    if (inputValue.trim() === "") {
      actions.setTelasAssociadasSearchTerm("");
      actions.setTelasAssociadasSearchResults([]);
      return;
    }

    const searchTermLowercase = inputValue.toLowerCase();
    const telasFiltradas = telasAssociadas.filter((tela) =>
      tela.nomeTela.toLowerCase().includes(searchTermLowercase)
    );

    actions.setTelasAssociadasSearchResults(telasFiltradas);

    actions.setTelasAssociadasSelectedSearchResults(
      telasFiltradas.filter((telaAssociada) =>
        telasAssociadasSelecionadas.some(
          (telaSelecionada) => telaSelecionada.id === telaAssociada.id
        )
      )
    );
  }

  async function handleRemoveScreenFromAssociatedList(telaAssociada) {
    let isSuccessed = true;

    if (isInEditMode) {
      const hasSuccessed = await controller.removeTelaAssociadaPermission({
        perfilDeAcessoId: perfilDeAcessoBeingEditedId,
        tela: telaAssociada,
        tipoOperacao: "visualizar",
      });

      isSuccessed = hasSuccessed;
    }

    if (!isSuccessed) {
      return;
    }

    if (telasAssociadasSearchResults.length === 1) {
      //clear search input text
      actions.setTelasAssociadasSearchTerm("");
    }

    if (
      telasAssociadasSearchResults.length > 0 &&
      telasAssociadasSearchResults.length !== 1
    ) {
      const newTelasAssociadasSearchResults =
        telasAssociadasSearchResults.filter(
          (tela) => tela.id !== telaAssociada.id
        );
      actions.setTelasAssociadasSearchResults(newTelasAssociadasSearchResults);
    }

    const newTelasAssociadas = telasAssociadas.filter(
      (tela) => tela.id !== telaAssociada.id
    );

    const removedItem = telasDisponiveisInitialValueBackup.find(
      (tela) => tela.id === telaAssociada.id
    );

    actions.setTelasAssociadas(newTelasAssociadas);
    if (removedItem !== undefined) {
      actions.setTelasDisponiveis([removedItem, ...telasDisponiveis]);
    }

    actions.setTelasAssociadasSelecionadas(
      telasAssociadasSelecionadas.filter(
        (telaSelecionada) => telaSelecionada.id !== telaAssociada.id
      )
    );
    actions.setTelasAssociadasSelectedSearchResults(
      telasAssociadasSelectedSearchResults.filter(
        (telaSelecionada) => telaSelecionada.id !== telaAssociada.id
      )
    );
    actions.setTelasDisponiveisSearchResults([]);
    actions.setTelasDisponiveisSelectedSearchResults([]);
    actions.setTelasDisponiveisSearchTerm("");
  }

  function handleSelectAllTelasAssociadas() {
    if (telasAssociadasSearchTerm.length > 0) {
      const searchResultsSelected = telasAssociadasSearchResults.filter(
        (telaAssociada) =>
          telasAssociadasSelecionadas.some(
            (telaAssociadaSelecionada) =>
              telaAssociadaSelecionada.id === telaAssociada.id
          )
      );

      const isAllSearchResultsSelectedd =
        searchResultsSelected.length > 0 &&
        searchResultsSelected.length === telasAssociadasSearchResults.length;

      const telasAssociadasSelecionadasWithoutSelectedSearchResultsItems =
        telasAssociadasSelecionadas.filter(
          (tela) =>
            !searchResultsSelected.some(
              (telaAssociadaSelecionada) =>
                telaAssociadaSelecionada.id === tela.id
            )
        );

      if (isAllSearchResultsSelectedd) {
        actions.setTelasAssociadasSelecionadas(
          telasAssociadasSelecionadasWithoutSelectedSearchResultsItems
        );

        actions.setTelasAssociadasSelectedSearchResults([]);
      } else {
        actions.setTelasAssociadasSelecionadas([
          ...telasAssociadasSelecionadasWithoutSelectedSearchResultsItems,
          ...telasAssociadasSearchResults,
        ]);

        actions.setTelasAssociadasSelectedSearchResults(
          telasAssociadasSearchResults
        );
      }

      return;
    }

    if (isAllSelected) {
      actions.setTelasAssociadasSelecionadas([]);
    } else {
      actions.setTelasAssociadasSelecionadas([...telasAssociadas]);
    }
  }

  function handleSelecionarTelaAssociada(telaId) {
    const telaSelecionada = telasAssociadas.find((tela) => tela.id === telaId);
    if (telaSelecionada !== undefined) {
      actions.setTelasAssociadasSelecionadas([
        ...telasAssociadasSelecionadas,
        telaSelecionada,
      ]);
    }

    if (telasAssociadasSearchTerm.length > 0) {
      actions.setTelasAssociadasSelectedSearchResults([
        ...telasAssociadasSelectedSearchResults,
        telaSelecionada,
      ]);
    }
  }

  function handleDeselecionarTelaAssociada(telaId) {
    actions.setTelasAssociadasSelecionadas(
      [...telasAssociadasSelecionadas].filter((tela) => tela.id !== telaId)
    );

    if (telasAssociadasSearchTerm.length > 0) {
      actions.setTelasAssociadasSelectedSearchResults(
        [...telasAssociadasSelectedSearchResults].filter(
          (tela) => tela.id !== telaId
        )
      );
    }
  }

  async function handleChangePermition(permissionType, telaAssociada) {
    let tela = { ...telaAssociada };

    if (tela !== undefined) {
      let permissoes = { ...tela?.permissoes };

      const togglePermission = async (permissionType) => {
        const isPermissionEnabled = tela?.permissoes[permissionType];

        if (isPermissionEnabled) {
          if (isInEditMode && tela !== undefined) {
            const isSuccessed = await controller.removeTelaAssociadaPermission({
              perfilDeAcessoId: perfilDeAcessoBeingEditedId,
              tela: tela,
              tipoOperacao: permissionType,
            });

            if (isSuccessed) {
              permissoes[permissionType] = !permissoes[permissionType];
            }
          } else {
            permissoes[permissionType] = !permissoes[permissionType];
          }
        } else {
          if (isInEditMode && tela !== undefined) {
            const isSuccessed = await controller.addTelaAssociadaPermission({
              perfilDeAcessoId: perfilDeAcessoBeingEditedId,
              tela: tela,
              tipoOperacao: permissionType,
            });
            if (isSuccessed) {
              permissoes[permissionType] = !permissoes[permissionType];
            }
          } else {
            permissoes[permissionType] = !permissoes[permissionType];
          }
        }
      };

      switch (permissionType) {
        case "criar":
          await togglePermission(permissionType);
          break;
        case "deletar":
          await togglePermission(permissionType);
          break;
        case "estornar":
          await togglePermission(permissionType);
          break;
        case "autorizar":
          await togglePermission(permissionType);
          break;
        case "filtros":
          await togglePermission(permissionType);
          break;
        case "desbloquear":
          await togglePermission(permissionType);
          break;
      }

      tela = { ...tela, permissoes };

      const telaAssociadaPreviousLocationIndex = telasAssociadas.findIndex(
        (telaAssociada) => telaAssociada.id === tela?.id
      );

      const newTelasAssociadasValue = [...telasAssociadas];

      newTelasAssociadasValue[telaAssociadaPreviousLocationIndex] = tela;

      actions.setTelasAssociadas(newTelasAssociadasValue);
      actions.setPerfilDeAcessoBeingCreatedData({
        campo: "telasAssociadas",
        value: newTelasAssociadasValue,
      });

      if (telasAssociadasSearchResults.length > 0) {
        const telaAssociadaSearchResultsPreviousLocationIndex =
          telasAssociadasSearchResults.findIndex(
            (telaAssociada) => telaAssociada.id === tela?.id
          );

        const newTelasAssociadasSearchResultsValue = [
          ...telasAssociadasSearchResults,
        ];

        newTelasAssociadasSearchResultsValue[
          telaAssociadaSearchResultsPreviousLocationIndex
        ] = tela;

        actions.setTelasAssociadasSearchResults(
          newTelasAssociadasSearchResultsValue
        );
      }
    }
  }

  function checkIfTelaAssociadaIsSelecionada(telaId) {
    const isTelaSelecionada = telasAssociadasSelecionadas.some(
      (tela) => tela.id === telaId
    );

    return isTelaSelecionada;
  }

  return (
    <Col md={5}>
      <Label
        style={{
          fontWeight: "700",
          color: "var(--color-preto)",
          fontSize: "17px",
        }}
      >
        Módulos Associados
      </Label>
      <OutlinedInput
        className="input-default mb-2"
        placeholder="Procurar"
        value={telasAssociadasSearchTerm}
        onChange={(e) => handleSearchTelasAssociadas(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <BiIcons.BiSearchAlt />
          </InputAdornment>
        }
      />
      <div
        style={{
          border: "1px solid #B5B5B5",
          borderRadius: "10px",
          height: "350px",
          overflow: "auto",
        }}
      >
        {isSelectAllVisible ? (
          <Row
            style={{
              flexWrap: "nowrap",
              margin: "15px 15px 0 15px",
              height: "35px",
              borderBottom: "1px solid #B5B5B5",
            }}
          >
            <Col md={1}>
              <Input
                type="checkbox"
                style={{ display: "flex" }}
                checked={isAllSelected}
                onChange={() => handleSelectAllTelasAssociadas()}
              />
            </Col>
            <Col>
              <p
                style={{
                  whiteSpace: "nowrap",
                  color: "var(--color-preto)",
                }}
              >
                Selecionar todos
              </p>
            </Col>
          </Row>
        ) : null}
        {loadingTelas && <LoadingIcon color="var(--color-roxo)" />}
        {telas
          .sort((a, b) => a.nomeTela?.localeCompare(b.nomeTela))
          .map((tela) => {
            const telaSistema = telasSistema.find(
              (telaSistema) => tela.id.toUpperCase() === telaSistema.id
            );
            return (
              <Row
                key={tela.nomeTela}
                style={{
                  flexWrap: "nowrap",
                  margin: "15px 15px 0 15px",
                  height: "35px",
                  borderBottom: "1px solid #B5B5B5",
                }}
              >
                {!(activeTab === "Visualize") && (
                  <Col md={1}>
                    <Input
                      key={tela.id}
                      type="checkbox"
                      style={{ display: "flex" }}
                      checked={checkIfTelaAssociadaIsSelecionada(tela.id)}
                      onChange={() => {
                        checkIfTelaAssociadaIsSelecionada(tela.id)
                          ? handleDeselecionarTelaAssociada(tela.id)
                          : handleSelecionarTelaAssociada(tela.id);
                      }}
                    />
                  </Col>
                )}
                <Col>
                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        color: "var(--color-preto)",
                      }}
                    >
                      {tela.nomeTela}
                    </p>
                    {telaSistema.options.criar && (
                      <BiIcons.BiEdit
                        style={
                          tela.permissoes.criar
                            ? {
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                margin: "0 0 0 20px",
                                color: "var(--color-verde)",
                              }
                            : {
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                margin: "0 0 0 20px",
                                color: "#C4C7CA",
                              }
                        }
                        onClick={() =>
                          !(activeTab === "Visualize") &&
                          handleChangePermition("criar", tela)
                        }
                      />
                    )}
                    {telaSistema.options.deletar && (
                      <BiIcons.BiTrash
                        style={
                          tela.permissoes.deletar
                            ? {
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                margin: "0 0 0 20px",
                                color: "var(--color-verde)",
                              }
                            : {
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                margin: "0 0 0 20px",
                                color: "#C4C7CA",
                              }
                        }
                        onClick={() =>
                          !(activeTab === "Visualize") &&
                          handleChangePermition("deletar", tela)
                        }
                      />
                    )}
                    {telaSistema.options.estornar && (
                      <VscDiscard
                        style={
                          tela.permissoes.estornar
                            ? {
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                margin: "0 0 0 20px",
                                color: "var(--color-verde)",
                              }
                            : {
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                margin: "0 0 0 20px",
                                color: "#C4C7CA",
                              }
                        }
                        onClick={() =>
                          !(activeTab === "Visualize") &&
                          handleChangePermition("estornar", tela)
                        }
                      />
                    )}
                    {telaSistema.options.autorizar && (
                      <GiCheckMark
                        style={
                          tela.permissoes.autorizar
                            ? {
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                margin: "0 0 0 20px",
                                color: "var(--color-verde)",
                              }
                            : {
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                margin: "0 0 0 20px",
                                color: "#C4C7CA",
                              }
                        }
                        onClick={() =>
                          !(activeTab === "Visualize") &&
                          handleChangePermition("autorizar", tela)
                        }
                      />
                    )}
                    {telaSistema.options.filtros && (
                      <AiOutlineFilter
                        style={
                          tela.permissoes.filtros
                            ? {
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                margin: "0 0 0 20px",
                                color: "var(--color-verde)",
                              }
                            : {
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                margin: "0 0 0 20px",
                                color: "#C4C7CA",
                              }
                        }
                        onClick={() =>
                          !(activeTab === "Visualize") &&
                          handleChangePermition("filtros", tela)
                        }
                      />
                    )}
                    {telaSistema.options.desbloquear && (
                      <TbLockOpen
                        style={
                          tela.permissoes.desbloquear
                            ? {
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                margin: "0 0 0 20px",
                                color: "var(--color-verde)",
                              }
                            : {
                                fontSize: "1.5rem",
                                cursor: "pointer",
                                margin: "0 0 0 20px",
                                color: "#C4C7CA",
                              }
                        }
                        onClick={() =>
                          !(activeTab === "Visualize") &&
                          handleChangePermition("desbloquear", tela)
                        }
                      />
                    )}
                  </div>
                </Col>
                {!(activeTab === "Visualize") && (
                  <Col md={1}>
                    <RiCloseFill
                      onClick={() => handleRemoveScreenFromAssociatedList(tela)}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                )}
              </Row>
            );
          })}
      </div>
    </Col>
  );
}
