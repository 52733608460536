export const COLUMNS = [
    {
        Header: "Descrição",
        accessor: "descricao",
    },
    {
        Header: "Instituição Financeira",
        accessor: "banco.nome",
    },
    {
        Header: "Convênio",
        accessor: "convenio.descricao",
    }
]


