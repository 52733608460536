import React from "react";
import { useSelector } from "react-redux";
import { Card, Col, Form, FormGroup, Input, Label } from "reactstrap";

import { removeAccentsAndUppercase } from "../../../util/masks";

import * as actions from "../../../store/modules/tipoFormalizacaoRibercred/actions";
import * as controller from "../../../controllers/tipoFormalizaçãoRibercredController";

const Register = () => {

    const { item, copy, invalid, seeRegister } = useSelector(state => state.tipoFormalizacaoRiber);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                controller.create(item);
            } else {
                controller.update(item, copy);
            };
        }
    }

    function onChange(value, field) {
        actions.cleanInvalid(field);
        actions.setItem(field, value);
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2" style={{ margin: '25px 0 0 25px' }}>
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>
                            Preencha os campos para cadastrar um novo Tipo de Formalização Ribercred
                        </p>
                    </Col>
                    <Form>
                        <Col style={{ margin: '25px 0 0 25px' }}>
                            <FormGroup>
                                <Label for="convenio" style={{ fontWeight: "700", color: 'var(--color-preto)' }}>
                                    Descrição
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Digite aqui..."
                                    value={item.descricao}
                                    onChange={e => onChange(removeAccentsAndUppercase(e.target.value), "descricao")}
                                    onKeyPress={e => handleKeyPress(e)}
                                    className={invalid.descricao ? "is-invalid" : ""}
                                    disabled={seeRegister ? true : false}
                                    style={{ width: '30%' }}
                                />
                            </FormGroup>
                        </Col>
                    </Form>
                </Card>
            </Col>
        </>
    );
}

export default Register;