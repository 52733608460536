import React, { useEffect } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import "../../style.css";
import { Card, Col, Form, Row, Button, TabContent, TabPane } from "reactstrap";
import Search from "./tabs/search";
import Register from "./tabs/register";
import { useSelector } from "react-redux";
import * as operacoesInstituicoesController from "../../controllers/operacaoInstituicaoController";
import * as operacoesInstituicoesActions from "../../store/modules/operacaoInstituicao/actions"
import Breadcrumb from '../../components/breadcrumbs';

const TipoOperacaoInstituicao = () => {

    const { item, activeTab, copy, seeRegister, permitions } = useSelector(state => state.operacaoInstituicao);

    useEffect(() => {
        if(window.location.search !== ''){
            operacoesInstituicoesActions.setActiveTab('Register')
        }else{
            operacoesInstituicoesActions.setActiveTab('Search')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function saveItem() {
        if (item.id === "") {
            operacoesInstituicoesController.create(item);
        } else {
            await operacoesInstituicoesController.update(item, copy);
        }
    }

    function changeTab(tab) {
        operacoesInstituicoesActions.setActiveTab(tab);
        operacoesInstituicoesActions.cleanRegister();
    }

    function seeRegisterF() {
        if (item.id !== "") {
            operacoesInstituicoesActions.cleanRegister();
        }
        operacoesInstituicoesActions.setSeeRegister(false);
    }

    return (
        <>
                <Col>
                    <Breadcrumb activeTab={activeTab} />
                    <Card>
                        <Form>
                            {
                                activeTab === "Search"
                                    ?
                                    permitions.criar && (
                                        <Col md={12}>
                                            <Row className="pt-2 pb-2">
                                                <Col md={8}>
                                                    <Button
                                                        onClick={() => changeTab("Register")}
                                                        style={{ width: "215px" }}
                                                        className='purpleButton'>
                                                        Cadastrar Operação Instituição
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                    :
                                    <Col className="pt-2 pb-2">
                                        <Button
                                            onClick={() => { changeTab("Search"); seeRegisterF() }}
                                            style={{ width: "150px", color: "white" }}
                                            className='purpleButton'>
                                            <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar
                                        </Button>
                                        {!seeRegister && <Button
                                            onClick={() => saveItem()}
                                            style={{ width: "242px", float: 'right' }}
                                            className="purpleButton">
                                            Salvar Cadastro
                                        </Button>}
                                    </Col>
                            }
                        </Form>
                    </Card>
                </Col>

                <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <br />
                        <Search />
                    </TabPane>
                    <TabPane tabId="Register">
                        <br />
                        <Register />
                    </TabPane>
                </TabContent>
        </>
    );
}

export default TipoOperacaoInstituicao;