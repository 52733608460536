import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    tab: {
        tab: '1',
        id: '877f4c3a-e576-46b8-810b-6ae24cdcff03'
    },
    tabs: [
        {
            tab: '1',
            id: '877f4c3a-e576-46b8-810b-6ae24cdcff03',
            quantidade: '0'
        },
        {
            tab: '2',
            id: '2876b674-6a87-45c2-b1d2-6e064899c21c',
            quantidade: '0'
        },
        {
            tab: '3',
            id: '3e23f30e-f541-42af-b3fb-7a48579626b6',
            quantidade: '0'
        },
        {
            tab: '4',
            id: 'e705cdbe-0405-4cbe-ab46-1c7be0a804c5',
            quantidade: '0'
        }
    ],
    getAllItems: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    isFirstPage: true,
    propostas: [],
    lojasBanco: [],
    tiposComissao: [],
    produtoBanco: [],
    permitions: {
        criar: false
    }
}

export default function inconsistenciaComissaoRecebida(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_TABS:
                    return { ...state, tabs: action.value }
                case TYPES.SET_TAB:
                    return { ...state, tab: action.value }
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.SET_PROPOSTAS:
                    return { ...state, propostas: action.value }
                case TYPES.SET_LOJAS_BANCO:
                    return { ...state, lojasBanco: action.value }
                case TYPES.SET_TIPOS_COMISSAO:
                    return { ...state, tiposComissao: action.value }
                case TYPES.SET_PRODUTO_BANCO:
                    return { ...state, produtoBanco: action.value }
                case TYPES.GET_ALL_ITEMS:
                    return { ...state, getAllItems: action.value }
                case TYPES.SET_IS_FIRST_PAGE:
                    return { ...state, isFirstPage: action.value }
                case TYPES.CLEAN_TABS:
                    return {
                        ...state,
                        propostas: INITIAL_STATE.propostas,
                        lojasBanco: INITIAL_STATE.lojasBanco
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}