import React, {  useMemo } from 'react'
import { useSelector } from 'react-redux';
import { BsArrowDown, BsArrowUp, BsArrowDownUp } from 'react-icons/bs';
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { Modal, ModalBody, Col, Button, Input, Row, Navbar, Table } from "reactstrap";
import { AiOutlineBarcode, AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineMinusCircle } from 'react-icons/ai';

import { COLUMNS_MODAL } from "../constants/columns";

import StepBar from './StepBar'
import * as actions from '../../../store/modules/formalizacaoLoja/actions'
import * as controller from '../../../controllers/formalizacaoLojaController'

import { notifyWarn } from '../../../components/message/message';

export default function ModalDetalhamento({modalDetalhamento, toggle}){
    const {modal} = useSelector(state => state.formalizacaoLoja);
    const columns = useMemo(() => COLUMNS_MODAL, []);
    const data = modal.propostas
  
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy);


    function handleKeyPress(e, campo) {
        if (e.key === "Enter") {
            e.preventDefault();
            setRecebido(e.target.value, campo)
        }
    }

    function setRecebido(value, campo){
        if(value !== ''){
            if(campo === 'codBarras'){
                modal.propostas.forEach(proposta=>{
                    if (proposta.codBarras === value && !modal.flagFinalizado){
                        proposta.statusEnvio = 1
                    }
                })
                actions.modal('propostas', modal.propostas)
                actions.modal(campo, '')
            }else if(campo === 'numProposta'){
                modal.propostas.forEach(proposta=>{
                    if (proposta.numProposta === value && !modal.flagFinalizado){
                        proposta.statusEnvio = 1
                    }
                })
                actions.modal('propostas', modal.propostas)
                actions.modal(campo, '')
            }else if(campo === 'numContrato'){
                modal.propostas.forEach(proposta=>{
                    if (proposta.numContrato === value && !modal.flagFinalizado){
                        proposta.statusEnvio = 1
                    }
                })
                actions.modal('propostas', modal.propostas)
                actions.modal(campo, '')
            }
        }
        document.getElementById(campo).focus()
    }

    function handleButton(modal, action){
        if(modal.codBarras){
            modal.propostas.forEach(proposta=>{
                if (proposta.codBarras === modal.codBarras && !modal.flagFinalizado){
                    proposta.statusEnvio = action === 'receber' ? 1 : 2
                }
            })
            actions.modal('propostas', modal.propostas)
            actions.modal('codBarras', '')
            document.getElementById("codBarras").focus()
        }else if(modal.numProposta){
            modal.propostas.forEach(proposta=>{
                if (proposta.numProposta === modal.numProposta && !modal.flagFinalizado){
                    proposta.statusEnvio = action === 'receber' ? 1 : 2
                }
            })
            actions.modal('propostas', modal.propostas)
            actions.modal('numProposta', '')
            document.getElementById("numProposta").focus()
        }else if(modal.numContrato){
            modal.propostas.forEach(proposta=>{
                if (proposta.numContrato === modal.numContrato && !modal.flagFinalizado){
                    proposta.statusEnvio = action === 'receber' ? 1 : 2
                }
            })
            actions.modal('propostas', modal.propostas)
            actions.modal('numContrato', '')
            document.getElementById("numContrato").focus()
        }
    }
    
    async function handleConfirmar(){
        let hasNotConfirmed = modal.propostas.find(e=> e.statusEnvio === 0)
        if(hasNotConfirmed !== undefined){
            notifyWarn("Confirme todas as propostas antes de continuar")
        }else{
            var isSucesses = await controller.update(modal)
        }
        isSucesses && toggle()
    }
    return(
        <Modal
            className="text-center"
            isOpen={modalDetalhamento}
            toggle={toggle}
            backdrop={true}
            size="lg"
            style={{maxWidth: '1600px', width: '80%'}}
        >
            <Navbar expand="md" style={{ width: "100%", height: "30px",margin: '20px 0 10px 20px'}}>
                <h5>{`Detalhamento de Borderô nº ${modal.numeroBordero}`}</h5>
            </Navbar>

            <ModalBody style={{margin:'0 0 0px 20px'}}>
                {modal.steps && (
                    <Row style={{width: '100%'}}>
                        <Col md={12}>
                            <StepBar steps={modal.steps}/>
                        </Col>
                    </Row>
                )}
                    <Row>
                        <Col md={4}>
                            <p style={{textAlign: 'initial', marginBottom: '7px', marginLeft: '3px'}}>Escanear código de barras</p>
                            <div style={{border: '1px solid #CCCFDE', borderRadius: '5px', display: 'flex'}}>
                                <Input
                                    id='codBarras'
                                    type="text"
                                    placeholder="Digite aqui..."
                                    value={modal.codBarras}
                                    onChange={e => actions.modal('codBarras', e.target.value)}
                                    onKeyPress={e => handleKeyPress(e, 'codBarras')}
                                    style={{border: 'none', boxShadow: 'none'}}
                                />
                                <AiOutlineBarcode style={{fontSize: '30px', marginTop: '1px', marginRight: '7px'}}/>
                            </div>
                        </Col>
                        <Col md={4}>
                            <p style={{textAlign: 'initial', marginBottom: '7px', marginLeft: '3px'}}>Número proposta</p>
                            <Input
                                id='numProposta'
                                type="text"
                                placeholder="Digite aqui..."
                                value={modal.numProposta}
                                onChange={e => actions.modal('numProposta', e.target.value)}
                                onKeyPress={e => handleKeyPress(e, 'numProposta')}
                            />
                        </Col>
                        <Col md={4}>
                            <p style={{textAlign: 'initial', marginBottom: '7px', marginLeft: '3px'}}>Número contrato</p>
                            <Input
                                id='numContrato'
                                type="text"
                                placeholder="Digite aqui..."
                                value={modal.numContrato}
                                onChange={e => actions.modal('numContrato', e.target.value)}
                                onKeyPress={e => handleKeyPress(e, 'numContrato')}
                            />
                        </Col>
                    </Row>
                    <Row>
                    <Col md={6}>
                            <div style={{display: 'flex', marginTop: '24px'}}>
                                <Button 
                                    style={{width: '150px', height: '34px', marginRight: '20px', color: 'var(--color-verde-claro)', background: 'transparent', border: '1px solid var(--color-verde-claro)', boxShadow: 'none'}}
                                    onClick={()=> handleButton(modal, 'receber')}
                                >
                                    <AiOutlineCheckCircle style={{fontSize: '20px'}}/> Recebido
                                </Button>
                                <Button 
                                    style={{width: '150px', minHeight: '34px', color: 'var(--color-laranja)', border: '1px solid var(--color-laranja)', background: 'transparent', boxShadow: 'none'}}
                                    onClick={()=> handleButton(modal,'negar')}
                                >
                                    <AiOutlineCloseCircle style={{fontSize: '20px'}}/> Não Recebido
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Table striped  style={{marginTop: '45px'}} {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th style={{background: 'transparent', border: 'none', fontWeight: 'bold'}}>
                                            {column.render("Header")}
                                            <span className="float-right">
                                                {column.isSorted ?
                                                    (column.isSortedDesc ?
                                                        <BsArrowDown
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />
                                                        :
                                                        <BsArrowUp
                                                            id="OrdenarAsc"
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />)
                                                    :
                                                    <BsArrowDownUp
                                                        {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                    />}
                                            </span>
                                        </th>
                                    ))}                                 
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row)
                                return (
                                    <tr className="text-center" {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            if(cell.column.Header === 'Status de envio'){
                                                return(                                               
                                                    <td style={{border: 'none'}} {...cell.getCellProps()}> {cell.row.original.statusEnvio === 1 ? <p style={{margin: '0', fontSize: '12px'}}><AiOutlineCheckCircle style={{marginRight: '10px', color: 'var(--color-verde-claro)', fontSize: '20px'}}/>Recebido</p> : cell.row.original.statusEnvio === 2 ? <p style={{margin: '0', fontSize: '12px'}}><AiOutlineCloseCircle style={{marginRight: '10px', color: 'var(--color-laranja)', fontSize: '20px'}}/>Não Recebido</p> : <p style={{margin: '0', fontSize: '12px'}}><AiOutlineMinusCircle style={{marginRight: '10px', color: '#81888A', fontSize: '20px'}}/>Não Confirmado</p>}</td>
                                                )
                                            }else{
                                                return(
                                                    <td style={{border: 'none'}} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                )
                                            }     
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
            </ModalBody>
            <Col>
            <Button
                style={{ width: "242px", float: 'right', marginBottom: '5px' }}
                className="purpleButton"
                disabled={modal.flagFinalizado}
                onClick={()=> handleConfirmar()}
            >
                Confirmar
            </Button>
            </Col>
        </Modal>
    )
}