import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function item(value) {
    dispatch({
        type: TYPES.PENDENCIA,
        value
    });
}

export function setItem(field, value) {
    dispatch({
        type: TYPES.SET_PENDENCIA,
        field,
        value
    });
}

export function copy(value) {
    dispatch({
        type: TYPES.PENDENCIA_COPY,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_PENDENCIA,
        field
    });
}


export function getItems(value) {
    dispatch({
        type: TYPES.PENDENCIAS,
        value
    });
}

export function getBancos(value) {
    dispatch({
        type: TYPES.BANCOS,
        value
    });
}

export function getPendenciasRiber(value) {
    dispatch({
        type: TYPES.PENDENCIAS_RIBER,
        value
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_PENDENCIA,
        field
    });
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}


export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}
