import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as actions from "../store/modules/pendenciaInstituicao/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("tipo-pendencia-banco");
    setLoading(false)

    if (!data.success) {
        return;
    }

    actions.getItems(data.content);
}

export async function getBancos() {

    setLoading(true)
    const data = await api.get("bancos");
    setLoading(false)

    if (!data.success) {
        return;
    }

    actions.getBancos(data.content);
}
export async function getPendenciasRiber() {

    setLoading(true)
    const data = await api.get("tipo-pendencia");
    setLoading(false)

    if (!data.success) {
        return;
    }

    actions.getPendenciasRiber(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`tipo-pendencia-banco/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    let newContent = {
        id: data.content.id,
        descricao: data.content.descricao,
        bancoId: data.content.banco.id,
        pendenciaRiber: data.content.tipoPendencia.id,
    }

    actions.copy(newContent);
    actions.item(newContent);
}

export async function create(item) {

    if (!item.descricao || item.descricao.trim() === "") {
        actions.setInvalid("descricao");
        notifyWarn("Descrição não pode estar em branco");
        return;
    }

    if (item.bancoId === '0') {
        actions.setInvalid("bancoId");
        notifyWarn("Instituição financeira não pode estar em branco");
        return;
    }

    if (item.pendenciaRiber === '0') {
        actions.setInvalid("pendenciaRiber");
        notifyWarn("Tipo Pendência Ribercred não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao,
        tipoPendenciaId: item.pendenciaRiber,
        bancoId: item.bancoId
    }

    setLoading(true)
    const data = await api.post("tipo-pendencia-banco", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Pendência instituição cadastrada");
    actions.cleanRegister();
    get();
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (!item.descricao || item.descricao.trim() === "") {
        actions.setInvalid("descricao");
        notifyWarn("Descrição não pode estar em branco");
        return;
    }

    if (item.bancoId === '0') {
        actions.setInvalid("bancoId");
        notifyWarn("Instituição financeira não pode estar em branco");
        return;
    }

    if (item.pendenciaRiber === '0') {
        actions.setInvalid("pendenciaRiber");
        notifyWarn("Tipo Pendência Ribercred não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao,
        tipoPendenciaId: item.pendenciaRiber,
        bancoId: item.bancoId
    }

    setLoading(true)
    const data = await api.put(`tipo-pendencia-banco/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Pendência ribercred atualizada");
    actions.cleanRegister();
    actions.setActiveTab("Search");
    get();
}


export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`tipo-pendencia-banco/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Pendência instituição excluída");
    get();
}