import React, { useEffect } from "react";
import { IoIosArrowBack } from 'react-icons/io';
import "../../style.css";
import { Card, Col, Form, Row, Button, TabContent, TabPane } from "reactstrap";
import Search from "./tabs/search";
import Register from "./tabs/register";
import { useSelector } from "react-redux";
import * as inconsistenciaActions from "../../store/modules/inconsistencias/actions";
import * as inconsistenciaController from "../../controllers/inconsistenciasController";
import Breadcrumb from "../../components/breadcrumbs";

const Inconsistencia = () => {

    const { item, copy, activeTab, seeRegister, permitions } = useSelector(state => state.inconsistencias);

    useEffect(() => {
        if(window.location.search !== ''){
            inconsistenciaActions.setActiveTab('Register')
        }else{
            inconsistenciaActions.setActiveTab('Search')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function saveItem() {
        if (item.id === "") {
            inconsistenciaController.create(item);
        } else {
            inconsistenciaController.update(item, copy);
        }
    }

    function changeTab(tab) {
        inconsistenciaActions.setActiveTab(tab);
        inconsistenciaActions.cleanRegister();
    }

    function seeRegisterF() {
        if (item.id !== "") {
            inconsistenciaActions.cleanRegister();
        }
        inconsistenciaActions.setSeeRegister(false);
    }

    return (
        <>
                <Col>
                    <Breadcrumb activeTab={activeTab} />
                    <Card>
                        <Form>
                            {
                                activeTab === "Search"
                                    ?
                                    permitions.criar && (
                                        <Col md={12}>
                                            <Row className="pt-2 pb-2">
                                                <Col md={8}>
                                                    <Button
                                                        onClick={() => changeTab("Register")}
                                                        style={{ width: "210px" }}
                                                        className='purpleButton'
                                                    >
                                                        Cadastrar Consistência
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                    :
                                    <Col className="pt-2 pb-2">
                                        <Button
                                            onClick={() => { changeTab("Search"); seeRegisterF() }}
                                            style={{ width: "150px", color: "white" }}
                                            className='purpleButton'>
                                            <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar
                                        </Button>
                                        {!seeRegister && <Button
                                            onClick={() => saveItem()}
                                            style={{ width: "242px", float: 'right' }}
                                            className="purpleButton"
                                        >
                                            Salvar Cadastro
                                                        </Button>}
                                    </Col>
                            }
                        </Form>
                    </Card>
                </Col>

                <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <br />
                        <Search />
                    </TabPane>
                    <TabPane tabId="Register">
                        <br />
                        <Register />
                    </TabPane>
                </TabContent>
        </>
    );
}

export default Inconsistencia;