import api, { client } from "../util/api";
import {
  notifyWarn,
  notifySuccess,
  notifyInfo,
  notifyError,
} from "../components/message/message";

import * as actions from "../store/modules/usuario/actions";
import { ValidateCNPJ, ValidateCPF, ValidateEmail } from "../util/validations";
import { removeMaskCPF, maskCPF } from "../util/masks";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function getAll(page, rows, search) {
  const strSeach = JSON.stringify(search);
  let arrSeach = JSON.parse(strSeach);

  if (arrSeach.email === "" && ValidateEmail(arrSeach.email)) {
    return;
  }

  arrSeach = { ...arrSeach, cpf: removeMaskCPF(arrSeach.cpf) };

  setLoading(true);
  const data = await api.get(
    `Autenticacao?Email=${search.email}&Nome=${search.nome}&Cpf=${
      search.cpf
    }&Ativo=${search.status}&Pagina=${page + 1}&Registros=${rows}`
  );
  setLoading(false);

  if (!data.success) {
    return;
  }
  data.content.registros.forEach((usuario) => {
    usuario.cpf = usuario.cpf;
  });
  actions.setItems(data.content.registros);
  actions.getAllItems({
    quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
  });
}

export async function getPessoa(cpfCnpj, item) {
  try {
    setLoading(true);
    const { data } = await client.get(`Pessoa/${removeMaskCPF(cpfCnpj)}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("TOKEN_KEY"),
        "Content-Type": "application/json",
      },
    });
    actions.setItem({
      ...item,
      cpf: cpfCnpj,
      nome: data.content.nome,
      pessoaId: data.content.id,
    });
    setLoading(false);
  } catch (error) {
    setLoading(false);
    if (!error?.response?.data?.messages === "Pessoa não encontrada") {
      notifyError(error?.response?.data?.messages[0]);
    }
    return error?.response?.data?.messages[0];
  }
}

export async function create(item, passwords) {
  const strItem = JSON.stringify(item);
  let arrItem = JSON.parse(strItem);

  if (arrItem.nome === "") {
    notifyWarn("Nome não pode estar em branco");
    actions.setInvalid("nome");
    return;
  }

  if (arrItem.perfilId === "0") {
    notifyWarn("Perfil não pode estar em branco");
    actions.setInvalid("perfil");
    return;
  }

  if (arrItem.cpf === "" || ValidateCPF(arrItem.cpf)) {
    notifyWarn("CPF inválido");
    actions.setInvalid("cpf");
    return;
  }
  if (arrItem.email === "" || !ValidateEmail(arrItem.email)) {
    notifyWarn("E-mail inválido");
    actions.setInvalid("email");
    return;
  }
  if (passwords.senha === "") {
    notifyWarn("Senha inválida");
    actions.setInvalid("senha");
    return;
  }

  if (passwords.senha !== passwords.reSenha) {
    notifyWarn("Senhas não coincidem");
    actions.setInvalid("reSenha");
    return;
  }

  let body = {};

  if (arrItem.pessoaId) {
    body = {
      nome: arrItem.nome,
      email: arrItem.email,
      senha: passwords.senha,
      grupoAcessoId: arrItem.perfilAcessoId,
      cpf: removeMaskCPF(arrItem.cpf),
      pessoaId: arrItem.pessoaId,
      usuarioSistemas: arrItem.usuarioSistemas,
    };
  } else {
    body = {
      nome: arrItem.nome,
      email: arrItem.email,
      senha: passwords.senha,
      grupoAcessoId: arrItem.perfilAcessoId,
      cpf: removeMaskCPF(arrItem.cpf),
      usuarioSistemas: arrItem.usuarioSistemas,
      pessoaId: arrItem.pessoaId,
    };
  }

  setLoading(true);
  const data = await api.post(`Autenticacao`, body);
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Usuário cadastrado");
  actions.cleanRegister();
  getAll(0, 10, {
    cpf: "",
    email: "",
    nome: "",
  });
  return true;
}

export async function update(item, copy) {
  const strItem = JSON.stringify(item);
  let arrItem = JSON.parse(strItem);

  if (JSON.stringify(arrItem) === JSON.stringify(copy)) {
    notifyInfo("Nada foi alterado");
    return;
  }

  if (arrItem.nome === "") {
    notifyWarn("Nome não pode estar em branco");
    actions.setInvalid("nome");
    return;
  }

  if (arrItem.cpf === "" || !ValidateCPF(arrItem.cpf) && !ValidateCNPJ(arrItem.cpf)) {
    notifyWarn("CPF inválido");
    actions.setInvalid("cpf");
    return;
  }

  if (arrItem.perfilAcessoId === "0") {
    notifyWarn("Perfil não pode estar em branco");
    actions.setInvalid("perfil");
    return;
  }

  if (arrItem.email === "" || !ValidateEmail(arrItem.email)) {
    notifyWarn("E-mail inválido");
    actions.setInvalid("email");
    return;
  }

  let body = {};

  const usuarioSistemas = arrItem.usuarioSistemas.filter(
    (sistema) => sistema.id === null
  );

  if (arrItem.pessoaId) {
    body = {
      id: arrItem.id,
      nome: arrItem.nome,
      email: arrItem.email,
      grupoAcessoId: arrItem.perfilAcessoId,
      cpf: arrItem.cpf,
      pessoaId: arrItem.pessoa ? arrItem.pessoa.id : null, 
      usuarioSistemas: usuarioSistemas,
      
    };
  } else {
    body = {
      id: arrItem.id,
      nome: arrItem.nome,
      email: arrItem.email,
      grupoAcessoId: arrItem.perfilAcessoId,
      cpf: arrItem.cpf,
      pessoaId: arrItem.pessoa ? arrItem.pessoa.id : null,
      usuarioSistemas: arrItem.usuarioSistemas.filter(
        (sistema) => sistema.id === null
      ),
    };
  }

  setLoading(true);
  const data = await api.put(`Autenticacao`, body);
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Usuário atualizado");
  getAll(0, 10, {
    cpf: "",
    email: "",
    nome: "",
  });
  return true;
}
export async function unblockUser(id){
  setLoading(true);
  const data = await api.put(`Autenticacao/${id}/desbloqueio`);
  setLoading(false);

  if (!data.success) {
    return;
  }
  notifySuccess("Usuário desbloqueado");
}
export async function deleteById(id, page, rows, search) {
  setLoading(true);
  const data = await api.delete(`Autenticacao/${id}`);
  setLoading(false);

  if (!data.success) {
    return;
  }
  notifySuccess("Usuário removido");
  getAll(page, rows, search);
}

export async function activateById(id, page, rows, search) {
  setLoading(true);
  const data = await api.put(`Autenticacao/${id}`);
  setLoading(false);

  if (!data.success) {
    return;
  }
  notifySuccess("Usuário ativado");
  getAll(page, rows, search);
}

export async function getById(id, items) {
  let item = items.find((item) => item.id === id);
  const usuarioSistemas = item.usuarioSistemas.map((s) => ({
    id: s.usuarioSistemaId,
    sistemaId: s.sistema.id,
  }));

  actions.setItem({ ...item, usuarioSistemas });
  actions.copy(item);
}

export async function updatePass(id, passwords) {
  const strPasswords = JSON.stringify(passwords);
  let arrPasswords = JSON.parse(strPasswords);

  if (arrPasswords.senha === "") {
    notifyWarn("Senha inválida");
    actions.setInvalid("senha");
    return;
  }

  if (arrPasswords.senha !== arrPasswords.reSenha) {
    notifyWarn("Senhas não coincidem");
    actions.setInvalid("reSenha");
    return;
  }

  let body = {
    id: id,
    novaSenha: arrPasswords.senha,
  };

  setLoading(true);
  const data = await api.put(`Autenticacao/alterar-senha`, body);
  setLoading(false);

  if (!data.success) {
    return;
  }

  notifySuccess("Senha atualizada");
}

export async function getPerfis() {
  setLoading(true);
  const data = await api.get(`GrupoAcesso`);
  setLoading(false);

  if (!data.success) {
    return;
  }
  actions.setPerfis(data.content);
}

export async function getSistemas() {
  setLoading(true);
  const data = await api.get(`Sistemas/listaSistemas`);
  setLoading(false);

  if (!data.success) {
    return;
  }

  actions.setSistemas(data.content);
}

export async function removerSistemaVinculado(usuarioSistemaId) {
  setLoading(true);
  const data = await api.delete(
    `UsuarioSistema/desvinculaSistema/${usuarioSistemaId}`
  );
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Sistema desvinculado");
  return true;
}
