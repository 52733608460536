import React, { useMemo } from "react";
import { Col, Row } from "reactstrap";
import * as BiIcons from "react-icons/bi";
import { useTable, usePagination } from "react-table";
import * as gruposActions from "../../../store/modules/grupos/actions";
import { COLUMNS_PERSONA } from "../constants/columns";
import { useSelector } from "react-redux";
import { maskCNPJ, maskCPF } from "../../../util/masks";

function TabelaPessoas() {

    const { item } = useSelector(state => state.grupo);
    const columns = useMemo(() => COLUMNS_PERSONA, []);
    const data = item.pessoas;

    const {
        getTableBodyProps,
    } = useTable({
        columns,
        data
    },
        usePagination);

    function handleRemoverItem(index) {
        const array = item.pessoas
        array.splice(index, 1)
        item.pessoas = array
        gruposActions.setPessoas(item.pessoas)
    }

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>CPF/CNPJ</th>
                        <th style={{ textAlign: 'center' }}>Ações</th>
                    </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                    {data.map((row, index) => {
                      
                      let cpfCnpjFormated
                        if(row.cpfCnpj.length === 14 && row.cpfCnpj.indexOf("x") === -1){
                            cpfCnpjFormated = maskCNPJ(row.cpfCnpj)
                        }else{
                            cpfCnpjFormated =
                            row.cpfCnpj.indexOf("x") !== -1 ? row.cpfCnpj
                              : maskCPF(row.cpfCnpj);
                        }
                        return (
                            <>
                                <tr>
                                    <td>{row.nome}</td>
                                    <td>{cpfCnpjFormated}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <>
                                            <BiIcons.BiTrash
                                                style={{ fontSize: '1.2rem', cursor: 'pointer', color: '#C4C7CA' }}
                                                onClick={() => handleRemoverItem(index)}
                                            />
                                        </>
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </table>
            <Row className="pt-4">
                <Col>
                    <span style={{ color: "var(--color-cinza-medio)" }}>{data.length} pessoas vinculadas</span>
                </Col>
            </Row>
        </>
    )
}

export default TabelaPessoas
