import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    filtros: {
        lojaId: '0',
        loja: '',
        centroCustoId: '0',
        tipoData: '1',
        dataInicio: '',
        dataFim: '',
        bancoId: '0',
        tipoComissaoId: '0',
        produtoId: '0',
        convenioId: '0',
        canalId: '0',
        grupoId: '0',
    },
    modalLojas: {
        id: '',
        nome: '',
        cnpj: '',
    },
    getAllLojas: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    bigNumbers: {
        comissaoRecebida: {
            totalPago: '0',
            qtde: '0',
            valorComparado: '0',
            crescimento: '0',
            media: '0',
            valor: '0',
        },
        provisionamento: {
            totalPago: '0',
            qtde: '0',
            valorComparado: '0',
            crescimento: '0',
            media: '0',
            valor: '0',
        }
    },
    tabelaBanco: [],
    tabelaCanal: [],
    tabelaCentroCusto: [],
    tabelaProduto: [],
   
    lojas: [],
    centrosCusto: [],
    bancos: [],
    tiposComissao: [],
    produtos: [],
    convenios: [],
    canais: [],
    grupos: [],
    loadingLojas: false,
}

export default function dashboardPrevisao(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_FILTROS:
                    return { ...state, filtros: { ...state.filtros, [action.campo]: action.value } }
                case TYPES.SET_BIG_NUMBERS:
                    return { ...state, bigNumbers: action.value }
                case TYPES.SET_TABELA_BANCO:
                    return { ...state, tabelaBanco: action.value }
                case TYPES.SET_TABELA_CANAL:
                    return { ...state, tabelaCanal: action.value }
                case TYPES.SET_TABELA_CENTRO_CUSTO:
                    return { ...state, tabelaCentroCusto: action.value }
                case TYPES.SET_TABELA_PRODUTO:
                    return { ...state, tabelaProduto: action.value }
                case TYPES.SET_LOJAS:
                    return { ...state, lojas: action.value }
                case TYPES.SET_GET_ALL_LOJAS:
                    return { ...state, getAllLojas: action.value }
                case TYPES.SET_MODAL_LOJA:
                    return { ...state, modalLojas: { ...state.modalLojas, [action.campo]: action.value } }
                case TYPES.SET_CENTROS_CUSTO:
                    return { ...state, centrosCusto: action.value }
                case TYPES.SET_BANCOS:
                    return { ...state, bancos: action.value }
                case TYPES.SET_TIPOS_COMISSAO:
                    return { ...state, tiposComissao: action.value }
                case TYPES.SET_PRODUTOS:
                    return { ...state, produtos: action.value }
                case TYPES.SET_CONVENIOS:
                    return { ...state, convenios: action.value }
                case TYPES.SET_CANAIS:
                    return { ...state, canais: action.value }
                case TYPES.SET_GRUPOS:
                    return { ...state, grupos: action.value }
                case TYPES.CLEAN_SEARCH:
                    return { ...state, filtros: INITIAL_STATE.filtros }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, 
                                bigNumbers: [],
                                tabelaBanco: [],
                                tabelaCanal: [],
                                tabelaCentroCusto: [],
                                tabelaProduto: [],
                                 }
                case TYPES.SET_LOADING_LOJAS:
                    return { ...state, loadingLojas: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}