import { STORE_ID, TYPES } from "./types";

const INITIAL_STATE = {
  sistemas: [],
  item: {
    id: "",
    cpf: "",
    nome: "",
    email: "",
    status: "",
    pessoaId: "",
    perfilAcessoId: "0",
    usuarioSistemas: [],
    bloqueado: false,
  },
  copy: {},
  invalid: {
    nome: false,
    cpf: false,
    email: false,
    senha: false,
    reSenha: false,
  },
  passwords: {
    senha: "",
    reSenha: "",
  },
  bloqueado: {
    bloqueado: true,
    desbloqueado: false,
  },
  search: {
    cpf: "",
    email: "",
    nome: "",
    status: "",
  },
  verifyPass: {
    power: 0,
    isStrong: true,
    misMatching: true,
  },
  items: [],
  perfis: [],
  statusUsers: ([] = [
    { id: false, descricao: "Inativo" },
    { id: true, descricao: "Ativo" },
  ]),
  activeTab: "Search",
  seeRegister: false,
  seeUpdate: false,
  changePass: false,
  getAllItems: {
    quantidade: "0",
    quantidadeTotal: "0",
    totalPaginas: "0",
  },
  permitions: {
    criar: false,
    deletar: false,
    autorizar: false,
    desbloquear: false,
  },
};

export default function usuario(state = INITIAL_STATE, action) {
  switch (action.storeId) {
    case STORE_ID:
      switch (action.type) {
        case TYPES.SET_ITEMS:
          return { ...state, items: action.value };
        case TYPES.SET_ITEM:
          return { ...state, item: action.value };
        case TYPES.SET_PERMITIONS:
          return { ...state, permitions: action.value };
        case TYPES.SET_PERFIS:
          return { ...state, perfis: action.value };
        case TYPES.INVALID_USUARIO:
          return {
            ...state,
            invalid: { ...state.invalid, [action.field]: true },
          };
        case TYPES.CLEAN_INVALID_USUARIO:
          return {
            ...state,
            invalid: { ...state.invalid, [action.field]: false },
          };
        case TYPES.SET_BLOCK:
            return {
                ...state,
                bloqueado: { ...state.bloqueado, [action.field]: true },
            };
        case TYPES.USUARIO_COPY:
          return { ...state, copy: action.value };
        case TYPES.CLEAN_REGISTER:
          return {
            ...state,
            item: {
              ...INITIAL_STATE.item,
              reSenha: "",
            },
            passwords: {
              senha: "",
              reSenha: "",
            },
            verifyPass: INITIAL_STATE.verifyPass,
            invalid: INITIAL_STATE.invalid,
            seeUpdate: INITIAL_STATE.seeUpdate,
            changePass: INITIAL_STATE.changePass,
          };
        case TYPES.SET_ACTIVE_TAB:
          return { ...state, activeTab: action.value };
        case TYPES.SET_SEE:
          return { ...state, seeRegister: action.value };
        case TYPES.SEARCH:
          return { ...state, search: action.value };
        case TYPES.CLEAN_SEARCH:
          return {
            ...state,
            search: INITIAL_STATE.search,
            items: INITIAL_STATE.items,
            getAllItems: INITIAL_STATE.getAllItems,
          };
        case TYPES.VERIFYPASS:
          return { ...state, verifyPass: action.value };
        case TYPES.SEEUPDATE:
          return { ...state, seeUpdate: action.value };
        case TYPES.CHANGEPASS:
          return { ...state, changePass: action.value };
        case TYPES.SETSENHA:
          return { ...state, passwords: action.value };
        case TYPES.GET_ALL_ITEMS:
          return { ...state, getAllItems: action.value };
        case TYPES.SET_SISTEMAS:
          return { ...state, sistemas: action.value };
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
}
