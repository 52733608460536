import React, { useEffect } from "react";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Select } from '../../../components/Inputs/Input'
import { useSelector } from "react-redux";
import { removeAccentsAndUppercase } from "../../../util/masks";

import { Switch } from '../../../components/Inputs/Input'

import * as hierarquiasActions from "../../../store/modules/hierarquia/actions";
import * as hierarquiasController from "../../../controllers/hierarquiasController";

const Register = () => {

    const { item, invalid, canaisVendas, seeRegister } = useSelector(state => state.hierarquia);

    function onChangeHierarquia(value, field) {
        hierarquiasActions.cleanInvalid(field);
        hierarquiasActions.item({ ...item, [field]: value });
    }

    useEffect(()=>{
        hierarquiasController.getCanaisVenda()
    },[]);

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2" style={{margin: '25px 0 0 25px'}}>
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>
                            Preencha os campos para cadastrar uma nova Hierarquia
                        </p>
                    </Col>
                    <Col className="pt-2">
                        <Form>
                            <Row md={11} className="d-flex justify-content-center">
                                <Col style={{margin: '25px 0 0 25px'}}>
                                    <FormGroup>
                                        <Row>
                                        <Col md={3} style={{margin: '7px 0 '}}>
                                        <Label for="hierarchy" style={{fontWeight: '700', color: 'var(--color-preto)'}}>
                                            Descrição da Hierarquia
                                        </Label>
                                        <Input
                                            type="text"
                                            name="hierarchy"
                                            id="hierarchy"
                                            placeholder="Digite aqui..."
                                            value={item.descricao}
                                            onChange={e => onChangeHierarquia(removeAccentsAndUppercase(e.target.value), "descricao")}
                                            className={invalid.descricao ? "is-invalid" : ""}
                                            disabled={seeRegister ? true : false}
                                        />
                                        </Col>
                                        <Col md={3} style={{ maxWidth:'170px', margin: '7px 0 ' }} className="d-flex flex-column align-items-center">
                                            <Label style={{fontWeight: '700', color: 'var(--color-preto)'}}>
                                                Considerar headcount
                                            </Label>
                                            <div style={{alignItems:'flex-start'}}>
                                            <Switch
                                            className='switch'
                                            checked={item.headCount}
                                            onChange={() => onChangeHierarquia(!item.headCount, "headCount")}
                                            disabled={seeRegister}
                                            />
                                            </div>
                                        </Col>
                                        <Col md={3} style={{margin: '7px 0 '}}>
                                            <Label for="hierarchy" style={{fontWeight: '700', color: 'var(--color-preto)'}}>
                                                Canal de Venda
                                            </Label>
                                            <Select
                                                first="Selecione"
                                                value={{ value: item.canalId }}
                                                select={{ value: "id", label: "nome" }}
                                                options={canaisVendas}
                                                onChange={e => onChangeHierarquia(e.value, "canalId")}
                                                autoComplete="off"
                                                disabled={seeRegister ? true : false}
                                            />
                                        </Col>
                                        <Col style={{margin: '7px 0', display: 'flex', flexDirection: 'column'}}>
                                            <p style={{fontWeight: '700', color: 'var(--color-preto)', margin: '0 0 7px'}}>
                                                Multiplos Centros de Custo
                                            </p>
                                            <Switch
                                                className='switch'
                                                checked={item.multiplosCentroCusto}
                                                onChange={e => onChangeHierarquia(!item.multiplosCentroCusto, "multiplosCentroCusto")}
                                                disabled={seeRegister}
                                            />
                                        </Col>
                                            <Col style={{margin: '7px 0 ', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                <p style={{fontWeight: '700', color: 'var(--color-preto)', margin: '0 0 7px'}}>
                                                    Consultor
                                                </p>
                                                <Switch
                                                    className='switch'
                                                    checked={item.consultor}
                                                    onChange={e => onChangeHierarquia(!item.consultor, "consultor")}
                                                    disabled={seeRegister}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Card>
            </Col>
        </>
    );
}

export default Register;
