import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as convenioActions from "../store/modules/conveniosRibercred/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("convenios");
    setLoading(false)

    if (!data.success) {
        return;
    }

    convenioActions.getItems(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`convenios/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    convenioActions.copy(data.content);
    convenioActions.item(data.content);
}

export async function create(item) {

    if (item.descricao.trim() === "") {
        convenioActions.setInvalid("descricao");
        notifyWarn("Descrição do convênio ribercred não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao,
    }

    setLoading(true)
    const data = await api.post("convenios", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Convênio ribercred cadastrado");
    convenioActions.cleanRegister();
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`convenios/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Convênio ribercred removido");
    get();
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (item.descricao.trim() === "") {
        convenioActions.setInvalid("descricao");
        notifyWarn("Descrição do convênio não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao
    }

    setLoading(true)
    const data = await api.put(`convenios/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Convênio ribercred atualizado");
    convenioActions.cleanRegister();
    convenioActions.setActiveTab("Search");
    get();
}
