import styled from "styled-components";

export const SidebarNav = styled.nav`
    background: #ffffff;
    width: 253px;
    position: fixed;
    z-index: 1;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    transition: 0.5s ease-in-out; 
    overflow-y: auto;

    ::-webkit-scrollbar-track {
        background-color: #F4F4F4;
        border-top: 30px;
    }
    ::-webkit-scrollbar {
        width: 8px;
        background: #F4F4F4;
        border-top: 30px;
    }
    ::-webkit-scrollbar-thumb {
        background: #dad7d7;
        border-radius: 7px;
        border-top: 30px;
    }
`;

export const SidebarNavMini = styled.nav`
    position: fixed;
    background: #ffffff;
    width: 85px;
    height: 100vh;
    max-height: 100vh;
    justify-content: center;
    overflow: auto;
`;

export const SidebarWrap = styled.div`
    width: 100%;
`;

export const SidebarContainer = styled.div`
    min-height: 100vh;
    width: 85px;
`;

export const Container = styled.div`
    min-height: 100vh;
    width: calc(100vw - 80px);
    background-color: #f5f6fa;
`;

export const PageContentContainer = styled.div`
    position: relative;
    width: ${props => (props.isSidebarFixed ? 'calc(100% - 15.813rem + 3.313rem)' : 'calc(100% - 85px)')};
    left: ${props => (props.isSidebarFixed ? '12rem' : 'calc(100% - 85px)')};
`;