import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    tab: {
        tab: '1',
        id: '3ad59592-cc69-45fc-92d5-0181c55326ab'
    },
    tabs:[
        {
            tab: '1',
            id: '3ad59592-cc69-45fc-92d5-0181c55326ab',
            quantidade: '0'
        },
        {
            tab: '2',
            id: 'a7858d1a-1d58-4333-b408-eabc7b97a25d',
            quantidade: '0'
        }
    ],
    codConsistencias: [],
    numerosProposta: [],
    isFirstPage: true,
    permitions:{
        criar: false
    },
    getAllItems: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
}

export default function inconsistenciaProposta(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_TABS:
                    return { ...state, tabs: action.value }
                case TYPES.SET_TAB:
                    return { ...state, tab: action.value }
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.SET_COD_CONSISTENCIAS:
                    return { ...state, codConsistencias: action.value }
                case TYPES.SET_NUMEROS_PROPOSTA:
                    return { ...state, numerosProposta: action.value }     
                case TYPES.GET_ALL_ITEMS:
                    return { ...state, getAllItems: action.value }                   
                case TYPES.SET_IS_FIRST_PAGE:
                    return { ...state, isFirstPage: action.value }                   
                case TYPES.CLEAN_TABS:
                return { ...state,
                    propostas: INITIAL_STATE.numerosProposta,
                     lojasBanco: INITIAL_STATE.codConsistencias}
            default:
                    return { ...state }
                }
                default:
                    return { ...state }
            }
        }