import api from '../../util/api'
import * as papeisActions from '../../store/modules/personas/papeis_store/actions'
import { notifySuccess } from '../../components/message/message'
import { setLoading } from '../../store/modules/globalLoading/actions'

export async function getConvenios() {
    var data = await api.get('convenios')
    if (!data.success) {
        return
    }
    if (data.content.length > 0) {
        papeisActions.setConvenios(data.content)
    }
}

export async function desvincularCentroCusto(loja, centroCusto) {
    setLoading(true)
    const data = await api.delete(`pessoa/lojas/${loja}/centrocusto/${centroCusto.id}`)
    setLoading(false)

    if (!data.success) {
        return false
    }

    return true
}

export async function getUsuarioBanco(idLoja, idPessoa) {
    const data = await api.get(`UsuarioBanco/loja/${idLoja}/pessoa/${idPessoa}`);

    if (!data.success) {
      return;
    }

    papeisActions.setUsuariosBanco(data.content);
  }

export async function getCentrosCustoV() {
    const data = await api.get('CentrosCusto/CentroCustoVinculado')

    if (!data.success) {
        return
    }

    papeisActions.setCentroCustoV(data.content)
}

export async function getCentrosCusto(idLoja) {
    setLoading(true)
    const data = await api.get(`CentrosCusto/vinculo/lojas/${idLoja}`)
    setLoading(false)

    if (!data.success) {
        return
    }

    papeisActions.setCentroCusto(data.content)
}

export async function getCentrosCustoParaSelect(idLoja){
    setLoading(true);
    const data = await api.get(`CentrosCusto/vinculo/lojas/${idLoja}`)
    setLoading(false);

    papeisActions.setCentroCusto(data.content)
}

export async function getInstituicoes() {
    const data = await api.get('bancos')

    if (!data.success) {
        return
    }
    papeisActions.setInstituicoesFinanceiras(data.content)
}

export async function getInstituicoesBancarias() {
    const data = await api.get('Bancos')

    if (!data.success) {
        return
    }

    const bancos = []

    data.content.forEach(banco => {
        bancos.push({
            nome: banco.nome,
            id: banco.id
        })
    })

    papeisActions.setInstituicoesBancarias(bancos)
}

export async function getRegimesTributario() {
    const data = await api.get('regimeTributário')

    if (!data.success) {
        return
    }
    papeisActions.setRegimesTrib(data.content)
}


export async function getTiposLoja() {
    const data = await api.get('tipoLoja')

    if (!data.success) {
        return
    }
    papeisActions.tiposLoja(data.content)
}

export async function getCanais(isSelect) {
    setLoading(true);
    const data = await api.get('canal')
    setLoading(false);

    if (!isSelect){
        if (!data.success) {
            return
        }
    }

    papeisActions.setCanais(data.content)
}

export async function getParcerias() {
    const data = await api.get('tipoParceira')

    if (!data.success) {
        return
    }
    papeisActions.tiposParcerias(data.content)
}

export async function getHierarquia(canalId) {
    setLoading(true)
    const data = await api.get(`Hierarquias/hierarquia-canal/${canalId}`);
    setLoading(false);

    if (!data.success) {
        return
    }
    papeisActions.niveisHierarquicos(data.content)
}

export async function getHierarquiaParaSelect(canalId){
    setLoading(true)
    const data = await api.get(`Hierarquias/hierarquia-canal/${canalId}`);
    setLoading(false);

    papeisActions.niveisHierarquicos(data.content)
}

export async function getMatrizes(canalId) {
    const data = await api.get(`lojas/canal/${canalId}/matriz`)

    if (!data.success) {
        return
    }
    papeisActions.setMatrizes(data.content)
}

export async function getAusencias() {
}

export async function getFuncionarios() {
    const data = await api.get('tipoFuncionario')

    if (!data.success) {
        return
    }
    papeisActions.setTipoFuncionario(data.content)
}

export async function getUsuarioFuncionario(canalId) {
    const data = await api.get(`Pessoa/loja/${canalId}/funcionarios`)

    if (!data.success) {
        return
    }
    papeisActions.usuarioFuncionario(data.content)
}

export async function getPerfisCliente() {
    const data = await api.get('clienteperfil')

    if (!data.success) {
        return
    }
    papeisActions.setPerfilUsuarios(data.content)
}

export async function getProfissoes() {
    const data = await api.get('profissoes')

    if (!data.success) {
        return
    }
    papeisActions.setProfissoes(data.content)
}

export async function getMasters(idBanco) {
    const data = await api.get(`lojas/lojas-master/${idBanco}`)

    if (!data.success) {
        return
    }
    papeisActions.setMasters(data.content)
}

export async function desvincularClienteCPF(id){
    setLoading(true)
    const data = await api.delete(`Pessoa/${id}/clientes`)
    setLoading(false)

    if(!data.success){
        return
    }

    notifySuccess('Cliente desvinculado com sucesso.')
}

export async function desvincularFuncionario(id){
    setLoading(true)
    const data = await api.delete(`Pessoa/${id}/funcionarios`)
    setLoading(false)

    if(!data.success){
        return
    }

    notifySuccess('Funcionário / Agente / Parceiro desvinculado com sucesso.')
}

export async function desvincularUserInstituicao(id){
    setLoading(true)
    const data = await api.delete(`Pessoa/${id}/usuarios-banco-todos`)
    setLoading(false)

    if(!data.success){
        return
    }

    notifySuccess('Usuário da Instituição desvinculado com sucesso.')
}

export async function desvincularClienteCNPJ(id){
    setLoading(true)
    const data = await api.delete(`Pessoa/${id}/clientes`)
    setLoading(false)

    if(!data.success){
        return
    }

    notifySuccess('Cliente desvinculado com sucesso.')
}

export async function desvincularLoja(id){
    setLoading(true)
    const data = await api.delete(`Pessoa/${id}/lojas`)
    setLoading(false)

    if(!data.success){
        return
    }

    notifySuccess('Loja desvinculada com sucesso.')
}

export async function desvincularInstituicao(id){
    setLoading(true)
    const data = await api.delete(`Pessoa/${id}/instituicoes`)
    setLoading(false)

    if(!data.success){
        return
    }

    notifySuccess('Instituição financeira desvinculada com sucesso.')
}

export async function desvincularFornecedor(id){
    setLoading(true)
    const data = await api.delete(`Fornecedor/${id}`)
    setLoading(false)

    if(!data.success){
        return
    }

    notifySuccess('Fornecedor desvinculado com sucesso.')
}
