import styled from "styled-components";
import { Link } from "react-router-dom";

export const SidebarLink = styled(Link)`
    display: flex;
    color: black;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
    list-style: none;
    height: 65px;
    text-decoration: none;
    font-size: 17px;

    &:hover {
        cursor: pointer;
        border-left: 6px solid var(--color-roxo);
        text-decoration: none;
        color: black;
    }
`;

export const SidebarLabel = styled.span`
    margin-left: 10px;
`;

export const DropDownLink = styled(Link)`
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    color: black;
    font-size: 17px;

    &:hover {
        cursor: pointer;
        text-decoration: none;
        color: black;
        border-left: 7px solid var(--color-roxo);
    }
`;

export const SidebarLinkMini = styled(Link)`
    display: flex;
    color: black;
    justify-content: space-between;
    align-items: center;
    padding: 28px;
    list-style: none;
    height: 65px;
    text-decoration: none;
    font-size: 20px;

    &:hover {
        cursor: pointer;
        border-left: 6px solid var(--color-roxo);
        text-decoration: none;
        color: black;
    }
`;