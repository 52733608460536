/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";

import {Card, Col, Form, Input, Label, Row, Button} from "reactstrap";

import {IoIosArrowBack} from "react-icons/io";

import {useSelector} from "react-redux";
import * as actions from "../../../store/modules/layoutParametrizacao/actions";
import * as controller from "../../../controllers/layoutParametrizacaoController";

import {Mapeamento} from "../components/Mapeamento";
import {Select, Switch} from "../../../components/Inputs/Input";
import Breadcrumb from "../../../components/breadcrumbs";

import "../styles.css";
import {notifyWarn} from "../../../components/message/message";

export function Edit() {
    const {
        layoutBeingEdited,
        layoutBeingEditedInitialValueBackup,
        tiposArquivo,
        tiposComissao,
        instituicoesFinanceira,
        layoutBeingEditedId,
        activeTab,
        layoutsCamposEquivalentes,
    } = useSelector((state) => state.layoutParametrizacao);

    useEffect(() => {
        (async () => {
            const layoutBeingEdited = await controller.getLayoutById(
                layoutBeingEditedId
            );

            handleTipoArquivoChange(layoutBeingEdited.tipoArquivoId, layoutBeingEdited.validaCpf);
            actions.setLayoutBeingEdited(layoutBeingEdited);
            actions.setLayoutBeingEditedInitialValueBackup(layoutBeingEdited);
        })();
    }, [layoutBeingEditedId]);

    useEffect(() => {
        actions.clearMapeamento();
        actions.clearLayoutBeingEdited();
        actions.clearDetalhamento();
        actions.clearStatus();
    }, []);

    useEffect(() => {
        (async () => {
            const tiposArquivo = await controller.getTiposArquivo();
            const instituicoesFinanceiras =
                await controller.getInstituicoesFinanceira();
            const tiposComissao = await controller.getTiposComissao();

            actions.setTipoArquivos(tiposArquivo);
            actions.setInstituicoes(instituicoesFinanceiras);
            actions.setTiposComissao(tiposComissao);
        })();
    }, []);

    function clearPageData() {
        actions.clearLayoutBeingEdited();
        actions.clearMapeamento();
        actions.clearDetalhamento();
        actions.clearStatus();
    }

    async function handleSalvar() {
        const isSuccessed = await controller.updateLayout(
            layoutBeingEdited,
            layoutBeingEditedInitialValueBackup
        );

        if (isSuccessed) {
            clearPageData();
            actions.setActiveTab("Search");
        }
    }

    function handleVoltar() {
        clearPageData();
        actions.setActiveTab("Search");
    }

    async function handleTipoArquivoChange(tipoArquivoId, validaCpf) {
        actions.clearMapeamento();
        actions.clearDetalhamento();
        actions.clearStatus();
        actions.cleanLayoutsCamposEquivalentes();
        actions.setLayoutBeingEdited({
            ...layoutBeingEdited,
            tipoArquivoId: tipoArquivoId,
        });

        if (tipoArquivoId !== "0") {
            const allLayoutsCamposEquivalentes =
                await controller.getAllLayoutsCamposEquivalentes(tipoArquivoId);
            const camposEquivalentes = await controller.getLayoutsCamposEquivalentes(
                tipoArquivoId
            );
            if (validaCpf){
                actions.setLayoutsCamposEquivalentes(camposEquivalentes ?? []);
            } else {
                let novaListaLayouts =
                    camposEquivalentes.filter(campo => campo.id !== "4ad5278b-d132-445b-b11c-fa4f552356f2");

                actions.setLayoutsCamposEquivalentes(novaListaLayouts ?? []);
            }

            actions.setAllLayoutsCamposEquivalentes(allLayoutsCamposEquivalentes ?? []);
        }
    }

    function handleTipoComissaoChange(e) {
        actions.setLayoutBeingEdited({
            ...layoutBeingEdited,
            tipoComissaoBancoId: e.value,
        });

        // clear detalhamentos from mapeamento
        actions.setMapeamento("detalhes", []);
    }

    async function handleValidaCpf() {
        await actions.setLayoutBeingEdited({
            ...layoutBeingEdited,
            validaCpf: !layoutBeingEdited.validaCpf,
        });

        if (!layoutBeingEdited.validaCpf){
            if (layoutBeingEdited.tipoArquivoId !== "0") {
                const allLayoutsCamposEquivalentes =
                    await controller.getAllLayoutsCamposEquivalentes(layoutBeingEdited.tipoArquivoId);
                const camposEquivalentes = await controller.getLayoutsCamposEquivalentes(
                    layoutBeingEdited.tipoArquivoId
                );

                actions.setLayoutsCamposEquivalentes(camposEquivalentes ?? []);
                actions.setAllLayoutsCamposEquivalentes(allLayoutsCamposEquivalentes ?? []);
            }
        } else {
            let possuiCpfRegistrado = layoutBeingEdited.layoutPlanilhaParametros.filter((layout) => {
                if (layout.layoutPlanilhaCamposEquivalenteId === "4ad5278b-d132-445b-b11c-fa4f552356f2")
                    return layout;
            });

            if (possuiCpfRegistrado.length !== 0 ){
                notifyWarn("CPF Já mapeado, favor remover do mapeamento");
                await actions.setLayoutBeingEdited({
                    ...layoutBeingEdited,
                    validaCpf: true,
                });
                return;
            }

            let novaListaLayouts =
                layoutsCamposEquivalentes.filter(campo => campo.id !== "4ad5278b-d132-445b-b11c-fa4f552356f2");

            actions.setLayoutsCamposEquivalentes(novaListaLayouts ?? []);
        }
    }
    function handleValidaProduto(){
        actions.setLayoutBeingEdited({
            ...layoutBeingEdited,
            validaProduto: !layoutBeingEdited.validaProduto,
        });
        actions.setMapeamento("layoutPlanilhaCamposEquivalenteId", "0");
    }

    return (
        <>
            <Col>
                <Breadcrumb activeTab={activeTab}/>
                <Card className="mb-2 mt-2">
                    <Form>
                        <Col className="pt-2 pb-2">
                            <Button
                                onClick={() => {
                                    handleVoltar();
                                }}
                                style={{width: "127px", color: "white"}}
                                className="purpleButton"
                            >
                                <IoIosArrowBack
                                    style={{
                                        marginTop: "3px",
                                        float: "left",
                                    }}
                                />
                                Voltar
                            </Button>

                            <Button
                                onClick={() => {
                                    handleSalvar();
                                }}
                                style={{
                                    width: "242px",
                                    float: "right",
                                }}
                                className="purpleButton"
                            >
                                Salvar Cadastro
                            </Button>
                        </Col>
                    </Form>
                </Card>
            </Col>
            <Col>
                <Card className="radius pb-5">
                    <Col>
                        <Col className="pt-2 mt-4 mb-4 ">
                            <p style={{fontSize: "18px", color: "var(--color-preto)"}}>
                                Preencha os campos para cadastrar um novo Layout de
                                parametrização
                            </p>
                        </Col>
                        <Form>
                            <Col>
                                <Row>
                                    <Col md={4}>
                                        <Label
                                            for="convenio"
                                            style={{fontWeight: "700", color: "var(--color-preto)"}}
                                        >
                                            Descrição do layout
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={layoutBeingEdited.descricao}
                                            onChange={(e) =>
                                                actions.setLayoutBeingEdited({
                                                    ...layoutBeingEdited,
                                                    descricao: e.target.value.toUpperCase(),
                                                })
                                            }
                                            style={{height: "37px"}}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Label
                                            for="convenio"
                                            style={{fontWeight: "700", color: "var(--color-preto)"}}
                                        >
                                            Tipo do arquivo
                                        </Label>
                                        <Select
                                            first="Selecione"
                                            value={{value: layoutBeingEdited.tipoArquivoId}}
                                            select={{value: "id", label: "descricao"}}
                                            options={tiposArquivo}
                                            onChange={(e) => {
                                                handleTipoArquivoChange(e.value);
                                            }}
                                            autoComplete="off"
                                            disabled={
                                                layoutBeingEdited.layoutPlanilhaParametros.length === 0
                                                    ? false
                                                    : true
                                            }
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Label
                                            for="convenio"
                                            style={{fontWeight: "700", color: "var(--color-preto)"}}
                                        >
                                            Instituição Financeira
                                        </Label>
                                        <Select
                                            first="Selecione"
                                            value={{value: layoutBeingEdited.bancoId}}
                                            select={{value: "id", label: "nome"}}
                                            options={instituicoesFinanceira}
                                            onChange={(e) =>
                                                actions.setLayoutBeingEdited({
                                                    ...layoutBeingEdited,
                                                    bancoId: e.value,
                                                })
                                            }
                                            autoComplete="off"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    {layoutBeingEdited.tipoArquivoId.toUpperCase() ===
                                        "4898DDC8-C0CB-49D8-BB72-EA946FB7963D" && (
                                            <Col md={4}>
                                                <Label
                                                    for="convenio"
                                                    style={{
                                                        fontWeight: "700",
                                                        color: "var(--color-preto)",
                                                    }}
                                                >
                                                    Tipo Comissão
                                                </Label>
                                                <Select
                                                    first="Selecione"
                                                    value={{
                                                        value: layoutBeingEdited.tipoComissaoBancoId,
                                                    }}
                                                    select={{value: "id", label: "descricao"}}
                                                    options={tiposComissao}
                                                    onChange={(e) => handleTipoComissaoChange(e)}
                                                    autoComplete="off"
                                                    disabled={layoutBeingEdited.layoutPlanilhaParametros.some(
                                                        (parametro) => parametro.detalhes.length > 0
                                                    )}
                                                />
                                            </Col>
                                        )}
                                    <Col md={4}>
                                        <Label
                                            for="convenio"
                                            style={{fontWeight: "700", color: "var(--color-preto)"}}
                                        >
                                            Número Aba
                                        </Label>
                                        <Input
                                            placeholder="Digite aqui..."
                                            value={layoutBeingEdited.nomeAba}
                                            onChange={(e) =>
                                                actions.setLayoutBeingEdited({
                                                    ...layoutBeingEdited,
                                                    nomeAba: e.target.value,
                                                })
                                            }
                                            style={{height: "37px"}}
                                        />
                                    </Col>
                                    {layoutBeingEdited.tipoArquivoId.toUpperCase() ===
                                        "4898DDC8-C0CB-49D8-BB72-EA946FB7963D" && (
                                            <Col
                                                md={4}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    marginLeft: "auto",
                                                }}
                                            >
                                             <>
                                            <Label style={{
                                                fontWeight: "700",
                                                color: "var(--color-preto)",
                                                whiteSpace: "nowrap",
                                              }}>
                                                Valida Produto
                                              </Label>
                                              <div style={{marginLeft: "-50px", marginTop: "25px"}}>
                                                <Switch
                                                    className="switch"
                                                    checked={layoutBeingEdited.validaProduto}
                                                    onChange={() => handleValidaProduto()}
                                                />
                                              </div>
                                            </>   
                                                <>
                                                    <Label style={{
                                                        fontWeight: "700",
                                                        color: "var(--color-preto)",
                                                        whiteSpace: "nowrap",
                                                    }}>
                                                        Valida CPF
                                                    </Label>
                                                    <div style={{marginLeft: "-50px", marginTop: "25px"}}>
                                                        <Switch
                                                            className="switch"
                                                            checked={layoutBeingEdited.validaCpf}
                                                            onChange={() => handleValidaCpf()}
                                                        />
                                                    </div>
                                                </>
                                                <>
                                                    <Label
                                                        style={{
                                                            fontWeight: "700",
                                                            color: "var(--color-preto)",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        Individual
                                                    </Label>
                                                    <div style={{marginLeft: "-50px", marginTop: "25px"}}>
                                                        <Switch
                                                            className="switch"
                                                            checked={layoutBeingEdited.isIndividual}
                                                            onChange={(e) =>
                                                                actions.setLayoutBeingEdited({
                                                                    ...layoutBeingEdited,
                                                                    isIndividual: !layoutBeingEdited.isIndividual,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </>
                                                <>
                                                    <Label
                                                        style={{
                                                            fontWeight: "700",
                                                            color: "var(--color-preto)",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        Quitação
                                                    </Label>
                                                    <div style={{marginLeft: "-50px", marginTop: "25px"}}>
                                                        <Switch
                                                            className="switch"
                                                            checked={layoutBeingEdited.isQuitacao}
                                                            onChange={(e) =>
                                                                actions.setLayoutBeingEdited({
                                                                    ...layoutBeingEdited,
                                                                    isQuitacao: !layoutBeingEdited.isQuitacao,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </>
                                                <>
                                                    <Label
                                                        style={{
                                                            fontWeight: "700",
                                                            color: "var(--color-preto)",
                                                            whiteSpace: "nowrap",
                                                        }}
                                                    >
                                                        Negativo
                                                    </Label>
                                                    <div style={{marginLeft: "-50px", marginTop: "25px"}}>
                                                        <Switch
                                                            className="switch"
                                                            checked={layoutBeingEdited.isNegativo}
                                                            onChange={(e) =>
                                                                actions.setLayoutBeingEdited({
                                                                    ...layoutBeingEdited,
                                                                    isNegativo: !layoutBeingEdited.isNegativo,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            </Col>
                                        )}
                                </Row>
                                <Mapeamento/>
                            </Col>
                        </Form>
                    </Col>
                </Card>
            </Col>
        </>
    );
}
