export const COLUMNS = [
    {
        Header: "Descrição do Layout",
        accessor: "descricao"
    },
    {
        Header: "Tipo de Arquivo",
        accessor: "tipoArquivo.descricao"
    },
]


