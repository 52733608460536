const STORE_ID = "TIPOCONVENIO";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    TIPOCONVENIO: "TIPOCONVENIO",
    TIPOCONVENIO_COPY: "TIPOCONVENIO_COPY",
    INVALID_TIPOCONVENIO: "INVALID_TIPOCONVENIO",
    SET_INSTITUICOES: "SET_INSTITUICOES",
    SET_CONVENIOS: "SET_CONVENIOS",
    CLEAN_INVALID_TIPOCONVENIO: "CLEAN_INVALID_TIPOCONVENIO",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    TIPOCONVENIOS_INSTITUICOES_FINANCEIRAS: "TIPOCONVENIOS_INSTITUICOES_FINANCEIRAS"
}

export { STORE_ID, TYPES }