const STORE_ID = "CONTA_CORRENTE";

const TYPES = {
    SET_MATRICULAS_SELECTED: "SET_MATRICULAS_SELECTED",
    SET_BANCOS: "SET_BANCOS",
    CONTA_CORRENTE: "CONTA_CORRENTE",
    SET_SEE: "SET_SEE",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    SET_INVALID: "SET_INVALID",
    CLEAN_INVALID: "CLEAN_INVALID",
    CLEAN: "CLEAN",
    SET_MATRICULAS: "SET_MATRICULAS",
    SET_TIPOS_CONTA: "SET_TIPOS_CONTA",
    SETCONTA: "SETCONTA",
    SET_TIPOS_PIX: "SET_TIPOS_PIX",
    SET_IS_EDITANDO: 'SET_IS_EDITANDO',
    SET_IS_EXCLUINDO: 'SET_IS_EXCLUINDO',
    SET_CAMPO_CONTA: 'SET_CAMPO_CONTA'
}

export { STORE_ID, TYPES }
