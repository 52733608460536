import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}
export function setTab(value) {
    dispatch({
        type: TYPES.SET_TAB,
        value
    })
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function setTabs(value) {
    dispatch({
        type: TYPES.SET_TABS,
        value
    })
}

export function setComissoes(value) {
    dispatch({
        type: TYPES.SET_COMISSOES,
        value
    })
}

export function setProdutos(value) {
    dispatch({
        type: TYPES.SET_PRODUTOS,
        value
    })
}

export function setTiposComissao(value) {
    dispatch({
        type: TYPES.SET_TIPOS_COMISSAO,
        value
    })
}

export function setConvenios(value) {
    dispatch({
        type: TYPES.SET_CONVENIOS,
        value
    })
}

export function setTiposOperacao(value) {
    dispatch({
        type: TYPES.SET_TIPOS_OPERACAO,
        value
    })
}

export function cleanTabs() {
    dispatch({
        type: TYPES.CLEAN_TABS
    })
}