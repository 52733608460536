import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function pesquisar(value) {
    dispatch({
        type: TYPES.PESQUISAR,
        value
    })

}
export function setCopia(value) {
    dispatch({
        type: TYPES.COPIA,
        value
    })
}

export function setUploads(value) {
    dispatch({
        type: TYPES.SET_UPLOADS,
        value
    })
}

export function setItem(campo, value) {
    dispatch({
        type: TYPES.SET_ITEM,
        campo,
        value
    })
}

export function setTipos(value) {
    dispatch({
        type: TYPES.SET_TIPOS,
        value
    })
}

export function setBancos(value) {
    dispatch({
        type: TYPES.SET_BANCOS,
        value
    })
}

export function setLayoutPlanilha(value) {
    dispatch({
        type: TYPES.SET_LAYOUT_PLANILHA,
        value
    })
}

export function setLayouts(value) {
    dispatch({
        type: TYPES.LAYOUTS,
        value
    })
}

export function setStatusImportacao(value) {
    dispatch({
        type: TYPES.SET_STATUS_IMPORTACAO,
        value
    })
}

export function setStatusImportacoes(value) {
    dispatch({
        type: TYPES.STATUS_IMPORTACOES,
        value
    })
}

export function setSearch(value) {
    dispatch({
        type: TYPES.SEARCH,
        value
    });
}

export function cleanSearch() {
    dispatch({
        type: TYPES.CLEAN_SEARCH
    })
}

export function setDownload(value) {
    dispatch({
        type: TYPES.SET_DOWNLOAD,
        value
    })
}

export function cleanDownload() {
    dispatch({
        type: TYPES.CLEAN_DOWNLOAD
    })
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function getAllItems(value) {
    dispatch({
        type: TYPES.GET_ALL_ITEMS,
        value
    });
}

export function setAllItemsInconsistencia(value) {
    dispatch({
        type: TYPES.GET_ALL_ITEMS_INCONSISTENCIA,
        value
    });
}

export function setPossuiInconsistencia(value) {
    dispatch({
        type: TYPES.SET_POSSUI_INCONSISTENCIA,
        value
    });
}


