import api from "../../util/api";
import * as actions from "../../store/modules/dashboard/campanha/actions";
import { notifyWarn } from "../../components/message/message";
import { setLoading } from "../../store/modules/globalLoading/actions";
import { maskMoeda } from "../../util/masks";

export async function get(filtros){
        if(filtros.campanhaId === '0'){
            return notifyWarn('É necessário informar a campanha')
        }

        if(filtros.consultorId === '0'){
            return notifyWarn('É necessário informar o consultor')
        }

    setLoading(true);
    var isBigNumbersSuccessed = await getBigNumbers(filtros)

    
    setLoading(false);
    
    if( isBigNumbersSuccessed.success){
        actions.setCampanha(isBigNumbersSuccessed.bigNumbers);
        actions.setTabela(isBigNumbersSuccessed.table);
        return true
    }
}

export async function getBigNumbers(filtros){
    var url = `CampanhaPremiacao/bignumbers?campanhaId=${filtros.campanhaId}&pessoaId=${filtros.consultorId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return {success: false};
    }
    var newData = {}

    data.content.bigNumbers.forEach((item) =>{
        if(item.parametroCampanhaId === 'a437cf75-2209-46cd-8161-f41b446300d5'){
            newData.metaMes = {...newData.metaMes, quantidade: item.metaMes}
            newData.producaoMes = {...newData.producaoMes, quantidade: item.producaoMes}
            newData.metaMesRestante = {...newData.metaMesRestante, quantidade: item.metaMesRestante}
            newData.metasAlcancadas = {...newData.metasAlcancadas, quantidade: item.metasAlcancadas}
            newData.projecaoGanho = {...newData.projecaoGanho, quantidade: item.projecaoGanho}
            newData.posicaoGeral = {...newData.posicaoGeral, quantidade: item.posicalGeral}
            newData.metaDu = {...newData.metaDu, quantidade: item.metaDu}
            newData.metaDuRecalculada = {...newData.metaDuRecalculada, quantidade: item.metaDuRecalculada}
            newData.valorProjetado = {...newData.valorProjetado, quantidade: item.valorProjetado}
        }else if(item.parametroCampanhaId === '13c80469-a378-4285-bbea-8cc0ee73b649'){
            newData.metaMes = {...newData.metaMes, valor: maskMoeda(Math.floor(item.metaMes).toFixed(2)).replace(',00', '')}
            newData.producaoMes = {...newData.producaoMes, valor: maskMoeda(Math.floor(item.producaoMes).toFixed(2)).replace(',00', '')}
            newData.metaMesRestante = {...newData.metaMesRestante, valor: maskMoeda(Math.floor(item.metaMesRestante).toFixed(2)).replace(',00', '')}
            newData.metasAlcancadas = {...newData.metasAlcancadas, valor: item.metasAlcancadas}
            newData.projecaoGanho = {...newData.projecaoGanho, valor: parseInt(item.projecaoGanho)}
            newData.posicaoGeral = {...newData.posicaoGeral, valor: item.posicalGeral}
            newData.metaDu = {...newData.metaDu, valor: maskMoeda(Math.floor(item.metaDu).toFixed(2)).replace(',00', '')}
            newData.metaDuRecalculada = {...newData.metaDuRecalculada, valor: maskMoeda(Math.floor(item.metaDuRecalculada).toFixed(2)).replace(',00', '')}
            newData.valorProjetado = {...newData.valorProjetado, valor: maskMoeda(Math.floor(item.valorProjetado).toFixed(2)).replace(',00', '')}
        }
    })
    return {success: true, bigNumbers : newData, table: data.content.regras}
}

export async function getTabelaCampanha(filtros){
    var url = `CampanhaPremiacao/regras?campanhaId=${filtros.campanhaId}&pessoaId=${filtros.consultorId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return {success: false};
    }
    return {success: true, data : data.content}
}

export async function getTabelaCampanhaConsultor(filtros, lojaId, tabela){
    var url = `Campanha/get-tabela-consultores?campanhaId=${filtros.campanhaId}&pessoaId=${filtros.consultorId}`

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    
    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId}`
    }

    url = url + `&lojaId=${lojaId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return {success: false};
    }
    tabela.forEach((e)=>{
        if(e.lojaId === lojaId){  
            e.consultores= data.content
        }
    })
    actions.setTabela(tabela)
    return {success: true}
}

export async function getBigNumbersProduto(filtros, produtoId, produtos){
    var url = `Campanha/get-bignumber-produto?campanhaId=${filtros.campanhaId}&pessoaId=${filtros.consultorId}&produtoId=${produtoId}`

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId}`
    }

    
    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    produtos.forEach((produto)=>{
        if(produto.produtoId === produtoId){
            produto.bigNumbers = data.content
        }
    })
    actions.setProdutos(produtos)
    return true
}

export async function getParcerias() {
    const data = await api.get(`TipoParceira`);

    if (!data.success) {
        return;
    }
    actions.setTiposParceria(data.content);
}

export async function getCampanhas() {
    var url = `CampanhaPremiacao/campanhas-aprovadas`
    const data = await api.get(url);

    if (!data.success) {
        return;
    }
    actions.setCampanhas(data.content ?? []);
    const campanhaSelected = data.content?.[0].id ?? '0';
    actions.setFiltros('campanhaId', campanhaSelected);
}

export async function getGrupos() {
    const data = await api.get(`grupos`);

    if (!data.success) {
        return;
    }
    actions.setGrupos(data.content);
}

export async function getCanal() {
    const data = await api.get(`canal`);

    if (!data.success) {
        return;
    }
    actions.setCanais(data.content);
}

export async function getCentrosCusto() {
    const data = await api.get(`CentrosCusto/vinculo`);

    if (!data.success) {
        return;
    }
    actions.setCentrosCusto(data.content);
}

export async function getHierarquias(canalId) {
    const data = await api.get(`hierarquias/hierarquia-canal/${canalId}`);

    if (!data.success) {
        return;
    }
    actions.setHierarquias(data.content);
}

export async function getConsultores(pesquisa, page, pageSize) {
    var url = `lojas/consultores?pagina=${page}&registros=${pageSize}`;
  
    if (pesquisa.cpf !== '') url += `&cpf=${pesquisa.cpf}`;
    if (pesquisa.nome !== '') url += `&nome=${pesquisa.nome}`;
    if (pesquisa.hierarquiaId !== '0') url += `&hierarquiaId=${pesquisa.hierarquiaId}`;
    if (pesquisa.status !== '0') url += `&status=${pesquisa.status}`;
  
    actions.setLoadingConsultores(true);
    const data = await api.get(url);
    actions.setLoadingConsultores(false);
  
    if (!data.success) {
      return;
    }
    
    actions.setGetAllConsultores({
      quantidade: data.content.quantidade, 
      quantidadeTotal: data.content.quantidadeTotal, 
      totalPaginas: data.content.totalPaginas
    });
    actions.setConsultores(data.content.registros);
  }

export async function getCanais() {
    const data = await api.get(`canal`);

    if (!data.success) {
        return;
    }
    actions.setCanais(data.content);
}



export async function getTabelaCampanhaProduto(filtros){
    var url = `Campanha/get-tabela-produto?campanhaId=${filtros.campanhaId}&pessoaId=${filtros.consultorId}`

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return {success: false};
    }
    return {success: true, data : data.content}
}