import styled, {css} from 'styled-components';

const dragActive =  css`
    border-color: var(--color-verde);
    height: 158px;
`;

const dragReject = css`
border-color: var(--color-laranja);
height: 158px;
`;
export const DropContainer = styled.div.attrs({
    className: "dropzone"
})`
    border: 2px dashed var(--color-preto);
    border-radius: 4px;
    cursor: pointer;
    transition: height 0.2s ease;
    width: 100%;
    margin: 1.5rem;
    padding: 15px 0;

    ${props => props.isDragActive && dragActive};
    ${props => props.isDragReject && dragReject};
`;

const messageColors = {
    default: 'black',
    error: 'var(--color-laranja)',
    success: 'var(--color-verde)'
};

export const UploadMessage = styled.p`
    color: ${props => messageColors[props.type || 'default']};
    padding: 15px 0;
    font-size: 18px;
    margin-left: 35%
`;

export const ListContainer = styled.ul`
margin-top: 20px;
width: 300px;

li{
    display: flex;
    justify-content: space-between;
    align-items: center;
};
& + li {
    margin-top: 15px
    }
}
`

export const FileInfo = styled.div`
display: flex;
align-items: center;

div{
    display: flex;
    flex-direction: column;
    margin-right: 10px
}

span{
    margin-top: 4px;
    color: var(--color-preto);
}

button{
    border: 0;
    background: transparent;
    color: var(--color-laranja);
    margin-left: 5px;
    cursor: pointer;
}
`