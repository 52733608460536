import React from "react";
import { Input } from "reactstrap";

const FilterWithAccent = ({ filter, setFilter }) => {

    return (
        <span>
            <Input
                placeholder="Digite aqui..."
                value={filter}
                onChange={e => setFilter(e.target.value)}
            />
        </span>
    )
}

export default FilterWithAccent;