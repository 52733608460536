import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as inconsistenciaActions from "../store/modules/inconsistencias/actions";
import { setLoading } from "../store/modules/globalLoading/actions"

export async function get() {

    setLoading(true)
    const data = await api.get("inconsistencia");
    setLoading(false)

    if (!data.success) {
        return;
    }

  inconsistenciaActions.getItems(data.content);
}

export async function getBancos() {

    const data = await api.get("Bancos");

    if (!data.success) {
        return;
    }

    let banco = data.content && data.content.map(banco => {
        return {
            id: banco.id,
            nome: banco.nome.toUpperCase()
        }
    })
    inconsistenciaActions.setBancos(banco)
}

export async function getResponsaveisPorAcao() {

  const data = await api.get("responsavel-por-acao");

  if (!data.success) {
      return;
  }

  inconsistenciaActions.setResponsaveis(data.content)
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`inconsistencia/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    const dataUpdate = {
        descricao: data.content.descricao,
        bancoId: data.content.banco.id,
        responsavelPorAcao: data.content.responsavelPorAcao.id,
        codigoConsistencia: data.content.codigoConsistencia,
        id: data.content.id
    }

    inconsistenciaActions.copy(dataUpdate);
    inconsistenciaActions.item(dataUpdate);
}

export async function create(item) {

    if (item.descricao.trim() === "") {
        inconsistenciaActions.setInvalid("descricao");
        return notifyWarn("Descrição da inconsistência não pode estar em branco");
    }

    if (item.bancoId === "0") {
        inconsistenciaActions.setInvalid("bancoId");
        return notifyWarn("Selecione uma instituição financeira");
    }

    if (item.codigoConsistencia === "") {
        inconsistenciaActions.setInvalid("codigoConsistencia");
        return notifyWarn("Código de inconsistência não pode estar em branco");
    }

    if (item.responsavelPorAcao === "0") {
        inconsistenciaActions.setInvalid("responsavelPorAcao");
        return notifyWarn("Selecione um responsável por ação");
    }

    const body = {
        descricao: item.descricao,
        bancoId: item.bancoId,
        codigoConsistencia: item.codigoConsistencia,
        responsavelPorAcaoId: item.responsavelPorAcao
    }

    setLoading(true)
    const data = await api.post("inconsistencia", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Inconsistência cadastrado");
    inconsistenciaActions.cleanRegister();
    get();
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (item.descricao.trim() === "") {
        inconsistenciaActions.setInvalid("descricao");
        return notifyWarn("Descrição da inconsistência não pode estar em branco");
    }

    if (item.bancoId === "0") {
        inconsistenciaActions.setInvalid("bancoId");
        return notifyWarn("Selecione uma instituição financeira");
    }

    if (item.codigoConsistencia === "") {
        inconsistenciaActions.setInvalid("codigoConsistencia");
        return notifyWarn("Código de inconsistência não pode estar em branco");
    }

    if (item.responsavelPorAcao === "0") {
        inconsistenciaActions.setInvalid("responsavelPorAcao");
        return notifyWarn("Selecione um responsável por ação");
    }

    const body = {
        descricao: item.descricao,
        bancoId: item.bancoId,
        codigoConsistencia: item.codigoConsistencia,
        responsavelPorAcaoId: item.responsavelPorAcao
    }

    setLoading(true)
    const data = await api.put(`inconsistencia/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Inconsistência atualizada");
    inconsistenciaActions.cleanRegister();
    inconsistenciaActions.setActiveTab("Search");
    get();
}
