import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane, Card } from "reactstrap";
import { useSelector } from "react-redux";
import classnames from "classnames";

import * as actions from "../../../store/modules/propostas/actions";

import Proposta from "../components/Proposta";
import Comissoes from "../components/Comissoes";
import ContasReceber from "../components/ContasReceber";
import ContasPagar from "../components/ContasPagar";

const Register = () => {
  const { tab, permitions, permitionsTabs } = useSelector(
    (state) => state.propostas
  );

  function changeTab(numberTab) {
    actions.setTab(numberTab);
  }
  return (
    <Card className="pt-3 pb-2 mb-5">
      <div>
        <Nav style={{ marginLeft: "25px", marginBottom: "30px" }}>
          <NavItem style={{ padding: "0 5px 0 5px" }}>
            <NavLink
              className={classnames({ active: tab === "1" })}
              onClick={() => {
                changeTab("1");
              }}
              style={
                tab === "1"
                  ? {
                      color: "var(--color-preto",
                      fontWeight: "700",
                      borderBottom: "3px solid var(--color-roxo)",
                      transition: "0.1s",
                      cursor: "pointer",
                    }
                  : {
                      color: "var(--color-preto",
                      fontWeight: "700",
                      cursor: "pointer",
                    }
              }
            >
              Proposta
            </NavLink>
          </NavItem>
          <NavItem hidden={!permitionsTabs.comissao}>
            <NavLink
              className={classnames({ active: tab === "2" })}
              onClick={() => {
                changeTab("2");
              }}
              style={
                tab === "2"
                  ? {
                      color: "var(--color-preto",
                      fontWeight: "700",
                      borderBottom: "3px solid var(--color-roxo)",
                      transition: "0.1s",
                      cursor: "pointer",
                    }
                  : {
                      color: "var(--color-preto",
                      fontWeight: "700",
                      cursor: "pointer",
                    }
              }
            >
              Comissões
            </NavLink>
          </NavItem>
          <NavItem hidden={!permitionsTabs.cp}>
            <NavLink
              className={classnames({ active: tab === "3" })}
              onClick={() => {
                changeTab("3");
              }}
              hidden={false}
              style={
                tab === "3"
                  ? {
                      color: "var(--color-preto",
                      fontWeight: "700",
                      borderBottom: "3px solid var(--color-roxo)",
                      transition: "0.1s",
                      cursor: "pointer",
                    }
                  : {
                      color: "var(--color-preto",
                      fontWeight: "700",
                      cursor: "pointer",
                    }
              }
            >
              Contas a Pagar
            </NavLink>
          </NavItem>
          <NavItem hidden={!permitionsTabs.cr}>
            <NavLink
              className={classnames({ active: tab === "4" })}
              onClick={() => {
                changeTab("4");
              }}
              disabled={
                permitions.vizualizar === null ? false : permitions.vizualizar
              }
              style={
                tab === "4"
                  ? {
                      color: "var(--color-preto",
                      fontWeight: "700",
                      borderBottom: "3px solid var(--color-roxo)",
                      transition: "0.1s",
                      cursor: "pointer",
                    }
                  : {
                      color: "var(--color-preto",
                      fontWeight: "700",
                      cursor: "pointer",
                    }
              }
            >
              Contas a Receber
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={tab}>
          <TabPane tabId="1">
            <Proposta />
          </TabPane>
          <TabPane tabId="2">
            <Comissoes />
          </TabPane>
          <TabPane tabId="3">
            <ContasPagar />
          </TabPane>
          <TabPane tabId="4">
            <ContasReceber />
          </TabPane>
        </TabContent>
      </div>
    </Card>
  );
};

export default Register;
