import React from "react";
import { Card, Col, Form, Input, Label, Row } from "reactstrap";
import { useSelector } from "react-redux";
import * as actions from "../../../store/modules/tipoParceria/actions";
import * as tipoParceriaController from "../../../controllers/tipoParceriaController";
import { Switch } from "../../../components/Inputs/Input";
import { removeAccentsAndUppercase } from "../../../util/masks";

const Register = () => {

    const { item, invalid, copy, seeRegister } = useSelector(state => state.tipoParceria);



    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                tipoParceriaController.create(item);
            } else {
                tipoParceriaController.update(item, copy);
            }
        }
    }

    function onChangeItem(value, field) {
        actions.cleanInvalid(field);
        actions.setItem({ ...item, [field]: value });
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5 pt-3 pl-3 pr-3">
                    <Col className="pt-2 pb-4">
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>
                            Preencha os campos para cadastrar um novo Tipo de Parceria
                        </p>
                    </Col>
                    <Form>
                        <Col>
                            <Row>
                                <Col md={6}>
                                    <Label for="descricao" style={{fontWeight: '700', color: 'var(--color-preto)'}}>
                                        Descrição do Tipo de Parceria
                                    </Label>
                                    <Input
                                        type="text"
                                        name="descricao"
                                        id="descricao"
                                        placeholder="Digite aqui..."
                                        value={item.nome}
                                        onChange={e => onChangeItem(removeAccentsAndUppercase(e.target.value), "nome")}
                                        onKeyPress={e => handleKeyPress(e)}
                                        className={invalid.nome ? "is-invalid" : ""}
                                        disabled={seeRegister}
                                    />
                                </Col>
                                <Col>
                                    <Label for="descricao" style={{fontWeight: '700', color: 'var(--color-preto)'}}>
                                        Divisão de Comissão
                                    </Label>
                                    <div style={{marginTop: '-15px', marginLeft: '-10px'}}>
                                        <Switch
                                            className='switch'
                                            checked={item.flagDivisaoComissao}
                                            onChange={() => { onChangeItem(!item.flagDivisaoComissao, "flagDivisaoComissao") }}
                                            disabled={seeRegister}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Form>
                </Card>
            </Col>
        </>
    );
}

export default Register;