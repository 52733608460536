import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function setItem(value) {
    dispatch({
        type: TYPES.SET_ITEM,
        value
    });
}

export function setItems(value) {
    dispatch({
        type: TYPES.SET_ITEMS,
        value
    });
}

export function copy(value) {
    dispatch({
        type: TYPES.TIPOPARCERIA_COPY,
        value
    });
}
export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_TIPOPARCERIA,
        field
    });

}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_TIPOPARCERIA,
        field
    })
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function setActivePersona(value) {
    dispatch({
        type: TYPES.SET_PERSONA,
        value
    })
}