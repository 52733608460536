import React from "react";
import * as BiIcons from "react-icons/bi";
import * as parametrosActions from "../../../../../../store/modules/personas/parametros_store/actions";
import * as controller from "../../../../../../controllers/personasController/parametrosController";
import { useSelector } from "react-redux";
import { notifySuccess } from "../../../../../../components/message/message";

function TableCredencial({ credencial }) {
  const { seeRegister } = useSelector((state) => state.personasParametros);

  const data = credencial;

  async function handleRemoveItem(id, index) {
    var isValid = await controller.deleteCredencial(id);
    if (isValid) {
      const arrCredenciais = credencial;

      arrCredenciais.splice(index, 1);
      parametrosActions.setCredenciais(arrCredenciais);

      notifySuccess("Credencial excluída com sucesso!");
    }
  }

  async function handleGetItem(item) {
    parametrosActions.setItem(item);
  }

  return (
    <>
      <table style={{ marginTop: "20px", width: "95%", marginLeft: "2%" }}>
        <thead>
          <tr>
            <th>Integração</th>
            <th>Login</th>
            <th style={{ textAlign: "center" }}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            return (
              <>
                <tr>
                  <td>Integração BMG</td>
                  <td>{row.login}</td>
                  <td style={{ textAlign: "center" }}>
                    <>
                      {!seeRegister && (
                        <BiIcons.BiEdit
                          style={{
                            fontSize: "1.2rem",
                            cursor: "pointer",
                            color: "#C4C7CA",
                          }}
                          onClick={() => {
                            handleGetItem(row);
                          }}
                        />
                      )}
                    </>
                    <>
                      {!seeRegister && (
                        <BiIcons.BiTrash
                          style={{
                            fontSize: "1.2rem",
                            cursor: "pointer",
                            color: "#C4C7CA",
                          }}
                          onClick={() => {
                            handleRemoveItem(row.id, index);
                          }}
                        />
                      )}
                    </>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default TableCredencial;
