import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import * as logHttpRequestController from '../../../../../controllers/httpRequestController';
import {useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import * as BsIcons from "react-icons/bs";
import {Col, Input, Row} from "reactstrap";
import ReactPaginate from "react-paginate";
import {COLUMNS_LOG} from "../../../constants/columns";



export default function LogHttp() {

    const {log, getAll} = useSelector(state => state.httpRequest);
    const columns = useMemo(() => COLUMNS_LOG, []);
    const data = log;
    const [selectedPage, setSelectedPage] = useState(0);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
            columns,
            data
        },
        useGlobalFilter,
        useSortBy,
        usePagination);
    useEffect(() => {
        logHttpRequestController.getAll(selectedPage + 1, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.pageSize])

    const {pageSize} = state;
    const [initialPage, setInitialPage] = useState(false);

    function handlePageClick(e) {
        const pageSelected = e.selected;
        setInitialPage(false);
        gotoPage(pageSelected);

        logHttpRequestController.getAll(pageSelected + 1, pageSize);
    }

    function handleInitialPage(e) {
        const pageSelected = 0;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        logHttpRequestController.getAll(pageSelected + 1, pageSize);
    }

    return (
        <Col style={{overflowX:"auto"}}>
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th>
                               
                                {column.render("Header")}
                                <span className="float-right">
                                                    {column.isSorted ?
                                                        (column.isSortedDesc ?
                                                            <BsIcons.BsArrowDown
                                                                {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
                                                            />
                                                            :
                                                            <BsIcons.BsArrowUp
                                                                id="OrdenarAsc"
                                                                {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
                                                            />)
                                                        :
                                                        <BsIcons.BsArrowDownUp
                                                            {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
                                                        />}
                                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map(row => {
                    prepareRow(row)
                    return (
                        <tr className="text-center" {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <Row className="pt-4">
                <Col>
                    <span
                        style={{color: "var(--color-cinza-medio)"}}>Mostrando {getAll.quantidade !== undefined ? getAll.quantidade : "0"} de {getAll.quantidadeTotal !== undefined ? getAll.quantidadeTotal : "0"}</span>
                </Col>
                <Col md={8}>
                    <Row className="float-right">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            initialPage={0}
                            pageCount={getAll.totalPaginas}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={e => handlePageClick(e)}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            forcePage={initialPage ? 0 : selectedPage}
                        />
                        <Input
                            type="select"
                            className="pageSize"
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value));
                                handleInitialPage(Number(e.target.value))
                            }}>
                            {
                                [10, 25, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))
                            }
                        </Input>
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}