import moment from "moment";

export const COLUMNS = [
  {
    Header: "Nome do Usuário",
    accessor: "nomeUsuario",
  },
  {
    Header: "Data de importação",
    accessor: "dataImportacao",
    Cell: ({ value }) => {
      return moment(value).local().format("DD/MM/YYYY HH:mm");
    },
  },
  {
    Header: "Nome arquivo",
    accessor: "nomeArquivo",
  },
  {
    Header: "Layout de Parametrização",
    accessor: "nomeLayout",
  },
  {
    Header: "Tipo de Arquivo",
    accessor: "tipoArquivo",
  },
  {
    Header: "Tipo Importação",
    accessor: "tipoImportacao",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

export const COLUMNS_INCONSISTENCIAS = [
  {
    Header: "Data de Criação",
    accessor: "dataCriacao",
    Cell: ({ value }) => {
      return moment(value).local().format("DD/MM/YYYY HH:mm");
    },
  },
  {
    Header: "Descrição Erro",
    accessor: "descricaoErro",
  },
  {
    Header: "Correção Possivel",
    accessor: "correcaoPossivel",
    Cell: ({ value }) => {
      return value ? "Correção possivel" : "Correção não possivel";
    },
  },
];
