import React from 'react'
import BarCode from 'react-barcode'

import logoCorreios from '../../../assets/images/logoCorreios.svg'

export default class  Etiqueta extends React.Component{
    render(){
        let dados = this.props.data
        return(
            <div style={{padding: '0 0px 10px 0', borderRight: '3px dashed black', borderBottom: '3px dashed black',width: '153mm'}}>
            <div style={{width: '150mm', border: '1px solid black'}}>
                <div style={{display: 'flex'}}>
                    <div style={{width: '20%', padding: '7% 10px', borderRight: '1px solid black', marginRight:'7%'}}>
                        <img src={logoCorreios} alt="logoCorreios" />
                    </div>
                    <BarCode value={dados.codRastreio} height={68} width={3.4} textPosition='top' fontSize='11' font="Arial"/>
                </div> 
                <div style={{border: '2px solid black'}}>
                    <div style={{background: 'black', color: 'white', width: '110px',margin: '-1px 0 0 -1px', padding: '2px', font: 'bold 11px Arial'}}>
                        DESTINATÁRIO   
                    </div>
                    <div style={{margin:'4mm 0 5mm 10mm', font: '400 11px Arial'}}>
                        <p style={{font: '400 11px Arial'}}>{dados.nomeDestinatario}</p>
                        <p style={{font: '400 11px Arial'}}>{`${dados.ruaDestinatario}, ${dados.numeroDestinatario}`}</p>
                        <p style={{font: '400 11px Arial'}}>{dados.bairroDestinatario}</p>          
                        <div style={{display: 'flex'}}>
                            <p style={{font: '400 11px Arial', marginRight: '20px'}}>{`${dados.cepDestinatario} ${dados.cidadeDestinatario} ${dados.ufDestinatario}`}</p> <p style={{font: '400 11px Arial'}}>{`Obs: ${dados.complementoDestinatario}`}</p>
                        </div>
                        <BarCode value={dados.cepDestinatario} height={68} width={1.3} fontSize='11' font="Arial"/>

                    </div>
                </div>   
                <div style={{margin:'5px 0 0 5mm'}}>
                    <p style={{font: '400 11px Arial'}}><strong>Remetente:</strong> {dados.nomeRemetente}</p>
                    <p style={{font: '400 11px Arial'}}>{`${dados.ruaRemetente}, ${dados.numeroRemetente}`}</p>
                    <p style={{font: '400 11px Arial'}}> {`${dados.complementoRemetente} - ${dados.bairroRemetente}`}</p>
                    <p style={{font: '400 11px Arial'}}>{`${dados.cepRemetente} ${dados.cidadeRemetente} ${dados.ufRemetente}`}</p> 
                </div>           
            </div>
            </div>
        )
    }
}