import React, { useEffect } from "react";

import { IoIosArrowBack } from "react-icons/io";
import { Button, Card, Col, Form, Row, TabContent, TabPane } from "reactstrap";

import { useSelector } from "react-redux";

import * as actions from "../../store/modules/vinculoLoja/actions";
import * as vinculoLojaController from "../../controllers/vinculoLojaController";

import Search from "./tabs/search";
import Register from "./tabs/register";

import Breadcrumb from "../../components/breadcrumbs";

const VinculoLoja = () => {
  const { item, activeTab, seeRegister, permitions } = useSelector(
    (state) => state.vinculoLoja
  );

  useEffect(() => {
    actions.setActiveTab("Search");
  }, []);

  async function saveItem() {
    let isSuccessed = false;
    if (!item.id) {
      isSuccessed = await vinculoLojaController.create(item);
    } else {
      isSuccessed = await vinculoLojaController.update(item);
    }

    if (isSuccessed) {
      changeTab("Search");
      vinculoLojaController.get(0, 10);
    }
  }

  function changeTab(tab) {
    actions.setActiveTab(tab);
    actions.cleanRegister();
  }

  function seeRegisterF() {
    if (item.id !== "") {
      actions.cleanRegister();
    }
    actions.setSeeRegister(false);
  }

  return (
    <>
      <Col>
        <Breadcrumb activeTab={activeTab} />

        <Card>
          <Form>
            {activeTab === "Search" ? (
              permitions.criar && (
                <Col md={12}>
                  <Row className="pt-2 pb-2">
                    <Col md={8}>
                      <Button
                        onClick={() => changeTab("Register")}
                        style={{ width: "210px" }}
                        className="purpleButton"
                      >
                        Cadastrar Loja Bancária
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )
            ) : (
              <Col className="pt-2 pb-2">
                <Button
                  onClick={() => {
                    changeTab("Search");
                    seeRegisterF();
                  }}
                  style={{ width: "150px", color: "white" }}
                  className="purpleButton"
                >
                  <IoIosArrowBack style={{ marginTop: "3px", float: "left" }} />{" "}
                  Voltar
                </Button>
                {!seeRegister && (
                  <Button
                    onClick={saveItem}
                    style={{ width: "242px", float: "right" }}
                    className="purpleButton"
                  >
                    Salvar Cadastro
                  </Button>
                )}
              </Col>
            )}
          </Form>
        </Card>
      </Col>

      <TabContent style={{ width: "100%" }} activeTab={activeTab}>
        <TabPane tabId="Search">
          <br />
          <Search />
        </TabPane>
        <TabPane tabId="Register">
          <br />
          <Register />
        </TabPane>
      </TabContent>
    </>
  );
};
export default VinculoLoja;
