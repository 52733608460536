import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    tab: {
        tab: '1',
        id: '129959c1-428b-4fca-95f1-da96deb61c3a'
    },
    tabs:[
        {
            tab: '1',
            id: '129959c1-428b-4fca-95f1-da96deb61c3a',
            quantidade: '0'
        },
        {
            tab: '2',
            id: 'f04da7ed-0dad-4de3-885e-d02a7c6378d3',
            quantidade: '0'
        },
        {
            tab: '3',
            id: '15b3e507-f3a8-4a17-b8f2-4b92b21273f0',
            quantidade: '0'
        },
        {
            tab: '4',
            id: '77c29cdd-3943-4817-8875-0a971d016f50',
            quantidade: '0'
        },
        {
            tab: '5',
            id: 'a3842893-e6d0-4cf8-a81a-2656d3d6e473',
            quantidade: '0'
        },
    ],
    tabelasDeComissao: [],
    produtos: [],
    tiposOperacao: [],
    convenios: [],
    tiposComissao: [],
    permitions:{
        criar: false
    }
}

export default function inconsistenciaComissao(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_TABS:
                    return { ...state, tabs: action.value }
                case TYPES.SET_TAB:
                    return { ...state, tab: action.value }
                case TYPES.SET_COMISSOES:
                    return { ...state, tabelasDeComissao: action.value }
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.SET_PRODUTOS:
                    return { ...state, produtos: action.value }
                case TYPES.SET_TIPOS_OPERACAO:
                    return { ...state, tiposOperacao: action.value }
                case TYPES.SET_CONVENIOS:
                    return { ...state, convenios: action.value }
                case TYPES.SET_TIPOS_COMISSAO:
                    return { ...state, tiposComissao: action.value }
                case TYPES.CLEAN_TABS:
                return { ...state,
                     tabelasDeComissao: INITIAL_STATE.tabelasDeComissao,
                     produtos: INITIAL_STATE.produtos,
                     tiposOperacao: INITIAL_STATE.tiposOperacao,
                     convenios: INITIAL_STATE.convenios,
                     tiposComissao: INITIAL_STATE.tiposComissao,}
            default:
                    return { ...state }
                }
                default:
                    return { ...state }
            }
        }