import React from "react";
import * as BiIcons from "react-icons/bi";
import * as papeisActions from "../../../../../../../store/modules/personas/papeis_store/actions";
import * as personasController from "../../../../../../../controllers/personasController/personasController";
import { useSelector } from "react-redux";
import { maskDate } from "../../../../../../../util/masks";

function TableLojaBanco({lojasBancos}) {

    const {
        seeRegister,
        instituicoesFinanceira,
        tiposParcerias,
      } = useSelector((state) => state.personasPapeis);

    const data = lojasBancos;

    async function handleRemoverItem(row, index) {
        let valid = await personasController.deleteLojaBanco(
            row.id
          );
          if (valid) {
            const arrLojas = lojasBancos;
            arrLojas.splice(index, 1);
            papeisActions.setLojasBanco(arrLojas);
          }
    }

    function setIsEditable(row){
      row.isEditable = true
      if(row.dataVigenciaFinal){
        row.isDataFinalEditable = true
      }else{
        row.isDataFinalEditable =false
      }
       var tipoParceria =  tiposParcerias.find(e=> e.id === row.tipoParceriaId)
       row.tipoParceria = tipoParceria.nome
       row.isMasterEditable = row.lojaMasterId ? false : true
      papeisActions.lojaBanco({...row});
    }

    return (
        <>
            <table style={{marginTop: '20px'}}>
                <thead>
                    <tr>
                        <th>Instituição Financeira</th>
                        <th>Relacionamento</th>
                        <th>Código de loja da instituição</th>
                        <th>Data de início da vigência</th>
                        <th>Data de fim de vigência</th>
                        <th style={{ textAlign: 'center' , width: "10%" }}>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => {
                        var instituicao = instituicoesFinanceira.find(e=> e.id === row.bancoId)
                        var tipoParceria = tiposParcerias.find(e=> e.id === row.tipoParceriaId)
                        return (
                            <>
                                <tr>
                                    <td>{instituicao!== undefined && instituicao.nome}</td>
                                    <td>{tipoParceria !== undefined && tipoParceria.nome}</td>
                                    <td>{row.codigoLojaBanco}</td>
                                    <td>{row.dataVigenciaInicial && maskDate(row.dataVigenciaInicial, 'DD/MM/YYYY')}</td>
                                    <td>{row.dataVigenciaFinal && maskDate(row.dataVigenciaFinal, 'DD/MM/YYYY')}</td>
                                    <td style={{ textAlign: 'center', width: "10%" }}>
                                        <>
                                           { !seeRegister && 
                                             (
                                                <>
                                                    <BiIcons.BiEdit
                                                    style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}
                                                    onClick={() => setIsEditable(row)}
                                                    />
                                                    <BiIcons.BiTrash
                                                        style={{ fontSize: '1.2rem', cursor: 'pointer', color: '#C4C7CA' }}
                                                        onClick={() => handleRemoverItem(row, index)}
                                                    />
                                                </>)}
                                        </>
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

export default TableLojaBanco
