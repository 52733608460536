const STORE_ID = "CONVENIOS";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    INCONSISTENCIA: "INCONSISTENCIA",
    INCONSISTENCIA_COPY: "INCONSISTENCIA_COPY",
    INVALID_INCONSISTENCIA: "INVALID_INCONSISTENCIA",
    INCONSISTENCIAS: "INCONSISTENCIAS",
    SET_BANCOS: "SET_BANCOS",
    SET_RESPONSAVEIS: "SET_RESPONSAVEIS",
    CLEAN_INVALID_INCONSISTENCIA: "CLEAN_INVALID_INCONSISTENCIA",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE"
}

export { STORE_ID, TYPES }