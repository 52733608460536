import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    item: {
        estadoId: "0",
        nome: "",
        id: ""
    },
    copy: {},
    invalid: {
        nome: false,
        estadoId: false,
    },
    search: {
        nome: "",
        estadoId: "0",
    },
    items: [],
    estados: [],
    activeTab: "Search",
    seeRegister: false
}

export default function orgaoEmissor(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.ORGAOS_EMISSORES:
                    return { ...state, items: action.value }
                case TYPES.ORGAO_EMISSOR:
                    return { ...state, item: action.value }
                case TYPES.INVALID_ORGAO_EMISSOR:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_ORGAO_EMISSOR:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.ORGAO_EMISSOR_COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, item: INITIAL_STATE.item, invalid: INITIAL_STATE.invalid }
                case TYPES.CLEAN_SEARCH:
                    return { ...state, search: INITIAL_STATE.search}
                case TYPES.SET_SEARCH:
                    return { ...state, search: { 
                        ...state.search,
                        [action.field]: action.value 
                    }
                }
                case TYPES.SET_ESTADOS:
                    return { ...state, estados: action.value }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}
