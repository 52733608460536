import api from '../../util/api'
import * as actions from '../../store/modules/campanhaPremiacao/eventos/actions'
import * as actionsCampanha from '../../store/modules/campanhaPremiacao/campanha/actions'
import { notifyWarn, notifySuccess } from '../../components/message/message'
import { removeMaskMoeda } from '../../util/masks'
import { setLoading } from '../../store/modules/globalLoading/actions'

export async function getCanais() {

    const data = await api.get('canal')

    if (!data.success) {
        return
    }

    actions.setCanais(data.content)
}

export async function getParametrosMedida() {

    const data = await api.get('ParametroMedidaCampanha')

    if (!data.success) {
        return
    }

    actions.setParametrosMedida(data.content)
}

export async function getMedidasPremiacao() {

    const data = await api.get('MedidaPremiacaoCampanha')

    if (!data.success) {
        return
    }

    actions.setMedidasPremiacao(data.content)
}

export async function getTiposOperacao() {

    const data = await api.get('tipos-operacao')

    if (!data.success) {
        return
    }

    actions.setTiposOperacao(data.content)
}
export async function getTiposParceria() {

    const data = await api.get('tipoparceira')

    if (!data.success) {
        return
    }

    actions.setTiposParceria(data.content)
}
export async function getConvenios() {

    const data = await api.get('convenios')

    if (!data.success) {
        return
    }

    actions.setConvenios(data.content)
}


export async function getBancos() {

    const data = await api.get('bancos')

    if (!data.success) {
        return
    }

    actions.setBancos(data.content)
}

export async function getProdutos() {

    const data = await api.get('produtos')

    if (!data.success) {
        return
    }

    actions.setProdutos(data.content)
}

export async function getGrupos() {

    const data = await api.get('grupos')

    if (!data.success) {
        return
    }

    actions.setGrupos(data.content)
}

export async function getHierarquiasProprio() {

    const data = await api.get(`hierarquias/hierarquia-canal/eeafe6f2-8e3a-436c-9065-daf6d0b483c0`)

    if (!data.success) {
        return
    }

    actions.setHierarquiasProprio(data.content)
}

export async function getHierarquiasTerceiro() {

    const data = await api.get(`hierarquias/hierarquia-canal/7dd40f59-82e4-4d1e-8792-84a70a44ee1c`)

    if (!data.success) {
        return
    }

    actions.setHierarquiasTerceiro(data.content)
}

export async function getAllHierarquias() {

    const data = await api.get(`hierarquias`)

    if (!data.success) {
        return
    }

    actions.setAllHierarquias(data.content)
}

export async function createRegraQualidade(regra, campanhaId){
    if(regra.tipoPendenciaId === '0'){
        notifyWarn('Não é possível salvar meta de qualidade sem tipo de pendência')
        return {success: false}
    }
    if(regra.periodoId === '0'){
        notifyWarn('Não é possível salvar meta de qualidade sem período')
        return {success: false}
    }
    if(regra.valorMaximo === ''){
        notifyWarn('Não é possível salvar meta de qualidade sem valor máximo')
        return {success: false}
    }
    
    const body = {
        campanhaPremiacaoId: campanhaId,
        valorMaximo: removeMaskMoeda(regra.valorMaximo),
        tipoPendenciaId: regra.tipoPendenciaId,
        periodoId: regra.periodoId
      }

    setLoading(true)
    const data = await api.post('CampanhaPremiacao/regra-qualidade', body)
    setLoading(false)

    if (!data.success) {
        return {success: false}
    }

    actionsCampanha.setRegraQualidade('id', data.content.id)
    notifySuccess('Regra de qualidade adicionada com sucesso!')
    return {success: true, id: data.content.id}
}

export async function deleteRegraQualidade(id) {

    const data = await api.delete(`CampanhaPremiacao/regra-qualidade/${id}`)

    if (!data.success) {
        return false
    }

    notifySuccess('Regra de qualidade excluída com sucesso')
   return true
}

export async function createRegraProdutividade(regra, campanhaId){    
    const body = {
        campanhaPremiacaoId: campanhaId,
        descricao: regra.descricao,
        bancoId: regra.bancoId !== '0' ? regra.bancoId : null,
        convenioId: regra.convenioId !== '0' ? regra.convenioId : null,
        tipoOperacaoId: regra.tipoOperacaoId !== '0' ? regra.tipoOperacaoId : null,
        tipoParceriaId: regra.tipoParceriaId !== '0' ? regra.tipoParceriaId : null,
        produtoId: regra.produtoId !== '0' ? regra.produtoId : null,
        centroCustoId: regra.centroCustoId !== '0' ? regra.centroCustoId : null,
        metaGlobal: regra.metaGlobal,
        metaPrincipal: regra.metaPrincipal,
      }

    setLoading(true)
    const data = await api.post('CampanhaPremiacao/regra-produtividade', body)
    setLoading(false)

    if (!data.success) {
        return {success: false}
    }

    actionsCampanha.setMetaProdutividade('id', data.content.id)
    notifySuccess('Regra de produtividade adicionada com sucesso!')
    return {success: true, id: data.content.id}
}

export async function deleteRegraProdutividade(id) {

    const data = await api.delete(`CampanhaPremiacao/regra-produtividade/${id}`)

    if (!data.success) {
        return false
    }

    notifySuccess('Regra de produtividade excluída com sucesso')
   return true
}

export async function createMetaProdutividade(regra, regraProdutividade){    
    const body = {
        campanhaPremiacaoRegraId: regraProdutividade,
        headcount: regra.headCount,
        hierarquiaId: regra.hierarquia.value,
        medidaPremiacaoCampanhaId: regra.medidaPremiacaoCampanhaId,
        valorMeta: removeMaskMoeda(regra.valorMeta),
        parametroMedidaCampanhaId: regra.parametroMedidaCampanhaId,
        valorPremio: removeMaskMoeda(regra.valorPremio)
        
      }

    setLoading(true)
    const data = await api.post('CampanhaPremiacao/meta-produtividade', body)
    setLoading(false)

    if (!data.success) {
        return {success: false}
    }

    actionsCampanha.setMetaProdutividade('id', data.content.id)
    notifySuccess('Meta de produtividade adicionada com sucesso!')
    return {success: true, id: data.content.id}
}

export async function updateMetaProdutividade(meta, campanhaId){    
    const body = {
        campanhaPremiacaoRegraId: campanhaId,
        headcount: meta.headCount,
        hierarquiaId: meta.hierarquia.value,
        medidaPremiacaoCampanhaId: meta.medidaPremiacaoCampanhaId,
        valorMeta: removeMaskMoeda(meta.valorMeta),
        parametroMedidaCampanhaId: meta.parametroMedidaCampanhaId,
        valorPremio: removeMaskMoeda(meta.valorPremio)
      }

    setLoading(true)
    const data = await api.put(`CampanhaPremiacao/meta-produtividade/${meta.id}`, body)
    setLoading(false)

    if (!data.success) {
        return {success: false}
    }

    notifySuccess('Meta de produtividade alterada com sucesso!')
    return {success: true}
}

export async function deleteMetaProdutividade(id){

    const data = await api.delete(`CampanhaPremiacao/meta-produtividade/${id}`)

    if (!data.success) {
        return false
    }

    notifySuccess('Meta de produtividade excluída com sucesso')
   return true
}

export async function createMetaSuperacao(regra, metaProdutividadeId){    
    const body = {
        campanhaPremiacaoMetaProdutividadeId: metaProdutividadeId,
        descricao: regra.descricao,
        medidaPremiacaoSuperacaoId: regra.medidaPremiacaoSuperacaoId,
        valorMeta: removeMaskMoeda(regra.valorMeta),
        valorPremio: removeMaskMoeda(regra.valorPremio),
      }

    setLoading(true)
    const data = await api.post('CampanhaPremiacao/meta-superacao', body)
    setLoading(false)

    if (!data.success) {
        return {success: false}
    }

    actionsCampanha.setMetaSuperacao('id', data.content.id)
    notifySuccess('Meta de superação adicionada com sucesso!')
    return {success: true, id: data.content.id}
}

export async function updateMetaSuperacao(regra, metaProdutividadeId){    
    const body = {
        campanhaPremiacaoMetaProdutividadeId: metaProdutividadeId,
        descricao: regra.descricao,
        medidaPremiacaoSuperacaoId: regra.medidaPremiacaoSuperacaoId,
        valorMeta: removeMaskMoeda(regra.valorMeta),
        valorPremio: removeMaskMoeda(regra.valorPremio),
      }

    setLoading(true)
    const data = await api.put(`CampanhaPremiacao/meta-superacao/${regra.id}`, body)
    setLoading(false)

    if (!data.success) {
        return {success: false}
    }

    actionsCampanha.setMetaSuperacao('id', regra.id)
    notifySuccess('Meta de superação adicionada com sucesso!')
    return {success: true, id: regra.id}
}

export async function deleteMetaSuperacao(id){

    const data = await api.delete(`CampanhaPremiacao/meta-superacao/${id}`)

    if (!data.success) {
        return false
    }

    notifySuccess('Meta de superação excluída com sucesso')
   return true
}

export async function createRegraRepasse(regra, metaProdutividadeId){    
    const body = {
        campanhaPremiacaoMetaProdutividadeId: metaProdutividadeId,
        tiposComissaoId: regra.tiposComissaoId,
        pmt:regra.pmt,
        valor: removeMaskMoeda(regra.valor),
      }

    setLoading(true)
    const data = await api.post('CampanhaPremiacao/regra-repasse', body)
    setLoading(false)

    if (!data.success) {
        return {success: false}
    }

    actionsCampanha.setMetaSuperacao('id', data.content.id)
    notifySuccess('Regra de repasse adicionada com sucesso!')
    return {success: true, id: data.content.id}
}

export async function updateRegraRepasse(regra, metaProdutividadeId){    
    const body = {
        campanhaPremiacaoMetaProdutividadeId: metaProdutividadeId,
        tiposComissaoId: regra.tiposComissaoId,
        pmt: regra.pmt,
        valor: removeMaskMoeda(regra.valor),
      }

    setLoading(true)
    const data = await api.put(`CampanhaPremiacao/regra-repasse/${regra.id}`, body)
    setLoading(false)

    if (!data.success) {
        return {success: false}
    }

    notifySuccess('Regra de repasse adicionada com sucesso!')
    return {success: true, id: regra.id}
}

export async function deleteRegraRepasse(id){

    const data = await api.delete(`CampanhaPremiacao/regra-repasse/${id}`)

    if (!data.success) {
        return false
    }

    notifySuccess('Regra de repasse excluída com sucesso')
   return true
}
