import { STORE_ID, TYPES } from "./types";

export const INITIAL_STATE = {
  activeTab: "Search",
  perfilDeAcessoBeingEditedId: "",
  perfilDeAcessoBeingVisualizedId: "",
  isInEditMode: false,
  isInVisualizeMode: false,
  perfilDeAcessoBeingCreatedData: {
    descricao: "",
    isHierarquia: false,
    isAdministrativo: false,
    telasAssociadas: [],
    lojasAssociadas: [],
  },
  perfilDeAcessoBeingEditedData: {
    id: "",
    descricao: "",
    isHierarquia: false,
    isAdministrativo: false,
    telasAssociadas: [],
    lojasAssociadas: [],
  },
  perfilDeAcessoBeingVisualizedData: {
    id: "",
    descricao: "",
    isHierarquia: false,
    isAdministrativo: false,
    telasAssociadas: [],
    lojasAssociadas: [],
  },
  perfisDeAcesso: [],
  telasDisponiveis: [],
  lojasDisponiveis: [],
  telasDisponiveisInitialValueBackup: [],
  lojasDisponiveisInitialValueBackup: [],
  telasSelecionadas: [],
  lojasSelecionadas: [],
  telasAssociadas: [],
  lojasAssociadas: [],
  telasDisponiveisSearchTerm: "",
  lojasDisponiveisSearchTerm: "",
  telasDisponiveisSearchResults: [],
  lojasDisponiveisSearchResults: [],
  telasDisponiveisSelectedSearchResults: [],
  lojasDisponiveisSelectedSearchResults: [],
  telasAssociadasSearchTerm: "",
  lojasAssociadasSearchTerm: "",
  telasAssociadasSearchResults: [],
  lojasAssociadasSearchResults: [],
  telasAssociadasSelectedSearchResults: [],
  lojasAssociadasSelectedSearchResults: [],
  telasAssociadasSelecionadas: [],
  lojasAssociadasSelecionadas: [],
  permitions: {
    criar: false,
    deletar: false,
    autorizar: false,
    desbloquear: false,
  },
};

export default function perfilAcesso(state = INITIAL_STATE, action) {
  switch (action.storeId) {
    case STORE_ID:
      switch (action.type) {
        case TYPES.SET_ACTIVE_TAB:
          return { ...state, activeTab: action.value };
        case TYPES.CLEAR_PERFIL_DE_ACESSO_BEING_CREATED_DATA:
          return { ...state, perfilDeAcessoBeingCreatedData: action.value };
        case TYPES.CLEAR_PERFIL_DE_ACESSO_BEING_EDITED_DATA:
          return { ...state, perfilDeAcessoBeingEditedData: action.value };
        case TYPES.CLEAR_PERFIL_DE_ACESSO_BEING_VISUALIZED_DATA:
          return { ...state, perfilDeAcessoBeingVisualizedData: action.value };
        case TYPES.SET_PERFIL_DE_ACESSO_BEING_EDITED_ID:
          return { ...state, perfilDeAcessoBeingEditedId: action.value };
        case TYPES.SET_PERFIL_DE_ACESSO_BEING_VISUALIZED_ID:
          return { ...state, perfilDeAcessoBeingVisualizedId: action.value };
        case TYPES.SET_IS_IN_EDIT_MODE:
          return { ...state, isInEditMode: action.value };
        case TYPES.SET_PERFIL_DE_ACESSO_BEING_CREATED_DATA:
          return {
            ...state,
            perfilDeAcessoBeingCreatedData: {
              ...state.perfilDeAcessoBeingCreatedData,
              [action.campo]: action.value,
            },
          };
        case TYPES.SET_PERFIL_DE_ACESSO_BEING_EDITED_DATA:
          return {
            ...state,
            perfilDeAcessoBeingEditedData: {
              ...state.perfilDeAcessoBeingEditedData,
              [action.campo]: action.value,
            },
          };
        case TYPES.SET_PERFIL_DE_ACESSO_BEING_VISUALIZED_DATA:
          return {
            ...state,
            perfilDeAcessoBeingVisualizedData: {
              ...state.perfilDeAcessoBeingVisualizedData,
              [action.campo]: action.value,
            },
          };
        case TYPES.SET_PERFIS_DE_ACESSO:
          return { ...state, perfisDeAcesso: action.value };
        case TYPES.SET_TELAS_DISPONIVEIS:
          return { ...state, telasDisponiveis: action.value };
        case TYPES.SET_LOJAS_DISPONIVEIS:
          return { ...state, lojasDisponiveis: action.value };
        case TYPES.SET_TELAS_DISPONIVEIS_INITIAL_VALUE_BACKUP:
          return { ...state, telasDisponiveisInitialValueBackup: action.value };
        case TYPES.SET_LOJAS_DISPONIVEIS_INITIAL_VALUE_BACKUP:
          return { ...state, lojasDisponiveisInitialValueBackup: action.value };
        case TYPES.SET_TELAS_SELECIONADAS:
          return { ...state, telasSelecionadas: action.value };
        case TYPES.SET_LOJAS_SELECIONADAS:
          return { ...state, lojasSelecionadas: action.value };
        case TYPES.SET_TELAS_ASSOCIADAS:
          return { ...state, telasAssociadas: action.value };
        case TYPES.SET_LOJAS_ASSOCIADAS:
          return { ...state, lojasAssociadas: action.value };
        case TYPES.SET_TELAS_DISPONIVEIS_SEARCH_TERM:
          return { ...state, telasDisponiveisSearchTerm: action.value };
        case TYPES.SET_LOJAS_DISPONIVEIS_SEARCH_TERM:
          return { ...state, lojasDisponiveisSearchTerm: action.value };
        case TYPES.SET_TELAS_DISPONIVEIS_SEARCH_RESULTS:
          return { ...state, telasDisponiveisSearchResults: action.value };
        case TYPES.SET_LOJAS_DISPONIVEIS_SEARCH_RESULTS:
          return { ...state, lojasDisponiveisSearchResults: action.value };
        case TYPES.SET_TELAS_DISPONIVEIS_SELECTED_SEARCH_RESULTS:
          return {
            ...state,
            telasDisponiveisSelectedSearchResults: action.value,
          };
        case TYPES.SET_LOJAS_DISPONIVEIS_SELECTED_SEARCH_RESULTS:
          return {
            ...state,
            lojasDisponiveisSelectedSearchResults: action.value,
          };

        case TYPES.SET_TELAS_ASSOCIADAS_SEARCH_TERM:
          return { ...state, telasAssociadasSearchTerm: action.value };
        case TYPES.SET_LOJAS_ASSOCIADAS_SEARCH_TERM:
          return { ...state, lojasAssociadasSearchTerm: action.value };
        case TYPES.SET_TELAS_ASSOCIADAS_SEARCH_RESULTS:
          return { ...state, telasAssociadasSearchResults: action.value };
        case TYPES.SET_LOJAS_ASSOCIADAS_SEARCH_RESULTS:
          return { ...state, lojasAssociadasSearchResults: action.value };
        case TYPES.SET_TELAS_ASSOCIADAS_SELECTED_SEARCH_RESULTS:
          return {
            ...state,
            telasAssociadasSelectedSearchResults: action.value,
          };
        case TYPES.SET_LOJAS_ASSOCIADAS_SELECTED_SEARCH_RESULTS:
          return {
            ...state,
            lojasAssociadasSelectedSearchResults: action.value,
          };
        case TYPES.SET_TELAS_ASSOCIADAS_SELECIONADAS:
          return { ...state, telasAssociadasSelecionadas: action.value };
        case TYPES.SET_LOJAS_ASSOCIADAS_SELECIONADAS:
          return { ...state, lojasAssociadasSelecionadas: action.value };

        case TYPES.SET_LOADING_TELAS:
          return { ...state, loadingTelas: action.value };
        case TYPES.SET_LOADING_LOJAS:
          return { ...state, loadingLojas: action.value };
        case TYPES.SET_PERMITIONS:
          return { ...state, permitions: action.value };
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
}
