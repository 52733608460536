import api from "../../util/api";
import * as actions from "../../store/modules/dashboard/formalizacao/actions";
import { setLoading } from "../../store/modules/globalLoading/actions";
import { notifyWarn } from "../../components/message/message";
import { maskDate, maskMoeda, removeMaskCNPJ } from "../../util/masks";

export async function get(filtros){
    if(filtros.dataInicio === ''){
        return notifyWarn('É necessário informar data de início')
    }
    if(filtros.dataFim === ''){
        return notifyWarn('É necessário informar data fim')
    }

    setLoading(true);
    var isBigNumbersSuccessed = await getBigNumbers(filtros)
    var isGraficosSuccessed = await getGraficos(filtros)
    var isTabelaSuccessed = await getTabela(filtros)
    setLoading(false);

    if( isBigNumbersSuccessed && isGraficosSuccessed && isTabelaSuccessed){
        return true
    }
}

export async function getBigNumbers(filtros){
    var url = `formalizacao/bignumbers`

    if(filtros.dataInicio !== ''){
        url = url + `?dataInicio=${filtros.dataInicio}`
    }else if(filtros.dataInicio === ''){
        url = url + `?dataInicio=`
    }

    if(filtros.dataFim !== ''){
        url = url + `&dataFim=${filtros.dataFim}`
    }else if (filtros.dataFim === ''){
        url = url + `&dataFim=`
    }

    if(filtros.instituicaoId !== '0'){
        url = url + `&bancoId=${filtros.instituicaoId}`
    }

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.produtoId !== '0'){
        url = url + `&produtoId=${filtros.produtoRibercredId}`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&tipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.parceriaId !== '0'){
        url = url + `&tipoParceriaId=${filtros.parceriaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&consultorId=${filtros.consultorId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    const newData = data.content.map((e)=>{
        return({
            crescimento : e.crescimento,
            qtdPropostas: e.quantidade,
            valorTotal: e.valor,
            status: e.descricao
        })
    })

    actions.setBigNumbers(newData);
    return true
}

export async function getGraficos(filtros){
    var url = `formalizacao/grafico-reabertura-primentrega`

    if(filtros.dataInicio !== ''){
        url = url + `?dataInicio=${filtros.dataInicio}`
    }else if(filtros.dataInicio === ''){
        url = url + `?dataInicio=`
    }

    if(filtros.dataFim !== ''){
        url = url + `&dataFim=${filtros.dataFim}`
    }else if (filtros.dataFim === ''){
        url = url + `&dataFim=`
    }

    if(filtros.instituicaoId !== '0'){
        url = url + `&bancoId=${filtros.instituicaoId}`
    }

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.produtoId !== '0'){
        url = url + `&produtoId=${filtros.produtoRibercredId}`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&tipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.parceriaId !== '0'){
        url = url + `&tipoParceriaId=${filtros.parceriaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&consultorId=${filtros.consultorId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    const graficoPendencia = [ ['Pendência', 'Quantidade'] ]
    const graficoFormalizacao = [ ['Formalização', 'Quantidade'] ]

    data.content.forEach((e)=>{
        if(e.descricao === 'Pendencia'){
            e.itensGrafico.forEach((grafico)=>{
                graficoPendencia.push([grafico.descricao, grafico.quantidade])
            })
        }else if(e.descricao === 'Formalizacao'){
            e.itensGrafico.forEach((grafico)=>{
                graficoFormalizacao.push([grafico.descricao, grafico.quantidade])
            })
        }
    })

    actions.setGraficoFormalizacao(graficoFormalizacao);
    actions.setGraficoPendencia(graficoPendencia);
    return true
}

export async function getTabela(filtros){
    var url = `formalizacao/tabela-n1-formalizacao`

    if(filtros.dataInicio !== ''){
        url = url + `?dataInicio=${filtros.dataInicio}`
    }else if(filtros.dataInicio === ''){
        url = url + `?dataInicio=`
    }

    if(filtros.dataFim !== ''){
        url = url + `&dataFim=${filtros.dataFim}`
    }else if (filtros.dataFim === ''){
        url = url + `&dataFim=`
    }

    if(filtros.instituicaoId !== '0'){
        url = url + `&bancoId=${filtros.instituicaoId}`
    }

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.produtoId !== '0'){
        url = url + `&produtoId=${filtros.produtoRibercredId}`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&tipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.parceriaId !== '0'){
        url = url + `&tipoParceriaId=${filtros.parceriaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&consultorId=${filtros.consultorId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((loja)=>{
        loja.status = []
    })

    actions.setTabelaFormalizacao(data.content);
    return true
}

export async function getTabelaStatus(filtros, lojaId, tabela){
    var url = `formalizacao/tabela-n2-formalizacao`

    if(filtros.dataInicio !== ''){
        url = url + `?dataInicio=${filtros.dataInicio}`
    }else if(filtros.dataInicio === ''){
        url = url + `?dataInicio=`
    }

    if(filtros.dataFim !== ''){
        url = url + `&dataFim=${filtros.dataFim}`
    }else if (filtros.dataFim === ''){
        url = url + `&dataFim=`
    }

    if(filtros.instituicaoId !== '0'){
        url = url + `&bancoId=${filtros.instituicaoId}`
    }

    url = url + `&lojaId=${lojaId}`

    if(filtros.produtoId !== '0'){
        url = url + `&produtoId=${filtros.produtoRibercredId}`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&tipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.parceriaId !== '0'){
        url = url + `&tipoParceriaId=${filtros.parceriaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&consultorId=${filtros.consultorId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((proposta)=>{
        proposta.valor = maskMoeda(proposta.valor.toFixed(2))
        proposta.dataProposta = maskDate(proposta.dataProposta, 'DD/MM/YYYY')
    })

    tabela.forEach((e)=>{
        if(e.lojaId === lojaId){
            e.status = data.content
        }
    })

    actions.setTabelaFormalizacao(tabela);
    return true
}

export async function getInstituicoes() {
    const data = await api.get(`Bancos`);

    if (!data.success) {
        return;
    }
    actions.setInsituicoes(data.content);
}

export async function getLojas(pesquisa, page, pageSize) {
    var url = `Pessoa?pagina=${page}&registros=${pageSize}&papel=5`
    if (pesquisa.nome !== ''){
        url = `Pessoa/busca-com-nome/${pesquisa.nome}?pagina=${page}&registros=${pageSize}&papel=5`
    } 

    else if (pesquisa.cnpj !== '') url = url + `&cpfCnpj=${removeMaskCNPJ(pesquisa.cnpj)}`
    actions.setLoadingLojas(true);
    const data = await api.get(url);
    actions.setLoadingLojas(false);

    if (!data.success) {
        return;
    }
    
    actions.setLojas(data.content.registros);
    actions.setGetAllLojas({
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas
    })
}

export async function getTiposOperacao() {
    const data = await api.get(`tipos-operacao`);

    if (!data.success) {
        return;
    }
    actions.setTiposOperacao(data.content);
}

export async function getParcerias() {
    const data = await api.get(`TipoParceira`);

    if (!data.success) {
        return;
    }
    actions.setParcerias(data.content);
}

export async function getConvenios() {
    const data = await api.get(`Convenios`);

    if (!data.success) {
        return;
    }
    actions.setConvenios(data.content);
}

export async function getGrupos() {
    const data = await api.get(`grupos`);

    if (!data.success) {
        return;
    }
    actions.setGrupos(data.content);
}

export async function getProdutos() {
    const data = await api.get(`Produtos`);

    if (!data.success) {
        return;
    }
    actions.setProdutos(data.content);
}

export async function getConsultores(pesquisa, page, pageSize) {
    var url = `lojas/consultores?pagina=${page}&registros=${pageSize}`

    if (pesquisa.cpf !== '') url = url + `&cpf=${pesquisa.cpf}`
    if (pesquisa.nome !== '') url = url + `&nome=${pesquisa.nome}`

    actions.setLoadingConsultores(true);
    const data = await api.get(url);
    actions.setLoadingConsultores(false);

    if (!data.success) {
        return;
    }
    
    actions.setGetAllConsultores({
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas
    })
    actions.setConsultores(data.content.registros);
}