import api from "../../util/api";
import { notifySuccess, notifyWarn } from "../../components/message/message";
import * as enderecoActions from "../../store/modules/personas/endereco_store/actions";
import axios from "axios";
import { removeMaskCEP } from "../../util/masks";
import { setLoading } from "../../store/modules/globalLoading/actions";

function cleanDados(){
    enderecoActions.cleanEndereco();
    enderecoActions.setIsEditando(false);
    enderecoActions.setIsDeleting(false);
}
export async function getEnderecoByCEP(endereco, estados, index, enderecos) {
    let cepNoMask = endereco.cep !== "" ? removeMaskCEP(endereco.cep) : "";

    if (cepNoMask.trim() !== "") {

        if (cepNoMask.trim().length === 8) {
            setLoading(true);
            const viacep = await axios.get(`https://viacep.com.br/ws/${cepNoMask}/json/`
            ).then(response => {
                
                const result = {
                    cidade: response.data.localidade,
                    bairro: response.data.bairro,
                    logradouro: response.data.logradouro,
                    uf: response.data.uf
                }
                
                let estadoId = "";
                estados.forEach(estado => {
                    if (result.uf === estado.sigla) {
                        return estadoId = estado.id;
                    }
                })
                if (index !== undefined) {
                  const newEnderecosValue = [...enderecos]
                  newEnderecosValue[index] = {
                      ...endereco, cidade: response.data.localidade,
                      bairro: response.data.bairro,
                      logradouro: response.data.logradouro,
                      uf: response.data.uf,
                      estadoId: estadoId
                    }
                  enderecoActions.setEnderecos(newEnderecosValue);
                } else {
                  enderecoActions.setEndereco({
                    ...endereco, cidade: response.data.localidade,
                    bairro: response.data.bairro,
                    logradouro: response.data.logradouro,
                    uf: response.data.uf,
                    estadoId: estadoId
                  });
                }

                setLoading(false);
                return true;
            }).catch(() => {
                return false;

            });
            if (viacep) {
                return true;
            }
            notifyWarn("CEP não encontrado");
        } else {
            notifyWarn("CEP inválido");
        }
    }
}

export async function getTiposEndereco() {
    const data = await api.get("tipoEndereco");

    if (!data.success) {
        return;
    }

    enderecoActions.getItemsTiposEnderecos(data.content);
}

export async function getEstados() {
    const data = await api.get("estados");

    if (!data.success) {
        return;
    }

    enderecoActions.setEstados(data.content);
}


export async function salveEndereco(id, endereco) {
    let body = []

    if (endereco.tipoEnderecoId === "0" ||
        endereco.cep === "" ||
        endereco.estadoId === "0" ||
        endereco.estadoId === "" ||
        endereco.cidade === "" ||
        endereco.bairro === "" ||
        endereco.logradouro === "" ||
        endereco.numero === ""
    ) {
        notifyWarn("Aba Endereço: Preencha todos os campos para adicionar um novo Endereço");
        return;
    }

    body.push({
        id: endereco.id === null ? '00000000-0000-0000-0000-000000000000' : endereco.id,
        tipoEnderecoId: endereco.tipoEnderecoId,
        cep: endereco.cep,
        estadoId: endereco.estadoId,
        principal: endereco.principal,
        cidade: endereco.cidade,
        bairro: endereco.bairro,
        logradouro: endereco.logradouro,
        numero: endereco.numero,
        complemento: endereco.complemento
    })

    setLoading(true);
    const data = await api.put(`Pessoa/${id}/enderecos`, body);
    setLoading(false);

    if (!data.success) {
        return;
    }

    notifySuccess("Endereço cadastrado");
    enderecoActions.setEnderecos(data.content.enderecos)
    cleanDados();
}


export async function removeEndereco(id){
    setLoading(true);
    const data = await api.delete(`Pessoa/${id}/enderecos`);
    setLoading(false);

    if (!data.success) {
        cleanDados();
        return false;
    }

    notifySuccess("Endereço removido com sucesso!")
    enderecoActions.setEnderecos(data.content.enderecos);
    cleanDados();
}