import {Button, Card, Col, Form, Row, TabContent, TabPane} from "reactstrap";
import Breadcrumb from "../../components/breadcrumbs";
import {useSelector} from "react-redux";
import * as actions from '../../store/modules/contaGerencial/actions';
import {IoIosArrowBack} from "react-icons/io";
import React, {useEffect, useState} from "react";
import Search from "./tabs/search"
import Register from "./tabs/register"
import * as contaGerencialController from "../../controllers/contaGerencialController"
import {getProdutosRiber, getTiposComissao} from "../../controllers/contaGerencialController";

const ContaGerencial = () => {

    const {item, activeTab, seeRegister, permitions, isDespesa, search} =
        useSelector(state => state.contaGerencial)
    const [ selectedPage, setSelectedPage ] = useState(null);

    useEffect(() => {
        actions.setActiveTabContaGerencial("Search")
        contaGerencialController.getProdutosRiber();
        contaGerencialController.getTiposComissao();
        contaGerencialController.getTiposContaGerencial();
    }, [])

    function changeTab(tab, valueIsCreate) {
        actions.setActiveTabContaGerencial(tab);
        actions.setIsCreate(valueIsCreate);
        initialState();
    }

    function seeRegisterContaGerencial() {
        if (item.produto.id !== "") {
            actions.cleanProdutoContaGerencial();
        } else if (item.descricao.id !== "") {
            actions.cleanDescricaoContaGerencial();
        }
        actions.setSeeRegisterContaGerencial(false);
    }

    function handleVoltar(){
        setSelectedPage(0)
        contaGerencialController.getAll(0, 10);
        seeRegisterContaGerencial();
        changeTab("Search", false);
    }

    async function saveItem() {
        if (item.descricao.isDescricao) {
            if (item.descricao.id !== null) {
                await contaGerencialController.atualizaDescricaoContaGerencial(item.descricao, isDespesa);
            } else {
                await contaGerencialController.registraDescricaoContaGerencial(item.descricao, isDespesa);
            }
        } else {
            if (item.produto.id !== null) {
                await contaGerencialController.atualizaProdutoContaGerencial(item.produto, isDespesa);
            } else {
                await contaGerencialController.registraProdutoContaGerencial(item.produto, isDespesa);
            }
        }
    }

    function initialState(){
        actions.cleanProdutoContaGerencial();
        actions.cleanDescricaoContaGerencial();
        actions.cleanContaGerencial();
        getProdutosRiber();
        getTiposComissao();
        actions.setIsDespesa(false);
        actions.setSeeRegisterContaGerencial(false);
        actions.setIsUpdate(false);
        actions.setTipoIsProduto(false);
        actions.setIsProduto();
        const states = {
            isDescricao: search.isDescricao ? false : search.isDescricao,
            isProduto: search.isProduto ? false : search.isProduto
        }
        actions.setSearch({...search, ...states});
    }

    return (
        <>
            <Col>
                <Breadcrumb activeTab={activeTab}/>
                <Card>
                    <Form>
                        {
                            activeTab === "Search"
                                ?
                                permitions.criar && (
                                    <Col md={12}>
                                        <Row className="pt-2 pb-2">
                                            <Col md={8}>
                                                <Button
                                                    onClick={() => changeTab("Register", true)}
                                                    style={{width: '210px'}}
                                                    className="purpleButton"
                                                >
                                                    Cadastrar Conta Gerencial
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                                :
                                <Col className="pt-2 pb-2">
                                    <Button
                                        onClick={() => {
                                            handleVoltar();
                                        }}
                                        style={{width: "127px", color: "white"}}
                                        className="purpleButton"
                                    >
                                        <IoIosArrowBack style={{marginTop: '3px', float: 'left'}}/> Voltar
                                    </Button>
                                    {!seeRegister &&
                                        <Button
                                            onClick={() => saveItem()}
                                            style={{width: "242px", float: 'right'}}
                                            className="purpleButton"
                                        >
                                            Salvar Cadastro
                                        </Button>
                                    }
                                </Col>
                        }
                    </Form>
                </Card>
            </Col>

            <TabContent style={{width: "100%"}} activeTab={activeTab}>
                <TabPane tabId="Search">
                    <br/>
                    <Search selectedPage={selectedPage} setSelectedPage={setSelectedPage}/>
                </TabPane>
                <TabPane tabId="Register">
                    <br/>
                    <Register/>
                </TabPane>
            </TabContent>
        </>
    )
}

export default ContaGerencial