const STORE_ID = "ORGAO_EMISSOR";

const TYPES = {
    ORGAO_EMISSOR: "ORGAO_EMISSOR",
    ORGAO_EMISSOR_COPY: "ORGAO_EMISSOR_COPY",
    INVALID_ORGAO_EMISSOR: "INVALID_ORGAO_EMISSOR",
    ORGAOS_EMISSORES: "ORGAOS_EMISSORES",
    CLEAN_INVALID_ORGAO_EMISSOR: "CLEAN_INVALID_ORGAO_EMISSOR",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    CLEAN_SEARCH: "CLEAN_SEARCH",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_SEARCH: "SET_SEARCH",
    SET_ESTADOS: "SET_ESTADOS",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE"
}

export { STORE_ID, TYPES }