import { STORE_ID, TYPES} from './types';
import store from '../../../store/index';

function dispatch(data) {
    data = { ...data, storeId: STORE_ID}
    store.dispatch(data);
}

export function setProdutoContaGerencial(value) {
    dispatch({
        type: TYPES.SET_PRODUTO_CONTA_GERENCIAL,
        value
    })
}

export function setDescricaoContaGerencial(value){
    dispatch({
        type: TYPES.SET_DESCRICAO_CONTA_GERENCIAL,
        value
    })
}

export function cleanProdutoContaGerencial(){
    dispatch({
        type: TYPES.CLEAN_PRODUTO_CONTA_GERENCIAL
    })
}

export function cleanDescricaoContaGerencial(){
    dispatch({
        type: TYPES.CLEAN_DESCRICAO_CONTA_GERENCIAL
    })
}

export function setProdutosRiberContaGerencial(value){
    dispatch({
        type: TYPES.SET_PRODUTOS_RIBER_CONTA_GERENCIAL,
        value
    })
}

export function setTiposComissaoContaGerencial(value){
    dispatch({
        type: TYPES.SET_TIPOS_COMISSAO_CONTA_GERENCIAL,
        value
    })
}

export function setActiveTabContaGerencial(value){
    dispatch({
        type: TYPES.SET_ACTIVE_TAB_CONTA_GERENCIAL,
        value
    })
}

export function setPermitionsContaGerencial(value){
    dispatch({
        type: TYPES.SET_PERMITIONS_CONTA_GERENCIAL,
        value
    })
}

export function setSeeRegisterContaGerencial(value){
    dispatch({
        type: TYPES.SET_SEE_REGISTER_CONTA_GERENCIAL,
        value
    })
}

export function setContasGerenciais(value){
    dispatch({
        type: TYPES.SET_CONTAS_GERENCIAIS,
        value
    })
}

export function setIsProduto(){
    dispatch({
        type: TYPES.SET_IS_PRODUTO,
    })
}

export function setIsDescricao(){
    dispatch({
        type: TYPES.SET_IS_DESCRICAO,
    })
}

export function setGetAll(value){
    dispatch({
        type: TYPES.SET_GET_ALL,
        value
    })
}

export function cleanContaGerencial(){
    dispatch({
        type: TYPES.CLEAN_CONTA_GERENCIAL
    })
}

export function setIsDespesa(value){
    dispatch({
        type: TYPES.SET_IS_DESPESA,
        value
    })
}

export function copy(value){
    dispatch({
        type: TYPES.CONTA_GERENCIAL_COPY,
        value
    })
}

export function setItem(value){
    dispatch({
        type: TYPES.SET_ITEM,
        value
    })
}

export function setTipoIsProduto(value){
    dispatch({
        type: TYPES.SET_TIPO_IS_PRODUTO,
        value
    })
}

export function setIsUpdate(value){
    dispatch({
        type: TYPES.SET_IS_UPDATE,
        value
    })
}

export function setIsCreate(value){
    dispatch({
        type: TYPES.SET_IS_CREATE,
        value
    })
}

export function setTiposConta(value){
    dispatch({
        type: TYPES.SET_TIPOS_CONTA,
        value
    })
}

export function setSearch(value){
    dispatch({
        type: TYPES.SET_SEARCH,
        value
    })
}

export function cleanSearch(){
    dispatch({
        type: TYPES.CLEAN_SEARCH
    })
}