import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function item(value) {
    dispatch({
        type: TYPES.GRUPO,
        value
    });
}

export function copy(value) {
    dispatch({
        type: TYPES.GRUPO_COPY,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_GRUPO,
        field
    });
}

export function getItems(value) {
    dispatch({
        type: TYPES.GRUPOS,
        value
    });
}
export function setGetAll(value) {
    dispatch({
        type: TYPES.SET_GET_ALL,
        value
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_GRUPO,
        field
    });
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function setPessoas(value) {
    dispatch({
        type: TYPES.SET_PESSOA,
        value
    })
}

export function getTipoPessoa(value){
    dispatch({
        type: TYPES.SET_TIPO_PESSOA,
        value
    })
}

export function setPessoa(value, campo) {
    dispatch({
        type: TYPES.PESSOA,
        campo,
        value
    })
}
export function setModal(value, campo) {
    dispatch({
        type: TYPES.SET_MODAL,
        campo,
        value
    })
}
export function setPessoasModal(value) {
    dispatch({
        type: TYPES.SET_PESSOAS_MODAL,
        value
    })
}
export function setLoadingModal(value) {
    dispatch({
        type: TYPES.SET_LOADING_MODAL,
        value
    })
}