/* eslint-disable default-case */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect } from "react";

import { Search } from "./tabs/search";
import { Register } from "./tabs/register";
import { Edit } from "./tabs/edit";
import { Visualize } from "./tabs/visualize";

import { useSelector } from "react-redux";
import * as actions from "../../store/modules/layoutParametrizacao/actions";

function renderActiveTab(activeTab) {
  switch (activeTab) {
    case "Search":
      return <Search />;
    case "Register":
      return <Register />;
    case "Edit":
      return <Edit />;
    case "Visualize":
      return <Visualize />;
  }
}

const LayoutParametrizacao = () => {
  const { activeTab } = useSelector((state) => state.layoutParametrizacao);

  useEffect(() => {
    actions.setActiveTab("Search");
  }, []);

  return <>{renderActiveTab(activeTab)}</>;
};

export default LayoutParametrizacao;
