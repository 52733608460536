export const COLUMNS_PROPOSTAS = [
    {
        Header: "Status",
        accessor: "status"
    },
    {
        Header: "Adesão",
        accessor: "numeroProposta"
    },
    {
        Header: "Digitador",
        accessor: "nomeDigitador"
    },
    {
        Header: "Cliente",
        accessor: "nomeCliente"
    },
    {
        Header: "Data",
        accessor: "dataProposta"
    },
    {
        Header: "Convênio",
        accessor: "nomeConvenio"
    },
    {
        Header: "Produto",
        accessor: "nomeProduto"
    },
    {
        Header: "Valor",
        accessor: "valor"
    },
]