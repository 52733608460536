const STORE_ID = "CONVENIO";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    CONVENIO: "CONVENIO",
    CONVENIO_COPY: "CONVENIO_COPY",
    INVALID_CONVENIO: "INVALID_CONVENIO",
    CONVENIOS: "CONVENIOS",
    CLEAN_INVALID_CONVENIO: "CLEAN_INVALID_CONVENIO",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE"
}

export { STORE_ID, TYPES }