import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styles.css";
import { Row } from "reactstrap";
import { useSelector } from "react-redux";
/**
 *
 * @param ativeTab Register | Search
 * @returns JSX.Element
 */
const Breadcrumb = ({ activeTab }) => {
  const { routes } = useSelector(state => state.routes)

  const location = useLocation();

  const [breadcrumb, setBreadcrumb] = useState({
    tilte: "",
    icon: <></>,
    path: "",
  });

  useEffect(() => {
    getInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getInformation = () => {
    let simpleRoutes = [];
    routes.forEach((route) => {
      if (route.subNav) {
        route.subNav.forEach((subNav) => {
          simpleRoutes.push({
            title: subNav.title,
            icon: subNav.icon,
            path: subNav.path,
          });
        });
      } else {
        simpleRoutes.push({
          title: route.title,
          icon: route.icon,
          path: route.path,
        });
      }
    });
    let newBreadcrumb = simpleRoutes.find(
      (simple) => simple.path === location.pathname
    );
    setBreadcrumb(newBreadcrumb);
  };

  return (
    <>
      <Row style={{ background: 'var(--color-gradiente-verde-claro)', height: '60px', borderBottomRightRadius: '30px', marginBottom: '10px' }}>
        <div className="icons-breadcrumb">{breadcrumb && breadcrumb.icon}</div>
        <div className="text-breadcrumb">
          {breadcrumb && `/ ${breadcrumb.title}/`}
          {activeTab === "Register" && ` Cadastrar ${breadcrumb.title} `}
        </div>
      </Row>
    </>
  );
};

export default Breadcrumb;
