import { STORE_ID, TYPES } from './types'
import store from '../../../index'

function dispatch(data) {
  data = { ...data, storeId: STORE_ID }
  store.dispatch(data)
}

export function setAusencias(value) {

  dispatch({
    type: TYPES.SET_AUSENCIAS,
    value,
  })
}

export function setUsuariosBanco(value) {
  dispatch({
    type: TYPES.SET_USUARIOS_BANCO,
    value,
  })
}

export function setMatrizes(value) {
  dispatch({
    type: TYPES.SET_MATRIZES,
    value,
  })
}

export function setConvenios(value) {
  dispatch({
    type: TYPES.SET_CONVENIOS,
    value,
  })
}

export function setSeeRegister(value) {
  dispatch({
    type: TYPES.SET_SEE,
    value,
  })
}

export function setBancos(value) {
  dispatch({
    type: TYPES.SET_BANCOS,
    value,
  })
}

export function setFuncionarioLoja(value) {
  dispatch({
    type: TYPES.SET_FUNCIONARIO_LOJA,
    value,
  })
}

export function setTipoFuncionario(value) {
  dispatch({
    type: TYPES.SET_TIPOS_FUNCIONARIOS,
    value,
  })
}

export function setCentroCusto(value) {
  dispatch({
    type: TYPES.SET_CENTRO_CUSTO,
    value,
  })
}

export function setCentroCustoV(value) {
  dispatch({
    type: TYPES.SET_CENTRO_CUSTOV,
    value,
  })
}

export function setInstituicoesFinanceiras(value) {
  dispatch({
    type: TYPES.SET_INSTITUICOES_FINANCEIRAS,
    value,
  })
}

export function setLojas(value) {
  dispatch({
    type: TYPES.SET_LOJAS,
    value,
  })
}

export function setRegimesTrib(value) {
  dispatch({
    type: TYPES.SET_REGIMES_TRIB,
    value,
  })
}

export function clienteCpf(value) {
  dispatch({
    type: TYPES.SET_CLIENTE_CPF,
    value,
  })
}

export function funcionario(value) {
  dispatch({
    type: TYPES.FUNCIONARIO,
    value,
  })
}

export function contaCorrente(value) {
  dispatch({
    type: TYPES.CONTA_CORRENTE,
    value,
  })
}

export function userInstituicao(value) {
  dispatch({
    type: TYPES.USER_INSTITUICAO,
    value,
  })
}

export function clienteCnpj(value) {
  dispatch({
    type: TYPES.CLIENTE_CNPJ,
    value,
  })
}

export function tipoLoja(value) {
  dispatch({
    type: TYPES.TIPO_LOJA,
    value,
  })
}

export function tiposLoja(value) {
  dispatch({
    type: TYPES.SET_TIPOS_LOJA,
    value,
  })
}

export function setLojasBanco(value) {
  dispatch({
    type: TYPES.SET_LOJAS_BANCO,
    value,
  })
}

export function setPersonas(value) {
  dispatch({
    type: TYPES.SET_PERSONAS,
    value,
  })
}

export function setInstituicaoFinanceira(value) {
  dispatch({
    type: TYPES.SET_INSTITUICAO_FINANCEIRA_JURIDICA,
    value,
  })
}

export function setIsClienteCpfActive(value, field) {
  dispatch({
    type: TYPES.SET_CLIENTE_ACTIVE,
    value,
    field,
  })
}
export function setActiveTabCpf(value) {
  dispatch({
    type: TYPES.SET_ACTIVE_TAB_CPF,
    value,
  })
}
export function setIsClienteCnpjActive(value, field) {
  dispatch({
    type: TYPES.SET_CLIENTE_CNPJ_ACTIVE,
    value,
    field,
  })
}

export function cleanRegister() {
  dispatch({
    type: TYPES.CLEAN_REGISTER,
  })
}

export function cleanPersonas() {
  dispatch({
    type: TYPES.CLEAN_PERSONAS
  });
}
export function cleanMatricula() {
  dispatch({
    type: TYPES.CLEAN_MATRICULA,
  })
}
export function cleanInstituicaoFinanceira() {
  dispatch({
    type: TYPES.CLEAN_INSTITUICAO_FINANCEIRA,
  })
}
export function setMatriculasSelected(value) {
  dispatch({
    type: TYPES.SET_MATRICULAS_SELECTED,
    value,
  })
}

export function setConveniosSelected(value) {
  dispatch({
    type: TYPES.SET_CONVENIOS_SELECTED,
    value,
  })
}

export function setCanais(value) {
  dispatch({
    type: TYPES.SET_CANAIS,
    value,
  })
}

export function tiposParcerias(value) {
  dispatch({
    type: TYPES.TIPOS_PARCERIAS,
    value,
  })
}

export function niveisHierarquicos(value) {
  dispatch({
    type: TYPES.SET_NIVEIS_HIERARQUICOS,
    value,
  })
}

export function setCentrosSelected(value) {
  dispatch({
    type: TYPES.SET_CENTROS_SELECTED,
    value,
  })
}

export function usuarioFuncionario(value) {
  dispatch({
    type: TYPES.SET_USUARIO_FUNCIONARIO,
    value,
  })
}

export function setMasters(value) {
  dispatch({
    type: TYPES.SET_MASTERS,
    value,
  })
}

export function setInvalid(field) {
  dispatch({
    type: TYPES.SET_INVALID,
    field,
  })
}

export function cleanInvalid(field) {
  dispatch({
    type: TYPES.CLEAN_INVALID,
    field,
  })
}
export function setPerfilUsuarios(value) {
  dispatch({
    type: TYPES.SET_PERFIL_USUARIOS,
    value,
  })
}

export function setProfissoes(value) {
  dispatch({
    type: TYPES.SET_PROFISSOES,
    value,
  })
}

export function setInstituicao(campo, value) {
  dispatch({
    type: TYPES.SET_INSTITUICAO,
    campo,
    value,
  })
}

export function setMatricula(value) {
  dispatch({
    type: TYPES.SET_MATRICULA,
    value,
  })
}

export function setIsEditingMatricula(value) {
  dispatch({
    type: TYPES.SET_IS_EDITING,
    value,
  })
}

export function setCentrocusto(value) {
  dispatch({
    type: TYPES.SET_CENTROCUSTO,
    value,
  })
}

export function setLojaBanco(field, value) {
  dispatch({
    type: TYPES.SET_LOJA_BANCO,
    field,
    value,
  })
}

export function lojaBanco(value) {
  dispatch({
    type: TYPES.LOJA_BANCO,
    value,
  })
}

export function cleanLojaBanco() {
  dispatch({
    type: TYPES.CLEAN_LOJA_BANCO,
  })
}
export function setPessoa(field, value) {
  dispatch({
    type: TYPES.SET_PESSOA,
    field,
    value
  })
}

export function setFilterPessoa(field, value) {
  dispatch({
    type: TYPES.SET_FILTER_PESSOA,
    field,
    value
  })
}

export function clearFilterPessoa() {
  dispatch({
    type: TYPES.CLEAR_FILTER_PESSOA,
  })
}

export function setFilterPessoaApplied(value) {
  dispatch({
    type: TYPES.SET_FILTER_PESSOA_APPLIED,
    value
  })
}

export function clearFilterPessoaApplied() {
  dispatch({
    type: TYPES.CLEAR_FILTER_PESSOA_APPLIED,
  })
}

export function pessoa(value) {
  dispatch({
    type: TYPES.PESSOA,
    value
  })
}

export function setInstituicaoBanco(field, value) {
  dispatch({
    type: TYPES.SET_INSTITUICAO_BANCO,
    field,
    value,
  })
}

export function setFonecedor(value) {
  dispatch({
    type: TYPES.SET_FORNECEDOR,
    value,
  })
}

export function cleanFonecedor(value) {
  dispatch({
    type: TYPES.CLEAN_FORNECEDOR,
    value,
  })
}
export async function changePapeis(value) {
  dispatch({
    type: TYPES.CHANGE_TAB,
    value,
  })
}

export async function cleanChangePapeis() {
  dispatch({
    type: TYPES.CLEAN_CHANGE_TAB,
  })
}

export function cleanInstituicao() {
  dispatch({
    type: TYPES.CLEAN_INSTITUICAO,
  })
}

export function cleanHeadcountOrcado() {
  dispatch({
    type: TYPES.CLEAN_HEADCOUNT_ORCADO,
  })
}

export function cleanPersona() {
  dispatch({
    type: TYPES.CLEAN_PERSONA,
  })
}

export function setInstituicoesBancarias(value) {
  dispatch({
    type: TYPES.SET_INSTITUICOES_BANCARIAS,
    value
  })
}

export function cleanClienteCnpjRelacionamento() {
  dispatch({
    type: TYPES.CLEAN_CLIENTE_CNPJ_RELACIONAMENTO
  })
}

export function cleanLojaRelacionamento() {
  dispatch({
    type: TYPES.CLEAN_LOJA_RELACIONAMENTO
  })
}

export function cleanInstituicaoRelacionamento() {
  dispatch({
    type: TYPES.CLEAN_INSTITUICAO_RELACIONAMENTO
  })
}

export function cleanFornecedorRelacionamento() {
  dispatch({
    type: TYPES.CLEAN_FORNECEDOR_RELACIONAMENTO
  })
}

export function cleanClienteCpfRelacionamento() {
  dispatch({
    type: TYPES.CLEAN_CLIENTE_CPF_RELACIONAMENTO
  })
}

export function cleanFuncionarioRelacionamento() {
  dispatch({
    type: TYPES.CLEAN_FUNCIONARIO_RELACIONAMENTO
  })
}

export function cleanUserInstituicaoRelacionamento() {
  dispatch({
    type: TYPES.CLEAN_USER_INSTITUICAO_RELACIONAMENTO
  })
}

export function setMatriculasSelectBox(value) {
  dispatch({
    type: TYPES.SET_MATRICULAS_SELECT_BOX,
    value
  })
}

export function setAlteraHierarquia(value, field) {
  dispatch({
    type: TYPES.SET_ALTERA_HIERARQUIA,
    field,
    value
  })
}

export function cleanAlteraHierarquia() {
  dispatch({
    type: TYPES.CLEAN_ALTERAR_HIERARQUIA
  })
}
