import { STORE_ID, TYPES } from "./types";
import store from "../../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function cleanDocumento() {
    dispatch({
        type: TYPES.CLEAN_DOCUMENT
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function getItemsTiposDocumentos(value) {
    dispatch({
        type: TYPES.GET_TIPOS_DOCUMENTO,
        value
    })
}

export function setDocumentos(value) {
    dispatch({
        type: TYPES.DOCUMENTOS,
        value
    })
}
export function setDocumento(value) {
    dispatch({
        type: TYPES.SETDOCUMENTO,
        value
    })
}