const STORE_ID = "DASHBOARD_RECEITA";

const TYPES = {
    SET_FILTROS: "SET_FILTROS",
    SET_BIG_NUMBERS: "SET_BIG_NUMBERS",
    SET_TABELA_PRODUCAO: "SET_TABELA_PRODUCAO",
    SET_TABELA_PROD: "SET_TABELA_PROD",
    SET_GRAFICO_PRODUTO: "SET_GRAFICO_PRODUTO",
    SET_GRAFICO_PROJETADO: "SET_GRAFICO_PROJETADO",
    SET_INSTITUICOES: "SET_INSTITUICOES",
    SET_LOJAS: "SET_LOJAS",
    SET_MODAL_LOJAS: "SET_MODAL_LOJAS",
    SET_GET_ALL_LOJAS: "SET_GET_ALL_LOJAS",
    SET_MODAL_CONSULTORES: "SET_MODAL_CONSULTORES",
    SET_GET_ALL_CONSULTORES: "SET_GET_ALL_CONSULTORES",
    SET_TIPOS_OPERACAO: "SET_TIPOS_OPERACAO",
    SET_PARCERIAS: "SET_PARCERIAS",
    SET_CONVENIOS: "SET_CONVENIOS",
    SET_GRUPOS: "SET_ACTIVE_TAB",
    SET_CONSULTORES: "SET_CONSULTORES",
    SET_FILTROS_SELECIONADOS: "SET_FILTROS_SELECIONADOS",
    CLEAN_FILTROS: "CLEAN_FILTROS",
    SET_LOADING_LOJAS: "SET_LOADING_LOJAS",
    SET_LOADING_CONSULTORES: "SET_LOADING_CONSULTORES"
}

export { STORE_ID, TYPES }