import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import {
    Card,
    Col,
    Form,
    Row,
    Button,
    TabContent,
    TabPane
} from "reactstrap";

import * as tipoParceriaController from "../../controllers/tipoParceriaController";
import * as actions from "../../store/modules/tipoParceria/actions";
import Search from './tabs/search'
import Register from './tabs/register'
import history from "../../util/history";
import Breadcrumb from "../../components/breadcrumbs";

const TipoParceria = () => {

    const { item, copy, activeTab, seeRegister, activePersona, permitions } = useSelector(state => state.tipoParceria);

    useEffect(() => {
        actions.setActiveTab("Search")
    }, [])

    function changeTab(tab) {
        actions.setActiveTab(tab);
        actions.cleanRegister();
    }
    function saveItem() {
        if (item.id === "") {
            tipoParceriaController.create(item);
        } else {
            tipoParceriaController.update(item, copy);
        }
    }

    function seeRegisterF() {
        if (item.id !== "") {
            actions.cleanRegister();
        }
        actions.setSeeRegister(false);
    }

    function changeView() {
        history.push("/personas");
        actions.setActivePersona(false);
        actions.setActiveTab("Search");
    }

    return (
        <>
                <Col>
                    <Breadcrumb activeTab={activeTab} />
                    <Card>
                        <Form>
                            {
                                activeTab === "Search"
                                    ?
                                    permitions.criar && (
                                        <Col md={12}>
                                            <Row className="pt-2 pb-2">
                                                <Col md={8}>
                                                    <Button
                                                        onClick={() => changeTab('Register')}
                                                        style={{ width: "210px" }}
                                                        className='purpleButton'
                                                    >
                                                        Cadastrar Tipo de Parceria
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                    :
                                    <Col className="pt-2 pb-2">
                                        {
                                            !activePersona ?
                                                <Button
                                                    onClick={() => { changeTab('Search'); seeRegisterF() }}
                                                    style={{ width: "150px", color: "white" }}
                                                    className='purpleButton'>
                                                    <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar
                                        </Button> :
                                                <Button
                                                    onClick={() => changeView()}
                                                    style={{ width: "200px", color: "white" }}
                                                    className='purpleButton'>
                                                    <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar para Personas
                                        </Button>
                                        }
                                        {!seeRegister && <Button
                                            onClick={() => saveItem()}
                                            style={{ width: "242px", float: 'right' }}
                                            className="purpleButton"
                                        >
                                            Salvar Cadastro
                                                        </Button>
                                        }
                                    </Col>
                            }
                        </Form>
                    </Card>
                </Col>

                <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <br />
                        <Search />
                    </TabPane>
                    <TabPane tabId="Register">
                        <br />
                        <Register />
                    </TabPane>
                </TabContent>
        </>
    );
}

export default TipoParceria;