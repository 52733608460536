import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as actions from "../store/modules/tipoFormalizacaoRibercred/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get(`TipoFormalizacao`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    actions.getTipos(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`TipoFormalizacao/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    actions.copy(data.content);
    actions.setItem('descricao', data.content.descricao);
    actions.setItem('id', data.content.id);
}

export async function create(item) {

    if (item.descricao.trim() === "") {
        actions.setInvalid("descricao");
        notifyWarn("Descrição não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao,
    }

    setLoading(true)
    const data = await api.post(`TipoFormalizacao`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Tipo de Formalização ribercred cadastrado");
    actions.cleanRegister();
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`TipoFormalizacao/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Tipo de formalização ribercred removido");
    get();
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (item.descricao.trim() === "") {
        actions.setInvalid("descricao");
        notifyWarn("Descrição não pode estar em branco");
        return;
    }

    const body = {
        id: item.id,
        descricao: item.descricao
    }

    setLoading(true)
    const data = await api.put(`TipoFormalizacao/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Tipo de formalização ribercred atualizado");
    actions.cleanRegister();
    actions.setActiveTab("Search");
    get();
}