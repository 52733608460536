import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    item: {
        descricao: "",
        id: "",
        headCount: false,
        canalId: '0',
        multiplosCentroCusto: false,
        consultor: false
    },
    copy: {},
    invalid: {
        descricao: false,
    },
    canaisVendas: [],
    niveisHierarquicos: [],
    items: [],
    activeTab: "Search",
    seeRegister: false,
    permitions: {
        criar: false,
        deletar: false,
    }
}

export default function hierarquia(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.SET_NIVEIS_HIERARQUICOS:
                    return { ...state, niveisHierarquicos: action.value }
                case TYPES.HIERARQUIAS:
                    return { ...state, items: action.value }
                case TYPES.HIERARQUIA:
                    return { ...state, item: action.value }
                case TYPES.SET_CANAIS_VENDA:
                    return { ...state, canaisVendas: action.value }
                case TYPES.INVALID_HIERARQUIA:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_HIERARQUIA:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.HIERARQUIA_COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, item: INITIAL_STATE.item, invalid: INITIAL_STATE.invalid }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}
