import moment from 'moment';
export const COLUMNS = [
    {
        Header: 'Título',
        accessor: 'titulo'
    },{
        Header:'Documento',
        accessor: 'documento'

    },
    {
        Header: 'Status',
        accessor: 'statusComissaoRecebida'
    },
    {
        Header: 'Produto',
        accessor: 'produto'
    },
    {
        Header: 'Data Emissão',
        accessor: 'dataEmissao',
        Cell: ({ value }) => {
            return moment(value).format("DD/MM/YYYY");
        },
        sortType: "datetime",
    },
    {
        Header: 'Data Vencimento',
        accessor: 'dataVencimento',
        Cell: ({ value }) => {
            return moment(value).format("DD/MM/YYYY");
        },
        sortType: "datetime",
    },
    {
        Header: 'Banco',
        accessor: 'banco'
    },
    {
        Header: 'Conta',
        accessor: 'contaCorrente'
    },
    {
        Header: 'Tipo Comissão',
        accessor: 'tipoComissao'
    },
    {
        Header: 'Valor Bruto',
        accessor: 'valorBruto'
    },
    {
        Header: 'Valor IR',
        accessor: 'valorIr'
    },
    {
        Header: 'Valor Liquido',
        accessor: 'valorLiquido'
    },
    {
        Header: 'Cód. Loja Banco',
        accessor: 'codigoLojaBanco'
    },
    {
        Header: 'Loja',
        accessor: 'loja'
    },
    {
        Header: 'Conta Espelho',
        accessor: 'contaEspelho'
    },
    {
        Header: 'Arquivo',
        accessor: 'nomeArquivo'
    }
]

export const COLUMNS_DETALHAMENTO = [
    {
        Header: 'Título',
        accessor: 'titulo'
    },
    {
        Header: 'Status',
        accessor: 'statusComissaoRecebida'
    },
    {
        Header: 'Centro de Custo',
        accessor: 'centroCusto'
    },
    {
        Header: "Produto",
        accessor: "produto"
    },
    {
        Header: 'Data Emissão',
        accessor: 'dataEmissao',
        Cell: ({ value }) => {
            return moment(value).format("DD/MM/YYYY");
        },
        sortType: "datetime",
    },
    {
        Header: 'Data Vencimento',
        accessor: 'dataVencimento',
        Cell: ({ value }) => {
            return moment(value).format("DD/MM/YYYY");
        },
        sortType: "datetime",
    },
    {
        Header: 'Banco',
        accessor: 'banco'
    },
    {
        Header: 'Conta',
        accessor: 'contaCorrente'
    },
    {
        Header: 'Tipo Comissão',
        accessor: 'tipoComissao'
    },
    {
        Header: 'Valor Bruto',
        accessor: 'valorBruto'
    },
    {
        Header: 'Valor IR',
        accessor: 'valorIr'
    },
    {
        Header: 'Valor Liquido',
        accessor: 'valorLiquido'
    },
    {
        Header: 'Cód. Loja Banco',
        accessor: 'codigoLojaBanco'
    },
    {
        Header: 'Loja',
        accessor: 'loja'
    },
    {
        Header: 'Conta Espelho',
        accessor: 'contaEspelho'
    },
    {
        Header: 'Arquivo',
        accessor: 'nomeArquivo'
    }
]

export const COLUMNS_LOG = [
    {
        Header: 'Data Cricação Log',
        accessor: 'dataCriacao'
    },
    {
        Header: 'Título',
        accessor: 'titulo'
    },
    {
        Header: 'Descrição',
        accessor: 'descricao'
    }
]
