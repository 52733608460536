import { Col, Label, Row, Input } from "reactstrap";
import { InputAdornment, OutlinedInput } from "@material-ui/core";

import * as BiIcons from "react-icons/bi";
import { RiCloseFill } from "react-icons/ri";

import { useSelector } from "react-redux";

import * as actions from "../../../../store/modules/perfilAcesso/actions";
import * as controller from "../../../../controllers/perfilAcessoController";

import LoadingIcon from "../../../../components/Loading/LoadingIcon";
import '../styles.css';

export function LojasAssociadasList() {
  const {
    lojasDisponiveis,
    lojasAssociadas,
    lojasAssociadasSelecionadas,
    lojasAssociadasSelectedSearchResults,
    perfilDeAcessoBeingEditedId,
    lojasDisponiveisInitialValueBackup,
    loadingLojas,
    isInEditMode,
    activeTab,
    lojasAssociadasSearchResults,
    lojasAssociadasSearchTerm,
  } = useSelector((state) => state.perfilAcesso);

  const lojas =
    lojasAssociadasSearchTerm.length > 0
      ? lojasAssociadasSearchResults
      : lojasAssociadas;

  const isAllSearchResultsSelected =
    lojasAssociadasSearchResults.length > 0 &&
    lojasAssociadasSearchResults.length ===
      lojasAssociadasSelectedSearchResults.length;

  const isAllSelected =
    lojasAssociadasSearchTerm.length > 0
      ? isAllSearchResultsSelected
      : lojasAssociadasSelecionadas.length > 0 &&
        lojasAssociadasSelecionadas.length === lojasAssociadas.length;

  const isLojasAssociadasEmpty = lojasAssociadas.length === 0;

  const isSelectAllVisible =
    lojasAssociadasSearchTerm.length > 0
      ? lojasAssociadasSearchResults.length > 0
      : !(isLojasAssociadasEmpty || activeTab === "Visualize");

  function handleSearchLojasAssociadas(inputValue) {
    actions.setLojasAssociadasSearchTerm(inputValue);

    if (inputValue.trim() === "") {
      actions.setLojasAssociadasSearchTerm("");
      actions.setLojasAssociadasSearchResults([]);
      return;
    }

    const searchTermLowercase = inputValue.toLowerCase();
    const lojasFiltradas = lojasAssociadas.filter((loja) =>
      loja.nome.toLowerCase().includes(searchTermLowercase)
    );

    actions.setLojasAssociadasSearchResults(lojasFiltradas);

    actions.setLojasAssociadasSelectedSearchResults(
      lojasFiltradas.filter((lojaAssociada) =>
        lojasAssociadasSelecionadas.some(
          (lojaSelecionada) => lojaSelecionada.id === lojaAssociada.id
        )
      )
    );
  }

  async function handleRemoveLojasFromAssociatedList(lojaId) {
    let isSuccessed = true;

    if (isInEditMode) {
      const hasSuccessed = await controller.removeLojaAssociada({
        perfilDeAcessoId: perfilDeAcessoBeingEditedId,
        lojaId: lojaId,
      });

      isSuccessed = hasSuccessed;
    }

    if (!isSuccessed) {
      return;
    }

    if (lojasAssociadasSearchResults.length === 1) {
      // clear lojas associadas search input text
      actions.setLojasAssociadasSearchTerm("");
    }

    if (
      lojasAssociadasSearchResults.length > 0 &&
      lojasAssociadasSearchResults.length !== 1
    ) {
      const newLojasAssociadasSearchResults =
        lojasAssociadasSearchResults.filter((loja) => loja.id !== lojaId);
      actions.setLojasAssociadasSearchResults(newLojasAssociadasSearchResults);
    }

    const newLojasAssociadas = lojasAssociadas.filter(
      (loja) => loja.id !== lojaId
    );

    const removedItem = lojasDisponiveisInitialValueBackup.find(
      (loja) => loja.id === lojaId
    );

    actions.setLojasAssociadas(newLojasAssociadas);
    if (removedItem !== undefined) {
      actions.setLojasDisponiveis([removedItem, ...lojasDisponiveis]);
    }

    actions.setLojasAssociadasSelecionadas(
      lojasAssociadasSelecionadas.filter(
        (lojaSelecionada) => lojaSelecionada.id !== lojaId
      )
    );
    actions.setLojasAssociadasSelectedSearchResults(
      lojasAssociadasSelectedSearchResults.filter(
        (lojaSelecionada) => lojaSelecionada.id !== lojaId
      )
    );
    actions.setLojasDisponiveisSearchResults([]);
    actions.setLojasDisponiveisSelectedSearchResults([]);
    actions.setLojasDisponiveisSearchTerm("");
  }

  function handleSelectAllLojasAssociadas() {
    if (lojasAssociadasSearchTerm.length > 0) {
      const searchResultsSelected = lojasAssociadasSearchResults.filter(
        (lojaAssociada) =>
          lojasAssociadasSelecionadas.some(
            (lojaAssociadaSelecionada) =>
              lojaAssociadaSelecionada.id === lojaAssociada.id
          )
      );

      const isAllSearchResultsSelectedd =
        searchResultsSelected.length > 0 &&
        searchResultsSelected.length === lojasAssociadasSearchResults.length;

      const lojasAssociadasSelecionadasWithoutSelectedSearchResultsItems =
        lojasAssociadasSelecionadas.filter(
          (loja) =>
            !searchResultsSelected.some(
              (lojaAssociadaSelecionada) =>
                lojaAssociadaSelecionada.id === loja.id
            )
        );

      if (isAllSearchResultsSelectedd) {
        actions.setLojasAssociadasSelecionadas(
          lojasAssociadasSelecionadasWithoutSelectedSearchResultsItems
        );

        actions.setLojasAssociadasSelectedSearchResults([]);
      } else {
        actions.setLojasAssociadasSelecionadas([
          ...lojasAssociadasSelecionadasWithoutSelectedSearchResultsItems,
          ...lojasAssociadasSearchResults,
        ]);

        actions.setLojasAssociadasSelectedSearchResults(
          lojasAssociadasSearchResults
        );
      }

      return;
    }

    if (isAllSelected) {
      actions.setLojasAssociadasSelecionadas([]);
    } else {
      actions.setLojasAssociadasSelecionadas([...lojasAssociadas]);
    }
  }

  function handleSelecionarLojaAssociada(lojaId) {
    const lojaSelecionada = lojasAssociadas.find((loja) => loja.id === lojaId);
    if (lojaSelecionada !== undefined) {
      actions.setLojasAssociadasSelecionadas([
        ...lojasAssociadasSelecionadas,
        lojaSelecionada,
      ]);
    }

    if (lojasAssociadasSearchTerm.length > 0) {
      actions.setLojasAssociadasSelectedSearchResults([
        ...lojasAssociadasSelectedSearchResults,
        lojaSelecionada,
      ]);
    }
  }

  function handleDeselecionarLojaAssociada(lojaId) {
    actions.setLojasAssociadasSelecionadas(
      [...lojasAssociadasSelecionadas].filter((loja) => loja.id !== lojaId)
    );

    if (lojasAssociadasSearchTerm.length > 0) {
      actions.setLojasAssociadasSelectedSearchResults(
        [...lojasAssociadasSelectedSearchResults].filter(
          (loja) => loja.id !== lojaId
        )
      );
    }
  }

  function checkIfLojaAssociadaIsSelecionada(lojaId) {
    const isLojaSelecionada = lojasAssociadasSelecionadas.some(
      (loja) => loja.id === lojaId
    );

    return isLojaSelecionada;
  }

  return (
    <Col md={5}>
      <Label
        style={{
          fontWeight: "700",
          color: "var(--color-preto)",
          fontSize: "17px",
        }}
      >
        Lojas Associadas
      </Label>
      <OutlinedInput
        className="input-default mb-2"
        placeholder="Procurar"
        value={lojasAssociadasSearchTerm}
        onChange={(e) => handleSearchLojasAssociadas(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <BiIcons.BiSearchAlt />
          </InputAdornment>
        }
      />
      <div
        style={{
          border: "1px solid #B5B5B5",
          borderRadius: "10px",
          height: "390px",
          overflow: "auto",
        }}
      >
        {isSelectAllVisible ? (
          <Row
            style={{
              flexWrap: "nowrap",
              margin: "15px 15px 0 15px",
              height: "35px",
              borderBottom: "1px solid #B5B5B5",
            }}
          >
            <Col md={1}>
              <Input
                type="checkbox"
                style={{display: 'flex'}}
                checked={isAllSelected}
                onChange={() => handleSelectAllLojasAssociadas()}
              />
            </Col>
            <Col>
              <p
                style={{
                  whiteSpace: "nowrap",
                  color: "var(--color-preto)",
                }}
              >
                Selecionar todos
              </p>
            </Col>
          </Row>
        ) : null}
        {loadingLojas && <LoadingIcon color="var(--color-roxo)" />}
        {lojas
          .sort((a, b) => a.nome.localeCompare(b.nome))
          .map((loja, i) => {
            return (
              <Row
                key={loja.id}
                style={{
                  flexWrap: "nowrap",
                  margin: "15px 15px 0 15px",
                  height: "35px",
                  borderBottom: "1px solid #B5B5B5",
                }}
              >
                {!(activeTab === "Visualize") && (
                  <Col md={1}>
                    <Input
                      key={loja.id}
                      type="checkbox"
                      style={{display: 'flex'}}
                      checked={checkIfLojaAssociadaIsSelecionada(loja.id)}
                      onChange={() => {
                        checkIfLojaAssociadaIsSelecionada(loja.id)
                          ? handleDeselecionarLojaAssociada(loja.id)
                          : handleSelecionarLojaAssociada(loja.id);
                      }}
                    />
                  </Col>
                )}
                <Col>
                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        color: "var(--color-preto)",
                      }}
                    >
                      {loja.nome}
                    </p>
                  </div>
                </Col>
                {!(activeTab === "Visualize") && (
                  <Col md={1}>
                    <RiCloseFill
                      onClick={() =>
                        handleRemoveLojasFromAssociatedList(loja.id)
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                )}
              </Row>
            );
          })}
      </div>
    </Col>
  );
}
