import React, { useEffect } from "react";

import {
  Card,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
  Button,
} from "reactstrap";

import { IoIosArrowBack } from "react-icons/io";

import { useSelector } from "react-redux";
import * as actions from "../../../store/modules/perfilAcesso/actions";
import * as controller from "../../../controllers/perfilAcessoController";

import AssociacaoLojas from "../components/AssociacaoLojas";
import { Switch } from "../../../components/Inputs/Input";
import AssociacaoTelas from "../components/AssociacaoTelas";
import Breadcrumb from "../../../components/breadcrumbs";
import { removeAccentsAndUppercase } from "../../../util/masks";

export function Register() {
  const { perfilDeAcessoBeingCreatedData, activeTab } = useSelector(
    (state) => state.perfilAcesso
  );

  async function saveItem() {
    const isSuccessed = await controller.createPerfilDeAcesso(
      perfilDeAcessoBeingCreatedData
    );

    if (isSuccessed) {
      actions.setActiveTab("Search");
      const data = await controller.getPerfisDeAcesso();
      actions.setPerfisDeAcesso(data);
    }
  }

  function changeTab(tab) {
    actions.setActiveTab(tab);
  }

  function handleVoltar() {
    changeTab("Search");
  }

  useEffect(() => {
    async function fetchLojasAndTelas() {
      const lojasDisponiveis = await controller.getLojas();
      const telasDisponiveis = await controller.getTelas();

      actions.setLojasDisponiveisInitialValueBackup(lojasDisponiveis);
      actions.setLojasDisponiveis(lojasDisponiveis);
      actions.setTelasDisponiveisInitialValueBackup(telasDisponiveis);
      actions.setTelasDisponiveis(telasDisponiveis);
    }

    // clear previous data
    actions.setTelasAssociadas([]);
    actions.setLojasAssociadas([]);
    actions.setTelasDisponiveis([]);
    actions.setLojasDisponiveis([]);
    actions.setTelasSelecionadas([]);
    actions.setLojasSelecionadas([]);
    actions.clearPerfilDeAcessoBeingCreatedData();
    actions.setTelasAssociadasSearchTerm("");
    actions.setTelasDisponiveisSearchTerm("");
    actions.setLojasAssociadasSearchTerm("");
    actions.setLojasDisponiveisSearchTerm("");

    fetchLojasAndTelas();
  }, []);

  return (
    <>
      <Col>
        <Breadcrumb activeTab={activeTab} />
        <Card>
          <Form>
            <Col className="pt-2 pb-2">
              <Button
                onClick={() => {
                  handleVoltar();
                }}
                style={{ width: "150px", color: "white" }}
                className="purpleButton"
              >
                <IoIosArrowBack style={{ marginTop: "3px", float: "left" }} />{" "}
                Voltar
              </Button>
              <Button
                onClick={() => saveItem()}
                style={{ width: "242px", float: "right" }}
                className="purpleButton"
              >
                Salvar Cadastro
              </Button>
            </Col>
          </Form>
        </Card>
      </Col>
      <Col>
        <Card className="radius pb-5">
          <Col className="pt-2 m-4">
            <p style={{ fontSize: "18px", color: "var(--color-preto)" }}>
              Preencha os campos para cadastrar um novo Perfil de acesso
            </p>
          </Col>
          <Col md={12} className="pt-2">
            <Form className="m-4">
              <div
                style={{ padding: "20px", borderBottom: "1px solid #D5D5D5" }}
              >
                <Row>
                  <Col md={5}>
                    <FormGroup>
                      <Label
                        style={{
                          fontWeight: "700",
                          color: "var(--color-preto)",
                        }}
                      >
                        Descrição
                      </Label>
                      <Input
                        type="text"
                        placeholder="Digite aqui..."
                        value={perfilDeAcessoBeingCreatedData.descricao}
                        onChange={(e) =>
                          actions.setPerfilDeAcessoBeingCreatedData({
                            campo: "descricao",
                            value: removeAccentsAndUppercase(e.target.value),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div
                style={{ padding: "20px", borderBottom: "1px solid #D5D5D5" }}
              >
                <Row>
                  <Col md={6}>
                    <Switch
                      componentLeft="Este perfil irá utilizar a hierarquia como filtro de visualização?"
                      className="switch mt-4"
                      checked={perfilDeAcessoBeingCreatedData.isHierarquia}
                      onChange={() =>
                        actions.setPerfilDeAcessoBeingCreatedData({
                          campo: "isHierarquia",
                          value: !perfilDeAcessoBeingCreatedData.isHierarquia,
                        })
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <Switch
                      componentLeft="Exibir CPF"
                      className="switch mt-4"
                      checked={perfilDeAcessoBeingCreatedData.isAdministrativo}
                      onChange={() =>
                        actions.setPerfilDeAcessoBeingCreatedData({
                          campo: "isAdministrativo",
                          value:
                            !perfilDeAcessoBeingCreatedData.isAdministrativo,
                        })
                      }
                    />
                  </Col>
                </Row>
              </div>
              <div
                style={{ padding: "20px", borderBottom: "1px solid #D5D5D5" }}
              >
                <AssociacaoTelas />
              </div>
              {!perfilDeAcessoBeingCreatedData.isHierarquia && (
                <div
                  style={{ padding: "20px", borderBottom: "1px solid #D5D5D5" }}
                >
                  <AssociacaoLojas />
                </div>
              )}
            </Form>
          </Col>
        </Card>
      </Col>
    </>
  );
}
