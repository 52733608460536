import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BsTable } from "react-icons/bs";
import { AiOutlineTags } from "react-icons/ai";
import { VscMegaphone } from "react-icons/vsc";
import { BiShoppingBag } from "react-icons/bi";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Col } from 'reactstrap';

import classnames from 'classnames';
import Breadcrumb from "../../components/breadcrumbs";

import TabelaComissao from "./TabelaComissao";
import Produto from "./Produto";
import TipoOperacao from "./TipoOperacao";
import Convenio from "./Convenio";
import TipoComissao from "./TipoComissao";

import * as actions from '../../store/modules/inconsistenciaComissao/actions';
import * as controller from '../../controllers/inconsistenciaComissaoController'

const IncosistenciaComissao = () => {
    const { tab, tabs} = useSelector(state => state.inconsistenciaComissao);

    useEffect(()=>{
        controller.getQuantidade(tabs)
        controller.getQuantidadeComissao(tabs)
        if(tab.tab === '1'){
            controller.getInconsistencias(tab)
        }else{
            controller.getInconsistenciasComissao(tab)
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function changeTab(tab){
        actions.cleanTabs()
        actions.setTab(tab)
        if(tab.tab === '1'){
            await controller.getInconsistencias(tab)
        }else{
            await controller.getInconsistenciasComissao(tab)
        }
        
    }
    return (
        <Col>
         <Breadcrumb activeTab={"Search"} />
            <Card className="pt-3 pb-2 mb-5">
                <div>
                    <Nav style={{ marginLeft: '25px', marginBottom: '10px', width: '95%' }}>
                        <NavItem style={{ padding: '0 5px 0 5px' }}>
                            <NavLink
                                className={classnames({ active: tab.tab === '1' })}
                                onClick={() => { changeTab({tab: '1', id: tabs[0].id}); }}
                                style={
                                    tab.tab === '1' ?
                                        { color: 'var(--color-preto)', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s', cursor: 'pointer' }
                                        : { color: 'var(--color-preto)', fontWeight: '700', cursor: 'pointer' }}
                            >
                               <BsTable style={{fontSize: '18px', paddingBottom: '2px', marginRight: '5px'}}/> 
                               {`Tabela de Comissão (${tabs[0].quantidade})`}
                            </NavLink>     
                        </NavItem>
                        <NavItem style={{ padding: '0 5px 0 5px' }}>
                            <NavLink
                                className={classnames({ active: tab.tab === '2' })}
                                onClick={() => { changeTab({tab: '2', id: tabs[1].id}); }}
                                style={
                                    tab.tab === '2' ?
                                        { color: 'var(--color-preto)', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s', cursor: 'pointer' }
                                        : { color: 'var(--color-preto)', fontWeight: '700', cursor: 'pointer' }}
                            >
                            <BiShoppingBag style={{fontSize: '18px', paddingBottom: '2px', marginRight: '5px'}}/>
                            {`Produto (${tabs[1].quantidade})`}
                            </NavLink>
                        </NavItem>           
                        <NavItem style={{ padding: '0 5px 0 5px' }}>
                            <NavLink
                                className={classnames({ active: tab.tab === '3' })}
                                onClick={() => { changeTab({tab: '3', id: tabs[2].id}); }}
                                style={
                                    tab.tab === '3' ?
                                        { color: 'var(--color-preto)', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s', cursor: 'pointer' }
                                        : { color: 'var(--color-preto)', fontWeight: '700', cursor: 'pointer' }}
                            >
                            <VscMegaphone style={{fontSize: '18px', paddingBottom: '2px', marginRight: '5px'}}/>  
                            {`Tipo Operação (${tabs[2].quantidade})`}
                            </NavLink>  
                        </NavItem>           
                        <NavItem style={{ padding: '0 5px 0 5px' }}>
                            <NavLink
                                className={classnames({ active: tab.tab === '4' })}
                                onClick={() => { changeTab({tab: '4', id: tabs[3].id}); }}
                                style={
                                    tab.tab === '4' ?
                                        { color: 'var(--color-preto)', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s', cursor: 'pointer' }
                                        : { color: 'var(--color-preto)', fontWeight: '700', cursor: 'pointer' }}
                            >
                            <AiOutlineTags style={{fontSize: '18px', paddingBottom: '2px', marginRight: '5px'}}/> 
                            {`Convênio (${tabs[3].quantidade})`}
                            </NavLink>    
                        </NavItem>           
                        <NavItem style={{ padding: '0 5px 0 5px' }}>
                            <NavLink
                                className={classnames({ active: tab.tab === '5' })}
                                onClick={() => { changeTab({tab: '5', id: tabs[4].id}); }}
                                style={
                                    tab.tab === '5' ?
                                        { color: 'var(--color-preto)', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s', cursor: 'pointer' }
                                        : { color: 'var(--color-preto)', fontWeight: '700', cursor: 'pointer' }}
                            >
                            <BsTable style={{fontSize: '18px', paddingBottom: '2px', marginRight: '5px'}}/> 
                            {`Tipo Comissão (${tabs[4].quantidade})`}
                            </NavLink>   
                        </NavItem>              
                    </Nav>
                    <TabContent activeTab={tab.tab}>
                        <TabPane tabId="1">
                           <TabelaComissao/>
                        </TabPane>
                        <TabPane tabId="2">
                           <Produto/>
                        </TabPane>
                        <TabPane tabId="3">
                           <TipoOperacao/>
                        </TabPane>
                        <TabPane tabId="4">
                           <Convenio/>
                        </TabPane>
                        <TabPane tabId="5">
                           <TipoComissao/>
                        </TabPane>
                    </TabContent>
                </div>
            </Card>
        </Col>

    );
}

export default IncosistenciaComissao;