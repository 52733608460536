import React, { useEffect, useMemo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import { HiOutlinePrinter } from "react-icons/hi";
import ReactPaginate from "react-paginate";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Button, Card, Col, Input, Label, Row, Form, FormGroup } from "reactstrap";
import ReactToPrint from 'react-to-print'

import { COLUMNS } from "../constants/columns";
import { Select } from "../../../components/Inputs/Input";
import ModalDetalhamento from "../components/ModalDetalhamento";
import ModalImpressao from "../components/ModalImpressao";

import * as actions from '../../../store/modules/formalizacaoMatriz/actions'
import * as  controller from "../../../controllers/formalizacaoMatrizController";

const Search = () => {
    const { pesquisar, getAllItems, formalizacoes, instituicoes, lojas, impressao} = useSelector(state => state.formalizacaoMatriz);
    const columns = useMemo(() => COLUMNS, []);
    const data = formalizacoes;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const { pageSize } = state;
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    const [modalDetalhamento, setModalDetalhamento] = useState(false)
    const toggleDetalhamento = () => setModalDetalhamento(!modalDetalhamento)

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        controller.get(pesquisar,pageSize, pageSelected + 1)
    }


    function handleInitialPage(pageSize) {
        const pageSelected = 0;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        controller.get(pesquisar, pageSize, pageSelected + 1);
    }

    function onChange(value, field) {
        actions.pesquisar(field, value );
    }

    function handleDetalhamento(id){
        controller.getById(id, 'Conferencia')
        toggleDetalhamento()
    }

    async function handleImpressao(id){
        await controller.getById(id, 'Impressao')
        document.getElementById('printButton').click()
    }

    useEffect(()=>{
        controller.getInstituicoes();
    }, [])

    useEffect(() => {
        if(getAllItems.tamanhoPagina){
            setPageSize(getAllItems.tamanhoPagina);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllItems])

    const componentRef = useRef();

    return (
        <>
            <Col>
                <Card className="pt-3 pb-2 mb-5">
                    <Col>
                        <div style={{ width: "25%" }} className="pb-3">
                            <Label style={{ fontWeight: '400', fontSize: '18px', margin: '5px 0 8px 15px' }}>Pesquisar</Label>
                        </div>
                        <Form style={{ margin: '0px 0px 15px 15px' }}>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Número do protocolo</Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={pesquisar.numeroProtocolo}
                                            onChange={e => onChange(e.target.value, 'numeroProtocolo')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Classificação</Label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: pesquisar.classificacaoId }}
                                            select={{ value: "id", label: "descricao" }}
                                            options={[{id:'1', descricao:'PRIMEIRA ENTREGA'}, {id:'2', descricao:'REABERTURA'}]}
                                            onChange={e => onChange(e.label, 'classificacaoId')}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Instituição Financeira</Label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: pesquisar.instituicaoId }}
                                            select={{ value: "id", label: "nome" }}
                                            options={instituicoes}
                                            onChange={e => onChange(e.value, 'instituicaoId')}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Lojas</Label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: pesquisar.lojaId }}
                                            select={{ value: "id", label: "descricao" }}
                                            options={lojas}
                                            onChange={e => onChange(e.value, 'lojaId')}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Data Inicial</Label>
                                        <Input
                                            type="date"
                                            value={pesquisar.dataInicial}
                                            max="9999-12-31"
                                            onChange={e => onChange(e.target.value, 'dataInicial')}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Data Final</Label>
                                        <Input
                                            type="date"
                                            value={pesquisar.dataFinal}
                                            max="9999-12-31"
                                            onChange={e => onChange(e.target.value, 'dataFinal')}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button className="mr-4"
                                        style={{
                                            width: "150px",
                                            background: "var(--color-branco)",
                                            borderColor: "var(--color-laranja)",
                                            color: "var(--color-laranja)",
                                            borderRadius: "7px"
                                        }}
                                        onClick={()=> {actions.cleanSearch(); onChange('0', 'classificacaoId')}}>
                                        Limpar
                                        </Button>
                                    <Button
                                        className="purpleButton"
                                        style={{ width: "150px" }}
                                        onClick={() => {controller.get(pesquisar, pageSize, 1);
                                            gotoPage(0);
                                            setInitialPage(true);
                                            setSelectedPage(0);}}>
                                        Buscar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th>
                                                {column.render("Header")}
                                                <span className="float-right">
                                                    {column.isSorted ?
                                                        (column.isSortedDesc ?
                                                            <BsIcons.BsArrowDown
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />
                                                            :
                                                            <BsIcons.BsArrowUp
                                                                id="OrdenarAsc"
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />)
                                                        :
                                                        <BsIcons.BsArrowDownUp
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />}
                                                </span>
                                            </th>
                                        ))}
                                        <th style={{ textAlign: "center" }}>
                                            Ações
                                 </th>
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr className="text-center" {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                            })}
                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                <>
                                                    <AiIcons.AiOutlineEye
                                                        style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}
                                                        onClick={() => handleDetalhamento(row.original.id)}
                                                    />
                                                    <HiOutlinePrinter
                                                        style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}     
                                                        onClick={()=> handleImpressao(row.original.id)}                                                    
                                                    />
                                                </>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Row className="pt-4">
                            <Col>
                                <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {getAllItems.quantidade !== undefined ? getAllItems.quantidade : "0"} de {getAllItems.quantidadeTotal !== undefined ? getAllItems.quantidadeTotal : "0"}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={getAllItems.totalPaginas}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { handleInitialPage(e.target.value); setPageSize(Number(e.target.value)) }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Card >
            </Col >
            {
                <ModalDetalhamento
                    modalDetalhamento={modalDetalhamento}
                    toggle={toggleDetalhamento}
                    search="true" />
            }     
            <div style={{display: 'none'}}>
                <ReactToPrint
                    trigger={() => <button id='printButton'></button>}
                    content={() => componentRef.current}
                />
                <ModalImpressao
                    ref={componentRef}
                    data={impressao} 
                    /> 
            </div>            
        </>
    );
}


export default Search;