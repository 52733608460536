const STORE_ID = "GRUPO";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    SET_GET_ALL: 'SET_GET_ALL',
    GRUPO: "GRUPO",
    GRUPO_COPY: "GRUPO_COPY",
    INVALID_GRUPO: "INVALID_GRUPO",
    GRUPOS: "GRUPOS",
    CLEAN_INVALID_GRUPO: "CLEAN_INVALID_GRUPO",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    SET_PESSOA: "SET_PESSOA",
    SET_PESSOAS_MODAL: "SET_PESSOAS_MODAL",
    SET_MODAL: "SET_MODAL",
    SET_TIPO_PESSOA: "SET_TIPO_PESSOA",
    PESSOA: "PESSOA",
    SET_LOADING_MODAL: "SET_LOADING_MODAL"
}

export { STORE_ID, TYPES }