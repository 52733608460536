import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as operacaoActions from "../store/modules/operacaoRibercred/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("tipos-operacao");
    setLoading(false)

    if (!data.success) {
        return;
    }

    operacaoActions.getItems(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`tipos-operacao/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    operacaoActions.copy(data.content);
    operacaoActions.item(data.content);
}

export async function create(item) {

    if (item.descricao.trim() === "") {
        operacaoActions.setInvalid("descricao");
        notifyWarn("Descrição da operação ribercred não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao,
    }

    setLoading(true)
    const data = await api.post("tipos-operacao", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Operação ribercred cadastrada");
    operacaoActions.cleanRegister();
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`tipos-operacao/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Operação ribercred excluída");
    get();
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (item.descricao === "") {
        operacaoActions.setInvalid("descricao");
        notifyWarn("Descrição da operação ribercred não pode estar em branco");
        return;
    }


    const body = {
        descricao: item.descricao,
    }

    setLoading(true)
    const data = await api.put(`tipos-operacao/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Operação ribercred atualizada");
    operacaoActions.cleanRegister();
    operacaoActions.setActiveTab("Search");
    get();
}
