import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    search: {
        banco: "0",
        loja: "",
        lojaId: "0",
        lojaMatriz: "",
        lojaMatrizId: "0",
        dataInicio: "",
        dataFim: "",
        codLoja: "",
        codLojaMatriz: ""
    },
    item: {
        bancoId: "0",
        lojaBancoId: '0',
        matriz: '',
        dataInicio: "",
        dataFim: "",
        divisaoComissaoItens: [],
        id: ""
    },
    divisaoComissaoCard: {
        tiposComissaoId: "0",
        percentual: "",
    },
    copy: {},
    invalid: {
        descricao: false,
    },
    items: [],
    getAllItems: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    modalLoja: {
        id: '',
        codLoja: '',
        nome: ''
    },
    getAllLoja: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    lojas: [],
    modalLojaMatriz: {
        id: '',
        codLojaMatriz: '',
        nome: ''
    },
    getAllLojaMatriz: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    lojasMatrizes: [],
    bancos: [],
    lojasBanco: [],
    tiposComissao: [],
    lojasSemDivisaoRegister: [],
    lojasRegister: [],
    lojasMatrizesRegister: [],
    activeTab: "Search",
    seeRegister: false,
    modalLojaLoading: false,
    modalLojaMatrizLoading: false,
    permitions: {
        criar: false,
        deletar: false,
    }
}

export default function divisaoComissao(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.SEARCH:
                    return { ...state, search: action.value }
                case TYPES.DIVISAO_COMISSOES:
                    return { ...state, items: action.value }
                case TYPES.DIVISAO_COMISSAO:
                    return { ...state, item: action.value }
                case TYPES.INVALID_DIVISAO_COMISSAO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_DIVISAO_COMISSAO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state,
                        item: INITIAL_STATE.item,
                        invalid: INITIAL_STATE.invalid,
                        divisaoComissaoCard: INITIAL_STATE.divisaoComissaoCard,
                        lojasBanco: INITIAL_STATE.lojasBanco,
                        tiposComissao: INITIAL_STATE.tiposComissao,
                    }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.GET_ALL_ITEMS:
                    return { ...state, getAllItems: action.value }
                case TYPES.SET_MODAL_LOJA:
                    return { ...state, modalLoja: { ...state.modalLoja, [action.campo]: action.value } }
                case TYPES.CLEAN_MODAL_LOJA:
                    return { ...state, modalLoja: INITIAL_STATE.modalLoja}
                case TYPES.SET_LOJAS:
                    return { ...state, lojas: action.value }
                case TYPES.SET_LOJAS_BANCO:
                    return { ...state, lojasBanco: action.value }
                case TYPES.SET_TIPOS_COMISSAO:
                    return { ...state, tiposComissao: action.value }
                case TYPES.SET_GET_ALL_LOJA:
                    return { ...state, getAllLoja: action.value }
                case TYPES.CLEAN_GET_ALL_LOJA:
                    return { ...state, getAllLoja: INITIAL_STATE.getAllLoja }
                case TYPES.SET_MODAL_LOJA_MATRIZ:
                    return { ...state, modalLojaMatriz: { ...state.modalLojaMatriz, [action.campo]: action.value } }
                case TYPES.CLEAN_MODAL_LOJA_MATRIZ:
                    return { ...state, modalLojaMatriz: INITIAL_STATE.modalLojaMatriz }
                case TYPES.SET_LOJAS_MATRIZES:
                    return { ...state, lojasMatrizes: action.value }
                case TYPES.SET_GET_ALL_LOJA_MATRIZ:
                    return { ...state, getAllLojaMatriz: action.value }
                case TYPES.CLEAN_GET_ALL_LOJA_MATRIZ:
                    return { ...state, getAllLojaMatriz: INITIAL_STATE.getAllLojaMatriz }
                case TYPES.ADD_LOJA:
                    return {
                        ...state, item: {
                            ...state.item,
                            divisaoComissaoItens: [...state.item.divisaoComissaoItens, action.value]
                        }
                    }
                case TYPES.REMOVE_LOJA:
                    let lojas = [...state.item.divisaoComissaoItens]
                    lojas.splice(action.index, 1)
                    return {
                        ...state, item: {
                            ...state.item,
                            divisaoComissaoItens: lojas
                        }
                    }
                case TYPES.SET_BANCOS:
                    return { ...state, bancos: action.value }
                case TYPES.SET_LOJAS_SEM_DIVISAO_REGISTER:
                    return { ...state, lojasSemDivisaoRegister: action.value }
                case TYPES.SET_LOJAS_REGISTER:
                    return { ...state, lojasRegister: action.value }
                case TYPES.SET_LOJAS_MATRIZES_REGISTER:
                    return { ...state, lojasMatrizesRegister: action.value }
                case TYPES.CLEAN_ALL_SEARCH:
                    return { ...state, search: INITIAL_STATE.search, items: INITIAL_STATE.items, getAllItems: INITIAL_STATE.getAllItems }
                case TYPES.CLEAN_LOJAS_SEM_DIVISAO_REGISTER:
                    return { ...state, lojasSemDivisaoRegister: INITIAL_STATE.lojasSemDivisaoRegister }
                case TYPES.CLEAN_LOJAS_REGISTER:
                    return { ...state, lojasRegister: INITIAL_STATE.lojasRegister }
                case TYPES.CLEAN_LOJAS_MATRIZES_REGISTER:
                    return { ...state, lojasMatrizesRegister: INITIAL_STATE.lojasMatrizesRegister }
                case TYPES.SET_DIVISAO_COMISSAO_CARD:
                    return { ...state, divisaoComissaoCard: action.value }
                case TYPES.CLEAN_DIVISAO_COMISSAO_CARD:
                    return { ...state, divisaoComissaoCard: INITIAL_STATE.divisaoComissaoCard }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                case TYPES.SET_LOADING_LOJA:
                    return { ...state, modalLojaLoading: action.value }
                case TYPES.SET_LOADING_LOJA_MATRIZ:
                    return { ...state, modalLojaMatrizLoading: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}