import React from 'react';
import Chart from 'react-google-charts';

export default function Grafico({data}){
    return(
        <div style={{boxShadow: '3px 3px 1em #0003', borderRadius: '3px'}}>
            <Chart
             options={{
                    bar: {groupWidth: 48},
                    colors: ['#FFC940'],
                    vAxis:  { 
                        format: 'currency',
                      },
                    }
                }
                chartLanguage='pt'
                height='300px'
                chartType="ColumnChart"
                loader={<div>Loading Chart</div>}
                data={data}
            />
        </div>
    )
}