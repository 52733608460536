import { STORE_ID, TYPES } from "./types";
import store from "../../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    });
}

export function setBancos(value) {
    dispatch({
        type: TYPES.SET_BANCOS,
        value
    });
}

export function setTiposConta(value) {
    dispatch({
        type: TYPES.SET_TIPOS_CONTA,
        value
    });
}

export function setTiposPix(value) {
    dispatch({
        type: TYPES.SET_TIPOS_PIX,
        value
    });
}

export function contaCorrente(value) {
    dispatch({
        type: TYPES.CONTA_CORRENTE,
        value
    });
}

export function clean() {
    dispatch({
        type: TYPES.CLEAN
    });
}
export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    });
}

export function setMatriculasSelected(value) {
    dispatch({
        type: TYPES.SET_MATRICULAS_SELECTED,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.SET_INVALID,
        field
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID,
        field
    });
}

export function setMatriculas(value) {
    dispatch({
        type: TYPES.SET_MATRICULAS,
        value
    });
}

export function setConta(value) {
    dispatch({
        type: TYPES.SETCONTA,
        value
    });
}

export function setCampoConta(field, value){
    dispatch({
        type: TYPES.SET_CAMPO_CONTA,
        field,
        value
    })
}

export function setIsEditando(value){
    dispatch({
        type: TYPES.SET_IS_EDITANDO,
        value
    })
}

export function setIsExcluindo(value){
    dispatch({
        type:TYPES.SET_IS_EXCLUINDO,
        value
    })
}

