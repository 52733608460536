import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { maskMoeda } from '../../../../../../util/masks'
import * as actions from '../../../../../../store/modules/personas/papeis_store/actions'
import * as controllerPapeis from '../../../../../../controllers/personasController/papeisController'
import { Select } from '../../../../../../components/Inputs/Input'
import '../../../../styles/papeis.css'

const ClienteCNPJ = () => {
    const { regimesTributario, clienteCNPJ, seeRegister, } = useSelector(state => state.personasPapeis);
    useEffect(() => {
        controllerPapeis.getRegimesTributario()
    }, [])

    function onChange(value, field) {
        actions.clienteCnpj({ ...clienteCNPJ, [field]: value });
    }

    return (
        <div style={{ marginTop: '25px' }}>
            <Col md={10}>
                <Form>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Regime tributário</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: clienteCNPJ.regimeTributarioId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={regimesTributario}
                                    onChange={e => onChange(e.value, 'regimeTributarioId')}
                                    isSearchable={true}
                                    autoComplete="off"
                                    disabled={seeRegister}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Faturamento</Label>
                                <Input
                                    placeholder="R$"
                                    value={clienteCNPJ.valorFaturamento}
                                    maxLength="16"
                                    onChange={e => onChange(maskMoeda(e.target.value), 'valorFaturamento')}
                                    disabled={seeRegister}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </div>
    )
}

export default ClienteCNPJ;