import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Label, Row, Collapse, Input } from "reactstrap";
import { useSelector } from "react-redux";
import { useTable, useSortBy } from "react-table";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import {BsArrowDown, BsArrowUp, BsArrowDownUp} from 'react-icons/bs'

import { Select } from "../../../components/Inputs/Input";
import { COLUMNS_PROPOSTAS } from "../constants/columns";

import * as actions from '../../../store/modules/formalizacaoMatriz/actions'
import * as controller from '../../../controllers/formalizacaoMatrizController'

const Register = () => {
    const { instituicoes, destinatarios, item, lojas} = useSelector(state => state.formalizacaoMatriz);

    useEffect(() => {
        if(!item.instituicaoId || item.instituicaoId === '0'){
            actions.setDestinatarios([])
        }else{
            controller.getDestinatarios(item.instituicaoId ?? null)
        }
    }, [item.instituicaoId])

    useEffect(() => {
        if(item.instituicaoId && item.instituicaoId !== '0'){
            controller.getPropostas(item.instituicaoId, item.lojaId)
        }
    }, [item.lojaId,  item.instituicaoId])

    return (
        <>
            <Col>
                <Card className="pt-3 pb-2 mb-5">
                    <Col>
                        <div className="pb-3">
                            <Label style={{ fontWeight: '400', fontSize: '18px', margin: '5px 0 8px 15px', color: 'var(--color-preto)' }}>Criação de borderô Ribercred Matriz</Label>
                        </div>
                        <Row style={{margin: '5px 0 30px 0'}}>
                        <Col md={4}>
                                <Label>Loja</Label>
                                {lojas.length <= 1 ?
                                (
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.lojaId}
                                        disabled
                                    />
                                )
                                :
                                (
                                    <Select
                                        first="Selecione"
                                        value={{ value: item.lojaId }}
                                        select={{ value: "id", label: "nome" }}
                                        options={lojas}
                                        onChange={e => actions.item('lojaId', e.value)}
                                        autoComplete="off"
                                    />
                                )
                                }
                            </Col>
                            <Col md={4}>
                                <Label>Instituição Financeira</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: item.instituicaoId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={instituicoes}
                                    onChange={e => {actions.item('instituicaoId', e.value); controller.getPropostas(e.value !== '0' && e.value)}}
                                    autoComplete="off"
                                />
                            </Col>
                            <Col md={4}>
                                <Label>Destinatário</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: item.destinatarioId }}
                                    select={{ value: "enderecoId", label: "nome" }}
                                    options={destinatarios}
                                    onChange={e => actions.item('destinatarioId', e.value)}
                                    autoComplete="off"
                                />
                            </Col>
                        </Row>
                        <div style={{display: 'flex'}}>
                            <div style={{background: '#F5F6FA',height: '26.7px', width: '5%'}}></div>
                            <div style={{background: '#F5F6FA', padding: '5px 1px 5px 14px', height: '26.7px', width: '25%'}}>
                                Número da proposta
                            </div>                          
                            <div style={{background: '#F5F6FA', padding: '5px 1px 5px 14px', height: '26.7px', width: '25%'}}>
                                Instituição
                            </div>
                            <div style={{background: '#F5F6FA', padding: '5px 1px 5px 14px', height: '26.7px', width: '25%'}}>
                                Produto Riber
                            </div>
                            <div style={{background: '#F5F6FA', padding: '5px 1px 5px 14px', height: '26.7px', width: '25%'}}>
                                Digitador
                            </div>
                        </div>
                        {item.propostas.map((e,i)=>{
                            return(
                                <CollapsedRow proposta={e} index={i} item={item}/>
                            )
                        })}
                    </Col>
                </Card >
            </Col >
        </>
    );
}


export default Register;

function CollapsedRow({proposta, item, index}){
    const { activeTab} = useSelector(state => state.formalizacaoLoja);

    const [isRowOpen, setIsRowOpen] = useState(false)

    const [isPropostasActive, setIsPropostasActive] = useState(false)

    const columns = useMemo(() => COLUMNS_PROPOSTAS, []);
    const data = proposta.propostasItem;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    },
        useSortBy);

    useEffect(() => {
        setIsPropostasActive(false)
    }, [activeTab])


    function handleSelectAllPropostas(){
        setIsPropostasActive(!isPropostasActive)
        if (!isPropostasActive) {
            proposta.propostasItem.forEach((e) => {
                e.isSelected = true
            })
        } else {
            proposta.propostasItem.forEach((e) => {
                e.isSelected = false
            })
        }
    }

    function handleSelectProposta(id){
        let index = proposta.propostasItem.findIndex((e) => e.id === id)
        proposta.propostasItem[index].isSelected = !proposta.propostasItem[index].isSelected
        actions.item('propostasItem', proposta.propostasItem)
        if (proposta.propostasItem.find((e) => e.isSelected === false)) {
            setIsPropostasActive(false)
        } else {
           setIsPropostasActive(true)
        }
    }
    function handleActiveRow(id){
        if(!isRowOpen){
            controller.getPropostasItem(id, item.propostas, index);
        }
        setIsRowOpen(!isRowOpen)
    }
    return(
        <>
            <div style={{display: 'flex', cursor: 'pointer'}} onClick={()=> handleActiveRow(proposta.id)}>
                <div style={{padding: '7px',width: '5%', minHeight: '29px', borderBottom: '1px solid #CBD0D1'}}>
                    {isRowOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine/>}
                </div>
                <div style={{padding: '7px', width: '25%', minHeight: '29px', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                    {proposta.numProposta}
                </div>
                <div style={{padding: '7px', width: '25%', minHeight: '29px', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                    {proposta.nomeBanco}
                </div>
                <div style={{padding: '7px', width: '25%', minHeight: '29px', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                    {proposta.nomeProduto}
                </div>
                <div style={{padding: '7px', width: '25%', minHeight: '29px', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                    {proposta.digitador}
                </div>
            </div>
            <Collapse isOpen={isRowOpen}>
                <table style={{width: '100%'}} {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                <th><input type="checkbox" checked={isPropostasActive} onClick={()=>handleSelectAllPropostas()}/></th>
                                {headerGroup.headers.map((column) => (
                                <th >
                                {column.render("Header")}
                                <span className="float-right">
                                    {column.isSorted ?
                                        (column.isSortedDesc ?
                                            <BsArrowDown
                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                            />
                                            :
                                            <BsArrowUp
                                                id="OrdenarAsc"
                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                            />)
                                        :
                                        <BsArrowDownUp
                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                        />}
                                </span>
                            </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)
                            return (
                                <tr className="text-center" {...row.getRowProps()}>
                                    <td ><input type="checkbox" style={{marginLeft: '7px'}} checked={row.original.isSelected} onClick={()=>handleSelectProposta(row.original.id)}/></td>
                                    {row.cells.map((cell) => {
                                        return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Collapse>
        </>
    )
    
}