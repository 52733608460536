import React from 'react'
import { BsArrowUp, BsArrowDown } from 'react-icons/bs'
import { AiOutlineMinus } from 'react-icons/ai'

import { maskMoeda } from '../../../../util/masks'
import { CustomTooltip } from '../../../../components/CustomTooltip'

function getCanceladoColor(crescimento) {
  return crescimento > 0 ? 'rgba(253, 105, 130, 0.2)' : crescimento === 0 ? '#ffd47a' : 'rgba(151, 224, 79, 0.25)'
}

function getCanceladoArrowColor(crescimento) {
  if(crescimento === 0) return 'var(--color-laranja)'

  if(crescimento > 0) return '#F43656'

  return 'var(--color-verde-claro)'
}

export default function BigNumber({element}){

    return(
        element.crescimento > 0 ? (
            <div style={{background: element.status === 'CANCELADO' ? getCanceladoColor(element.crescimento) : 'rgba(151, 224, 79, 0.25)',  borderRadius: '20px',width: '100%', height: '110px', marginRight: '20px', paddingTop: '15px'}}>
                {
                    (element.status === 'TICKET MÉDIO' || element.status === 'SHARE') &&
                    <div style={{marginTop: '0.9rem'}}/>
                }
                <CustomTooltip title={`Valor Comparado: R$${maskMoeda(element.valorComparado.toFixed(2))}`}>
                  <p style={{textAlign: 'center', color: element.status === 'CANCELADO' ? getCanceladoArrowColor(element.crescimento) : 'var(--color-verde-claro)', fontsize: '18px', marginBottom: '3px'}}>
                    <BsArrowUp style={{fontSize: '20px'}}/> {`${element.crescimento}%`}
                  </p>
                </CustomTooltip>
                {element.status !== 'TICKET MÉDIO' && element.status !== 'SHARE' && (
                    <p style={{textAlign: 'center', fontsize: '18px', marginBottom: '3px'}}>
                        {`(${element.qtdPropostas})`}
                    </p>
                )}
                <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                    {element.status !== 'SHARE' ? `R$${maskMoeda(element.valorTotal.toFixed(2))}` : `${maskMoeda(element.valorTotal.toFixed(2))}`}
                </p>
                <p style={{textAlign: 'center', fontSize: '13px'}}>
                    {element.status}
                </p>
            </div>
        ) :
        element.crescimento === 0 ? (
            <div style={{background: element.status === 'CANCELADO' ? getCanceladoColor(element.crescimento) : '#ffd47a',  borderRadius: '20px',width: '100%', height: '110px', marginRight: '20px', paddingTop: '15px'}}>
                {
                    (element.status === 'TICKET MÉDIO' || element.status === 'SHARE') &&
                    <div style={{marginTop: '0.9rem'}}/>
                }
                <p style={{textAlign: 'center', color: element.status === 'CANCELADO' ? getCanceladoArrowColor(element.crescimento) : 'var(--color-laranja)', fontsize: '18px', marginBottom: '3px'}}>
                  <AiOutlineMinus style={{fontSize: '20px'}}/> {`${element.crescimento}%`}
                </p>
                {element.status !== 'TICKET MÉDIO' && element.status !== 'SHARE' && (
                    <p style={{textAlign: 'center', fontsize: '18px', marginBottom: '3px'}}>
                        {`(${element.qtdPropostas})`}
                    </p>
                )}
                <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                    {element.status !== 'SHARE' ? `R$${maskMoeda(element.valorTotal.toFixed(2))}` : `${maskMoeda(element.valorTotal.toFixed(2))}`}
                </p>
                <p style={{textAlign: 'center', fontSize: '13px'}}>
                    {element.status}
                </p>
            </div>
        ) : (
            <div style={{background: element.status === 'CANCELADO' ? getCanceladoColor(element.crescimento) : 'rgba(253, 105, 130, 0.2)', borderRadius: '20px', width: '100%', height: '110px', marginRight: '20px', paddingTop: '15px'}}>
               {
                    (element.status === 'TICKET MÉDIO' || element.status === 'SHARE') &&
                    <div style={{marginTop: '0.9rem'}}/>
                }
                <CustomTooltip title={`Valor Comparado: R$${maskMoeda(element.valorComparado.toFixed(2))}`}>
                  <p style={{textAlign: 'center', color: element.status === 'CANCELADO' ? getCanceladoArrowColor(element.crescimento) : '#F43656', fontsize: '18px', marginBottom: '1px'}}>
                    <BsArrowDown style={{fontSize: '20px'}}/> {`${element.crescimento}%`}
                  </p>
                </CustomTooltip>
                {element.status !== 'TICKET MÉDIO' && element.status !== 'SHARE' && (
                    <p style={{textAlign: 'center', fontsize: '18px', marginBottom: '3px'}}>
                        {`(${element.qtdPropostas})`}
                    </p>
                )}
                <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                    {`R$${maskMoeda(element.valorTotal.toFixed(2))}`}
                </p>
                <p style={{textAlign: 'center', fontSize: '13px'}}>
                    {element.status}
                </p>
            </div>
        )
    )
}