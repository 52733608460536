const STORE_ID = "OPERACAO_INSTITUICAO";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    OPERACAO_INSTITUICAO: "OPERACAO_INSTITUICAO",
    OPERACAO_INSTITUICAO_COPY: "OPERACAO_INSTITUICAO_COPY",
    INVALID_OPERACAO_INSTITUICAO: "INVALID_OPERACAO_INSTITUICAO",
    OPERACOES_INSTITUICOES: "OPERACOES_INSTITUICOES",
    CLEAN_INVALID_OPERACAO_INSTITUICAO: "CLEAN_INVALID_OPERACAO_INSTITUICAO",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    SET_OPERACOESRC: "SET_OPERACOESRC",
    SET_BANCOS: "SET_BANCOS"
}

export { STORE_ID, TYPES }