import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    item:{
        id: '0',
        url: '',
        instituicao: '',
        layoutPlanilhaId: '0',
        recorrenciaId: '0',
        usuario: '',
        intervalo: '',
        horaInicio: '',
        horaFim: '',
        senha: '',
        log: [],
        diaDaSemana: '0',
        diaDoMes: '0',
    },
    parametros: [],
    recorrencias: [],
    layoutesPlanilha: [],
    activeTab: "Search",
    seeRegister: false,
    getAllLog:{quantidade: '0',
                quantidadeTotal: '0',
                totalPaginas: '0'},
}

export default function parametrosAutomacao(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.ITEM:
                    return { ...state, item:action.value };
                case TYPES.SET_ITEM:
                    return { ...state, item: { ...state.item, [action.campo]: action.value } };
                case TYPES.GET_PARAMETROS:
                    return { ...state, parametros: action.value };
                case TYPES.GET_RECORRENCIAS:
                    return { ...state, recorrencias: action.value };
                case TYPES.GET_LAYOUTES:
                    return { ...state, layoutesPlanilha: action.value };
                case TYPES.CLEAN_REGISTER:
                    return { ...state, item:{ id: '0',
                                                url: '',
                                                instituicao: '',
                                                layoutPlanilhaId: '0',
                                                recorrenciaId: '0',
                                                intervalo: '',
                                                horaInicio: '',
                                                horaFim: '',
                                                usuario: '',
                                                senha: '',
                                                log: []}
             }
             case TYPES.GET_ALL_ITEMS:
                    return { ...state, getAllLog: action.value }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}