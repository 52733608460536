import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Modal, ModalBody, Button } from "reactstrap";

import { maskNumber } from '../../../util/masks'

import * as actions from '../../../store/modules/hierarquia/actions'
import * as controller from '../../../controllers/hierarquiasController'

export default function ModalNiveis({isOpen, toggle, hasCancelButton}){
    const { niveisHierarquicos } = useSelector(state => state.hierarquia);
    const [isFinished, setIsFinished] = useState(false)

    useEffect(()=>{
        checkIsFinished()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[niveisHierarquicos])

    function checkIsFinished(){
        if(niveisHierarquicos.find((hierarquia)=> Number(hierarquia.ordem) === 99)){
            setIsFinished(false)
        }else{
            setIsFinished(true)
        }
            }

    function onChange(e, i){
        niveisHierarquicos[i].ordem = e
        actions.setNiveisHierarquicos(niveisHierarquicos)
    }

    function ordenarArray(a, b) {
        if (Number(a.ordem) < Number(b.ordem) ) {
            return -1;
        }
        if (Number(a.ordem) > Number(b.ordem) ) {
            return 1;
        }
        return 0;
        }


    function handleSetNivel(e) {
            e.preventDefault();
            var newArray = niveisHierarquicos
            newArray = newArray.sort(ordenarArray)
            actions.setNiveisHierarquicos(newArray)
            checkIsFinished()
    }

    async function handleConfirmar(){
       var isSuccessed = await controller.updateNiveis(niveisHierarquicos)
       if(isSuccessed){
           actions.cleanRegister()
           actions.setActiveTab('Search')
           toggle()
       }
    }

    return(
        <Modal
            className="text-center"
            isOpen={isOpen}
            toggle={toggle}
            backdrop={'static'}
            keyboard={false}
            size="lg"
            style={{maxWidth: '1600px', width: '80%'}}
        >
            <ModalBody style={{margin:'0 0 0px 20px', width: '100%'}}>
                <div style={{display: 'flex', width: '95%'}}>
                    <div style={{padding: '10px 1px 5px 14px', fontWeight: '700', height: '40px', width: '15%'}}>
                         Ordem
                    </div>
                    <div style={{padding: '10px 1px 5px 14px', fontWeight: '700',  height: '40px', width: '85%'}}>
                        Hierarquia
                    </div>
                </div>
                {niveisHierarquicos.map((hierarquia, index)=>{
                    return(
                        <div key={index} style={{display: 'flex', width: '95%'}}>
                            <div style={{padding: '7px', width: '15%', minHeight: '40px', borderBottom: '1px solid #CBD0D1'}}>
                                 <input 
                                 type="text" 
                                 style={{border: '1px solid #CBD0D1',borderRadius: '5px', padding: '7px', outline: 'none', minWidth: '10%', maxWidth: '20%'}}
                                 value={hierarquia.ordem !== 99 ? hierarquia.ordem : ''} 
                                 onChange={(e)=> onChange(maskNumber(e.target.value), index)} 
                                 onBlur={(e)=> handleSetNivel(e)}/>
                            </div>
                            <div style={{padding: '7px', width: '85%', minHeight: '40px', borderBottom: '1px solid #CBD0D1'}}>
                                {hierarquia.descricao}
                            </div>
                        </div>
                    )
                })}
            </ModalBody>
        <div className={`d-flex justify-content-${hasCancelButton ? "between" : "end"} align-items-center mx-5 mb-3`}>
            {hasCancelButton ? (
              <Button
                  onClick={() => toggle()}
                  style={{
                            width: "242px",
                            background: "var(--color-branco)",
                            borderColor: "var(--color-laranja)",
                            color: "var(--color-laranja)",
                            borderRadius: "7px"
                  }}
              >
              Cancelar
              </Button>
            ): null}
            <Button
                style={{ width: "242px", marginRight: "10px"}}
                className="purpleButton"
                disabled={!isFinished}
                onClick={()=> handleConfirmar()}
            >
                Confirmar
            </Button>
          </div>

        </Modal>
    )
}