import React from "react";
import * as BiIcons from "react-icons/bi";
import * as documentacaoActions from "../../../../../../store/modules/personas/documentacao_store/actions";
import * as personasController from '../../../../../../controllers/personasController/personasController'
import { useSelector } from "react-redux";
import { AiOutlineDownload } from "react-icons/ai";
import { notifyWarn } from "../../../../../../components/message/message";

function TableDocumento({documentos}) {

    const { tiposDocumentos, seeRegister } = useSelector(state => state.personasDocumentacao);

    const data = documentos;

    async function handleRemoverItem(row, index) {
        let isSuccess = await personasController.removeDocumentacao(row.id)
        if (isSuccess){
            const arrDocumentos = documentos;
            arrDocumentos.splice(index, 1);
            documentacaoActions.setDocumentos(arrDocumentos);
        }else{
            notifyWarn("Não foi possivel remover esse documento")
        }
    }

    async function handleDownload(documentoId,  nomeDocumento) {
      const data = await personasController.getDocumentoBASE64(documentoId)

      if (data) {
        const a = document.createElement('a')
        a.href = `data:application/pdf;base64,${data.base64}`
        a.download = nomeDocumento
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    }
  
    return (
        <>
            <table style={{marginTop: '20px', width: '95%', marginLeft: '2%'}}>
                <thead>
                    <tr>
                        <th>Documento</th>
                        <th>Tipo de Documento</th>
                        <th style={{ textAlign: 'center' }}>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => {
                        var tipoDocumento = tiposDocumentos.find(e=> e.id === row.tipoDocumentoId)
                        return (
                            <>
                                <tr>
                                    <td>{row.nome}</td>
                                    <td>{tipoDocumento !== undefined && tipoDocumento.descricao}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <> 
                                            {!seeRegister && <BiIcons.BiTrash
                                                style={{ fontSize: '1.2rem', cursor: 'pointer', color: '#C4C7CA' }}
                                                onClick={() => handleRemoverItem(row, index)}
                                            />}

                                                <AiOutlineDownload
                                                  onClick={() => {handleDownload(row.id, row.nome)}}
                                                  style={{ fontSize: '1.2rem', cursor: 'pointer', color: '#C4C7CA' }}
                                                />
                                           
                                        </>
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

export default TableDocumento
