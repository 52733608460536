import React, { useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { useSelector } from 'react-redux';
import { Col, Row, FormGroup, Label, Input, Button, Collapse } from 'reactstrap'

import { Select, Switch } from '../../../components/Inputs/Input'
import ModalLoja from './components/ModalLoja.jsx';
import ModalConsultores from './components/ModalConsultores';
import BigNumber from './components/BigNumbers';
import Tabela from './components/Table';
import Grafico from './components/Grafico'

import * as actions from '../../../store/modules/dashboard/formalizacao/actions'
import * as controller from '../../../controllers/dashboardController/formalizacaoController'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';


export default function Formalizacao(){
    const { graficoPendencia, graficoFormalizacao, tabela, bigNumbers, filtros, instituicoes, produtos, tiposOperacao, parcerias, convenios, grupos } = useSelector(state => state.dashboardFormalizacao);

    useEffect(()=>{
        controller.getInstituicoes()
        controller.getProdutos()
        controller.getTiposOperacao()
        controller.getParcerias()
        controller.getConvenios()
        controller.getGrupos()
    },[])

    const [modalLoja, setModalLoja] = useState(false)
    const [modalConsultor, setModalConsultor] = useState(false)
    const [isTabelaOpen, setIsTabelaOpen] = useState(false)

    const toggleLoja = () => setModalLoja(!modalLoja)
    const toggleConsultor = () => setModalConsultor(!modalConsultor)

    async function onChangeFiltros(value, campo){
        actions.setFiltros(campo, value)
    }
    return(
        <>
            <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '0 20px 0 20px', padding: '20px'}}>
                <p style={{fontSize: '16px', fontWeight: '700'}}>Filtros</p>
                <div>
                    <Row style={{marginRight: '-45px'}}>
                        <Col md={2}>
                            <Label style={{fontWeight: '700'}}>Data Início</Label>
                            <Input
                                type='date'
                                value={filtros.dataInicio}
                                max="9999-12-31"
                                onChange={(e) => onChangeFiltros(e.target.value, 'dataInicio')}
                            />
                        </Col>
                        <Col md={2}>
                            <Label style={{fontWeight: '700'}}>Data Fim</Label>
                            <Input
                                type='date'
                                value={filtros.dataFim}
                                max="9999-12-31"
                                onChange={(e) => onChangeFiltros(e.target.value, 'dataFim')}
                            />
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label style={{fontWeight: '700'}}>Instituição Financeira</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.instituicaoId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={instituicoes}
                                    onChange={(e) => {onChangeFiltros(e.value, 'instituicaoId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label style={{fontWeight: '700'}}>Loja</Label>
                                <Input
                                 type="text"
                                 placeholder="Busque um favorecido..."
                                 value={filtros.loja}
                                 disabled
                                />
                            </FormGroup>
                        </Col>
                        <AiOutlineSearch
                            style={{color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer'}}
                            onClick={()=> toggleLoja()}
                        />
                    </Row>
                    <Row style={{marginRight: '-45px'}}>
                    {filtros.filtrosAvancados && (
                        <>
                                                  
                            <Col md={2}>
                                <FormGroup>
                                    <Label style={{fontWeight: '700'}}>Grupo</Label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: filtros.grupoId }}
                                        select={{ value: "id", label: "descricao" }}
                                        options={grupos}
                                        onChange={(e) => {onChangeFiltros(e.value, 'grupoId')}}
                                        isSearchable={true}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label style={{fontWeight: '700'}}>Produto</Label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: filtros.produtoId }}
                                        select={{ value: "id", label: "nome" }}
                                        options={produtos}
                                        onChange={(e) => {onChangeFiltros(e.value, 'produtoId')}}
                                        isSearchable={true}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label style={{fontWeight: '700'}}>Consultor</Label>
                                    <Input
                                    type="text"
                                    placeholder="Busque um consultor..."
                                    value={filtros.consultor}
                                    disabled
                                    />
                                </FormGroup>
                            </Col>
                            <AiOutlineSearch
                                style={{color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer'}}
                                onClick={()=> toggleConsultor()}
                            />
                            <Col md={3}>
                                <FormGroup>
                                    <Label style={{fontWeight: '700'}}>Tipo de Operação</Label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: filtros.tipoOperacaoId }}
                                        select={{ value: "id", label: "descricao" }}
                                        options={tiposOperacao}
                                        onChange={(e) => {onChangeFiltros(e.value, 'tipoOperacaoId')}}
                                        isSearchable={true}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label style={{fontWeight: '700'}}>Parceria</Label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: filtros.parceriaId }}
                                        select={{ value: "id", label: "nome" }}
                                        options={parcerias}
                                        onChange={(e) => {onChangeFiltros(e.value, 'parceriaId')}}
                                        isSearchable={true}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label style={{fontWeight: '700'}}>Cônvenio</Label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: filtros.convenioId }}
                                        select={{ value: "id", label: "descricao" }}
                                        options={convenios}
                                        onChange={(e) => {onChangeFiltros(e.value, 'convenioId')}}
                                        isSearchable={true}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            </Col>
                        </>
                        )}
                    </Row>
                </div>
                <div style={{borderTop: '1px solid #C4C7CA', paddingTop: '20px'}}>
                    <Row>
                        <Col md={2}>
                            <Label style={{fontWeight: '700'}}>Exibir filtros avançados</Label>
                            <div style={{ marginTop: '-20px', marginLeft: '-10px' }}>
                                <Switch
                                    className='switch mt-4'
                                    checked={filtros.filtrosAvancados}
                                    onClick={() => onChangeFiltros(!filtros.filtrosAvancados, 'filtrosAvancados')}
                                />
                            </div>
                        </Col>
                        <Col>
                        <Button
                                className="purpleButton"
                                style={{ width: "150px", float: 'right' }}
                                onClick={() => controller.get(filtros)}>
                                Aplicar
                            </Button>
                            <Button className="mr-4"
                                style={{
                                    width: "150px",
                                    background: "var(--color-branco)",
                                    borderColor: "var(--color-laranja)",
                                    color: "var(--color-laranja)",
                                    borderRadius: "7px",
                                    float: 'right'
                                }}
                                onClick={() => actions.cleanFiltros()}>
                                Limpar Filtros
                                </Button>
                        </Col>
                        </Row>
                    </div>
                </div>
                <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px'}}>
                    <p style={{fontSize: '16px', fontWeight: '700'}}>Formalização </p>
                    <div style={{display: 'flex'}}>
                        {bigNumbers.map((e)=>{
                            return(
                                <BigNumber element={e}/>
                            )
                        })}
                    </div>
                </div>
                <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px', display: 'flex'}}>
                    {graficoPendencia.length > 1 && <Grafico data={graficoPendencia}/>}
                    {graficoFormalizacao.length > 1 && <Grafico data={graficoFormalizacao}/>}
                </div>
                <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px'}}>
                    <p onClick={() => setIsTabelaOpen(!isTabelaOpen)} style={{fontSize: '16px', fontWeight: '700', cursor: 'pointer'}}>
                        {isTabelaOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine/>} Tabela de formalização 
                    </p>
                    <Collapse isOpen={isTabelaOpen}>
                        {tabela.length > 0 && <Tabela data={tabela}/>}
                    </Collapse>
                </div>
                
            {
                <ModalLoja
                    modalPessoa={modalLoja}
                    toggle={toggleLoja} />
            }
            {
                <ModalConsultores
                    isOpen={modalConsultor}
                    toggle={toggleConsultor} />
            }
            </>
    )
}