import React from "react";
import * as BiIcons from "react-icons/bi";
import * as papeisActions from "../../../../../../../store/modules/personas/papeis_store/actions"
import * as personasController from "../../../../../../../controllers/personasController/personasController";
import { useSelector } from "react-redux";
import { maskDate } from "../../../../../../../util/masks";

function TableInstituicao({ instituicoes, hasDataFinal }) {

    const { seeRegister, instituicoesFinanceira } = useSelector(state => state.personasPapeis);

    const data = instituicoes;

    async function handleRemoverItem(row, index) {
        let valid = await personasController.deleteUsuarioBanco(row.id)

        if (valid) {
            const arrContas = instituicoes;
            arrContas.splice(index, 1);
            papeisActions.userInstituicao(arrContas);
        }
    }

    function limpaCamposSelect(){
        papeisActions.cleanInstituicao();
        papeisActions.setLojas([]);
        papeisActions.setCentroCusto([]);
        papeisActions.setCanais([]);
        papeisActions.niveisHierarquicos([]);
    }

    async function handleUpdate(id, dataFinal){
        limpaCamposSelect();
        await personasController.getUsuarioBanco(id)
        if(dataFinal){
            hasDataFinal(true)
        }else{
            hasDataFinal(false)
        }
    }

    return (
        <>
            <table style={{ marginTop: '20px' }}>
                <thead>
                    <tr>
                        <th>Usuário</th>
                        <th>Instituição Financeira</th>
                        <th>Nível Hierárquico</th>
                        <th>Loja</th>
                        <th>Centro de Custo</th>
                        <th>Data de início da vigência do usuário</th>
                        <th>Data de fim da vigência do usuário</th>
                        <th>Status</th>
                        <th style={{ textAlign: 'center' }}>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => {
                        var instituicao = instituicoesFinanceira.find(e => e.id === row.bancoId)
                        return (
                            <>
                                <tr>
                                    <td>{row.usuarioInstituicao}</td>
                                    <td>{instituicao !== undefined && instituicao.nome}</td>
                                    <td>{row.hierarquia}</td>
                                    <td>{row.loja}</td>
                                    <td>{row.descricaoCentroCusto}</td>
                                    <td>{maskDate(row.dataVigenciaInicial, 'DD/MM/YYYY')}</td>
                                    <td>{row.dataVigenciaFinal && maskDate(row.dataVigenciaFinal, 'DD/MM/YYYY')}</td>
                                    <td>{row.statusUsuario === true ? 'Ativo' : 'Inativo'}</td>
                                    <td style={{ textAlign: 'center', width: '60px' }}>
                                        {!seeRegister &&
                                            <>
                                                <BiIcons.BiEdit
                                                    style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}
                                                    onClick={() => handleUpdate(row.id, row.dataVigenciaFinal)}
                                                />
                                                <BiIcons.BiTrash
                                                    style={{ fontSize: '1.2rem', cursor: 'pointer', color: '#C4C7CA' }}
                                                    onClick={() => handleRemoverItem(row, index)}
                                                />
                                            </>
                                        }
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

export default TableInstituicao
