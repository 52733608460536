const STORE_ID = 'PERSONAS_PAPEIS'

const TYPES = {
  SET_AUSENCIAS: 'SET_AUSENCIAS',
  PESSOA: 'PESSOA',
  SET_CLIENTE_CPF: 'SET_CLIENTE_CPF',
  SET_MATRICULAS_SELECTED: 'SET_MATRICULAS_SELECTED',
  SET_CONVENIOS_SELECTED: 'SET_CONVENIOS_SELECTED',
  SET_CENTRO_CUSTO: 'SET_CENTRO_CUSTO',
  SET_CENTRO_CUSTOV: 'SET_CENTRO_CUSTOV',
  SET_BANCOS: 'SET_BANCOS',
  SET_FUNCIONARIO_LOJA: 'SET_FUNCIONARIO_LOJA',
  SET_MASTERS: 'SET_MASTERS',
  SET_USUARIOS_BANCO: 'SET_USUARIOS_BANCO',
  SET_INSTITUICOES_FINANCEIRAS: 'SET_INSTITUICOES_FINANCEIRAS',
  SET_LOJAS: 'SET_LOJAS',
  SET_REGIMES_TRIB: 'SET_REGIMES_TRIB',
  CLIENTE_CNPJ: 'CLIENTE_CNPJ',
  SET_TIPOS_FUNCIONARIOS: 'SET_TIPOS_FUNCIONARIOS',
  CONTA_CORRENTE: 'CONTA_CORRENTE',
  FUNCIONARIO: 'FUNCIONARIO',
  SET_MATRIZES: 'SET_MATRIZES',
  SET_TIPOS_LOJA: 'SET_TIPOS_LOJA',
  SET_CANAIS: 'SET_CANAIS',
  SET_CONVENIOS: 'SET_CONVENIOS',
  TIPO_LOJA: 'TIPO_LOJA',
  SET_SEE: 'SET_SEE',
  CLEAN_REGISTER: 'CLEAN_REGISTER',
  SET_CLIENTE_ACTIVE: 'SET_CLIENTE_ACTIVE',
  SET_CLIENTE_CNPJ_ACTIVE: 'SET_CLIENTE_CNPJ_ACTIVE',
  USER_INSTITUICAO: 'USER_INSTITUICAO',
  SET_LOJAS_BANCO: 'SET_LOJAS_BANCO',
  TIPOS_PARCERIAS: 'TIPOS_PARCERIAS',
  SET_PERSONAS: 'SET_PERSONAS',
  CLEAN_PERSONAS: 'CLEAN_PERSONAS',
  SET_NIVEIS_HIERARQUICOS: 'SET_NIVEIS_HIERARQUICOS',
  SET_INSTITUICAO_FINANCEIRA_JURIDICA: 'SET_INSTITUICAO_FINANCEIRA_JURIDICA',
  SET_CENTROS_SELECTED: 'SET_CENTROS_SELECTED',
  SET_USUARIO_FUNCIONARIO: 'SET_USUARIO_FUNCIONARIO',
  SET_INVALID: 'SET_INVALID',
  CLEAN_INVALID: 'CLEAN_INVALID',
  SET_PERFIL_USUARIOS: 'SET_PERFIL_USUARIOS',
  SET_PROFISSOES: 'SET_PROFISSOES',
  SET_ACTIVE_TAB_CPF: 'SET_ACTIVE_TAB_CPF',
  SET_INSTITUICAO: 'SET_INSTITUICAO',
  SET_IS_EDITING: 'SET_IS_EDITING',
  SET_MATRICULA: 'SET_MATRICULA',
  SET_CENTROCUSTO: 'SET_CENTROCUSTO',
  SET_LOJA_BANCO: 'SET_LOJA_BANCO',
  CLEAN_HEADCOUNT_ORCADO: 'CLEAN_HEADCOUNT_ORCADO',
  LOJA_BANCO: 'LOJA_BANCO',
  CLEAN_LOJA_BANCO: 'CLEAN_LOJA_BANCO',
  SET_PESSOA: 'SET_PESSOA',
  SET_FILTER_PESSOA: 'SET_FILTER_PESSOA',
  CLEAR_FILTER_PESSOA: 'CLEAR_FILTER_PESSOA',
  SET_FILTER_PESSOA_APPLIED: 'SET_FILTER_PESSOA_APPLIED',
  CLEAR_FILTER_PESSOA_APPLIED: 'CLEAR_FILTER_PESSOA_APPLIED',
  CLEAN_MATRICULA: 'CLEAN_MATRICULA',
  CLEAN_INSTITUICAO_FINANCEIRA: 'CLEAN_INSTITUICAO_FINANCEIRA',
  CLEAN_INSTITUICAO: 'CLEAN_INSTITUICAO',
  CLEAN_PERSONA: 'CLEAN_PERSONA',
  SET_INSTITUICAO_BANCO: 'SET_INSTITUICAO_BANCO',
  CHANGE_TAB: 'CHANGE_TAB',
  CLEAN_CHANGE_TAB: 'CLEAN_CHANGE_TAB',
  SET_FORNECEDOR: 'SET_FORNECEDOR',
  CLEAN_FORNECEDOR: 'CLEAN_FORNECEDOR',
  SET_INSTITUICOES_BANCARIAS: 'SET_INSTITUICOES_BANCARIAS',
  CLEAN_CLIENTE_CNPJ_RELACIONAMENTO: 'CLEAN_CLIENTE_CNPJ_RELACIONAMENTO',
  CLEAN_LOJA_RELACIONAMENTO: 'CLEAN_LOJA_RELACIONAMENTO',
  CLEAN_INSTITUICAO_RELACIONAMENTO: 'CLEAN_INSTITUICAO_RELACIONAMENTO',
  CLEAN_FORNECEDOR_RELACIONAMENTO: 'CLEAN_FORNECEDOR_RELACIONAMENTO',
  CLEAN_CLIENTE_CPF_RELACIONAMENTO: 'CLEAN_CLIENTE_CPF_RELACIONAMENTO',
  CLEAN_FUNCIONARIO_RELACIONAMENTO: 'CLEAN_FUNCIONARIO_RELACIONAMENTO',
  CLEAN_USER_INSTITUICAO_RELACIONAMENTO: 'CLEAN_USER_INSTITUICAO_RELACIONAMENTO',
  SET_MATRICULAS_SELECT_BOX: 'SET_MATRICULAS_SELECT_BOX',
  SET_ALTERA_HIERARQUIA: 'SET_ALTERA_HIERARQUIA',
  CLEAN_ALTERAR_HIERARQUIA: 'CLEAN_ALTERAR_HIERARQUIA'
 
}

export { STORE_ID, TYPES }
