import React, { useEffect } from 'react';
import { IoIosArrowBack } from "react-icons/io";
import { Card, Col, Form, Row, Button, TabContent, TabPane } from "reactstrap";
import Search from "./tabs/search";
import Register from "./tabs/register";
import { useSelector } from "react-redux";
import * as operacaoController from "../../controllers/operacaoRcController";
import * as operacaoActions from "../../store/modules/operacaoRibercred/actions";
import Breadcrumb from '../../components/breadcrumbs';

const OperacaoRibercred = () => {

    const { item, activeTab, copy, seeRegister, permitions } = useSelector(state => state.operacaoRibercred);

    useEffect(() => {
        if(window.location.search !== ''){
            operacaoActions.setActiveTab('Register')
        }else{
            operacaoActions.setActiveTab('Search')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function saveItem() {
        if (item.id === "") {
            operacaoController.create(item);
        } else {
            await operacaoController.update(item, copy);
        }
    }

    function changeTab(tab) {
        operacaoActions.setActiveTab(tab);
        operacaoActions.cleanRegister();
    }

    function seeRegisterF() {
        if (item.id !== "") {
            operacaoActions.cleanRegister();
        }
        operacaoActions.setSeeRegister(false);
    }

    return (
        <>
                <Col>
                    <Breadcrumb activeTab={activeTab} />
                    <Card>
                        <Form>
                            {
                                activeTab === "Search"
                                    ?
                                    permitions.criar && (
                                        <Col md={12}>
                                            <Row className="pt-2 pb-2">
                                                <Col md={8}>
                                                    <Button
                                                        onClick={() => changeTab("Register")}
                                                        style={{ width: "210px" }}
                                                        className="purpleButton"
                                                    >
                                                        Cadastrar Operação Ribercred</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                    :
                                    <Col className="pt-2 pb-2">
                                        <Button
                                            onClick={() => { changeTab("Search"); seeRegisterF() }}
                                            style={{ width: "127px", color: "white" }}
                                            className="purpleButton"
                                        >
                                            <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar
                                        </Button>
                                        {!seeRegister &&
                                            <Button
                                                onClick={() => saveItem()}
                                                style={{ width: "242px", float: 'right' }}
                                                className="purpleButton"
                                            >
                                                Salvar Cadastro
                                            </Button>}
                                    </Col>
                            }
                        </Form>
                    </Card>
                </Col>
                <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <br />
                        <Search />
                    </TabPane>
                    <TabPane tabId="Register">
                        <br />
                        <Register />
                    </TabPane>
                </TabContent>
        </>
    );
}


export default OperacaoRibercred;