import api from "../util/api";
import * as actions from "../store/modules/aceiteCp/actions";
import { setLoading } from "../store/modules/globalLoading/actions";
import { maskMoeda, maskDate } from "../util/masks";
import { notifyError, notifyWarn } from "../components/message/message";

export async function get(page, pageSize, item) {
  var url = `CampanhaPremiacaoContaPagar/conta-pagar?pagina=${page}&registros=${pageSize}
    `;

  if (item.campanhaId !== "0") {
    url = url + `&campanhaId=${item.campanhaId}`;
  }

  if (item.hierarquiaId !== "0") {
    url = url + `&hierarquiaId=${item.hierarquiaId}`;
  }

  if (item.pessoaId !== "0") {
    url = url + `&personaId=${item.pessoaId}`;
  }

  if (item.centroCustoId !== "0") {
    url = url + `&ccustoId=${item.centroCustoId}`;
  }

  if (item.empresaFuncionarioId !== "0") {
    url = url + `&empresaFuncionarioId=${item.empresaFuncionarioId}`;
  }

  if (item.campanhaTipo !== "0") {
    url = url + `&campanhaTipo=${item.campanhaTipo}`;
  }

  if (item.statusIntegracao !== "0") {
    url = url + `&statusIntegracao=${item.statusIntegracao}`;
  }

  if (item.sistema !== "0") {
    url = url + `&sistema=${item.sistema}`;
  }

  if (item.dataPagamento !== "0") {
    url = url + `&dataPagto=${item.dataPagamento}`;
  }

  if (item.dataIntegracao !== "0") {
    url = url + `&dataIntegracao=${item.dataIntegracao}`;
  }

  if (item.statusFavorecido !== "" && item.statusFavorecido !== "0") {
    const isIntegrated = !!item.statusFavorecido;
    url = url + `&statusFavorecido=${isIntegrated}`;
  }

  setLoading(true);
  const data = await api.get(url);
  setLoading(false);

  if (!data.success) {
    return;
  }

  data.content.registros.forEach((item) => {
    item.percAtingimentoSobreMeta = maskMoeda(
      Number(item.percAtingimentoSobreMeta).toFixed(2)
    );
    item.producaoTotal = maskMoeda(Number(item.producaoTotal).toFixed(2));
    item.receitaTotal = maskMoeda(Number(item.receitaTotal).toFixed(2));
    item.percReceitaTotal = maskMoeda(Number(item.percReceitaTotal).toFixed(2));
    item.valorComissaoPagaOriginal = maskMoeda(
      Number(item.valorComissaoPagaOriginal).toFixed(2)
    );
    item.percComissaoPaga = maskMoeda(Number(item.percComissaoPaga).toFixed(2));
    item.valorProdutividadeOriginal = maskMoeda(
      Number(item.valorProdutividadeOriginal).toFixed(2)
    );
    item.premioPago = maskMoeda(Number(item.premioPago).toFixed(2));
    item.percPremioPago = maskMoeda(Number(item.percPremioPago).toFixed(2));
    item.totalPago = maskMoeda(Number(item.totalPago).toFixed(2));
    item.metaTotal = maskMoeda(Number(item.metaTotal).toFixed(2));
    item.percTotalPagoSobreProducao = maskMoeda(
      Number(item.percTotalPagoSobreProducao).toFixed(2)
    );
    item.percTotalPagoSobreReceita = item.percTotalPagoSobreReceita.toFixed(2);
    item.dataEmissao = maskDate(item.dataEmissao, "DD/MM/YYYY");
    item.dataVencimento = maskDate(item.dataVencimento, "DD/MM/YYYY");
    item.statusFavorecido =
      item.statusFavorecido === 1 ? "Admitido" : "Demitido";
    item.aceito =
      item.statusProdutividadeId === "cdb612ff-bfe9-48b6-a5ea-8fa7d46200b1" &&
      item.statusComissaoPagaId === "cdb612ff-bfe9-48b6-a5ea-8fa7d46200b1" &&
      item.statusSuperacaoId === "cdb612ff-bfe9-48b6-a5ea-8fa7d46200b1"
        ? true
        : false;
    item.isSelected = false;
  });

  actions.setItems(data.content.registros);
  actions.getAllItems({
    quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
    tamanhoPagina: data.content.tamanhoPagina,
  });
}

export async function getCanais() {
  const data = await api.get(`canal`);

  if (!data.success) {
    return;
  }
  actions.setCanais(data.content);
}
export async function getCampanhas(canalId) {
  try {
    let url = "";

    if (canalId) {
      url = `CampanhaPremiacao/campanhas-processadas?canalId=${canalId}`;
    } else {
      actions.setCampanhas([]);
      return;
    }

    const response = await api.get(url);

    if (response.success) {
      actions.setCampanhas(response.content);
    } else {
      console.error("Erro ao obter campanhas:", response.error);
    }
  } catch (error) {
    console.error("Erro ao obter campanhas:", error);
  }
}

export async function getHierarquia(canalId, campanhaId) {
  let url = `Hierarquias/hierarquia-canal/${canalId}`;

  if (campanhaId) {
    url = `CampanhaPremiacaoContaPagar/campanha-hierarquias?campanhaId=${campanhaId}`;
  }

  const data = await api.get(url);

  if (!data.success) {
    return;
  }
  actions.setHierarquias(data.content);
}

export async function getPessoas(pesquisa, page, pageSize) {
  var url = `Pessoa/?pagina=${page}&registros=${pageSize}&papel=2`;

  if (pesquisa.nome !== "") {
    url = `Pessoa/busca-com-nome/${pesquisa.nome}?pagina=${page}&registros=${pageSize}&papel=2`;
  }

  if (pesquisa.cpf !== "") url = url + `&cpfCnpj=${pesquisa.cpf}`;
  if (pesquisa.canalId !== "") url = url + `&canalId=${pesquisa.cpf}`;

  const data = await api.get(url);

  if (!data.success) {
    return;
  }

  actions.setGetAllConsultores({
    quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
  });
  actions.setConsultores(data.content.registros);
}

export async function getCentrosCusto(canalId, campanhaId) {
  try {
    let url = "";

    if (!campanhaId) {
      actions.setCentrosCusto([]);
      return;
    }

    url = `CampanhaPremiacao/${campanhaId}/centros-custo`;

    const response = await api.get(url);

    if (response.success) {
      actions.setCentrosCusto(response.content);
    } else {
      console.error("Erro ao obter centros de custo:", response.error);
    }
  } catch (error) {
    console.error("Erro ao obter centros de custo:", error);
  }
}
export async function getRazaoSocialEmpresa() {
  const data = await api.get(`EmpresaFuncionario`);

  if (!data.success) {
    return;
  }
  actions.setEmpresasFuncionario(data.content);
}

export async function getStatus() {
  const data = await api.get(`contas-pagar/status`);

  if (!data.success) {
    return;
  }
  actions.setStatusIntegracao(data.content);
}

export async function getStatusFavorecido() {
  const data = await api.get(
    `CampanhaPremiacaoContaPagar/conta-pagar/statusFavorecido`
  );

  if (!data.success) {
    return;
  }
  actions.setStatusFavorecido(data.content);
}

export async function getSistemas() {
  const data = await api.get(`contas-pagar/sistemas`);

  if (!data.success) {
    return;
  }
  actions.setSistemas(data.content);
}

export async function aceiteProdutividade(item) {
  if (!item) {
    notifyWarn("Nenhum item foi enviado");
    return false;
  }

  setLoading(true);
  const data = await api.put(
    `CampanhaPremiacaoContaPagar/${item.id}/aceite-produtividade`
  );
  setLoading(false);

  if (!data.success) {
    notifyError(`Linha ${item.descricao} não pode ser aceita`);
    return false;
  }

  return true;
}

export async function aceiteSuperacao(item) {
  if (!item) {
    notifyWarn("Nenhum item foi enviado");
    return false;
  }

  setLoading(true);
  const data = await api.put(
    `CampanhaPremiacaoContaPagar/${item.id}/aceite-superacao`
  );
  setLoading(false);

  if (!data.success) {
    notifyError(`Linha ${item.descricao} não pode ser aceita`);
    return false;
  }

  return true;
}

export async function aceiteComissao(item) {
  if (!item) {
    notifyWarn("Nenhum item foi enviado");
    return false;
  }

  setLoading(true);
  const data = await api.put(
    `CampanhaPremiacaoContaPagar/${item.id}/aceite-comissao`
  );
  setLoading(false);

  if (!data.success) {
    notifyError(`Linha ${item.descricao} não pode ser aceita`);
    return false;
  }

  return true;
}
