import React, { useState } from "react"
import { AiOutlineSearch } from 'react-icons/ai'
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { useSelector } from "react-redux"
import { Switch } from '../../../components/Inputs/Input'
import { maskCNPJ, maskMoeda, maskNumber } from "../../../util/masks"
import ModalFornecedor from '../components/modalFornecedor'
import ModalFavorecido from '../components/modalFavorecido'
import ModalFilial from '../components/modalFilial'
import * as actions from "../../../store/modules/contasReceber/actions"
import { removeAccentsAndUppercase } from "../../../util/masks";

const Register = () => {
    const { item, seeRegister, invalid } = useSelector(state => state.contasReceber);

    function onChangeItem(campo, value) {
        actions.cleanInvalid(campo)
        actions.setItem({ ...item, [campo]: value })
    }

    const [modalFornecedor, setModalFornecedor] = useState(false)
    const [modalFilial, setModalFilial] = useState(false)
    const [modalFavorecido, setModalFavorecido] = useState(false)

    const toggleFornecedor = () => setModalFornecedor(!modalFornecedor)
    const toggleFilial = () => setModalFilial(!modalFilial)
    const toggleFavorecido = () => setModalFavorecido(!modalFavorecido)

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2">
                        <p style={{ fontSize: "20px" }}>Preencha os campos para cadastrar uma conta a receber</p>
                        <p>* Campos obrigatórios</p>
                    </Col>
                    <Col md={12} className="pt-2">
                        <Form>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>Empresa <span style={{ color: 'red' }}>*</span></Label>
                                        <Input
                                            defaultValue={item.empresa}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label>Filial <span style={{ color: 'red' }}>*</span></Label>
                                        <Input
                                            placeholder="Busque uma filial..."
                                            value={item.filial}
                                            onChange={e => onChangeItem('filial', e.target.value)}
                                            invalid={invalid.filial ? 'is-invalid' : ''}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <AiOutlineSearch
                                    style={{ color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer' }}
                                    onClick={() => toggleFilial()}
                                />
                                <Col md={2}>
                                    <Label>CNPJ Filial</Label>
                                    <p style={{ marginTop: '5px' }}>
                                        {item.cnpjFilial === '' ? '__.___.___/____-__' : maskCNPJ(item.cnpjFilial)}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Título</Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.titulo}
                                            onChange={e => onChangeItem('titulo', removeAccentsAndUppercase(e.target.value))}
                                            disabled={seeRegister}
                                            invalid={invalid.titulo ? 'is-invalid' : ''}
                                            maxLength="150"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Tipo do Título</Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.tipoTitulo}
                                            onChange={e => onChangeItem('tipoTitulo', removeAccentsAndUppercase(e.target.value))}
                                            maxLength="150"
                                            invalid={invalid.tipoTitulo ? 'is-invalid' : ''}
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>Fornecedor</Label>
                                        <Input
                                            placeholder="Fornecedor"
                                            value={item.fornecedor}
                                            onChange={e => onChangeItem('fornecedor', e.target.value)}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <AiOutlineSearch
                                    style={{ color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer' }}
                                    onClick={() => toggleFornecedor()}
                                />
                                <Col md={2}>
                                    <Label> CNPJ Fornecedor</Label>
                                    <p style={{ marginTop: '5px' }}>
                                        {item.cnpj === '' ? '__.___.___/____-__' : maskCNPJ(item.cnpj)}
                                    </p>
                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label>Favorecido <span style={{ color: 'red' }}>*</span></Label>
                                        <Input
                                            placeholder="Favorecido"
                                            value={item.favorecido}
                                            onChange={e => onChangeItem('favorecido', e.target.value)}
                                            invalid={invalid.favorecido ? 'is-invalid' : ''}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <AiOutlineSearch
                                    style={{ color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer' }}
                                    onClick={() => toggleFavorecido()}
                                />
                            </Row>
                            <Col className="pl-0 pr-1 mt-3 mb-5">
                                <p style={{ fontSize: '17px', color: 'var(--color-preto)' }}><span className="dot mr-3"></span>Valores</p><hr style={{ border: "1px solid lightgrey", marginLeft: "100px", marginTop: "-25px" }}></hr>
                            </Col>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Valor <span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.valor}
                                            onChange={e => onChangeItem('valor', maskMoeda(e.target.value))}
                                            disabled={seeRegister}
                                            invalid={invalid.valor ? 'is-invalid' : ''}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Forma de Pagamento <span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.formaPagamento}
                                            onChange={e => onChangeItem('formaPagamento', removeAccentsAndUppercase(e.target.value))}
                                            invalid={invalid.formaPagamento ? 'is-invalid' : ''}
                                            maxLength="150"
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Transação
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.transacao}
                                            onChange={e => onChangeItem('transacao', removeAccentsAndUppercase(e.target.value))}
                                            maxLength="150"
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Data de Entrada <span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Input
                                            type="date"
                                            value={item.dataEntrada}
                                            max="9999-12-31"
                                            onChange={e => onChangeItem('dataEntrada', e.target.value)}
                                            invalid={invalid.dataEntrada ? 'is-invalid' : ''}
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Data de Emissão <span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Input
                                            type="date"
                                            value={item.dataEmissao}
                                            max="9999-12-31"
                        
                                            onChange={e => onChangeItem('dataEmissao', e.target.value)}
                                            invalid={invalid.dataEmissao ? 'is-invalid' : ''}
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Vencimento <span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Input
                                            type="date"
                                            value={item.dataVencimento}
                                            max="9999-12-31"
                        
                                            onChange={e => onChangeItem('dataVencimento', e.target.value)}
                                            invalid={invalid.dataVencimento ? 'is-invalid' : ''}
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}>
                                    <Label>Considerar Juros</Label>
                                    <div style={{ marginTop: '-20px', marginLeft: '-10px' }}>
                                        <Switch
                                            className='switch mt-4'
                                            checked={item.consideraJuros}
                                            onChange={() => onChangeItem('consideraJuros', !item.consideraJuros)}
                                            disabled={seeRegister}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {item.consideraJuros ?
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>
                                                Percentual de juros
                                            </Label>
                                            <Input
                                                type="text"
                                                value={item.percentualJuros}
                                                onChange={e => onChangeItem('percentualJuros', maskMoeda(e.target.value))}
                                                invalid={invalid.percentualJuros ? 'is-invalid' : ''}
                                                maxLength="6"
                                                disabled={seeRegister}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>
                                                Percentual de multa
                                            </Label>
                                            <Input
                                                type="text"
                                                invalid={invalid.percentualMulta ? 'is-invalid' : ''}
                                                value={item.percentualMulta}
                                                onChange={e => onChangeItem('percentualMulta', maskMoeda(e.target.value))}
                                                maxLength="6"
                                                disabled={seeRegister}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                :
                                ""
                            }
                            <Col className="pl-0 pr-1 mt-3 mb-5">
                                <p style={{ fontSize: '17px', color: 'var(--color-preto)' }}><span className="dot mr-3"></span>Informações</p><hr style={{ border: "1px solid lightgrey", marginLeft: "135px", marginTop: "-25px" }}></hr>
                            </Col>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Portador
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.portador}
                                            onChange={e => onChangeItem('portador', maskNumber(e.target.value))}
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Carteira
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.carteira}
                                            onChange={e => onChangeItem('carteira', maskNumber(e.target.value))}
                                            maxLength="12"
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>
                                            Observação
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.observacao}
                                            onChange={e => onChangeItem('observacao', removeAccentsAndUppercase(e.target.value))}
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Col className="pl-0 pr-1 mt-3 mb-5">
                                <p style={{ fontSize: '17px', color: 'var(--color-preto)' }}><span className="dot mr-3"></span>Conta</p><hr style={{ border: "1px solid lightgrey", marginLeft: "90px", marginTop: "-25px" }}></hr>
                            </Col>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Agência
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.agencia}
                                            onChange={e => onChangeItem('agencia', maskNumber(e.target.value))}
                                            maxLength="15"
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Conta
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.conta}
                                            onChange={e => onChangeItem('conta', maskNumber(e.target.value))}
                                            maxLength="6"
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Conta financeira
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.contaFinanceira}
                                            onChange={e => onChangeItem('contaFinanceira', maskNumber(e.target.value))}
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Conta contábil
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.contaContabil}
                                            onChange={e => onChangeItem('contaContabil', maskNumber(e.target.value))}
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Col className="pl-0 pr-1 mt-3 mb-5">
                                <p style={{ fontSize: '17px', color: 'var(--color-preto)' }}><span className="dot mr-3"></span>Informações do projeto</p><hr style={{ border: "1px solid lightgrey", marginLeft: "220px", marginTop: "-25px" }}></hr>
                            </Col>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Projeto
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.projeto}
                                            onChange={e => onChangeItem('projeto', removeAccentsAndUppercase(e.target.value))}
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Orçamento
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.orcamento}
                                            onChange={e => onChangeItem('orcamento', maskNumber(e.target.value))}
                                            disabled={seeRegister}
                                            maxLength="12"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Col className="pl-0 pr-1 mt-3 mb-5">
                                <p style={{ fontSize: '17px', color: 'var(--color-preto)' }}><span className="dot mr-3"></span>Outras informações</p><hr style={{ border: "1px solid lightgrey", marginLeft: "220px", marginTop: "-25px" }}></hr>
                            </Col>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Percentual de rateio
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.percentualRateio}
                                            onChange={e => onChangeItem('percentualRateio', maskNumber(e.target.value))}
                                            maxLength="6"
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Rateio
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.rateio}
                                            onChange={e => onChangeItem('rateio', maskNumber(e.target.value))}
                                            disabled={seeRegister}
                                            maxLength="12"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>
                                            Centro de custo
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.centroCusto}
                                            onChange={e => onChangeItem('centroCusto', e.target.value)}
                                            disabled={seeRegister}
                                            maxLength="150"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Valor rateio centro de custo</Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.valorRateioCentroCusto}
                                            onChange={e => onChangeItem('valorRateioCentroCusto', maskNumber(e.target.value))}
                                            disabled={seeRegister}
                                            maxLength="12"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Percentual centro de custo</Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.percentualCentroCusto}
                                            onChange={e => onChangeItem('percentualCentroCusto', maskNumber(e.target.value))}
                                            maxLength="6"
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Card>
            </Col>
            {
                <ModalFilial
                    modalPessoa={modalFilial}
                    toggle={toggleFilial} />
            }
            {
                <ModalFornecedor
                    modalPessoa={modalFornecedor}
                    toggle={toggleFornecedor}
                    onChange={actions.setItem}
                    dados={item}
                />
            }
            {
                <ModalFavorecido
                    modalPessoa={modalFavorecido}
                    toggle={toggleFavorecido} />
            }
        </>
    );
}

export default Register;