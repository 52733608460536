import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import ReactPaginate from "react-paginate";
import { BsArrowDown, BsArrowUp, BsArrowDownUp } from 'react-icons/bs';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Modal, ModalBody, Col, Input, Row, Navbar, Table } from "reactstrap";
import {  AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineMinusCircle } from 'react-icons/ai';

import { COLUMNS_MODAL } from "../constants/columns";

import StepBar from '../../formalizacaoLoja/components/StepBar';


export default function ModalDetalhamento({modalDetalhamento, toggle}){
    const {modal} = useSelector(state => state.formalizacaoMatriz);
    const columns = useMemo(() => COLUMNS_MODAL, []);
    const data = modal.propostas
  
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const { pageSize } = state;
    const [rows, setRows] = useState(0);
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    useEffect(() => {
        setRows(page.length);
    }, [page])

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
    }


    function handleInitialPage() {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }

    const pageCount = Math.ceil(data.length/pageSize);
    return(
        <Modal
            className="text-center"
            isOpen={modalDetalhamento}
            toggle={toggle}
            backdrop={true}
            size="lg"
            style={{maxWidth: '1600px', width: '80%'}}
        >
            <Navbar expand="md" style={{ width: "100%", height: "30px",margin: '20px 0 10px 20px'}}>
                <h5>{`Detalhamento de Borderô nº ${modal.numeroBordero}`}</h5>
            </Navbar>

            <ModalBody style={{margin:'0 0 0px 20px'}}>
                    {modal.steps && (
                        <Row>
                            <Col md={12}>
                                <StepBar steps={modal.steps}/>
                            </Col>
                        </Row>
                    )}
                    <Table striped  style={{marginTop: '45px'}} {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th style={{background: 'transparent', border: 'none', fontWeight: 'bold'}}>
                                            {column.render("Header")}
                                            <span className="float-right">
                                                {column.isSorted ?
                                                    (column.isSortedDesc ?
                                                        <BsArrowDown
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />
                                                        :
                                                        <BsArrowUp
                                                            id="OrdenarAsc"
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />)
                                                    :
                                                    <BsArrowDownUp
                                                        {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                    />}
                                            </span>
                                        </th>
                                    ))}                                 
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <tr className="text-center" {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            if(cell.column.Header === 'Status de envio'){
                                                return(                                               
                                                    <td style={{border: 'none'}} {...cell.getCellProps()}>
                                                        {cell.row.original.statusEnvio === 'Finalizada' ? 
                                                            <p style={{margin: '0', fontSize: '12px'}}>
                                                                <AiOutlineCheckCircle 
                                                                    style={{marginRight: '10px', color: 'var(--color-verde-claro)', fontSize: '20px'}}
                                                                />
                                                                Finalizada
                                                            </p> 
                                                        : cell.row.original.statusEnvio === 'Reabertura' ? 
                                                            <p style={{margin: '0', fontSize: '12px'}}>
                                                                <AiOutlineCloseCircle 
                                                                    style={{marginRight: '10px', color: 'var(--color-laranja)', fontSize: '20px'}}
                                                                />
                                                                Reabertura
                                                            </p> 
                                                            : 
                                                            <p style={{margin: '0', fontSize: '12px'}}>
                                                                <AiOutlineMinusCircle 
                                                                    style={{marginRight: '10px', color: '#81888A', fontSize: '20px'}}
                                                                />
                                                                Pendente
                                                            </p>
                                                        }
                                                    </td>                                                )
                                            }else{
                                                return(
                                                    <td style={{border: 'none'}} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                )
                                            }     
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <Row className="pt-4">
                            <Col md={2}>
                                <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {rows} de {data.length}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { setPageSize(Number(e.target.value)); handleInitialPage() }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>
            </ModalBody>
        </Modal>
    )
}