const STORE_ID = "DASHBOARD_RECEITA";

const TYPES = {
    SET_BIG_NUMBERS: "SET_BIG_NUMBERS",
    SET_GRAFICO_PENDENCIA: "SET_GRAFICO_PENDENCIA",
    SET_GRAFICO_FORMALIZACAO: "SET_GRAFICO_FORMALIZACAO",
    SET_TABELA_FORMALIZACAO: "SET_TABELA_FORMALIZACAO",
    SET_FILTROS: "SET_FILTROS",
    SET_INSTITUICOES: "SET_INSTITUICOES",
    SET_LOJAS: "SET_LOJAS",
    SET_PRODUTOS: "SET_PRODUTOS",
    SET_MODAL_LOJAS: "SET_MODAL_LOJAS",
    SET_GET_ALL_LOJAS: "SET_GET_ALL_LOJAS",
    SET_MODAL_CONSULTORES: "SET_MODAL_CONSULTORES",
    SET_GET_ALL_CONSULTORES: "SET_GET_ALL_CONSULTORES",
    SET_TIPOS_OPERACAO: "SET_TIPOS_OPERACAO",
    SET_PARCERIAS: "SET_PARCERIAS",
    SET_CONVENIOS: "SET_CONVENIOS",
    SET_GRUPOS: "SET_GRUPOS",
    SET_CONSULTORES: "SET_CONSULTORES",
    CLEAN_FILTROS: "CLEAN_FILTROS",
    SET_LOADING_LOJAS: "SET_LOADING_LOJAS",
    SET_LOADING_CONSULTORES: "SET_LOADING_CONSULTORES"
}

export { STORE_ID, TYPES }