const STORE_ID = "CAMPANHA";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    ITEMS: "ITEMS",
    SET_ITEM: 'SET_ITEM',
    SET_REGRA_QUALIDADE: 'SET_REGRA_QUALIDADE',
    TIPOS_FUNCIONARIO: 'TIPOS_FUNCIONARIO',
    REGRA_QUALIDADE: 'REGRA_QUALIDADE',
    CLEAN_REGRA_QUALIDADE: 'CLEAN_REGRA_QUALIDADE',
    SET_REGRA_PRODUTIVIDADE: 'SET_REGRA_PRODUTIVIDADE',
    REGRA_PRODUTIVIDADE: 'REGRA_PRODUTIVIDADE',
    CLEAN_REGRA_PRODUTIVIDADE: 'CLEAN_REGRA_PRODUTIVIDADE',
    SET_META_PRODUTIVIDADE: 'SET_META_PRODUTIVIDADE',
    META_PRODUTIVIDADE: 'META_PRODUTIVIDADE',
    CLEAN_META_PRODUTIVIDADE: "CLEAN_META_PRODUTIVIDADE",
    SET_META_SUPERACAO: "SET_META_SUPERACAO",
    SET_REGRA_REPASSE: "SET_REGRA_REPASSE",
    REGRA_REPASSE: "REGRA_REPASSE",
    CLEAN_REGRA_REPASSE: "CLEAN_REGRA_REPASSE",
    META_SUPERACAO: "META_SUPERACAO",
    CLEAN_META_SUPERACAO: "CLEAN_META_SUPERACAO",
    SET_TAB: 'SET_TAB',
    ITEM: "ITEM",
    INVALID_ITEM: "INVALID_ITEM",
    CLEAN_INVALID_ITEM: "CLEAN_INVALID_ITEM",
    CLEAN_INVALIDS: "CLEAN_INVALIDS",
    ITEM_COPY: "ITEM_COPY",
    PERIODICIDADES: "PERIODICIDADES",
    TIPOS_COMISSAO: "TIPOS_COMISSAO",
    TIPOS_COMISSAO_PRODUTO: "TIPOS_COMISSAO_PRODUTO",
    BANCOS: "BANCOS",
    CONVENIOS: "CONVENIOS",
    CENTROS_CUSTO: "CENTROS_CUSTO",
    TIPOS_PENDENCIA: "TIPOS_PENDENCIA",
    PERIODOS: "PERIODOS",
    HIERARQUIAS: "HIERARQUIAS",
    HIERARQUIAS_REGRA: "HIERARQUIAS_REGRA",
    HIERARQUIAS_PRODUTIVIDADE: "HIERARQUIAS_PRODUTIVIDADE",
    GRUPOS: "GRUPOS",
    PRODUTOS: "PRODUTOS",
    TIPOS_OPERACAO: "TIPOS_OPERACAO",
    TIPOS_PARCERIA: "TIPOS_PARCERIA",
    CANAIS: "CANAIS",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    SEARCH: "SEARCH",
    CLEAN_SEARCH: "CLEAN_SEARCH",
    GET_ALL_ITEMS: "GET_ALL_ITEMS"
}

export { STORE_ID, TYPES }