import {STORE_ID, TYPES} from "./types"

const INITIAL_STATE = {
    enderecos: [],
    tiposEnderecos: [],
    estados: [],
    seeRegister: false,
    isDeleting: false,
    isEditando: false,
    endereco: {
        id: null,
        tipoEnderecoId: "0",
        principal: false,
        cep: "",
        estadoId: "0",
        cidade: "",
        bairro: "",
        logradouro: "",
        numero: "",
        complemento: "",
    }
}

export default function personasEndereco(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {

                case TYPES.ENDERECOS:
                    return {...state, enderecos: action.value}
                case TYPES.ENDERECO:
                    return {...state, endereco: action.value}

                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state,
                        enderecos: [],
                        endereco: INITIAL_STATE.endereco
                    }

                case TYPES.SET_SEE:
                    return {...state, seeRegister: action.value}

                case TYPES.GET_TIPOS_ENDERECO:
                    return {...state, tiposEnderecos: action.value}

                case TYPES.SET_ESTADOS:
                    return {...state, estados: action.value}

                case TYPES.SET_ENDERECO:
                    return {...state, endereco: action.value}

                case TYPES.CLEAN_ENDERECO:
                    return {...state, endereco: INITIAL_STATE.endereco}

                case TYPES.SET_IS_DELETING:
                    return {...state, isDeleting: action.value}

                case TYPES.SET_IS_EDITANDO:
                    return {...state, isEditando: action.value}

                default:
                    return {...state}
            }
        default:
            return {...state}
    }
}