import { STORE_ID, TYPES } from './types'

const INITIAL_STATE = {
    pesquisar: {
        descricao: '',
        codInstituicao: '0',
        codProduto: '0',
        produtoRiberId: '0',
        codConvenio: '0',
        codOperacao: '0',
        dataInicioVigencia: '',
        dataFimVigencia: '',
        statusTabela: false
    },
    registros: {
        id: '',
        descricao: '',
        codigoTabela: '',
        bancoId: '0',
        produtoBancoId: '0',
        convenioBancoId: '0',
        tipoOperacaoBancoId: '0',
        dtInicioVig: '',
        dtFimVig: '',
        tabelaComissaoEventos: []
    },
    eventos: [],
    invalid: {
        descricao: false,
        bancoId: false,
        produtoBancoId: false,
        convenioBancoId: false,
        tipoOperacaoBancoId: false,
        dtInicioVig: false,
        dtFimVig: false,
        codigoTabela: false
    },
    getAllItems: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'

    },
    copia: {},
    comissoes: [],
    instituicoes: [],
    produtosInstituicao: [],
    conveniosInstituicao: [],
    tiposOperacaoIntituicao: [],
    produtos: [],
    produtosRiber: [],
    convenios: [],
    tiposOperacao: [],
    tiposComissao: [],
    tiposComissaoBanco: [],
    conveniosCadastro: [],
    produtosCadastro: [],
    operacoesCadastro: [],
    activeTab: 'Search',
    seeRegister: false,
    permitions: {
        criar: false,
        deletar: false,
    },
    cleanEventos: false
}

export default function centroDeCusto(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.PESQUISAR:
                    return { ...state, pesquisar: action.value }
                case TYPES.SET_ALL_ITEMS:
                    return { ...state, getAllItems: action.value }
                case TYPES.REGISTROS:
                    return { ...state, registros: action.value }
                case TYPES.EVENTOS:
                    return { ...state, eventos: action.value }
                case TYPES.COPIA:
                    return { ...state, copia: action.value }
                case TYPES.INSTITUICOES:
                    return { ...state, instituicoes: action.value }
                case TYPES.SET_PRODUTOS_INSTITUICAO:
                    return { ...state, produtosInstituicao: action.value }
                case TYPES.SET_CONVENIOS_INSTITUICAO:
                    return { ...state, conveniosInstituicao: action.value }
                case TYPES.SET_TIPO_INSTITUICAO:
                    return { ...state, tiposOperacaoIntituicao: action.value }
                case TYPES.PRODUTOS:
                    return { ...state, produtos: action.value }
                case TYPES.PRODUTOS_RIBER:
                    return { ...state, produtosRiber: action.value }
                case TYPES.CONVENIOS:
                    return { ...state, convenios: action.value }
                case TYPES.COMISSOES:
                    return { ...state, comissoes: action.value }
                case TYPES.TIPOS_OPERACAO:
                    return { ...state, tiposOperacao: action.value }
                case TYPES.TIPOS_COMISSAO:
                    return { ...state, tiposComissao: action.value }
                case TYPES.TIPOS_COMISSAO_BANCO:
                    return { ...state, tiposComissaoBanco: action.value }
                case TYPES.CLEAN_TIPOS_COMISSAO_BANCO:
                    return { ...state, tiposComissaoBanco: INITIAL_STATE.tiposComissaoBanco }
                case TYPES.INVALID:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.CLEAN_SEARCH:
                    return { ...state, pesquisar: INITIAL_STATE.pesquisar, comissoes: [] }
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state, registros: {
                            id: '',
                            descricao: '',
                            bancoId: '0',
                            produtoBancoId: '0',
                            convenioBancoId: '0',
                            tipoOperacaoBancoId: '0',
                            codigoTabela: '',
                            dtInicioVig: '',
                            dtFimVig: '',
                            tabelaComissaoEventos: []
                        },
                        eventos: INITIAL_STATE.eventos,
                        invalid: INITIAL_STATE.invalid
                    }
                case TYPES.CLEAN_EVENTOS:
                    return { ...state, cleanEventos: action.value }
                case TYPES.PRODUTOS_CADASTRO:
                    return { ...state, produtosCadastro: action.value }
                case TYPES.CONVENIOS_CADASTRO:
                    return { ...state, conveniosCadastro: action.value }
                case TYPES.OPERACOES_CADASTRO:
                    return { ...state, operacoesCadastro: action.value }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}
