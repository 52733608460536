import {STORE_ID, TYPES} from "./types";

const INITIAL_STATE = {
    instituicaoFinanceira: {
        cdClienteW3erp: ''
    },
}
export default function instituicaoFinanceira(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_INSTITUICOES_FINANCEIRAS_COD_W3ERP:
                    return {...state, instituicaoFinanceira: action.value}
                case TYPES.CLEAN_INSTITUICOES_FINANCEIRAS_COD_W3ERP:
                    return {...state, instituicaoFinanceira: INITIAL_STATE.instituicaoFinanceira.cdClienteW3erp}
                default:
                    return {...state}
            }
        default:
            return {...state}
    }
}