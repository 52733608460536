import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Button,
  Form,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Toast,
} from "reactstrap";
import { maskMoeda } from "../../../../../../util/masks";
import { Select } from "../../../../../../components/Inputs/Input";
import { notifyWarn } from "../../../../../../components/message/message";
import history from "../../../../../../util/history";
import TableMatricula from "./subComponents/TableMatricula";
import * as papeisController from "../../../../../../controllers/personasController/papeisController";
import * as AiIcons from "react-icons/ai";
import * as papeisActions from "../../../../../../store/modules/personas/papeis_store/actions";
import * as perfilActions from "../../../../../../store/modules/perfilCliente/actions";
import * as personasController from "../../../../../../controllers/personasController/personasController";
import * as contaCorrenteController from "../../../../../../controllers/personasController/contaCorrenteController";
import { notifySuccess } from "../../../../../../components/message/message";
import "../../../../styles/papeis.css";

const ClienteCPF = () => {
  const {
    matriculasSelected,
    matricula,
    clienteCPF,
    seeRegister,
    invalid,
    isEditing,
    perfilUsuarios,
    profissoes,
  } = useSelector((state) => state.personasPapeis);
  const { informacao, isJuridica } = useSelector(
    (state) => state.personasInformacoes
  );

  const [modalConfirmacao, setModalConfirmacao] = useState(false);
  const [rendaField, setRendaField] = useState("");
  const [showButtons, setShowButtons] = useState(true);
  const [firstChange, setFirstChange] = useState(true);
  const { setIsEditingMatricula } = papeisActions;

  useEffect(() => {
    papeisController.getPerfisCliente();
    papeisController.getProfissoes();
  }, []);

  const onChangeCliente = (value, field) => {
    papeisActions.cleanInvalid(field);
    papeisActions.clienteCpf({ ...clienteCPF, [field]: value });
  };

  const onChangeItem = (value, field) => {
    papeisActions.setMatricula({ ...matricula, [field]: value });
  };

  function onCloseModal() {
    setModalConfirmacao(false);
  }

  function onOpenModal() {
    setModalConfirmacao(true);
  }

  const confirmModalAction = () => {
    setShowButtons(true);
    notifySuccess("Modo Edição Ativada");
    onCloseModal();
  };

  function cancelModalEditAction() {
    onCloseModal();
    setIsEditingMatricula(false);
  }

  const changeView = () => {
    history.push("/perfildocliente");
    perfilActions.setActivePersona(true);
    perfilActions.setActiveTab("Register");
  };

  const handleCancelAction = () => {
    papeisActions.setIsEditingMatricula(false);
    papeisActions.cleanMatricula();
  };

  const salveCliente = async (e) => {
    e.preventDefault();
    if (matricula.clientePerfilId === "0" || matricula.numero === "") {
      notifyWarn("Preencha todos os campos obrigatórios");
    } else {
      await personasController.salveCliente(
        informacao.id,
        isJuridica,
        clienteCPF,
        matricula
      );
      await contaCorrenteController.buscaMatriculas(informacao.id);
    }
  };

  const onChangeRendaIndividual = (value, field) => {
    if (firstChange) {
      onOpenModal();
      setFirstChange(false);
    }
    papeisActions.setMatricula({ ...matricula, [field]: value });
  };

  let date = new Date().toISOString().substr(0, 10);
  let dateBR = date.split("-").reverse().join("/");

  return (
    <>
      <div style={{ marginTop: "25px" }}>
        <Form>
          <Col>
            <Row>
              <Col md={5}>
                <Label>Profissão</Label>
                <Select
                  first="Selecione"
                  disabled={seeRegister}
                  onChange={(e) => onChangeCliente(e.value, "profissao")}
                  options={profissoes}
                  select={{ value: "id", label: "titulo" }}
                  value={{ value: clienteCPF.profissao }}
                  isSearchable={true}
                />
              </Col>
            </Row>
          </Col>
        </Form>
        <Card
          className="p-3 m-4"
          style={{ backgroundColor: "var(--color-quase-branco)" }}
        >
          <Row style={{ margin: "15px 0 5px 0" }}>
            <Col md={4}>
              <FormGroup>
                <Label for="matricula">Matricula*</Label>
                <Input
                  value={matricula.numero}
                  disabled={seeRegister}
                  onChange={(e) => onChangeItem(e.target.value, "numero")}
                  id="matricula"
                  maxLength="150"
                  type="text"
                  placeholder="Digite aqui..."
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <Label>Renda Individual</Label>
              <Input
                value={matricula.valorRendaIndividual}
                disabled={seeRegister}
                id="valorRendaIndividual"
                maxLength="16"
                onChange={(e) =>
                  onChangeRendaIndividual(
                    e.target.value,
                    "valorRendaIndividual"
                  )
                }
                type="text"
                placeholder="R$ 00,00"
              />
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Perfil do Cliente*</Label>
                <Select
                  first="Selecione"
                  disabled={seeRegister}
                  onChange={(e) => onChangeItem(e.value, "clientePerfilId")}
                  options={perfilUsuarios}
                  select={{ value: "id", label: "descricao" }}
                  value={{ value: matricula.clientePerfilId }}
                  isSearchable={true}
                />
              </FormGroup>
            </Col>
            {/*fazer if para se for edição mostar os botoes de salvar e cancelar senão mostrar o de adicionar matricula */}
            <Col md={3} className="d-flex align-items-center">
              {isEditing && (
                <div className="d-flex">
                  <Button
                    onClick={(e) => salveCliente(e)}
                    style={{
                      marginRight: "10px",
                      fontSize: "1.1rem",
                      cursor: "pointer",
                      color: "#ffffff",
                      backgroundColor: "green",
                      borderRadius: "8px",
                      width: "100px",
                      textAlign: "center",
                    }}
                  >
                    Salvar
                  </Button>
                  <Button
                    onClick={handleCancelAction}
                    style={{
                      fontSize: "1.1rem",
                      cursor: "pointer",
                      color: "#ffffff",
                      backgroundColor: "gray",
                      borderRadius: "8px",
                      width: "100px",
                      textAlign: "center",
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
              )}
              {!isEditing && (
                <Label
                  onClick={salveCliente}
                  style={{
                    fontSize: "1.1rem",
                    marginRight: "7px",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  <AiIcons.AiOutlinePlusCircle
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "7px",
                      color: "green",
                      cursor: "pointer",
                    }}
                  />
                  Adicionar Matrícula{" "}
                </Label>
              )}
            </Col>

            <Button
              style={{
                marginTop: "10px",
                marginBottom: "15px",
                width: "160px",
                background: "var(--color-branco)",
                borderColor: "var(--color-verde)",
                color: "var(--color-verde)",
                borderRadius: "7px",
                marginLeft: "20px",
              }}
              disabled={seeRegister}
              onClick={() => changeView()}
            >
              Novo Perfil do Cliente{" "}
            </Button>
          </Row>
          <Row className="justify-content-center"></Row>
        </Card>
        {matriculasSelected.length > 0 && (
          <TableMatricula matriculas={matriculasSelected} />
        )}
        <Col>
          <Row>
            <Col>
              <p style={{ color: "var(--color-cinza-medio)" }}>
                Data do cadastro {dateBR}
              </p>
            </Col>
          </Row>
        </Col>
      </div>
      <Modal
        isOpen={modalConfirmacao}
        className="justify-content-center"
        style={{ width: "350px" }}
      >
        <ModalHeader style={{ border: "none" }}>
          Confirmar Edição de Renda
        </ModalHeader>
        <ModalBody
          style={{ border: "none", alignItems: "center" }}
          className="justify-content-center"
        >
          Tem certeza que deseja editar a renda individual?
        </ModalBody>
        <ModalFooter
          style={{ border: "none" }}
          className="justify-content-center"
        >
          <Button
            color="success"
            onClick={confirmModalAction}
            style={{ width: "100px" }}
          >
            Confirmar
          </Button>
          <Button
            color="danger"
            onClick={cancelModalEditAction}
            style={{ width: "100px" }}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ClienteCPF;
