import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function search(value) {
    dispatch({
        type: TYPES.SEARCH,
        value
    });
}

export function setModalFornecedor(campo, value) {
    dispatch({
        type: TYPES.SET_MODAL_FORNECEDOR,
        campo,
        value
    })
}

export function setModalFilial(campo, value) {
    dispatch({
        type: TYPES.SET_MODAL_FILIAL,
        campo,
        value
    })
}

export function setAllItens(value) {
    dispatch({
        type: TYPES.SET_ALL_ITENS,
        value
    })
}

export function setAllItensFavorecido(value) {
    dispatch({
        type: TYPES.SET_ALL_ITENS_FAVORECIDO,
        value
    })
}
export function setAllItensFilial(value) {
    dispatch({
        type: TYPES.SET_ALL_ITENS_FILIAL,
        value
    })
}
export function setAllItensFornecedor(value) {
    dispatch({
        type: TYPES.SET_ALL_ITENS_FORNECEDOR,
        value
    })
}

export function setModalFavorecido(campo, value) {
    dispatch({
        type: TYPES.SET_MODAL_FAVORECIDO,
        campo,
        value
    })
}

export function setItem(value) {
    dispatch({
        type: TYPES.SET_ITEM,
        value
    })
}

export function copy(value) {
    dispatch({
        type: TYPES.COPY,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID,
        field
    });
}

export function getItems(value) {
    dispatch({
        type: TYPES.ITEMS,
        value
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID,
        field
    });
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function cleanSearch() {
    dispatch({
        type: TYPES.CLEAN_SEARCH
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function cleanAllSearch() {
    dispatch({
        type: TYPES.CLEAN_ALL_SEARCH
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function setEmpresas(value) {
    dispatch({
        type: TYPES.SET_EMPRESAS,
        value
    })

}
export function setFiliais(value) {
    dispatch({
        type: TYPES.SET_FILIAIS,
        value
    })

}
export function setFornecedores(value) {
    dispatch({
        type: TYPES.SET_FORNECEDORES,
        value
    })

}
export function setFavorecidos(value) {
    dispatch({
        type: TYPES.SET_FAVORECIDOS,
        value
    })

}
export function setContas(value) {
    dispatch({
        type: TYPES.SET_CONTAS,
        value
    })

}
export function setLoadingFilial(value) {
    dispatch({
        type: TYPES.SET_LOADING_FILIAL,
        value
    })

}
export function setLoadingFavorecido(value) {
    dispatch({
        type: TYPES.SET_LOADING_FAVORECIDO,
        value
    })

}
export function setLoadingFornecedor(value) {
    dispatch({
        type: TYPES.SET_LOADING_FORNECEDOR,
        value
    })

}
