import { STORE_ID, TYPES } from "./types";
import store from "../../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setFiltros(campo, value) {
    dispatch({
        type: TYPES.SET_FILTROS,
        campo,
        value
    });
}

export function setBigNumbers(value) {
    dispatch({
        type: TYPES.SET_BIG_NUMBERS,
        value
    });
}

export function setTabelaBanco(value) {
    dispatch({
        type: TYPES.SET_TABELA_BANCO,
        value
    });
}

export function setTabelaCanal(value) {
    dispatch({
        type: TYPES.SET_TABELA_CANAL,
        value
    });
}

export function setTabelaCentroCusto(value) {
    dispatch({
        type: TYPES.SET_TABELA_CENTRO_CUSTO,
        value
    });
}

export function setTabelaProduto(value) {
    dispatch({
        type: TYPES.SET_TABELA_PRODUTO,
        value
    });
}

export function setLojas(value) {
    dispatch({
        type: TYPES.SET_LOJAS,
        value
    });
}

export function setGetAllLojas(value) {
    dispatch({
        type: TYPES.SET_GET_ALL_LOJAS,
        value
    });
}

export function setModalLoja(campo, value) {
    dispatch({
        type: TYPES.SET_MODAL_LOJA,
        campo,
        value
    });
}

export function setCentrosCusto(value) {
    dispatch({
        type: TYPES.SET_CENTROS_CUSTO,
        value
    })
}

export function setBancos(value) {
    dispatch({
        type: TYPES.SET_BANCOS,
        value
    })
}

export function setTiposComissao(value) {
    dispatch({
        type: TYPES.SET_TIPOS_COMISSAO,
        value
    })
}

export function setProdutos(value) {
    dispatch({
        type: TYPES.SET_PRODUTOS,
        value
    })
}

export function setConvenios(value) {
    dispatch({
        type: TYPES.SET_CONVENIOS,
        value
    })
}

export function setCanais(value) {
    dispatch({
        type: TYPES.SET_CANAIS,
        value
    })
}

export function setGrupos(value) {
    dispatch({
        type: TYPES.SET_GRUPOS,
        value
    })
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function cleanFiltros() {
    dispatch({
        type: TYPES.CLEAN_SEARCH
    })
}

export function setLoadingLojas(value) {
    dispatch({
        type: TYPES.SET_LOADING_LOJAS,
        value
    });
}