import {
  maskDate,
  maskMoeda,
  maskMoeda4,
  maskMoeda4new,
} from "../../../util/masks";

export const COLUMNS = [
  {
    Header: "Descrição",
    accessor: "descricao",
  },
  {
    Header: "Período",
    accessor: "periodo",
  },
  {
    Header: "Status",
    accessor: "statusCampanha",
  },
];

export const COLUMNS_REGRA_PRODUTIVIDADE = [
  {
    Header: "Descrição",
    accessor: "descricao",
  },
  {
    Header: "Instituição financeira",
    accessor: "instituição",
  },
  {
    Header: "Convênio",
    accessor: "convenio",
  },
  {
    Header: "Tipo Operação",
    accessor: "tipoOperacao",
  },
  {
    Header: "Tipo Parceria",
    accessor: "tipoParceria",
  },
  {
    Header: "Produto",
    accessor: "produto",
  },
  {
    Header: "Centro de custo",
    accessor: "centroCusto",
  },
  {
    Header: "Meta Global",
    accessor: "metaGlobal",
    Cell: ({ value }) => {
      return value ? "Sim" : "Não";
    },
  },
  {
    Header: "Critério de Elegibilidade",
    accessor: "metaPrincipal",
    Cell: ({ value }) => {
      return value ? "Sim" : "Não";
    },
  },
];

export const COLUMNS_REGRA_QUALIDADE = [
  {
    Header: "Tipo de pendência",
    accessor: "tipoPendencia",
  },
  {
    Header: "Período",
    accessor: "periodo",
  },
  {
    Header: "Valor máximo %",
    accessor: "valorMaximo",
  },
];

export const COLUMNS_AGENDA = [
  {
    Header: "Data Início",
    accessor: "dataInicial",
    Cell: ({ value }) => {
      return value ? maskDate(value, "DD/MM/YYYY") : "";
    },
  },
  {
    Header: "Data Fim",
    accessor: "dataFim",
    Cell: ({ value }) => {
      return value ? maskDate(value, "DD/MM/YYYY") : "";
    },
  },
  {
    Header: "Data Apuração",
    accessor: "dataExecucao",
    Cell: ({ value }) => {
      return value ? maskDate(value, "DD/MM/YYYY") : "";
    },
  },
  {
    Header: "Data Pagamento",
    accessor: "dataPagamento",
    Cell: ({ value }) => {
      return value ? maskDate(value, "DD/MM/YYYY") : "";
    },
  },
];

export const COLUMNS_SUPERACAO = [
  {
    Header: "Nível",
    accessor: "descricao",
  },
  {
    Header: "Medida de Premiação",
    accessor: "medidaPremiacaoSuperacao",
  },
  {
    Header: "Meta",
    accessor: "valorMeta",
    Cell: ({ value }) => {
      return maskMoeda(Number(value).toFixed(2));
    },
  },
  {
    Header: "Prêmio de superação",
    accessor: "valorPremio",
    Cell: ({ row }) => {
      const medidaPremiacao = row.original.medidaPremiacaoSuperacaoId;
      const valorPremio = row.original.valorPremio;
      if (medidaPremiacao === "3b256d42-40a0-4bb7-9f5b-93f3deb148eb") {
        return `${valorPremio?.toFixed(4)}%`;
      }
      if (medidaPremiacao === "352e8dfc-ce3e-4f3d-8f66-f3d6847dae07") {
        return `R$ ${maskMoeda(valorPremio?.toFixed(2))}`;
      } else {
        return valorPremio;
      }
    },
  },
];

export const COLUMNS_REGRA_REPASSE = [
  {
    Header: "Tipo Comissão",
    accessor: "tipoComissao",
  },
  {
    Header: "PMT",
    accessor: "pmt",
  },
  {
    Header: "% sobre % recebido",
    accessor: "valor",
    Cell: ({ value }) => {
      return maskMoeda(Number(value).toFixed(2));
    },
  },
];
