import * as actions from '../../store/modules/dashboard/previsao/actions'
import { setLoading } from '../../store/modules/globalLoading/actions';
import api from '../../util/api';
import { maskMoeda, removeMaskCNPJ } from '../../util/masks';
export async function get(filtros){
    setLoading(true);
    var isBigNumbersSucceeded = await getBigNumbers(filtros)

    var isTabelaCentroCustoSucceeded = await getTabelaCentroCusto(filtros)
    
    var isTabelaBancoSucceeded = await getTabelaBanco(filtros)

    var isTabelaProdutoSucceeded = await getTabelaProduto(filtros)
    
    var isTabelaCanalSucceeded = await getTabelaCanal(filtros) 
    setLoading(false);

    if( isBigNumbersSucceeded
         && isTabelaBancoSucceeded
         && isTabelaCanalSucceeded
         && isTabelaCentroCustoSucceeded
         && isTabelaProdutoSucceeded){
        return true
    }
}

export async function getBigNumbers(filtros){
    var url = `dash-provisionamento/big-number?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId }`
    }

    if(filtros.bancoId !== '0'){
        url = url + `&bancoId=${filtros.bancoId}`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.produtoId !== '0'){
        url = url + `&produtoId=${filtros.produtoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    if(data.content){
        actions.setBigNumbers(data.content);
    }
    return true
}


export async function getTabelaBanco(filtros){
    var url = `dash-provisionamento-tabela-banco?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId }`
    }

    if(filtros.bancoId !== '0'){
        url = url + `&bancoId=${filtros.bancoId}`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.produtoId !== '0'){
        url = url + `&produtoId=${filtros.produtoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.produtos = []
    })
    actions.setTabelaBanco(data.content);
    return true
}

export async function getTabelaBancoProduto(filtros, bancoId, tabela){
    var url = `dash-provisionamento-tabela-banco/produto?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}&bancoId=${bancoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId }`
    }

    if(filtros.produtoId !== '0'){
        url = url + `&produtoId=${filtros.produtoId}`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.centrosCusto = []
    })

    tabela.forEach((e)=>{
        if(e.id === bancoId){
            e.produtos = data.content
        }
    })
    actions.setTabelaBanco(tabela);
    return true
}

export async function getTabelaBancoCentroCusto(filtros, elemento, tabela){
    var url = `dash-provisionamento-tabela-banco/centro-custo?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}
    &bancoId=${elemento.bancoId}
    &produtoId=${elemento.produtoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId }`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.tiposComissao = []
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.bancoId){
            e.produtos.forEach((e)=>{
                if(e.id === elemento.produtoId){
                    e.centrosCusto = data.content
                }
            })
        }
    })

    actions.setTabelaBanco(tabela);
    return true
}

export async function getTabelaBancoTipoComissao(filtros, elemento, tabela){
    var url = `dash-provisionamento-tabela-banco/tipo-comissao?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}
    &bancoId=${elemento.bancoId}
    &produtoId=${elemento.produtoId}
    &centroCustoId=${elemento.centroCustoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.propostas = []
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.bancoId){
            e.produtos.forEach((e)=>{
                if(e.id === elemento.produtoId){
                    e.centrosCusto.forEach((e)=>{
                        if(e.id === elemento.centroCustoId){
                            e.tiposComissao = data.content
                        }
                    })
                }
            })
        }
    })

    actions.setTabelaBanco(tabela);
    return true
}

export async function getTabelaBancoPropostas(filtros, elemento, tabela){
    var url = `dash-provisionamento-tabela-banco/propostas?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}
    &bancoId=${elemento.bancoId}
    &produtoId=${elemento.produtoId}
    &centroCustoId=${elemento.centroCustoId}
    &tipoComissaoId=${elemento.tipoComissaoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    
    data.content.forEach((item)=>{
        item.valorProposta = `R$ ${maskMoeda(Number(item.valorProposta).toFixed(2))}`
        item.valorComissaoRecebida = `R$ ${maskMoeda(Number(item.valorComissaoRecebida).toFixed(2))}`
        item.percentualComissaoRecebida = `${maskMoeda(Number(item.percentualComissaoRecebida).toFixed(2))} %`
        item.valorProvisionamento = `R$ ${maskMoeda(Number(item.valorProvisionamento).toFixed(2))}`
        item.percentualPrevisto = `${maskMoeda(Number(item.percentualPrevisto).toFixed(2))} %`
    })
    
    tabela.forEach((e)=>{
        if(e.id === elemento.bancoId){
            e.produtos.forEach((e)=>{
                if(e.id === elemento.produtoId){
                    e.centrosCusto.forEach((e)=>{
                        if(e.id === elemento.centroCustoId){
                            e.tiposComissao.forEach((e)=>{
                                if(e.id === elemento.tipoComissaoId){
                                    e.propostas = data.content
                                }
                            })
                        }
                    })
                }
            })
        }
    })

    actions.setTabelaBanco(tabela);
    return true
}

export async function getTabelaCanal(filtros){
    var url = `dash-provisionamento-canal?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId }`
    }

    if(filtros.bancoId !== '0'){
        url = url + `&bancoId=${filtros.bancoId}`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.produtoId !== '0'){
        url = url + `&produtoId=${filtros.produtoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.produtos = []
    })
    actions.setTabelaCanal(data.content);
    return true
}

export async function getTabelaCanalProduto(filtros, canalId, tabela){
    var url = `dash-provisionamento-canal/produto?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}&canalId=${canalId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId }`
    }

    if(filtros.produtoId !== '0'){
        url = url + `&produtoId=${filtros.produtoId}`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.bancoId !== '0'){
        url = url + `&bancoId=${filtros.bancoId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.bancos = []
    })

    tabela.forEach((e)=>{
        if(e.id === canalId){
            e.produtos = data.content
        }
    })
    actions.setTabelaCanal(tabela);
    return true
}

export async function getTabelaCanalBanco(filtros, elemento, tabela){
    var url = `dash-provisionamento-canal/banco?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}
    &canalId=${elemento.canalId}
    &produtoId=${elemento.produtoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId }`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.bancoId !== '0'){
        url = url + `&bancoId=${filtros.bancoId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.centrosCusto = []
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.canalId){
            e.produtos.forEach((e)=>{
                if(e.id === elemento.produtoId){
                    e.bancos = data.content
                }
            })
        }
    })

    actions.setTabelaCanal(tabela);
    return true
}

export async function getTabelaCanalCentroCusto(filtros, elemento, tabela){
    var url = `dash-provisionamento-canal/centro-custo?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}
    &canalId=${elemento.canalId}
    &produtoId=${elemento.produtoId}
    &bancoId=${elemento.bancoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.tiposComissao = []
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.canalId){
            e.produtos.forEach((e)=>{
                if(e.id === elemento.produtoId){
                    e.bancos.forEach((e)=>{
                        if(e.id === elemento.bancoId){
                            e.centrosCusto = data.content
                        }
                    })
                }
            })
        }
    })

    actions.setTabelaCanal(tabela);
    return true
}

export async function getTabelaCanalTipoComissao(filtros, elemento, tabela){
    var url = `dash-provisionamento-canal/tipo-comissao?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}
    &canalId=${elemento.canalId}
    &produtoId=${elemento.produtoId}
    &bancoId=${elemento.bancoId}
    &centroCustoId=${elemento.centroCustoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.propostas = []
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.canalId){
            e.produtos.forEach((e)=>{
                if(e.id === elemento.produtoId){
                    e.bancos.forEach((e)=>{
                        if(e.id === elemento.bancoId){
                            e.centrosCusto.forEach((e)=>{
                                if(e.id === elemento.centroCustoId){
                                    e.tiposComissao = data.content
                                }
                            })
                        }
                    })
                }
            })
        }
    })

    actions.setTabelaCanal(tabela);
    return true
}
export async function getTabelaCanalProposta(filtros, elemento, tabela){
    var url = `dash-provisionamento-canal/propostas?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}
    &canalId=${elemento.canalId}
    &produtoId=${elemento.produtoId}
    &bancoId=${elemento.bancoId}
    &centroCustoId=${elemento.centroCustoId}
    &tipoComissaoId=${elemento.tipoComissaoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((item)=>{
        item.valorProposta = `R$ ${maskMoeda(Number(item.valorProposta).toFixed(2))}`
        item.valorComissaoRecebida = `R$ ${maskMoeda(Number(item.valorComissaoRecebida).toFixed(2))}`
        item.percentualComissaoRecebida = `${maskMoeda(Number(item.percentualComissaoRecebida).toFixed(2))} %`
        item.valorProvisionamento = `R$ ${maskMoeda(Number(item.valorProvisionamento).toFixed(2))}`
        item.percentualPrevisto = `${maskMoeda(Number(item.percentualPrevisto).toFixed(2))} %`
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.canalId){
            e.produtos.forEach((e)=>{
                if(e.id === elemento.produtoId){
                    e.bancos.forEach((e)=>{
                        if(e.id === elemento.bancoId){
                            e.centrosCusto.forEach((e)=>{
                                if(e.id === elemento.centroCustoId){
                                    e.tiposComissao.forEach((e)=>{
                                        if(e.id === elemento.tipoComissaoId){
                                            e.propostas = data.content ?? []
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
    })

    actions.setTabelaCanal(tabela);
    return true
}

export async function getTabelaCentroCusto(filtros){
    var url = `dash-provisionamento-tabela-centro-custo?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId }`
    }

    if(filtros.bancoId !== '0'){
        url = url + `&bancoId=${filtros.bancoId}`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.produtoId !== '0'){
        url = url + `&produtoId=${filtros.produtoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.produtos = []
    })
    actions.setTabelaCentroCusto(data.content);
    return true
}

export async function getTabelaCentroCustoProduto(filtros, centroCustoId, tabela){
    var url = `dash-provisionamento-tabela-centro-custo/produto?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}&centroCustoId=${centroCustoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId }`
    }

    if(filtros.produtoId !== '0'){
        url = url + `&produtoId=${filtros.produtoId}`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.bancoId !== '0'){
        url = url + `&bancoId=${filtros.bancoId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.bancos = []
    })

    tabela.forEach((e)=>{
        if(e.id === centroCustoId){
            e.produtos = data.content
        }
    })
    actions.setTabelaCentroCusto(tabela);
    return true
}

export async function getTabelaCentroCustoBanco(filtros, elemento, tabela){
    var url = `dash-provisionamento-tabela-centro-custo/instituicao?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}
    &centroCustoId=${elemento.centroCustoId}
    &produtoId=${elemento.produtoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId }`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.bancoId !== '0'){
        url = url + `&bancoId=${filtros.bancoId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.tiposComissao = []
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.centroCustoId){
            e.produtos.forEach((e)=>{
                if(e.id === elemento.produtoId){
                    e.bancos = data.content
                }
            })
        }
    })

    actions.setTabelaCentroCusto(tabela);
    return true
}

export async function getTabelaCentroCustoTipoComissao(filtros, elemento, tabela){
    var url = `dash-provisionamento-tabela-banco/tipo-comissao?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}
    &centroCustoId=${elemento.centroCustoId}
    &produtoId=${elemento.produtoId}
    &bancoId=${elemento.bancoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.propostas = []
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.centroCustoId){
            e.produtos.forEach((e)=>{
                if(e.id === elemento.produtoId){
                    e.bancos.forEach((e)=>{
                        if(e.id === elemento.bancoId){
                            e.tiposComissao = data.content
                        }
                    })
                }
            })
        }
    })

    actions.setTabelaCentroCusto(tabela);
    return true
}

export async function getTabelaCentroCustoPropostas(filtros, elemento, tabela){
    var url = `dash-provisionamento-tabela-banco/propostas?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}
    &centroCustoId=${elemento.centroCustoId}
    &produtoId=${elemento.produtoId}
    &bancoId=${elemento.bancoId}
    &tipoComissaoId=${elemento.tipoComissaoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    
    data.content.forEach((item)=>{
        item.valorProposta = `R$ ${maskMoeda(Number(item.valorProposta).toFixed(2))}`
        item.valorComissaoRecebida = `R$ ${maskMoeda(Number(item.valorComissaoRecebida).toFixed(2))}`
        item.percentualComissaoRecebida = `${maskMoeda(Number(item.percentualComissaoRecebida).toFixed(2))} %`
        item.valorProvisionamento = `R$ ${maskMoeda(Number(item.valorProvisionamento).toFixed(2))}`
        item.percentualPrevisto = `${maskMoeda(Number(item.percentualPrevisto).toFixed(2))} %`
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.centroCustoId){
            e.produtos.forEach((e)=>{
                if(e.id === elemento.produtoId){
                    e.bancos.forEach((e)=>{
                        if(e.id === elemento.bancoId){
                            e.tiposComissao.forEach((e)=>{
                                if(e.id === elemento.tipoComissaoId){
                                    e.propostas = data.content
                                }
                            })
                        }
                    })
                }
            })
        }
    })

    actions.setTabelaCentroCusto(tabela);
    return true
}

export async function getTabelaProduto(filtros){
    var url = `dash-provisionamento-produto?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId }`
    }

    if(filtros.bancoId !== '0'){
        url = url + `&bancoId=${filtros.bancoId}`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.produtoId !== '0'){
        url = url + `&produtoId=${filtros.produtoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.bancos = []
    })
    actions.setTabelaProduto(data.content);
    return true
}

export async function getTabelaProdutoBanco(filtros, produtoId, tabela){
    var url = `dash-provisionamento-produto/banco?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}&produtoId=${produtoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId }`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId}`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.bancoId !== '0'){
        url = url + `&bancoId=${filtros.bancoId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.centrosCusto = []
    })

    tabela.forEach((e)=>{
        if(e.id === produtoId){
            e.bancos = data.content
        }
    })
    actions.setTabelaProduto(tabela);
    return true
}

export async function getTabelaProdutoCentroCusto(filtros, elemento, tabela){
    var url = `dash-provisionamento-produto/centro-custo?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}
    &produtoId=${elemento.produtoId}
    &bancoId=${elemento.bancoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId }`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.centroCustoId !== '0'){
        url = url + `&centroCustoId=${filtros.centroCustoId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.tiposComissao = []
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.produtoId){
            e.bancos.forEach((e)=>{
                if(e.id === elemento.bancoId){
                    e.centrosCusto = data.content
                }
            })
        }
    })

    actions.setTabelaProduto(tabela);
    return true
}

export async function getTabelaProdutoTiposComissao(filtros, elemento, tabela){
    var url = `dash-provisionamento-produto/tipo-comissao?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}
    &produtoId=${elemento.produtoId}
    &bancoId=${elemento.bancoId}
    &centroCustoId=${elemento.centroCustoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.tipoComissaoId !== '0'){
        url = url + `&tipoComissaoId=${filtros.tipoComissaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.propostas = []
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.produtoId){
            e.bancos.forEach((e)=>{
                if(e.id === elemento.bancoId){
                    e.centrosCusto.forEach((e)=>{
                        if(e.id === elemento.centroCustoId){
                            e.tiposComissao = data.content
                        }
                    })
                }
            })
        }
    })

    actions.setTabelaProduto(tabela);
    return true
}

export async function getTabelaProdutoProposta(filtros, elemento, tabela){
    var url = `dash-provisionamento-produto/propostas?tipoDataProposta=${filtros.tipoData}&dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}
    &produtoId=${elemento.produtoId}
    &bancoId=${elemento.bancoId}
    &centroCustoId=${elemento.centroCustoId}
    &tipoComissaoId=${elemento.tipoComissaoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&lojaId=${filtros.lojaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&convenioId=${filtros.convenioId}`
    }

    if(filtros.canalId !== '0'){
        url = url + `&canalId=${filtros.canalId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&grupoId=${filtros.grupoId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((item)=>{
        item.valorProposta = `R$ ${maskMoeda(Number(item.valorProposta).toFixed(2))}`
        item.valorComissaoRecebida = `R$ ${maskMoeda(Number(item.valorComissaoRecebida).toFixed(2))}`
        item.percentualComissaoRecebida = `${maskMoeda(Number(item.percentualComissaoRecebida).toFixed(2))} %`
        item.valorProvisionamento = `R$ ${maskMoeda(Number(item.valorProvisionamento).toFixed(2))}`
        item.percentualPrevisto = `${maskMoeda(Number(item.percentualPrevisto).toFixed(2))} %`
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.produtoId){
            e.bancos.forEach((e)=>{
                if(e.id === elemento.bancoId){
                    e.centrosCusto.forEach((e)=>{
                        if(e.id === elemento.centroCustoId){
                            e.tiposComissao.forEach((e)=>{
                                if(e.id === elemento.tipoComissaoId){
                                    e.propostas = data.content
                                }
                            })
                        }
                    })
                }
            })
        }
    })

    actions.setTabelaProduto(tabela);
    return true
}


export async function getCanais() {
    const data = await api.get(`canal`);

    if (!data.success) {
        return;
    }
    actions.setCanais(data.content);
}

export async function getLojas(pesquisa, page, pageSize, canalId) {
    var url = `Pessoa?pagina=${page}&registros=${pageSize}&papel=5`
  
    if (pesquisa.nome !== ''){
        url = `Pessoa/busca-com-nome/${pesquisa.nome}?pagina=${page}&registros=${pageSize}&papel=5`
    } 

    else if (pesquisa.cnpj !== '') url = url + `&cpfCnpj=${removeMaskCNPJ(pesquisa.cnpj)}`

    if (canalId !== '0') {
      url = url + `&canalId=${canalId}`
    }
    
    actions.setLoadingLojas(true);
    const data = await api.get(url);
    actions.setLoadingLojas(false);

    if (!data.success) {
        return;
    }
    
    actions.setLojas(data.content.registros);
    actions.setGetAllLojas({
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas
    })
}

export async function getCentrosCusto() {
    const data = await api.get(`CentrosCusto`);

    if (!data.success) {
        return;
    }
    actions.setCentrosCusto(data.content);
}

export async function getBancos() {
    const data = await api.get(`Bancos`);

    if (!data.success) {
        return;
    }
    actions.setBancos(data.content);
}

export async function getTiposcomissao() {
    const data = await api.get(`TipoComissao`);

    if (!data.success) {
        return;
    }
    actions.setTiposComissao(data.content);
}

export async function getProdutos() {
    const data = await api.get(`Produtos`);

    if (!data.success) {
        return;
    }
    actions.setProdutos(data.content);
}

export async function getConvenios() {
    const data = await api.get(`Convenios`);

    if (!data.success) {
        return;
    }
    actions.setConvenios(data.content);
}

export async function getGrupos() {
    const data = await api.get(`Grupos`);

    if (!data.success) {
        return;
    }
    actions.setGrupos(data.content);
}
