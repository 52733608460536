import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function item(value) {
    dispatch({
        type: TYPES.PRODUTOINST,
        value
    });
}

export function copy(value) {
    dispatch({
        type: TYPES.PRODUTOINST_COPY,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_PRODUTOINST,
        field
    });
}

export function getItems(value) {
    dispatch({
        type: TYPES.PRODUTOSINST,
        value
    });
}

export function setOperacoes(value) {
    dispatch({
        type: TYPES.SET_OPERACOES,
        value
    });
}

export function setProdutosRc(value) {
    dispatch({
        type: TYPES.SET_PRODUTOSRC,
        value
    });
}

export function setBancos(value) {
    dispatch({
        type: TYPES.SET_BANCOS,
        value
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_PRODUTOINST,
        field
    });
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}
