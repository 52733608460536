import React, { useEffect } from "react";
import { Card, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { useSelector } from "react-redux";
import * as operacoesInstituicoesActions from "../../../store/modules/operacaoInstituicao/actions";
import * as operacoesInstituicoesController from "../../../controllers/operacaoInstituicaoController";
import { Select } from "../../../components/Inputs/Input";
import {removeAccents, removeAccentsAndComma} from "../../../util/masks";

const Register = () => {

    const { item, bancos, operacoesRc, copy, invalid, seeRegister } = useSelector(state => state.operacaoInstituicao);

    useEffect(() => {
        operacoesInstituicoesController.getOperacoesRc();
    }, []);

    useEffect(() => {
        operacoesInstituicoesController.getBancos();
    }, []);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                operacoesInstituicoesController.create(item);
            } else {
                operacoesInstituicoesController.update(item, copy);
            };
        }
    }

    function onChangeItem(value, field) {
        operacoesInstituicoesActions.cleanInvalid(field);
        operacoesInstituicoesActions.item({ ...item, [field]: value });
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2" style={{ margin: '25px 0 0 25px' }}>
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>Preencha os campos para cadastrar uma nova Operação Instituição</p>
                    </Col>
                    <Form style={{ margin: '40px 0 0 25px' }}>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="operacaoInstituicao" style={{ color: 'var(--color-preto)' }}>
                                    Descrição da Operação Instituição
                                </Label>
                                <Input
                                    type="text"
                                    name="operacaoInstituicao"
                                    id="operacaoInstituicao"
                                    placeholder="Digite aqui..."
                                    value={item.descricao}
                                    onChange={e => onChangeItem(removeAccents(e.target.value), "descricao")}
                                    onKeyPress={e => handleKeyPress(e)}
                                    className={invalid.descricao ? "is-invalid" : ""}
                                    disabled={seeRegister}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3} >
                            <FormGroup>
                                <Label style={{ color: 'var(--color-preto)', marginTop: '27px' }}>
                                    Instituição Financeira
                                </Label>
                                <Select
                                    first="SELECIONE"
                                    value={{ value: item.bancoId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={bancos}
                                    onChange={(e) => onChangeItem(e.value, 'bancoId')}
                                    isSearchable={true}
                                    autoComplete="off"
                                    disabled={seeRegister}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            borderColor: invalid.bancoId ? "red" : "lightgrey",
                                            minHeight: "35px",
                                            height: "35px"
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label style={{ color: 'var(--color-preto)', marginTop: '27px' }}>
                                    Associação tipo de Operação Ribercred
                                </Label>
                                <Select
                                    first="SELECIONE"
                                    value={{ value: item.operacaoRcId }}
                                    select={{ value: "id", label: "descricao" }}
                                    options={operacoesRc}
                                    onChange={(e) => onChangeItem(e.value, 'operacaoRcId')}
                                    isSearchable={true}
                                    autoComplete="off"
                                    disabled={seeRegister}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            borderColor: invalid.operacaoRcId ? "red" : "lightgrey",
                                            minHeight: "35px",
                                            height: "35px"
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Form>
                </Card>
            </Col>
        </>
    );
}

export default Register;
