import React from "react";
import { IoIosArrowBack } from 'react-icons/io';
import "../../style.css";
import { Col, Button, TabContent, TabPane } from "reactstrap";
import Search from "./tabs/search";
import Register from "./tabs/register";
import { useSelector } from "react-redux";
import * as actions from "../../store/modules/propostas/actions";
import Breadcrumb from '../../components/breadcrumbs'

import * as  controller from "../../controllers/propostasController";

import history from '../../util/history'
import { useEffect } from "react";

export default function Propostas(){
    const { item, activeTab, activeDash } = useSelector(state => state.propostas);

    function changeTab(tab) {
        actions.setActiveTab(tab);
        actions.cleanRegister();
    }

    function changeView(){
        history.push("/dashboard");
        actions.setActiveDash(false);
        actions.setActiveTab("Search");
    }

    function seeRegisterF() {
        if (item.id !== "") {
            actions.cleanRegister();
        }
        actions.setSeeRegister(false);
    }

    useEffect(()=>{
        if(window.location.search !== ''){
            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get("id")
            controller.getById(id).then(()=>{
                actions.setSeeRegister(true);
                actions.setActiveDash(true)
                actions.setActiveTab("Register");
            })
        }
    },[])

    return (
        <Col>
                <Breadcrumb activeTab={activeTab} />
                            {
                                activeTab !== "Search"
                                    &&  (
                                        !activeDash ?
                                    <Col className="pt-2 pb-2">
                                        <Button
                                            onClick={() => { changeTab("Search"); seeRegisterF() }}
                                            style={{ width: "150px", color: "white" }}
                                            className='purpleButton'>
                                            <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar
                                        </Button>
                                    </Col>
                                    :
                                    <Col className="pt-2 pb-2">
                                        <Button
                                            onClick={() => { changeView() }}
                                            style={{ width: "250px", color: "white" }}
                                            className='purpleButton'>
                                            <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar para Dashboard
                                        </Button>
                                    </Col>
                                    )
                            }
                <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <Search />
                    </TabPane>
                    <TabPane tabId="Register">
                        <Register />
                    </TabPane>
                </TabContent>
        </Col>
    );
}

