import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import * as actions from '../../../../../../store/modules/personas/papeis_store/actions'
import * as controllerPapeis from '../../../../../../controllers/personasController/papeisController'
import * as personasController from '../../../../../../controllers/personasController/personasController'
import { Select, Switch } from '../../../../../../components/Inputs/Input'
import * as AiIcons from 'react-icons/ai'
import '../../../../styles/papeis.css'
import TableInstituicao from './subComponents/TableInstituicao'
import { notifyWarn } from '../../../../../../components/message/message'
import { useState } from 'react';
import { maskDate } from "../../../../../../util/masks";

export default function Funcionario() {
    const {
        ausencias,
        funcionario,
        seeRegister,
        tiposFuncionarios,
        funcionariosLoja
    } = useSelector(state => state.personasPapeis)
    const {
        instituicao,
        instituicoesFinanceira,
        instituicoesFinanceirasSelected,
        lojas,
        centrosDeCusto,
        canais,
        niveisHierarquicos
    } = useSelector(state => state.personasPapeis)
    const { informacao } = useSelector(state => state.personasInformacoes)

    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        controllerPapeis.getAusencias()
        controllerPapeis.getFuncionarios()
    }, [])

    useEffect(() => {
        controllerPapeis.getInstituicoes()
    }, [])

    useEffect(() => {
        if (informacao.id !== '0')
            personasController.getFuncionarioLoja(informacao.id)
    }, [informacao.id])

    async function salveInstituicao() {
        var isFuncionarioSuccessed = false
        var isUsuarioSuccessed = false
        if (!funcionario.id) {
            isFuncionarioSuccessed = await personasController.salveFuncionario(informacao.id, funcionario)
        } else {
            isFuncionarioSuccessed = true
        }
        if (isFuncionarioSuccessed) {
            isUsuarioSuccessed = await personasController.salveUsuariosBanco(informacao.id, instituicao)
            if (isUsuarioSuccessed) {
                await personasController.getById(informacao.id)
                setIsEditing(false)
            } else {
                actions.cleanInstituicao();
                setIsEditing(false)
            }
        }
    }

    function limparCampos() {
        actions.cleanInstituicao();
        actions.setLojas([]);
        actions.setCentroCusto([]);
        actions.setCanais([]);
        actions.niveisHierarquicos([]);
    }

    async function onChangeUserInstituicao(value, field) {
        if (field === 'dataVigenciaFinal') {
            var currentDate = new Date().toISOString().slice(0, 10)
            if (currentDate < value) {
                return notifyWarn('A data de vigência final não pode ser maior do que a data atual.')
            }
            if (value === '') {
                actions.setInstituicao('statusUsuario', true)
            } else {
                actions.setInstituicao('statusUsuario', false)
            }
        }

        actions.setInstituicao(field, value);

        if (field === 'bancoId') {
            await personasController.getLojasBanco(value, true);
            await controllerPapeis.getCanais(true);
        }

        if (field === 'lojaId') {
            await controllerPapeis.getCentrosCustoParaSelect(value);
        }

        if (field === 'canalId') {
            await controllerPapeis.getHierarquiaParaSelect(value);
        }
    }

    function onChange(value, field) {
        if (field === 'possuiRamal') {
            actions.funcionario({ ...funcionario, [field]: value, ramal: '' })
        } else {
            actions.funcionario({ ...funcionario, [field]: value })
        }
    }

    function onChangeFuncionario(value, label) {
        actions.funcionario({ ...funcionario, 'tipoFuncionarioId': value, 'tipoFuncionario': label })
    }

    function stateSetHeadCount() {
        if (!instituicao.headCount) {
            actions.setInstituicao('headCount', true);
        } else {
            actions.setInstituicao('headCount', false);
        }
    }

    const isInEditPage = funcionario.id ? true : false

    return (
        <div style={{ marginTop: '25px' }}>
            <Col md={12}>
                <Form>
                    <Row>
                        <Col md={2}>
                            <FormGroup style={{ margin: '0px 0px 0px 40px' }}>
                                <Label style={{ margin: '0px 40px 7px 20px' }}>Ativo</Label>
                                <Switch
                                    className='switch'
                                    checked={
                                        isInEditPage ? funcionario.possuiRamal
                                            :
                                            (funcionario.possuiRamal === null) ? true : funcionario.possuiRamal
                                    }
                                    onChange={() => {
                                        onChange(funcionario.possuiRamal === null ? false : !funcionario.possuiRamal, 'possuiRamal')
                                    }}
                                    disabled={seeRegister}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={3}>
                            <FormGroup>
                                <Label>Ramal</Label>
                                <Input
                                    placeholder='Digite aqui...'
                                    value={funcionario.ramal}
                                    onChange={(e) => {
                                        onChange(e.target.value, 'ramal')
                                    }}
                                    maxLength='6'
                                    disabled={seeRegister || (funcionario.possuiRamal === null) ? false : !funcionario.possuiRamal}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={4}>
                            <FormGroup>
                                <Label for='code'>Loja</Label>
                                <Select
                                    first='Selecione'
                                    value={{ value: funcionario.lojaId }}
                                    select={{ value: 'id', label: 'nome' }}
                                    options={funcionariosLoja}
                                    onChange={e => onChange(e.value, 'lojaId')}
                                    isSearchable={true}
                                    autoComplete='off'
                                    disabled={instituicao.id ? true : seeRegister}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={3}>
                            <FormGroup>
                                <Label>Canal</Label>
                                <Select
                                    first='Selecione'
                                    value={{ value: funcionario.tipoFuncionarioId }}
                                    select={{ value: 'id', label: 'nome' }}
                                    options={tiposFuncionarios}
                                    onChange={e => onChangeFuncionario(e.value, e.label)}
                                    isSearchable={true}
                                    autoComplete='off'
                                    disabled={seeRegister}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        funcionario.tipoFuncionarioId === '5e33295b-36f0-48d0-9118-7053cb8aeb40' ?
                            <>
                                <Row>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for='code'>Código da Empresa</Label>
                                            <Input
                                                type='text'
                                                id='code'
                                                value={funcionario.codigoEmpresa}
                                                onChange={e => onChange(e.target.value, 'codigoEmpresa')}
                                                disabled={funcionario.id ? true : seeRegister}
                                                placeholder='Digite aqui...'
                                                maxLength='50'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for='code'>Cód. do Funcionario ERP</Label>
                                            <Input
                                                type='text'
                                                id='code'
                                                value={funcionario.codigoFuncionarioERP}
                                                onChange={e => onChange(e.target.value, 'codigoFuncionarioERP')}
                                                disabled={funcionario.id ? true : seeRegister}
                                                placeholder='Digite aqui...'
                                                maxLength='50'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for='code'>Cód. Centro de Custo</Label>
                                            <Input
                                                type='text'
                                                id='code'
                                                value={funcionario.codigoCentroCusto}
                                                onChange={e => onChange(e.target.value, 'codigoCentroCusto')}
                                                disabled={funcionario.id ? true : seeRegister}
                                                placeholder='Digite aqui...'
                                                maxLength='50'
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for='code'>Razão Social Empresa</Label>
                                            <Input
                                                type='text'
                                                id='code'
                                                value={funcionario.razaoSocialEmpresa}
                                                onChange={e => onChange(e.target.value, 'razaoSocialEmpresa')}
                                                disabled={funcionario.id ? true : seeRegister}
                                                placeholder='Digite aqui...'
                                                maxLength='50'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Cargo</Label>
                                            <Input
                                                type='text'
                                                value={funcionario.nomeCargo}
                                                onChange={e => onChange(e.target.value, 'nomeCargo')}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Data de Admissão</Label>
                                            <Input
                                                type='date'
                                                max="9999-12-31"
                                                value={funcionario.dataAdmissao}
                                                onChange={e => onChange(e.target.value, 'dataAdmissao')}
                                                disabled={seeRegister}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>Data de Demissão</Label>
                                            <Input
                                                type='date'
                                                max="9999-12-31"
                                                value={funcionario.dataDemissao}
                                                onChange={e => onChange(e.target.value, 'dataDemissao')}
                                                disabled={seeRegister}
                                                placeholder='Digite aqui...'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className='cardBody'>
                                        <p style={{ color: 'var(--color-preto)', margin: '15px 0 10px 15px' }}>Registro de
                                            ausências</p>

                                        <table style={{ border: 'none', margin: '15px 0 10px 15px' }}>
                                            <tr style={{ backgroundColor: 'transparent' }}>
                                                <td style={{ border: 'none', color: 'var(--color-preto)' }}>Motivo</td>
                                                <td style={{
                                                    border: 'none',
                                                    width: '150px',
                                                    color: 'var(--color-preto)'
                                                }}>Início da ausência
                                                </td>
                                                <td style={{
                                                    border: 'none',
                                                    width: '150px',
                                                    color: 'var(--color-preto)'
                                                }}>Fim da ausência
                                                </td>
                                            </tr>
                                            {ausencias.map((ausencia) => {
                                                return (
                                                    <tr>
                                                        <td style={{ border: 'none' }}>{ausencia.descricaoMotivo}</td>
                                                        <td style={{ border: 'none' }}>{maskDate(ausencia.dataInicial, "DD/MM/YYYY")}</td>
                                                        <td style={{ border: 'none' }}>{maskDate(ausencia.dataFinal, "DD/MM/YYYY")}</td>
                                                    </tr>
                                                )
                                            })}
                                        </table>
                                    </div>
                                </Row>
                            </>
                            :
                            ''
                    }

                    <Card className='p-3 m-4' style={{ backgroundColor: 'var(--color-quase-branco)' }}>
                        <Form style={{ margin: '0 0 0 25px' }}>
                            <Col className='mb-4'>
                            </Col>
                            <Col md={10}>
                                <FormGroup>
                                    <Row>
                                        <Col md={6}>
                                            <Label for='user'>Usuário</Label>
                                            <Input
                                                value={instituicao.usuarioInstituicao}
                                                disabled={instituicao.id ? true : seeRegister}
                                                onChange={e => onChangeUserInstituicao(e.target.value, 'usuarioInstituicao')}
                                                id='user'
                                                type='text'
                                                placeholder='Digite aqui...'
                                                maxLength='150'
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Label>Instituição Financeira</Label>
                                            <Select
                                                first='Selecione'
                                                value={{ value: instituicao.bancoId }}
                                                select={{ value: 'id', label: 'nome' }}
                                                options={instituicoesFinanceira}
                                                onChange={e => onChangeUserInstituicao(e.value, 'bancoId')}
                                                isSearchable={true}
                                                autoComplete='off'
                                                disabled={instituicao.id ? true : seeRegister}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col md={10}>
                                <FormGroup>
                                    <Row>
                                        <Col md={6}>
                                            <Label>Loja</Label>
                                            <Select
                                                first='Selecione'
                                                value={{ value: instituicao.lojaId }}
                                                select={{ value: 'id', label: 'nome' }}
                                                options={lojas}
                                                onChange={e => onChangeUserInstituicao(e.value, 'lojaId')}
                                                isSearchable={true}
                                                autoComplete='off'
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Label>Centro Custo</Label>
                                            <Select
                                                first='Selecione'
                                                value={{ value: instituicao.centroCustoId }}
                                                select={{ value: 'centroCustoId', label: 'descricao' }}
                                                options={centrosDeCusto}
                                                onChange={e => onChangeUserInstituicao(e.value, 'centroCustoId')}
                                                isSearchable={true}
                                                autoComplete='off'
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col md={10}>
                                <FormGroup>
                                    <Row>
                                        <Col md={5}>
                                            <Label>Canal</Label>
                                            <Select
                                                first='Selecione'
                                                value={{ value: instituicao.canalId }}
                                                select={{ value: 'id', label: 'nome' }}
                                                options={canais}
                                                onChange={e => onChangeUserInstituicao(e.value, 'canalId')}
                                                isSearchable={true}
                                                autoComplete='off'
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <Label>Nivel Hierárquico</Label>
                                            <Select
                                                first='Selecione'
                                                value={{ value: instituicao.nivelHierarquicoId }}
                                                select={{ value: 'id', label: 'descricao' }}
                                                options={niveisHierarquicos}
                                                onChange={e => onChangeUserInstituicao(e.value, 'nivelHierarquicoId')}
                                                isSearchable={true}
                                                autoComplete='off'
                                                disabled={true}
                                            />
                                        </Col>
                                        <Col md={2} style={{ paddingTop: '3px' }}>
                                            <Label>HeadCount</Label>
                                            <Switch
                                                className="switch"
                                                checked={instituicao.headCount}
                                                onChange={() => {
                                                    stateSetHeadCount();
                                                }}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col>
                                <Row>
                                    <Col md={5}>
                                        <Label style={{ fontSize: '13px' }}>Data de início da vigência do usuário</Label>
                                        <Input
                                            type='date'
                                            max="9999-12-31"
                                            value={instituicao.dataVigenciaInicial}
                                            onChange={e => onChangeUserInstituicao(e.target.value, 'dataVigenciaInicial')}
                                            disabled={false}
                                        />
                                    </Col>
                                    <Col md={5}>
                                        <Label style={{ fontSize: '13px' }}>Data de fim da vigência do usuário</Label>
                                        <Input
                                            type='date'
                                            max="9999-12-31"
                                            value={instituicao.dataVigenciaFinal}
                                            onChange={e => onChangeUserInstituicao(e.target.value, 'dataVigenciaFinal')}
                                            disabled={false}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <br />
                            <Col>
                                <Row>
                                    <Col md={3} style={{ marginRight: '25px' }}>
                                        <Label style={{ cursor: 'pointer' }}
                                            onClick={() => salveInstituicao()}
                                        >
                                            <AiIcons.AiOutlinePlusCircle
                                                style={{
                                                    fontSize: '1.5rem',
                                                    marginRight: '7px',
                                                    color: 'green',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                            Adicionar Usuário Banco</Label>
                                    </Col>
                                    <Col>
                                        <Label style={{ cursor: 'pointer' }}
                                            onClick={() => limparCampos()}
                                        >
                                            <AiIcons.AiFillEdit
                                                style={{
                                                    fontSize: '1.5rem',
                                                    marginRight: '7px',
                                                    color: 'darkgreen',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                            Limpar Campos</Label>
                                    </Col>
                                </Row>
                            </Col>
                        </Form>
                    </Card>
                    {
                        !instituicoesFinanceirasSelected.length !== 0 &&
                        <TableInstituicao
                            instituicoes={instituicoesFinanceirasSelected}
                            hasDataFinal={setIsEditing} />
                    }
                </Form>
            </Col>
        </div>
    )
}
