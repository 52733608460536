export const COLUMNS = [
    {
        Header: "Descrição",
        accessor: "descricao",
    }
]

export const COLUMNS_PERSONA = [
    {
        Header: "Nome",
        accessor: "nome"
    },
    {
        Header: "CPF",
        accessor: "cpfCnpj"
    }
]
