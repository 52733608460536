import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as actions from "../store/modules/tipoParceria/actions";
import { setLoading } from "../store/modules/globalLoading/actions";



export async function getAll() {

    setLoading(true)
    const data = await api.get('TipoParceira')
    setLoading(false)

    if (!data.success) {
        return;
    }
    actions.setItems(data.content)

}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`TipoParceira/${id}`)
    setLoading(false)

    if (!data.success) {
        return;
    }
    actions.setItem(data.content)
    actions.copy(data.content)

}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`TipoParceira/${id}`)
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Tipo de parceria removida");
    getAll();

}
export async function create(item) {

    if (item.nome === "") {
        actions.setInvalid('nome')
        notifyWarn("Descrição do tipo de parceria não pode estar em branco");
    }
    
    setLoading(true)
    const data = await api.post(`TipoParceira`, item)
    setLoading(false)

    if (!data.success) {
        return;
    }
    notifySuccess("Tipo de parceria cadastrado");
    actions.cleanRegister();
    getAll()
}
export async function update(item, copy) {
    if (item.nome === "") {
        actions.setInvalid('nome')
        notifyWarn("Descrição do tipo de parceria não pode estar em branco");
        return;
    }

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    setLoading(true)
    const data = await api.put(`TipoParceira/${item.id}`, item);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Tipo de parceria atualizada");
    actions.cleanRegister();
    getAll();


}
