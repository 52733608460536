import { notifyError, notifySuccess } from "../components/message/message";
import * as esqueceuSenhaActions from "../store/modules/esqueceuSenha/actions";
import api from "../util/api";
import history from "../util/history";

export async function recuperarSenha(item, id) {

    if(item.novaSenha !== item.confirmaSenha){
        esqueceuSenhaActions.setInvalid(true, "confirmaSenha")
        return notifyError("Os campos de senha estão diferentes")
    }

    if(item.novaSenha.length < 10){
        esqueceuSenhaActions.setInvalid(true, "novaSenha")
        esqueceuSenhaActions.setInvalid(true, "confirmaSenha")
        return notifyError("A senha deve ter pelo menos 10 caracteres")
    }

    const body = {
        senha: item.novaSenha
    }

    esqueceuSenhaActions.setLoadingEsqueceuSenha(true)
    const data = await api.put(`Autenticacao/recuperar-senha/${id}`, body)
    esqueceuSenhaActions.setLoadingEsqueceuSenha(false)

    if(!data.success){
        return
    }

    notifySuccess("Senha alterada com sucesso.")
    esqueceuSenhaActions.cleanItem()
    history.push('/login')
}

export async function validaGuid(id) {

    esqueceuSenhaActions.setLoadingEsqueceuSenha(true)
    const data = await api.get(`Autenticacao/link-valido/${id}`)
    esqueceuSenhaActions.setLoadingEsqueceuSenha(false)

    if(!data.success){
        return true
    }

    return false
}