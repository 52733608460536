const STORE_ID = "RCPRODUCTS";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    PRODUTORC: "PRODUTORC",
    PRODUTORC_COPY: "PRODUTORC_COPY",
    INVALID_PRODUTORC: "INVALID_PRODUTORC",
    PRODUTOSRC: "PRODUTOSRC",
    CLEAN_INVALID_PRODUTORC: "CLEAN_INVALID_PRODUTORC",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE"
}

export { STORE_ID, TYPES }