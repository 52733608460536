import React from 'react'

import { maskDate, maskMoeda } from '../../../../util/masks'

import './style.css'

export default function ({data}) {
    return(
        <div style={{overflow: 'auto'}}>
            <table style={{ marginTop: '30px'}}>
                <thead >
                    <tr >
                        <th className='colunaCampanha'>Regra</th>
                        <th className='colunaCampanha'>Ranking</th>
                        <th className='colunaCampanha'>Meta </th>
                        <th className='colunaCampanha'>Meta DU </th>
                        <th className='colunaCampanha'>Elegível </th>
                        <th className='colunaCampanha'>Produção</th>
                        <th className='colunaCampanha'>% atingido </th>
                        <th className='colunaCampanha'>Meta Restante</th>
                        <th className='colunaCampanha'>Meta DU Recalc.</th>
                        <th className='colunaCampanha'>Produção Projetada </th>
                        <th className='colunaCampanha'>Faixa </th>
                        <th className='colunaCampanha'>Projeção de ganho </th>
                        <th className='colunaCampanha'>Data Início </th>
                        <th className='colunaCampanha'>Data Fim </th>
                        <th className='colunaCampanha'>Data Apuração </th>                                     
                    </tr>
                </thead>
                <tbody >
                    {data.map((e, i)=>{
                        return(
                            <FirstLevelTableRow data={e} index={i}/>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

function FirstLevelTableRow({data,index}){

    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen' style={{ background: '#F8F8FF',width: '300px', textAlign: 'center'}}>
                    <div >
                        {data.descricaoCampanha}
                    </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                    <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                            {data.posicaoGeral}
                    </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                    <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                            {`${maskMoeda(Number(data.meta).toFixed(2))}`}
                        </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                            {`${maskMoeda(Number(data.metaDu).toFixed(2))}`}
                    </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                    <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                            {`${data.metaBatida ? 'Sim' : 'Não'}`}
                        </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                    <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                            {`${maskMoeda(Number(data.producaoMes).toFixed(2))}`}
                    </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                    <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                            {`${maskMoeda(Number(data.incremento).toFixed(2))}`}
                    </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                    <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                         {`${maskMoeda(Number(data.metaRestante).toFixed(2))}`}
                    </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                    <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                            {`${maskMoeda(Number(data.metaDuRecalculada).toFixed(2))}`}
                    </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                    <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                            {`${maskMoeda(Number(data.valorProjetado).toFixed(2))}`}
                    </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                    <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                            {data.faixa}
                    </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                    <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                        {`${maskMoeda(Number(data.valorProjecao).toFixed(2))}`}
                    </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                    <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                            {`${maskDate(data.dataInicio, "DD/MM/YYYY")}`}
                    </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                    <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                             {`${maskDate(data.dataFim, "DD/MM/YYYY")}`}
                    </div>
                </td>
                <td className='linhaGreen' style={{ background: '#F8F8FF', textAlign: 'center'}}>
                    <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%'}}>          
                             {`${maskDate(data.dataExecucao, "DD/MM/YYYY")}`}
                    </div>
                </td>   
            </tr>
        </>
    )
}