import { STORE_ID, TYPES } from "./types";
import store from "../../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function item(value) {
    dispatch({
        type: TYPES.PERSONAS,
        value
    });
}

export function copy(value) {
    dispatch({
        type: TYPES.PERSONAS_COPY,
        value
    });
}

export function getItems(value) {
    dispatch({
        type: TYPES.PERSONAS,
        value
    });
}

export function cleanSearch() {
    dispatch({
        type: TYPES.CLEAN_SEARCH
    })
}

export function cleanAllSearch() {
    dispatch({
        type: TYPES.CLEAN_ALL_SEARCH
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSearch(value) {
    dispatch({
        type: TYPES.SEARCH,
        value
    });
}

export function getItemsEstados(value) {
    dispatch({
        type: TYPES.ESTADOS,
        value
    });
}

export function getCanais(value) {
    dispatch({
        type: TYPES.CANAIS,
        value
    });
}

export function getTiposLoja(value) {
    dispatch({
        type: TYPES.TIPOS_LOJA,
        value
    });
}

export function getItemsNascimentos(value) {
    dispatch({
        type: TYPES.NASCIMENTOS,
        value
    });
}

export function getAllItems(value) {
    dispatch({
        type: TYPES.GET_ALL_ITEMS,
        value
    });
}

export function setPersonaId(value) {
    dispatch({
        type: TYPES.PERSONA,
        value
    });
}

export function getItemsPapeis(value) {
    dispatch({
        type: TYPES.PAPEIS,
        value
    });
}

export function setTab(value) {
    dispatch({
        type: TYPES.SET_TAB,
        value
    });
}