import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import * as informacoesActions from "../../../../../../store/modules/personas/informacoes_store/actions";
import * as informacoesController from "../../../../../../controllers/personasController/informacoesController";
import { InputCpfCnpj, Select } from "../../../../../../components/Inputs/Input";
import { ValidateCPF } from "../../../../../../util/validations";
import { removeAccentsAndUppercase, removeMaskCPF } from "../../../../../../util/masks";
import { notifyWarn } from "../../../../../../components/message/message";

const PessoaFisica = () => {
    const { informacao, invalid, seeRegister, orgaosRg, ufsDoc } = useSelector(state => state.personasInformacoes);

    useEffect(() => {
        informacoesController.getUfDoc();
        informacoesController.getOrgaosRg();
    }, []);

    function onChangeItem(value, field) {

        informacoesActions.cleanInvalid(field);
        informacoesActions.item({ ...informacao, [field]: value });

    }

    function onChangeUfRg(value) {

        informacoesActions.cleanInvalid('orgaoRgId');
        informacoesActions.item({ ...informacao, ufRgId: value });

    }

    function validaCPF(value){
        if(!ValidateCPF(removeMaskCPF(value)) && value !== ''){
            notifyWarn("Aba Informações: CPF inválido");
            informacoesActions.setInvalid("cpf");
            return;
        }
    }

    return (
        <>
            <div style={{ marginBottom: "35px" }}>
                <Row>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="CPF" style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>CPF</Label>
                            <InputCpfCnpj
                                id="CPF"
                                tipo="cpf"
                                value={informacao.cpf}
                                placeholder="Digite aqui..."
                                onChange={e => onChangeItem(e.target.value, "cpf")}
                                autoComplete="off"
                                disabled={seeRegister || informacao.id !== null}
                                className={invalid.cpf && "is-invalid"}
                                maxLength="14"
                                onBlur={e => validaCPF(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="nome" style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>Nome</Label>
                            <Input
                                type="text"
                                id="nome"
                                placeholder="Digite aqui..."
                                value={informacao.nome}
                                onChange={e => onChangeItem(removeAccentsAndUppercase(e.target.value), "nome")}
                                className={invalid.nome && "is-invalid"}
                                disabled={seeRegister}
                                maxLength="150"
                            />
                        </FormGroup>
                    </Col>

                </Row>
                <Row>

                    <Col md={2}>
                        <FormGroup>
                            <Label for="RG" style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>RG </Label>
                            <Input
                                type="Select"
                                id="RG"
                                placeholder="Digite aqui..."
                                value={informacao.rg}
                                onChange={e => onChangeItem(e.target.value, "rg")}
                                className={invalid.rg && "is-invalid"}
                                disabled={seeRegister}
                                maxLength="9"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <Label for="UfDoc" style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>UF do Documento</Label>
                        <FormGroup>
                            <Select
                                first="Selecione"
                                disabled={seeRegister}
                                onChange={e => onChangeUfRg(e.value)}
                                value={{ value: informacao.ufRgId }}
                                options={ufsDoc}
                                isSearchable={true}
                                select={{ value: "id", label: "sigla" }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="orgaoRG" style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>Órgão Emissor do RG</Label>
                            <Select
                                first="Selecione"
                                disabled={seeRegister}
                                onChange={e => onChangeItem(e.value, "orgaoRgId")}
                                value={{ value: informacao.orgaoRgId }}
                                select={{ value: "id", label: "nome" }}
                                options={orgaosRg}
                                isSearchable={true}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="dataNascimento" style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>Data de Nascimento</Label>
                            <Input
                                type="date"
                                id="dataNascimento"
                                value={informacao.dataNascimento}
                                max="9999-12-31"
                                onChange={e => onChangeItem(e.target.value, "dataNascimento")}
                                className={invalid.dataNascimento && "is-invalid"}
                                disabled={seeRegister}
                                maxLength="10"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>

                </Row>
                <Row>
                    <Col md={4}>
                        <Label>Nome da Mãe</Label>
                        <Input
                            type="text"
                            placeholder="Digite aqui..."
                            value={informacao.nomeMae}
                            onChange={e => onChangeItem(removeAccentsAndUppercase(e.target.value), "nomeMae")}
                            disabled={seeRegister}
                            maxLength="150"
                            className={invalid.nomeMae && "is-invalid"}
                        />
                    </Col>
                    <Col md={4}>
                        <Label>Nome do Pai</Label>
                        <Input
                            type="text"
                            placeholder="Digite aqui..."
                            value={informacao.nomePai}
                            onChange={e => onChangeItem(removeAccentsAndUppercase(e.target.value), "nomePai")}
                            maxLength="150"
                            disabled={seeRegister}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default PessoaFisica;