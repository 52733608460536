import moment from "moment"

export const COLUMNS = [
    {
        Header: "CPF / CNPJ",
        accessor: "cpfCnpj"
    },
    {
        Header: "Nome / Nome Fantasia",
        accessor: "nome"
    },
    {
        Header: "Nascimento / Criação",
        accessor: "dataNascimento",
        Cell: ({ value }) => { return moment(value).local().format("DD/MM/YYYY") }
    },
    {
        Header: "Cidade",
        accessor: "cidade"
    },
    {
        Header: "UF",
        accessor: "uf"
    },
    //     {
    //         Header: "Papel",
    //         accessor: "papel"
    //     }
]

export const COLUMNS_PESSOAS = [
    {
        Header: "Centro de custo",
        accessor: "centroCusto"
    },
    {
        Header: "Usuário Instituição",
        accessor: "usuarioBanco.usuarioInstituicao"
    },
    {
        Header: "Instituição",
        accessor: "usuarioBanco.banco.nome"
    },
    {
        Header: "Data início da vigência",
        accessor: "dataVigenciaInicial",
        Cell: ({ value }) => { return moment(value).local().format("DD/MM/YYYY") }
    },
    {
        Header: "Data fim de vigência",
        accessor: "dataVigenciaFinal",
        Cell: ({ value }) => { return value ? moment(value).local().format("DD/MM/YYYY") : ''}
    }
]


