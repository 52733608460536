import React from "react";
import { Card, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { useSelector } from "react-redux";
import * as conveniosActions from "../../../store/modules/conveniosRibercred/actions";
import * as conveniosRcController from "../../../controllers/conveniosRcController";
import { removeAccentsAndUppercase } from "../../../util/masks";

const Register = () => {

    const { item, copy, invalid, seeRegister } = useSelector(state => state.convenioRc);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                conveniosRcController.create(item);
            } else {
                conveniosRcController.update(item, copy);
            };
        }
    }

    function onChangeConvenio(value, field) {
        conveniosActions.cleanInvalid(field);
        conveniosActions.item({ ...item, [field]: value });
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2" style={{ margin: '25px 0 0 25px' }}>
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>
                            Preencha os campos para cadastrar um novo Convênio Ribercred
                        </p>
                    </Col>
                    <Form>
                        <Col style={{ margin: '25px 0 0 25px' }}>
                            <FormGroup>
                                <Label for="convenio" style={{ fontWeight: "700", color: 'var(--color-preto)' }}>
                                    Descrição do Convênio Ribercred
                                </Label>
                                <Input
                                    type="text"
                                    name="convenio"
                                    id="convenio"
                                    placeholder="Digite aqui..."
                                    value={item.descricao}
                                    onChange={e => onChangeConvenio(removeAccentsAndUppercase(e.target.value), "descricao")}
                                    onKeyPress={e => handleKeyPress(e)}
                                    className={invalid.descricao ? "is-invalid" : ""}
                                    disabled={seeRegister ? true : false}
                                    style={{ width: '30%' }}
                                />
                            </FormGroup>
                        </Col>
                    </Form>
                </Card>
            </Col>
        </>
    );
}

export default Register;