import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';

import * as controller from '../../../../controllers/dashboardController/receitaController'

import { maskMoeda } from '../../../../util/masks';

import './style.css'

export default function Table({data}){
    return(
        <table style={{maxWidth: '100%'}}>
            <thead style={{maxWidth: '100%'}}>
                <tr style={{maxWidth: '100%'}}>
                    <th className='colunaReceita' style={{width: '30%'}}>Instituição Financeira</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Receita Total</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Pago</th>
                    <th className='colunaReceita' style={{width: '7%'}}>À Vista</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Taxa manutenção diferida</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Taxa manutenção antecipada</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Rotativo</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Ativação</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Diferido turbinado</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Bonus</th>
                    <th className='colunaReceita' style={{width: '7%'}}>Share</th>
                </tr>
            </thead>
            <tbody style={{maxWidth: '100%'}}>
                {data.map((e, i)=>{
                    return(
                       <FirstLevelTableRow data={e} index={i}/>
                    )
                })}
            </tbody>
        </table>
    )
}

function FirstLevelTableRow({data,index}){

    const { filtros, tabelaProducao} = useSelector(state => state.dashboardReceita);

    const [isTipoParceriaActive, setIsTipoParceriaActive] = useState(false)

    function handleOnClick(){
        if(isTipoParceriaActive === false){
            var isSuccessed = controller.getTabelaReceitaTiposParceria(filtros, data.id, tabelaProducao)
            isSuccessed && setIsTipoParceriaActive(!isTipoParceriaActive)
        }else{
            setIsTipoParceriaActive(!isTipoParceriaActive)
        }
    }

    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen'>
                    <div style={{display: 'flex'}}>
                        <div className='button' onClick={(e) => handleOnClick()}>{!isTipoParceriaActive ? '+' : '-'}</div>
                        {data.nome}
                    </div>
                </td>
                {data.tiposComissaoReceita.map((e)=>{
                    return(
                        <td className={e.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}>
                            <div>
                            <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {`(${e.quantidade})`} {e.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> }
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                    {`R$${maskMoeda(e.valor.toFixed(2))}`}
                                </p> 
                            </div>
                        </td>
                    )
                })}
            </tr>
            {isTipoParceriaActive &&
            data.tiposParceria.map((e, i) =>{
                return(
                    <SecondLevelTableRow data={e} index={i} bancoId={data.id}/>
                )
            })
            }
        </>
    )
}


function SecondLevelTableRow({data,index, bancoId}){
    const { filtros, tabelaProducao} = useSelector(state => state.dashboardReceita);

    const [isCanalActive, setIsCanalActive] = useState(false)

    var elemento = {tipoParceriaId: data.id, bancoId: bancoId}

    function handleOnClick(){
        if(isCanalActive === false){
            var isSuccessed = controller.getTabelaReceitaCanais(filtros, elemento, tabelaProducao)
            isSuccessed && setIsCanalActive(!isCanalActive)
        }else{
            setIsCanalActive(!isCanalActive)
        }
    }

    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen'>
                    <div style={{display: 'flex', marginLeft: '30px'}}>
                        <div className='button' onClick={(e) => handleOnClick()}>{!isCanalActive ? '+' : '-'}</div>
                        {data.nome}
                    </div>
                </td>
                {data.tiposComissaoReceita.map((e)=>{
                    return(
                        <td className={e.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}>
                            <div>
                            <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {`(${e.quantidade})`} {e.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> }
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                    {`R$${maskMoeda(e.valor.toFixed(2))}`}
                                </p> 
                            </div>
                        </td>
                    )
                })}
            </tr>
            {isCanalActive &&
            data.canais.map((e, i) =>{
                return(
                    <ThirdLevelTableRow data={e} index={i} e={elemento}/>
                )
            })
            }
        </>
    )
}

function ThirdLevelTableRow({data,index, e}){
    const { filtros, tabelaProducao} = useSelector(state => state.dashboardReceita);

    const [isLojasActive, setIsLojasActive] = useState(false)

    var elemento = {tipoParceriaId: e.tipoParceriaId, bancoId: e.bancoId, canalId: data.id}

    function handleOnClick(){
        if(isLojasActive === false){
            var isSuccessed = controller.getTabelaReceitaLojas(filtros, elemento, tabelaProducao)
            isSuccessed && setIsLojasActive(!isLojasActive)
        }else{
            setIsLojasActive(!isLojasActive)
        }
    }

    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen'>
                    <div style={{display: 'flex', marginLeft: '60px'}}>
                        <div className='button' onClick={(e) => handleOnClick()}>{!isLojasActive ? '+' : '-'}</div>
                        {data.nome}
                    </div>
                </td>
                {data.tiposComissaoReceita.map((e)=>{
                    return(
                        <td className={e.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}>
                            <div>
                            <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {`(${e.quantidade})`} {e.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> }
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                    {`R$${maskMoeda(e.valor.toFixed(2))}`}
                                </p> 
                            </div>
                        </td>
                    )
                })}
            </tr>
            {isLojasActive &&
            data.lojas.map((e, i) =>{
                return(
                    <FourthLevelTableRow data={e} index={i} e={elemento}/>
                )
            })
            }
        </>
    )
}

function FourthLevelTableRow({data,index, e}){
    const { filtros, tabelaProducao} = useSelector(state => state.dashboardReceita);

    const [isConsultoresActive, setIsConsultoresActive] = useState(false)

    var elemento = {tipoParceriaId: e.tipoParceriaId, bancoId: e.bancoId, canalId: e.canalId, lojaId: data.id}

    function handleOnClick(){
        if(isConsultoresActive === false){
            var isSuccessed = controller.getTabelaReceitaConsultores(filtros, elemento, tabelaProducao)
            isSuccessed && setIsConsultoresActive(!isConsultoresActive)
        }else{
            setIsConsultoresActive(!isConsultoresActive)
        }       
    }

    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen'>
                    <div style={{display: 'flex', marginLeft: '90px'}}>
                        <div className='button' onClick={(e) => handleOnClick()}>{!isConsultoresActive ? '+' : '-'}</div>
                        {data.nome}
                    </div>
                </td>
                {data.tiposComissaoReceita.map((e)=>{
                    return(
                        <td className={e.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}>
                            <div>
                            <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {`(${e.quantidade})`} {e.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> }
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                    {`R$${maskMoeda(e.valor.toFixed(2))}`}
                                </p> 
                            </div>
                        </td>
                    )
                })}
            </tr>
            {isConsultoresActive &&
            data.consultores.map((e, i) =>{
                return(
                    <FifthLevelTableRow data={e} index={i}/>
                )
            })
            }
        </>
    )
}

function FifthLevelTableRow({data,index}){
    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen'>
                    <div style={{display: 'flex', marginLeft: '90px'}}>
                        {data.nome}
                    </div>
                </td>
                {data.tiposComissaoReceita.map((e)=>{
                    return(
                        <td className={e.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}>
                            <div>
                            <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {`(${e.quantidade})`} {e.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> }
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                    {`R$${maskMoeda(e.valor.toFixed(2))}`}
                                </p> 
                            </div>
                        </td>
                    )
                })}
            </tr>
        </>
    )
}