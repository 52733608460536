export const COLUMNS = [
    {
        Header: "Tipo Conta",
        accessor: "tipoConta",
    },
    {
        Header: "Identificador",
        accessor: "identificadorContaGerencial"
    },
    {
        Header: "Código C.G",
        accessor: "codigoContaGerencial"
    },
    {
        Header: "Informação",
        accessor: "informacaoContaGerencial"
    },
    {
        Header: "Classificação Receita",
        accessor: "classificacaoReceitaContaGerencial"
    },
    {
        Header: "Produto Riber",
        accessor: "produtoRiberContaGerencial"
    },
    {
        Header: "Tipo Comissão",
        accessor: "tipoComissaoContaGerencial"
    }
]
