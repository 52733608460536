import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BsTable } from "react-icons/bs";
import { BiShoppingBag } from "react-icons/bi";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Col } from 'reactstrap';

import classnames from 'classnames';
import Breadcrumb from "../../components/breadcrumbs";

import Proposta from "./Propostas";
import LojasBanco from "./LojasBanco";
import Produto from "./Produto";

import * as actions from '../../store/modules/inconsistenciaComissaoRecebida/actions';
import * as controller from '../../controllers/inconsistenciaComissaoRecebidaController'
import TipoComissao from "./TipoComissao";

const IncosistenciaComissaoRecebida = () => {
    const { tab, tabs } = useSelector(state => state.inconsistenciaComissaoRecebida);

    useEffect(() => {
        controller.getQuantidade(tabs)
        if (tab.tab === '1') {
            controller.getInconsistenciasPaginado('', 1)
        } else {
            controller.getInconsistencias(tab)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function changeTab(tab) {
        actions.cleanTabs()
        actions.setTab(tab)
        if (tab.tab === '1') {
            await controller.getInconsistenciasPaginado('', 1)
        } else {
            await controller.getInconsistencias(tab)
        }
    }
    return (
        <Col>
            <Breadcrumb activeTab={"Search"} />
            <Card className="pt-3 pb-2 mb-5">
                <div>
                    <Nav style={{ marginLeft: '25px', marginBottom: '10px', width: '95%' }}>
                        <NavItem style={{ padding: '0 5px 0 5px' }}>
                            <NavLink
                                className={classnames({ active: tab.tab === '1' })}
                                onClick={() => { changeTab({ tab: '1', id: tabs[0].id }); }}
                                style={
                                    tab.tab === '1' ?
                                        { color: 'var(--color-preto)', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s', cursor: 'pointer' }
                                        : { color: 'var(--color-preto)', fontWeight: '700', cursor: 'pointer' }}
                            >
                                <BsTable style={{ fontSize: '18px', paddingBottom: '2px', marginRight: '5px' }} />
                                {`Propostas (${tabs[0].quantidade})`}
                            </NavLink>
                        </NavItem>
                        <NavItem style={{ padding: '0 5px 0 5px' }}>
                            <NavLink
                                className={classnames({ active: tab.tab === '2' })}
                                onClick={() => { changeTab({ tab: '2', id: tabs[1].id }); }}
                                style={
                                    tab.tab === '2' ?
                                        { color: 'var(--color-preto)', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s', cursor: 'pointer' }
                                        : { color: 'var(--color-preto)', fontWeight: '700', cursor: 'pointer' }}
                            >
                                <BiShoppingBag style={{ fontSize: '18px', paddingBottom: '2px', marginRight: '5px' }} />
                                {`Loja Banco (${tabs[1].quantidade})`}
                            </NavLink>
                        </NavItem>
                        <NavItem style={{ padding: '0 5px 0 5px' }}>
                            <NavLink
                                className={classnames({ active: tab.tab === '3' })}
                                onClick={() => { changeTab({ tab: '3', id: tabs[2].id }); }}
                                style={
                                    tab.tab === '3' ?
                                        { color: 'var(--color-preto)', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s', cursor: 'pointer' }
                                        : { color: 'var(--color-preto)', fontWeight: '700', cursor: 'pointer' }}
                            >
                                <BiShoppingBag style={{ fontSize: '18px', paddingBottom: '2px', marginRight: '5px' }} />
                                {`Tipo Comissão (${tabs[2].quantidade})`}
                            </NavLink>
                        </NavItem>
                        <NavItem style={{ padding: '0 5px 0 5px' }}>
                            <NavLink
                                className={classnames({ active: tab.tab === '4' })}
                                onClick={() => { changeTab({ tab: '4', id: tabs[3].id }); }}
                                style={
                                    tab.tab === '4' ?
                                        { color: 'var(--color-preto)', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s', cursor: 'pointer' }
                                        : { color: 'var(--color-preto)', fontWeight: '700', cursor: 'pointer' }}
                            >
                                <BiShoppingBag style={{ fontSize: '18px', paddingBottom: '2px', marginRight: '5px' }} />
                                {`Produto (${tabs[3].quantidade})`}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={tab.tab}>
                        <TabPane tabId="1">
                            <Proposta />
                        </TabPane>
                        <TabPane tabId="2">
                            <LojasBanco />
                        </TabPane>
                        <TabPane tabId="3">
                            <TipoComissao />
                        </TabPane>
                        <TabPane tabId="4">
                            <Produto />
                        </TabPane>
                    </TabContent>
                </div>
            </Card>
        </Col>

    );
}

export default IncosistenciaComissaoRecebida;