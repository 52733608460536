const STORE_ID = "PERFIL_CLIENTE";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    PERFIL: "PERFIL",
    PERFIL_COPY: "PERFIL_COPY",
    INVALID_PERFIL: "INVALID_PERFIL",
    PERFILS: "PERFILS",
    CLEAN_INVALID_PERFIL: "CLEAN_INVALID_PERFIL",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    SET_PERSONA: "SET_PERSONA"
}

export { STORE_ID, TYPES }