import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BsArrowUp, BsArrowDown, BsArrowDownUp } from "react-icons/bs";
import { AiOutlineEye, AiOutlineSearch }  from "react-icons/ai";
import ReactPaginate from "react-paginate";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import {
  Button,
  Col,
  Input,
  Label,
  Row,
  Form,
  FormGroup,
} from "reactstrap";
import * as actions from "../../../../../store/modules/conferenciaCr/actions";
import * as controller from "../../../../../controllers/conferenciaCrController";
import ModalLoja from "../../../components/ModaLoja";
import ModalDetalhamento from "../../../components/ModalDetalhamento";
import { GrFormClose } from "react-icons/gr";
import { removeMaskMoeda, maskMoeda } from "../../../../../util/masks";
import { COLUMNS } from "../../../constants/columns";
import { Select } from "../../../../../components/Inputs/Input";
import { PiMicrosoftExcelLogoLight } from "react-icons/pi";

const SearchConferenciaCr = () => {
  const {
    activeTab,
    search,
    comissoesRecebidaAgrupado,
    getAllAgrupado,
    instituicoes,
    centrosCusto,
    arquivo,
    arquivoCp,
    tiposComissao,
    contasBancaria,
    statusComissaoRecebida,
    integrado,
    contaCorrente
  } = useSelector((state) => state.conferenciaCr);
  const columns = useMemo(() => COLUMNS, []);
  const data = comissoesRecebidaAgrupado;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageSize } = state;
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [isAllComissaoActive, setIsAllComissaoActive] = useState(false);
  const [isModalDetalhamentoOpen, setIsModalDetalhamentoOpen] = useState(false);
  const [isModalLojaOpen, setIsModalLojaOpen] = useState(false);
  const [comissaoId, setComissaoId] = useState("0");
  const toggleLoja = () => setIsModalLojaOpen(!isModalLojaOpen);
  const toggleDetalhamento = () =>
    setIsModalDetalhamentoOpen(!isModalDetalhamentoOpen);

  const handlePageClick = (e) => {
    const pageSelected = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
    controller.getComissaoAgrupada(search, pageSize, pageSelected + 1);
  };

  useEffect(() => {
    if (getAllAgrupado.tamanhoPagina) {
      setPageSize(getAllAgrupado.tamanhoPagina);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllAgrupado]);

  useEffect(() => {
    controller.getTiposcomissao();
    controller.getInstituicoes();
    controller.getCentrosCusto();
    controller.getStatusAgrupamentoComissaoRecebida();
    controller.getAllContasCorrente();
   
    
  }, []);

  useEffect(() => {
    actions.setSearch("contaBancaria", "0");
    if (search.lojaId === "0") {
      actions.setContasBancaria([]);
    } else {
      controller.getContasBancaria(search.lojaId);
    }
  }, [search.lojaId]);

  function handleInitialPage(pageSize) {
    const pageSelected = 0;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
    controller.getComissaoAgrupada(search, pageSize, pageSelected + 1);
  }

  function onChange(value, field) {
    actions.setSearch(field, value);
  }

  function getAll() {
    controller.getComissaoAgrupada(search, pageSize, 1);
    gotoPage(0);
    setInitialPage(true);
    setSelectedPage(0);
  }

  function seeRegister(id) {
    setComissaoId(id);
    controller.getComissao(search, 10, 1, id);
    toggleDetalhamento();
  }

  function handleSelectComissao(id) {
    let index = comissoesRecebidaAgrupado.findIndex((e) => e.id === id);
    comissoesRecebidaAgrupado[index].isSelected =
      !comissoesRecebidaAgrupado[index].isSelected;
    actions.setComissoesAgrupado(comissoesRecebidaAgrupado);
    if (comissoesRecebidaAgrupado.length === getAllAgrupado.quantidadeTotal && comissoesRecebidaAgrupado.every((e) => e.isSelected)) {
      setIsAllComissaoActive(true);
    } else {
      setIsAllComissaoActive(false);
    }
  }

  function handleSelectAllComissoes() {
    setIsAllComissaoActive(!isAllComissaoActive);
    if (!isAllComissaoActive) {
      comissoesRecebidaAgrupado.forEach((e) => {
        e.isSelected = true;
      });
    } else {
      comissoesRecebidaAgrupado.forEach((e) => {
        e.isSelected = false;
      });
    }
  }

  async function handleBaixarComissoes() {
    await controller.baixarPlanilha(search, pageSize, 1);
    document.getElementById("download").click();
  }

  async function handleBaixaComissoes() {
    const comissoes = [];
    comissoesRecebidaAgrupado.forEach((item) => {
      if (item.isSelected) {
        comissoes.push({ id: item.id });
      }
    });
    await controller.baixaComissao(comissoes);
  }

  async function handleBaixarCP() {
    const comissoes = [];
    comissoesRecebidaAgrupado.forEach((item) => {
      if (item.isSelected) {
        comissoes.push({ id: item.id });
      }
    });
    await controller.baixarCP(comissoes);
    document.getElementById("downloadCP").click();
  }

  useEffect(() => {
    setIsAllComissaoActive(false);
  }, [activeTab]);

  function getTotalValue(values) {
    return maskMoeda(
      values
        .map((valueMasked) =>
          parseFloat(Number(removeMaskMoeda(valueMasked).replace(/ /g, "")))
        )
        .reduce((a, b) => a + b, 0)
        .toFixed(2)
    );
  }
  const contasCorrente = search.lojaId === "0" ? contaCorrente : contasBancaria;

  const totalValorBruto = getTotalValue(data.map((d) => d.valorBruto));
  const totalValorIr = getTotalValue(data.map((d) => d.valorIr));
  const totalValorLiquido = getTotalValue(data.map((d) => d.valorLiquido));

  return (
    <>
      <Col>
        <Col>
          <Col
            style={{
              margin: "30px 15px",
              border: "1px solid #ccc",
              padding: "15px",
              borderRadius: "7px",
            }}
          >
            <div style={{ marginBottom: "15px" }}>
              <Label
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginBottom: "8px",
                }}
              >
                Pesquisar
              </Label>
            </div>
            <Form>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label>Nome Arquivo:</Label>
                    <Input
                      type="text"
                      placeholder="Digite aqui"
                      value={search.nomeArquivo}
                      onChange={(e) => onChange(e.target.value, "nomeArquivo")}
                      autoComplete="true"
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>Loja:</Label>
                    <Input
                      type="text"
                      placeholder="Busque um favorecido..."
                      value={search.loja}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <GrFormClose
                  style={{
                    marginTop: "30px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onChange("", "loja");
                    onChange("0", "lojaId");
                  }}
                />
                <AiOutlineSearch
                  style={{
                    color: "var(--color-verde)",
                    marginTop: "30px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleLoja()}
                />
                <Col style={{ flex: " 0 0 22.9%" }}>
                  <FormGroup>
                    <Label>Centro Custo:</Label>
                    <Select
                      first="Selecione"
                      value={{ value: search.centroCustoId }}
                      select={{ value: "id", label: "descricao" }}
                      options={centrosCusto}
                      onChange={(e) => {
                        onChange(e.value, "centroCustoId");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>
                
                <Col md={2}>
                  <FormGroup>
                    <Label>Banco:</Label>
                    <Select
                      first="Selecione"
                      value={{ value: search.instituicaoId }}
                      select={{ value: "id", label: "nome" }}
                      options={instituicoes}
                      onChange={(e) => {
                        onChange(e.value, "instituicaoId");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label>Cód. Loja Banco:</Label>
                    <Input
                      type="text"
                      placeholder="Digite aqui"
                      value={search.lojaBanco}
                      onChange={(e) => onChange(e.target.value, "lojaBanco")}
                      autoComplete="true"
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label>Tipo Comissão:</Label>
                    <Select
                      first="Selecione"
                      value={{ value: search.tipoComissaoId }}
                      select={{ value: "id", label: "descricao" }}
                      options={tiposComissao}
                      onChange={(e) => {
                        onChange(e.value, "tipoComissaoId");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label>Status Título:</Label>
                    <Select
                      first="Selecione"
                      value={{ value: search.statusComissaoRecebidaId }}
                      select={{ value: "id", label: "descricao" }}
                      options={statusComissaoRecebida}
                      onChange={(e) => {
                        onChange(e.value, "statusComissaoRecebidaId");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label>Conta Bancária:</Label>
                    <Select
                      first="Selecione"
                      value={{ value: search.contaBancaria }}
                      select={{ value: "id", label: "conta" }}
                      options={contasCorrente}
                      onChange={(e) => {
                        onChange(e.value, "contaBancaria");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row></Row>
              <Row style={{ flexWrap: "0px" }}>
                <Col md={2}>
                  <FormGroup>
                    <Label>Documento W3ERP:</Label>
                    <Input
                      type="text"
                      placeholder="Digite aqui"
                      value={search.documento}
                      onChange={(e) => onChange(e.target.value, "documento")}
                      autoComplete="true"
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label>Título:</Label>
                    <Input
                      type="text"
                      placeholder="Digite o nº do título"
                      value={search.titulo}
                      onChange={(e) => onChange(e.target.value, "titulo")}
                      autoComplete="true"
                    />
                  </FormGroup>
                </Col>
               
                    
                <Col md={2}>
                  <FormGroup>
                    <Label>Status Integração W3ERP:</Label>
                    <Select
                      first="Selecione"
                      value={{ value: search.integrado }}
                      select={{ value: "id", label: "descricao" }}
                      options={integrado}
                      onChange={(e) => {
                        onChange(e.value, "integrado");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label style={{ fontWeight: "bold" }}>
                      Emissão Inicial:
                    </Label>
                    <Input
                      type="date"
                      value={search.DataEmissaoInicial}
                      onChange={(e) =>
                        onChange(e.target.value, "DataEmissaoInicial")
                      }
                      max="9999-12-31"
                      style={{ height: "38px" }}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label style={{ fontWeight: "bold" }}>Emissão Final:</Label>
                    <Input
                      type="date"
                      value={search.DataEmissaoFinal}
                      onChange={(e) =>
                        onChange(e.target.value, "DataEmissaoFinal")
                      }
                      max="9999-12-31"
                      style={{ height: "38px" }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={2}>
                  <FormGroup>
                    <Label style={{ fontWeight: "bold" }}>
                      Vencimento Inicial:
                    </Label>
                    <Input
                      type="date"
                      value={search.DataVencimentoInicial}
                      onChange={(e) =>
                        onChange(e.target.value, "DataVencimentoInicial")
                      }
                      max="9999-12-31"
                      style={{ height: "38px" }}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label style={{ fontWeight: "bold" }}>
                      Vencimento Final:
                    </Label>
                    <Input
                      type="date"
                      value={search.DataVencimentoFinal}
                      onChange={(e) =>
                        onChange(e.target.value, "DataVencimentoFinal")
                      }
                      max="9999-12-31"
                      style={{ height: "38px" }}
                    />
                  </FormGroup>
                </Col>
                <Col md={2} style={{ marginTop: "5px" }}>
                  <Button
                    style={{
                      width: "100%",
                      height: "38px",
                      backgroundColor: "var(--color-branco)",
                      borderColor: "var(--color-laranja)",
                      color: "var(--color-laranja)",
                    }}
                    onClick={actions.cleanSearch}
                  >
                    Limpar
                  </Button>
                </Col>
                <Col md={2} style={{ marginTop: "5px" }}>
                  <Button
                    className="purpleButton"
                    style={{
                      width: "100%",
                      height: "38px",
                    }}
                    onClick={() => getAll()}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Col>
        <div
          style={{
            width: "97%",
            margin: "30px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ marginRight: "1%" }}>
            <Button
              style={{
                width: "250px",
                color: "var(--color-branco)",
                background: "var(--color-roxo)",
                borderColor: "var(--color-roxo)",
              }}
              onClick={() => handleBaixaComissoes()}
            >
              Realizar baixa dos selecionados
            </Button>
          </div>
          <div style={{ marginRight: "1%" }}>
            <Button
              style={{
                width: "250px",
                background: "var(--color-branco)",
                color: "var(--color-roxo)",
                borderColor: "var(--color-roxo)",
              }}
              onClick={() => handleBaixarComissoes()}
            >
              <PiMicrosoftExcelLogoLight
                style={{ color: "green", height: "20px", width: "20px" }}
              />
              Exportar página
            </Button>
          </div>
          <div>
            <Button
              style={{
                width: "250px",
                background: "var(--color-branco)",
                color: "var(--color-roxo)",
                borderColor: "var(--color-roxo)",
              }}
              onClick={() => handleBaixarCP()}
            >
              <PiMicrosoftExcelLogoLight
                style={{ color: "green", height: "20px", width: "20px" }}
              />
              Exportar CP dos selecionados
            </Button>
          </div>
        </div>
        <div style={{ width: "97%", overflow: `auto`, maxHeight: "700px", margin:'30px' }}>
          <table {...getTableProps()}>
            <thead
              style={{
                textAlign: "center",
                position: "sticky",
                top: 0,
                zIndex: 0,
              }}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  <th style={{ textAlign: "left",  minWidth: "20px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <input
                        style={{ display: "inline-block" }}
                        type="checkbox"
                        checked={isAllComissaoActive}
                        onClick={() => handleSelectAllComissoes()}
                      />
                    </div>
                  </th>
                  {headerGroup.headers.map((column) => (
                    <th key={column.id} style={{ minWidth: "160px" }}>
                      <span className="float-left">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BsArrowDown
                              {...column.getHeaderProps(
                                column.getSortByToggleProps({
                                  title: undefined,
                                })
                              )}
                            />
                          ) : (
                            <BsArrowUp
                              id="OrdenarAsc"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps({
                                  title: undefined,
                                })
                              )}
                            />
                          )
                        ) : (
                          <BsArrowDownUp
                            {...column.getHeaderProps(
                              column.getSortByToggleProps({
                                title: undefined,
                              })
                            )}
                          />
                        )}
                      </span>
                      {column.render("Header")}
                    </th>
                  ))}
                  <th style={{ textAlign: "center" }}>Ações</th>
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    className="text-center"
                    {...row.getRowProps()}
                    index={row.original.id}
                  >
                    <td>
                      <input
                        type="checkbox"
                        style={{ display: "flex", marginLeft: "7px" }}
                        checked={row.original.isSelected ? true : false}
                        defaultChecked={false}
                        onChange={() => handleSelectComissao(row.original.id)}
                      />
                    </td>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                    <td style={{ textAlign: "center", width: "10%" }}>
                      <>
                        <AiOutlineEye
                          style={{
                            fontSize: "1.2rem",
                            cursor: "pointer",
                            color: "#C4C7CA",
                            marginRight: "7px",
                          }}
                          onClick={() => seeRegister(row.original.id)}
                        />
                      </>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="pt-4">
          <Row className="justify-content-center">
            <Col xs={10} md={8} lg={6}>
              <div
                style={{
                  backgroundColor: "#f9f9f9",
                  padding: "20px",
                  borderRadius: "8px",
                  border: "1px solid #cbd0d1",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <strong>Valor Total Bruto:</strong> R$ {totalValorBruto}
                </div>
                <div>
                  <strong>Valor Total IR:</strong> R$ {totalValorIr}
                </div>
                <div>
                  <strong>Valor Total Líquido:</strong> R$ {totalValorLiquido}
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <Row className="pt-4">
          <Col>
            <span style={{ color: "var(--color-cinza-medio)" }}>
              Mostrando{" "}
              {getAllAgrupado.quantidade ? getAllAgrupado.quantidade : "0"} de{" "}
              {getAllAgrupado.quantidadeTotal
                ? getAllAgrupado.quantidadeTotal
                : "0"}
            </span>
          </Col>
          <Col md={8}>
            <Row className="float-right">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={getAllAgrupado.totalPaginas}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={initialPage ? 0 : selectedPage}
              />
              <Input
                type="select"
                className="pageSize"
                value={pageSize}
                onChange={(e) => {
                  handleInitialPage(e.target.value);
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 25, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Input>
            </Row>
          </Col>
        </Row>
      </Col>

      <div style={{ display: "none" }}>
        <a
          id="download"
          href={`data:application/xlsx;base64,${arquivo}`}
          download={"Planilha.xlsx"}
        >
          .
        </a>
      </div>
      <div style={{ display: "none" }}>
        <a
          id="downloadCP"
          href={`data:application/xlsx;base64,${arquivoCp}`}
          download={"PlanilhaCP.xlsx"}
        >
          .
        </a>
      </div>
      {<ModalLoja isOpen={isModalLojaOpen} toggle={toggleLoja} />}
      {
        <ModalDetalhamento
          isOpen={isModalDetalhamentoOpen}
          toggle={toggleDetalhamento}
          id={comissaoId}
        />
      }
    </>
  );
};

export default SearchConferenciaCr;
