import React from 'react'
import { BsArrowUp, BsArrowDown } from 'react-icons/bs'
import { AiOutlineMinus } from 'react-icons/ai'

import { maskMoeda, maskDate } from '../../../../util/masks'

import './style.css'
import { useSelector } from 'react-redux'
import { useState } from 'react'

import { AiOutlineEye } from 'react-icons/ai'
import { MdContentCopy } from 'react-icons/md'
import CopyToClipboard from 'react-copy-to-clipboard'

import * as controller from '../../../../controllers/dashboardController/producaoController'
import { CustomTooltip } from '../../../../components/CustomTooltip'

function getCanceladoColor(crescimento) {
  return crescimento > 0 ? 'linhaRed' : crescimento === 0 ? 'linhaAmarela': 'linhaGreen'
}

function getCanceladoArrow(crescimento) {
  if(crescimento === 0) return <AiOutlineMinus style={{color: 'var(--color-laranja)'}} />

  if(crescimento > 0) return <BsArrowUp style={{color: 'red'}} />

  return <BsArrowDown style={{color: 'var(--color-verde-claro)'}} />
}

export default function Table({data}) {
    return(
        <table style={{maxWidth: '100%'}}>
            <thead style={{maxWidth: '100%'}}>
                <tr style={{maxWidth: '100%'}}>
                    <th className='coluna'>Nome do Produto</th>
                    <th className='coluna'>Digitado</th>
                    <th className='coluna'>Total Pago</th>
                    <th className='coluna'>Andamento</th>
                    <th className='coluna'>Valor projetado</th>
                    <th className='coluna'>Cancelado</th>
                    <th className='coluna'>Ticket médio</th>
                    <th className='coluna'>Share</th>
                </tr>
            </thead>
            <tbody style={{maxWidth: '100%'}}>
                {data?.map((e, i)=>{
                    return(
                        <FirstLevelTableRow data={e} index={i}/>
                    )
                })}
            </tbody>
        </table>
    )
}

function FirstLevelTableRow({data,index}){
    const { filtrosSelecionados, tabelaProdutos} = useSelector(state => state.dashboardProducao);

    const [isLojaActive, setIsLojaActive] = useState(false)

    function handleOnClick(){
        if (!isLojaActive){
            var isSuccessed = controller.getTabelaProducaoPorProdutoLojas(filtrosSelecionados, {produtoId: data.id}, tabelaProdutos)
            isSuccessed && setIsLojaActive(!isLojaActive)
        }else{
            setIsLojaActive(!isLojaActive)
        }
    }

    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen' style={{filter: 'brightness(85%)'}}>
                    <div style={{display: 'flex'}}>
                    <div className='button' onClick={(e) => handleOnClick()}>{!isLojaActive ? '+' : '-'}</div>
                        {data.nome}
                    </div>
                </td>
                {data.statusPropostas.map((e, i)=>{
                    return(     
                            e.descricao !== 'SHARE' ? (
                                <CustomTooltip title={`Valor Comparado: R$${maskMoeda(e.valorComparado.toFixed(2))}`}>
                                <td className={e.descricao === 'CANCELADO' ? getCanceladoColor(e.crescimento) : e.crescimento > 0 ? 'linhaGreen' : e.crescimento === 0 ? 'linhaAmarela': 'linhaRed'} style={{filter: 'brightness(85%)'}}>
                                    <div>
                                    <p style={{fontSize: '11px', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                        {e.descricao !== 'SHARE' ? `(${e.quantidade})` : ''} {e.descricao === 'CANCELADO' ? getCanceladoArrow(e.crescimento) : e.crescimento > 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : e.crescimento === 0 ? <AiOutlineMinus style={{color: 'var(--color-laranja)'}}/>: <BsArrowDown style={{color: 'red'}}/> } {e.descricao !== 'SHARE' ? `(${e.crescimento}%)` : ''}
                                        </p>
                                        <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                            {e.descricao !== 'SHARE' ? `R$${maskMoeda(e.valorTotal.toFixed(2))}` : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                                        </p> 
                                    </div>
                                </td>
                            </CustomTooltip>
                        )
                        :
                        (
                            <td className={e.descricao === 'CANCELADO' ? getCanceladoColor(e.crescimento) : e.crescimento > 0 ? 'linhaGreen' : e.crescimento === 0 ? 'linhaAmarela': 'linhaRed'} style={{filter: 'brightness(85%)'}}>
                            <div>
                            <p style={{fontSize: '11px', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {e.descricao !== 'SHARE' ? `(${e.quantidade})` : ''} {e.descricao === 'CANCELADO' ? getCanceladoArrow(e.crescimento) : e.crescimento > 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : e.crescimento === 0 ? <AiOutlineMinus style={{color: 'var(--color-laranja)'}}/>: <BsArrowDown style={{color: 'red'}}/> } {e.descricao !== 'SHARE' ? `(${e.crescimento}%)` : ''}
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                    {e.descricao !== 'SHARE' ? `R$${maskMoeda(e.valorTotal.toFixed(2))}` : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                                </p> 
                            </div>
                        </td>
                        )
                    )
                })}
            </tr>
            {isLojaActive &&
            data.lojas.map((e, i) =>{
                return(
                    <SecondLevelTableRow data={e} index={i} produtoId={data.id}/>
                )
            })
            }
        </>
    )
}

function SecondLevelTableRow({data,index, produtoId}){
    const { filtrosSelecionados, tabelaProdutos} = useSelector(state => state.dashboardProducao);

    const [isConsultorActive, setIsConsultorActive] = useState(false)

    var elemento = {lojaId: data.id, produtoId: produtoId}

    function handleOnClick(){
        if (!isConsultorActive){
            var isSuccessed = controller.getTabelaProducaoPorProdutoConsultores(filtrosSelecionados, elemento, tabelaProdutos)
            isSuccessed && setIsConsultorActive(!isConsultorActive)
        }else{
            setIsConsultorActive(!isConsultorActive)
        }
    }

    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen' style={{ filter: 'brightness(90%)'}}>
                    <div style={{display: 'flex', marginLeft: '30px'}}>
                        <div className='button' onClick={(e) => handleOnClick()}>{!isConsultorActive ? '+' : '-'}</div>
                        {data.nome}
                    </div>
                </td>
                {data.statusPropostas.map((e)=>{
                    return(
                        e.descricao !== 'SHARE' ?
                        <CustomTooltip title={`Valor Comparado: R$${maskMoeda(e.valorComparado.toFixed(2))}`}>
                        <td className={e.descricao === 'CANCELADO' ? getCanceladoColor(e.crescimento) : e.crescimento > 0 ? 'linhaGreen' : e.crescimento === 0 ? 'linhaAmarela': 'linhaRed'} style={{ filter: 'brightness(90%)'}}>
                            <div>
                            <p style={{fontSize: '11px', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {e.descricao !== 'SHARE' ? `(${e.quantidade})` : ''} {e.descricao === 'CANCELADO' ? getCanceladoArrow(e.crescimento) : e.crescimento > 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : e.crescimento === 0 ? <AiOutlineMinus style={{color: 'var(--color-laranja)'}}/>: <BsArrowDown style={{color: 'red'}}/> } {e.descricao !== 'SHARE' ? `(${e.crescimento}%)` : ''}
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {e.descricao !== 'SHARE' ? `R$${maskMoeda(e.valorTotal.toFixed(2))}` : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                                </p> 
                            </div>
                        </td>
                        </CustomTooltip>
                        :
                        <td className={e.descricao === 'CANCELADO' ? getCanceladoColor(e.crescimento) : e.crescimento > 0 ? 'linhaGreen' : e.crescimento === 0 ? 'linhaAmarela': 'linhaRed'} style={{ filter: 'brightness(90%)'}}>
                            <div>
                            <p style={{fontSize: '11px', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {e.descricao !== 'SHARE' ? `(${e.quantidade})` : ''} {e.descricao === 'CANCELADO' ? getCanceladoArrow(e.crescimento) : e.crescimento > 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : e.crescimento === 0 ? <AiOutlineMinus style={{color: 'var(--color-laranja)'}}/>: <BsArrowDown style={{color: 'red'}}/> } {e.descricao !== 'SHARE' ? `(${e.crescimento}%)` : ''}
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {e.descricao !== 'SHARE' ? `R$${maskMoeda(e.valorTotal.toFixed(2))}` : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                                </p> 
                            </div>
                        </td>
                    )
                })}
            </tr>
            {isConsultorActive &&
            data.consultores.map((e, i) =>{
                return(
                    <ThirdLevelTableRow data={e} index={i} e={elemento}/>
                )
            })
            }
        </>
    )
}

function ThirdLevelTableRow({data,index, e}){
    const { filtrosSelecionados, tabelaProdutos} = useSelector(state => state.dashboardProducao);

    const [isPropostasActive, setIsPropostasActive] = useState(false)

    var elemento = {produtoId: e.produtoId, lojaId: e.lojaId, consultorId: data.id}

    function handleOnClick(){
        if (!isPropostasActive){
            var isSuccessed = controller.getTabelaProducaoPorProdutoPropostas(filtrosSelecionados, elemento, tabelaProdutos)
            isSuccessed && setIsPropostasActive(!isPropostasActive)
        }else{
            setIsPropostasActive(!isPropostasActive)
        }
    }

    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen' style={{ borderColor: 'white!important'}}>
                    <div style={{display: 'flex', marginLeft: '90px'}}>
                        <div className='button' onClick={(e) => handleOnClick()}>{!isPropostasActive ? '+' : '-'}</div>
                        {data.nome}
                    </div>
                </td>
                {data.statusPropostas.map((e)=>{
                    return(
                        e.descricao !== 'SHARE' ?
                        <CustomTooltip title={`Valor Comparado: R$${maskMoeda(e.valorComparado.toFixed(2))}`}>
                             <td className={e.descricao === 'CANCELADO' ? getCanceladoColor(e.crescimento) : e.crescimento > 0 ? 'linhaGreen' : e.crescimento === 0 ? 'linhaAmarela': 'linhaRed'} style={{ filter: 'brightness(95%)'}}>
                            <div>
                            <p style={{fontSize: '11px', marginBottom: '3px', color: 'var(--color-preto)'}}>
{ e.descricao !== 'SHARE' ? `(${e.quantidade})` : ''} {e.descricao === 'CANCELADO' ? getCanceladoArrow(e.crescimento) : e.crescimento > 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : e.crescimento === 0 ? <AiOutlineMinus style={{color: 'var(--color-laranja)'}}/>: <BsArrowDown style={{color: 'red'}}/> } {e.descricao !== 'SHARE' ? `(${e.crescimento}%)` : ''}
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {e.descricao !== 'SHARE' ? `R$${maskMoeda(e.valorTotal.toFixed(2))}` : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                                </p> 
                            </div>
                        </td>
                        </CustomTooltip>
                        :
                        <td className={e.descricao === 'CANCELADO' ? getCanceladoColor(e.crescimento) : e.crescimento > 0 ? 'linhaGreen' : e.crescimento === 0 ? 'linhaAmarela': 'linhaRed'} style={{ filter: 'brightness(95%)'}}>
                            <div>
                            <p style={{fontSize: '11px', marginBottom: '3px', color: 'var(--color-preto)'}}>
{ e.descricao !== 'SHARE' ? `(${e.quantidade})` : ''} {e.descricao === 'CANCELADO' ? getCanceladoArrow(e.crescimento) : e.crescimento > 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : e.crescimento === 0 ? <AiOutlineMinus style={{color: 'var(--color-laranja)'}}/>: <BsArrowDown style={{color: 'red'}}/> } {e.descricao !== 'SHARE' ? `(${e.crescimento}%)` : ''}
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {e.descricao !== 'SHARE' ? `R$${maskMoeda(e.valorTotal.toFixed(2))}` : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                                </p> 
                            </div>
                        </td>
                    )
                })}
            </tr>
            { isPropostasActive &&
             <>
                            <tr className='tr'>   
                        <td style={{padding: '0', margin: '0'}}>
                            <th style={{height: '40px', width: '25%', color: 'var(--color-preto)', background: '#F5F6FA'}}>Adesão</th>
                            <th style={{height: '40px',width: '50%', maxWidth: '50%', color: 'var(--color-preto)', background: '#F5F6FA'}}>Digitador</th>
                            <th style={{height: '40px', width: '210px', maxWidth: '210px',color: 'var(--color-preto)', background: '#F5F6FA'}}>Cliente</th>
                        </td>
                        <td style={{padding: '0', margin: '0', background: '#F5F6FA'}}>
                        <th style={{height: '40px', width: '109px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Data Digitação</th>
                        </td>
                        <td style={{padding: '0', margin: '0', background: '#F5F6FA'}}>
                        <th style={{height: '40px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Convênio</th>
                        </td>
                        <td style={{padding: '0', margin: '0', background: '#F5F6FA'}}>
                        <th style={{height: '40px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Produto</th>
                        </td>
                        <td style={{padding: '0', margin: '0', background: '#F5F6FA'}}>
                        <th style={{height: '40px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Status</th>
                        </td>
                        <td style={{padding: '0', margin: '0', background: '#F5F6FA'}}>
                        <th style={{height: '40px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Valor</th>
                        </td>
                        <td style={{padding: '0', margin: '0', background: '#F5F6FA'}}>
                        <th style={{height: '40px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Ações</th> 
                        </td>
                    </tr>
                    </>}
            {isPropostasActive &&
            data.propostas.map((e, i) =>{
                return(
                    <FourthLevelTableRow data={e} index={i}/>
                )
            })
            }
        </>
    )
}

function FourthLevelTableRow({data,index}){
    async function changeView(){
        const baseURL = window.location.origin
        window.open(`${baseURL}/propostas?id=${data.id}`)
    }
     return(
        <>
        <tr className='tr' key={index}>
            <td style={{ padding: '7px'}}>
                <td style={{width: '25%', borderBottom: '0'}}>
                    <CopyToClipboard text={data.adesao}>
                        <button style={{background: 'transparent', border: 'none'}}> <MdContentCopy style={{color: 'var(--color-verde-claro)', marginRight: '10px'}}/></button>
                    </CopyToClipboard>
                    {data.adesao}
                </td>
                <td style={{width: '50%', borderBottom: '0'}}>{data.digitador}</td>
                <td style={{width: '210px', borderBottom: '0'}}>{data.cliente}</td>
            </td>
            <td style={{width: '10%'}}>{maskDate(data.dataProposta, "DD/MM/YYYY")}</td>
            <td style={{width: '10%'}}>{data.convenio}</td>
            <td style={{width: '10%'}}>{data.produto}</td>
            <td style={{width: '10%'}}>{data.status}</td>
            <td style={{width: '10%'}}>{maskMoeda(data.valorFinanciado.toFixed(2))}</td>
            <td style={{width: '10%'}}>{
                    <div style={{display: 'flex'}}> 
                         <AiOutlineEye style={{fontSize: '25px', paddingBottom: '5px', cursor: 'pointer', marginRight: '5px'}} onClick={()=> changeView()}/> 
                    </div>}</td>
        </tr>
        </>
    )
}