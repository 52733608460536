export const COLUMNS = [
    {
        Header: "Número Borderô",
        accessor: "numBordero"
    },
    {
        Header: "Data de envio",
        accessor: "dataEnvio"
    },
    {
        Header: "Nº Postagem",
        accessor: "numPostagem"
    },
    {
        Header: "Status de envio",
        accessor: "statusEnvio"
    }
]

export const COLUMNS_PROPOSTAS = [
    {
        Header: "Nome pendência",
        accessor: "nomeTipoPendencia"
    },
    {
        Header: "Motivo",
        accessor: "motivo"
    },
    {
        Header: "Data pendência",
        accessor: "dataPendencia"
    },
    {
        Header: "Dias pendentes",
        accessor: "diasPendentes"
    },
]

export const COLUMNS_MODAL = [
    {
        Header: "Código de barras",
        accessor: "codBarras"
    },
    {
        Header: "Propostas",
        accessor: "numProposta"
    },
    {
        Header: "Contrato",
        accessor: "numContrato"
    },
    {
        Header: "Instituição",
        accessor: "instituicao"
    },
    {
        Header: "Status de envio",
        accessor: "statusEnvio"
    }
]
