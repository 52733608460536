import React, { useEffect, useMemo, useState } from 'react'
import { useTable, usePagination, useGlobalFilter, useSortBy } from "react-table";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Modal, ModalBody, Col, Input, Label, Row, Navbar } from "reactstrap";
import GlobalFilter from "../../../../../../../components/filter/filter";

import * as actions from '../../../../../../../store/modules/personas/papeis_store/actions';

export default function ModalFuncionarios({ isOpen, toggle }) {
    const { usuariosFuncionarios } = useSelector(state => state.personasPapeis);

    const columns = useMemo(() => [{
        Header: "CPF",
        accessor: 'cpfCnpj'
    },{
        Header: "NOME",
        accessor: 'nome'
    },], []); 
    const data = usuariosFuncionarios ?? []

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
        setGlobalFilter,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const { globalFilter, pageSize } = state;
    const [rows, setRows] = useState(0);
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    useEffect(() => {
        setRows(page?.length);
    }, [page])

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
    }

    function handleInitialPage() {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }

    function onChangeItem(value, campo) {
        actions.setPessoa(campo, value)
    }

    const pageCount = Math.ceil(data?.length / pageSize);
    return (
        <>
            <Modal
                className="text-center"
                isOpen={isOpen}
                toggle={toggle}
                backdrop={true}
                size="lg"
            >
                <Navbar expand="md" style={{ width: "100%", height: "40px", background: 'var(--color-gradiente-verde)', marginBottom: '20px' }}>
                    <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2" >Pesquisa de funcionários</h5>
                </Navbar>
                <ModalBody >
                    <Row>
                        <div style={{ width: "25%" }} className="pb-3 ml-4">
                            <Label style={{ fontWeight: '700' }}>Pesquisar</Label>
                            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        </div>
                    </Row>
                    <fieldset className={usuariosFuncionarios?.length > 0 ? "fieldset-socio" : "fieldset-socio-no-results"}>
                        <legend style={{ textAlign: "left", fontSize: "20px" }}>Resultados</legend>
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th>
                                                {column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row)
                                    return (
                                      <tr className="text-center" {...row.getRowProps()} onDoubleClick={() => { onChangeItem(row.original.nome, 'pessoa'); onChangeItem(row.original.id, 'pessoaId'); onChangeItem('0', 'usuarioBancoId'); toggle() }}>
                                            {row.cells.map((cell) => {
                                                return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <br />
                    </fieldset>
                    {usuariosFuncionarios && <Row className="pt-4">
                    <Col>
                                <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {rows} de {usuariosFuncionarios?.length}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        activeClassName={"active"}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { setPageSize(Number(e.target.value)); handleInitialPage() }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>}
                </ModalBody>
            </Modal>
        </>
    )
}