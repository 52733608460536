import { useSelector } from "react-redux";
import * as actions from "../../../store/modules/layoutParametrizacao/actions";
import * as controller from "../../../controllers/layoutParametrizacaoController";

import { Col, Input, Label, Row } from "reactstrap";

import { AiOutlineMinusCircle } from "react-icons/ai";

import { notifyWarn } from "../../../components/message/message";
import Select from "../../../components/Inputs/Select";

import { BiEdit } from "react-icons/bi";

export function MapeamentosList() {
  const {
    isInEditMode,
    layoutBeingCreated,
    layoutBeingEdited,
    layoutBeingVisualized,
    allLayoutsCamposEquivalentes,
    layoutsCamposEquivalentes,
    mapeamento,
    isInVisualizeMode,
  } = useSelector((state) => state.layoutParametrizacao);

  let item;

  if (isInEditMode) {
    item = layoutBeingEdited;
  }

  if (isInVisualizeMode) {
    item = layoutBeingVisualized;
  }

  if (!isInEditMode && !isInVisualizeMode) {
    item = layoutBeingCreated;
  }

  async function handleRemoveMapeamento(event, index, mapeamento) {
    event.preventDefault();

    if (mapeamento.id) {
      await controller.removeParametro(mapeamento.id);
      mapeamento.camposEquivalentesOpcionais.forEach(async (item) => {
        await controller.removeParametro(item.id);
      });
      await controller.getLayoutsCamposEquivalentes(
        item.tipoArquivoId,
        item.id
      );
    }

    actions.removeLayoutPlanilhaParametros({ index, isInEditMode });
  }

  function handleEditarDetalhamento(event) {
    event.preventDefault();

    actions.setIsModalDetalhamentoOpen(true);
  }

  function handleEditarStatus(event) {
    event.preventDefault();

    actions.setIsModalStatusOpen(true);
  }

  return (
    <>
      {item.layoutPlanilhaParametros?.map((e, i) => {
        return (
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              width: `${
                mapeamento.camposEquivalentesOpcionais.length === 0
                  ? 100
                  : 100 + mapeamento.camposEquivalentesOpcionais.length * 14
              }%`,
            }}
            key={`layout${i}`}
          >
            <div style={{ margin: "0" }}>
              <Row>
                <Col style={{ maxWidth: "245px" }}>
                  <Label
                    for="convenio"
                    style={{
                      fontWeight: "700",
                      color: "var(--color-preto)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Descrição
                  </Label>
                  <Input
                    type="text"
                    placeholder="Digite aqui..."
                    value={e.descricao}
                    onChange={(e) =>
                      actions.setMapeamento("descricao", e.target.value)
                    }
                    disabled
                    style={{ height: "37px" }}
                  />
                </Col>
                <Col style={{ minWidth: "235px", maxWidth: "235px" }}>
                  <Label
                    for="convenio"
                    style={{
                      fontWeight: "700",
                      color: "var(--color-preto)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Nome da coluna
                  </Label>
                  <Input
                    type="text"
                    placeholder="Digite aqui..."
                    value={e.nomeColuna}
                    onChange={(e) =>
                      actions.setMapeamento("nomeColuna", e.target.value)
                    }
                    disabled
                    style={{ height: "37px" }}
                  />
                </Col>
                {
                  <Col
                    style={{
                      display: "flex",
                      marginRight: "-1rem",
                      minWidth: "235px",
                      maxWidth: "235px",
                    }}
                  >
                    <div>
                      <Label
                        for="convenio"
                        style={{
                          fontWeight: "700",
                          color: "var(--color-preto)",
                        }}
                      >
                        Campo equivalente no sistema
                      </Label>
                      <Select
                        value={e.layoutPlanilhaCamposEquivalenteId}
                        label="campoModel"
                        options={
                          true
                            ? allLayoutsCamposEquivalentes
                            : layoutsCamposEquivalentes
                        }
                        onFocus={() => {
                          if (item.tipoArquivoId === "0") {
                            return notifyWarn(
                              "Por favor, informe o tipo do arquivo."
                            );
                          }
                        }}
                        onChange={(e) => {
                          if (item.tipoArquivoId) {
                            actions.setMapeamento(
                              "layoutPlanilhaCamposEquivalenteId",
                              e.value
                            );
                            actions.setMapeamento(
                              "layoutPlanilhaCamposEquivalente",
                              e.label
                            );
                          }
                        }}
                        disabled
                      />
                    </div>
                  </Col>
                }
                {!e.default &&
                  e.camposEquivalentesOpcionais.map((campo, index) => (
                    <Col
                      style={{
                        minWidth: "235px",
                        maxWidth: "235px",
                        display: "flex",
                      }}
                      key={`campos${index}`}
                    >
                      <div style={{ width: "100%" }}>
                        <Label
                          style={{
                            fontWeight: "700",
                            color: "var(--color-preto)",
                          }}
                        >
                          Campo equivalente opcional
                        </Label>
                        <Select
                          value={campo.layoutPlanilhaCamposEquivalenteId}
                          label="campoModel"
                          options={
                            true
                              ? allLayoutsCamposEquivalentes
                              : layoutsCamposEquivalentes
                          }
                          onFocus={() => {
                            if (item.tipoArquivoId === "0") {
                              return notifyWarn(
                                "Por favor, informe o tipo do arquivo."
                              );
                            }
                          }}
                          onChange={(e) => {
                            if (item.tipoArquivoId) {
                              var array = e.camposEquivalentesOpcionais;
                              array[index].layoutPlanilhaCamposEquivalenteId =
                                e.value;
                              actions.setMapeamento(
                                "camposEquivalentesOpcionais",
                                array
                              );
                            }
                          }}
                          disabled
                        />
                      </div>
                    </Col>
                  ))}
                {e.camposEquivalentesOpcionais.length === 0 && (
                  <Col style={{ minWidth: "235px", maxWidth: "235px" }}>
                    <Label
                      style={{
                        fontWeight: "700",
                        color: "var(--color-preto)",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Default
                    </Label>
                    <Input
                      type="text"
                      placeholder="Digite aqui..."
                      value={e.default}
                      onChange={(e) =>
                        actions.setMapeamento("default", e.target.value)
                      }
                      disabled
                      style={{ height: "37px" }}
                    />
                  </Col>
                )}

                {!isInVisualizeMode ? (
                  <button
                    style={{
                      border: "none",
                      background: "transparent",
                      margin: "25px 0 0 0",
                      color: "var(--color-preto)",
                    }}
                    onClick={(event) => handleRemoveMapeamento(event, i, e)}
                  >
                    <AiOutlineMinusCircle
                      style={{
                        color: "var(--color-laranja)",
                        marginRight: "5px",
                        fontSize: "20px",
                      }}
                    />
                  </button>
                ) : null}
              </Row>
            </div>

            {e.detalhes?.length > 0 && (
              <button
                style={{
                  border: "none",
                  background: "transparent",
                  margin: "25px 0 0 0.5rem",
                  color: "var(--color-preto)",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  handleEditarDetalhamento(event);
                }}
              >
                <BiEdit
                  style={{
                    color: "var(--color-verde)",
                    marginRight: "5px",
                    fontSize: "20px",
                  }}
                />
                Editar detalhamento
              </button>
            )}
            {e.status?.length > 0 && (
              <button
                style={{
                  border: "none",
                  background: "transparent",
                  margin: "25px 0 0 0.5rem",
                  color: "var(--color-preto)",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  handleEditarStatus(event);
                }}
              >
                <BiEdit
                  style={{
                    color: "var(--color-verde)",
                    marginRight: "5px",
                    fontSize: "20px",
                  }}
                />
                Editar status
              </button>
            )}
          </div>
        );
      })}
    </>
  );
}
