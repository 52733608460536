import React from "react";
import { useSelector } from "react-redux";
import * as BiIcons from "react-icons/bi";
import * as papeisActions from "../../../../../../../store/modules/personas/papeis_store/actions";
import * as contaActions from "../../../../../../../store/modules/personas/conta_corrente_store/actions";
import * as personasController from "../../../../../../../controllers/personasController/personasController";

function TableMatricula({ matriculas }) {
  const { seeRegister } = useSelector((state) => state.personasPapeis);

  const data = matriculas;

  async function handleRemoverItem(row, index) {
    let valid = await personasController.deleteMatricula(row.id);

    if (valid) {
      const arrMatriculas = matriculas;
      arrMatriculas.splice(index, 1);
      papeisActions.setMatriculasSelected(arrMatriculas);
      contaActions.setMatriculas(arrMatriculas);
    }
  }

  async function handleEditarItem(row) {
    const updatedRow = { ...row, clientePerfilId: row.clientePerfil.id };
    papeisActions.setMatricula(updatedRow);
    papeisActions.setIsEditingMatricula(true);
  }
  return (
    <>
      <table className="p-3 m-4" style={{ marginTop: "20px", width: "96%", borderCollapse: "collapse", textAlign: "center" }}>
        <thead style={{ textAlign: "left" }}>
          <tr>
            <th>Matrícula</th>
            <th>Perfil do Cliente</th>
            <th>Valor Renda Individual</th>
            <th style={{ textAlign: "center" }}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            return (
              <tr key={index}>
                <td>{row.numero}</td>
                <td>{row.clientePerfil.descricao}</td>
                <td> {row.valorRendaIndividual != null
                  ? row.valorRendaIndividual.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                  : ''}</td>
                <td style={{ textAlign: "center" }}>
                  <>
                    <BiIcons.BiEdit
                      style={{
                        fontSize: "1.2rem",
                        cursor: "pointer",
                        color: "#C4C7CA",
                      }}
                      onClick={() => handleEditarItem(row)}
                    />

                    {!seeRegister && (
                      <BiIcons.BiTrash
                        style={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          color: "#C4C7CA",
                        }}
                        onClick={() => handleRemoverItem(row, index)}
                      />
                    )}
                  </>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default TableMatricula;
