export const COLUMNS = [
    {
        Header: "Descrição do produto",
        accessor: "descricao",
    },
    {
        Header: "Instituição Financeira",
        accessor: "banco.nome",
    },
    {
        Header: "Tipo de operação Ribercred",
        accessor: "tipoOperacao.descricao",
    },
    {
        Header: "Produto Ribercred",
        accessor: "produto.nome",
    }
]


