import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
  data = { ...data, storeId: STORE_ID };
  store.dispatch(data);
}

export function setActiveTab(value) {
  dispatch({
    type: TYPES.SET_ACTIVE_TAB,
    value,
  });
}

export function clearPerfilDeAcessoBeingCreatedData() {
  dispatch({
    type: TYPES.CLEAR_PERFIL_DE_ACESSO_BEING_CREATED_DATA,
    value: {
      descricao: "",
      isHierarquia: false,
      isAdministrativo: false,
      telasAssociadas: [],
      lojasAssociadas: [],
    },
  });
}

export function clearPerfilDeAcessoBeingEditedData() {
  dispatch({
    type: TYPES.CLEAR_PERFIL_DE_ACESSO_BEING_EDITED_DATA,
    value: {
      id: "",
      descricao: "",
      isHierarquia: false,
      isAdministrativo: false,
      telasAssociadas: [],
      lojasAssociadas: [],
    },
  });
}

export function clearPerfilDeAcessoBeingVisualizedData() {
  dispatch({
    type: TYPES.CLEAR_PERFIL_DE_ACESSO_BEING_VISUALIZED_DATA,
    value: {
      id: "",
      descricao: "",
      isHierarquia: false,
      isAdministrativo: false,
      telasAssociadas: [],
      lojasAssociadas: [],
    },
  });
}

export function setPerfilDeAcessoBeingEditedId(value) {
  dispatch({
    type: TYPES.SET_PERFIL_DE_ACESSO_BEING_EDITED_ID,
    value,
  });
}

export function setPerfilDeAcessoBeingVisualizedId(value) {
  dispatch({
    type: TYPES.SET_PERFIL_DE_ACESSO_BEING_VISUALIZED_ID,
    value,
  });
}

export function setIsInEditMode(value) {
  dispatch({
    type: TYPES.SET_IS_IN_EDIT_MODE,
    value,
  });
}

export function setIsInVisualizeMode(value) {
  dispatch({
    type: TYPES.SET_IS_IN_VISUALIZE_MODE,
    value,
  });
}

export function setPerfilDeAcessoBeingCreatedData({ campo, value }) {
  dispatch({
    type: TYPES.SET_PERFIL_DE_ACESSO_BEING_CREATED_DATA,
    campo,
    value,
  });
}

export function setPerfilDeAcessoBeingEditedData({ campo, value }) {
  dispatch({
    type: TYPES.SET_PERFIL_DE_ACESSO_BEING_EDITED_DATA,
    campo,
    value,
  });
}

export function setPerfilDeAcessoBeingVisualizedData({ campo, value }) {
  dispatch({
    type: TYPES.SET_PERFIL_DE_ACESSO_BEING_VISUALIZED_DATA,
    campo,
    value,
  });
}

export function setPerfisDeAcesso(value) {
  dispatch({
    type: TYPES.SET_PERFIS_DE_ACESSO,
    value,
  });
}

export function setTelasDisponiveis(value) {
  dispatch({
    type: TYPES.SET_TELAS_DISPONIVEIS,
    value,
  });
}

export function setLojasDisponiveis(value) {
  dispatch({
    type: TYPES.SET_LOJAS_DISPONIVEIS,
    value,
  });
}

export function setTelasDisponiveisInitialValueBackup(value) {
  dispatch({
    type: TYPES.SET_TELAS_DISPONIVEIS_INITIAL_VALUE_BACKUP,
    value,
  });
}

export function setLojasDisponiveisInitialValueBackup(value) {
  dispatch({
    type: TYPES.SET_LOJAS_DISPONIVEIS_INITIAL_VALUE_BACKUP,
    value,
  });
}

export function setTelasSelecionadas(value) {
  dispatch({
    type: TYPES.SET_TELAS_SELECIONADAS,
    value,
  });
}

export function setLojasSelecionadas(value) {
  dispatch({
    type: TYPES.SET_LOJAS_SELECIONADAS,
    value,
  });
}

export function setTelasAssociadas(value) {
  dispatch({
    type: TYPES.SET_TELAS_ASSOCIADAS,
    value,
  });
}

export function setLojasAssociadas(value) {
  dispatch({
    type: TYPES.SET_LOJAS_ASSOCIADAS,
    value,
  });
}

export function setTelasDisponiveisSearchTerm(value) {
  dispatch({
    type: TYPES.SET_TELAS_DISPONIVEIS_SEARCH_TERM,
    value,
  });
}

export function setLojasDisponiveisSearchTerm(value) {
  dispatch({
    type: TYPES.SET_LOJAS_DISPONIVEIS_SEARCH_TERM,
    value,
  });
}

export function setTelasDisponiveisSearchResults(value) {
  dispatch({
    type: TYPES.SET_TELAS_DISPONIVEIS_SEARCH_RESULTS,
    value,
  });
}

export function setLojasDisponiveisSearchResults(value) {
  dispatch({
    type: TYPES.SET_LOJAS_DISPONIVEIS_SEARCH_RESULTS,
    value,
  });
}

export function setTelasDisponiveisSelectedSearchResults(value) {
  dispatch({
    type: TYPES.SET_TELAS_DISPONIVEIS_SELECTED_SEARCH_RESULTS,
    value,
  });
}

export function setLojasDisponiveisSelectedSearchResults(value) {
  dispatch({
    type: TYPES.SET_LOJAS_DISPONIVEIS_SELECTED_SEARCH_RESULTS,
    value,
  });
}

export function setTelasAssociadasSearchTerm(value) {
  dispatch({
    type: TYPES.SET_TELAS_ASSOCIADAS_SEARCH_TERM,
    value,
  });
}

export function setLojasAssociadasSearchTerm(value) {
  dispatch({
    type: TYPES.SET_LOJAS_ASSOCIADAS_SEARCH_TERM,
    value,
  });
}

export function setTelasAssociadasSearchResults(value) {
  dispatch({
    type: TYPES.SET_TELAS_ASSOCIADAS_SEARCH_RESULTS,
    value,
  });
}

export function setLojasAssociadasSearchResults(value) {
  dispatch({
    type: TYPES.SET_LOJAS_ASSOCIADAS_SEARCH_RESULTS,
    value,
  });
}

export function setTelasAssociadasSelectedSearchResults(value) {
  dispatch({
    type: TYPES.SET_TELAS_ASSOCIADAS_SELECTED_SEARCH_RESULTS,
    value,
  });
}

export function setLojasAssociadasSelectedSearchResults(value) {
  dispatch({
    type: TYPES.SET_LOJAS_ASSOCIADAS_SELECTED_SEARCH_RESULTS,
    value,
  });
}

export function setTelasAssociadasSelecionadas(value) {
  dispatch({
    type: TYPES.SET_TELAS_ASSOCIADAS_SELECIONADAS,
    value,
  });
}

export function setLojasAssociadasSelecionadas(value) {
  dispatch({
    type: TYPES.SET_LOJAS_ASSOCIADAS_SELECIONADAS,
    value,
  });
}

export function setLoadingTelas(value) {
  dispatch({
    type: TYPES.SET_LOADING_TELAS,
    value,
  });
}

export function setLoadingLojas(value) {
  dispatch({
    type: TYPES.SET_LOADING_LOJAS,
    value,
  });
}

export function setPermitions(value) {
  dispatch({
    type: TYPES.SET_PERMITIONS,
    value,
  });
}
