import React, { useEffect, useMemo, useState } from "react";

import ReactPaginate from "react-paginate";

import {
  Button,
  Card,
  Col,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Form,
  FormGroup,
} from "reactstrap";

import * as actions from "../../../store/modules/vinculoLoja/actions";

import * as vinculoLojaController from "../../../controllers/vinculoLojaController";

import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { COLUMNS } from "../constants/columns";

import { Select } from "../../../components/Inputs/Input";

import { useSelector } from "react-redux";

const Search = () => {
  const {
    bancos,
    permitions,
    items,
    search,
    lojasArrecadadoras,
    contas,
    tiposComissao,
    getAllItems
  } = useSelector((state) => state.vinculoLoja);

  const columns = useMemo(() => COLUMNS, []);
  const data = items;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageSize } = state;
  const [itemSelected, setItem] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    setPageSize(10);
    vinculoLojaController.get(selectedPage, pageSize, search);
    vinculoLojaController.getBancos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(getAllItems.tamanhoPagina){
        setPageSize(getAllItems.tamanhoPagina);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [getAllItems])

  function onChangeSearch(value, field) {
    actions.setSearch({ ...search, [field]: value });
  }

  function onInstituicaoChange(bancoId) {
    onChangeSearch(bancoId, "bancoId");

    actions.cleanSearchField("lojaId");
    actions.setLojasArrecadadoras([]);

    actions.cleanSearchField("tiposComissaoId");
    actions.setTiposComissao([]);

    actions.cleanSearchField("contaCorrenteId");
    actions.setContas([]);


    if (bancoId !== "0") {
      vinculoLojaController.getLojasArrecadadoras(bancoId);
    }
  }

  async function onLojaArrecadadoraChange(lojaId, bancoId) {
    onChangeSearch(lojaId, "lojaId");

    actions.cleanSearchField("tiposComissaoId");
    actions.setTiposComissao([]);

    actions.cleanSearchField("contaCorrenteId");
    actions.setContas([]);

    if (lojaId !== "0") {
      vinculoLojaController.getContaCorrente(lojaId);
      vinculoLojaController.getTiposComissao(bancoId);
    }
  }

  const handlePageClick = (e) => {
    const pageSelected = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
    vinculoLojaController.get(pageSelected, pageSize, search);
  };

  function handleSee(id) {
    actions.setSeeRegister(true);
    actions.setActiveTab("Register");
    vinculoLojaController.getById(id);
  }

  function handleToggleDeleteModal(id) {
    setItem(id);
    toggleModalDelete();
  }

  function toggleModalDelete() {
    setModalDelete(!modalDelete);
  }

  async function handleUpdate(id) {
    actions.setActiveTab("Register");
    vinculoLojaController.getById(id);
  }

  async function handleDelete() {
    const isSuccessed = await vinculoLojaController.remove(itemSelected);
    if (isSuccessed) {
      vinculoLojaController.get(selectedPage, pageSize, search);
    }
  }

  function handleInitialPage(pageSize) {
    gotoPage(0);
    setInitialPage(true);
    setSelectedPage(0);
    vinculoLojaController.get(0, pageSize, search);
  }

  function handleSearch() {
    vinculoLojaController.get(selectedPage, pageSize, search);
  }

  return (
    <>
      <Modal
        className="text-center"
        isOpen={modalDelete}
        toggle={toggleModalDelete}
        backdrop={false}
      >
        <ModalHeader
          toggle={toggleModalDelete}
          style={{ height: "10px", border: "none" }}
        />
        <ModalBody>
          <p style={{ fontSize: "18px", color: "var(--color-preto)" }}>
            Tem certeza que deseja{" "}
            <strong style={{ fontSize: "17px" }}>remover</strong> esse item?
          </p>
        </ModalBody>
        <div className="m-3">
          <Button
            onClick={() => toggleModalDelete()}
            color="danger"
            style={{ width: "100px", marginRight: "15px" }}
          >
            {" "}
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleDelete();
              toggleModalDelete();
            }}
            style={{
              width: "100px",
              marginLeft: "15px",
              background: "var(--color-verde)",
              border: "none",
            }}
          >
            {" "}
            Confirmar
          </Button>
        </div>
      </Modal>
      <Col>
        <Card className="pt-3 pb-2 mb-5">
          <Col>
            <div style={{ width: "25%" }} className="pb-3">
              <Label
                style={{
                  fontWeight: "400",
                  fontSize: "18px",
                  margin: "5px 0 8px 15px",
                }}
              >
                Pesquisar
              </Label>
            </div>
            <Form style={{ margin: "0px 0px 15px 15px" }}>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label>Instituição</Label>
                    <Select
                      first="SELECIONE"
                      value={{ value: search.bancoId }}
                      select={{ value: "id", label: "nome" }}
                      options={bancos}
                      onChange={(e) => {
                        onInstituicaoChange(e.value);
                      }}
                      isSearchable={true}
                      autoComplete="off"
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "35px",
                          height: "35px",
                        }),
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Loja arrecadadora</Label>
                    <Select
                      first="SELECIONE"
                      value={{ value: search.lojaId }}
                      select={{ value: "id", label: "descricao" }}
                      options={lojasArrecadadoras}
                      onChange={(e) => {
                        onLojaArrecadadoraChange(e.value, search.bancoId);
                      }}
                      isSearchable={true}
                      autoComplete="off"
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "35px",
                          height: "35px",
                        }),
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Tipo comissão</Label>
                    <Select
                      first="SELECIONE"
                      value={{ value: search.tiposComissaoId }}
                      select={{ value: "id", label: "descricao" }}
                      options={tiposComissao}
                      onChange={(e) => {
                        onChangeSearch(e.value, "tiposComissaoId");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "35px",
                          height: "35px",
                        }),
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label>Conta</Label>
                    <Select
                      first="SELECIONE"
                      value={{ value: search.contaCorrenteId }}
                      select={{ value: "id", label: "conta" }}
                      options={contas}
                      onChange={(e) => {
                        onChangeSearch(e.value, "contaCorrenteId");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "35px",
                          height: "35px",
                        }),
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button
                    className="mr-4"
                    style={{
                      width: "150px",
                      background: "var(--color-branco)",
                      borderColor: "var(--color-laranja)",
                      color: "var(--color-laranja)",
                      borderRadius: "7px",
                    }}
                    onClick={actions.cleanSearch}
                  >
                    Limpar
                  </Button>
                  <Button
                    className="purpleButton"
                    style={{ width: "150px" }}
                    onClick={handleSearch}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th>
                        {column.render("Header")}
                        <span className="float-right">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BsIcons.BsArrowDown
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps({
                                    title: undefined,
                                  })
                                )}
                              />
                            ) : (
                              <BsIcons.BsArrowUp
                                id="OrdenarAsc"
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps({
                                    title: undefined,
                                  })
                                )}
                              />
                            )
                          ) : (
                            <BsIcons.BsArrowDownUp
                              {...column.getHeaderProps(
                                column.getSortByToggleProps({
                                  title: undefined,
                                })
                              )}
                            />
                          )}
                        </span>
                      </th>
                    ))}
                    <th style={{ textAlign: "center" }}>Ações</th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="text-center" {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                      <td style={{ textAlign: "center", width: "10%" }}>
                        <>
                          {permitions.criar && (
                            <BiIcons.BiEdit
                              style={{
                                fontSize: "1.2rem",
                                cursor: "pointer",
                                color: "#C4C7CA",
                                marginRight: "7px",
                              }}
                              onClick={() => handleUpdate(row.original.id)}
                            />
                          )}
                          <AiIcons.AiOutlineEye
                            style={{
                              fontSize: "1.2rem",
                              cursor: "pointer",
                              color: "#C4C7CA",
                              marginRight: "7px",
                            }}
                            onClick={() => handleSee(row.original.id)}
                          />
                          {permitions.deletar && (
                            <BiIcons.BiTrash
                              style={{
                                fontSize: "1.2rem",
                                cursor: "pointer",
                                color: "#C4C7CA",
                              }}
                              onClick={() =>
                                handleToggleDeleteModal(row.original.id)
                              }
                            />
                          )}
                        </>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Row className="pt-4">
              <Col>
              <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {getAllItems.quantidade ? getAllItems.quantidade : "0"} de {getAllItems.quantidadeTotal ? getAllItems.quantidadeTotal : "0"}</span>
              </Col>
              <Col md={8}>
                <Row className="float-right">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    initialPage={0}
                    pageCount={getAllItems.totalPaginas}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => handlePageClick(e)}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={initialPage ? 0 : selectedPage}
                  />
                  <Input
                    type="select"
                    className="pageSize"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                      handleInitialPage(Number(e.target.value));
                    }}
                  >
                    {[10, 25, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Input>
                </Row>
              </Col>
            </Row>
          </Col>
        </Card>
      </Col>
    </>
  );
};

export default Search;
