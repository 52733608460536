import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    item: {
        descricao: "",
        id: ""
    },
    copy: {},
    invalid: {
        descricao: false,
    },
    tipos: [],
    activeTab: "Search",
    seeRegister: false,
    permitions: {
        criar: false,
        deletar: false,
    }
}

export default function tipoFormalizacaoRiber(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.SET_TIPOS:
                    return { ...state, tipos: action.value }
                case TYPES.SET_ITEM:
                    return { ...state, item: {...state.item, [action.field] : action.value} }
                case TYPES.SET_INVALID:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.SET_COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, item: INITIAL_STATE.item, invalid: INITIAL_STATE.invalid }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}