import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as perfilActions from "../store/modules/perfilCliente/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("clienteperfil");
    setLoading(false)

    if (!data.success) {
        return;
    }

    perfilActions.items(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`clienteperfil/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    perfilActions.copy(data.content);
    perfilActions.item(data.content);
}

export async function create(item) {

    if (item.descricao.trim() === "") {
        perfilActions.setInvalid("descricao");
        notifyWarn("Descrição do perfil do cliente não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao
    }

    setLoading(true)
    const data = await api.post("clienteperfil", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Perfil do cliente cadastrado");
    perfilActions.cleanRegister();
    get();
}

export async function update(item, copy) {

    if (item.descricao === copy.descricao) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (item.descricao === "") {
        perfilActions.setInvalid("descricao");
        notifyWarn("Descrição do perfil do cliente não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao
    }

    setLoading(true)
    const data = await api.put(`clienteperfil/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Perfil do cliente aualizado");
    perfilActions.cleanRegister();
    perfilActions.setActiveTab("Search");
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`clienteperfil/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Perfil do cliente removido");
    get();
}
