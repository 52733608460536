import { STORE_ID, TYPES } from "./types";

export const INITIAL_STATE = {
  matriculasSelected: [],
  matriculasSelectBox: [],
  conveniosSelected: [],
  contasCorrenteSelected: [],
  lojasBancosSelected: [],
  instituicoesFinanceirasSelected: [],
  centrosDeCustoSelected: [],
  personasSelected: [],
  clienteCPF: {
    profissao: "",
    dataAlteracao: "",
    id: null,
  },
  centroCusto: {
    id: "0",
  },
  funcionario: {
    ramal: "",
    possuiRamal: null,
    tipoFuncionarioId: "0",
    id: null,
    codigoEmpresa: "",
    razaoSocialEmpresa: "",
    codigoCentroCusto: "",
    dataDemissao: "",
    lojaId: "0",
  },

  matricula: {
    clientePerfilId: "0",
    valorRendaIndividual: "",
    clientePerfil: {
      descricao: "",
      id: "0",
    },
    numero: "",
    id: null,
  },
  instituicaoFinanceira: {
    codInstituicao: "0",
    dataInicioVigencia: "",
    id: null,
    dataFimVigencia: "",
  },
  clienteCNPJ: {
    regimeTributarioId: "0",
    valorFaturamento: "",
    id: null,
  },
  tipoLoja: {
    dataInicio: "",
    dataFim: "",
    tipoLojaId: "0",
    empresa: "",
    canalId: "0",
    dataInicioVigencia: "",
    id: null,
    dataFimVigencia: "",
    isMatriz: false,
    exibeSimulador: false,
    matrizId: "0",
    ir: "0.00",
    cdEmpresaW3ERP: null,
  },

  instituicaoFinanceiraJuridica: false,
  bancos: [],
  usuariosBanco: [],
  convenios: [],
  masters: [],
  matrizes: [],
  instituicoesFinanceira: [],
  lojas: [],
  funcionariosLoja: [],
  isEditing: false,
  instituicoesBancarias: [],
  centrosDeCustoV: [],
  centrosDeCusto: [],
  ausencias: [],
  tiposFuncionarios: [],
  tiposLoja: [],
  regimesTributario: [],
  canais: [],
  tiposParcerias: [],
  niveisHierarquicos: [],
  usuariosFuncionarios: [],
  perfilUsuarios: [],
  profissoes: [],
  cliente_cpf: {
    isClienteCpfActive: false,
    isFuncionarioActive: false,
    isUserInstituicaoActive: false,
  },
  cliente_cnpj: {
    isClienteCnpjActive: false,
    isMatrizActive: false,
    isInstituicaoActive: false,
    isFornecedor: false,
  },
  seeRegister: false,

  invalid: {
    valorRendaIndividual: false,
    profissao: false,
    matricula: false,
  },
  instituicao: {
    bancoId: "0",
    dataVigenciaInicial: "",
    dataVigenciaFinal: "",
    id: null,
    usuarioInstituicao: "",
    statusUsuario: false,
    lojaId: "0",
    centroCustoId: "0",
    vinculoCentroCustoId: "0",
    canalId: "0",
    nivelHierarquicoId: "0",
    headCount: false,
    possuiRelacionamento: false,
  },
  lojaBanco: {
    id: "0",
    lojaMasterId: "0",
    bancoId: "0",
    tipoParceria: "",
    tipoParceriaId: "0",
    codigoLojaBanco: "",
    hasLojaMaster: false,
    dataVigenciaInicial: "",
    dataVigenciaFinal: "",
    isEditable: false,
    isDataFinalEditable: false,
  },
  pessoa: {
    hierarquiaId: "0",
    dataVigenciaInicial: "",
    dataVigenciaFinal: "",
    pessoaId: "0",
    pessoa: "",
    headCount: false,
    usuarioBancoId: "0",
    centroCustoId: "0",
    centroCusto: "",
    isEditable: false,
    isDataFinalEditable: false,
  },
  filterPessoa: {
    cpf: "",
    nome: "",
    hierarquiaId: "0",
    instituicaoId: "0",
    usuarioInstituicaoId: "0",
    status: "0",
  },
  filterPessoaApplied: {
    cpf: "",
    nome: "",
    hierarquiaId: "0",
    instituicaoId: "0",
    usuarioInstituicaoId: "0",
    status: "0",
  },
  instituicaoBanco: {
    banco: false,
  },
  fornecedor: {
    id: "",
    codigoFornecedor: "",
  },
  hierarquiaParaAlterar: {
    pessoaId: "0",
    hierarquiaAntigaId: "0",
    hierarquiaNovaId: "0",
    lojaId: "0",
    alteracaoConfirmada: false,
  },
};

export default function personasPapeis(state = INITIAL_STATE, action) {
  switch (action.storeId) {
    case STORE_ID:
      switch (action.type) {
        case TYPES.SET_IS_EDITING:
          return { ...state, isEditing: action.value };

        case TYPES.SET_INSTITUICOES_BANCARIAS:
          return { ...state, instituicoesBancarias: action.value };

        case TYPES.SET_CLIENTE_CPF:
          return { ...state, clienteCPF: action.value };

        case TYPES.SET_MATRICULAS_SELECTED:
          return { ...state, matriculasSelected: action.value };

        case TYPES.SET_CONVENIOS_SELECTED:
          return { ...state, conveniosSelected: action.value };

        case TYPES.CLIENTE_CNPJ:
          return { ...state, clienteCNPJ: action.value };

        case TYPES.CONTA_CORRENTE:
          return { ...state, contasCorrenteSelected: action.value };

        case TYPES.FUNCIONARIO:
          return { ...state, funcionario: action.value };

        case TYPES.TIPO_LOJA:
          return { ...state, tipoLoja: action.value };
        case TYPES.SET_FUNCIONARIO_LOJA:
          return { ...state, funcionariosLoja: action.value };

        case TYPES.SET_INSTITUICAO_FINANCEIRA_JURIDICA:
          return { ...state, instituicaoFinanceiraJuridica: action.value };

        case TYPES.USER_INSTITUICAO:
          return { ...state, instituicoesFinanceirasSelected: action.value };

        case TYPES.SET_LOJAS_BANCO:
          return { ...state, lojasBancosSelected: action.value };

        case TYPES.SET_USUARIOS_BANCO:
          return { ...state, usuariosBanco: action.value };

        case TYPES.SET_PERSONAS:
          return { ...state, personasSelected: action.value };

        case TYPES.CLEAN_PERSONAS:
          return { ...state, personasSelected: INITIAL_STATE.personasSelected };

        case TYPES.SET_MASTERS:
          return { ...state, masters: action.value };
        case TYPES.SET_MATRIZES:
          return { ...state, matrizes: action.value };

        case TYPES.SET_CENTROS_SELECTED:
          return { ...state, centrosDeCustoSelected: action.value };

        case TYPES.SET_CENTRO_CUSTO:
          return { ...state, centrosDeCusto: action.value };

        case TYPES.SET_CENTRO_CUSTOV:
          return { ...state, centrosDeCustoV: action.value };

        case TYPES.SET_AUSENCIAS:
          return { ...state, ausencias: action.value };

        case TYPES.SET_TIPOS_FUNCIONARIOS:
          return { ...state, tiposFuncionarios: action.value };

        case TYPES.SET_INSTITUICOES_FINANCEIRAS:
          return { ...state, instituicoesFinanceira: action.value };

        case TYPES.SET_LOJAS:
          return { ...state, lojas: action.value };

        case TYPES.SET_TIPOS_LOJA:
          return { ...state, tiposLoja: action.value };

        case TYPES.SET_CANAIS:
          return { ...state, canais: action.value };

        case TYPES.SET_CONVENIOS:
          return { ...state, convenios: action.value };

        case TYPES.SET_BANCOS:
          return { ...state, bancos: action.value };

        case TYPES.SET_REGIMES_TRIB:
          return { ...state, regimesTributario: action.value };

        case TYPES.TIPOS_PARCERIAS:
          return { ...state, tiposParcerias: action.value };

        case TYPES.SET_NIVEIS_HIERARQUICOS:
          return { ...state, niveisHierarquicos: action.value };

        case TYPES.SET_USUARIO_FUNCIONARIO:
          return { ...state, usuariosFuncionarios: action.value };

        case TYPES.SET_CLIENTE_ACTIVE:
          return {
            ...state,
            cliente_cpf: { ...state.cliente_cpf, [action.field]: action.value },
          };

        case TYPES.SET_CLIENTE_CNPJ_ACTIVE:
          return {
            ...state,
            cliente_cnpj: {
              ...state.cliente_cnpj,
              [action.field]: action.value,
            },
          };

        case TYPES.SET_SEE:
          return { ...state, seeRegister: action.value };

        case TYPES.SET_INVALID:
          return {
            ...state,
            invalid: { ...state.invalid, [action.field]: true },
          };

        case TYPES.CLEAN_INVALID:
          return {
            ...state,
            invalid: { ...state.invalid, [action.field]: false },
          };

        case TYPES.SET_PERFIL_USUARIOS:
          return { ...state, perfilUsuarios: action.value };

        case TYPES.SET_PROFISSOES:
          return { ...state, profissoes: action.value };

        case TYPES.SET_INSTITUICAO:
          return {
            ...state,
            instituicao: { ...state.instituicao, [action.campo]: action.value },
          };

        case TYPES.CLEAN_INSTITUICAO:
          return { ...state, instituicao: INITIAL_STATE.instituicao };

        case TYPES.SET_MATRICULA:
          return { ...state, matricula: action.value };

        case TYPES.SET_CENTROCUSTO:
          return { ...state, centroCusto: action.value };

        case TYPES.SET_LOJA_BANCO:
          return {
            ...state,
            lojaBanco: { ...state.lojaBanco, [action.field]: action.value },
          };

        case TYPES.LOJA_BANCO:
          return { ...state, lojaBanco: action.value };

        case TYPES.CLEAN_LOJA_BANCO:
          return { ...state, lojaBanco: INITIAL_STATE.lojaBanco };

        case TYPES.SET_PESSOA:
          return {
            ...state,
            pessoa: { ...state.pessoa, [action.field]: action.value },
          };

        case TYPES.SET_FILTER_PESSOA:
          return {
            ...state,
            filterPessoa: {
              ...state.filterPessoa,
              [action.field]: action.value,
            },
          };

        case TYPES.CLEAR_FILTER_PESSOA:
          return { ...state, filterPessoa: INITIAL_STATE.filterPessoa };

        case TYPES.SET_FILTER_PESSOA_APPLIED:
          return { ...state, filterPessoaApplied: action.value };

        case TYPES.CLEAR_FILTER_PESSOA_APPLIED:
          return {
            ...state,
            filterPessoaApplied: INITIAL_STATE.filterPessoaApplied,
          };

        case TYPES.PESSOA:
          return { ...state, pessoa: action.value };

        case TYPES.CLEAN_REGISTER:
          return {
            ...state,
            cliente_cnpj: INITIAL_STATE.cliente_cnpj,
            cliente_cpf: INITIAL_STATE.cliente_cpf,
            clienteCPF: INITIAL_STATE.clienteCPF,
            clienteCNPJ: INITIAL_STATE.clienteCNPJ,
            funcionario: INITIAL_STATE.funcionario,
            instituicaoFinanceira: INITIAL_STATE.instituicaoFinanceira,
            tipoLoja: INITIAL_STATE.tipoLoja,
            centroCusto: INITIAL_STATE.centrosDeCusto,
            instituicaoFinanceiraJuridica:
              INITIAL_STATE.instituicaoFinanceiraJuridica,
            matriculasSelected: [],
            conveniosSelected: [],
            contasCorrenteSelected: [],
            lojasBancosSelected: [],
            instituicoesFinanceirasSelected: [],
            centrosDeCustoSelected: [],
            personasSelected: [],
            invalid: INITIAL_STATE.invalid,
            matricula: INITIAL_STATE.matricula,
            lojaBanco: INITIAL_STATE.lojaBanco,
            pessoa: INITIAL_STATE.pessoa,
            instituicao: INITIAL_STATE.instituicao,
            instituicaoBanco: INITIAL_STATE.instituicaoBanco,
            isChangePapeis: INITIAL_STATE.isChangePapeis,
            fonecedor: INITIAL_STATE.fonecedor,
          };
       
        case TYPES.CLEAN_MATRICULA:
          return { ...state, matricula: INITIAL_STATE.matricula };

        case TYPES.CLEAN_INSTITUICAO_FINANCEIRA:
          return {
            ...state,
            instituicaoFinanceira: INITIAL_STATE.instituicaoFinanceira,
          };

        case TYPES.CLEAN_PERSONA:
          return { ...state, pessoa: INITIAL_STATE.pessoa };

        case TYPES.SET_ACTIVE_TAB_CPF:
          return { ...state, activeTab: action.value };

        case TYPES.SET_INSTITUICAO_BANCO:
          return {
            ...state,
            instituicaoBanco: {
              ...state.instituicaoBanco,
              [action.field]: action.value,
            },
          };

        case TYPES.CHANGE_TAB:
          return { ...state, isChangePapeis: action.value };

        case TYPES.SET_FORNECEDOR:
          return { ...state, fornecedor: action.value };

        case TYPES.CLEAN_FORNECEDOR:
          return { ...state, fornecedor: INITIAL_STATE };

        case TYPES.CLEAN_CHANGE_TAB:
          return {
            ...state,
            isChangePapeis: {
              clienteCpfActiveTab: false,
              funcionarioActiveTab: false,
              userInstituicaoActiveTab: false,
              clienteCnpjActiveTab: false,
              matrizActiveTab: false,
              instituicaoActiveTab: false,
              fornecedorTab: false,
            },
          };

        case TYPES.CLEAN_CLIENTE_CNPJ_RELACIONAMENTO:
          return { ...state, clienteCNPJ: INITIAL_STATE.clienteCNPJ };

        case TYPES.CLEAN_LOJA_RELACIONAMENTO:
          return {
            ...state,
            tipoLoja: INITIAL_STATE.tipoLoja,
            centrosDeCustoV: INITIAL_STATE.centrosDeCustoV,
            lojaBanco: INITIAL_STATE.lojaBanco,
            pessoa: INITIAL_STATE.pessoa,
          };

        case TYPES.CLEAN_INSTITUICAO_RELACIONAMENTO:
          return {
            ...state,
            instituicaoBanco: INITIAL_STATE.instituicaoBanco,
          };

        case TYPES.CLEAN_FORNECEDOR_RELACIONAMENTO:
          return {
            ...state,
            fornecedor: INITIAL_STATE.fornecedor,
          };

        case TYPES.CLEAN_CLIENTE_CPF_RELACIONAMENTO:
          return {
            ...state,
            clienteCPF: INITIAL_STATE.clienteCPF,
            matricula: INITIAL_STATE.matricula,
            matriculasSelected: INITIAL_STATE.matriculasSelected,
          };

        case TYPES.CLEAN_USER_INSTITUICAO_RELACIONAMENTO:
          return {
            ...state,
            instituicao: INITIAL_STATE.instituicao,
            instituicoesFinanceirasSelected:
              INITIAL_STATE.instituicoesFinanceirasSelected,
          };
        case TYPES.SET_MATRICULAS_SELECT_BOX:
          return { ...state, matriculasSelectBox: action.value };

        case TYPES.SET_ALTERA_HIERARQUIA:
          return {
            ...state,
            hierarquiaParaAlterar: {
              ...state.hierarquiaParaAlterar,
              [action.field]: action.value,
            },
          };

        case TYPES.CLEAN_ALTERAR_HIERARQUIA:
          return {
            ...state,
            hierarquiaParaAlterar: INITIAL_STATE.hierarquiaParaAlterar,
          };

        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
}
