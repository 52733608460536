import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    pesquisar: {
        numeroBordero: '',
        classificacaoId: '',
        instituicaoId: '0',
        statusId: '',
        dataInicial: '',
        dataFinal: '',
        lojaId: '0'
    },
    modal:{
        id: '',
        codBarras: '',
        numProposta: '',
        numContrato: '',
        steps: [],
        flagFinalizado: false,
        propostas: []
    },
    item:{
        id: '0',
        instituicaoId: '0',
        destinatarioId: '0',
        lojaId: '0',
        propostasItem: [],
        propostas: [],
        detalhamento: []
    },
    impressao:{
        codBarras: '',
        parceiro: '',
        usuario: '',
        etiqueta: '',
        dataGerado: '',
        totalPropostas: '0',
        propostas:[]
    },
    etiqueta: {
        codRatreio: '',
        nomeDestinatario: '',
        ruaDestinatario:'',
        numeroDestinatario: '',
        bairroDestinatario: '',
        complementoDestinatario: '',
        cepDestinatario: '',
        cidadeDestinatario: '',
        ufDestinatario: '',
        nomeRemetente: '',
        ruaRemetente:'',
        numeroRemetente: '',
        bairroRemetente: '',
        complementoRemetente: '',
        cepRemetente: '',
        cidadeRemetente: '',
        ufRemetente: '',
    },
    formalizacoes: [],
    instituicoes: [],
    destinatarios: [],
    lojas: [],
    activeTab: "Search",
    seeRegister: false,
    permitions: {
        criar: false,
        autorizar: false,
    },
    getAllItems:{quantidade: '0',
                quantidadeTotal: '0',
                totalPaginas: '0'},
}

export default function formalizacaoLoja(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.PESQUISAR:
                    return { ...state, pesquisar: { ...state.pesquisar, [action.campo]: action.value } };
                case TYPES.ITEM:
                    return { ...state, item: { ...state.item, [action.campo]: action.value } };
                case TYPES.MODAL:
                    return { ...state, modal: { ...state.modal, [action.campo]: action.value } };
                case TYPES.SET_IMPRESSAO:
                    return { ...state, impressao: action.value };
                case TYPES.SET_ETIQUETA:
                    return { ...state, etiqueta: action.value };
                case TYPES.SET_FORMALIZACOES:
                    return { ...state, formalizacoes: action.value };
                case TYPES.SET_INSTITUICOES:
                    return { ...state, instituicoes: action.value };
                case TYPES.SET_DESTINATARIOS:
                    return { ...state, destinatarios: action.value };             
                case TYPES.SET_LOJAS:
                    return { ...state, lojas: action.value };             
                case TYPES.CLEAN_SEARCH:
                    return { ...state, pesquisar: INITIAL_STATE.pesquisar }
                case TYPES.CLEAN_ETIQUETA:
                    return { ...state, etiqueta: INITIAL_STATE.etiqueta }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, item:{ id: '0',
                                            instituicaoId: '0',
                                            destinatarioId: '0',
                                            propostas: []}
             }
             case TYPES.GET_ALL_ITEMS:
                    return { ...state, getAllItems: action.value }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}