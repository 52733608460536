import api from '../util/api'
import * as actions from '../store/modules/uploadComissao/actions'
import { notifySuccess, notifyWarn } from '../components/message/message'
import { setLoading } from '../store/modules/globalLoading/actions'


export async function getTiposArquivo() {
    const data = await api.get('tipoArquivo')

    if (!data.success) {
        return
    }
    actions.setTipos(data.content)
}

export async function getBancos() {
    const data = await api.get('bancos')

    if (!data.success) {
        return
    }
    actions.setBancos(data.content)
}

export async function getLayoutsPlanilha(id) {
    let data;
    setLoading(true)
    if (id == 0) {
        data = await api.get("LayoutPlanilha")
    } else {
        data = await api.get(`LayoutPlanilha/layoutUploadArquivo?tipoArquivoId=${id}`)
    }
    setLoading(false)

    if (!data.success) {
        return
    }
    actions.setLayoutPlanilha(data.content)
    actions.setLayouts(data.content)
}

export async function get(pesquisa, pageSize, page) {
    var url = `planilhaImportacao/?pagina=${page}&registros=${pageSize}`
    if (pesquisa.nomeUser !== '') {
        url = url + `&nome=${pesquisa.nomeUser}`
    }
    if (pesquisa.dataInicial !== '') {
        url = url + `&dataInicio=${pesquisa.dataInicial}`
    }
    if (pesquisa.dataFinal !== '') {
        url = url + `&dataFim=${pesquisa.dataFinal}`
    }
    if (pesquisa.tipoArquivo !== '0') {
        url = url + `&tipoArquivo=${pesquisa.tipoArquivo}`
    }
    if (pesquisa.layoutPlanilha !== '') {
        url = url + `&layoutArquivo=${pesquisa.layoutPlanilha}`
    }
    if (pesquisa.statusImportacao !== '0') {
        url = url + `&status=${pesquisa.statusImportacao}`
    }
    if (pesquisa.nomeArquivo !== '') {
        url = url + `&nomeArquivo=${pesquisa.nomeArquivo}`
    }
    if (pesquisa.nomeLayout !== '') {
        url = url + `&nomeLayout=${pesquisa.nomeLayout}`
    }

    setLoading(true)
    const data = await api.get(url,
        {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('TOKEN_KEY'),
                'Content-Type': 'aplication/json'
            }
        })
    setLoading(false)

    if (!data.success) {
        return
    }
    var body = []
    data.content.registros.forEach((regs) => {
        if (regs.statusImportacao === 1) {
            regs.statusImportacao = 'Aguardando processamento'
        }
        else if (regs.statusImportacao === 2) {
            regs.statusImportacao = 'Processado'
        }
        else if (regs.statusImportacao === 3) {
            regs.statusImportacao = 'Inconsistência'
        }
        else if (regs.statusImportacao === 4) {
            regs.statusImportacao = 'Processando'
        }
        else if (regs.statusImportacao === 5) {
            regs.statusImportacao = 'Estornado'
        }
        else if (regs.statusImportacao === 6) {
            regs.statusImportacao = 'Erro de Layout'
        }
        else if (regs.statusImportacao === 7) {
            regs.statusImportacao = 'Sem registro'
        }
        else if(regs.statusImportacao === 8){
            regs.statusImportacao = 'Processado parcialmente'
        }
        if (regs.tipoImportacao === 1) {
            regs.tipoImportacao = 'Manual'
        } else if (regs.tipoImportacao === 2) {
            regs.tipoImportacao = 'Automático'
        }
        body.push({
            id: regs.id,
            nomeUsuario: regs.nomeUsuario,
            dataImportacao: regs.dataCriacao,
            tipoArquivo: regs.tipoArquivo.descricao,
            tipoImportacao: regs.tipoImportacao,
            nomeArquivo: regs.nomeArquivo,
            status: regs.statusImportacao,
            nomeLayout: regs.nomeLayout,
           
        })
    })
    actions.setUploads(body)
    actions.getAllItems({
        quantidade: body.length,
        quantidadeTotal: data.content.quantidadeTotal,
        totalPaginas: data.content.totalPaginas,
        tamanhoPagina: data.content.tamanhoPagina
    })
}

export function getStatusImportacao() {

    const obterDadosDaAPI = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const dados = [
                    { id: 1, descricao: "Aguardando processamento" },
                    { id: 2, descricao: "Processado" },
                    { id: 3, descricao: "Inconsistência" },
                    { id: 4, descricao: "Processando" },
                    { id: 5, descricao: "Estornado" },
                    { id: 6, descricao: "Erro de Layout" },
                    { id: 7, descricao: "Sem registro" },
                    { id: 8, descricao: "Processado parcialmente" }
                ];
                resolve(dados);
            }, 1000);
        });
    };
    obterDadosDaAPI().then(content => {
        actions.setStatusImportacoes(content);
    })
}

export async function create(file) {
    if (file.tipoArquivo === '0') {
        return notifyWarn('Por favor, selecione um tipo de arquivo.')
    }

    if (file.layoutPlanilhaId === '0') {
        return notifyWarn('Por favor, selecione um layout de parametrização')
    }

    if (file.arquivos.length === 0) {
        return notifyWarn('Por favor, insira pelo menos um arquivo.')
    }
    file.arquivos.forEach(async (arquivo) => {
        const body = new FormData()
        const dados = { tipoArquivoId: file.tipoArquivo, tipoImportacao: 1 }
        body.append('arquivo', arquivo.file)
        setLoading(true)
        const data = await api.post(`planilhaImportacao?layoutPlanilhaId=${file.layoutPlanilhaId}&tipoImportacao=${dados.tipoImportacao}`, body,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        setLoading(false)
        if (!data.success) {
            return
        }
        notifySuccess('Upload realizado com sucesso.')
        actions.cleanRegister()
    })
}

export async function getById(id) {
    setLoading(true)
    const data = await api.get(`planilhaImportacao/GetByIdPaginado?id=${id}`)
    setLoading(false)

    if (!data.success) {
        return
    }
    actions.setItem('id', id)
    actions.setItem('tipoArquivo', data.content.tipoArquivoId)
    actions.setItem('layoutPlanilhaId', data.content.layoutPlanilhaId)
    actions.setItem('instituicao', data.content.bancoId)
    actions.setItem('arquivo64', data.content.arquivoImportado)
    actions.setItem('nome', data.content.nomeArquivo)
    actions.setItem('inconsistencias', data.content.inconsisteciaSistemaDetalhe.registros)

    data.content.inconsisteciaSistemaDetalhe.registros.length !== 0 ? actions.setPossuiInconsistencia(true) : actions.setPossuiInconsistencia(false)
    actions.setAllItemsInconsistencia({
        quantidade: data.content.inconsisteciaSistemaDetalhe.quantidade,
        quantidadeTotal: data.content.inconsisteciaSistemaDetalhe.quantidadeTotal,
        totalPaginas: data.content.inconsisteciaSistemaDetalhe.totalPaginas
    })
}


export async function getByIdPaginado(id, pagina, registros) {
    setLoading(true)
    const data = await api.get(`planilhaImportacao/GetByIdPaginado?id=${id}&pagina=${pagina}&registros=${registros}`)
    setLoading(false)

    if (!data.success) {
        return
    }
    actions.setItem('inconsistencias', data.content.inconsisteciaSistemaDetalhe.registros)

    data.content.inconsisteciaSistemaDetalhe.registros.length !== 0 ? actions.setPossuiInconsistencia(true) : actions.setPossuiInconsistencia(false)

    actions.setAllItemsInconsistencia({
        quantidade: data.content.inconsisteciaSistemaDetalhe.quantidade,
        quantidadeTotal: data.content.inconsisteciaSistemaDetalhe.quantidadeTotal,
        totalPaginas: data.content.inconsisteciaSistemaDetalhe.totalPaginas
    })
}

export async function remove(id, pesquisa, pageSize, page) {
    setLoading(true)
    const data = await api.delete(`planilhaImportacao/${id}`)
    setLoading(false)

    if (!data.success) {
        return
    }

    notifySuccess('Upload removido com sucesso.')
    get(pesquisa, pageSize, page)
}

export async function estorno(id, pesquisa, pageSize, page) {
    setLoading(true)
    const data = await api.delete(`planilhaimportacao/${id}/estornos`)
    setLoading(false)

    if (!data.success) {
        return
    }

    notifySuccess('Upload estornado com sucesso.')
    get(pesquisa, pageSize, page + 1)
}

export async function downloadPlanilha(id) {
    setLoading(true)
    const data = await api.get(`PlanilhaImportacao/DownloadArquivo/${id}`)
    setLoading(false)

    if (!data.success) {
        return
    }

    actions.setDownload(data.content)
}