import React from "react";

import { Card, Col, Label, Row, Form, FormGroup } from "reactstrap";

import * as actions from "../../../store/modules/vinculoLoja/actions";

import * as vinculoLojaController from "../../../controllers/vinculoLojaController";

import { Select } from "../../../components/Inputs/Input";

import { useSelector } from "react-redux";

const Register = () => {
  const {
    bancos,
    item,
    lojasArrecadadoras,
    contas,
    tiposComissao,
    seeRegister,
  } = useSelector((state) => state.vinculoLoja);

  async function onInstituicaoChange(bancoId) {
    onChangeItem(bancoId, "bancoId");

    actions.cleanItemField("lojaId");
    actions.setLojasArrecadadoras([]);

    actions.cleanItemField("tiposComissaoId");
    actions.setTiposComissao([]);

    actions.cleanItemField("contaCorrenteId");
    actions.setContas([]);
    actions.cleanItemField("contaCorrenteEspelhoId");

    if (bancoId !== "0") {
      vinculoLojaController.getLojasArrecadadoras(bancoId);
        vinculoLojaController.getTiposComissao(bancoId);
    }
  }

  async function onLojaArrecadadoraChange(lojaId, bancoId) {
    onChangeItem(lojaId, "lojaId");

    actions.cleanItemField("contaCorrenteId");
    actions.setContas([]);

    if (lojaId !== "0") {
      vinculoLojaController.getContaCorrente(lojaId);

    }
  }

  function onChangeItem(value, field) {
    actions.setItem({ ...item, [field]: value });
  }

  return (
    <>
      <Col>
        <Card className="pt-3 pb-2 mb-5">
          <Col>
            <div style={{ width: "25%" }} className="pb-3">
              <Label
                style={{
                  fontWeight: "400",
                  fontSize: "18px",
                  margin: "5px 0 8px 15px",
                }}
              >
                Preencha os campos para cadastrar um Vinculo Loja
              </Label>
            </div>
            <Form style={{ margin: "0px 0px 15px 15px" }}>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label>Instituição</Label>
                    <Select
                      first="SELECIONE"
                      value={{ value: item.bancoId }}
                      select={{ value: "id", label: "nome" }}
                      options={bancos}
                      onChange={(e) => {
                        onInstituicaoChange(e.value);
                      }}
                      isSearchable={true}
                      autoComplete="off"
                      disabled={seeRegister}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "35px",
                          height: "35px",
                        }),
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Loja arrecadadora</Label>
                    <Select
                      first="SELECIONE"
                      value={{ value: item.lojaId }}
                      select={{ value: "id", label: "descricao" }}
                      options={lojasArrecadadoras}
                      onChange={(e) => {
                        onLojaArrecadadoraChange(e.value, item.bancoId);
                      }}
                      isSearchable={true}
                      autoComplete="off"
                      disabled={seeRegister}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "35px",
                          height: "35px",
                        }),
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>Tipo comissão</Label>
                    <Select
                      first="SELECIONE"
                      value={{ value: item.tiposComissaoId }}
                      select={{ value: "id", label: "descricao" }}
                      options={tiposComissao}
                      onChange={(e) => {
                        onChangeItem(e.value, "tiposComissaoId");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                      disabled={seeRegister}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "35px",
                          height: "35px",
                        }),
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label>Conta</Label>
                    <Select
                      first="SELECIONE"
                      value={{ value: item.contaCorrenteId }}
                      select={{ value: "id", label: "conta" }}
                      options={contas}
                      onChange={(e) => {
                        onChangeItem(e.value, "contaCorrenteId");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                      disabled={seeRegister}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: "35px",
                          height: "35px",
                        }),
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <div
                    style={{
                      display: "block",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Label>Principal</Label>
                  </div>

                  <input
                    type="checkbox"
                    value={item.principal}
                    style={{ marginTop: "0.7rem", display: 'flex' }}
                    disabled={seeRegister}
                    checked={item.principal}
                    onChange={(e) => {
                      onChangeItem(e.target.checked, "principal");
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Card>
      </Col>
    </>
  );
};

export default Register;
