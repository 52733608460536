import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { AiOutlineEye } from 'react-icons/ai'
import CopyToClipboard from 'react-copy-to-clipboard'
import { BsArrowUp, BsArrowDown } from 'react-icons/bs'
import { MdContentCopy } from 'react-icons/md'

import { maskDate, maskMoeda } from '../../../../util/masks'
import history from '../../../../util/history';

import * as controller from '../../../../controllers/dashboardController/esteiraProducaoController'
import * as controllerPropostas from '../../../../controllers/propostasController'
import * as actionsPropostas from '../../../../store/modules/propostas/actions'

import './style.css'

export default function TableCancelados({data}) {
    return(
        <table style={{maxWidth: '100%'}}>
            <thead style={{maxWidth: '100%'}}>
                <tr style={{maxWidth: '100%'}}>
                    <th className='colunaEsteira'>Seleção</th>
                    <th className='colunaEsteira'>Cancelado</th>
                    <th className='colunaEsteira'>Não tratado</th>
                    <th className='colunaEsteira'>Reaproveitado</th>
                </tr>
            </thead>
            <tbody style={{maxWidth: '100%'}}>
                {data.map((e, i)=>{
                    return(
                        <FirstLevelTableRow data={e} index={i}/>
                    )
                })}
            </tbody>
        </table>
    )
}

function FirstLevelTableRow({data,index}){

    const { filtrosSelecionados, tabelaEsteiraProducaoCancelados} = useSelector(state => state.dashboardEsteiraProducao);

    const [isPropostaActive, setisPropostaActive] = useState(false)

    function handleOnClick(){
        var isSuccessed = controller.getTabelaEsteiraProducaoPropostasCancelados(filtrosSelecionados, data.id, tabelaEsteiraProducaoCancelados)
        isSuccessed && setisPropostaActive(!isPropostaActive)
    }

    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen' style={{filter: 'brightness(85%)'}}>
                    <div style={{display: 'flex'}}>
                        <div className='button' onClick={(e) => handleOnClick()}>{!isPropostaActive ? '+' : '-'}</div>
                        {data.nome}
                    </div>
                </td>
                {data.statusPropostas.map((e)=>{
                    return(
                        <td className={e.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'} style={{filter: 'brightness(85%)'}}>
                            <div>
                            <p style={{fontSize: '11px', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {`(${e.quantidade})`} {e.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> }
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                    {`R$${maskMoeda(e.valorTotal.toFixed(2))}`}
                                </p> 
                            </div>
                        </td>
                    )
                })}
            </tr>
            { isPropostaActive &&
             <>
                    <tr className='tr'>
                        <td style={{padding: '0', margin: '0',  width: '50%'}}>
                            <th style={{height: '40px', width: '25%', color: 'var(--color-preto)', background: '#F5F6FA'}}>Adesão</th>
                            <th style={{height: '40px',width: '48%', color: 'var(--color-preto)', background: '#F5F6FA'}}>Digitador</th>
                            <th style={{height: '40px', width: '210px', maxWidth: '210px',color: 'var(--color-preto)', background: '#F5F6FA'}}>Cliente</th>
                        </td>
                        <td style={{padding: '0', margin: '0', background: '#F5F6FA', width: '20%'}}>
                            <th style={{height: '40px', width: '50%', color: 'var(--color-preto)', background: '#F5F6FA'}}>Data</th>
                            <th style={{height: '40px',width: '35%', color: 'var(--color-preto)', background: '#F5F6FA'}}>Convênio</th>
                        </td>
                        <td style={{padding: '0', margin: '0', background: '#F5F6FA', width: '20%'}}>
                            <th style={{height: '40px',width: '10%', color: 'var(--color-preto)', background: '#F5F6FA'}}>Produto</th>
                            <th style={{height: '40px',width: '10%', color: 'var(--color-preto)', background: '#F5F6FA'}}>Status</th>
                        </td>
                        <td style={{padding: '0', margin: '0', background: '#F5F6FA'}}>
                            <th style={{height: '40px',width: '10%', color: 'var(--color-preto)', background: '#F5F6FA'}}>Valor</th>
                            <th style={{height: '40px',width: '10%', color: 'var(--color-preto)', background: '#F5F6FA'}}>Ações</th>
                        </td>
                    </tr>
            </>}
            {isPropostaActive &&
            data.propostas.map((e, i) =>{
                return(
                    <SecondLevelTableRow data={e} index={i}/>
                )
            })
            }
        </>
    )
}
function SecondLevelTableRow({ data, index }) {
    const [inconsistencias, setInconsistencias] = useState([])
    
    async function handleExpand(propostaId) {
      const inconsistencias = await controller.getInconsistenciasDaProposta(propostaId)
      setInconsistencias(inconsistencias)
    }

    async function changeView(){
        actionsPropostas.setSeeRegister(true);
        actionsPropostas.setActiveDash(true)
        await controllerPropostas.getById(data.id);
        actionsPropostas.setActiveTab("Register");
        history.push('/propostas')
    }
     return(
        <>
        <tr className='tr linhaGreen' key={index} style={{filter: 'brightness(90%)'}}>
            <td style={{ padding: '7px'}}>
                <td style={{width: '25%', borderBottom: '0'}}>
                    <CopyToClipboard text={data.adesao}>
                        <button style={{background: 'transparent', border: 'none'}}> <MdContentCopy style={{color: 'var(--color-verde-claro)', marginRight: '10px'}}/></button>
                    </CopyToClipboard>
                    <div onClick={() => { handleExpand(data.id)}} style={{cursor: data.possuiInconsistencia ? "pointer" : "auto", fontWeight: data.possuiInconsistencia ? "bold" : "normal",color: data.possuiInconsistencia ? "black" : "normal"}}>
                      {data.adesao}
                    </div>
                </td>
                <td style={{width: '50%', borderBottom: '0'}}>{data.digitador}</td>
                <td style={{width: '210px', borderBottom: '0'}}>{data.cliente}</td>
            </td>
            <td>
                <td style={{width: '10%',  borderBottom: '0'}}>{maskDate(data.dataProposta, "DD/MM/YYYY")}</td>
                <td style={{width: '10%',  borderBottom: '0'}}>{data.convenio}</td>
            </td>
            <td>
                <td style={{width: '10%',  borderBottom: '0'}}>{data.produto}</td>
                <td style={{width: '10%',  borderBottom: '0'}}>{data.status}</td>
            </td>
            <td>
                <td style={{width: '10%',  borderBottom: '0'}}>{maskMoeda(data.valorFinanciado.toFixed(2))}</td>
                <td style={{width: '10%',  borderBottom: '0'}}>{
                        <div style={{display: 'flex'}}>
                            <AiOutlineEye style={{fontSize: '25px', paddingBottom: '5px', cursor: 'pointer', marginRight: '5px'}} onClick={()=> changeView()}/>
                        </div>}</td>
            </td>
        </tr>
         {inconsistencias.length > 0 ? (
           <ThirdLevelTable inconsistencias={inconsistencias} />
         ): null}
        </>
    )
}

function ThirdLevelTable({ inconsistencias }) {
  return(
      <>
              <tr className='tr'>   
                <th  style={{height: '40px',color: 'var(--color-preto)', background: '#F5F6FA'}}>Consistência</th>
                <th colSpan={1} style={{ height: '40px', color: 'var(--color-preto)', background: '#F5F6FA' }}>Descrição</th>
                <th colSpan={1} style={{ height: '40px', color: 'var(--color-preto)', background: '#F5F6FA' }}>Responsável</th>
                <th style={{
                  height: '40px', color: 'var(--color-preto)', background: '#F5F6FA', display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "25px"
                }}>Ações</th>
              </tr>
              {inconsistencias?.map(inconsistencia => (
                <ThirdLevelTableRow inconsistencia={inconsistencia}/>
              ))}
      </>
  )
}

function ThirdLevelTableRow({ inconsistencia }) {
  const [isInconsistenciaCorrigida, setIsInconsistenciaCorrigida] = useState(null)

  async function handleToggleInconsistenciaStatus(inconsistenciaStatus, propostaInconsistenciaId) {
    const newStatusInconsistencia = !inconsistenciaStatus
    const isSuccessed = await controller.updateFlagDaInconsistenciaProposta(propostaInconsistenciaId, newStatusInconsistencia)
    if (isSuccessed) {
      setIsInconsistenciaCorrigida(newStatusInconsistencia)
    }
  }

  const isResponsavelPorAcaoInstituicaoFinanceira = inconsistencia.reponsavelPorAcaoId.toUpperCase() === 'D60DD5FA-B56A-4DDA-9E1D-3073C841F1FB'

  return(
      <>
                <tr className='tr' key={inconsistencia.codigoInconsistencia}>   
                    <td >{inconsistencia.codigoInconsistencia}</td>
                    <td colSpan={1}>{inconsistencia.descricao}</td>
                    <td colSpan={1}>
                          {inconsistencia.responsavelPorAcao}
                  </td>
                  <td>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "25px"}}>
                        {
                            inconsistencia.responsavelPorAcao !== 'INSTITUICAO FINANCEIRA' && (
                                <input
                                type="checkbox"
                                disabled={isResponsavelPorAcaoInstituicaoFinanceira}
                                defaultChecked={isInconsistenciaCorrigida ?? inconsistencia.codigoConsistenciaCorrigido}
                                onChange={() => handleToggleInconsistenciaStatus(isInconsistenciaCorrigida ?? inconsistencia.codigoConsistenciaCorrigido, inconsistencia.idPropostaInconsistencia)} />
                            )
                        }
                    </div>
                  </td>
                </tr>
      </>
  )
}