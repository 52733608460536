import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';

export function CustomTooltip({
  fontSize = '1rem',
  children,
  ...rest
}) {

  const useStyles = makeStyles({
    tooltip: {
      fontSize
    },
  });

  const classes = useStyles();

  return (
    <Tooltip
      placement="top"
      arrow
      classes={{tooltip: classes.tooltip}}
      {...rest}
    >
      {children}
    </Tooltip>
  );
}
