import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setRoutes(value) {
    dispatch({
        type: TYPES.SET_ROUTES,
        value
    })
}

export function cleanRoutes() {
    dispatch({
        type: TYPES.CLEAN_ROUTES
    })
}