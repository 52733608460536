import React from "react";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useSelector } from "react-redux";
import * as actions from "../../../store/modules/centroCusto/actions";
import * as controller from "../../../controllers/centroCustoController";
import { maskMonthYear, maskNumber, removeAccentsAndUppercase } from "../../../util/masks";
import * as AiIcons from 'react-icons/ai'
import TableHeadcountOrcado from "../components/TableHeadcountOrcado";

const Register = () => {

    const { item, copy, seeRegister,headcount } = useSelector(state => state.centroCusto);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                controller.create(item);
            } else {
                controller.update(item, copy);
            };
        }
    }

    function onChange(value, field) {
        actions.item({ ...item, [field]: value });
    }

    async function salveHeadcountOrcado() {
        if(headcount.id !== '0'){
          await controller.editHeadcountOrcado(headcount.id, {centroCustoId: item.id, qtde: headcount.headCountOrcadoLojasCentroCusto, periodo: headcount.periodo})
          await controller.getById(item.id)
        }else{
          var isHeadcountSuccessed = await controller.salveHeadcountOrcado({centroCustoId: item.id, qtde: headcount.headCountOrcadoLojasCentroCusto, periodo: headcount.periodo})
              if(isHeadcountSuccessed){
                  await controller.getById(item.id)
                  actions.cleanHeadcountOrcado();
              }else{
                  actions.cleanHeadcountOrcado();
              }
        }
      }

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2" style={{ margin: '25px 0 0 25px' }}>
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>
                            Preencha os campos para cadastrar um novo Centro de Custo
                        </p>
                    </Col>
                    <Form>
                        <Col style={{ margin: '25px 50px 0 25px' }}>
                            <Row>
                                <Col md={7}>
                                    <FormGroup  style={{ width: '100%' }}>
                                        <Label for="centroCusto" style={{ fontWeight: "700", color: 'var(--color-preto)' }}>
                                            Descrição do Centro de custo
                                        </Label>
                                        <Input
                                            type="text"
                                            name="centroCusto"
                                            id="centroCusto"
                                            placeholder="Digite aqui..."
                                            value={item.descricao}
                                            onChange={e => onChange(removeAccentsAndUppercase(e.target.value), "descricao")}
                                            onKeyPress={e => handleKeyPress(e)}
                                            disabled={seeRegister ? true : false}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup style={{width: '70%'}}>
                                        <Label for="codCentroCustoW3Erp" tyle={{ fontWeight: '700', color: 'var(--color-preto)' }} >
                                            ID Centro de Custo W3ERP
                                        </Label>
                                        <Input
                                            type="text"
                                            value={item.codCentroCustoW3Erp}
                                            placeholder="Digite aqui..."
                                            onChange={e => onChange(removeAccentsAndUppercase(e.target.value), "codCentroCustoW3Erp")}
                                            onKeyPress={e => handleKeyPress(e)}
                                            disabled={seeRegister ? true : false}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {
                            item.id && (
                                <>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                        <Label>Quantidade headcount</Label>
                                        <Input
                                            value={headcount.headCountOrcadoLojasCentroCusto}
                                            disabled={seeRegister}
                                            onChange={(e) =>
                                            actions.setHeadcount("headCountOrcadoLojasCentroCusto",maskNumber(e.target.value))
                                            }
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <Label>Período</Label>
                                        <FormGroup>
                                            <Input
                                                type='text'
                                                placeholder='mm/aaaa'
                                                value={headcount.periodo}
                                                onChange={e => actions.setHeadcount('periodo', maskMonthYear(e.target.value))}
                                                disabled={seeRegister}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {!seeRegister && (
                                        <Col>
                                        <Label
                                        onClick={() => salveHeadcountOrcado()}
                                        style={{ cursor: "pointer", marginTop: '30px'}}
                                        >
                                        <AiIcons.AiOutlinePlusCircle
                                            style={{
                                            fontSize: "1.5rem",
                                            marginRight: "7px",
                                            color: "green",
                                            cursor: "pointer",
                                            }}
                                        />
                                        Adicionar Headcount
                                        </Label>
                                        </Col>
                                    )}
                                    </Row>
                                    {
                                        !item.headCountOrcadoLojasCentroCusto.length !== 0 &&
                                        <div style={{paddingRight: '50px'}}>
                                            <TableHeadcountOrcado />
                                        </div>
                                    }
                                    </>
                            )
                        }
                        </Col>
                    </Form>
                </Card>
            </Col>
        </>
    );
}

export default Register;
