import React, { useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment/locale/pt-br";
import {
  DatePickerContainer,
  HeaderContainer,
  YearSelector,
  MonthSelector,
  CustomButton,
  ButtonGroup,
  ButtonArrow,
  ButtonClear,
} from "./style";
import classNames from "classnames";
import { Input } from "reactstrap";

moment.locale("pt-br");

const CustomDatePicker = ({ onChangeFiltros }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(moment().month());

  const years = Array.from(
    { length: new Date().getFullYear() - 1900 + 1 },
    (_, i) => 1900 + i
  );

  const months = Array.from(
    { length: 12 },
    (_, i) =>
      moment().month(i).format("MMMM").charAt(0).toUpperCase() +
      moment().month(i).format("MMMM").slice(1)
  );

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      onChangeFiltros(moment(start).format("YYYY-MM-DD"), "dataInicio");
      onChangeFiltros(moment(end).format("YYYY-MM-DD"), "dataFim");
    }
    setIsOpen(false);
  };

  const handlePeriodoPreDefinido = (offset) => {
    const targetMonth = moment().subtract(offset, "months");
    const inicio = targetMonth.startOf("month").toDate();
    let fim;

    if (targetMonth.isSame(moment(), "month")) {
      fim = new Date();
    } else {
      fim = targetMonth.endOf("month").toDate();
    }

    setStartDate(inicio);
    setEndDate(fim);
    setSelectedMonth(targetMonth.month());
    onChangeFiltros(moment(inicio).format("YYYY-MM-DD"), "dataInicio");
    onChangeFiltros(moment(fim).format("YYYY-MM-DD"), "dataFim");
    setIsOpen(false);
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    onChangeFiltros("", "dataInicio");
    onChangeFiltros("", "dataFim");
    setIsOpen(false);
  };

  return (
    <DatePickerContainer isOpen={isOpen}>
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/mm/aaaa - dd/mm/aaaa"
        selectsRange
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <HeaderContainer>
            <ButtonArrow
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {"<"}
            </ButtonArrow>
            <YearSelector
              value={date ? date.getFullYear() : new Date().getFullYear()}
              onChange={({ target: { value } }) => changeYear(parseInt(value))}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </YearSelector>
            <MonthSelector
              value={selectedMonth}
              onChange={({ target: { value } }) => {
                setSelectedMonth(parseInt(value));
                changeMonth(parseInt(value));
              }}
            >
              {months.map((month, index) => (
                <option key={index} value={index}>
                  {month}
                </option>
              ))}
            </MonthSelector>
            <ButtonArrow
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {">"}
            </ButtonArrow>
          </HeaderContainer>
        )}
        customInput={<Input />}
        calendarContainer={({ className, children }) => (
          <CalendarContainer
            className={classNames(className, "d-flex flex-row-reverse")}
          >
            <div style={{ position: "relative" }}>{children}</div>
            <ButtonGroup>
              <CustomButton onClick={() => setIsOpen(false)}>
                {" "}
                Personalizado{" "}
              </CustomButton>
              <CustomButton onClick={() => handlePeriodoPreDefinido(0)}>
                {months[moment().month()]}
              </CustomButton>
              <CustomButton onClick={() => handlePeriodoPreDefinido(1)}>
                {months[moment().subtract(1, "month").month()]}
              </CustomButton>
              <CustomButton onClick={() => handlePeriodoPreDefinido(2)}>
                {months[moment().subtract(2, "month").month()]}
              </CustomButton>
              <ButtonClear onClick={handleClear}>Limpar</ButtonClear>
            </ButtonGroup>
          </CalendarContainer>
        )}
      />
    </DatePickerContainer>
  );
};

export default CustomDatePicker;
