import React from "react";
import * as BiIcons from "react-icons/bi";
import * as actions from "../../../store/modules/centroCusto/actions";
import * as controller from "../../../controllers/centroCustoController";
import { useSelector } from "react-redux";
import { maskDate } from "../../../util/masks";

function TableHeadcountOrcado() {

    const { seeRegister, item } = useSelector(state => state.centroCusto);

   const data = item.headCountOrcadoLojasCentroCusto;

    async function handleRemoverItem(row, index) {
        let valid = await controller.deleteHeadcountOrcado(row.id)

        if (valid) {
           let arrHeadCounts = data;
           arrHeadCounts.splice(index, 1);
           actions.item({ ...item, headCountOrcadoLojasCentroCusto:arrHeadCounts });
        }
    }

    async function handleUpdate(row){
        actions.headcount({ headCountOrcadoLojasCentroCusto: row.qtde,
        dataInicio: maskDate(row.dataInicial, 'YYYY-MM-DD'),
        dataFim: maskDate(row.dataFinal, 'YYYY-MM-DD'),
        id: row.id });
    }

    return (
        <>
            <table style={{ marginTop: '20px' }}>
                <thead>
                    <tr>
                        <th>Quantidade</th>
                        <th>Data de início</th>
                        <th>Data de fim</th>
                        <th style={{ textAlign: 'center' }}>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => {
                        return (
                            <>
                                <tr>
                                    <td>{row.qtde}</td>                                   
                                    <td>{maskDate(row.dataInicial, 'DD/MM/YYYY')}</td>
                                    <td>{row.dataFinal && maskDate(row.dataFinal, 'DD/MM/YYYY')}</td>
                                    <td style={{ textAlign: 'center', width: '60px' }}>
                                        {!seeRegister &&
                                            <>
                                                <BiIcons.BiEdit
                                                    style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}
                                                    onClick={() => handleUpdate(row)}
                                                />
                                                <BiIcons.BiTrash
                                                    style={{ fontSize: '1.2rem', cursor: 'pointer', color: '#C4C7CA' }}
                                                    onClick={() => handleRemoverItem(row, index)}
                                                />
                                            </>
                                        }
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

export default TableHeadcountOrcado
