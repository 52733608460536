import React from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { useSelector } from "react-redux";
import * as  gruposController from "../../../controllers/gruposController";
import { maskCPF, maskCNPJ } from '../../../util/masks';
import * as grupoActions from '../../../store/modules/grupos/actions';

export function SessaoCPF() {
  const { item } = useSelector(state => state.grupo);

  function onChange(value) {
    grupoActions.setPessoa(maskCPF(value), 'cpfCnpj');
    if (value.length === 14) {
      gruposController.getPessoas(maskCPF(value));
    }
  }

  return (
    <Row>
      <Col sm={5} style={{ margin: "10px" }}>
        <Row>
          <Label>CPF</Label>
          <Input
            placeholder="Digite aqui..."
            onChange={(e) => onChange(e.target.value)}
            value={item.cpfCnpj}
            maxLength={14}
          />
        </Row>
      </Col>
      <Col sm={5} style={{ margin: "10px" }}>
        <Row>
          <Label>Nome</Label>
          <Input value={item.nome} disabled={true}
            onChange={(e) => grupoActions.setPessoa(e.target.value, 'nome')} />
        </Row>
      </Col>
    </Row>
  );
}

export function SessaoCNPJ() {
  
  const { item } = useSelector(state => state.grupo);

  async function onChange(value) {
    grupoActions.setPessoa(maskCNPJ(value), 'cpfCnpj');
    gruposController.getPessoas(maskCNPJ(value), item);
  }

  return (
    <Row>
      <Col sm={5} style={{ margin: "10px" }}>
        <Row>
          <Label>CNPJ</Label>
          <Input
            placeholder="Digite aqui..."
            onChange={(e) => onChange(e.target.value)}
            value={item.cpfCnpj}
            maxLength={18}
          />
        </Row>
      </Col>
      <Col sm={5} style={{ margin: "10px" }}>
        <Row>
          <Label>Nome Fantasia</Label>
          <Input value={item.nome} disabled={true}
            onChange={(e) => grupoActions.setPessoa(e.target.value, 'nome')} />
        </Row>
      </Col>
    </Row>
  );
}