import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { MdContentCopy } from 'react-icons/md';
import { RiArrowDownSLine, RiArrowRightSLine, RiArrowUpSLine, RiErrorWarningLine } from 'react-icons/ri';
import { Collapse, Row, Col, Input, Label, Button } from 'reactstrap'

import * as controller from '../../controllers/inconsistenciaComissaoController'
import { FiRefreshCcw } from 'react-icons/fi';
import { notifySuccess } from '../../components/message/message';

export default function TipoOperacao(){
    const { tiposOperacao, tab } = useSelector(state => state.inconsistenciaComissao);

    return(
        <div style={{width: '93%', marginLeft: '25px'}}>
            {tiposOperacao.map((e,i)=>{
                return(
                    <CollapsedCard inconsistencia={e} index={i} tab={tab}/>
                )
            })}
        </div>
    )
}

function CollapsedCard({inconsistencia, index, tab}){
    const { tabs } = useSelector(state => state.inconsistenciaComissao);

    const [isCardOpen, setIsCardOpen] = useState(false)

    function handleCorrigirInconsistencia(){
        const baseURL = window.location.origin
        window.open(`${baseURL}/operacaoinstituicao?isRedirect=true`)
    }

    async function handleRefresh(){
        await controller.correcaoInconsistenciaComissao(inconsistencia, tab)
        await controller.getQuantidadeComissao(tabs)
    }
    return(
        <div style={{borderTop: '1px solid #CCCFDE', marginBottom: '30px'}}>
            <div style={{display: 'flex', marginTop: '30px'}} >
                <RiErrorWarningLine style={{color: 'var(--color-laranja)',marginRight: '5px', fontSize: '25px'}}/>
                <p style={{fontWeight: 'bold', margin: '5px 75% 0 0'}}>
                    {`Inconsistência de Tipo Operação ${String(index+1).padStart(2,'0')}`}
                </p>
                <FiRefreshCcw
                style={{paddingTop: '5px', fontSize: '20px', cursor: 'pointer'}} 
                onClick={() => handleRefresh()}/>
                {isCardOpen ? 
                    <RiArrowUpSLine  
                    style={{fontSize: '25px', cursor: 'pointer'}}
                    onClick={()=> setIsCardOpen(!isCardOpen)}/> 
                : 
                    <RiArrowDownSLine 
                    style={{fontSize: '25px', cursor: 'pointer'}}
                    onClick={()=> setIsCardOpen(!isCardOpen)}/>}
            </div>
            <Collapse isOpen={isCardOpen} style={{paddingTop: '30px', paddingLeft: '5px'}}>
                <Row>
                    <Col md={4}>
                        <Label style={{fontWeight: 'bold'}}>Descrição</Label>
                            <div style={{display: 'flex', border: '1px solid var(--color-laranja)', borderRadius: '0.25rem', padding: '8px 0 8px 10px', background: '#E9ECEF'}}>
                            <p style={{width: '95%', margin: '0'}}>
                                {inconsistencia.descricao}
                            </p>
                            <CopyToClipboard text={inconsistencia.descricao} onCopy={()=> notifySuccess('Copiado!')}>
                                <button style={{background: 'transparent', border: 'none'}}> <MdContentCopy style={{color: 'var(--color-verde-claro)', marginRight: '10px'}}/></button>
                            </CopyToClipboard>
                            </div>
                    </Col>
                    <Col md={4}>
                        <Label style={{fontWeight: 'bold'}}>Instituição Financeira</Label>
                        <Input
                            type="text"
                            value={inconsistencia.nomeBanco}
                            disabled
                        />
                    </Col>
                    <Col md={4}>
                        <Label style={{fontWeight: 'bold'}}>Nome do arquivo importado</Label>
                        <Input
                            type="text"
                            value={inconsistencia.nomeArquivo}
                            disabled
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: '15px'}}>
                    <div style={{width: '50%'}}>
                        <Button 
                        className='purpleButton' 
                        style={{width: '216px',margin: '12px 0 7px 65%'}}
                        onClick={()=> handleCorrigirInconsistencia()}>
                            <RiArrowRightSLine style={{fontSize: '20px', paddingBottom: '3px'}}/> 
                            Corrigir Inconsistência
                        </Button>
                        <span style={{marginLeft: '65%', fontSize: '12px', color: 'var(--color-cinza-medio)'}}> 
                            Você será redirecionado para outra tela
                        </span>
                    </div>
                </Row>
            </Collapse>
        </div>
    )
}