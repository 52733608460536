import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Label, Row, Input, FormGroup } from "reactstrap";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import {BsArrowDown, BsArrowUp, BsArrowDownUp} from 'react-icons/bs'

import { Select } from "../../../components/Inputs/Input";
import { COLUMNS_LOG } from "../constants/columns";

import * as actions from '../../../store/modules/parametrosAutomacao/actions'
import * as controller from '../../../controllers/parametrosAutomacaoController'
import { dias, diasSemana } from "../../campanhaPremiacao/constants/dias";

const Register = () => {
    const { item, recorrencias ,layoutesPlanilha, seeRegister, getAllLog } = useSelector(state => state.parametrosAutomacao);

    useEffect(()=>{
        controller.getRecorrencias()
        controller.getLayoutes()
    },[])

    const columns = useMemo(() => COLUMNS_LOG, []);
    const data = item.log;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const { pageSize } = state;
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        controller.getLog(item.id, pageSelected + 1, pageSize)
    }


    function handleInitialPage(e) {
        const pageSelected = 0;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        controller.getLog(item.id, pageSelected + 1, e)
    }

    useEffect(() => {
        if(getAllLog.tamanhoPagina){
            setPageSize(getAllLog.tamanhoPagina);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllLog])

    return (
        <>
            <Col>
                <Card className="pt-3 pb-2 mb-5">
                    <Col>
                        <div className="pb-3">
                            <Label style={{ fontWeight: '400', fontSize: '18px', margin: '5px 0 8px 15px', color: 'var(--color-preto)' }}>Parametrização da automação</Label>
                        </div>
                    </Col>
                    <div style={{margin: '5px 30px 30px 30px', borderBottom: '1px solid #c4c7ca', padding: '10px'}}>
                        <Row style={{marginBottom: '10px'}}>
                            <h3 style={{fontWeight: '700', fontSize: '16px', color: 'var(--color-preto)'}}>Informações</h3>
                        </Row>
                        <Row style={{marginBottom: '10px'}}>
                            <Col md={8}>
                                <Label style={{fontWeight: '700'}}>URL de acesso</Label>
                                <Input
                                 type="text"
                                 value={item.url}
                                 onChange={e => actions.setItem('url', e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Label style={{fontWeight: '700'}}>Instituição</Label>
                                <Input
                                 type="text"
                                 value={item.instituicao}
                                 disabled
                                />
                            </Col>
                            <Col md={5}>
                                <Label style={{fontWeight: '700'}}>Layout da planilha</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: item.layoutPlanilhaId }}
                                    select={{ value: "id", label: "descricao" }}
                                    options={layoutesPlanilha}
                                    onChange={e => actions.setItem('layoutPlanilhaId', e.value)}
                                    autoComplete="off"
                                    disabled={seeRegister}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div style={{margin: '5px 30px 30px 30px', borderBottom: '1px solid #c4c7ca', padding: '10px'}}>
                        <Row style={{marginBottom: '10px'}}>
                            <h3 style={{fontWeight: '700', fontSize: '16px', color: 'var(--color-preto)'}}>Recorrência</h3>
                        </Row>
                        <Row style={{marginBottom: '10px'}}>
                        <Col md={2}>
                                <Label>Recorrência</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: item.recorrenciaId }}
                                    select={{ value: "id", label: "descricao" }}
                                    options={recorrencias}
                                    onChange={e => actions.setItem('recorrenciaId', e.value)}
                                    autoComplete="off"
                                    disabled={seeRegister}
                                />
                            </Col>
                           {(item.recorrenciaId === '62bfebc3-64d0-4c21-98ab-08d9f7a3b6c6' || item.recorrenciaId === 'a0eb69a3-5a23-42fb-9f5a-1098e360331d') &&
                            <Col md={2}>
                                <Label>Intervalo</Label>
                                <Input
                                 type="time"
                                 value={item.intervalo}
                                 onChange={e => actions.setItem('intervalo', e.target.value)}
                                 disabled={seeRegister}
                                 max='23:59'
                                />
                            </Col>}
                            {item.recorrenciaId === 'f164e5a0-73f2-4fb2-98ae-08d9f7a3b6c6' &&
                            <Col md={2}>
                                <Label>Dia da semana</Label>
                                <FormGroup>
                                                <Select
                                                    first='SELECIONE'
                                                    value={{ value: item?.diaDaSemana?.toString() }}
                                                    select={{ value: 'value', label: 'label' }}
                                                    options={diasSemana}
                                                    onChange={e => actions.setItem('diaDaSemana',e.value)}
                                                    isSearchable={true}
                                                    autoComplete='off'
                                                    disabled={seeRegister}

                                                />
                                            </FormGroup>
                            </Col>}
                            {item.recorrenciaId === '2b69b83a-4102-485c-98ad-08d9f7a3b6c6' &&
                            <Col md={2}>
                                <Label>Dia do mês</Label>
                                <FormGroup>
                                                <Select
                                                    first='SELECIONE'
                                                    value={{ value: item?.diaDoMes?.toString() }}
                                                    select={{ value: 'value', label: 'label' }}
                                                    options={dias}
                                                    onChange={e => actions.setItem('diaDoMes',e.value)}
                                                    isSearchable={true}
                                                    autoComplete='off'
                                                    disabled={seeRegister}

                                                />
                                            </FormGroup>
                            </Col>}
                            <Col md={2}>
                                <Label>Hora início</Label>
                                <Input
                                 type="time"
                                 value={item.horaInicio}
                                 onChange={e => actions.setItem('horaInicio', e.target.value)}
                                 disabled={seeRegister}
                                />
                            </Col>
                            <Col md={2}>
                                <Label>Hora fim</Label>
                                <Input
                                 type="time"
                                 value={item.horaFim}
                                 onChange={e => actions.setItem('horaFim', e.target.value)}
                                 disabled={seeRegister}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div style={{margin: '5px 30px 30px 30px', borderBottom: '1px solid #c4c7ca', padding: '10px'}}>
                        <Row style={{marginBottom: '10px'}}>
                            <h3 style={{fontWeight: '700', fontSize: '16px', color: 'var(--color-preto)'}}>Credenciais de acesso</h3>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Label style={{fontWeight: '700'}}>Usuário</Label>
                                <Input
                                 type="text"
                                 value={item.usuario}
                                 onChange={e => actions.setItem('usuario', e.target.value)}
                                 disabled={seeRegister}
                                />
                            </Col>
                            <Col md={4}>
                                <Label style={{fontWeight: '700'}}>Senha</Label>
                                <Input
                                 type="password"
                                 placeholder="*************"
                                 value={item.senha}
                                 onChange={e => actions.setItem('senha', e.target.value)}
                                 disabled={seeRegister}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div style={{margin: '5px 30px 30px 30px', borderBottom: '1px solid #c4c7ca', padding: '10px'}}>
                        <Row style={{marginBottom: '10px'}}>
                            <h3 style={{fontWeight: '700', fontSize: '16px', color: 'var(--color-preto)'}}>Log</h3>
                        </Row>
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th>
                                                {column.render("Header")}
                                                <span className="float-right">
                                                    {column.isSorted ?
                                                        (column.isSortedDesc ?
                                                            <BsArrowDown
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />
                                                            :
                                                            <BsArrowUp
                                                                id="OrdenarAsc"
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />)
                                                        :
                                                        <BsArrowDownUp
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr className="text-center" {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Row className="pt-4">
                            <Col>
                                <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {getAllLog.quantidade !== undefined ? getAllLog.quantidade : "0"} de {getAllLog.quantidadeTotal !== undefined ? getAllLog.quantidadeTotal : "0"}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={getAllLog.totalPaginas}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        disableInitialCallback={true}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { handleInitialPage(e.target.value); setPageSize(Number(e.target.value)) }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Card >
            </Col >
        </>
    );
}


export default Register;
