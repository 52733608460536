import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    pesquisa: {
        nomeUser: '',
        email: '',
        dataInicial: '',
        dataFinal: '',
        layoutPlanilha: '',
        tipoArquivo: '0',
        statusImportacao: '0',
        nomeArquivo: '',
        nomeLayout: '',
    },
    item: {
        id: '',
        tipoArquivo: '0',
        layoutPlanilhaId: '0',
        instituicao: '0',
        arquivos: [],
        inconsistencias: [],
    },
    possuiInconsistencia: false,
    download: {
        arquivoBase64: '',
        nomeArquivo: '0',
    },
    layouts: [],
    bancos: [],
    layoutsPlanilha: [],
    statusImportacoes: [],
    uploads: [],
    tiposArquivo: [],
    copia: {},
    activeTab: "Search",
    seeRegister: false,
    getAllItems: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    getAllItemsInconsistencia: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    permitions: {
        criar: false,
        deletar: false,
        estornar: false,
    }
}

export default function uploadComissao(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.PESQUISAR:
                    return { ...state, pesquisa: action.value };
                case TYPES.COPIA:
                    return { ...state, copia: action.value };
                case TYPES.SET_BANCOS:
                    return { ...state, bancos: action.value };
                case TYPES.SET_LAYOUT_PLANILHA:
                    return { ...state, layoutsPlanilha: action.value };
                case TYPES.SET_NOME_LAYOUT:
                    return { ...state, nomeLayout: action.value };
                case TYPES.STATUS_IMPORTACOES:
                    return { ...state, statusImportacoes: action.value };
                case TYPES.SET_UPLOADS:
                    return { ...state, uploads: action.value };
                case TYPES.SET_ITEM:
                    return { ...state, item: { ...state.item, [action.campo]: action.value } };
                case TYPES.SET_TIPOS:
                    return { ...state, tiposArquivo: action.value };
                case TYPES.SET_DOWNLOAD:
                    return { ...state, download: action.value };
                case TYPES.CLEAN_DOWNLOAD:
                    return { ...state, download: INITIAL_STATE.download }
                case TYPES.CLEAN_SEARCH:
                    return { ...state, uploads: INITIAL_STATE.uploads, pesquisa: INITIAL_STATE.pesquisa }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, item: INITIAL_STATE.item }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SEARCH:
                    return { ...state, search: action.value }
                case TYPES.LAYOUTS:
                    return { ...state, layouts: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                case TYPES.GET_ALL_ITEMS:
                    return { ...state, getAllItems: action.value }
                case TYPES.SET_POSSUI_INCONSISTENCIA:
                    return { ...state, possuiInconsistencia: action.value }
                case TYPES.GET_ALL_ITEMS_INCONSISTENCIA:
                    return { ...state, getAllItemsInconsistencia: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}