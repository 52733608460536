const STORE_ID = "PROPOSTAS";

const TYPES = {
  SET_PERMITIONS: "SET_PERMITIONS",
  SET_PERMITIONS_TAB: "SET_PERMITIONS_TAB",
  PESQUISAR: "PESQUISAR",
  SET_TAB: "SET_TAB",
  PROPOSTAS: "PROPOSTAS",
  SET_ACTIVE_DASH: "SET_ACTIVE_DASH",
  ITEM: "ITEM",
  UPDATE: "UPDATE",
  INSTITUICOES: "INSTITUICOES",
  PRODUTOS: "PRODUTOS",
  CONVENIOS: "CONVENIOS",
  COMISSOES: "COMISSOES",
  SET_GET_ALL: "SET_GET_ALL",
  TIPO_OPERACAO: "TIPO_OPERACAO",
  STATUS: "STATUS",
  DATA_ENTRADA: "DATA_INICIO",
  DATA_STATUS: "DATA_STATUS",
  TIPO_DATA: "TIPO_DATA",
  TIPOS_COMISSAO: "TIPOS_COMISSAO",
  CLEAN_SEARCH: "CLEAN_SEARCH",
  CLEAN_REGISTER: "CLEAN_REGISTER",
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  SET_SEE: "SET_SEE",
  SET_FILTROS: "SET_FILTROS",

};

export { STORE_ID, TYPES };
