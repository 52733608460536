import api from '../util/api'
import * as actions from '../store/modules/inconsistenciaProducao/actions'
import { setLoading } from '../store/modules/globalLoading/actions'

import { maskDate } from '../util/masks'
import { notifyWarn } from '../components/message/message'

export async function getQuantidade(inconsistencias) {

    setLoading(true)
    const data = await api.get(`inconsistencia-sistema-proposta/quantidades/possivel-corrigir`)
    setLoading(false)

    if (!data.success) {
        return
    }

    inconsistencias.forEach(inconsistencia =>{
        let inconsistenciaFound = data.content.find((e) => inconsistencia.id === e.id)
        if(inconsistenciaFound){
            inconsistencia.quantidade = inconsistenciaFound.quantidade
        }else{
            inconsistencia.quantidade = 0
        }
    })

    actions.setTabs(inconsistencias)
};

export async function getPropostas(planilhaId, codDigitador, tabela) {

    setLoading(true)
    const data = await api.get(`inconsistencia-sistema-proposta/proposta?codigoDigitador=${codDigitador}&planilhaId=${planilhaId}`)
    setLoading(false)

    if (!data.success) {
        return
    }

    tabela.forEach(registro =>{
        if(registro.usuario === codDigitador && registro.planilhaId === planilhaId){
                registro.propostas = data.content.map((proposta) => 
                {return(
                    {
                        numeroProposta : proposta.numeroProposta,
                        dataEntradaProposta : maskDate(proposta.dataEntradaProposta, "YYYY-MM-DD")
                    }
                )
                })
        }
    })

    actions.setUsuarios(tabela)
};

export async function getInconsistencias(tab, inconsistencias){

    setLoading(true)
    const data = await api.get(`inconsistencia-sistema-proposta/tipo-inconsistencia/${tab.id}`)

    if (!data.success) {
        return setLoading(false)
    }

    if(tab.tab === "1"){
        let response =  data.content.map((e)=>{
            let propostas = []
            return({
                usuario: e.codigoDigitador,
                planilhaId: e.planilhaImportacaoId,
                instituicao: e.nomeBanco,
                dataEntradaProposta: maskDate(e.dataEntradaProposta, 'YYYY-MM-DD'),
                bancoId: e.bancoId,
                nomeArquivoImportado: e.nomeArquivo,
                propostas: propostas
            })
        })
        let usuarios = inconsistencias
        response.forEach((response) =>{
            if( !inconsistencias.find((proposta) => (response.usuario === proposta.usuario) && (response.planilhaId === proposta.planilhaId))){
                usuarios.push(response)
            }
        })
        actions.setUsuarios(usuarios)
    }else if (tab.tab === "2"){
        let produtos = []
        data.content.forEach((e)=>{
            let propostas = []
            e.propostas.forEach((proposta) => {
                propostas.push({
                    numeroProposta : proposta.numeroProposta,
                    dataEntradaProposta : String(proposta.dataEntradaProposta).substring(0,10)
                })
            })
            produtos.push({
                produtoInstituicao: e.produto,
                instituicao: e.nomeBanco,
                bancoId: e.bancoId,
                nomeArquivoImportado: e.nomeArquivo,
                propostas: propostas
            },)
        })
        actions.setProdutos(produtos)
    }else if (tab.tab === "3"){
        let tiposOperacao = []
        data.content.forEach((e)=>{
            let propostas = []
            e.propostas.forEach((proposta) => {
                propostas.push({
                    numeroProposta : proposta.numeroProposta,
                    dataEntradaProposta : String(proposta.dataEntradaProposta).substring(0,10)
                })
            })
            tiposOperacao.push({
                operacaoInstituicao: e.tipoOperacao,
                instituicao: e.nomeBanco,
                bancoId: e.bancoId,
                nomeArquivoImportado: e.nomeArquivo,
                propostas: propostas
            },)
        })
        actions.setTiposOperacao(tiposOperacao)
    }else if (tab.tab === "4"){
        let status = []
        data.content.forEach((e)=>{
            let propostas = []
            e.propostas.forEach((proposta) => {
                propostas.push({
                    numeroProposta : proposta.numeroProposta,
                    dataEntradaProposta : String(proposta.dataEntradaProposta).substring(0,10)
                })
            })
            status.push({
                statusInstituicao: e.statusProposta,
                instituicao: e.nomeBanco,
                bancoId: e.bancoId,
                nomeArquivoImportado: e.nomeArquivo,
                propostas: propostas
            },)
        })
        actions.setStatus(status)
    }else if (tab.tab === "5"){
        let convenios = []
        data.content.forEach((e)=>{
            let propostas = []
            e.propostas.forEach((proposta) => {
                propostas.push({
                    numeroProposta : proposta.numeroProposta,
                    dataEntradaProposta : String(proposta.dataEntradaProposta).substring(0,10)
                })
            })
            convenios.push({
                convenioInstituicao: e.codigoConvenio,
                instituicao: e.nomeBanco,
                bancoId: e.bancoId,
                nomeArquivoImportado: e.nomeArquivo,
                propostas: propostas
            },)
        })
        actions.setConvenios(convenios)
    }else if (tab.tab === "6"){
        let tiposFormalizacao = []
        data.content.forEach((e)=>{
            let propostas = []
            e.propostas.forEach((proposta) => {
                propostas.push({
                    numeroProposta : proposta.numeroProposta,
                    dataEntradaProposta : String(proposta.dataEntradaProposta).substring(0,10)
                })
            })
            tiposFormalizacao.push({
                tipoFormalizacao: e.tipoFormalizacao,
                instituicao: e.nomeBanco,
                bancoId: e.bancoId,
                nomeArquivoImportado: e.nomeArquivo,
                propostas: propostas
            },)
        })
        actions.setFormalizacoes(tiposFormalizacao)
    }else if (tab.tab === "7"){
        let clientes = []
        data.content.forEach((e)=>{
            let propostas = []
            e.propostas.forEach((proposta) => {
                propostas.push({
                    numeroProposta : proposta.numeroProposta,
                    dataEntradaProposta : String(proposta.dataEntradaProposta).substring(0,10)
                })
            })
            clientes.push({
                cliente: e.nomeCliente,
                cpf: e.codigoCliente,
                bancoId: e.bancoId,
                nomeArquivoImportado: e.nomeArquivo,
                propostas: propostas
            },)
        })
        actions.setClientes(clientes)
    }else if (tab.tab === "8"){
        let lojas = []
        data.content.forEach((e)=>{
            let propostas = []
            e.propostas.forEach((proposta) => {
                propostas.push({
                    numeroProposta : proposta.numeroProposta,
                    dataEntradaProposta : String(proposta.dataEntradaProposta).substring(0,10)
                })
            })
            lojas.push({
                codLoja: e.lojaBanco,
                instituicao: e.nomeBanco,
                bancoId: e.bancoId,
                nomeArquivoImportado: e.nomeArquivo,
                propostas: propostas
            },)
        })
        actions.setLojas(lojas)
    }
    setLoading(false)
}

export async function correcaoInconsistencia(inconsistencia, tab, propostas) {

    let body = {}
    if(tab.tab === "1"){
        if(!inconsistencia.propostas[0].dataEntradaProposta){
            return notifyWarn('Expanda a inconsistencia antes de corrigi-la')
        }
         body = {
            tipoInconsistenciaId: tab.id,
            descricao: inconsistencia.usuario,
            bancoId: inconsistencia.bancoId,
            dataEntrada: maskDate(inconsistencia.propostas[0].dataEntradaProposta, 'YYYY-MM-DD')
        }
    }else if (tab.tab === "2"){
        body = {
            tipoInconsistenciaId: tab.id,
            descricao: inconsistencia.produtoInstituicao,
            bancoId: inconsistencia.bancoId
        }
    }else if (tab.tab === "3"){
        body = {
            tipoInconsistenciaId: tab.id,
            descricao: inconsistencia.operacaoInstituicao,
            bancoId: inconsistencia.bancoId
        }
    }else if (tab.tab === "4"){
        body = {
            tipoInconsistenciaId: tab.id,
            descricao: inconsistencia.statusInstituicao,
            bancoId: inconsistencia.bancoId
        }
    }else if (tab.tab === "5"){
        body = {
            tipoInconsistenciaId: tab.id,
            descricao: inconsistencia.convenioInstituicao,
            bancoId: inconsistencia.bancoId
        }
    }else if (tab.tab === "6"){
        body = {
            tipoInconsistenciaId: tab.id,
            descricao: inconsistencia.tipoFormalizacao,
            bancoId: inconsistencia.bancoId
        }
    }else if (tab.tab === "7"){
        body = {
            tipoInconsistenciaId: tab.id,
            descricao: inconsistencia.cpf,
            bancoId: inconsistencia.bancoId
        }
    }else if (tab.tab === "8"){
        body = {
            tipoInconsistenciaId: tab.id,
            descricao: inconsistencia.codLoja,
            bancoId: inconsistencia.bancoId
        }
    }

    setLoading(true)
    const data = await api.post("inconsistencia-sistema-proposta/correcao", body);
    setLoading(false)

    if (!data.success) {
    return;
    }

    if(tab.tab === '1'){
        propostas.forEach((proposta, index) =>{
            if(proposta.usuario === inconsistencia.usuario && proposta.bancoId === inconsistencia.bancoId ){
                propostas.splice(index, 1)
            }
        })        
        getInconsistencias(tab, propostas)
    }else{
        getInconsistencias(tab)
    }
}