import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Collapse,
} from "reactstrap";
import classnames from "classnames";
import { useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import * as BiIcons from "react-icons/bi";
import { useTable, useSortBy, usePagination } from "react-table";
import { COLUMNS_SUPERACAO, COLUMNS_REGRA_REPASSE } from "../constants/columns";
import * as BsIcons from "react-icons/bs";
import Select from "../../../components/Inputs/Select";
import { Switch } from "../../../components/Inputs/Input";
import { useSelector } from "react-redux";
import {
  AiFillPlusCircle,
  AiOutlineClose,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import {
  maskMoeda,
  maskNumber,
  removeMaskMoeda,
  maskMoeda4,
  maskMoeda4new,
  formatDecimal4,
} from "../../../util/masks";

import * as controller from "../../../controllers/premiacaoController/eventosController";
import * as controllerCampanha from "../../../controllers/premiacaoController/campanhaPremiacao";
import * as actions from "../../../store/modules/campanhaPremiacao/eventos/actions";
import { useEffect } from "react";
import { notifyWarn } from "../../../components/message/message";

export function ModalRegraProdutividade({
  isModalOpen,
  toggle,
  onChangeMetaProdutividade,
  addNewHierarquia,
  handleSaveMetaProdutividade,
  handleDeleteMetaProdutividade,
  currentRegraProdutividade,
}) {
  const { campanhaPremiacaoMetaProdutividade } = useSelector(
    (state) => state.eventosCampanha
  );
  const { item, seeRegister } = useSelector((state) => state.campanhaPremiacao);

  const [activeTab, setActiveTab] = useState(0);
  const [metaProdutividadeBeenEdited, setMetaProdutividadeBeenEdited] =
    useState(null);

  useEffect(() => {
    if (currentRegraProdutividade.id) {
      controllerCampanha.getTiposComissaoProduto(currentRegraProdutividade.id);
    }
  }, [currentRegraProdutividade.id]);

  async function onChange(field, value, index) {
    await onChangeMetaProdutividade(field, value, index);
  }

  async function handleAddNewHierarquia() {
    const nonSavedMeta = campanhaPremiacaoMetaProdutividade.find(
      (meta) => !meta.id
    );
    if (nonSavedMeta) {
      const response = await handleSaveMetaProdutividade(
        nonSavedMeta,
        campanhaPremiacaoMetaProdutividade.findIndex((meta) => !meta.id),
        true
      );
      if (response.success) {
        setActiveTab(campanhaPremiacaoMetaProdutividade.length);
      }
    } else if (metaProdutividadeBeenEdited) {
      const response = await handleSaveMetaProdutividade(
        metaProdutividadeBeenEdited,
        campanhaPremiacaoMetaProdutividade.findIndex(
          (meta) => meta.id === metaProdutividadeBeenEdited.id
        ),
        true
      );
      if (response.success) {
        setMetaProdutividadeBeenEdited(null);
        setActiveTab(campanhaPremiacaoMetaProdutividade.length);
      }
    } else {
      addNewHierarquia();
      setActiveTab(campanhaPremiacaoMetaProdutividade.length - 1);
    }
  }

  async function handleDeleteHierarquia(hierarquia, index) {
    handleDeleteMetaProdutividade(hierarquia, index);
  }

  async function handleChangeTab(index) {
    const nonSavedMeta = campanhaPremiacaoMetaProdutividade.find(
      (meta) => !meta.id
    );
    if (nonSavedMeta) {
      const response = await handleSaveMetaProdutividade(
        nonSavedMeta,
        campanhaPremiacaoMetaProdutividade.findIndex((meta) => !meta.id),
        true
      );
      if (response.success) {
        setActiveTab(campanhaPremiacaoMetaProdutividade.length);
      }
    } else if (metaProdutividadeBeenEdited) {
      const response = await handleSaveMetaProdutividade(
        metaProdutividadeBeenEdited,
        campanhaPremiacaoMetaProdutividade.findIndex(
          (meta) => meta.id === metaProdutividadeBeenEdited.id
        ),
        true
      );
      if (response.success) {
        setMetaProdutividadeBeenEdited(null);
        setActiveTab(campanhaPremiacaoMetaProdutividade.length);
      }
    }
    setActiveTab(index);
  }

  async function handleConfirmar() {
    let isSuccess = true;
    if (!item.hasAproved) {
      const nonSavedMeta = campanhaPremiacaoMetaProdutividade.find(
        (meta) => !meta.id
      );
      if (nonSavedMeta) {
        const response = await handleSaveMetaProdutividade(
          nonSavedMeta,
          campanhaPremiacaoMetaProdutividade.findIndex((meta) => !meta.id)
        );
        isSuccess = response.success;
      }
      if (metaProdutividadeBeenEdited) {
        const response = await handleSaveMetaProdutividade(
          metaProdutividadeBeenEdited,
          campanhaPremiacaoMetaProdutividade.findIndex(
            (meta) => meta.id === metaProdutividadeBeenEdited.id
          )
        );
        isSuccess = response.success;
      }
    }
    if (isSuccess) {
      setMetaProdutividadeBeenEdited(null);
      actions.cleanMetaProdutividade();
      toggle();
    }
  }
  useEffect(() => {
    setActiveTab(campanhaPremiacaoMetaProdutividade.length - 1);
  }, [campanhaPremiacaoMetaProdutividade.length]);

  const formatarValor = (valor) => {
    const numeroFormatado = parseFloat(valor.replace(/[^\d]/g, "")).toFixed(5);
    const valorFormatado = numeroFormatado.replace(".", ","); // Troca o ponto por vírgula
    return `R$${valorFormatado}`;
  };
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggle}
      style={{ maxWidth: "1600px", width: "80%" }}
    >
      <ModalBody style={{ padding: "20px" }}>
        <div style={{ display: "flex", marginRight: "5px" }}>
          <div style={{ marginLeft: "auto", width: "5px" }}>
            {<AiOutlineClose onClick={toggle} style={{ cursor: "pointer" }} />}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <p
            style={{ fontSize: "16px", fontWeight: "700", marginRight: "5px" }}
          >
            Regra:
          </p>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "700",
              color: "var(--color-roxo)",
            }}
          >
            {currentRegraProdutividade.name}
          </p>
        </div>
        <Nav style={{ marginLeft: "25px", marginBottom: "30px" }}>
          {campanhaPremiacaoMetaProdutividade.map((hierarquia, index) => (
            <NavItem style={{ padding: "0 5px 0 5px" }} key={`nav${index}`}>
              <NavLink
                className={classnames({ active: activeTab === index })}
                onClick={() => handleChangeTab(index)}
                style={
                  activeTab === index
                    ? {
                        color: "var(--color-preto",
                        fontWeight: "700",
                        borderBottom: "3px solid var(--color-roxo)",
                        transition: "0.1s",
                        cursor: "pointer",
                      }
                    : {
                        color: "var(--color-preto",
                        fontWeight: "700",
                        cursor: "pointer",
                      }
                }
              >
                {hierarquia.hierarquia?.label?.toUpperCase() ?? ""}{" "}
                {!seeRegister && !item.hasAproved && (
                  <AiOutlineClose
                    style={{ color: "gray" }}
                    onClick={() => handleDeleteHierarquia(hierarquia, index)}
                  />
                )}
              </NavLink>
            </NavItem>
          ))}
          {!item.hasAproved && !seeRegister && (
            <NavItem style={{ padding: "0 5px 0 5px" }}>
              <NavLink
                onClick={() => {
                  handleAddNewHierarquia();
                }}
                style={{
                  color: "var(--color-preto",
                  fontWeight: "700",
                  cursor: "pointer",
                }}
              >
                Adicionar Hierarquia{" "}
                <AiFillPlusCircle style={{ color: "gray" }} />
              </NavLink>
            </NavItem>
          )}
        </Nav>
        {seeRegister && campanhaPremiacaoMetaProdutividade.length === 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <h3> Não há metas cadastradas para nenhuma hierarquia </h3>
          </div>
        )}
        <TabContent activeTab={activeTab}>
          {campanhaPremiacaoMetaProdutividade.map((hierarquia, index) => (
            <TabPane tabId={index} key={`content${index}`}>
              <ModalContent
                content={hierarquia}
                index={index}
                onChange={onChange}
                handleSaveMetaProdutividade={handleSaveMetaProdutividade}
                handleConfirmar={handleConfirmar}
                setMetaProdutividadeBeenEdited={setMetaProdutividadeBeenEdited}
              />
            </TabPane>
          ))}
        </TabContent>
      </ModalBody>
    </Modal>
  );
}

function ModalContent({
  content,
  index,
  onChange,
  handleSaveMetaProdutividade,
  handleConfirmar,
  setMetaProdutividadeBeenEdited,
}) {
  const {
    campanhaPremiacaoMetaProdutividade,
    canais,
    hierarquiasProprio,
    hierarquiasTerceiro,
    parametrosMedida,
    medidasPremiacao,
  } = useSelector((state) => state.eventosCampanha);
  const { item, seeRegister, tiposComissao, tiposComissaoProduto } =
    useSelector((state) => state.campanhaPremiacao);

  const [isMetaProdutividadeOpen, setIsMetaProdutividadeOpen] = useState(true);
  const [isMetaSuperacaoOpen, setIsMetaSuperacaoOpen] = useState(false);
  const [isRegraRepasseOpen, setIsRegraRepasseOpen] = useState(false);

  const [thisHierarquiasProprio, setThisHierarquiasProprio] = useState([]);
  const [thisHierarquiasTerceiro, setThisHierarquiasTerceiro] = useState([]);
  const [isProdutividadeCompleted, setIsProdutividadeCompleted] =
    useState(false);

  useEffect(() => {
    if (!content.id) {
      if (
        item.tipoFuncionarioId === "0" ||
        content.hierarquia.value === "0" ||
        content.parametroMedidaCampanhaId === "0" ||
        content.valorMeta === "" ||
        content.valorMeta === "0,00" ||
        content.medidaPremiacaoCampanhaId === "0" ||
        content.valorPremio === "" ||
        content.valorPremio === "0,00"
      ) {
        setIsProdutividadeCompleted(false);
      } else {
        setIsProdutividadeCompleted(true);
      }
    }
  }, [content]);

  useEffect(() => {
    let filteredHierarquiaProprio = hierarquiasProprio.filter(
      (hierarquia) =>
        !campanhaPremiacaoMetaProdutividade.find(
          (campanha) =>
            campanha?.hierarquia?.value === hierarquia.id &&
            campanha.hierarquia.value !== content.hierarquia.value
        )
    );
    let filteredHierarquiaTerceiro = hierarquiasTerceiro.filter(
      (hierarquia) =>
        !campanhaPremiacaoMetaProdutividade.find(
          (campanha) =>
            campanha?.hierarquia?.value === hierarquia.id &&
            campanha.hierarquia.value !== content.hierarquia.value
        )
    );
    setThisHierarquiasProprio(filteredHierarquiaProprio);
    setThisHierarquiasTerceiro(filteredHierarquiaTerceiro);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hierarquiasProprio,
    hierarquiasTerceiro,
    campanhaPremiacaoMetaProdutividade,
  ]);

  const [campanhaPremiacaoMetaSuperacao, setCampanhaPremiacaoMetaSuperacao] =
    useState({
      id: "",
      descricao: "",
      medidaPremiacaoSuperacaoId: "0",
      valorMeta: "",
      valorPremio: "",
    });

  const [campanhaPremiacaoRegraRepasse, setCampanhaPremiacaoRegraRepasse] =
    useState({
      id: "",
      pmt: "",
      tiposComissaoId: "0",
      valor: "",
    });

  async function handleOnChangeHierarquia(hierarquiaId, hierarquia) {
    await onChange(
      "hierarquia",
      { value: hierarquiaId, label: hierarquia },
      index
    );
    if (content.id) {
      setMetaProdutividadeBeenEdited({
        ...content,
        hierarquia: { value: hierarquiaId, label: hierarquia },
      });
    }
  }

  function onChangeMetaSuperacao(field, value) {
    setCampanhaPremiacaoMetaSuperacao({
      ...campanhaPremiacaoMetaSuperacao,
      [field]: value,
    });
  }

  function onChangeRegraRepasse(field, value) {
    setCampanhaPremiacaoRegraRepasse({
      ...campanhaPremiacaoRegraRepasse,
      [field]: value,
    });
  }

  async function handleDeleteSuperacao(id, indexSuperacao) {
    const isSuceeded = await controller.deleteMetaSuperacao(id);
    if (isSuceeded) {
      let newStringArray = JSON.stringify(
        content.campanhaPremiacaoMetaSuperacao
      );
      let newArray = JSON.parse(newStringArray);
      newArray.splice(indexSuperacao, 1);
      onChange("campanhaPremiacaoMetaSuperacao", newArray, index);
    }
  }

  async function handleDeleteRegraRepasse(id, indexRepasse) {
    const isSuceeded = await controller.deleteRegraRepasse(id);
    if (isSuceeded) {
      let newStringArray = JSON.stringify(
        content.campanhaPremiacaoMetaProdutividadeRegraRepasse
      );
      let newArray = JSON.parse(newStringArray);
      newArray.splice(indexRepasse, 1);
      onChange(
        "campanhaPremiacaoMetaProdutividadeRegraRepasse",
        newArray,
        index
      );
    }
    return isSuceeded;
  }

  async function handleAddMeta() {
    if (!content.id && !isProdutividadeCompleted) {
      return notifyWarn(
        "É necessário preencher os campos da meta de produtividade"
      );
    }
    if (campanhaPremiacaoMetaSuperacao.descricao === "") {
      return notifyWarn("Preencha o campo Nível");
    }
    if (campanhaPremiacaoMetaSuperacao.valorMeta === "") {
      return notifyWarn("Preencha o campo Meta");
    }
    if (campanhaPremiacaoMetaSuperacao.medidaPremiacaoSuperacaoId === "0") {
      return notifyWarn("Preencha o campo Medida de premiação");
    }
    if (!content.id) {
      const response = await handleSaveMetaProdutividade(
        content,
        campanhaPremiacaoMetaProdutividade.findIndex((meta) => !meta.id)
      );
      if (response.success) {
        content.id = response.id;
      }
    }

    if (!campanhaPremiacaoMetaSuperacao.id) {
      let response = await controller.createMetaSuperacao(
        campanhaPremiacaoMetaSuperacao,
        content.id
      );
      if (response.success) {
        const { descricao } = medidasPremiacao.find(
          (medida) =>
            medida.id ===
            campanhaPremiacaoMetaSuperacao.medidaPremiacaoSuperacaoId
        );
        const newArray = [
          ...content.campanhaPremiacaoMetaSuperacao,
          {
            ...campanhaPremiacaoMetaSuperacao,
            id: response.id,
            medidaPremiacaoSuperacao: descricao,
            valorMeta: Number(
              removeMaskMoeda(campanhaPremiacaoMetaSuperacao.valorMeta)
            ),
            valorPremio: Number(
              removeMaskMoeda(campanhaPremiacaoMetaSuperacao.valorPremio)
            ),
          },
        ];
        onChange("campanhaPremiacaoMetaSuperacao", newArray, index);
        setCampanhaPremiacaoMetaSuperacao({
          descricao: "",
          medidaPremiacaoSuperacaoId: "0",
          valorMeta: "",
          valorPremio: "",
        });
      }
    } else {
      let response = await controller.updateMetaSuperacao(
        campanhaPremiacaoMetaSuperacao,
        content.id
      );
      if (response.success) {
        let newStringArray = JSON.stringify(
          content.campanhaPremiacaoMetaSuperacao
        );
        let newArray = JSON.parse(newStringArray);
        const { descricao } = medidasPremiacao.find(
          (medida) =>
            medida.id ===
            campanhaPremiacaoMetaSuperacao.medidaPremiacaoSuperacaoId
        );
        newArray[
          newArray.findIndex(
            (meta) => meta.id === campanhaPremiacaoMetaSuperacao.id
          )
        ] = {
          ...campanhaPremiacaoMetaSuperacao,
          medidaPremiacaoSuperacao: descricao,
          valorMeta: Number(
            removeMaskMoeda(campanhaPremiacaoMetaSuperacao.valorMeta)
          ),
          valorPremio: Number(
            removeMaskMoeda(campanhaPremiacaoMetaSuperacao.valorPremio)
          ),
        };
        onChange("campanhaPremiacaoMetaSuperacao", newArray, index);
        setCampanhaPremiacaoMetaSuperacao({
          descricao: "",
          medidaPremiacaoSuperacaoId: "0",
          valorMeta: "",
          valorPremio: "",
        });
      }
    }
  }
  async function handleAddRegraRepasse() {
    if (!content.id && !isProdutividadeCompleted) {
      return notifyWarn(
        "É necessário preencher os campos da meta de produtividade"
      );
    }
    if (campanhaPremiacaoRegraRepasse.tiposComissaoId === "0") {
      return notifyWarn(
        "É necessário informar tipo de comissão para inserir uma regra de repasse"
      );
    }
    if (campanhaPremiacaoRegraRepasse.pmt === "") {
      return notifyWarn(
        "É necessário informar o pmt para inserir uma regra de repasse"
      );
    }
    if (campanhaPremiacaoRegraRepasse.valor === "") {
      return notifyWarn(
        "É necessário informar o valor para inserir uma regra de repasse"
      );
    }
    if (!content.id) {
      const response = await handleSaveMetaProdutividade(
        content,
        campanhaPremiacaoMetaProdutividade.findIndex((meta) => !meta.id)
      );
      if (response.success) {
        content.id = response.id;
      }
    }

    if (!campanhaPremiacaoRegraRepasse.id) {
      let response = await controller.createRegraRepasse(
        campanhaPremiacaoRegraRepasse,
        content.id
      );
      if (response.success) {
        const { descricao } = tiposComissao.find(
          (tipoComissao) =>
            tipoComissao.id === campanhaPremiacaoRegraRepasse.tiposComissaoId
        );
        const newArray = [
          ...content.campanhaPremiacaoMetaProdutividadeRegraRepasse,
          {
            ...campanhaPremiacaoRegraRepasse,
            id: response.id,
            tipoComissao: descricao,
            valor: removeMaskMoeda(campanhaPremiacaoRegraRepasse.valor),
          },
        ];
        onChange(
          "campanhaPremiacaoMetaProdutividadeRegraRepasse",
          newArray,
          index
        );
        setCampanhaPremiacaoRegraRepasse({
          id: "",
          pmt: "",
          tiposComissaoId: "0",
          valor: "",
        });
      }
    } else {
      let response = await controller.updateRegraRepasse(
        campanhaPremiacaoRegraRepasse,
        content.id
      );
      if (response.success) {
        let newStringArray = JSON.stringify(
          content.campanhaPremiacaoMetaProdutividadeRegraRepasse
        );
        let newArray = JSON.parse(newStringArray);
        const { descricao } = tiposComissao.find(
          (tipoComissao) =>
            tipoComissao.id === campanhaPremiacaoRegraRepasse.tiposComissaoId
        );
        newArray[
          newArray.findIndex(
            (meta) => meta.id === campanhaPremiacaoRegraRepasse.id
          )
        ] = {
          ...campanhaPremiacaoRegraRepasse,
          tipoComissao: descricao,
          valor: removeMaskMoeda(campanhaPremiacaoRegraRepasse.valor),
        };
        onChange(
          "campanhaPremiacaoMetaProdutividadeRegraRepasse",
          newArray,
          index
        );
        setCampanhaPremiacaoRegraRepasse({
          id: "",
          pmt: "",
          tiposComissaoId: "0",
          valor: "",
        });
      }
    }
  }
  const handleValorPremiacaoChange = (e) => {
    if (
      content.medidaPremiacaoCampanhaId === "0" ||
      content.medidaPremiacaoCampanhaId === ""
    ) {
      return notifyWarn("Selecione uma medida de premiação");
    }

    const valorPremio =
      content.medidaPremiacaoCampanhaId ===
      "3b256d42-40a0-4bb7-9f5b-93f3deb148eb"
        ? maskMoeda4(e.target.value)
        : maskMoeda(e.target.value);

    onChange("valorPremio", valorPremio, index);
    content.id &&
      setMetaProdutividadeBeenEdited({
        ...content,
        valorPremio: valorPremio,
      });
  };
  const handleMedidaPremiacaoChange = (e) => {
    if (campanhaPremiacaoMetaSuperacao.medidaPremiacaoSuperacaoId === "0") {
      return notifyWarn("Selecione uma medida de premiação");
    }

    const valorPremio =
      campanhaPremiacaoMetaSuperacao.medidaPremiacaoSuperacaoId ===
      "3b256d42-40a0-4bb7-9f5b-93f3deb148eb"
        ? maskMoeda4(e.target.value)
        : maskMoeda(e.target.value);

    onChangeMetaSuperacao("valorPremio", valorPremio);
    campanhaPremiacaoMetaSuperacao.id &&
      setMetaProdutividadeBeenEdited({
        ...campanhaPremiacaoMetaSuperacao,
        valorPremio: valorPremio,
      });
  };

  const prizeValueCampaign = useMemo(() => {
    if (
      content.medidaPremiacaoCampanhaId ===
      "3b256d42-40a0-4bb7-9f5b-93f3deb148eb"
    ) {
      return maskMoeda4(content.valorPremio);
    }

    const valorPremio = parseFloat(content.valorPremio?.replace(",", ".") || 0);

    const valorPremioFloat = isNaN(valorPremio) ? 0 : valorPremio;

    return valorPremioFloat.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }, [content.valorPremio, content.medidaPremiacaoCampanhaId]);

  return (
    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
      <h2
        style={{
          cursor: "poineter",
          fontSize: "18px",
          fontWeight: 700,
          color: "var(--color-roxo)",
          marginBottom: "20px",
        }}
        onClick={() => setIsMetaProdutividadeOpen(!isMetaProdutividadeOpen)}
      >
        Adicionar meta de produtividade{" "}
        {isMetaProdutividadeOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
      </h2>
      <Collapse isOpen={isMetaProdutividadeOpen}>
        <Row>
          <Col md={3}>
            <Label>
              Hierarquia{" "}
              <span style={{ color: "var(--color-laranja)", fontSize: "16px" }}>
                *
              </span>{" "}
            </Label>
            <Select
              value={content.hierarquia?.value}
              label="descricao"
              options={
                !item.tipoFuncionarioId
                  ? []
                  : item.tipoFuncionarioId ===
                    "eeafe6f2-8e3a-436c-9065-daf6d0b483c0"
                  ? thisHierarquiasProprio
                  : thisHierarquiasTerceiro
              }
              onChange={async (e) => handleOnChangeHierarquia(e.value, e.label)}
              disabled={item.hasAproved ? true : seeRegister}
            />
          </Col>
          <Col md={3}>
            <Label>
              {" "}
              Parâmetro medida{" "}
              <span style={{ color: "var(--color-laranja)", fontSize: "16px" }}>
                *
              </span>{" "}
            </Label>
            <Select
              value={content.parametroMedidaCampanhaId}
              label="descricao"
              options={parametrosMedida}
              onChange={(e) => {
                onChange("parametroMedidaCampanhaId", e.value, index);
                content.id &&
                  setMetaProdutividadeBeenEdited({
                    ...content,
                    parametroMedidaCampanhaId: e.value,
                  });
              }}
              disabled={item.hasAproved ? true : seeRegister}
            />
          </Col>
          <Col md={3}>
            <Label>
              {" "}
              Valor Mínimo{" "}
              <span style={{ color: "var(--color-laranja)", fontSize: "16px" }}>
                *
              </span>{" "}
            </Label>
            <Input
              type="text"
              placeholder="Digite aqui..."
              value={content.valorMeta}
              onChange={(e) => {
                onChange("valorMeta", maskMoeda(e.target.value), index);
                content.id &&
                  setMetaProdutividadeBeenEdited({
                    ...content,
                    valorMeta: maskMoeda(e.target.value),
                  });
              }}
              disabled={item.hasAproved ? true : seeRegister}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Label>
              Medida Premiação{" "}
              <span style={{ color: "var(--color-laranja)", fontSize: "16px" }}>
                *
              </span>{" "}
            </Label>
            <Select
              value={content.medidaPremiacaoCampanhaId}
              label="descricao"
              options={medidasPremiacao}
              onChange={(e) => {
                onChange("medidaPremiacaoCampanhaId", e.value, index);
                content.id &&
                  setMetaProdutividadeBeenEdited({
                    ...content,
                    medidaPremiacaoCampanhaId: e.value,
                  });
              }}
              disabled={item.hasAproved ? true : seeRegister}
            />
          </Col>
          <Col md={3}>
            <Label>
              Valor Premiação{" "}
              <span style={{ color: "var(--color-laranja)", fontSize: "16px" }}>
                *
              </span>{" "}
            </Label>
            <Input
              type="text"
              placeholder="Digite aqui..."
              value={prizeValueCampaign}
              onChange={(e) => handleValorPremiacaoChange(e)}
              disabled={item.hasAproved ? true : seeRegister}
            />
          </Col>
        </Row>
        <Row>
          <Col md={10} />
          <Col md={2} xl={1}>
            <Switch
              className="switch"
              componentTop="HeadCount"
              checked={content.headCount}
              onChange={(e) => {
                onChange("headCount", !content.headCount, index);
                content.id &&
                  setMetaProdutividadeBeenEdited({
                    ...content,
                    headCount: !content.headCount,
                  });
              }}
              disabled={item.hasAproved ? true : seeRegister}
            />
          </Col>
        </Row>
      </Collapse>

      <div
        style={{
          width: "80%",
          margin: "30px 100px",
          borderTop: "1px solid #bbb",
        }}
      />

      <h2
        style={{
          cursor: "poineter",
          fontSize: "18px",
          fontWeight: 700,
          color: "var(--color-roxo)",
          marginBottom: "20px",
        }}
        onClick={() => setIsMetaSuperacaoOpen(!isMetaSuperacaoOpen)}
      >
        Adicionar meta de superação{" "}
        {isMetaSuperacaoOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
      </h2>
      <Collapse isOpen={isMetaSuperacaoOpen}>
        <Row>
          <Col md={3}>
            <Label>
              {" "}
              Nível{" "}
              <span style={{ color: "var(--color-laranja)", fontSize: "16px" }}>
                *
              </span>
            </Label>
            <Input
              type="text"
              placeholder="Digite aqui..."
              value={campanhaPremiacaoMetaSuperacao.descricao}
              onChange={(e) =>
                onChangeMetaSuperacao("descricao", e.target.value)
              }
              disabled={item.hasAproved ? true : seeRegister}
            />
          </Col>
          <Col md={3}>
            <Label>
              {" "}
              Meta{" "}
              <span style={{ color: "var(--color-laranja)", fontSize: "16px" }}>
                *
              </span>
            </Label>
            <Input
              type="text"
              placeholder="Digite aqui..."
              value={campanhaPremiacaoMetaSuperacao.valorMeta}
              onChange={(e) =>
                onChangeMetaSuperacao("valorMeta", maskMoeda(e.target.value))
              }
              disabled={item.hasAproved ? true : seeRegister}
            />
          </Col>
          <Col md={2}>
            <Label>
              Medida de Premiação{" "}
              <span style={{ color: "var(--color-laranja)", fontSize: "16px" }}>
                *
              </span>
            </Label>
            <Select
              value={campanhaPremiacaoMetaSuperacao.medidaPremiacaoSuperacaoId}
              label="descricao"
              options={medidasPremiacao}
              onChange={(e) =>
                onChangeMetaSuperacao("medidaPremiacaoSuperacaoId", e.value)
              }
              disabled={item.hasAproved ? true : seeRegister}
            />
          </Col>
          <Col md={3}>
            <Label>
              {" "}
              Premio de Superação{" "}
              <span style={{ color: "var(--color-laranja)", fontSize: "16px" }}>
                *
              </span>
            </Label>
            <Input
              type="text"
              placeholder="Digite aqui..."
              value={campanhaPremiacaoMetaSuperacao.valorPremio}
              onChange={(e) => handleMedidaPremiacaoChange(e)}
              disabled={item.hasAproved ? true : seeRegister}
            />
          </Col>
          <Col md={1}>
            {!seeRegister && !item.hasAproved && (
              <button
                style={{
                  border: "none",
                  background: "transparent",
                  margin: "5px 0 0 0",
                  color: "var(--color-preto)",
                  marginTop: "30%",
                }}
                onClick={(e) => handleAddMeta()}
              >
                <AiOutlinePlusCircle
                  style={{
                    color: "var(--color-roxo)",
                    marginRight: "5px",
                    fontSize: "20px",
                  }}
                />
              </button>
            )}
          </Col>
        </Row>
        {Boolean(content.campanhaPremiacaoMetaSuperacao.length > 0) && (
          <TabelaMetaSuperacao
            content={content.campanhaPremiacaoMetaSuperacao}
            handleDeleteSuperacao={handleDeleteSuperacao}
            setCampanhaPremiacaoMetaSuperacao={
              setCampanhaPremiacaoMetaSuperacao
            }
          />
        )}
      </Collapse>
      <div
        style={{
          width: "80%",
          margin: "30px 100px",
          borderTop: "1px solid #bbb",
        }}
      />

      <h2
        style={{
          cursor: "poineter",
          fontSize: "18px",
          fontWeight: 700,
          color: "var(--color-roxo)",
          marginBottom: "20px",
        }}
        onClick={() => setIsRegraRepasseOpen(!isRegraRepasseOpen)}
      >
        Adicionar regra de repasse{" "}
        {isRegraRepasseOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
      </h2>
      <Collapse isOpen={isRegraRepasseOpen}>
        <Row>
          <Col md={4}>
            <Label>
              {" "}
              Tipo de Comissão{" "}
              <span style={{ color: "var(--color-laranja)", fontSize: "16px" }}>
                *
              </span>
            </Label>
            <Select
              value={campanhaPremiacaoRegraRepasse.tiposComissaoId}
              options={tiposComissaoProduto}
              label="descricao"
              onChange={(e) => onChangeRegraRepasse("tiposComissaoId", e.value)}
              disabled={item.hasAproved ? true : seeRegister}
            />
          </Col>
          <Col md={3}>
            <Label>
              PMT{" "}
              <span style={{ color: "var(--color-laranja)", fontSize: "16px" }}>
                *
              </span>
            </Label>
            <Input
              type="text"
              placeholder="Digite aqui..."
              value={campanhaPremiacaoRegraRepasse.pmt}
              onChange={(e) =>
                onChangeRegraRepasse("pmt", maskNumber(e.target.value))
              }
              disabled={item.hasAproved ? true : seeRegister}
            />
          </Col>
          <Col md={4}>
            <Label>
              {" "}
              % sobre % recebido{" "}
              <span style={{ color: "var(--color-laranja)", fontSize: "16px" }}>
                *
              </span>
            </Label>
            <Input
              type="text"
              placeholder="Digite aqui..."
              maxLength={6}
              value={campanhaPremiacaoRegraRepasse.valor}
              onChange={(e) =>
                onChangeRegraRepasse(
                  "valor",
                  maskMoeda(e.target.value).replaceAll(",", ".") > 100
                    ? "100,00"
                    : maskMoeda(e.target.value)
                )
              }
              disabled={item.hasAproved ? true : seeRegister}
            />
          </Col>
          <Col md={1}>
            {!seeRegister && !item.hasAproved && (
              <button
                style={{
                  border: "none",
                  background: "transparent",
                  margin: "5px 0 0 0",
                  color: "var(--color-preto)",
                  marginTop: "30%",
                }}
                onClick={(e) => handleAddRegraRepasse()}
              >
                <AiOutlinePlusCircle
                  style={{
                    color: "var(--color-roxo)",
                    marginRight: "5px",
                    fontSize: "20px",
                  }}
                />
              </button>
            )}
          </Col>
        </Row>
        {Boolean(
          content.campanhaPremiacaoMetaProdutividadeRegraRepasse.length > 0
        ) && (
          <TabelaRegraRepasse
            content={content.campanhaPremiacaoMetaProdutividadeRegraRepasse}
            deleteRegraRepasse={handleDeleteRegraRepasse}
            setCampanhaPremiacaoRegraRepasse={setCampanhaPremiacaoRegraRepasse}
          />
        )}
      </Collapse>
      <ModalFooter className="p-3 mt-5">
        <Button
          className="purpleButton"
          style={{ width: "150px" }}
          onClick={handleConfirmar}
        >
          Confirmar
        </Button>
      </ModalFooter>
    </div>
  );
}

function TabelaMetaSuperacao({
  content,
  setCampanhaPremiacaoMetaSuperacao,
  handleDeleteSuperacao,
}) {
  const { item, seeRegister } = useSelector((state) => state.campanhaPremiacao);
  const columns = useMemo(() => COLUMNS_SUPERACAO, []);
  let data = content;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state,
    setPageSize,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { pageSize } = state;
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  const handlePageClick = (e) => {
    const pageSelected = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
  };
  function getTotalValueMetas(values) {
    const total = values.reduce((acc, value) => acc + Number(value), 0);
    return maskMoeda(total.toFixed(2));
  }
  function getTotalValuePremios(values, content) {
    if (!values || values.length === 0) return "0";

    if (!Array.isArray(content)) {
      return "Conteúdo inválido.";
    }

    if (content.length === 0) {
      return "Conteúdo vazio.";
    }

    const medidasPremiacao = content.map(
      (item) => item?.medidaPremiacaoSuperacaoId
    );
    const primeiraMedidaId = medidasPremiacao[0];

    const todasMedidasIguais = medidasPremiacao.every(
      (id) => id === primeiraMedidaId
    );

    if (!todasMedidasIguais) {
      return " Não é possível realizar a soma de duas medidas diferentes.";
    }

    const medidaPremiacao = primeiraMedidaId;

    let totalMonetario = 0;
    let totalPorcentagem = 0;

    values.forEach((value) => {
      if (!value) {
        return;
      }

      let valorNumerico = parseFloat(
        value.toString().replace("%", "").replace("R$", "").replace(",", ".")
      );

      if (isNaN(valorNumerico)) {
        valorNumerico = 0;
      }

      if (medidaPremiacao === "3b256d42-40a0-4bb7-9f5b-93f3deb148eb") {
        // Porcentagem
        totalPorcentagem += parseFloat(valorNumerico);
        console.log("Total porcentagem:", totalPorcentagem);
      } else if (medidaPremiacao === "352e8dfc-ce3e-4f3d-8f66-f3d6847dae07") {
        // Valor monetário
        totalMonetario += parseFloat(valorNumerico);
      } else if (medidaPremiacao === "db829fda-28d6-4cd1-8712-4819475d93d7") {
        // Valor por quantidade
        totalMonetario += parseInt(valorNumerico);
      }
    });

    if (medidaPremiacao === "3b256d42-40a0-4bb7-9f5b-93f3deb148eb") {
      // Porcentagem
      return `${totalPorcentagem.toFixed(4)}%`;
    } else if (medidaPremiacao === "352e8dfc-ce3e-4f3d-8f66-f3d6847dae07") {
      // Valor monetário
      return `R$ ${maskMoeda(totalMonetario.toFixed(2))}`;
    } else if (medidaPremiacao === "db829fda-28d6-4cd1-8712-4819475d93d7") {
      // Valor por quantidade
      return totalMonetario;
    } else {
      return "Medida de premiação não reconhecida.";
    }
  }

  const metas = getTotalValueMetas(data.map((d) => d.valorMeta));
  const premios = getTotalValuePremios(
    data.map((d) => d.valorPremio),
    content
  );

  async function handleUpdate(row) {
    let valorMetaFormatted, valorPremioFormatted;

    switch (row.medidaPremiacaoSuperacaoId) {
      case "3b256d42-40a0-4bb7-9f5b-93f3deb148eb":
        // Porcentagem
        valorPremioFormatted = `${row.valorPremio.toFixed(4)}%`;
        break;
      case "352e8dfc-ce3e-4f3d-8f66-f3d6847dae07":
        // Valor monetário
        valorPremioFormatted = `R$ ${row.valorPremio.toFixed(2)}`;
        break;
      case "db829fda-28d6-4cd1-8712-4819475d93d7":
        // Valor por quantidade
        valorPremioFormatted = row.valorPremio;
        break;
      default:
        valorPremioFormatted = "Medida de premiação não reconhecida.";
        break;
    }

    valorMetaFormatted = valorMetaFormatted || row.valorMeta;

    setCampanhaPremiacaoMetaSuperacao({
      ...row,
      valorMeta: valorMetaFormatted,
      valorPremio: valorPremioFormatted,
    });
  }

  function handleInitialPage() {
    gotoPage(0);
    setInitialPage(true);
    setSelectedPage(0);
  }
  const pageCount = Math.ceil(rows.length / pageSize);
  return (
    <div style={{ padding: "30px" }}>
      <table
        {...getTableProps()}
        style={{ width: "100%", borderCollapse: "collapse" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  style={{
                    background: "white",
                    height: "40px",
                    border: "1px solid #b9cbdb",
                    padding: "10px",
                    textAlign: "center",
                  }}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                  <span className="float-right">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <BsIcons.BsArrowDown
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({ title: undefined })
                          )}
                        />
                      ) : (
                        <BsIcons.BsArrowUp
                          id="OrdenarAsc"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({ title: undefined })
                          )}
                        />
                      )
                    ) : (
                      <BsIcons.BsArrowDownUp
                        id="Ordenar"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({ title: undefined })
                        )}
                      />
                    )}
                  </span>
                </th>
              ))}
              <th
                style={{
                  background: "white",
                  height: "40px",
                  border: "1px solid #b9cbdb",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                Ações
              </th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                key={`tableSuperacao${index}`}
                style={{
                  height: "40px",
                  border: "1px solid #b9cbdb",
                  padding: "10px",
                  textAlign: "center",
                }}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{ textAlign: "center" }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
                <td style={{ textAlign: "center", width: "10%" }}>
                  {!seeRegister && !item.hasAproved && (
                    <>
                      <BiIcons.BiEdit
                        style={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          color: "#C4C7CA",
                          marginRight: "7px",
                        }}
                        onClick={() => handleUpdate(row.original)}
                      />
                      <BiIcons.BiTrash
                        style={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          color: "#C4C7CA",
                        }}
                        onClick={() =>
                          handleDeleteSuperacao(row.original.id, row.index)
                        }
                      />
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <td
            colSpan={headerGroups[0].headers.length}
            style={{
              textAlign: "right",
              padding: "10px",
              borderTop: "1px solid #b9cbdb",
              background: "white",
            }}
          >
            <span style={{ marginLeft: "20px" }}>
              <strong>Valor Total Metas:</strong> R$ {metas}
            </span>
            <span style={{ marginLeft: "20px" }}>
              <strong>Valor Total Superação:</strong>
              {premios}
            </span>
          </td>
        </tfoot>
      </table>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span style={{ color: "var(--color-cinza-medio)" }}>
          Mostrando {page.length} de {content.length}
        </span>

        <div style={{ display: "flex", alignItems: "center" }}>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            initialPage={0}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "10px",
            }}
          >
            <strong style={{ marginBottom: "15px" }}>Itens por página:</strong>
            <Input
              type="select"
              className="pageSize"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                handleInitialPage();
              }}
              style={{ marginLeft: "5px", marginBottom: "35px" }}
            >
              {[5, 10, 25].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Input>
          </div>
        </div>
      </div>
    </div>
  );
}

function TabelaRegraRepasse({
  content,
  setCampanhaPremiacaoRegraRepasse,
  deleteRegraRepasse,
}) {
  const { item, seeRegister } = useSelector((state) => state.campanhaPremiacao);
  const columns = useMemo(() => COLUMNS_REGRA_REPASSE, []);
  let data = content;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state,
    setPageSize,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  );

  const { pageSize } = state;
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  useEffect(() => {
    setPageSize(5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageClick = (e) => {
    const pageSelected = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
  };

  async function handleUpdate(row) {
    setCampanhaPremiacaoRegraRepasse({
      ...row,
      valor: maskMoeda(Number(row.valor).toFixed(2)),
    });
  }

  function handleInitialPage() {
    gotoPage(0);
    setInitialPage(true);
    setSelectedPage(0);
  }
  const pageCount = Math.ceil(rows.length / pageSize);

  async function handleDeleteRegraRepasse(id, index) {
    await deleteRegraRepasse(id, index);
  }
  return (
    <div style={{ padding: "30px" }}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  style={{
                    background: "white",
                    height: "40px",
                    border: "1px solid #b9cbdb",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {column.render("Header")}
                  <span className="float-right">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <BsIcons.BsArrowDown
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({ title: undefined })
                          )}
                        />
                      ) : (
                        <BsIcons.BsArrowUp
                          id="OrdenarAsc"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({ title: undefined })
                          )}
                        />
                      )
                    ) : (
                      <BsIcons.BsArrowDownUp
                        id="Ordenar"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps({ title: undefined })
                        )}
                      />
                    )}
                  </span>
                </th>
              ))}
              <th
                style={{
                  background: "white",
                  height: "40px",
                  border: "1px solid #b9cbdb",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                Ações
              </th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                key={`tableRepasse${index}`}
                style={{
                  height: "40px",
                  border: "1px solid #b9cbdb",
                  padding: "10px",
                  textAlign: "center",
                }}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{ textAlign: "center" }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
                <td style={{ textAlign: "center", width: "10%" }}>
                  {!seeRegister && !item.hasAproved && (
                    <>
                      <BiIcons.BiEdit
                        style={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          color: "#C4C7CA",
                          marginRight: "7px",
                        }}
                        onClick={() => handleUpdate(row.original)}
                      />
                      <BiIcons.BiTrash
                        style={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          color: "#C4C7CA",
                        }}
                        onClick={() =>
                          handleDeleteRegraRepasse(row.original.id, row.index)
                        }
                      />
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Row className="pt-4">
        <Col>
          <span style={{ color: "var(--color-cinza-medio)" }}>
            Mostrando {page.length} de {content.length}
          </span>
        </Col>
        <Col md={8}>
          <Row className="float-right">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              initialPage={0}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={initialPage ? 0 : selectedPage}
            />
            <Input
              type="select"
              className="pageSize"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                handleInitialPage();
              }}
            >
              {[5, 10, 25].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Input>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
