import React, { useEffect } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Button, Card, Col, Form, Row, TabContent, TabPane } from 'reactstrap';
import { useSelector } from "react-redux";
import * as  AntContasReceberController from "../../controllers/antecipacaoContasReceberController";
import * as AntContasReceberActions from "../../store/modules/antecipacaoContasReceber/actions";
import Breadcrumb from '../../components/breadcrumbs';
import Search from './tabs/search';
import Register from './tabs/register';

const AntecipacaoContasReceber = () => {

    const { item, search, activeTab, copy, seeRegister, permitions } = useSelector(state => state.antContasReceber);

    useEffect(() => {
        AntContasReceberActions.setActiveTab("Search")
    }, [])

    async function saveItem() {
        if (!item.id) {
            AntContasReceberController.create(item, search);
        } else {
            await AntContasReceberController.update(item, copy);
        }
    }

    function changeTab(tab) {
        AntContasReceberActions.setActiveTab(tab);
        AntContasReceberActions.cleanRegister();
    }

    function seeRegisterF() {
        if (item.id !== "") {
            AntContasReceberActions.cleanRegister();
        }
        AntContasReceberActions.setSeeRegister(false);
    }


    return (
        <>
                <Col>
                    <Breadcrumb activeTab={activeTab} />
                    <Card>
                        <Form>
                            {
                                activeTab === "Search"

                                    ?
                                    permitions.criar && (
                                    <Col md={12}>
                                        <Row className="pt-2 pb-2">
                                            <Col md={8}>
                                                <Button
                                                    onClick={() => changeTab("Register")}
                                                    style={{ width: "300px" }}
                                                    className="purpleButton"
                                                >
                                                    Definir parâmetros
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    )
                                    :
                                    <Col className="pt-2 pb-2">
                                        <Button
                                            onClick={() => { changeTab("Search"); seeRegisterF() }}
                                            style={{ width: "127px", color: "white" }}
                                            className="purpleButton"
                                        >
                                            <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar
                                        </Button>
                                        {!seeRegister &&
                                            <Button
                                                onClick={() => saveItem()}
                                                style={{ width: "242px", float: 'right' }}
                                                className="purpleButton"
                                            >
                                                Salvar Cadastro
                                            </Button>}
                                    </Col>
                            }
                        </Form>
                    </Card>
                </Col>

                <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <br />
                        <Search />
                    </TabPane>
                    <TabPane tabId="Register">
                        <br />
                        <Register />
                    </TabPane>
                </TabContent>
        </>
    )
}

export default AntecipacaoContasReceber
