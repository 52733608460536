import React, { useState, useMemo, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import ReactPaginate from "react-paginate";
import { MdContentCopy } from 'react-icons/md';
import { useTable, useSortBy, usePagination } from "react-table";
import { BsArrowDown, BsArrowUp, BsArrowDownUp } from 'react-icons/bs';
import { RiArrowDownSLine, RiArrowRightSLine, RiArrowUpSLine, RiErrorWarningLine } from 'react-icons/ri';
import { Collapse, Row, Col, Input, Label, Button, Table } from 'reactstrap'

import { COLUMNS } from "./constants/columns";
import * as controller from '../../controllers/inconsistenciaComissaoController'
import { FiRefreshCcw } from 'react-icons/fi';
import { notifySuccess } from '../../components/message/message';

export default function TabelaComissao(){
    const { tabelasDeComissao, tab } = useSelector(state => state.inconsistenciaComissao);

    return(
        <div style={{width: '93%', marginLeft: '25px'}}>
            {tabelasDeComissao.map((e,i)=>{
                return(
                    <CollapsedCard inconsistencia={e} index={i} tab={tab}/>
                )
            })}
        </div>
    )
}

function CollapsedCard({inconsistencia, index, tab}){
    const { tabs } = useSelector(state => state.inconsistenciaComissao);
    const columns = useMemo(() => COLUMNS, []);
    const data = inconsistencia.propostas;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
        columns,
        data
    },
        useSortBy,
        usePagination);

    const { pageSize } = state;
    const [rows, setRows] = useState(0);
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    useEffect(() => {
        setRows(page.length);
    }, [page])

    useEffect(() => {
        setPageSize(5);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
    }

    const pageCount = Math.ceil(data.length / pageSize);

    const [isCardOpen, setIsCardOpen] = useState(false)

    function handleCorrigirInconsistencia(){
        const baseURL = window.location.origin
        window.open(`${baseURL}/tabeladecomissao?isRedirect=true`)
    }

    async function handleRefresh(){
        await controller.correcaoInconsistencia(inconsistencia, tab)
        await controller.getQuantidade(tabs)
    }
    return(
        <div style={{borderTop: '1px solid #CCCFDE', marginBottom: '30px'}}>
            <div style={{display: 'flex', marginTop: '30px'}} >
                <RiErrorWarningLine style={{color: 'var(--color-laranja)',marginRight: '5px', fontSize: '25px'}}/>
                <p style={{fontWeight: 'bold', margin: '5px 75% 0 0'}}>
                    {`Inconsistência de tabela de comissão ${String(index+1).padStart(2,'0')}`}
                </p>
                <FiRefreshCcw
                style={{paddingTop: '5px', fontSize: '20px', cursor: 'pointer'}} 
                onClick={() => handleRefresh()}/>
                {isCardOpen ? 
                    <RiArrowUpSLine 
                    style={{fontSize: '25px', cursor: 'pointer'}}
                    onClick={()=> setIsCardOpen(!isCardOpen)}/> 
                : 
                    <RiArrowDownSLine 
                    style={{fontSize: '25px', cursor: 'pointer'}}
                    onClick={()=> setIsCardOpen(!isCardOpen)}/>}
            </div>
            <Collapse isOpen={isCardOpen} style={{paddingTop: '30px', paddingLeft: '5px'}}>
                <Row>
                    <Col md={3}>
                        <Label style={{fontWeight: 'bold'}}>Tabela</Label>
                        {inconsistencia.tabelaComissao ?
                            <div style={{display: 'flex', border: '1px solid var(--color-laranja)', borderRadius: '0.25rem', padding: '8px 0 8px 10px', background: '#E9ECEF'}}>
                            <p style={{width: '95%', margin: '0'}}>
                                {inconsistencia.tabelaComissao}
                            </p>
                            <CopyToClipboard text={inconsistencia.tabelaComissao} onCopy={()=> notifySuccess('Copiado!')}>
                                <button style={{background: 'transparent', border: 'none'}}> <MdContentCopy style={{color: 'var(--color-verde-claro)', marginRight: '10px'}}/></button>
                            </CopyToClipboard>
                            </div>
                        :
                            <Input
                                type="text"
                                placeholder="Busque um favorecido..."
                                value={inconsistencia.tabelaComissao}
                                disabled
                            />
                        }
                    </Col>
                    <Col md={3}>
                        <Label style={{fontWeight: 'bold'}}>Produto</Label>
                        {!inconsistencia.tabelaComissao?
                            <div style={{display: 'flex', border: '1px solid var(--color-laranja)', borderRadius: '0.25rem', padding: '8px 0 8px 10px', background: '#E9ECEF'}}>
                            <p style={{width: '95%', margin: '0'}}>
                                {inconsistencia.produto}
                            </p>
                            <CopyToClipboard text={inconsistencia.produto} onCopy={()=> notifySuccess('Copiado!')}>
                                <button style={{background: 'transparent', border: 'none'}}> <MdContentCopy style={{color: 'var(--color-verde-claro)', marginRight: '10px'}}/></button>
                            </CopyToClipboard>
                            </div>
                        :
                            <Input
                                type="text"
                                placeholder="Busque um favorecido..."
                                value={inconsistencia.produto}
                                disabled
                            />
                        }
                    </Col>
                    <Col md={3}>
                        <Label style={{fontWeight: 'bold'}}>Convênio</Label>
                        {!inconsistencia.tabelaComissao ? 
                            <div style={{display: 'flex', border: '1px solid var(--color-laranja)', borderRadius: '0.25rem', padding: '8px 0 8px 10px', background: '#E9ECEF'}}>
                            <p style={{width: '95%', margin: '0'}}>
                                {inconsistencia.convenio}
                            </p>
                            <CopyToClipboard text={inconsistencia.convenio} onCopy={()=> notifySuccess('Copiado!')}>
                                <button style={{background: 'transparent', border: 'none'}}> <MdContentCopy style={{color: 'var(--color-verde-claro)', marginRight: '10px'}}/></button>
                            </CopyToClipboard>
                            </div>
                        :
                            <Input
                            type="text"
                            placeholder="Busque um favorecido..."
                            value={inconsistencia.convenio}
                            disabled
                            />
                        }
                    </Col>
                    <Col md={3}>
                        <Label style={{fontWeight: 'bold'}}>Instituição Financeira</Label>
                        <Input
                            type="text"
                            placeholder="Busque um favorecido..."
                            value={inconsistencia.instituicao}
                            disabled
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: '15px'}}>
                    <Col md={3}>
                        <Label style={{fontWeight: 'bold'}}>Quantidade de parcelas</Label>
                        {!inconsistencia.tabelaComissao ?
                             <div style={{display: 'flex', border: '1px solid var(--color-laranja)', borderRadius: '0.25rem', padding: '8px 0 8px 10px', background: '#E9ECEF'}}>
                             <p style={{width: '95%', margin: '0'}}>
                                 {inconsistencia.quantidadeParcelas}
                             </p>
                             <CopyToClipboard text={inconsistencia.quantidadeParcelas} onCopy={()=> notifySuccess('Copiado!')}>
                                 <button style={{background: 'transparent', border: 'none'}}> <MdContentCopy style={{color: 'var(--color-verde-claro)', marginRight: '10px'}}/></button>
                             </CopyToClipboard>
                             </div>
                        :
                            <Input
                            type="text"
                            placeholder="Busque um favorecido..."
                            value={inconsistencia.quantidadeParcelas}
                            disabled
                            />
                         }
                    </Col>
                    <Col md={3}>
                        <Label style={{fontWeight: 'bold'}}>Nome do arquivo importado</Label>
                        <Input
                            type="text"
                            placeholder="Busque um favorecido..."
                            value={inconsistencia.nomeArquivoImportado}
                            disabled
                        />
                    </Col>
                    <div style={{width: '50%'}}>
                        <Button 
                        className='purpleButton' 
                        style={{width: '216px',margin: '12px 0 7px 60%'}}
                        onClick={()=> handleCorrigirInconsistencia()}>
                            <RiArrowRightSLine style={{fontSize: '20px', paddingBottom: '3px'}}/> 
                            Corrigir Inconsistência
                        </Button>
                        <span style={{marginLeft: '60%', fontSize: '12px', color: 'var(--color-cinza-medio)'}}> 
                            Você será redirecionado para outra tela
                        </span>
                    </div>
                </Row>
                    <Table striped {...getTableProps()} style={{marginTop: '20px'}}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th>
                                            {column.render("Header")}
                                            <span className="float-right">
                                                {column.isSorted ?
                                                    (column.isSortedDesc ?
                                                        <BsArrowDown
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />
                                                        :
                                                        <BsArrowUp
                                                            id="OrdenarAsc"
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />)
                                                    :
                                                    <BsArrowDownUp
                                                        id="Ordenar"
                                                        {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                    />}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <tr className="text-center" {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                        <Row className="pt-4">
                            <Col>
                                <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {rows} de {data.length}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                </Row>
                            </Col>
                        </Row>
            </Collapse>
        </div>
    )
}