import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as convenioActions from "../store/modules/convenioInstituicao/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("conveniosBanco");
    setLoading(false)

    if (!data.success) {
        return;
    }

    convenioActions.getItems(data.content);
}

export async function getConveniosRc() {

    const data = await api.get("convenios");

    if (!data.success) {
        return;
    }

    convenioActions.setConveniosRc(data.content);
}

export async function getBancos() {

    const data = await api.get("bancos");

    if (!data.success) {
        return;
    }

    convenioActions.setBancos(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`conveniosBanco/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    const dataUpdate = {
        descricao: data.content.descricao,
        bancoId: data.content.banco.id,
        codConvenio: data.content.codigoConvenio,
        convenioRcId: data.content.convenio.id,
        id: data.content.id
    }

    convenioActions.copy(dataUpdate);
    convenioActions.item(dataUpdate);
}

export async function create(item) {

    if (!item.descricao || item.descricao.trim() === "") {
        convenioActions.setInvalid("descricao");
        return notifyWarn("Descrição do convênio instituição não pode estar em branco");
    }

    if (item.codConvenio.trim() === "") {
        convenioActions.setInvalid("codConvenio");
        return notifyWarn("Código do convênio não pode estar em branco");
    }

    if (item.bancoId === "0") {
        convenioActions.setInvalid("bancoId");
        return notifyWarn("Selecione uma instituição");
    }

    if (item.convenioRcId === "0") {
        convenioActions.setInvalid("convenioRcId");
        return notifyWarn("Selecione um convênio ribercred");
    }

    const body = {
        descricao: item.descricao,
        codigoConvenio: item.codConvenio,
        bancoId: item.bancoId,
        convenioId: item.convenioRcId
    }

    setLoading(true)
    const data = await api.post("conveniosBanco", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Convênio instituição cadastrado");
    convenioActions.cleanRegister();
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`conveniosBanco/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Convênio instituição removido");
    get();
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (!item.descricao || item.descricao.trim() === "") {
        convenioActions.setInvalid("descricao");
        return notifyWarn("Descrição do convênio instituição não pode estar em branco");
    }

    if (item.codConvenio.trim() === "") {
        convenioActions.setInvalid("codConvenio");
        return notifyWarn("Código do convênio não pode estar em branco");
    }

    if (item.bancoId === "0") {
        convenioActions.setInvalid("bancoId");
        return notifyWarn("Selecione uma instituição");
    }

    if (item.convenioRcId === "0") {
        convenioActions.setInvalid("convenioRcId");
        notifyWarn("Selecione um convênio ribercred");
        return;
    }

    const body = {
        descricao: item.descricao,
        bancoId: item.bancoId,
        codigoConvenio: item.codConvenio,
        convenioId: item.convenioRcId
    }

    setLoading(true)
    const data = await api.put(`conveniosBanco/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Convênio instituição atualizado com sucesso");
    convenioActions.cleanRegister();
    convenioActions.setActiveTab("Search");
    get();
}
