import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    documentos: [],
    seeRegister: false,
    tiposDocumentos: [],
    documento: {
        nome: "",
        tipoDoc: "",
        tipoDocumentoId: "0",
        base64: "",
        id: null
    }
}

export default function personasDocumentacao(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:

            switch (action.type) {

                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state,
                        documentos: [],
                        documento: INITIAL_STATE.documento
                    }

                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }

                case TYPES.CLEAN_DOCUMENT:
                    return { ...state, documento: INITIAL_STATE.documento }

                case TYPES.GET_TIPOS_DOCUMENTO:
                    return { ...state, tiposDocumentos: action.value }

                case TYPES.DOCUMENTOS:
                    return { ...state, documentos: action.value }

                case TYPES.SETDOCUMENTO:
                    return { ...state, documento: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}