import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    filtrosAplicados: {
      dataInicio: '',
      dataFim: '',
      instituicaoId: '0',
      instituicao: '',
      lojaId: '0',
      loja: '',
      produtoRibercredId: '0',
      produtoRibercred: '',
      tipoOperacaoId: '0',
      tipoOperacao: '',
      parceriaId: '0',
      parceria: '',
      convenioId: '0',
      convenio: '',
      grupoId: '0',
      canalId: '0',
      canal: '',
      tipoData: '1',
      grupo: '',
      consultorId: '0',
      consultor: '',
      filtrosAvancados: false
    },
    filtros: {
        dataInicio: '',
        dataFim: '',
        instituicaoId: '0',
        instituicao: '',
        lojaId: '0',
        loja: '',
        produtoRibercredId: '0',
        produtoRibercred: '',
        tipoOperacaoId: '0',
        tipoOperacao: '',
        parceriaId: '0',
        parceria: '',
        convenioId: '0',
        convenio: '',
        grupoId: '0',
        canalId: '0',
        canal: '',
        tipoData: '1',
        grupo: '',
        consultorId: '0',
        consultor: '',
        filtrosAvancados: false
    },
    modalLojas: {
        id: '',
        nome: '',
        cnpj: '',
    },
    getAllLojas: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    modalConsultores: {
        id: '',
        nome: '',
        cpf: '',
        hierarquiaId: '0',
        canalId: '0',
    },
    getAllConsultores: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    bigNumbers: [],
    tabelaProducao: [],
    tabelaProdutos: [],
    tabelaCanais: [],
    dashProducao: [],
    filtrosSelecionados: [],
    instituicoes: [],
    lojas: [],
    produtosRibercred: [],
    tiposOperacao: [],
    parcerias: [],
    convenios: [],
    grupos: [],
    hierarquias: [],
    canais: [],
    consultores: [],
    loadingLojas: false,
    loadingConsultores: false,
}

export default function dashboardProducao(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_FILTROS:
                    return { ...state, filtros: { ...state.filtros, [action.campo]: action.value } }
                case TYPES.SET_MODAL_LOJAS:
                    return { ...state, modalLojas: { ...state.modalLojas, [action.campo]: action.value } }
                case TYPES.SET_MODAL_CONSULTORES:
                    return { ...state, modalConsultores: { ...state.modalConsultores, [action.campo]: action.value } }
                case TYPES.SET_FILTROS_SELECIONADOS:
                    return { ...state, filtrosSelecionados: action.value }
                case TYPES.SET_BIG_NUMBERS:
                    return { ...state, bigNumbers: action.value }
                case TYPES.SET_TABELA_PRODUCAO:
                    return { ...state, tabelaProducao: action.value }
                case TYPES.SET_TABELA_PRODUTO:
                    return { ...state, tabelaProdutos: action.value }
                case TYPES.SET_TABELA_CANAL:
                    return { ...state, tabelaCanais: action.value }
                case TYPES.SET_DASH_PRODUCAO:
                    return { ...state, dashProducao: action.value }
                case TYPES.SET_INSTITUICOES:
                    return { ...state, instituicoes: action.value }
                case TYPES.SET_LOJAS:
                    return { ...state, lojas: action.value }
                case TYPES.SET_GET_ALL_LOJAS:
                    return { ...state, getAllLojas: action.value }
                case TYPES.SET_GET_ALL_CONSULTORES:
                    return { ...state, getAllConsultores: action.value }
                case TYPES.SET_PRODUTOS_RIBER:
                    return { ...state, produtosRibercred: action.value }
                case TYPES.SET_TIPOS_OPERACAO:
                    return { ...state, tiposOperacao: action.value }
                case TYPES.SET_PARCERIAS:
                    return { ...state, parcerias: action.value }
                case TYPES.SET_CONVENIOS:
                    return { ...state, convenios: action.value }
                case TYPES.SET_GRUPOS:
                    return { ...state, grupos: action.value }
                case TYPES.SET_HIERARQUIAS:
                    return { ...state, hierarquias: action.value }
                case TYPES.SET_CANAIS:
                    return { ...state, canais: action.value }
                case TYPES.SET_CONSULTORES:
                    return { ...state, consultores: action.value }
                case TYPES.CLEAN_FILTROS:
                    return { ...state, filtros: INITIAL_STATE.filtros }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, 
                                bigNumbers: [],
                                tabelaProducao: [],
                                tabelaProdutos: [],
                                tabelaCanais: [],
                                dashProducao: [],
                                 }
                case TYPES.SET_LOADING_LOJAS:
                    return { ...state, loadingLojas: action.value }
                case TYPES.SET_LOADING_CONSULTORES:
                    return { ...state, loadingConsultores: action.value }
                case TYPES.SET_FILTROS_APLICADOS:
                    return { ...state, filtrosAplicados: action.value }
                case TYPES.CLEAN_FILTROS_APLICADOS:
                    return { ...state, filtrosAplicados: INITIAL_STATE.filtrosAplicados }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}