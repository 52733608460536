import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}
export function setTab(value) {
    dispatch({
        type: TYPES.SET_TAB,
        value
    })
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function setTabs(value) {
    dispatch({
        type: TYPES.SET_TABS,
        value
    })
}

export function setUsuarios(value) {
    dispatch({
        type: TYPES.SET_USUARIOS,
        value
    })
}
export function setProdutos(value) {
    dispatch({
        type: TYPES.SET_PRODUTOS,
        value
    })
}
export function setTiposOperacao(value) {
    dispatch({
        type: TYPES.SET_TIPOS_OPERACAO,
        value
    })
}
export function setStatus(value) {
    dispatch({
        type: TYPES.SET_STATUS,
        value
    })
}
export function setConvenios(value) {
    dispatch({
        type: TYPES.SET_CONVENIO,
        value
    })
}
export function setFormalizacoes(value) {
    dispatch({
        type: TYPES.SET_FORMALIZACOES,
        value
    })
}
export function setClientes(value) {
    dispatch({
        type: TYPES.SET_CLIENTES,
        value
    })
}
export function setLojas(value) {
    dispatch({
        type: TYPES.SET_LOJAS,
        value
    })
}

export function cleanTabs() {
    dispatch({
        type: TYPES.CLEAN_TABS
    })
}