import React, {useState, useMemo,} from 'react'
import { useSelector } from "react-redux";
import { useTable, useSortBy } from "react-table";
import CopyToClipboard from 'react-copy-to-clipboard'
import { BsArrowUp, BsArrowDown, BsArrowDownUp } from 'react-icons/bs'
import { AiOutlineEye } from 'react-icons/ai'
import { MdContentCopy } from 'react-icons/md'

import { maskMoeda } from '../../../../util/masks'

import * as controller from '../../../../controllers/dashboardController/formalizacaoController'
import { COLUMNS_PROPOSTAS } from '../constants/columns'
import { Collapse } from 'reactstrap';


export default function Table({data}) {
    return(
        <>
            <div style={{display: 'flex'}}>
                <div style={{background: 'var(--color-gradiente-roxo)', padding: '10px 1px 5px 14px', fontWeight: '700', color: 'white', height: '40px', width: '25%'}}> Loja</div>
                <div style={{background: 'var(--color-gradiente-roxo)', padding: '10px 1px 5px 14px', fontWeight: '700', color: 'white',  height: '40px', width: '15%'}}>
                Prim. Entrega
                </div>
                <div style={{background: 'var(--color-gradiente-roxo)', padding: '10px 1px 5px 14px', fontWeight: '700', color: 'white',  height: '40px', width: '15%'}}>
                Reabertura
                </div>
                <div style={{background: 'var(--color-gradiente-roxo)', padding: '10px 1px 5px 14px', fontWeight: '700', color: 'white',  height: '40px', width: '15%'}}>
                Enviado Riber
                </div>
                <div style={{background: 'var(--color-gradiente-roxo)', padding: '10px 1px 5px 14px', fontWeight: '700', color: 'white',  height: '40px', width: '15%'}}>
                Recep. Riber
                </div>
                <div style={{background: 'var(--color-gradiente-roxo)', padding: '10px 1px 5px 14px', fontWeight: '700', color: 'white',  height: '40px', width: '15%'}}>
                Recep. Instituição
                </div>
            </div>
            {data.map((e, i)=>{
                return(
                    <FirstLevelTableRow data={e} index={i}/>
                )
            })}
        </>
    )
}

function FirstLevelTableRow({data,index}){
    const { filtros, tabela} = useSelector(state => state.dashboardFormalizacao);
    const [isPropostasActive, setIsPropostasActive] = useState(false)

    async function handleOnClick(){
        if(!isPropostasActive){
            let isSuccessed = await controller.getTabelaStatus(filtros,data.lojaId,tabela)
           isSuccessed && setIsPropostasActive(!isPropostasActive)
        }else{
            setIsPropostasActive(!isPropostasActive)
        }
    }

    return(
        <>
        <div style={{display: 'flex'}} key={index} className='tr'>
            <div 
            style={{padding: '7px',width: '25%', minHeight: '40px', borderBottom: '1px solid #CBD0D1', cursor: 'pointer'}}
            className='linhaGreen'>
                <div style={{display: 'flex'}}>
                    <div className='button' onClick={(e) => handleOnClick()}>{!isPropostasActive ? '+' : '-'}</div>
                    {data.nomeLoja}
                </div>
            </div>
            {data.statusFormalizacao.map((e)=>{
                return(
                    <div  className={e.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
                    style={{padding: '7px', width: '15%', minHeight: '40px', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                        <div>
                        <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                            {`(${e.quantidade})`} {e.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> }
                            </p>
                            <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {`R$${maskMoeda(e.valor.toFixed(2))}`}
                            </p> 
                        </div>
                    </div>
                )
            })}
        </div>
        <Collapse isOpen={isPropostasActive}>
            <SecondLevelTableRow loja={data}/>
        </Collapse>
        </>
    )
}

function SecondLevelTableRow({loja}){
    const columns = useMemo(() => COLUMNS_PROPOSTAS, []);
    const data = loja.status;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    },
        useSortBy);

        async function changeView(propostaId){
            window.open(`/propostas?id=${propostaId}`, '_blank');
        }
     return(
        <table {...getTableProps()}>
        <thead>
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                    <th >
                    {column.render("Header")}
                    <span className="float-right">
                        {column.isSorted ?
                            (column.isSortedDesc ?
                                <BsArrowDown
                                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                />
                                :
                                <BsArrowUp
                                    id="OrdenarAsc"
                                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                />)
                            :
                            <BsArrowDownUp
                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                            />}
                    </span>
                </th>
                    ))}
                    <th>Ações</th>
                </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map(row => {
            prepareRow(row)
                return (
                    <tr className="text-center" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                            if(cell.column.Header === 'Adesão'){
                                return (<td style={{width: '12%'}} {...cell.getCellProps()}>
                                    <CopyToClipboard text={cell.row.value}>
                                        <button style={{background: 'transparent', border: 'none'}}> <MdContentCopy style={{color: 'var(--color-verde-claro)', marginRight: '10px'}}/></button>
                                    </CopyToClipboard>
                                        {cell.render("Cell")}
                                        </td>)
                            }else{
                                return (<td style={{width: '12%'}}{...cell.getCellProps()}>{cell.render("Cell")}</td>)
                            }
                        })}
                        <td>
                            {
                                <div style={{display: 'flex'}}> 
                                    <AiOutlineEye style={{fontSize: '25px', paddingBottom: '5px', cursor: 'pointer', marginRight: '5px', color: 'green'}} onClick={()=> changeView(row.original.id)}/> 
                                </div>
                            }
                        </td>
                    </tr>
                );
            })}
        </tbody>
    </table>
    )
}