import api from "../util/api";
import * as actions from "../store/modules/conferenciaCr/actions";
import { maskMoeda, removeMaskCNPJ } from "../util/masks";
import { setLoading } from "../store/modules/globalLoading/actions";
import { notifySuccess } from "../components/message/message";
import * as actionsLog from "../store/modules/conferenciaCr/logW3Erp/actions";

export async function getComissaoAgrupada(pesquisa, pageSize, page) {
  var url = `comissao-recebida/agrupamento?pagina=${page}&registros=${pageSize}`;
  if (pesquisa.lojaBanco !== "") {
    url = url + `&CodigoLojaBanco=${pesquisa.lojaBanco}`;
  }
  if (pesquisa.instituicaoId !== "0") {
    url = url + `&BancoId=${pesquisa.instituicaoId}`;
  }
  if (pesquisa.centroCustoId !== "0") {
    url = url + `&CentroCustoId=${pesquisa.centroCustoId}`;
  }
  if (pesquisa.tipoComissaoId !== "0") {
    url = url + `&TipoComissaoId=${pesquisa.tipoComissaoId}`;
  }
  if (
    pesquisa.DataVencimentoInicial !== "" &&
    pesquisa.DataVencimentoFinal !== ""
  ) {
    url =
      url +
      `&DataVencimentoInicial=${pesquisa.DataVencimentoInicial}&DataVencimentoFinal=${pesquisa.DataVencimentoFinal}`;
  }
  if (pesquisa.DataEmissaoInicial !== "" && pesquisa.DataEmissaoFinal !== "") {
    url =
      url +
      `&DataEmissaoInicial=${pesquisa.DataEmissaoInicial}&DataEmissaoFinal=${pesquisa.DataEmissaoFinal}`;
  }

  if (pesquisa.nomeArquivo !== "") {
    url = url + `&nomeArquivo=${pesquisa.nomeArquivo}`;
  }
  if (pesquisa.contaBancaria !== "0") {
    url = url + `&ContaCorrenteId=${pesquisa.contaBancaria}`;
  }
  if (pesquisa.lojaId !== "0") {
    url = url + `&lojaId=${pesquisa.lojaId}`;
  }
  if (pesquisa.statusComissaoRecebidaId !== "") {
    if (
      pesquisa.statusComissaoRecebidaId === undefined ||
      pesquisa.statusComissaoRecebidaId === "0"
    ) {
      url = url + `&StatusComissaoRecebidaId=${""}`;
    } else {
      url =
        url + `&StatusComissaoRecebidaId=${pesquisa.statusComissaoRecebidaId}`;
    }
  }
  if (pesquisa.documento !== "") {
    url = url + `&Documento=${pesquisa.documento}`;
  }
  if (pesquisa.titulo !== "") {
    url = url + `&Titulo=${pesquisa.titulo}`;
  }

  if (pesquisa.integrado !== "0") {
    const isIntegrated = !!pesquisa.integrado;
    if (isIntegrated) {
      url = url + "&Integrado=true";
    } else {
      url = url + "&Integrado=false";
    }
  }

  const data = await api.get(url);
  setLoading(false);
  if (!data.success) {
    return;
  }

  data.content.registros.forEach((item) => {
    item.dataEmissao = new Date(Date.parse(item.dataEmissao));
    item.dataVencimento = new Date(Date.parse(item.dataVencimento));
    item.valorBruto =
      item.valorBruto < 0
        ? `- ${maskMoeda(item.valorBruto.toFixed(2))}`
        : maskMoeda(item.valorBruto.toFixed(2));
    item.valorLiquido =
      item.valorLiquido < 0
        ? `- ${maskMoeda(item.valorLiquido.toFixed(2))}`
        : maskMoeda(item.valorLiquido.toFixed(2));
    item.valorIr =
      item.valorIr < 0
        ? `- ${maskMoeda(item.valorIr.toFixed(2))}`
        : maskMoeda(item.valorIr.toFixed(2));
    item.produto = item.produto;
    item.statusComissaoRecebida = item.statusComissaoRecebida;
    item.documento = item.documento;
    item.integrado = item.integrado;
    item.DataEmissaoInicial = pesquisa.DataEmissaoInicial;
    item.DataEmissaoFinal = pesquisa.DataEmissaoFinal;
    item.DataVencimentoInicial = pesquisa.DataVencimentoInicial;
    item.DataVencimentoFinal = pesquisa.DataVencimentoFinal;
  });

  actions.setComissoesAgrupado(data.content.registros);
  actions.setAllAgrupado({
    quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
    tamanhoPagina: data.content.tamanhoPagina,
  });
}
export async function getComissao(pesquisa, pageSize, page, comissaoId) {
  var url = `comissao-recebida?pagina=${page}&registros=${pageSize}&Id=${comissaoId}`;

  setLoading(true);
  const data = await api.get(url);
  setLoading(false);
  if (!data.success) {
    return;
  }

  data.content.registros.forEach((item) => {
    item.dataEmissao = new Date(Date.parse(item.dataEmissao));
    item.dataVencimento = new Date(Date.parse(item.dataVencimento));
    item.valorBruto =
      item.valorBruto < 0
        ? `- ${maskMoeda(item.valorBruto.toFixed(2))}`
        : maskMoeda(item.valorBruto.toFixed(2));
    item.valorLiquido =
      item.valorLiquido < 0
        ? `- ${maskMoeda(item.valorLiquido.toFixed(2))}`
        : maskMoeda(item.valorLiquido.toFixed(2));
    item.valorIr =
      item.valorIr < 0
        ? `- ${maskMoeda(item.valorIr.toFixed(2))}`
        : maskMoeda(item.valorIr.toFixed(2));
    item.produto = item.produto;
    item.statusComissaoRecebida = item.statusComissaoRecebida;
    item.documento = item.documento;
    item.titulo = item.titulo;
    item.integrado = item.integrado;
    item.DataEmissaoInicial = pesquisa.DataEmissaoInicial;
    item.DataEmissaoFinal = pesquisa.DataEmissaoFinal;
    item.DataVencimentoInicial = pesquisa.DataVencimentoInicial;
    item.DataVencimentoFinal = pesquisa.DataVencimentoFinal;
  });

  actions.setComissoes(data.content.registros);
  actions.setAll({
    quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
    tamanhoPagina: data.content.tamanhoPagina,
  });
}

export async function getInstituicoes() {
  const data = await api.get("bancos");

  if (!data.success) {
    return;
  }
  actions.setInstituicoes(data.content);
}

export async function getAllContasCorrente() {
  const data = await api.get("conta-corrente/lojas");
  if (!data.success) {
    return;
  }
  actions.setAllContaCorrente(data.content);
}
export async function getLojasCanal(page, pageSize, pesquisa) {
  try {
    let url = `lojas/matriz/paginado?pagina=${page}&quantidade=${pageSize}`;

    if (pesquisa.nomeFantasia && pesquisa.nomeFantasia !== "") {
      url += `&nome=${pesquisa.nomeFantasia}`;
    }
    if (pesquisa.cnpj && pesquisa.cnpj !== "") {
      url += `&cnpj=${removeMaskCNPJ(pesquisa.cnpj)}`;
    }
    if (pesquisa.canalId && pesquisa.canalId !== "") {
      url += `&idCanal=${pesquisa.canalId}`;
    }

    const data = await api.get(url);

    if (!data.success) {
      return;
    }

    actions.setLojasMatrizes(data.content.registros);
    actions.setGetAllLojas({
      quantidade: data.content.quantidade,
      quantidadeTotal: data.content.quantidadeTotal,
      totalPaginas: data.content.totalPaginas,
    });
  } catch (error) {
    console.error("Error fetching lojas:", error);
  }
}

export async function getLojas(pesquisa, page, pageSize) {
  var url = `Pessoa?pagina=${page}&registros=${pageSize}&papel=5`;
  if (pesquisa.nome !== "") {
    url = `Pessoa/busca-com-nome/${pesquisa.nome}?pagina=${page}&registros=${pageSize}&papel=5`;
  } else if (pesquisa.cnpj !== "")
    url = url + `&cpfCnpj=${removeMaskCNPJ(pesquisa.cnpj)}`;

  const data = await api.get(url);

  if (!data.success) {
    return;
  }

  actions.setLojas(data.content.registros);
  actions.setGetAllLojas({
    quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
  });
}

export async function getCentrosCusto() {
  const data = await api.get(`centrosCusto`);

  if (!data.success) {
    return;
  }
  actions.setCentroscusto(data.content);
}

export async function getTiposcomissao() {
  const data = await api.get("tipoComissao");

  if (!data.success) {
    return;
  }
  actions.setTiposComissao(data.content);
}

export async function getContasBancaria(lojaId) {
  const data = await api.get(`conta-corrente/pessoa/${lojaId}`);

  if (!data.success) {
    return;
  }
  actions.setContasBancaria(data.content);
}

export async function baixaComissao(comissoes) {
  const body = comissoes;

  setLoading(true);
  const data = await api.post("agrupamento-comissa-recebida/baixa", body);
  setLoading(false);

  if (!data.success) {
    return;
  }

  notifySuccess("Comissões baixadas com sucesso");
  getComissaoAgrupada(
    {
      lojaBanco: "",
      lojaId: "0",
      instituicaoId: "0",
      centroCustoId: "0",
      tipoComissaoId: "0",
      dataEmissao: "",
      dataVencimento: "",
      nomeArquivo: "",
      contaBancaria: "0",
    },
    10,
    1
  );
}

export async function baixarCP(comissoes) {
  const body = comissoes;

  setLoading(true);
  const data = await api.post(
    "agrupamento-comissa-recebida/baixar-planilha-cp",
    body
  );
  setLoading(false);

  if (!data.success) {
    return;
  }

  actions.setDownloadCP(data.content);
}

export async function baixarPlanilha(pesquisa, pageSize, page) {
  var url = `agrupamento-comissa-recebida/baixar-planilha?pagina=${page}&registros=${pageSize}`;
  if (pesquisa.lojaBanco !== "") {
    url = url + `&CodigoLojaBanco=${pesquisa.lojaBanco}`;
  }
  if (pesquisa.instituicaoId !== "0") {
    url = url + `&BancoId=${pesquisa.instituicaoId}`;
  }
  if (pesquisa.centroCustoId !== "0") {
    url = url + `&CentroCustoId=${pesquisa.centroCustoId}`;
  }
  if (pesquisa.tipoComissaoId !== "0") {
    url = url + `&TipoComissaoId=${pesquisa.tipoComissaoId}`;
  }
  if (
    pesquisa.DataVencimentoInicial !== "" &&
    pesquisa.DataVencimentoFinal !== ""
  ) {
    url =
      url +
      `&DataVencimentoInicial=${pesquisa.DataVencimentoInicial}&DataVencimentoFinal=${pesquisa.DataVencimentoFinal}`;
  }
  if (pesquisa.DataEmissaoInicial !== "" && pesquisa.DataEmissaoFinal !== "") {
    url =
      url +
      `&DataEmissaoInicial=${pesquisa.DataEmissaoInicial}&DataEmissaoFinal=${pesquisa.DataEmissaoFinal}`;
  }

  if (pesquisa.nomeArquivo !== "") {
    url = url + `&nomeArquivo=${pesquisa.nomeArquivo}`;
  }
  if (pesquisa.contaBancaria !== "0") {
    url = url + `&ContaCorrenteId=${pesquisa.contaBancaria}`;
  }
  if (pesquisa.lojaId !== "0") {
    url = url + `&lojaId=${pesquisa.lojaId}`;
  }
  if (pesquisa.statusComissaoRecebidaId !== "") {
    if (
      pesquisa.statusComissaoRecebidaId === undefined ||
      pesquisa.statusComissaoRecebidaId === "0"
    ) {
      url = url + `&StatusComissaoRecebidaId=${""}`;
    } else {
      url =
        url + `&StatusComissaoRecebidaId=${pesquisa.statusComissaoRecebidaId}`;
    }
  }
  if (pesquisa.documento !== "") {
    url = url + `&Documento=${pesquisa.documento}`;
  }
  if (pesquisa.titulo !== "") {
    url = url + `&Titulo=${pesquisa.titulo}`;
  }

  if (pesquisa.integrado !== "0") {
    const isIntegrated = !!pesquisa.integrado;
    if (isIntegrated) {
      url = url + "&Integrado=true";
    } else {
      url = url + "&Integrado=false";
    }
  }

  setLoading(true);
  const data = await api.get(url);
  setLoading(false);

  if (!data.success) {
    return;
  }

  actions.setDownload(data.content);
}

export async function getStatusAgrupamentoComissaoRecebida() {
  setLoading(true);
  const data = await api.get("status-agrupamento-comissao-recebida");
  setLoading(false);

  if (!data.success) {
    return { valid: false };
  }

  actions.setStatusComissaoRecebida(data.content);
}

export async function getAllLogs(page, pageSize) {
  let url = `logW3Erp?pagina=${page}&registros=${pageSize}`;

  setLoading(true);
  const data = await api.get(url, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("TOKEN_KEY"),
      "Content-Type": "aplication/json",
    },
  });
  setLoading(false);
  if (!data.success) {
    return;
  }

  let body = [];
  data.content.registros.forEach((log) => {
    let dataSplit = log.dataCriacao.split("/");
    let dia = dataSplit[1];
    let mes = dataSplit[0];
    let ano = dataSplit[2];

    log.dataCriacao = `${dia}/${mes}/${ano}`;
    body.push({
      dataCriacao: log.dataCriacao,
      titulo: log.titulo,
      descricao: log.descricao,
    });
  });

  actionsLog.setLog(body);
  actionsLog.setGetAll({
    quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
    tamanhoPagina: data.content.tamanhoPagina,
  });
}
