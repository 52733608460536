import React, { useEffect, useMemo, useState } from "react";

import {
  Button,
  Card,
  Col,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Form,
} from "reactstrap";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { COLUMNS } from "../constants/columns";
import ReactPaginate from "react-paginate";

import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";

import GlobalFilter from "../../../components/filter/filter";
import Breadcrumb from "../../../components/breadcrumbs";

import { useSelector } from "react-redux";
import * as controller from "../../../controllers/layoutParametrizacaoController";
import * as actions from "../../../store/modules/layoutParametrizacao/actions";
import { notifySuccess } from "../../../components/message/message";
import { Switch } from "../../../components/Inputs/Input";

export function Search() {
  const [itemSelectedID, setItemSelectedID] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { layouts, permitions, activeTab } = useSelector(
    (state) => state.layoutParametrizacao
  );

  const columns = useMemo(() => COLUMNS, []);

  const data = layouts;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state,
    rows,
    setPageSize,
    setGlobalFilter,
    layoutStatus,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageSize } = state;

  const handlePageClick = (e) => {
    const pageSelected = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
  };

  async function handleEdit(id) {
    actions.setLayoutBeingEditedId(id);
    actions.setIsInEditMode(true);

    actions.setActiveTab("Edit");
  }

  async function handleVisualize(id) {
    actions.setLayoutBeingVisualizedId(id);
    actions.setIsInVisualizeMode(true);

    actions.setActiveTab("Visualize");
  }

  function handleDelete(id) {
    setItemSelectedID(id);
    toggleModalDelete();
  }

  function toggleModalDelete() {
    setModalDelete(!modalDelete);
  }

  async function deleteItem() {
    const isSuccessed = await controller.deleteLayout(itemSelectedID);

    if (isSuccessed) {
      const filteredLayouts = [
        ...layouts.filter((layout) => layout.id !== itemSelectedID),
      ];
      actions.setLayouts(filteredLayouts);
    }

    toggleModalDelete();
  }
  async function handleToggleStatus(id, currentStatus) {
    const newStatus = !currentStatus;

    const success = await controller.updateLayoutStatus(id, newStatus);

    if (success) {
      const updatedLayouts = layouts.map((layout) =>
        layout.id === id ? { ...layout, status: newStatus } : layout
      );
      actions.setLayouts(updatedLayouts);
      notifySuccess("Status atualizado");
    }
  }
  function handleInitialPage() {
    gotoPage(0);
    setInitialPage(true);
    setSelectedPage(0);
  }

  useEffect(() => {
    async function getTableData() {
      const layouts = await controller.getLayouts();

      actions.setLayouts(layouts);
    }

    getTableData();
  }, []);

  useEffect(() => {
    actions.setIsInEditMode(false);
    actions.setIsInVisualizeMode(false);
  }, []);

  return (
    <>
      <Modal
        className="text-center"
        isOpen={modalDelete}
        toggle={toggleModalDelete}
        backdrop={false}
      >
        <ModalHeader
          toggle={toggleModalDelete}
          style={{ height: "10px", border: "none" }}
        />
        <ModalBody>
          <p style={{ fontSize: "18px", color: "var(--color-preto)" }}>
            Tem certeza que deseja
            <strong style={{ fontSize: "17px" }}>remover</strong> esse item?
          </p>
        </ModalBody>
        <div className="m-3">
          <Button
            onClick={() => toggleModalDelete()}
            color="danger"
            style={{ width: "100px", marginRight: "15px", border: "none" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => deleteItem()}
            style={{
              width: "100px",
              marginLeft: "15px",
              background: "var(--color-verde)",
              border: "none",
            }}
          >
            Confirmar
          </Button>
        </div>
      </Modal>

      <Col>
        <Breadcrumb activeTab={activeTab} />
        <Card className="mb-2 mt-2">
          <Form>
            {permitions.criar ? (
              <Col md={12}>
                <Row className="pt-2 pb-2">
                  <Col md={8}>
                    <Button
                      onClick={() => actions.setActiveTab("Register")}
                      style={{ width: "280px" }}
                      className="purpleButton"
                    >
                      Cadastrar Layout de Parametrização
                    </Button>
                  </Col>
                </Row>
              </Col>
            ) : null}
          </Form>
        </Card>
      </Col>
      <Col>
        <Card className="pt-3 pb-2 mb-5">
          <Col>
            <div style={{ width: "25%" }} className="pb-3">
              <Label style={{ fontWeight: "700" }}>Pesquisar</Label>
              <GlobalFilter
                filter={globalFilter ?? ""}
                setFilter={setGlobalFilter}
              />
            </div>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th>
                        {column.render("Header")}
                        <span className="float-right">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BsIcons.BsArrowDown
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps({
                                    title: undefined,
                                  })
                                )}
                              />
                            ) : (
                              <BsIcons.BsArrowUp
                                id="OrdenarAsc"
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps({
                                    title: undefined,
                                  })
                                )}
                              />
                            )
                          ) : (
                            <BsIcons.BsArrowDownUp
                              {...column.getHeaderProps(
                                column.getSortByToggleProps({
                                  title: undefined,
                                })
                              )}
                            />
                          )}
                        </span>
                      </th>
                    ))}
                    <th style={{ textAlign: "center" }}>Ativo / Inativo</th>

                    <th style={{ textAlign: "center" }}>Ações</th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="text-center" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}

                      <td style={{ textAlign: "center", width: "10%" }}>
                        <Switch
                          checked={row.original.status}
                          className="switch mt-2"
                          loading={loading}
                          onChange={() =>
                            handleToggleStatus(
                              row.original.id,
                              row.original.status
                            )
                          }
                        />
                      </td>

                      <td style={{ textAlign: "center", width: "10%" }}>
                        <>
                          {permitions.criar && (
                            <BiIcons.BiEdit
                              style={{
                                fontSize: "1.2rem",
                                cursor: "pointer",
                                color: "#C4C7CA",
                                marginRight: "7px",
                              }}
                              onClick={() => handleEdit(row.original.id)}
                            />
                          )}
                          <AiIcons.AiOutlineEye
                            style={{
                              fontSize: "1.2rem",
                              cursor: "pointer",
                              color: "#C4C7CA",
                              marginRight: "7px",
                            }}
                            onClick={() => handleVisualize(row.original.id)}
                          />
                          {permitions.deletar && (
                            <BiIcons.BiTrash
                              style={{
                                fontSize: "1.2rem",
                                cursor: "pointer",
                                color: "#C4C7CA",
                              }}
                              onClick={() => handleDelete(row.original.id)}
                            />
                          )}
                        </>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Row className="pt-4">
              <Col>
                <span style={{ color: "var(--color-cinza-medio)" }}>
                  Mostrando {page.length} de {layouts.length}
                </span>
              </Col>
              <Col md={8}>
                <Row className="float-right">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil(rows.length / pageSize)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    activeClassName={"active"}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    forcePage={initialPage ? 0 : selectedPage}
                  />
                  <Input
                    type="select"
                    className="pageSize"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                      handleInitialPage();
                    }}
                  >
                    {[10, 25, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Input>
                </Row>
              </Col>
            </Row>
          </Col>
        </Card>
      </Col>
    </>
  );
}
