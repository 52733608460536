import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    informacao: {
        nome: "",
        razaoSocial: "",
        nomeFantasia: "",
        isento: false,
        cpf: "",
        cnpj: "",
        rg: "",
        orgaoRgId: "0",
        ufRgId: "0",
        dataNascimento: "",
        dataCriacao: "",
        inscricaoEstadual: "",
        inscricaoMunicipal: "",
        nomeMae: "",
        nomePai: "",
        id: null
    },
    socios: [],
    invalid: {
        nome: false,
        razaoSocial: false,
        cpf: false,
        cnpj: false,
        rg: false,
        inscricaoEstadual: false,
        inscricaoMunicipal: false,
        nomeMae: false
    },
    orgaosRg: [],
    ufsDoc: [],
    isJuridica: false,
    seeRegister: false,
    serpro: false,
    pesquisa: {
        cpf: "",
        nome: ""
    },
    sociosPesquisa: []
}

export default function personasInformacoes(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.ITEMS_UF_DOC:
                    return { ...state, ufsDoc: action.value }
                case TYPES.ITEMS_ORGAO_RG:
                    return { ...state, orgaosRg: action.value }
                case TYPES.SOCIOS:
                    return { ...state, socios: action.value }
                case TYPES.INFORMACAO:
                    return { ...state, informacao: action.value }
                case TYPES.INVALID_INFORMACAO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_INFORMACAO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state,
                        informacao: INITIAL_STATE.informacao,
                        invalid: INITIAL_STATE.invalid,
                        socios: [],
                        isJuridica: INITIAL_STATE.isJuridica,
                        serpro: false,
                    }
                case TYPES.SET_ACTIVE_PERSONA:
                    return { ...state, isJuridica: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                case TYPES.SET_SERPRO:
                    return { ...state, serpro: action.value }
                case TYPES.SET_PESQUISA:
                    return { ...state, pesquisa: action.value }
                case TYPES.SET_SOCIOS_PESQUISA:
                    return { ...state, sociosPesquisa: action.value }
                case TYPES.CLEAN_SEARCH:
                    return { ...state, sociosPesquisa: INITIAL_STATE.sociosPesquisa, pesquisa: INITIAL_STATE.pesquisa }
                case TYPES.ADD_SOCIO:
                    return { ...state, socios: [...state.socios, action.value] }
                case TYPES.REMOVE_SOCIO:
                    let socios = [...state.socios]
                    socios.splice(action.index, 1)
                    return { ...state, socios: socios }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}