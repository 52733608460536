const STORE_ID = "CONTAS_RECEBER";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    SEARCH: "SEARCH",
    COPY: "COPY",
    INVALID: "INVALID",
    ITEMS: "ITEMS",
    CLEAN_INVALID: "CLEAN_INVALID",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    CLEAN_SEARCH: "CLEAN_SEARCH",
    CLEAN_ALL_SEARCH: "CLEAN_ALL_SEARCH",
    SET_CONTAS: 'SET_CONTAS',
    SET_EMPRESAS: 'SET_EMPRESAS',
    SET_FILIAIS: 'SET_FILIAIS',
    SET_ALL_ITENS: 'SET_ALL_ITENS',
    SET_ALL_ITENS_FORNECEDOR: 'SET_ALL_ITENS_FORNECEDOR',
    SET_ALL_ITENS_FAVORECIDO: 'SET_ALL_ITENS_FAVORECIDO',
    SET_ALL_ITENS_FILIAL: 'SET_ALL_ITENS_FILIAL',
    SET_FORNECEDORES: 'SET_FORNECEDORES',
    SET_FAVORECIDOS: 'SET_FAVORECIDOS',
    SET_ITEM: 'SET_ITEM',
    SET_MODAL_FORNECEDOR: 'SET_MODAL_FORNECEDOR',
    SET_MODAL_FILIAL: 'SET_MODAL_FILIAL',
    SET_MODAL_FAVORECIDO: 'SET_MODAL_FAVORECIDO',
    SET_LOADING_FILIAL: 'SET_LOADING_FILIAL',
    SET_LOADING_FAVORECIDO: 'SET_LOADING_FAVORECIDO',
    SET_LOADING_FORNECEDOR: 'SET_LOADING_FORNECEDOR'
}

export { STORE_ID, TYPES }