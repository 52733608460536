import React, { useEffect } from "react";
import { Card, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { useSelector } from "react-redux";
import * as actions from "../../../store/modules/pendenciaInstituicao/actions";
import * as controller from "../../../controllers/pendenciaInstituicaoController";
import { Select } from "../../../components/Inputs/Input";
import { removeAccents } from "../../../util/masks";

const Register = () => {

    const { item, bancos, pendenciasRiber, copy, invalid, seeRegister } = useSelector(state => state.pendenciaInstituicao);

    useEffect(() => {
        controller.getBancos();
        controller.getPendenciasRiber();
    }, []);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                controller.create(item);
            } else {
                controller.update(item, copy);
            };
        }
    }

    function onChangeItem(value, field) {
        actions.cleanInvalid(field);
        actions.setItem(field, value);
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2" style={{ margin: '25px 0 0 25px' }}>
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>Preencha os campos para cadastrar uma nova Pendência Instituição</p>
                    </Col>
                    <Form style={{ margin: '40px 0 0 25px' }}>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="operacaoInstituicao" style={{ color: 'var(--color-preto)' }}>
                                    Descrição da Pendência Instituição
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Digite aqui..."
                                    value={item.descricao}
                                    onChange={e => onChangeItem(removeAccents(e.target.value), "descricao")}
                                    onKeyPress={e => handleKeyPress(e)}
                                    className={invalid.descricao ? "is-invalid" : ""}
                                    disabled={seeRegister}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3} >
                            <FormGroup>
                                <Label style={{ color: 'var(--color-preto)', marginTop: '27px' }}>
                                    Instituição Financeira
                                </Label>
                                <Select
                                    first="SELECIONE"
                                    value={{ value: item.bancoId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={bancos}
                                    onChange={(e) => onChangeItem(e.value, 'bancoId')}
                                    isSearchable={true}
                                    autoComplete="off"
                                    disabled={seeRegister}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label style={{ color: 'var(--color-preto)', marginTop: '27px' }}>
                                    Associação tipo de Pendência Ribercred
                                </Label>
                                <Select
                                    first="SELECIONE"
                                    value={{ value: item.pendenciaRiber }}
                                    select={{ value: "id", label: "descricao" }}
                                    options={pendenciasRiber}
                                    onChange={(e) => onChangeItem(e.value, 'pendenciaRiber')}
                                    isSearchable={true}
                                    autoComplete="off"
                                    disabled={seeRegister}                                   
                                />
                            </FormGroup>
                        </Col>
                    </Form>
                </Card>
            </Col>
        </>
    );
}

export default Register;