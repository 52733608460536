import React, {useMemo, useState} from 'react'
import { useSelector } from "react-redux";
import { BsArrowUp, BsArrowDown, BsArrowDownUp} from 'react-icons/bs'

import { maskMoeda } from '../../../../util/masks'

import * as controller from '../../../../controllers/dashboardController/previsaoController'

import { Collapse } from 'reactstrap';
import { COLUMNS_PROPOSTAS } from '../constants/columns';

import { useSortBy, useTable } from 'react-table';
import { AiOutlineEye } from 'react-icons/ai';


export default function TabelaBanco({data}) {
    return(
        <>
            <div style={{display: 'flex'}}>
                <div style={{background: 'var(--color-verde-claro)', padding: '10px 1px 5px 14px', fontWeight: '700', color: 'white', height: '40px', width: '25%', minWidth: '400px'}}> 
                    Instituição
                </div>
                <div style={{background: 'var(--color-verde-claro)',textAlign: 'center', padding: '10px 1px 5px 14px', fontWeight: '700', color: 'white',  height: '40px', width: '15%', minWidth: '200px'}}>
                    Valor Produção
                </div>
                <div style={{background: 'var(--color-verde-claro)',textAlign: 'center', padding: '10px 1px 5px 14px', fontWeight: '700', color: 'white',  height: '40px', width: '15%', minWidth: '200px'}}>
                    Valor Comissão Recebida
                </div>
                <div style={{background: 'var(--color-verde-claro)',textAlign: 'center', padding: '10px 1px 5px 14px', fontWeight: '700', color: 'white',  height: '40px', width: '15%', minWidth: '200px'}}>
                    % Comissão recebida
                </div>
                <div style={{background: 'var(--color-verde-claro)',textAlign: 'center', padding: '10px 1px 5px 14px', fontWeight: '700', color: 'white',  height: '40px', width: '15%', minWidth: '200px'}}>
                    Valor Comissão Prevista
                </div>
                <div style={{background: 'var(--color-verde-claro)',textAlign: 'center', padding: '10px 1px 5px 14px', fontWeight: '700', color: 'white',  height: '40px', width: '15%', minWidth: '200px'}}>
                    % Comissão Prevista
                </div>
            </div>
            {data.map((e, i)=>{
                return(
                    <FirstLevelTableRow data={e} index={i}/>
                )
            })}
        </>
    )
}

function FirstLevelTableRow({data,index}){
    const { filtros, tabelaBanco} = useSelector(state => state.dashboardPrevisao);
    const [isProdutoActive, setIsProdutoActive] = useState(false)

    async function handleOnClick(){
        if(!isProdutoActive){
            let isSuccessed = await controller.getTabelaBancoProduto(filtros, data.id, tabelaBanco)
           isSuccessed && setIsProdutoActive(!isProdutoActive)
        }else{
            setIsProdutoActive(!isProdutoActive)
        }
    }

    return(
        <>
        <div style={{display: 'flex'}} key={index} className='tr'>
            <div 
            style={{padding: '7px',width: '25%', minWidth: '400px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', cursor: 'pointer', filter: 'brightness(85%)'}}
            className='linhaGreen'>
                <div style={{display: 'flex'}}>
                    <div className='button' onClick={(e) => handleOnClick()} style={{minWidth: '16px'}}>{!isProdutoActive ? '+' : '-'}</div>
                    {data.descricao}
                </div>
            </div>
            <div  className={data.comissaoRecebida.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(85%)'}}>
                <div>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`R$${maskMoeda(data.comissaoRecebida.totalPago.toFixed(2))}`}
                    </p> 
                </div>
            </div>         
            <div  className={data.comissaoRecebida.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(85%)'}}>
                <div>
                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                    {`(${data.comissaoRecebida.qtde})`} {data.comissaoRecebida.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> } {`${data.comissaoRecebida.crescimento}%`}
                    </p>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`R$${maskMoeda(data.comissaoRecebida.valor.toFixed(2))}`}
                    </p> 
                </div>
            </div>
            <div  className={data.comissaoRecebida.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(85%)'}}>
                <div>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`${maskMoeda(data.comissaoRecebida.media.toFixed(2))}%`}
                    </p> 
                </div>
            </div>

            <div  className={data.provisionamento.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(85%)'}}>
                <div>
                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                    {`(${data.provisionamento.qtde})`} {data.provisionamento.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> } {`${data.provisionamento.crescimento}%`}
                    </p>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`R$${maskMoeda(data.provisionamento.valor.toFixed(2))}`}
                    </p> 
                </div>
            </div>
            <div  className={data.provisionamento.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(85%)'}}>
                <div>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`${maskMoeda(data.provisionamento.media.toFixed(2))}%`}
                    </p> 
                </div>
            </div>
        </div>
        <Collapse isOpen={isProdutoActive}>
            {data.produtos.map((produto, index)=>(
                <SecondLevelTableRow data={produto} index={index} bancoId={data.id}/>
            ))}
        </Collapse>
        </>
    )
}

function SecondLevelTableRow({data,index,bancoId}){
    const { filtros, tabelaBanco} = useSelector(state => state.dashboardPrevisao);
    const [isCentroCustoOpen, setisCentroCustoOpen] = useState(false)

    const elemento = {bancoId, produtoId: data.id}

    async function handleOnClick(){
        if(!isCentroCustoOpen){
            let isSuccessed = await controller.getTabelaBancoCentroCusto(filtros, elemento, tabelaBanco)
           isSuccessed && setisCentroCustoOpen(!isCentroCustoOpen)
        }else{
            setisCentroCustoOpen(!isCentroCustoOpen)
        }
    }

    return(
        <>
        <div style={{display: 'flex'}} key={index} className='tr'>
            <div 
            style={{padding: '7px',width: '25%', minWidth: '400px',minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', cursor: 'pointer', filter: 'brightness(90%)'}}
            className='linhaGreen'>
                <div style={{display: 'flex', marginLeft: '30px'}}>
                    <div className='button' onClick={(e) => handleOnClick()} style={{minWidth: '16px'}}>{!isCentroCustoOpen ? '+' : '-'}</div>
                    {data.descricao}
                </div>
            </div>
            <div  className={data.comissaoRecebida.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(90%)'}}>
                <div>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`R$${maskMoeda(data.comissaoRecebida.totalPago.toFixed(2))}`}
                    </p> 
                </div>
            </div>
            <div  className={data.comissaoRecebida.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(90%)'}}>
                <div>
                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                    {`(${data.comissaoRecebida.qtde})`} {data.comissaoRecebida.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> } {`${data.comissaoRecebida.crescimento}%`}
                    </p>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`R$${maskMoeda(data.comissaoRecebida.valor.toFixed(2))}`}
                    </p> 
                </div>
            </div>
            <div  className={data.comissaoRecebida.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(90%)'}}>
                <div>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`${maskMoeda(data.comissaoRecebida.media.toFixed(2))}%`}
                    </p> 
                </div>
            </div>

            <div  className={data.provisionamento.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(90%)'}}>
                <div>
                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                    {`(${data.provisionamento.qtde})`} {data.provisionamento.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> } {`${data.provisionamento.crescimento}%`}
                    </p>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`R$${maskMoeda(data.provisionamento.valor.toFixed(2))}`}
                    </p> 
                </div>
            </div>
            <div  className={data.provisionamento.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(90%)'}}>
                <div>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`${maskMoeda(data.provisionamento.media.toFixed(2))}%`}
                    </p> 
                </div>
            </div>
        </div>
        <Collapse isOpen={isCentroCustoOpen}>
            {data.centrosCusto.map((produto, index)=>(
                <ThirdLevelTableRow data={produto} index={index} e={elemento}/>
            ))}
        </Collapse>
        </>
    )
}

function ThirdLevelTableRow({data,index,e}){
    const { filtros, tabelaBanco} = useSelector(state => state.dashboardPrevisao);
    const [isTipoComissaoActive, setIsTipoComissaoActive] = useState(false)

    const elemento = {...e, centroCustoId: data.id}

    async function handleOnClick(){
        if(!isTipoComissaoActive){
            let isSuccessed = await controller.getTabelaBancoTipoComissao(filtros,elemento, tabelaBanco)
           isSuccessed && setIsTipoComissaoActive(!isTipoComissaoActive)
        }else{
            setIsTipoComissaoActive(!isTipoComissaoActive)
        }
    }

    return(
        <>
        <div style={{display: 'flex'}} key={index} className='tr'>
            <div 
            style={{padding: '7px',width: '25%', minWidth: '400px',minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', cursor: 'pointer', filter: 'brightness(95%)'}}
            className='linhaGreen'>
                <div style={{display: 'flex', marginLeft: '60px'}}>
                    <div className='button' onClick={(e) => handleOnClick()} style={{minWidth: '16px'}}>{!isTipoComissaoActive ? '+' : '-'}</div>
                    {data.descricao}
                </div>
            </div>
            <div  className={data.comissaoRecebida.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(95%)'}}>
                <div>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`R$${maskMoeda(data.comissaoRecebida.totalPago.toFixed(2))}`}
                    </p> 
                </div>
            </div>
            <div  className={data.comissaoRecebida.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(95%)'}}>
                <div>
                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                    {`(${data.comissaoRecebida.qtde})`} {data.comissaoRecebida.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> } {`${data.comissaoRecebida.crescimento}%`}
                    </p>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`R$${maskMoeda(data.comissaoRecebida.valor.toFixed(2))}`}
                    </p> 
                </div>
            </div>
            <div  className={data.comissaoRecebida.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(95%)'}}>
                <div>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`${maskMoeda(data.comissaoRecebida.media.toFixed(2))}%`}
                    </p> 
                </div>
            </div>

            <div  className={data.provisionamento.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(95%)'}}>
                <div>
                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                    {`(${data.provisionamento.qtde})`} {data.provisionamento.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> } {`${data.provisionamento.crescimento}%`}
                    </p>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`R$${maskMoeda(data.provisionamento.valor.toFixed(2))}`}
                    </p> 
                </div>
            </div>
            <div  className={data.provisionamento.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A', filter: 'brightness(95%)'}}>
                <div>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`${maskMoeda(data.provisionamento.media.toFixed(2))}%`}
                    </p> 
                </div>
            </div>
        </div>
        <Collapse isOpen={isTipoComissaoActive}>
            {data.tiposComissao.map((produto, index)=>(
                <FourthLevelTableRow data={produto} index={index} e={elemento}/>
            ))}
        </Collapse>
        </>
    )
}

function FourthLevelTableRow({data,index,e}){
    const { filtros, tabelaBanco} = useSelector(state => state.dashboardPrevisao);
    const [isPropostasActive, setIsPropostasActive] = useState(false)

    const elemento = {...e, tipoComissaoId: data.id}

    async function handleOnClick(){
        if(!isPropostasActive){
            let isSuccessed = await controller.getTabelaBancoPropostas(filtros,elemento, tabelaBanco)
           isSuccessed && setIsPropostasActive(!isPropostasActive)
        }else{
            setIsPropostasActive(!isPropostasActive)
        }
    }

    return(
        <>
        <div style={{display: 'flex'}} key={index} className='tr'>
            <div 
            style={{padding: '7px',width: '25%', minWidth: '400px',minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', cursor: 'pointer'}}
            className='linhaGreen'>
                <div style={{display: 'flex', marginLeft: '90px'}}>
                    <div className='button' onClick={(e) => handleOnClick()} style={{minWidth: '16px'}}>{!isPropostasActive ? '+' : '-'}</div>
                    {data.descricao}
                </div>
            </div>
            <div  className={data.comissaoRecebida.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                <div>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`R$${maskMoeda(data.comissaoRecebida.totalPago.toFixed(2))}`}
                    </p> 
                </div>
            </div>
            <div  className={data.comissaoRecebida.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                <div>
                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                    {`(${data.comissaoRecebida.qtde})`} {data.comissaoRecebida.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> } {`${data.comissaoRecebida.crescimento}%`}
                    </p>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`R$${maskMoeda(data.comissaoRecebida.valor.toFixed(2))}`}
                    </p> 
                </div>
            </div>
            <div  className={data.comissaoRecebida.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                <div>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`${maskMoeda(data.comissaoRecebida.media.toFixed(2))}%`}
                    </p> 
                </div>
            </div>

            <div  className={data.provisionamento.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                <div>
                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                    {`(${data.provisionamento.qtde})`} {data.provisionamento.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> } {`${data.provisionamento.crescimento}%`}
                    </p>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`R$${maskMoeda(data.provisionamento.valor.toFixed(2))}`}
                    </p> 
                </div>
            </div>
            <div  className={data.provisionamento.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'}
            style={{padding: '7px', width: '15%', minWidth: '200px', minHeight: '40px', height: 'auto', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                <div>
                    <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                        {`${maskMoeda(data.provisionamento.media.toFixed(2))}%`}
                    </p> 
                </div>
            </div>
        </div>
        <Collapse isOpen={isPropostasActive}>
            <FifthLevelTableRow data={data.propostas} index={index}/>
        </Collapse>
        </>
    )
}

function FifthLevelTableRow({data: propostas}){
    const columns = useMemo(() => COLUMNS_PROPOSTAS, []);
    const data = propostas;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    },
        useSortBy);

    async function changeView(propostaId){
        window.open(`/propostas?id=${propostaId}`, '_blank');
    }
     return(
        <table {...getTableProps()}>
        <thead>
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                    <th style={{width: '9%', minWidth: '8.7rem', maxWidth: '8.7rem'}}>
                    {column.render("Header")}
                    <span className="float-right">
                        {column.isSorted ?
                            (column.isSortedDesc ?
                                <BsArrowDown
                                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                />
                                :
                                <BsArrowUp
                                    id="OrdenarAsc"
                                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                />)
                            :
                            <BsArrowDownUp
                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                            />}
                    </span>
                </th>
                    ))}
                    <th style={{width: '60px'}}>Ações</th>
                </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map(row => {
            prepareRow(row)
                return (
                    <tr className="text-center" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                            return (<td style={{width: '9%'}}{...cell.getCellProps()}>{cell.render("Cell")}</td>)
                        })}
                        <td>
                            {
                                <div style={{display: 'flex'}}> 
                                    <AiOutlineEye style={{fontSize: '25px', paddingBottom: '5px', cursor: 'pointer', marginRight: '5px', color: 'green'}} onClick={()=> changeView(row.original.id)}/> 
                                </div>
                            }
                        </td>
                    </tr>
                );
            })}
        </tbody>
    </table>
     )
}

