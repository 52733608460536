export const COLUMNS = [
    {
        Header: "Código",
        accessor: "codigo"
    },
    {
        Header: "Descrição do centro de custo",
        accessor: "descricao"
    },
    {
        Header: "Loja",
        accessor: "loja"
    },
]

export const COLUMNS_LOJA_PESSOA = [
    {
        Header: "Nome",
        accessor: "nome"
    },
    {
        Header: "Usuário Instituição",
        accessor: "usuarioInstituicao"
    },
    {
        Header: "Hierarquia",
        accessor: "hierarquia"
    },
]


