import React, { useEffect } from "react";

import {
  Modal,
  ModalBody,
  Col,
  Button,
  Input,
  Label,
  Row,
  Navbar,
} from "reactstrap";

import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

import { Switch } from "../../../components/Inputs/Input";
import Select from "../../../components/Inputs/Select";
import { Select as SelectMain } from "../../../components/Inputs/Input";
import { notifyWarn } from "../../../components/message/message";

import { useSelector } from "react-redux";
import * as actions from "../../../store/modules/layoutParametrizacao/actions";
import * as controller from "../../../controllers/layoutParametrizacaoController";

export function ModalDetalhamento({ isOpen, isEditable, handleAddMapeamento }) {
  const {
    detalhamento,
    tiposComissao,
    isInVisualizeMode,
    isInEditMode,
    mapeamento,
    layoutBeingCreated,
    layoutBeingEdited,
    layoutBeingVisualized,
  } = useSelector((state) => state.layoutParametrizacao);

  const layout = isInVisualizeMode
    ? layoutBeingVisualized
    : isInEditMode
    ? layoutBeingEdited
    : layoutBeingCreated;

  const detalhamentosAlreadyAdded =
    layout.layoutPlanilhaParametros?.find(
      (layout) => layout.detalhes.length > 0
    )?.detalhes ?? [];

  const detalhamentos = isEditable
    ? detalhamentosAlreadyAdded
    : mapeamento.detalhes;

  const hasCampoVazio = detalhamentos.some(
    (detalhamento) => detalhamento.aceitaVazio === true
  );

  useEffect(() => {
    actions.clearDetalhamento();
  }, []);

  function handleAddDetalhamento() {
    if (detalhamento.aceitaVazio && hasCampoVazio) {
      return notifyWarn("Apenas um campo vazio é permitido.");
    }
    if (!detalhamento.aceitaVazio && detalhamento.valorCampo === "") {
      return notifyWarn("Informe o nome do campo ou marque como vazio.");
    }
    if (detalhamento.tiposComissaoId === "0") {
      return notifyWarn("Informe o tipo comissão.");
    }

    if (detalhamento.aceitaVazio) {
      detalhamento.valorCampo = "";
    }

    if (!isEditable) {
      actions.setMapeamento("detalhes", [detalhamento, ...mapeamento.detalhes]);
      actions.clearDetalhamento();
    } else {
      const oldParametroValue = layout.layoutPlanilhaParametros.find(
        (layout) => layout.detalhes.length > 0
      );

      const newLayoutPlanilhaParametros = {
        ...layout,
        layoutPlanilhaParametros: [
          {
            ...oldParametroValue,
            detalhes: [detalhamento, ...detalhamentos],
          },
          ...layout.layoutPlanilhaParametros.filter(
            (layout) =>
              layout.layoutPlanilhaCamposEquivalenteId !==
              "bcd763de-9030-4eff-bdec-a9e2915043c8"
          ),
        ],
      };

      if (isInEditMode) {
        actions.setLayoutBeingEdited(newLayoutPlanilhaParametros);
      } else {
        actions.setLayoutBeingCreated(newLayoutPlanilhaParametros);
      }

      actions.clearDetalhamento();
    }
  }

  async function handleDeleteDetalhamento(id, i) {
    const newDetalhamentoValue = [...detalhamentos];
    newDetalhamentoValue.splice(i, 1);

    const oldParametroValue = layout.layoutPlanilhaParametros.find(
      (layout) => layout.detalhes.length > 0
    );
    const newLayoutPlanilhaParametros = {
      ...layout,
      layoutPlanilhaParametros: [
        {
          ...oldParametroValue,
          detalhes: newDetalhamentoValue,
        },
        ...layout.layoutPlanilhaParametros.filter(
          (layout) =>
            layout.layoutPlanilhaCamposEquivalenteId !==
            "bcd763de-9030-4eff-bdec-a9e2915043c8"
        ),
      ],
    };

    if (!id) {
      if (isEditable) {
        if (isInEditMode) {
          actions.setLayoutBeingEdited(newLayoutPlanilhaParametros);
        } else {
          actions.setLayoutBeingCreated(newLayoutPlanilhaParametros);
        }
      } else {
        actions.setMapeamento("detalhes", newDetalhamentoValue);
      }
    } else {
      var isSucceeded = await controller.removeDetalhamento(id);
      if (isSucceeded) {
        if (isInEditMode) {
          actions.setLayoutBeingEdited(newLayoutPlanilhaParametros);
        } else {
          actions.setLayoutBeingCreated(newLayoutPlanilhaParametros);
        }
      }
    }

    if (newDetalhamentoValue.length === 0 && isEditable) {
      actions.setIsModalDetalhamentoOpen(!isOpen);
    }
  }

  async function handleConfirmar(e) {
    if (!isEditable) {
      if (mapeamento.detalhes.length === 0) {
        return notifyWarn("Informe ao menos um detalhamento");
      }
      await handleAddMapeamento(e);
    }

    actions.clearDetalhamento();
    actions.setIsModalDetalhamentoOpen(!isOpen);
  }

  return (
    <>
      <Modal
        className="text-center"
        isOpen={isOpen}
        toggle={() => actions.setIsModalDetalhamentoOpen(!isOpen)}
        backdrop={true}
        size="lg"
      >
        <Navbar
          expand="md"
          style={{
            width: "100%",
            height: "40px",
            background: "var(--color-gradiente-verde)",
            marginBottom: "20px",
          }}
        >
          <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2">
            Cadastro de detalhamento
          </h5>
        </Navbar>
        <ModalBody>
          <div>
            {!isInVisualizeMode ? (
              <Row md={12} style={{ marginLeft: "5rem", paddingRight: "0" }}>
                <Col md={1} style={{ display: "flex" }}>
                  {!hasCampoVazio && (
                    <>
                      <Label
                        style={{
                          fontWeight: "700",
                          color: "var(--color-preto)",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Vazio
                      </Label>
                      <div style={{ marginLeft: "-50px", marginTop: "25px" }}>
                        <Switch
                          className="switch"
                          checked={detalhamento.aceitaVazio}
                          onChange={() =>
                            actions.setDetalhamento(
                              "aceitaVazio",
                              !detalhamento.aceitaVazio
                            )
                          }
                          disabled={isInVisualizeMode}
                          readOnly={isInVisualizeMode}
                        />
                      </div>
                    </>
                  )}
                </Col>
                <Col md={4}>
                  <Label
                    for="convenio"
                    style={{
                      fontWeight: "700",
                      color: "var(--color-preto)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Campo planilha
                  </Label>
                  <Input
                    type="text"
                    placeholder={
                      detalhamento.aceitaVazio ? "" : "Digite aqui..."
                    }
                    value={
                      detalhamento.aceitaVazio ? "" : detalhamento.valorCampo
                    }
                    onChange={(e) =>
                      actions.setDetalhamento("valorCampo", e.target.value)
                    }
                    disabled={
                      isInVisualizeMode ? true : detalhamento.aceitaVazio
                    }
                    style={{ height: "37px" }}
                  />
                </Col>
                <Col md={4}>
                  <Label
                    for="convenio"
                    style={{ fontWeight: "700", color: "var(--color-preto)" }}
                  >
                    Tipo Comissão
                  </Label>
                  <SelectMain
                    first="Selecione"
                    value={{ value: detalhamento.tiposComissaoId }}
                    select={{ value: "id", label: "descricao" }}
                    options={tiposComissao}
                    onChange={(e) =>
                      actions.setDetalhamento("tiposComissaoId", e.value)
                    }
                    disabled={isInVisualizeMode}
                  />
                </Col>
                <Col md={1}>
                  <button
                    style={{
                      border: "none",
                      background: "transparent",
                      margin: "25px 0 0 -0",
                      color: "var(--color-preto)",
                      cursor: "pointer",
                    }}
                    onClick={handleAddDetalhamento}
                  >
                    <AiOutlinePlusCircle
                      style={{ color: "var(--color-verde)", fontSize: "30px" }}
                    />
                  </button>
                </Col>
              </Row>
            ) : null}
            {detalhamentos.map((det, i) => (
              <Row md={12} style={{ marginLeft: "5rem" }} key={`${det.valorCampo}-${i}`}>
                <Col md={1} style={{ display: "flex" }}>
                  <Label
                    style={{
                      fontWeight: "700",
                      color: "var(--color-preto)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Vazio
                  </Label>
                  <div style={{ marginLeft: "-50px", marginTop: "25px" }}>
                    <Switch
                      className="switch"
                      checked={det.aceitaVazio}
                      disabled
                      readOnly
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <Label
                    for="convenio"
                    style={{
                      fontWeight: "700",
                      color: "var(--color-preto)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Campo planilha
                  </Label>
                  <Input
                    type="text"
                    value={det.valorCampo}
                    disabled
                    readOnly
                    style={{ height: "37px" }}
                  />
                </Col>
                <Col md={4}>
                  <Label
                    for="convenio"
                    style={{ fontWeight: "700", color: "var(--color-preto)" }}
                  >
                    Tipo Comissão
                  </Label>
                  <Select
                    label="descricao"
                    value={det.tiposComissaoId}
                    options={tiposComissao}
                    disabled
                    readOnly
                  />
                </Col>
                {!isInVisualizeMode ? (
                  <Col md={1}>
                    <button
                      style={{
                        border: "none",
                        background: "transparent",
                        margin: "25px 0 0 -0",
                        color: "var(--color-preto)",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteDetalhamento(det.id, i)}
                    >
                      <AiOutlineMinusCircle
                        style={{
                          color: "var(--color-laranja)",
                          fontSize: "30px",
                        }}
                      />
                    </button>
                  </Col>
                ) : null}
              </Row>
            ))}
          </div>
        </ModalBody>
        <div className="m-3">
          <Button
            onClick={() => {
              actions.clearDetalhamento();
              actions.setIsModalDetalhamentoOpen(!isOpen);
            }}
            className="mr-4"
            style={{
              width: "150px",
              background: "var(--color-branco)",
              borderColor: "var(--color-laranja)",
              color: "var(--color-laranja)",
              borderRadius: "7px",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={(e) => handleConfirmar(e)}
            color="danger"
            className="purpleButton"
            style={{ width: "150px" }}
          >
            Confirmar
          </Button>
        </div>
      </Modal>
    </>
  );
}
