import React, { useEffect, useState } from 'react';
import { IoIosArrowBack } from "react-icons/io";
import { Card, Col, Form, Row, Button, TabContent, TabPane } from "reactstrap";
import Search from "./tabs/search";
import Register from "./tabs/register";
import { useSelector } from "react-redux";
import * as hierarquiasController from "../../controllers/hierarquiasController";
import * as hierarquiasActions from "../../store/modules/hierarquia/actions"
import Breadcrumb from '../../components/breadcrumbs';
import ModalNiveis from './components/ModalNiveis';

const Hierarquia = () => {

    const { item, activeTab, copy, seeRegister, permitions } = useSelector(state => state.hierarquia);

    useEffect(() => {
        hierarquiasActions.setActiveTab("Search")
    }, [])

    const [modalNiveis, setModalNiveis] = useState(false)

    const toggleNiveis = () => setModalNiveis(!modalNiveis)

    async function saveItem() {
        var isSuccessed = false
        if (item.id === "") {
            isSuccessed = await hierarquiasController.create(item);
        } else {
            isSuccessed = await hierarquiasController.update(item, copy);
        }
        if(isSuccessed){
            await hierarquiasController.getNiveisHierarquicos(item.canalId)
            toggleNiveis()
        }
    }

    function changeTab(tab) {
        hierarquiasActions.setActiveTab(tab);
        hierarquiasActions.cleanRegister();
    }

    function seeRegisterF() {
        if (item.id !== "") {
            hierarquiasActions.cleanRegister();
        }
        hierarquiasActions.setSeeRegister(false);
    }

    return (
        <>
                <Col>
                    <Breadcrumb activeTab={activeTab} />
                    <Card>
                        <Form>
                            {
                                activeTab === "Search"
                                    ?
                                    permitions.criar && (
                                        <Col md={12}>
                                            <Row className="pt-2 pb-2">
                                                <Col md={8}>
                                                    <Button
                                                        onClick={() => changeTab("Register")}
                                                        style={{ width: "200px" }}
                                                        className="purpleButton"
                                                    >
                                                        Cadastrar Hierarquia
                                                </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                    :
                                    <Col className="pt-2 pb-2">
                                        <Button
                                            onClick={() => { changeTab("Search"); seeRegisterF() }}
                                            style={{ width: "127px", color: "white" }}
                                            className="purpleButton"
                                        >
                                            <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar
                                        </Button>
                                        {!seeRegister &&
                                            <Button
                                                onClick={() => saveItem()}
                                                style={{ width: "242px", float: 'right' }}
                                                className="purpleButton"
                                            >
                                                Salvar Cadastro
                                            </Button>}
                                    </Col>
                            }
                        </Form>
                    </Card>
                </Col>

                <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <br />
                        <Search />
                    </TabPane>
                    <TabPane tabId="Register">
                        <br />
                        <Register />
                    </TabPane>
                </TabContent>
                {
                <ModalNiveis
                    hasCancelButton={false}
                    isOpen={modalNiveis}
                    toggle={toggleNiveis} />
            }
        </>
    );
}

export default Hierarquia;