export const COLUMNS = [
  {
    Header: "Instituição",
    accessor: "banco",
  },
  {
    Header: "Loja arrecadadora",
    accessor: "loja",
  },
  {
    Header: "Tipo comissão",
    accessor: "tipoComissao",
  },
  {
    Header: "Conta",
    accessor: "conta",
  },
];
