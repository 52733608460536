import React, { useEffect } from "react";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Select } from "../../../components/Inputs/Input";
import { useSelector } from "react-redux";
import {removeAccents, removeAccentsAndComma} from "../../../util/masks";
import * as produtoInstActions from "../../../store/modules/produtosInstituicoes/actions";
import * as produtoInstController from "../../../controllers/produtosInstController";

const Register = () => {

    const { item, invalid, copy, seeRegister, operacoes, produtosRc, bancos } = useSelector(state => state.produtoInst);

    useEffect(() => {
        produtoInstController.getOperacoes();
    }, []);

    useEffect(() => {
        produtoInstController.getProdutosRc();
    }, []);

    useEffect(() => {
        produtoInstController.getBancos();
    }, []);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                produtoInstController.create(item);
            } else {
                produtoInstController.update(item, copy);
            };
        }
    }

    function onChangeItem(value, field) {
        produtoInstActions.cleanInvalid(field);
        produtoInstActions.item({ ...item, [field]: value });
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5" style={{ display: "flex" }}>
                    <Col className="pt-2" style={{ margin: '25px 0 0 25px' }}>
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>
                            Preencha os campos para cadastrar um novo Produto Instituição
                        </p>
                    </Col>
                    <Form>
                        <Col md={5} style={{ margin: '25px 0 0 25px' }}>
                            <FormGroup>
                                <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }} for="productDescription">Descrição do Produto Instituição</Label>
                                <Input
                                    type="text"
                                    name="product"
                                    id="productDescription"
                                    placeholder="Digite aqui..."
                                    value={item.descricao}
                                    onChange={e => onChangeItem(removeAccents(e.target.value), "descricao")}
                                    className={invalid.descricao ? "is-invalid" : ""}
                                    disabled={seeRegister}
                                    onKeyPress={e => handleKeyPress(e)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={5} style={{ margin: '25px 0 0 25px' }}>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Instituição Financeira</Label>
                                        <Select
                                            first="SELECIONE"
                                            value={{ value: item.bancoId }}
                                            select={{ value: "id", label: "nome" }}
                                            onChange={(e) => onChangeItem(e.value, "bancoId")}
                                            options={bancos}
                                            isSearchable={true}
                                            autoComplete="off"
                                            disabled={seeRegister}
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor: invalid.bancoId ? "red" : "lightgrey",
                                                    minHeight: "35px",
                                                    height: "35px"
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Tipo de Operação Ribercred</Label>
                                        <Select
                                            first="SELECIONE"
                                            value={{ value: item.tipoOperacaoId }}
                                            select={{ value: "id", label: "descricao" }}
                                            onChange={(e) => onChangeItem(e.value, "tipoOperacaoId")}
                                            options={operacoes}
                                            isSearchable={true}
                                            autoComplete="off"
                                            disabled={seeRegister}
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor: invalid.tipoOperacaoId ? "red" : "lightgrey",
                                                    minHeight: "35px",
                                                    height: "35px"
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3} style={{ margin: '25px 0 0 25px' }}>
                            <FormGroup>
                                <Label style={{ color: 'var(--color-preto)', fontWeight: '700' }}>Associação Produto Ribercred</Label>
                                <Select
                                    first="SELECIONE"
                                    value={{ value: item.produtoRcId }}
                                    select={{ value: "id", label: "nome" }}
                                    onChange={(e) => onChangeItem(e.value, "produtoRcId")}
                                    options={produtosRc}
                                    isSearchable={true}
                                    autoComplete="off"
                                    disabled={seeRegister}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            borderColor: invalid.produtoRcId ? "red" : "lightgrey",
                                            minHeight: "35px",
                                            height: "35px"
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Form>
                </Card>
            </Col>
        </>
    );
}

export default Register;
