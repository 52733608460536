import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Col, Input, Label, Modal, ModalHeader, Row, Form, FormGroup, ModalBody } from 'reactstrap'
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table'
import { useSelector } from 'react-redux'
import { Select } from '../../../components/Inputs/Input'
import { COLUMNS } from '../constants/columns'
import ReactPaginate from 'react-paginate'
import * as BiIcons from 'react-icons/bi'
import * as BsIcons from 'react-icons/bs'
import * as AiIcons from 'react-icons/ai'
import * as  divisaoComissaoController from '../../../controllers/divisaoComissaoController'
import * as  divisaoComissaoActions from '../../../store/modules/divisaoComissao/actions'
import ModalLoja from '../components/modalLoja'
import ModalLojaMatriz from '../components/modalLojaMatriz'


const Search = () => {
    const { items, search, getAllItems, bancos, permitions } = useSelector(state => state.divisaoComissao)

    useEffect(() => {
        if(getAllItems.tamanhoPagina){
            setPageSize(getAllItems.tamanhoPagina);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllItems])

    const columns = useMemo(() => COLUMNS, [])
    const data = items
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination)

    const { pageSize } = state
    const [itemSelected, setItem] = useState(null)
    const [modalDelete, setModalDelete] = useState(false)
    const [initialPage, setInitialPage] = useState(false)
    const [selectedPage, setSelectedPage] = useState(null)
    const [modalLoja, setModalLoja] = useState(false)
    const [modalLojaMatriz, setModalLojaMatriz] = useState(false)

    function handlePageClick(e) {
        const pageSelected = e.selected
        setInitialPage(false)
        setSelectedPage(pageSelected)
        gotoPage(pageSelected)
        divisaoComissaoController.get(search, pageSize, pageSelected + 1)
    }

    async function handleUpdate(id) {
        await divisaoComissaoController.getById(id)
        divisaoComissaoActions.setActiveTab('Register')
    }
    function seeRegister(id) {
        divisaoComissaoActions.setSeeRegister(true)
        handleUpdate(id)
    }

    function handleDelete(id) {
        setItem(id)
        toggleModalDelete()
    }

    function toggleModalDelete() {
        setModalDelete(!modalDelete)
    }

    function deleteItem() {
        divisaoComissaoActions.cleanSearch()
        divisaoComissaoController.remove(itemSelected, selectedPage, pageSize, search)
        toggleModalDelete()
    }

    function onChangeSearch(value, field) {
        divisaoComissaoActions.setSearch({ ...search, [field]: value })
    }

    async function getAll() {
        await divisaoComissaoController.get(search, pageSize, 1);
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }

    function handleInitialPage(pageSize) {
        const pageSelected = 0;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        divisaoComissaoController.get(search, pageSize, pageSelected + 1)
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault()
            getAll()
        }
    }

    const toggleLoja = () => setModalLoja(!modalLoja)
    const toggleLojaMatriz = () => setModalLojaMatriz(!modalLojaMatriz)

    return (
        <>
            <Modal
                className='text-center'
                isOpen={modalDelete}
                toggle={toggleModalDelete}
                backdrop={false}
            >
                <ModalHeader toggle={toggleModalDelete} style={{ height: '10px', border: 'none' }} />
                <ModalBody>
                    <p style={{ fontSize: '18px', color: 'var(--color-preto)' }}>Tem certeza que deseja <strong style={{ fontSize: '17px' }}>remover</strong> esse item?</p>
                </ModalBody>
                <div className='m-3'>
                    <Button onClick={() => toggleModalDelete()} color='danger' style={{ width: '100px', marginRight: '15px', border: 'none' }}> Cancelar </Button>
                    <Button onClick={() => deleteItem()} style={{ width: '100px', marginLeft: '15px', background: 'var(--color-verde)', border: 'none' }}> Confirmar </Button>
                </div>
            </Modal>

            <Col>
                <Card className='pt-3 pb-2 mb-5'>
                    <Col>
                        <div className='pb-3'>
                            <Label style={{ fontWeight: '700' }}>Pesquisar</Label>
                            <Form>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for='cpfCnpj'>Instituição Financeira</Label>
                                            <Select
                                                first='SELECIONE'
                                                value={{ value: search.banco }}
                                                onChange={e => onChangeSearch(e.value, 'banco')}
                                                select={{ value: 'id', label: 'nome' }}
                                                options={bancos}
                                                isSearchable
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <FormGroup style={{ width: 'calc(100% - 20px)' }}>
                                            <Label>Loja</Label>
                                            <Input
                                                placeholder='Busque uma loja'
                                                value={search.loja}
                                                onChange={e => onChangeSearch(e.target.value, 'loja')}
                                                disabled
                                            />
                                        </FormGroup>
                                        <AiIcons.AiOutlineSearch
                                            style={{ color: 'var(--color-verde)', marginTop: '30px', marginLeft: '5px', fontSize: '20px', cursor: 'pointer' }}
                                            onClick={() => { toggleLoja() }}
                                        />
                                    </Col>
                                    <Col md={4} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <FormGroup style={{ width: 'calc(100% - 20px)' }}>
                                            <Label>Loja Master</Label>
                                            <Input
                                                placeholder='Busque uma loja master'
                                                value={search.lojaMatriz}
                                                onChange={e => onChangeSearch(e.target.value, 'lojaMatriz')}
                                                onKeyPress={e => handleKeyPress(e)}
                                                disabled
                                            />
                                        </FormGroup>
                                        <AiIcons.AiOutlineSearch
                                            style={{ color: 'var(--color-verde)', marginTop: '30px', marginLeft: '5px', fontSize: '20px', cursor: 'pointer' }}
                                            onClick={() => { toggleLojaMatriz() }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Data de início</Label>
                                            <Input
                                                type='date'
                                                max="9999-12-31"
                                                onChange={(e) => { onChangeSearch(e.target.value, 'dataInicio') }}
                                                value={search.dataInicio}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Data de fim</Label>
                                            <Input
                                                type='date'
                                                max="9999-12-31"
                                                onChange={(e) => { onChangeSearch(e.target.value, 'dataFim') }}
                                                value={search.dataFim}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Button className='mr-4'
                                            style={{
                                                width: '150px',
                                                background: 'var(--color-branco)',
                                                borderColor: 'var(--color-laranja)',
                                                color: 'var(--color-laranja)',
                                                borderRadius: '7px'
                                            }}
                                            onClick={() => divisaoComissaoActions.cleanAllSearch()}
                                        >
                                            Limpar
                                        </Button>
                                        <Button
                                            className='purpleButton'
                                            style={{ width: '150px' }}
                                            onClick={() => getAll()}
                                        >
                                            Buscar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div style={{ overflowX: 'auto' }}>
                            <table {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th>
                                                    {column.render('Header')}
                                                    <span className='float-right'>
                                                        {column.isSorted ?
                                                            (column.isSortedDesc ?
                                                                <BsIcons.BsArrowDown
                                                                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                                />
                                                                :
                                                                <BsIcons.BsArrowUp
                                                                    id='OrdenarAsc'
                                                                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                                />)
                                                            :
                                                            <BsIcons.BsArrowDownUp
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />}
                                                    </span>
                                                </th>
                                            ))}
                                            <th style={{ textAlign: 'center' }}>
                                                Ações
                                            </th>
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr className='text-center' {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
                                                })}
                                                <td style={{ textAlign: 'center', width: '10%' }}>
                                                    <>
                                                        {
                                                            permitions.criar && (
                                                                <BiIcons.BiEdit
                                                                    style={{ fontSize: '1.2rem', cursor: 'pointer', color: '#C4C7CA', marginRight: '7px' }}
                                                                    onClick={() => handleUpdate(row.original.id)}
                                                                />
                                                            )
                                                        }
                                                        <AiIcons.AiOutlineEye
                                                            style={{ fontSize: '1.2rem', cursor: 'pointer', color: '#C4C7CA', marginRight: '7px' }}
                                                            onClick={() => seeRegister(row.original.id)}
                                                        />
                                                        {
                                                            permitions.deletar && (
                                                                <BiIcons.BiTrash
                                                                    style={{ fontSize: '1.2rem', cursor: 'pointer', color: '#C4C7CA' }}
                                                                    onClick={() => handleDelete(row.original.id)}
                                                                />
                                                            )
                                                        }
                                                    </>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <Row className='pt-4'>
                            <Col>
                                <span style={{ color: 'var(--color-cinza-medio)' }}>Mostrando {getAllItems.quantidade !== undefined ? getAllItems.quantidade : '0'} de {getAllItems.quantidadeTotal !== undefined ? getAllItems.quantidadeTotal : '0'}</span>
                            </Col>
                            <Col md={8}>
                                <Row className='float-right'>
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={getAllItems.totalPaginas}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={e => handlePageClick(e)}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type='select'
                                        className='pageSize'
                                        value={pageSize}
                                        onChange={e => { handleInitialPage(e.target.value); setPageSize(Number(e.target.value)) }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Card >
            </Col >
            {
                <ModalLoja
                    isOpen={modalLoja}
                    toggle={toggleLoja}
                />
            }
            {
                <ModalLojaMatriz
                    isOpen={modalLojaMatriz}
                    toggle={toggleLojaMatriz}
                />
            }
        </>
    )
}


export default Search
