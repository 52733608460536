import React, { useEffect } from "react";
import Search from "./tabs/search";
import Register from "./tabs/register";
import { IoIosArrowBack } from "react-icons/io";
import { Card, Col, Form, Row, Button, TabContent, TabPane } from "reactstrap";
import { useSelector } from "react-redux";
import * as personasController from "../../controllers/personasController/personasController";
import * as parametrosController from "../../controllers/personasController/parametrosController"
import * as personasActions from "../../store/modules/personas/personas_store/actions";
import * as informacoesActions from "../../store/modules/personas/informacoes_store/actions";
import * as papeisActions from "../../store/modules/personas/papeis_store/actions";
import * as enderecoActions from "../../store/modules/personas/endereco_store/actions";
import * as parametrosActions from "../../store/modules/personas/parametros_store/actions";
import * as contatoActions from "../../store/modules/personas/contato_store/actions";
import * as documentacaoActions from "../../store/modules/personas/documentacao_store/actions";
import * as contaActions from "../../store/modules/personas/conta_corrente_store/actions";
import * as enderecoController from "../../controllers/personasController/enderecoController";
import Breadcrumb from '../../components/breadcrumbs'
import '../../style.css'
import { notifyWarn } from '../../components/message/message'
import { useState } from "react";
import personasParametros from "../../store/modules/personas/parametros_store";

const Personas = () => {
  const { activeTab, copy, tab, search, permitions } = useSelector(
    (state) => state.personas
  )
  const { informacao, socios, isJuridica, seeRegister } = useSelector(
    (state) => state.personasInformacoes
  )
  const {
    cliente_cpf,
    cliente_cnpj,
    funcionario,
    clienteCPF,
    tipoLoja,
    clienteCNPJ,
    matricula,
    fornecedor,
    pessoa,
    personasSelected,
    lojaBanco
  } = useSelector((state) => state.personasPapeis)
  const { endereco } = useSelector(state => state.personasEndereco)
  const { documento } = useSelector(state => state.personasDocumentacao)
  const { item } = useSelector(state => state.personasParametros)
  const { contasCorrenteSelected, conta, conta: { matriculasContaCorrente }, } = useSelector(state => state.contaCorrente)
  const [selectedPage, setSelectedPage] = useState(null);

  const isInContatoTab = tab === '4'

  const informacoes = {
    informacao,
    socios,
    isJuridica,
  }

  useEffect(() => {
    if (window.location.search !== '') {
      personasActions.setActiveTab('Register')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function saveItem() {
    switch (tab) {
      case '1':
        personasController.salveInformation(informacoes)
        break
      case '2':
        if (
          cliente_cnpj.isClienteCnpjActive
        ) {
          personasController.salveCliente(
            informacao.id,
            isJuridica,
            clienteCNPJ,
            matricula
          )
        }
        if (cliente_cpf.isClienteCpfActive) {
          personasController.salveCliente(informacao.id, isJuridica, clienteCPF, matricula)
        }
        if (cliente_cnpj.isMatrizActive) {
          let isSuccess = false
          if (tipoLoja.id === '') {
            let valid = await personasController.salveLoja(informacao.id, tipoLoja)
            if (valid) {
              (lojaBanco.bancoId !== '0' && lojaBanco.tipoParceriaId !== 0 && lojaBanco.codigoLojaBanco) && personasController.salveLojaBanco(informacao.id, tipoLoja.id, lojaBanco)
                (pessoa.hierarquiaId !== '0' && pessoa.pessoaId !== '0') && (isSuccess = await personasController.salvePersonas(tipoLoja.id, pessoa))
              if (isSuccess) {
                personasSelected.push(pessoa)
                papeisActions.setPersonas(personasSelected)
                papeisActions.cleanPersona()
              }
            }
          } else {
            let id = await personasController.salveLoja(informacao.id, tipoLoja);
            (lojaBanco.bancoId !== '0' && lojaBanco.tipoParceriaId !== 0 && lojaBanco.codigoLojaBanco) && personasController.salveLojaBanco(informacao.id, id, lojaBanco)
              (pessoa.hierarquiaId !== '0' && pessoa.pessoaId !== '0') && (isSuccess = await personasController.salvePersonas(id, pessoa))
            if (isSuccess) {
              personasSelected.push(pessoa)
              papeisActions.setPersonas(personasSelected)
              papeisActions.cleanPersona()
            }
          }
        }
        if (
          cliente_cpf.isFuncionarioActive
        ) {
          personasController.salveFuncionario(informacao.id, funcionario)
        }
        if (cliente_cnpj.isFornecedor) {
          personasController.salveFornecedor(informacao.id, fornecedor)
        }
        break
      case '3':
        await enderecoController.salveEndereco(informacao.id, endereco)
        break
      case '4':
        break
      case '5':
        (documento.nome && documento.tipoDoc && documento.tipoDocumentoId !== '0' && documento.base64) && personasController.salveDocumentacao(informacao.id, documento)
        break
      case '6':
        let isSuccess = await personasController.salveContaCorrente(informacao.id, conta, matriculasContaCorrente)
        if (isSuccess.valid) {
          if (conta.instituicaoFinanceiraId === '0') {
            return notifyWarn('Informe um Banco')
          }
          if (conta.nomeAgencia === '') {
            return notifyWarn('Informe a Agencia')
          }
          if (conta.numero === '') {
            return notifyWarn('Informe a Conta Corrente')
          }
          var obj = {
            id: isSuccess.id,
            instituicaoFinanceiraId: conta.instituicaoFinanceiraId,
            nomeAgencia: conta.nomeAgencia,
            numero: conta.numero,
            matriculasContaCorrente: conta.matriculasContaCorrente,
            chavePix: conta.chavePix,
            tipoPixId: conta.tipoPixId,
            cdContaCorrenteW3ERP: conta.cdContaCorrenteW3ERP
          }
          contasCorrenteSelected.push(obj)
          contaActions.contaCorrente(contasCorrenteSelected)
          contaActions.clean()
        }
        break
      case '7':
        const tipoIntegracaoId = 'DCC7D5EA-8B74-43DF-9A32-061373B1308E'
        item.id != '' ? await parametrosController.updateParametroIntegracao(item, tipoIntegracaoId, tipoLoja)
          : await parametrosController.salveParametroIntegracao(item, tipoIntegracaoId, tipoLoja)
        await parametrosController.GetByLojaId(tipoLoja.id)
        break
      default:
        break
    }
  }

  function changeTab(tab) {
    personasActions.cleanSearch()
    informacoesActions.cleanRegister()
    enderecoActions.cleanRegister()
    contatoActions.cleanRegister()
    papeisActions.cleanRegister()
    documentacaoActions.cleanRegister()
    contaActions.cleanRegister()
    personasActions.setActiveTab(tab)
    personasActions.setTab('1')
    if (search.razaoSocialNome) {
      personasController.getComNome(0, 10, search)
    } else {
      personasController.get(0, 10, search)
    }
    parametrosActions.cleanAll();
  }

  function seeRegisterF() {
    personasActions.cleanSearch()
    if (copy.id !== '') {
      informacoesActions.cleanRegister()
      personasActions.cleanSearch()
      informacoesActions.cleanRegister()
      contaActions.cleanRegister();
      contaActions.clean();
      contaActions.setIsExcluindo(false);
      contaActions.setIsEditando(false);
      enderecoActions.cleanRegister()
      enderecoActions.setIsEditando(false);
      enderecoActions.setIsDeleting(false);
      enderecoActions.cleanEndereco();
      contatoActions.cleanRegister()
      papeisActions.cleanRegister()
      documentacaoActions.cleanRegister()
      parametrosActions.cleanRegister()
    }
    informacoesActions.setSeeRegister(false)
    enderecoActions.setSeeRegister(false)
    enderecoActions.setIsEditando(false);
    enderecoActions.setIsDeleting(false);
    enderecoActions.cleanEndereco();
    contatoActions.setSeeRegister(false)
    papeisActions.setSeeRegister(false)
    documentacaoActions.setSeeRegister(false)
    contaActions.setSeeRegister(false)
    contaActions.clean();
    contaActions.setIsExcluindo(false);
    contaActions.setIsEditando(false);
    parametrosActions.setSeeRegister(false);
  }

  return (
    <>
      <Col>
        <Breadcrumb activeTab={activeTab} />
        <Card>
          <Form>
            {activeTab === 'Search' ? (
              permitions.criar && (
                <Col md={12}>
                  <Row className='pt-2 pb-2'>
                    <Col md={8}>
                      <Button
                        onClick={() => changeTab('Register')}
                        style={{ width: '200px' }}
                        className='purpleButton'
                      >
                        Cadastrar Personas
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )
            ) : (
              <Col className='pt-2 pb-2'>
                <Button
                  onClick={() => {
                    setSelectedPage(0);
                    changeTab('Search')
                    seeRegisterF()

                  }}
                  style={{ width: '150px', color: 'white' }}
                  className='purpleButton'
                >
                  <IoIosArrowBack
                    style={{ marginTop: '3px', float: 'left' }}
                  />{' '}
                  Voltar
                </Button>
                {!seeRegister && !isInContatoTab ? (
                  <Button
                    onClick={() => saveItem()}
                    style={{ width: '240px', float: 'right' }}
                    className='purpleButton'
                  >
                    Salvar Cadastro
                  </Button>
                ) : null}
              </Col>
            )}
          </Form>
        </Card>
      </Col>
      <TabContent style={{ width: '100%' }} activeTab={activeTab}>
        <TabPane tabId='Search'>
          <br />
          <Search selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
        </TabPane>
        <TabPane tabId='Register'>
          <br />
          <Register />
        </TabPane>
      </TabContent>
    </>
  )
}

export default Personas
