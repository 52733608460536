import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    item: {
        descricao: "",
        bancoId: "0",
        convenioId: "0",
        id: ""
    },
    copy: {},
    invalid: {
        descricao: false,
        bancoId: false,
        convenioId: false,
    },
    items: [],
    instituicoes: [],
    convenios: [],
    activeTab: "Search",
    seeRegister: false,
    permitions: {
        criar: false,
        deletar: false,
    }
}

export default function tipoConvenio(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.SET_INSTITUICOES:
                    return { ...state, instituicoes: action.value }
                case TYPES.SET_CONVENIOS:
                    return { ...state, convenios: action.value }
                case TYPES.TIPOCONVENIO:
                    return { ...state, item: action.value }
                case TYPES.TIPOCONVENIOS_INSTITUICOES_FINANCEIRAS:
                    return { ...state, items: action.value }
                case TYPES.INVALID_TIPOCONVENIO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_TIPOCONVENIO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.TIPOCONVENIO_COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state, item: {
                            ...state.item,
                            descricao: "",
                            bancoId: "0",
                            convenioId: "0",
                            id: ""
                        },
                        invalid: INITIAL_STATE.invalid
                    }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}