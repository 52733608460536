import React from 'react'
import { Col, Label } from 'reactstrap'
import  Select  from '../../../components/Inputs/Select'

const Loja = ({options, value, index, onChange, disabled}) => {
    return (
        <>
            <Col>
                <Label>
                    Loja
                </Label>
                <Select
                value={ value ? value : "0" }
                label= 'nome'
                onChange={(e)=> onChange(e.value, index)}
                disabled={disabled}
                options={options ? options : []}
                />
            </Col>
        </>
    )
}

export default Loja
