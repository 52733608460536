import React from 'react'

export default function BigNumbers({data}){
    return(
        <div>
            <div style={{display: 'flex'}}>
                <div style={{background: '#58ce74', borderRadius: '20px',width: '30%', height: '100px', marginRight: '5%', paddingTop: '10px'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{margin: '1px' , fontWeight:'700', fontSize: '26px'}}>
                                {data.metaMes.quantidade ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Quantidade
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{marginBottom: '1px' , fontWeight:'700', fontSize: '26px'}}>
                                {data.metaMes.valor ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Valor
                            </p>
                        </div>
                    </div>
                    <p style={{textAlign: 'center', fontWeight: '500', fontSize: '14px', marginBottom: '5px'}}>
                        Meta
                    </p>
                </div>
                <div style={{background: '#58ce74', borderRadius: '20px',width: '30%', height: '100px', marginRight: '5%', paddingTop: '10px'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{margin: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.producaoMes.quantidade ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Quantidade
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{marginBottom: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.producaoMes.valor ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Valor
                            </p>
                        </div>
                    </div>
                    <p style={{textAlign: 'center', fontWeight: '500', fontSize: '14px', marginBottom: '5px'}}>
                        Produção
                    </p>
                </div>
                <div style={{background: '#58ce74', borderRadius: '20px',width: '30%', height: '100px', paddingTop: '10px'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{margin: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.metaMesRestante.quantidade ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Quantidade
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{marginBottom: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.metaMesRestante.valor ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Valor
                            </p>
                        </div>
                    </div>
                    <p style={{textAlign: 'center', fontWeight: '500', fontSize: '14px', marginBottom: '5px'}}>
                        Meta restante
                    </p>
                </div>
            </div>
            <div style={{display: 'flex', marginTop: '10px'}}>
                <div style={{background: '#95d0b8',  borderRadius: '20px',width: '30%', height: '100px', marginRight: '5%', paddingTop: '10px'}}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{margin: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.metasAlcancadas.quantidade ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Quantidade
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{margin: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.metasAlcancadas.valor ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Valor
                            </p>
                        </div>
                    </div>
                    <p style={{textAlign: 'center', fontWeight: '500', fontSize: '14px', marginBottom: '5px'}}>
                       Metas alcançadas
                    </p>
                </div>
                <div style={{background: '#95d0b8',  borderRadius: '20px',width: '30%', height: '100px', marginRight: '5%', paddingTop: '10px'}}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{marginBottom: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.projecaoGanho.quantidade ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Quantidade
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{marginBottom: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.projecaoGanho.valor ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Valor
                            </p>
                        </div>
                    </div>
                    <p style={{textAlign: 'center', fontWeight: '500', fontSize: '14px', marginBottom: '5px'}}>
                        Projeção de ganho
                    </p>
                </div>
                <div style={{background: '#95d0b8',  borderRadius: '20px',width: '30%', height: '100px', paddingTop: '10px'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: '60%'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{marginBottom: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.posicaoGeral.quantidade ?? '-'}º
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Quantidade
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{marginBottom: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.posicaoGeral.valor ?? '-'}º
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Valor
                            </p>
                        </div>
                    </div>
                    <p style={{textAlign: 'center', fontWeight: '500', fontSize: '14px', marginBottom: '5px', marginTop: 'auto'}}>
                        	Ranking 
                    </p>
                </div>
            </div>
            <div style={{display: 'flex', marginTop: '10px'}}>
                <div style={{background: '#dedfc5',  borderRadius: '20px',width: '30%', height: '100px', marginRight: '5%', paddingTop: '10px'}}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{margin: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.metaDu.quantidade ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Quantidade
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{marginBottom: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.metaDu.valor ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Valor
                            </p>
                        </div>
                    </div>
                    <p style={{textAlign: 'center', fontWeight: '500', fontSize: '14px', marginBottom: '5px'}}>
                        Meta DU
                    </p>
                </div>
                <div style={{background: '#dedfc5',  borderRadius: '20px',width: '30%', height: '100px', marginRight: '5%', paddingTop: '10px'}}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{margin: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.metaDuRecalculada.quantidade ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Quantidade
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{marginBottom: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.metaDuRecalculada.valor ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Valor
                            </p>
                        </div>
                    </div>
                    <p style={{textAlign: 'center', fontWeight: '500', fontSize: '14px', marginBottom: '5px'}}>
                        Meta DU recalculada
                    </p>
                </div>
                <div style={{background: '#dedfc5',  borderRadius: '20px',width: '30%', height: '100px', paddingTop: '10px'}}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{margin: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.valorProjetado.quantidade ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                            Quantidade
                            </p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '60px'}}>
                            <p style={{marginBottom: '1px' , fontWeight:'700', fontSize: '26px'}}>
                            {data.valorProjetado.valor ?? '-'}
                            </p>
                            <p style={{ fontWeight:'700', fontSize: '11px'}}>
                                Valor
                            </p>
                        </div>
                    </div>
                    <p style={{textAlign: 'center', fontWeight: '500', fontSize: '14px', marginBottom: '5px'}}>
                    Produção Projetada
                    </p>
                </div>
            </div>
        </div>
    )
}