import jwtDecode from "jwt-decode";
import { Redirect } from "react-router";
import { useLocation } from "react-router-dom";
import Login from "../views/login/login";
import { cripto } from "cripto"
import { useSelector } from "react-redux";

export const isAuthenticated = () => {

    const token = localStorage.getItem("TOKEN_KEY");

    if (token) {
        if (token === "") {
            <Redirect to="/login" component={Login} />

        } else {

            var decodedToken = jwtDecode(token, { complete: true });

            var isExpired = false;
            var date = new Date();
            var dateNow = parseInt(date.getTime() / 1000);

            if (decodedToken.exp > dateNow) {
                isExpired = true;
                return isExpired;
            } else {
                return isExpired;
            }
        }
    } else {
        < Redirect to="/login" component={Login} />
    }

}

export const UserAccessControll = () => {
    const { routes } = useSelector(state => state.routes)

    let textEncript = localStorage.getItem("userControllkeys")
    let userKeys = textEncript && JSON.parse(cripto.toText(textEncript))

    const location = useLocation();
    let screenControlKeys = []
    routes.forEach((route) => {
        if (route.subNav) {
            route.subNav.forEach((subNav) => {
                screenControlKeys.push({
                    id: subNav.id,
                    path: subNav.path,
                });
            });
        } else {
            screenControlKeys.push({
                id: route.id,
                path: route.path,
            });
        }
    })
    let currentPage = screenControlKeys.find(
        (screen) => screen.path === location.pathname
    );
    if (userKeys && currentPage) {
        if (userKeys.find(e => e.toUpperCase() === currentPage.id)) {
            return true;
        } else {
            return false;
        }
    }
}

