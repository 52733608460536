import React, { useEffect, useState } from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { Card, Col, Row, FormGroup, Label, Input } from 'reactstrap'
import { useSelector } from 'react-redux'
import { Select, SelectUnity } from '../../../components/Inputs/Input'
import * as actions from '../../../store/modules/comissao/actions'
import * as controller from '../../../controllers/comissaoController'
import { notifyWarn } from '../../../components/message/message'
import { maskMoeda, maskNumber } from '../../../util/masks'
import CardItem from '../components/cardItem'

const Register = () => {
    const { tiposOperacaoIntituicao, instituicoes, produtosInstituicao, conveniosInstituicao, registros, seeRegister, invalid, tiposComissaoBanco, cleanEventos } = useSelector(state => state.comissao)

    const [mesesDe, setMesesDe] = useState('')
    const [mesesAte, setMesesAte] = useState('')
    const [tipoComissaoId, setTipoComissaoId] = useState('0')
    const [valor, setValor] = useState('')
    const [unidade, setUnidade] = useState('')

    const [errMesesDe, setErrMesesDe] = useState(false)
    const [errMesesAte, setErrMesesAte] = useState(false)
    const [errComissao, setErrComissao] = useState(false)
    const [errValor, setErrValor] = useState(false)

    useEffect(() => {
        if (registros.produtoBancoId !== '0') {
            controller.getTiposComissaoProdutoBanco(registros.produtoBancoId)
        }
    }, [registros.id, registros.produtoBancoId])

    function getOptions(id) {
        controller.getConveniosCadastro(id)
        controller.getProdutosCadastro(id)
        controller.getTiposOperacaoCadastro(id)
    }

    useEffect(()=>{
        if(registros.bancoId && registros.bancoId !== 0 && registros.bancoId !== '0'){
            controller.getProdutosInstituicao(registros.bancoId)
            controller.getConveniosInstituicao(registros.bancoId)
            controller.getTiposOperacaoInstituicao(registros.bancoId)
        }
    },[registros.bancoId])

    function onChange(value, field) {
        actions.cleanInvalid(field)
        actions.setRegistros({ ...registros, [field]: value })
        if (field === 'bancoId') {
            setTipoComissaoId('0')
            if (value === '0') {
                return actions.cleanTiposComissaoBanco()
            }
        }
    }

    function cleanState() {
        setMesesDe('')
        setMesesAte('')
        setTipoComissaoId('0')
        setValor('')
        setUnidade('')
        setErrMesesDe(false)
        setErrMesesAte(false)
        setErrComissao(false)
        setErrValor(false)
    }

    function handleAddEvento() {
        if (tipoComissaoId === '0') {
            setErrComissao(true)
            return notifyWarn('Selecione o tipo de comissão para cadastrar um novo evento')
        }
        if (mesesDe === '') {
            setErrMesesDe(true)
            return notifyWarn('Preencha o campo "Meses de" para cadastrar um novo evento')
        }
        if (mesesAte === '') {
            setErrMesesAte(true)
            return notifyWarn('Preencha o campo "Meses até" para cadastrar um novo evento')
        }
        if (valor === '') {
            setErrValor(true)
            return notifyWarn('Preencha o campo "Percentual" para cadastrar um novo evento')
        }

        var hasError = false
        registros.tabelaComissaoEventos.forEach((evento)=>{
            if((evento.tiposComissaoBanco ? evento.tiposComissaoBanco.id : evento.tiposComissaoBancoId) === tipoComissaoId){
                if(Number(mesesDe) <= Number(evento.mesesDe) && Number(mesesAte) >= Number(evento.mesesDe)){
                    return hasError = true
                }
                if(Number(mesesDe) > Number(evento.mesesDe) && Number(mesesDe) <= Number(evento.mesesAte)){
                    return hasError = true
                }
                if(Number(mesesAte) < Number(mesesDe)){
                    if( Number(mesesAte) >= Number(evento.mesesDe)){
                            return hasError = true
                        }
                }

            }
            return
        })

        if (hasError){
            return notifyWarn('Ja existe um evento cadastrado com esses parâmetros')
        }

        registros.tabelaComissaoEventos.push({
            mesesDe: mesesDe,
            mesesAte: mesesAte,
            valor: valor,
            tiposComissaoBancoId: tipoComissaoId,
            unidade: unidade
        })
        actions.setRegistros(registros)
        cleanState()
    }
    useEffect(() => {
        if(cleanEventos === true){
            cleanState()
            actions.cleanEventos(false)
        }
    }, [cleanEventos])
    return (
        <>
            <Col>
                <Card className='radius pb-5'>
                    <p style={{ margin: '15px 0 0 25px', fontSize: '18px', color: 'var(--color-preto)' }}>Preencha os campos para cadastrar uma nova Tabela de Comissão</p>
                    <Col className='mt-4'>
                        <Col md={12}>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Descrição</Label>
                                        <Input
                                            type='text'
                                            placeholder='Digite aqui...'
                                            value={registros.descricao}
                                            onChange={e => onChange(e.target.value.toUpperCase(), 'descricao')}
                                            disabled={registros.id !== '' ? true : seeRegister}
                                            className={invalid.descricao ? 'is-invalid' : ''}
                                            maxLength='150'
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Instituição Financeira</Label>
                                    <Select
                                        first='SELECIONE'
                                        value={{ value: registros.bancoId }}
                                        select={{ value: 'id', label: 'nome' }}
                                        options={instituicoes}
                                        onChange={e => {
                                            onChange(e.value, 'bancoId')
                                            getOptions(e.value)
                                        }}
                                        autoComplete='off'
                                        disabled={registros.id !== '' ? true : seeRegister}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                borderColor: invalid.bancoId ? 'red' : 'lightgrey',
                                                minHeight: '35px',
                                                height: '35px'
                                            })
                                        }}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Produto Instituição</Label>
                                    <Select
                                        first='SELECIONE'
                                        value={{ value: registros.produtoBancoId }}
                                        select={{ value: 'id', label: 'descricao' }}
                                        options={produtosInstituicao}
                                        onChange={e => onChange(e.value, 'produtoBancoId')}
                                        autoComplete='off'
                                        disabled={registros.id !== '' ? true : seeRegister}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                borderColor: invalid.produtoBancoId ? 'red' : 'lightgrey',
                                                minHeight: '35px',
                                                height: '35px'
                                            })
                                        }} />
                                </Col>
                                <Col md={3}>
                                    <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Convênio Riber</Label>
                                    <Select
                                        first='SELECIONE'
                                        value={{ value: registros.convenioBancoId }}
                                        select={{ value: 'id', label: 'descricao' }}
                                        options={conveniosInstituicao}
                                        onChange={e => onChange(e.value, 'convenioBancoId')}
                                        autoComplete='off'
                                        disabled={registros.id !== '' ? true : seeRegister}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                borderColor: invalid.convenioBancoId ? 'red' : 'lightgrey',
                                                minHeight: '35px',
                                                height: '35px'
                                            })
                                        }} />
                                </Col>
                                <Col md={3}>
                                    <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Tipo de Operação Instituição</Label>
                                    <Select
                                        first='SELECIONE'
                                        value={{ value: registros.tipoOperacaoBancoId }}
                                        select={{ value: 'id', label: 'descricao' }}
                                        options={tiposOperacaoIntituicao}
                                        onChange={e => onChange(e.value, 'tipoOperacaoBancoId')}
                                        autoComplete='off'
                                        disabled={registros.id !== '' ? true : seeRegister}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                                borderColor: invalid.tipoOperacaoBancoId ? 'red' : 'lightgrey',
                                                minHeight: '35px',
                                                height: '35px'
                                            })
                                        }} />
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col md={3}>
                                    <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}> Vigência Inicial </Label>
                                    <Input
                                        type='date'
                                        value={registros.dtInicioVig}
                                        max="9999-12-31"
                                        disabled={registros.id !== '' ? true : seeRegister}
                                        onChange={e => onChange(e.target.value, 'dtInicioVig')}
                                        className={invalid.dtInicioVig ? 'is-invalid' : ''}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}> Vigência Final </Label>
                                    <Input
                                        type='date'
                                        max="9999-12-31"
                                        value={registros.dtFimVig}
                                        disabled={seeRegister}
                                        onChange={e => onChange(e.target.value, 'dtFimVig')}
                                        className={invalid.dtFimVig ? 'is-invalid' : ''}
                                    />
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Código da Tabela</Label>
                                        <Input
                                            type='text'
                                            placeholder='Digite aqui...'
                                            value={registros.codigoTabela}
                                            onChange={e => onChange(e.target.value, 'codigoTabela')}
                                            disabled={seeRegister}
                                            className={invalid.codigoTabela ? 'is-invalid' : ''}
                                            maxLength='80'
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <p style={{ margin: '20px 0 20px 0', fontWeight: '700', color: 'var(--color-preto)' }}>Eventos:</p>

                            <Card className='p-3 ' style={{ backgroundColor: 'var(--color-quase-branco)' }}>
                                <Row>
                                    <Col md={4}>
                                        <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Tipo de Comissão</Label>
                                        <SelectUnity
                                            first='SELECIONE'
                                            value={{ value: tipoComissaoId }}
                                            select={{ value: 'id', label: 'descricao', unity: 'unidadeComissao' }}
                                            disabled={seeRegister}
                                            options={tiposComissaoBanco}
                                            onChange={e => {
                                                setTipoComissaoId(e.value)
                                                setUnidade(e.unity.descricao)
                                                setErrComissao(false)
                                                setValor('')
                                            }}
                                            autoComplete='off'
                                            invalid={errComissao}
                                        />
                                    </Col>
                                    <Col md={1}>
                                        <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Meses de</Label>
                                        <Input
                                            type='text'
                                            placeholder='...'
                                            value={maskNumber(mesesDe)}
                                            disabled={seeRegister}
                                            maxLength='3'
                                            onChange={e => {
                                                setMesesDe(maskNumber(e.target.value))
                                                setErrMesesDe(false)
                                            }}
                                            className={errMesesDe ? 'is-invalid' : ''}
                                        />
                                    </Col>
                                    <Col md={1}>
                                        <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Meses até</Label>
                                        <Input
                                            type='text'
                                            placeholder='...'
                                            value={maskNumber(mesesAte)}
                                            disabled={seeRegister}
                                            maxLength='3'
                                            className={errMesesAte ? 'is-invalid' : ''}
                                            onChange={e => {
                                                setMesesAte(maskNumber(e.target.value))
                                                setErrMesesAte(false)
                                            }}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>{unidade === 'UNIDADE' ? 'Unidade' : 'Percentual'}</Label>
                                        <Input
                                            type='text'
                                            value={maskMoeda(valor)}
                                            disabled={seeRegister}
                                            placeholder='Digite aqui...'
                                            onChange={e => {
                                                if (unidade === 'UNIDADE') {
                                                  setValor(maskMoeda(e.target.value))
                                                } else {
                                                  setValor(maskMoeda(e.target.value).replaceAll(',', '.') > 100 ? "100,00" : maskMoeda(e.target.value))
                                                }

                                                setErrValor(false)
                                            }}
                                            maxLength={unidade === 'UNIDADE' ? '12' : '6'}
                                            className={errValor ? 'is-invalid' : ''}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <button style={{ border: 'none', background: 'transparent', color: 'var(--color-preto', margin: '25px 30px 0 0', cursor: 'pointer' }}
                                            onClick={() => handleAddEvento()}
                                            disabled={seeRegister}>
                                            <AiOutlinePlusCircle style={{ fontSize: '20px', color: 'var(--color-verde)' }} /> Adicionar Evento
                                        </button>
                                        <button style={{ margin: '15px 0 20px 0', height: '36px', width: '170px', background: 'transparent', color: 'var(--color-laranja)', border: '1px solid var(--color-laranja)', borderRadius: '5px', cursor: 'pointer' }}
                                            disabled={seeRegister}
                                            onClick={() => cleanState()}>
                                            Limpar
                                        </button>
                                    </Col>
                                </Row>

                                {registros.tabelaComissaoEventos.map((event, i) => {
                                    return (
                                        <CardItem e={event} i={i} />
                                    )
                                })}
                            </Card>
                        </Col>
                    </Col>
                </Card>
            </Col>
        </>
    )
}

export default Register
