const STORE_ID = "TIPO_OPERACAO";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    OPERACAO: "OPERACAO",
    OPERACAO_COPY: "OPERACAO_COPY",
    INVALID_OPERACAO: "INVALID_OPERACAO",
    OPERACOES: "OPERACOES",
    CLEAN_INVALID_OPERACAO: "CLEAN_INVALID_OPERACAO",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE"
}

export { STORE_ID, TYPES }