import { combineReducers } from 'redux';
import routes from './routes';
import login from './login';
import produtoRc from './produtosRibercred';
import hierarquia from './hierarquia';
import convenioRc from './conveniosRibercred';
import operacaoRibercred from './operacaoRibercred';
import statusRc from './statusRibercred';
import vinculoCentroDeCusto from './vinculoCentroDeCusto';
import centroCusto from './centroCusto';
import convenioInstituicao from './convenioInstituicao';
import operacaoInstituicao from './operacaoInstituicao';
import produtoInst from './produtosInstituicoes';
import statusInstituicao from './statusInstituicao';
import personas from './personas/personas_store';
import personasInformacoes from './personas/informacoes_store';
import personasEndereco from './personas/endereco_store';
import personasContato from './personas/contato_store';
import personasDocumentacao from './personas/documentacao_store';
import personasPapeis from './personas/papeis_store';
import inconsistencias from './inconsistencias';
import campanhaPremiacao from './campanhaPremiacao/campanha';
import eventosCampanha from './campanhaPremiacao/eventos';
import tipoComissao from './tipoComissao';
import grupo from './grupos';
import comissao from './comissao';
import tipoConvenio from './tipoConvenio';
import regrasRepasse from './regrasRepasse';
import tipoDocumento from './tipoDocumento';
import tipoParceria from './tipoParceria';
import propostas from './propostas';
import perfilCliente from './perfilCliente';
import contaCorrente from './personas/conta_corrente_store';
import alterarSenha from './alterarSenha';
import layoutParametrizacao from './layoutParametrizacao';
import usuario from './usuario';
import orgaoEmissor from './orgaoEmissor';
import grupoDeAcesso from './grupoDeAcesso';
import uploadComissao from './uploadComissao';
import perfilAcesso from './perfilAcesso';
import contasReceber from './contasReceber';
import contasAPagar from './contasAPagar';
import assocUsuarioGrupo from './assocUsuarioGrupo';
import esqueceuSenha from './esqueceuSenha';
import dashboard from './dashboard';
import dashboardProducao from './dashboard/producao';
import dashboardEsteiraProducao from './dashboard/esteiraProducao';
import dashboardPrevisao from './dashboard/previsao';
import dashboardReceita from './dashboard/receita';
import dashboardCampanha from './dashboard/campanha';
import dashboardFormalizacao from './dashboard/formalizacao';
import antContasReceber from './antecipacaoContasReceber';
import divisaoComissao from './divisaoComissao';
import loading from './globalLoading';
import formalizacaoLoja from './formalizacaoLoja';
import formalizacaoMatriz from './formalizacaoMatriz';
import tipoFormalizacaoRiber from './tipoFormalizacaoRibercred';
import tipoFormalizacaoInst from './tipoFormalizacaoInstituicao';
import inconsistenciaProducao from './inconsistenciaProducao';
import inconsistenciaComissao from './inconsistenciaComissao';
import pendenciaRibercred from './pendenciaRibercred';
import pendenciaInstituicao from './pendenciaInstituicao';
import parametrosAutomacao from './parametrosAutomacao';
import aceiteCp from './aceiteCp';
import inconsistenciaComissaoRecebida from './inconsistenciaComissaoRecebida';
import vinculoLoja from './vinculoLoja';
import agrupamentoPropostas from './agrupamentoProposta';
import agrupamentoPropostasCentroCusto from './agrupamentoPropostaCentroCusto';
import conferenciaCr from './conferenciaCr';
import inconsistenciaProposta from './inconsistenciaProposta';
import inconsistenciaFormalizacao from './inconsistenciaFormalizacao';
import httpRequest from './propostas/httpRequest';
import instituicaoFinanceira from './personas/instituicaoFinanceira';
import contaGerencial from './contaGerencial';
import personasParametros from './personas/parametros_store';
import logW3Erp from "./conferenciaCr/logW3Erp";

export default combineReducers({
  routes,
  orgaoEmissor,
  login,
  produtoRc,
  hierarquia,
  convenioRc,
  operacaoRibercred,
  statusRc,
  vinculoCentroDeCusto,
  convenioInstituicao,
  operacaoInstituicao,
  produtoInst,
  statusInstituicao,
  personas,
  personasInformacoes,
  personasEndereco,
  personasContato,
  personasDocumentacao,
  personasPapeis,
  personasParametros,
  inconsistencias,
  campanhaPremiacao,
  tipoComissao,
  grupo,
  comissao,
  tipoConvenio,
  regrasRepasse,
  tipoDocumento,
  tipoParceria,
  propostas,
  perfilCliente,
  contaCorrente,
  instituicaoFinanceira,
  contaGerencial,
  usuario,
  alterarSenha,
  layoutParametrizacao,
  grupoDeAcesso,
  uploadComissao,
  eventosCampanha,
  perfilAcesso,
  contasReceber,
  contasAPagar,
  assocUsuarioGrupo,
  esqueceuSenha,
  dashboard,
  dashboardProducao,
  dashboardCampanha,
  antContasReceber,
  dashboardEsteiraProducao,
  dashboardReceita,
  divisaoComissao,
  dashboardPrevisao,
  loading,
  formalizacaoLoja,
  formalizacaoMatriz,
  tipoFormalizacaoRiber,
  tipoFormalizacaoInst,
  inconsistenciaProducao,
  inconsistenciaComissao,
  pendenciaRibercred,
  pendenciaInstituicao,
  dashboardFormalizacao,
  parametrosAutomacao,
  aceiteCp,
  inconsistenciaComissaoRecebida,
  vinculoLoja,
  agrupamentoPropostas,
  agrupamentoPropostasCentroCusto,
  conferenciaCr,
  inconsistenciaProposta,
  inconsistenciaFormalizacao,
  centroCusto,
  httpRequest,
  logW3Erp,
});
