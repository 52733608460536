import React, { useMemo } from 'react'
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Col, Row } from 'reactstrap';
import * as BsIcons from "react-icons/bs";
import Paginate from './Paginate';

function Table(props) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns = useMemo(() => props.columns, []);
    const data = props.data;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        setPageSize,
        prepareRow,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const { pageSize } = state;

    return (
        <>
            <div style={{ overflowX: 'auto' }}>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th>
                                        {column.render("Header")}
                                        <span className="float-right">
                                            {column.isSorted ?
                                                (column.isSortedDesc ?
                                                    <BsIcons.BsArrowDown
                                                        {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                    />
                                                    :
                                                    <BsIcons.BsArrowUp
                                                        id="OrdenarAsc"
                                                        {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                    />)
                                                :
                                                <BsIcons.BsArrowDownUp
                                                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                />}
                                        </span>
                                    </th>
                                ))}
                                {props.actions &&
                                    <th style={{ textAlign: "center" }}>
                                        Ações
                                    </th>
                                }
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr key={i} className="text-center" {...row.getRowProps()}>
                                    {row.cells.map((cell, i) => {
                                        return (<td key={i} {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                    })}

                                    {props.actions && <td style={{ textAlign: "center", width: "10%" }}>
                                        <>
                                            {props.actions(row.original.id)}
                                        </>
                                    </td>}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <Row className="pt-4">
                <Col>
                    <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {data ? data.length : "0"} de {props.totalRegistros ? props.totalRegistros : "0"}</span>
                </Col>
                <Col md={8}>
                    <Row className="float-right">
                        <Paginate
                            onPageChange={props.onPageChange}
                            pageCount={props.totalPaginas}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            dataLength={data.length}
                            pageSelected={props.pageSelected}
                            setPageSelected={props.setPageSelected}
                            handleInitialPage={props.handleInitialPage}
                        />
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Table
