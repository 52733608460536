import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}
export function setTab(value) {
    dispatch({
        type: TYPES.SET_TAB,
        value
    })
}

export function setPermitions(label, { visualizar }) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        label,
        value: !!visualizar
    })
}

export function setPermitionsTables(tab, label, { visualizar }) {
    dispatch({
        type: TYPES.SET_PERMITIONS_TABLES,
        tab,
        label,
        value: !!visualizar
    })
}