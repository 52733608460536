import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import { Card, Col, Form, Row, Button, TabContent, TabPane } from "reactstrap";
import ReactToPrint from 'react-to-print'

import Breadcrumb from "../../components/breadcrumbs";
import Search from "./tabs/search";
import Register from "./tabs/register";
import Etiqueta from "./components/Etiqueta";
import ModalImpressao from "./components/ModalImpressao";

import * as actions from "../../store/modules/formalizacaoMatriz/actions";
import * as controller from "../../controllers/formalizacaoMatrizController";

export default function FormalizacaoLoja(){

    const { item, impressao, etiqueta, activeTab, seeRegister, permitions } = useSelector(state => state.formalizacaoMatriz);

    useEffect(() => {
        actions.setActiveTab("Search")
    }, [])

    async function saveItem() {
        if(item.id === "0"){
            let isSuccess = await controller.create(item)
            isSuccess && document.getElementById('etiquetaButton').click()
        }
    }

    function changeTab(tab) {
        actions.setActiveTab(tab);
       actions.cleanRegister();
    }

    function seeRegisterF() {
        if (item.id !== "") {
            actions.cleanRegister();
        }
        actions.setSeeRegister(false);
    }

    const componentEtiqueta = useRef(null);
    const componentPrint = useRef(null);

    return (
        <>
                <Col>
                    <Breadcrumb activeTab={activeTab} />
                    <Card>
                        <Form>
                            {
                                activeTab === "Search"
                                    ?
                                    permitions.criar && (
                                        <Col md={12}>
                                            <Row className="pt-2 pb-2">
                                                <Col md={8}>
                                                    <Button
                                                        onClick={() => changeTab("Register")}
                                                        style={{ width: "280px" }}
                                                        className='purpleButton'
                                                    >
                                                        Criar Protocolo
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                    :
                                    <Col className="pt-2 pb-2">
                                        <Button
                                            onClick={() => { changeTab("Search"); seeRegisterF() }}
                                            style={{ width: "127px", color: "white" }}
                                            className="purpleButton"
                                        >
                                            <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar
                                        </Button>
                                        {!seeRegister && (
                                            <Button
                                                onClick={() => saveItem()}
                                                style={{ width: "242px", float: 'right' }}
                                                className="purpleButton"
                                            >
                                                Gerar Protocolo
                                            </Button>
                                        )}
                                    </Col>
                            }
                        </Form>
                    </Card>
                </Col>

                 <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <br />
                        <Search />
                    </TabPane>
                    <TabPane tabId="Register">
                        <br />
                        <Register />
                    </TabPane>
                </TabContent>

                <div style={{display:'none'}}>
                <ReactToPrint
                    onAfterPrint={()=> {actions.cleanEtiqueta(); document.getElementById('prinButton').click()}}
                    trigger={() => <button id='etiquetaButton'></button>}
                    content={() => componentEtiqueta.current}
                />
                <ReactToPrint
                    onAfterPrint={()=> actions.cleanImpressao()}
                    trigger={() => <button id='prinButton'></button>}
                    content={() => componentPrint.current}
                />
                
                <Etiqueta data={etiqueta} ref={componentEtiqueta}/>
                <ModalImpressao data={impressao} ref={componentPrint}/>
            </div>
        </>
    );
}