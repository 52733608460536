import { STORE_ID, TYPES } from "./types";
import store from "../../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setEnderecos(value) {
    dispatch({
        type: TYPES.ENDERECOS,
        value
    });
}
export function setEndereco(value) {
    dispatch({
        type: TYPES.ENDERECO,
        value
    });
}
export function setEstados(value) {
    dispatch({
        type: TYPES.SET_ESTADOS,
        value
    });
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function getItemsTiposEnderecos(value) {
    dispatch({
        type: TYPES.GET_TIPOS_ENDERECO,
        value
    });
}
export function cleanEndereco(value) {
    dispatch({
        type: TYPES.CLEAN_ENDERECO,
        value
    });
}

export function setIsDeleting(value){
    dispatch({
        type: TYPES.SET_IS_DELETING,
        value
    })
}

export function setIsEditando(value){
    dispatch({
        type: TYPES.SET_IS_EDITANDO,
        value
    })
}