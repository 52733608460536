import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal, ModalBody, Row, Navbar, Col, Label, Input } from 'reactstrap'
import * as controller from '../../../controllers/aceiteCpController'
import { useEffect } from 'react'
import { useState } from 'react'
import { maskMoeda } from '../../../util/masks'
import  Select  from '../../../components/Inputs/Select'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import { notifySuccess } from '../../../components/message/message'

const ModalEditar = ({ toggle, isOpen, pagination }) => {
    const { item: filters, items, seeRegister } = useSelector(state => state.aceiteCp)

    const [canAceiteProdutividade, setCanAceiteProdutividade] = useState(true)
    const [canAceiteSuperacao, setCanAceiteSuperacao] = useState(true)
    const [canAceiteComissao,setCanAceiteComissao] = useState(true)

    const [isAceiteProdutividadeSelected, setIsAceiteProdutividadeSelected] = useState(false)
    const [isAceiteSuperacaoSelected, setIsAceiteSuperacaoSelected] = useState(false)
    const [isAceiteComissaoSelected,setIsAceiteComissaoSelected] = useState(false)
    
    async function handleConfirmar(){
        const newItems = items.filter((item) => item.isSelected);
        newItems.forEach(async (item)=>{
            if(isAceiteComissaoSelected){
                await controller.aceiteComissao({id: item.id, descricao: item.nomePessoa})
            }
            if(isAceiteSuperacaoSelected){
                await controller.aceiteSuperacao({id: item.id, descricao: item.nomePessoa})
            }
            if(isAceiteProdutividadeSelected){
                await controller.aceiteProdutividade({id: item.id, descricao: item.nomePessoa})
            }

            await controller.get(pagination, 10, filters)
        });
        notifySuccess('Itens aceitos com sucesso!')
        toggle()
    }

    useEffect(()=>{
        if(isOpen){
            setIsAceiteProdutividadeSelected(false)
            setIsAceiteSuperacaoSelected(false)
            setIsAceiteComissaoSelected(false)

            const selectedItems = items.filter((item) => item.isSelected);
            const hasStatusComissaoPaga = selectedItems.findIndex((item)=> item.statusComissaoPagaId === 'cdb612ff-bfe9-48b6-a5ea-8fa7d46200b1')
            const hasStatusSuperacao = selectedItems.findIndex((item)=> item.statusSuperacaoId === 'cdb612ff-bfe9-48b6-a5ea-8fa7d46200b1')
            const hasStatusProdutividade = selectedItems.findIndex((item)=> item.statusProdutividadeId === 'cdb612ff-bfe9-48b6-a5ea-8fa7d46200b1')
           
            if(hasStatusComissaoPaga !== -1){
                setCanAceiteComissao(false)
            }else{
                setCanAceiteComissao(true)
            }
           
            if(hasStatusSuperacao !== -1){
                setCanAceiteSuperacao(false)
            }else{
                setCanAceiteSuperacao(true)
            }
           
            if(hasStatusProdutividade !== -1){
                setCanAceiteProdutividade(false)
            }else{
                setCanAceiteProdutividade(true)
            }
        }
    },[isOpen])


    return (
        <div>
            <Modal
                className="text-center"
                isOpen={isOpen}
                toggle={toggle}
                backdrop={'static'}
                keyboard={false}
                size="md"
            >
                <Navbar expand="md" style={{ width: "100%", height: "40px", background: 'var(--color-gradiente-verde)', marginBottom: '20px' }}>
                    <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2" >Aceite dados</h5>
                </Navbar>
                <ModalBody>
                    <div style={{marginLeft: '15%'}}>
                        <Row>
                            <Col md={10}>
                                <div style={{width: '100%', justifyContent: 'start', alignItems: 'start', textAlign: 'start'}}>
                                    <Checkbox
                                        checked={isAceiteProdutividadeSelected}
                                        style={canAceiteProdutividade ? {color: 'green'} : {color: 'grey', cursor: 'not-allowed'}}
                                        size={'small'}
                                        onClick={()=> {
                                            setIsAceiteProdutividadeSelected(!isAceiteProdutividadeSelected)
                                        }}
                                        disabled={!canAceiteProdutividade}
                                    />
                                    <label htmlFor="aceiteProdutividade">Produtividade</label>
                                </div>

                                <div style={{width: '100%', justifyContent: 'start', alignItems: 'start', textAlign: 'start'}}>
                                    
                                   <Checkbox
                                        checked={isAceiteSuperacaoSelected}
                                        style={canAceiteSuperacao ? {color: 'green'} : {color: 'grey', cursor: 'not-allowed'}}
                                        size={'small'}
                                        onClick={()=> {
                                            setIsAceiteSuperacaoSelected(!isAceiteSuperacaoSelected)
                                        }}
                                        disabled={!canAceiteSuperacao}
                                    />
                                    <label htmlFor="aceiteSuperacao">Superação</label>
                                </div>

                                <div style={{width: '100%', justifyContent: 'start', alignItems: 'start', textAlign: 'start'}}>
                                    <Checkbox
                                        checked={isAceiteComissaoSelected}
                                        style={canAceiteComissao ? {color: 'green'} : {color: 'grey', cursor: 'not-allowed'}}
                                        size={'small'}
                                        onClick={()=> {
                                            setIsAceiteComissaoSelected(!isAceiteComissaoSelected)
                                        }}
                                        disabled={!canAceiteComissao}
                                    />
                                    <label htmlFor="aceiteComissao">Comissão</label>
                                </div>
                                
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <div className="m-3">
                    <Button
                        onClick={()=> toggle()}
                        className="mr-4"
                        style={{
                            width: "150px",
                            background: "var(--color-branco)",
                            borderColor: "var(--color-laranja)",
                            color: "var(--color-laranja)",
                            borderRadius: "7px"
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={(e) => handleConfirmar()}
                        color="danger"
                        className="purpleButton"
                        style={{ width: "150px" }}
                        disabled={seeRegister}
                    >
                        Confirmar
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export default ModalEditar