import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    search: {
        cnpjPesquisa: '',
        titulo: '',
        empresaPesquisa: '',
        fornecedor: '',
        dataEntrada: '',
        dataEmissao: '',
        dataVencimento: ''
    },
    item: {
        id: '',
        pessoaId: '',
        propostaId: null,
        empresa: 'Ribercred-RP',
        filial: '',
        cnpjFilial: '',
        titulo: '',
        tipoTitulo: '',
        fornecedor: '',
        cnpj: '',
        favorecido: '',
        valor: 0,
        formaPagamento: '',
        transacao: '',
        dataEmissao: '',
        dataEntrada: '',
        dataVencimento: '',
        consideraJuros: false,
        juros: '',
        percentualJuros: 0,
        percentualMulta: 0,
        percentualDesconto: 0,
        valorDesconto: 0,
        portador: 0,
        carteira: 0,
        moeda: '1',
        observacao: '',
        agencia: '',
        conta: '',
        contaFinanceira: '',
        contaContabil: '',
        projeto: '',
        orcamento: '',
        percentualRateio: 0,
        rateio: 0,
        centroCusto: '',
        percentualCentroCusto: 0,
        valorRateioCentroCusto: 0
    },
    modalFornecedor: {
        cnpj: '',
        nome: ''
    },
    modalFilial: {
        cnpj: '',
        nome: ''
    },
    modalFavorecido: {
        cpfCnpj: '',
        nome: '',
        filtro: '0'
    },
    copy: {},
    invalid: {
        filial: false,
        favorecido: false,
        valor: false,
        formaPagamento: false,
        dataEntrada: false,
        dataEmissao: false,
        dataVencimento: false,
        percentualJuros: false,
        percentualMulta: false
    },
    allItems: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
        },

    allItemsFavorecido: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
        },
        
    allItemsFilial: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
        },

    allItemsFornecedor: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
        },

    items: [],
    contas: [],
    empresas: [],
    filiais: [],
    fornecedores: [],
    favorecidos: [],
    activeTab: "Search",
    seeRegister: false,
    modalFilialLoading: false,
    modalFavorecidoLoading: false,
    modalFornecedorLoading: false,
    permitions: {
        criar: false,
        deletar: false,
    }
}

export default function contasReceber(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.SET_MODAL_FORNECEDOR:
                    return { ...state, modalFornecedor: { ...state.modalFornecedor, [action.campo]: action.value } }
                case TYPES.SET_MODAL_FILIAL:
                    return { ...state, modalFilial: { ...state.modalFilial, [action.campo]: action.value } }
                case TYPES.SET_MODAL_FAVORECIDO:
                    return { ...state, modalFavorecido: { ...state.modalFavorecido, [action.campo]: action.value } }
                case TYPES.SET_ITEM:
                    return { ...state, item: action.value }
                case TYPES.ITEMS:
                    return { ...state, items: action.value }
                case TYPES.SEARCH:
                    return { ...state, search: action.value }
                case TYPES.SET_CONTAS:
                    return { ...state, contas: action.value }
                case TYPES.SET_EMPRESAS:
                    return { ...state, empresas: action.value }
                case TYPES.SET_FILIAIS:
                    return { ...state, filiais: action.value }
                case TYPES.SET_FORNECEDORES:
                    return { ...state, fornecedores: action.value }
                case TYPES.SET_FAVORECIDOS:
                    return { ...state, favorecidos: action.value }
                case TYPES.SET_ALL_ITENS:
                    return { ...state, allItems: action.value }
                case TYPES.SET_ALL_ITENS_FAVORECIDO:
                    return { ...state, allItemsFavorecido: action.value }
                case TYPES.SET_ALL_ITENS_FILIAL:
                    return { ...state, allItemsFilial: action.value }
                case TYPES.SET_ALL_ITENS_FORNECEDOR:
                    return { ...state, allItemsFornecedor: action.value }
                case TYPES.INVALID:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state,
                        item: INITIAL_STATE.item,
                        invalid: INITIAL_STATE.invalid
                    }
                case TYPES.CLEAN_SEARCH:
                    return {
                        ...state,
                        search: INITIAL_STATE.search,
                        copy: INITIAL_STATE.copy,
                        modalFornecedor: INITIAL_STATE.modalFornecedor,
                        modalFilial: INITIAL_STATE.modalFilial,
                        modalFavorecido: INITIAL_STATE.modalFavorecido
                    }
                case TYPES.CLEAN_ALL_SEARCH:
                    return {
                        ...state,
                        search: INITIAL_STATE.search,
                        copy: INITIAL_STATE.copy,
                        items: INITIAL_STATE.items,
                        getAllItems: INITIAL_STATE.getAllItems
                    }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                case TYPES.SET_LOADING_FILIAL:
                    return { ...state, modalFilialLoading: action.value }
                case TYPES.SET_LOADING_FAVORECIDO:
                    return { ...state, modalFavorecidoLoading: action.value }
                case TYPES.SET_LOADING_FORNECEDOR:
                    return { ...state, modalFornecedorLoading: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}