import React, { useState } from 'react';
import * as RiIcons from "react-icons/ri";
import { Card, Collapse, CardBody } from 'reactstrap';

const CardCollapse = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <>
            <Card className="p-2">
                <div color="primary" onClick={toggle}>{isOpen ? <RiIcons.RiArrowUpSLine className="mr-2" /> : <RiIcons.RiArrowDownSLine className="mr-2" />} {props.title && props.title}</div>
                <Collapse isOpen={isOpen}>
                    <CardBody>
                        {props.children}
                    </CardBody>
                </Collapse>
            </Card>
        </>
    )
}

export default CardCollapse
