import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as operacaoInstituicaoActions from "../store/modules/operacaoInstituicao/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("tipos-operacao-banco");
    setLoading(false)

    if (!data.success) {
        return;
    }

    operacaoInstituicaoActions.getItems(data.content);
}

export async function getOperacoesRc() {

    setLoading(true)
    const data = await api.get("tipos-operacao");
    setLoading(false)

    if (!data.success) {
        return;
    }

    operacaoInstituicaoActions.setOperacoes(data.content);
}

export async function getBancos() {

    const data = await api.get("bancos");

    if (!data.success) {
        return;
    }

    operacaoInstituicaoActions.setBancos(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`tipos-operacao-banco/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    const dataUpdate = {
        bancoId: data.content.banco.id,
        operacaoRcId: data.content.tipoOperacao.id,
        descricao: data.content.descricao,
        id: data.content.id
    }

    operacaoInstituicaoActions.copy(dataUpdate);
    operacaoInstituicaoActions.item(dataUpdate);
}

export async function create(item) {

    if (!item.descricao || item.descricao.trim() === "") {
        operacaoInstituicaoActions.setInvalid("descricao");
        return notifyWarn("Descrição do tipo de operação instituição não pode estar em branco");
    }

    if (item.bancoId === "0") {
        operacaoInstituicaoActions.setInvalid("bancoId");
        return notifyWarn("Selecione uma instituição financeira");
    }

    if (item.operacaoRcId === "0") {
        operacaoInstituicaoActions.setInvalid("operacaoRcId");
        return notifyWarn("Selecione uma operação ribercred");
    }

    const body = {
        descricao: item.descricao,
        bancoId: item.bancoId,
        tipoOperacaoId: item.operacaoRcId
    }

    setLoading(true)
    const data = await api.post("tipos-operacao-banco", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Tipo de operação instituição cadastrada");
    operacaoInstituicaoActions.cleanRegister();
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`tipos-operacao-banco/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Tipo de operação removida");
    get();
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (!item.descricao || item.descricao.trim() === "") {
        operacaoInstituicaoActions.setInvalid("descricao");
        return notifyWarn("Descrição do tipo de operação instituição não pode estar em branco");
    }

    if (item.bancoId === "0") {
        operacaoInstituicaoActions.setInvalid("bancoId");
        return notifyWarn("Selecione uma instituição financeira");
    }

    if (item.operacaoRcId === "0") {
        operacaoInstituicaoActions.setInvalid("operacaoRcId");
        return notifyWarn("Selecione uma operação ribercred");
    }

    const body = {
        descricao: item.descricao,
        bancoId: item.bancoId,
        tipoOperacaoId: item.operacaoRcId
    }

    setLoading(true)
    const data = await api.put(`tipos-operacao-banco/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Tipo de operação instituição atualizada");
    operacaoInstituicaoActions.cleanRegister();
    operacaoInstituicaoActions.setActiveTab("Search");
    get();
}
