import React from 'react'

import './stepBarStyle.css'
export default function StepBar({steps}){
    return(
            <div className='container-stepbar'>
                { steps.map((step, index) =>{
                    return(
                        <div>
                            <div style={{display: 'flex'}}>
                                <div className={index === 0 ? 'linha noLine' : 'linha'}/>
                                <div className='numero-step'>
                                    {index+1}
                                </div>
                                <div className={
                                    steps[steps.length-1].descricao === 'OBJETO ENTREGUE AO DESTINATÁRIO' ?
                                    (index === steps.length-1 ? 'linha noLine' : 'linha')
                                    :
                                    (index === steps.length-1 ? 'linha-cinza' : 'linha')}/>
                            </div>
                            <div className='step'>
                            <p className='descricao-step'>
                                    {step.descricao}
                            </p>
                            <p className='data-hora'>
                                {step.data}
                            </p>
                            </div>
                        </div>
                    )
                })}
                {
                    steps.length>= 1 && steps[steps.length-1].descricao !== 'OBJETO ENTREGUE AO DESTINATÁRIO' && (
                        <div >
                            <div id='numero-step' 
                            style={{width: '32px', height: '32px', background: 'transparent', borderRadius: '32px',padding:'7px 2px 0 0', margin:'0px 8px 0px 8px', color: 'var(--color-cinza-medio)', border: '1px solid var(--color-cinza-medio)'}}>
                                {steps.length+1}
                            </div>
                            <div id='step'>
                            <p id='descricao-step'
                            style={{textAlign:'left', fontSize: '12px', marginBottom: '4px'}}>
                                   Entregue
                            </p>
                            </div>
                        </div>          
                    )
                }          
            </div>
    )
}