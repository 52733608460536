import { Col, Input, Label, Row } from "reactstrap";

import { Select } from "../../../components/Inputs/Input";
import SelectItem from "../../../components/Inputs/Select";
import { notifyWarn } from "../../../components/message/message";

import { MapeamentosList } from "./MapeamentosList";
import { ModalDetalhamento } from "./ModalDetalhamento";
import { ModalStatus } from "./ModalStatus";

import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";

import { useSelector } from "react-redux";

import * as actions from "../../../store/modules/layoutParametrizacao/actions";
import { useEffect, useState } from "react";

export function Mapeamento() {
  const {
    isInEditMode,
    layoutBeingCreated,
    layoutBeingEdited,
    layoutBeingVisualized,
    layoutsCamposEquivalentes,
    mapeamento,
    isModalDetalhamentoOpen,
    isModalStatusOpen,
    isInVisualizeMode,
  } = useSelector((state) => state.layoutParametrizacao);

  const layout = isInVisualizeMode
    ? layoutBeingVisualized
    : isInEditMode
    ? layoutBeingEdited
    : layoutBeingCreated;

  const hasDetalhamento =
    layout.layoutPlanilhaParametros.some(
      (parametro) => parametro.detalhes.length > 0
    ) || mapeamento.camposEquivalentesOpcionais.length > 0;

  const hasStatus = layout.layoutPlanilhaParametros.some(
    (parametro) => parametro.status.length > 0
  );

  const [camposEquivalentesDisponiveis, setCamposEquivalentesDisponiveis] =
    useState(
      layoutsCamposEquivalentes.filter(
        (campoEquivalente) =>
          !layout.layoutPlanilhaParametros?.some(
            (layoutPlanilhaParametro) =>
              layoutPlanilhaParametro.layoutPlanilhaCamposEquivalenteId ===
              campoEquivalente.id
          ) &&
          !layout.layoutPlanilhaParametros?.some((layoutPlanilhaParametro) =>
            layoutPlanilhaParametro.camposEquivalentesOpcionais.some(
              (campoOpcional) =>
                campoOpcional.layoutPlanilhaCamposEquivalenteId ===
                campoEquivalente.id
            )
          ) &&
          campoEquivalente.id !==
            mapeamento.layoutPlanilhaCamposEquivalenteId &&
          !mapeamento.camposEquivalentesOpcionais.some(
            (campoOpcional) =>
              campoOpcional.layoutPlanilhaCamposEquivalenteId ===
              campoEquivalente.id
          )
      )
    );

  useEffect(() => {
    if (layoutBeingCreated.validaProduto || layoutBeingEdited.validaProduto) {
      setCamposEquivalentesDisponiveis(
        layoutsCamposEquivalentes.filter(
          (campoEquivalente) =>
            !layout.layoutPlanilhaParametros?.some(
              (layoutPlanilhaParametro) =>
                layoutPlanilhaParametro.layoutPlanilhaCamposEquivalenteId ===
                campoEquivalente.id
            ) &&
            !layout.layoutPlanilhaParametros?.some((layoutPlanilhaParametro) =>
              layoutPlanilhaParametro.camposEquivalentesOpcionais.some(
                (campoOpcional) =>
                  campoOpcional.layoutPlanilhaCamposEquivalenteId ===
                  campoEquivalente.id
              )
            ) &&
            campoEquivalente.id !==
              mapeamento.layoutPlanilhaCamposEquivalenteId &&
            !mapeamento.camposEquivalentesOpcionais.some(
              (campoOpcional) =>
                campoOpcional.layoutPlanilhaCamposEquivalenteId ===
                campoEquivalente.id
            )
        )
      );
    } else {
      setCamposEquivalentesDisponiveis(
        layoutsCamposEquivalentes.filter(
          (campoEquivalente) =>
            !layout.layoutPlanilhaParametros?.some(
              (layoutPlanilhaParametro) =>
                layoutPlanilhaParametro.layoutPlanilhaCamposEquivalenteId ===
                campoEquivalente.id
            ) &&
            !layout.layoutPlanilhaParametros?.some((layoutPlanilhaParametro) =>
              layoutPlanilhaParametro.camposEquivalentesOpcionais.some(
                (campoOpcional) =>
                  campoOpcional.layoutPlanilhaCamposEquivalenteId ===
                  campoEquivalente.id
              )
            ) &&
            campoEquivalente.id !==
              mapeamento.layoutPlanilhaCamposEquivalenteId &&
            !mapeamento.camposEquivalentesOpcionais.some(
              (campoOpcional) =>
                campoOpcional.layoutPlanilhaCamposEquivalenteId ===
                campoEquivalente.id
            ) &&
            campoEquivalente.id !== "485f4008-b714-4eab-af91-163eaacf4dab" 
        )
      );
    }
  }, [
    layoutBeingCreated.validaProduto,
    layoutBeingEdited.validaProduto,
    layoutsCamposEquivalentes,
  ]);

  async function handleAddMapeamento(event) {
    event.preventDefault();
    let validDescricao = true;
    let validNomeColuna = true;
    let validCampoEquivalente = true;

    if (mapeamento.descricao === "") {
      return notifyWarn("Por favor, informe a descrição do mapeamento.");
    }

    if (
      mapeamento.layoutPlanilhaCamposEquivalenteId === "0" ||
      mapeamento.layoutPlanilhaCamposEquivalenteId === ""
    ) {
      return notifyWarn("Por favor, informe o campo equivalente do sistema.");
    }

    layout.layoutPlanilhaParametros.forEach((layout) => {
      if (layout.descricao === mapeamento.descricao) {
        validDescricao = false;
      }
      if (
        layout.nomeColuna === mapeamento.nomeColuna &&
        mapeamento.nomeColuna !== ""
      ) {
        validNomeColuna = false;
      }
      if (
        layout.layoutPlanilhaCamposEquivalenteId ===
        mapeamento.layoutPlanilhaCamposEquivalenteId
      ) {
        validCampoEquivalente = false;
      }
    });

    if (!validDescricao) {
      return notifyWarn("A descrição informada já foi cadastrada");
    }
    if (!validNomeColuna) {
      return notifyWarn("O nome da coluna informada já foi cadastrada");
    }
    if (!validCampoEquivalente) {
      return notifyWarn("O campo equilavente informado já foi cadastrado");
    }

    actions.addLayoutPlanilhaParametros({ mapeamento, isInEditMode });

    actions.clearMapeamento();
  }

  function handleAdicionarCampoEquivalente(event) {
    event.preventDefault();

    // clear Default field
    actions.setMapeamento("default", "");

    actions.setMapeamento("camposEquivalentesOpcionais", [
      ...mapeamento.camposEquivalentesOpcionais,
      { layoutPlanilhaCamposEquivalenteId: "0" },
    ]);
  }

  function handleCampoEquivalenteNoSistemaChange(e) {
    if (layout.tipoArquivoId) {
      actions.setMapeamento("layoutPlanilhaCamposEquivalenteId", e.value);
      actions.setMapeamento("layoutPlanilhaCamposEquivalente", e.label);
      actions.setMapeamento("detalhes", []);
      actions.setMapeamento("status", []);
    }
  }

  return (
    <Row className="mt-4">
      <div className={"card-paramentros"}>
        <p style={{ fontWeight: "700" }}>Mapeamento de campo</p>
        <p style={{ marginTop: "15px", color: "var(--color-preto)" }}>
          Os parâmetros abaixo serão utilizados como base para a leitura
          automática dos arquivos recebidos pelo banco.
        </p>
        {!isInVisualizeMode && (
          <div
            style={{
              display: "flex",
              width: `${
                mapeamento.camposEquivalentesOpcionais.length === 0
                  ? "100rem"
                  : `calc(85rem + ${
                      (mapeamento.camposEquivalentesOpcionais.length - 1) * 25
                    }rem)`
              }`,
              maxWidth: "1780px",
            }}
          >
            <div style={{ minWidth: "66rem" }}>
              <Row>
                <Col style={{ minWidth: "235px", maxWidth: "235px" }}>
                  <Label
                    for="convenio"
                    style={{
                      fontWeight: "700",
                      color: "var(--color-preto)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Descrição
                  </Label>
                  <Input
                    type="text"
                    placeholder="Digite aqui..."
                    value={mapeamento.descricao}
                    onChange={(e) =>
                      actions.setMapeamento("descricao", e.target.value)
                    }
                    style={{ height: "37px" }}
                  />
                </Col>
                <Col style={{ minWidth: "235px", maxWidth: "235px" }}>
                  <Label
                    for="convenio"
                    style={{
                      fontWeight: "700",
                      color: "var(--color-preto)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Nome da coluna
                  </Label>
                  <Input
                    type="text"
                    placeholder="Digite aqui..."
                    value={mapeamento.nomeColuna}
                    onChange={(e) =>
                      actions.setMapeamento("nomeColuna", e.target.value)
                    }
                    style={{ height: "37px" }}
                  />
                </Col>

                <Col
                  style={{
                    display: "flex",
                    marginRight: "-1rem",
                    minWidth: "235px",
                    maxWidth: "235px",
                  }}
                >
                  <div>
                    <Label
                      for="convenio"
                      style={{
                        fontWeight: "700",
                        color: "var(--color-preto)",
                      }}
                    >
                      Campo equivalente no sistema
                    </Label>
                    <Select
                      first="Selecione"
                      value={{
                        value: mapeamento.layoutPlanilhaCamposEquivalenteId,
                      }}
                      select={{ value: "id", label: "campoModel" }}
                      options={camposEquivalentesDisponiveis}
                      onChange={(e) => {
                        handleCampoEquivalenteNoSistemaChange(e);
                      }}
                      autoComplete="off"
                    />
                  </div>
                </Col>

                {mapeamento.default === "" &&
                  mapeamento.camposEquivalentesOpcionais.map(
                    (campo, campoI) => (
                      <Col
                        style={{
                          minWidth: "250px",
                          maxWidth: "250px",
                          display: "flex",
                        }}
                        key={`campo${campoI}`}
                      >
                        <div>
                          <Label
                            style={{
                              fontWeight: "700",
                              color: "var(--color-preto)",
                            }}
                          >
                            Campo equivalente opcional
                          </Label>
                          <SelectItem
                            value={campo.layoutPlanilhaCamposEquivalenteId}
                            label="campoModel"
                            options={camposEquivalentesDisponiveis}
                            onFocus={() => {
                              if (layout.tipoArquivoId === "0") {
                                return notifyWarn(
                                  "Por favor, informe o tipo do arquivo."
                                );
                              }
                            }}
                            onChange={(e) => {
                              if (layout.tipoArquivoId) {
                                var array =
                                  mapeamento.camposEquivalentesOpcionais;
                                array[
                                  campoI
                                ].layoutPlanilhaCamposEquivalenteId = e.value;
                                actions.setMapeamento(
                                  "camposEquivalentesOpcionais",
                                  array
                                );
                              }
                            }}
                          />
                        </div>
                        <button
                          style={{
                            border: "none",
                            background: "transparent",
                            margin: "25px 0 0 5px",
                            color: "var(--color-preto)",
                            cursor: "pointer",
                            padding: "0",
                            width: "40px",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            var array = mapeamento.camposEquivalentesOpcionais;
                            array.splice(campoI, 1);
                            actions.setMapeamento(
                              "camposEquivalentesOpcionais",
                              array
                            );
                          }}
                        >
                          <AiOutlineClose />
                        </button>
                      </Col>
                    )
                  )}
                {mapeamento.camposEquivalentesOpcionais.length === 0 && (
                  <Col style={{ maxWidth: "235px" }}>
                    <Label
                      style={{
                        fontWeight: "700",
                        color: "var(--color-preto)",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Default
                    </Label>
                    <Input
                      type="text"
                      placeholder="Digite aqui..."
                      value={mapeamento.default}
                      onChange={(e) =>
                        actions.setMapeamento("default", e.target.value)
                      }
                      style={{ height: "37px" }}
                    />
                  </Col>
                )}
              </Row>
            </div>
            <div style={{ width: "26rem", display: "flex" }}>
              {mapeamento.camposEquivalentesOpcionais.length < 3 && (
                <button
                  id="addCampoButton"
                  style={{
                    border: "none",
                    background: "transparent",
                    marginTop: "25px",
                    marginLeft: "10px",
                    color: "var(--color-preto)",
                    cursor: "pointer",
                    padding: "0",
                    width: "130px",
                  }}
                  onClick={(e) => {
                    handleAdicionarCampoEquivalente(e);
                  }}
                >
                  <AiOutlinePlusCircle
                    style={{
                      color: "var(--color-roxo)",
                      fontSize: "20px",
                    }}
                  />
                  Equivalência
                </button>
              )}
              <button
                style={{
                  border: "none",
                  background: "transparent",
                  margin: "25px 0 0 10px",
                  color: "var(--color-preto)",
                  cursor: "pointer",
                  width: "130px",
                }}
                onClick={(e) => {
                  e.preventDefault();

                  handleAddMapeamento(e);
                }}
              >
                <AiOutlinePlusCircle
                  style={{
                    color: "var(--color-verde)",
                    marginRight: "5px",
                    marginLeft: "-1rem",
                    fontSize: "20px",
                  }}
                />
                Mapeamento
              </button>
              {(layout.tipoComissaoBancoId === "0" ||
                !layout.tipoComissaoBancoId) &&
                !hasDetalhamento &&
                mapeamento.layoutPlanilhaCamposEquivalente ===
                  "TipoComissao" && (
                  <>
                    <button
                      id="detalhamentoButton"
                      style={{
                        border: "none",
                        background: "transparent",
                        marginTop: "25px",
                        color: "var(--color-preto)",
                        cursor: "pointer",
                        padding: "0",
                        width: "130px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        actions.setIsModalDetalhamentoOpen(
                          !isModalDetalhamentoOpen
                        );
                      }}
                    >
                      <AiOutlinePlusCircle
                        style={{
                          color: "var(--color-verde)",
                          marginRight: "5px",
                          fontSize: "20px",
                        }}
                      />
                      Detalhamento
                    </button>
                  </>
                )}
              {!hasStatus &&
              mapeamento.layoutPlanilhaCamposEquivalente === "Situacao" ? (
                <>
                  <button
                    style={{
                      border: "none",
                      background: "transparent",
                      marginTop: "25px",
                      color: "var(--color-preto)",
                      cursor: "pointer",
                      padding: "0",
                      width: "130px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      actions.setIsModalStatusOpen(!isModalStatusOpen);
                    }}
                  >
                    <AiOutlinePlusCircle
                      style={{
                        color: "var(--color-verde)",
                        marginRight: "5px",
                        fontSize: "20px",
                      }}
                    />
                    Status
                  </button>
                </>
              ) : null}
            </div>
          </div>
        )}
        <MapeamentosList />
      </div>
      {isModalDetalhamentoOpen ? (
        <ModalDetalhamento
          isOpen={isModalDetalhamentoOpen}
          isEditable={hasDetalhamento}
          handleAddMapeamento={handleAddMapeamento}
        />
      ) : null}

      {isModalStatusOpen ? (
        <ModalStatus
          isOpen={isModalStatusOpen}
          isEditable={hasStatus}
          handleAddMapeamento={handleAddMapeamento}
        />
      ) : null}
    </Row>
  );
}
