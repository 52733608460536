import React from "react";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useSelector } from "react-redux";
import * as statusRcActions from "../../../store/modules/statusRibercred/actions";
import * as statusRcController from "../../../controllers/statusRcController";
import { removeAccents } from "../../../util/masks";
const Register = () => {

    const { item, copy, invalid, seeRegister } = useSelector(state => state.statusRc);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                statusRcController.create(item);
            } else {
                statusRcController.update(item, copy);
            };
        }
    }

    function onChangeItem(value, field) {
        statusRcActions.cleanInvalid(field);
        statusRcActions.item({ ...item, [field]: value });
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2" style={{margin: '25px 0 0 25px'}}>
                        <p style={{ fontSize: "18px", color:'var(--color-preto)' }}>Preencha o campo para cadastrar um novo Status Ribercred</p>
                    </Col>
                    <Col md={10} className="pt-2">
                        <Form>
                            <Row>
                                <Col md={6} style={{margin: '25px 0 0 25px'}}>
                                    <FormGroup>
                                        <Label for="hierarchy">Descrição do Status Ribercred</Label>
                                        <Input
                                            type="text"
                                            name="status"
                                            id="status"
                                            placeholder="Digite aqui..."
                                            value={item.descricao}
                                            onChange={e => onChangeItem(removeAccents(e.target.value), "descricao")}
                                            onKeyPress={e => handleKeyPress(e)}
                                            className={invalid.descricao ? "is-invalid" : ""}
                                            disabled={seeRegister ? true : false}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Card>
            </Col>
        </>
    );
}

export default Register;