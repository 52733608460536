import React from "react";
import { useSelector } from "react-redux";
import { Card, Col, FormGroup, Input, Label, Row } from "reactstrap";
import * as informacoesActions from "../../../../../../store/modules/personas/informacoes_store/actions";
import * as informacaoController from "../../../../../../controllers/personasController/informacoesController";
import CardItem from "./cardItem";
import * as AiIcons from "react-icons/ai";
import { InputCpfCnpj, Switch } from "../../../../../../components/Inputs/Input";
import { maskNumber, removeAccentsAndUppercase } from "../../../../../../util/masks";
import { notifyWarn } from "../../../../../../components/message/message";

const PessoaJuridica = () => {

    const { informacao, invalid, seeRegister, socios, serpro } = useSelector(state => state.personasInformacoes);

    function onChangeItem(value, field) {

        if (field === "isento" && value === true) {
            informacoesActions.cleanInvalid(field);
            informacoesActions.item({ ...informacao, [field]: value, inscricaoEstadual: "" });
        } else {
            informacoesActions.cleanInvalid(field);
            informacoesActions.item({ ...informacao, [field]: value });
        }
    }

    let baseSocio = {
        id: null,
        socio: "",
        pessoaSocioId: "0"
    };

    
    function addItem() {
        const arrSocios = socios;
        let err = false;
        if(arrSocios.length !== 0){
            arrSocios.forEach(element => {
                if (element.socio === "") {
                    err = true;
                }
            });
            if (!err) {
                informacoesActions.addSocio({ socio: baseSocio.socio })
            } else {
                notifyWarn("Necessário informar sócio");
                return;
            }
        }else{
            informacoesActions.addSocio({ socio: baseSocio.socio })
        }
    }

    return (
        <>
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <Label for="CNPJ" style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>CNPJ</Label>
                        <InputCpfCnpj
                            id="CNPJ"
                            tipo="cnpj"
                            value={informacao.cnpj}
                            placeholder="Digite aqui..."
                            onChange={e => onChangeItem(e.target.value, "cnpj")}
                            onBlur={e => informacao.cnpj.length === 18 && informacaoController.getSerpro(informacao.cnpj)}
                            autoComplete="off"
                            disabled={seeRegister ? true : (serpro && informacao.cnpj) || informacao.id !== null}
                            className={invalid.cnpj && "is-invalid"}
                            maxLength="18"
                        />
                    </FormGroup>
                </Col>
                <Col md={5}>
                    <FormGroup>
                        <Label for="razaoSocial" style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>Razão Social</Label>
                        <Input
                            type="text"
                            id="razaoSocial"
                            placeholder="Digite aqui..."
                            value={informacao.razaoSocial}
                            onChange={e => onChangeItem(e.target.value, "razaoSocial")}
                            className={invalid.razaoSocial && "is-invalid"}
                            disabled={seeRegister ? true : (serpro && informacao.razaoSocial !== '') && true}
                            maxLength="150"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <FormGroup>
                        <Label style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>Nome Fantasia</Label>
                        <Input
                            placeholder="Digite aqui..."
                            value={informacao.nomeFantasia}
                            onChange={e => onChangeItem(removeAccentsAndUppercase(e.target.value), "nomeFantasia")}
                            disabled={seeRegister}
                            className={invalid.nomeFantasia && "is-invalid"}
                            maxLength="100"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <FormGroup>
                        <Label for="dataCriacao" style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>Data da Criação</Label>
                        <Input
                            type="date"
                            id="dataCriacao"
                            max="9999-12-31"
                            value={informacao.dataCriacao}
                            onChange={e => onChangeItem(e.target.value, "dataCriacao")}                            
                            disabled={seeRegister ? true : serpro}
                            className={invalid.dataCriacao && "is-invalid"}
                            maxLength="10"
                        />
                    </FormGroup>
                </Col>
                <Col md={1}>
                    <FormGroup style={{display:'flex', justifyContent: 'center'}}>
                        <Switch
                            componentTop="Isento IE"
                            className="switch"
                            checked={informacao.isento}
                            onChange={() => onChangeItem(!informacao.isento, "isento")}
                            disabled={seeRegister}
                        />
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <Label for="inscricaoEstadual" style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>Inscrição Estadual</Label>
                    <Input
                        type="text"
                        id="inscricaoEstadual"
                        placeholder="Digite aqui..."
                        value={informacao.inscricaoEstadual}
                        onChange={e => onChangeItem(maskNumber(e.target.value), "inscricaoEstadual")}
                        className={invalid.inscricaoEstadual && "is-invalid"}
                        disabled={seeRegister ? true : informacao.isento}
                        maxLength={9}
                    />
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="inscricaoMunicipal" style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>Inscrição Municipal</Label>
                        <Input
                            type="text"
                            id="inscricaoMunicipal"
                            placeholder="Digite aqui..."
                            value={informacao.inscricaoMunicipal}
                            onChange={e => onChangeItem(maskNumber(e.target.value), "inscricaoMunicipal")}
                            className={invalid.inscricaoMunicipal && "is-invalid"}
                            disabled={seeRegister}
                            maxLength="11"
                        >
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <Card className="p-3 mb-3" style={{ backgroundColor: "var(--color-quase-branco)" }}>

                        {socios.map((element, index) => {
                            return <CardItem socio={element} index={index} />
                        })}
                        {!seeRegister &&
                            <Label className="mt-4">
                                <AiIcons.AiOutlinePlusCircle
                                    style={{ fontSize: "1.5rem", marginRight: "7px", color: "green", cursor: "pointer" }}
                                    onClick={() => addItem()}
                                    disabled={seeRegister ? true : serpro}
                                />
                                Novo sócio
                            </Label>
                        }
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default PessoaJuridica;