import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function item(value) {
    dispatch({
        type: TYPES.ESQUECEU_SENHA,
        value
    });
}

export function cleanItem() {
    dispatch({
        type: TYPES.CLEAN_ITEM,
    });
}

export function setInvalid(value, campo) {
    dispatch({
        type: TYPES.SET_INVALID,
        value,
        campo
    });

}

export function setLoadingEsqueceuSenha(value) {
    dispatch({
        type: TYPES.SET_LOADING_ESQUECEU_SENHA,
        value
    });
}