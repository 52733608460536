const STORE_ID = "PENDENCIA_INSTITUICAO";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    PENDENCIA: "PENDENCIA",
    PENDENCIA_COPY: "PENDENCIA_COPY",
    INVALID_PENDENCIA: "INVALID_PENDENCIA",
    PENDENCIAS: "PENDENCIAS",
    SET_PENDENCIA: "SET_PENDENCIA",
    BANCOS: "BANCOS",
    PENDENCIAS_RIBER: "PENDENCIAS_RIBER",
    CLEAN_INVALID_PENDENCIA: "CLEAN_INVALID_PENDENCIA",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE"
}

export { STORE_ID, TYPES }