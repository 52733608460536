export const COLUMNS = [
    {
        Header: 'Descrição',
        accessor: 'descricao',
    },
    {
        Header: 'Instituição Financeira',
        accessor: 'banco.nome',
    },
    {
        Header: 'Responsável por Ação',
        accessor: 'responsavelPorAcao.descricao',
    },
    {
        Header: 'Código Inconsistência',
        accessor: 'codigoConsistencia',
    }
]


