import React from 'react'
import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { COLUMNS_AGENDA } from "../constants/columns";
import * as BsIcons from "react-icons/bs";
import GlobalFilter from "../../../components/filter/filter";
import { Button, Modal, ModalBody, Row, Navbar, Col, Label, Input } from 'reactstrap'
import { useMemo } from 'react';
import { useState } from 'react';
import { aprovarCampanha, getById } from '../../../controllers/premiacaoController/campanhaPremiacao';
import * as actions from '../../../store/modules/campanhaPremiacao/campanha/actions'

const ModalAgenda = ({ isOpen, toggle }) => {
    const { item, seeRegister, periodicidades, bancos, convenios, tiposOperacao, tiposParceria, produtos, centrosCusto, tiposPendencia, periodos  } = useSelector(state => state.campanhaPremiacao)

    const columns = useMemo(() => COLUMNS_AGENDA, []);
    const data = item.campanhaPremiacaoAgenda;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
        setGlobalFilter,
        rows
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const { globalFilter, pageSize } = state;
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
    }
    
    async function handleConfirmar(){
        if(!item.hasAproved){
            await aprovarCampanha(item.id, data)
        }

        const content = await getById(item.id)

        let campanhaPremiacaoRegras = []
            content.campanhaPremiacaoRegras.forEach((regra)=>{
            let instituição = bancos.find((banco) => banco.id === regra.bancoId) ?? ''
            let convenio = convenios.find((convenio) => convenio.id === regra.convenioId) ?? '' 
            let tipoOperacao = tiposOperacao.find((operacao) => operacao.id === regra.tipoOperacaoId) ?? '' 
            let tipoParceria = tiposParceria.find((parceria) => parceria.id === regra.tipoParceriaId) ?? '' 
            let produto = produtos.find((produto) => produto.id === regra.produtoId) ?? '' 
            let centroCusto = centrosCusto.find((centroCusto) => centroCusto.id === regra.centroCustoId) ?? '' 

            campanhaPremiacaoRegras.push({
                ...regra,
                instituição : instituição.nome,
                convenio: convenio.descricao,
                tipoOperacao : tipoOperacao.descricao,
                tipoParceria: tipoParceria.nome,
                produto: produto.nome,
                centroCusto: centroCusto.descricao
            })
        })
        actions.setItem('campanhaPremiacaoRegras', campanhaPremiacaoRegras)

        let campanhaPremiacaoRegraQualidade = []
        content.campanhaPremiacaoRegraQualidade.forEach((regra)=>{
            let tipoPendencia = tiposPendencia.find((tipoPendencia) => tipoPendencia.id === regra.tipoPendenciaId) ?? ''
            let periodo = periodos.find((periodo) => periodo.id === regra.periodoId) ?? '' 

            campanhaPremiacaoRegraQualidade.push({
                ...regra,
                tipoPendencia: tipoPendencia.descricao,
                periodo: periodo.descricao,
            })
        })
        actions.setItem('campanhaPremiacaoRegraQualidade', campanhaPremiacaoRegraQualidade)

        let diaPagamento = periodicidades.find((periodo) => periodo.id === item.periodicidadePagamentoId)
        let diaApuracao = periodicidades.find((periodo) => periodo.id === item.periodicidadeApuracaoId)

        actions.setItem('descricaoLabel', diaPagamento.descricao)
        actions.setItem('descricaoLabelApuracao', diaApuracao.descricao)

        toggle()
    }

    function handleInitialPage() {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }

    const pageCount = Math.ceil(rows.length / pageSize);

    return (
        <div>
            <Modal
                className="text-center"
                isOpen={isOpen}
                toggle={toggle}
                backdrop={'static'}
                keyboard={false}
                size="lg"
            >
                <Navbar expand="md" style={{ width: "100%", height: "40px", background: 'var(--color-gradiente-verde)', marginBottom: '20px' }}>
                    <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2" >Agenda</h5>
                </Navbar>
                <ModalBody>
                <div style={{ width: "25%" }} className="pb-3">
                            <Label style={{ fontWeight: '700' }}>Pesquisar</Label>
                            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        </div>
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th>
                                                {column.render("Header")}
                                                <span className="float-right">
                                                    {column.isSorted ?
                                                        (column.isSortedDesc ?
                                                            <BsIcons.BsArrowDown
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />
                                                            :
                                                            <BsIcons.BsArrowUp
                                                                id="OrdenarAsc"
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />)
                                                        :
                                                        <BsIcons.BsArrowDownUp
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />}
                                                </span>
                                            </th>
                                        ))}                                    
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr className="text-center" {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                            })}                                          
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Row className="pt-4">
                            <Col>
                            <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {page.length} de {item.campanhaPremiacaoAgenda.length}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { setPageSize(Number(e.target.value)); handleInitialPage() }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>
                </ModalBody>
                <div className="m-3">
                    <Button
                        onClick={()=> toggle()}
                        className="mr-4"
                        style={{
                            width: "150px",
                            background: "var(--color-branco)",
                            borderColor: "var(--color-laranja)",
                            color: "var(--color-laranja)",
                            borderRadius: "7px"
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={(e) => handleConfirmar()}
                        color="danger"
                        className="purpleButton"
                        style={{ width: "150px" }}
                        disabled={seeRegister}
                    >
                        Confirmar
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export default ModalAgenda