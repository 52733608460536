import React from 'react'
import {useSelector} from 'react-redux';
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import * as actions from '../../../../../../store/modules/personas/instituicaoFinanceira/actions'
import '../../../../styles/papeis.css'
import * as instituicaoFinanceiraController
    from '../../../../../../controllers/personasController/instituicaoFinanceiraController'

export default function InstituicaoFinanceira() {
    const {instituicaoFinanceira} = useSelector(
        (state) => state.instituicaoFinanceira
    );

    const {informacao, seeRegister} = useSelector(state => state.personasInformacoes);
    const onChange = (value) => {
        actions.setInstituicoesFinanceiras(value);
    };

    async function salveInstituicaoFinanceira(e) {
        e.preventDefault();
        let isSuccess = await
            instituicaoFinanceiraController.registraCodClienteW3ERP(informacao.id, instituicaoFinanceira);
        if (isSuccess.valid) {
            actions.setInstituicoesFinanceiras(instituicaoFinanceira)
        }
    }

    return (
        <>
            <Form style={{margin: '30px 0px 0px 0px'}}>
                <Form className="m-2">
                    <Row style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                        <Col md={2}>
                            <FormGroup>
                                <Label>Id Cliente W3ERP</Label>
                                <Input
                                    type="text"
                                    placeholder="Digite aqui..."
                                    value={instituicaoFinanceira}
                                    maxLength="16"
                                    onChange={(e) => onChange(e.target.value)}
                                    disabled={seeRegister}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2} style={{padding: '10px 0px 0px 10px'}}>
                            {!seeRegister &&
                                <Button
                                    style={{width: '210px'}}
                                    className="purpleButton"
                                    onClick={(e) => salveInstituicaoFinanceira(e)}
                                >
                                    Salvar
                                </Button>
                            }
                        </Col>
                    </Row>
                </Form>
            </Form>
        </>
    )
}
