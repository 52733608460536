import React, { useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { Card, Col, Collapse, Input, Label, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import * as BsIcons from "react-icons/bs";

import { COLUMNS_CONTAS } from "../constants/columns";

import GlobalFilter from "../../../components/filter/filter";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

const ContasReceber = () => {
    const { item:{ contasReceber } } = useSelector(state => state.propostas);
    const columns = useMemo(() => COLUMNS_CONTAS, []);
    const data = contasReceber;
    const {
        gotoPage,
        state,
        setPageSize,
        setGlobalFilter,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const { globalFilter, pageSize } = state;
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);


    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
    }

    function handleInitialPage() {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }

    const pageCount = Math.ceil(data.length / pageSize);
    return (
        <>
            <Col>
                <Card className="pt-3 pb-2 mb-5">
                    <Col>
                        <div style={{ width: "25%" }} className="pb-3">
                            <Label>Pesquisar</Label>
                            <GlobalFilter filter={globalFilter}
                                setFilter={setGlobalFilter} />
                        </div>
                        <div style={{display: 'flex', width: '100%'}}>
                            <div style={{height: '40px', background:'var(--color-roxo)', color:'white', width: '2%'}}></div>
                            <div style={{ fontWeight: 'bold', padding: '10px 1px 5px 14px', height: '40px', background:'var(--color-roxo)', color:'white', width: '25%'}}>
                                Tipo Comissão
                            </div>
                            <div style={{ fontWeight: 'bold', padding: '10px 1px 5px 14px', height: '40px', background:'var(--color-roxo)', color:'white', width: '25%'}}>
                                Valor
                            </div>
                            <div style={{ fontWeight: 'bold', padding: '10px 1px 5px 14px', height: '40px', background:'var(--color-roxo)', color:'white', width: '25%'}}>
                               Parcelas
                            </div>
                            <div style={{ fontWeight: 'bold', padding: '10px 1px 5px 14px', height: '40px', background:'var(--color-roxo)', color:'white', width: '25%'}}>
                                Data Vencimento
                            </div>                         
                        </div>
                        {
                            contasReceber.map((e,i)=>(
                                <CollapsedRow conta={e} index={i}/>
                            ))
                        }
                        <Row className="pt-4">
                            <Col md={12}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        activeClassName={"active"}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { setPageSize(Number(e.target.value)); handleInitialPage() }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Card >
            </Col >
        </>
    );
}


export default ContasReceber;

function CollapsedRow({conta, index}){
    const [isRowOpen, setIsRowOpen] = useState(false)

    const columns = useMemo(() => COLUMNS_CONTAS, []);
    const data = conta.contasReceberDivisao;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    },
        useSortBy);

    function handleActiveRow(){
        setIsRowOpen(!isRowOpen)
    }
    return(
        <div>
            <div style={{display: 'flex'}} >
                <div 
                style={{padding: '7px',width: '2%', minHeight: '29px', borderBottom: '1px solid #CBD0D1', cursor: 'pointer'}}
                onClick={()=> handleActiveRow()}>
                    {data && (isRowOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine/>)}
                </div>
                <div style={{padding: '7px', width: '25%', minHeight: '29px', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                    {conta.tipoComissao}
                </div>
                <div style={{padding: '7px', width: '25%', minHeight: '29px', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                    {conta.valor}
                </div>
                <div style={{padding: '7px', width: '25%', minHeight: '29px', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                    {conta.parcela}
                </div>
                <div style={{padding: '7px', width: '25%', minHeight: '29px', borderBottom: '1px solid #CBD0D1', color: '#81888A'}}>
                    {conta.dataVencimento}
                </div>
            </div>
            <Collapse isOpen={isRowOpen}>
                <table style={{ width: '100%'}} {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                <th >
                                {column.render("Header")}
                                <span className="float-right">
                                    {column.isSorted ?
                                        (column.isSortedDesc ?
                                            <BsIcons.BsArrowDown
                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                            />
                                            :
                                            <BsIcons.BsArrowUp
                                                id="OrdenarAsc"
                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                            />)
                                        :
                                        <BsIcons.BsArrowDownUp
                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                        />}
                                </span>
                            </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)
                            return (
                                <tr className="text-center" {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Collapse>
        </div>
    )
}