import { STORE_ID, TYPES } from "./types";
import store from "../../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data)
}

export function setInstituicoesFinanceiras(value) {
    dispatch({
        type: TYPES.SET_INSTITUICOES_FINANCEIRAS_COD_W3ERP,
        value,
    })
}

export function cleanInstituicoesFinanceiras(){
    dispatch({
        type: TYPES.CLEAN_INSTITUICOES_FINANCEIRAS_COD_W3ERP
    })
}
