import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    item: {
        recuperacaoEmail: "",
        recuperacaoSenha: "",
        novaSenha: "",
        confirmaSenha: ""
    },
    copy: {},
    invalid: {
        recuperacaoEmail: false,
        recuperacaoSenha: false,
        novaSenha: false,
        confirmaSenha: false
    },
    loadingEsqueceuSenha: false,
}

export default function convenio(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.ESQUECEU_SENHA:
                    return { ...state, item: action.value }
                case TYPES.CLEAN_ITEM:
                    return { ...state, item: INITIAL_STATE.item }
                case TYPES.SET_INVALID:
                    return { ...state, invalid: { ...state.invalid, [action.campo]: action.value } }
                case TYPES.SET_LOADING_ESQUECEU_SENHA:
                    return { ...state, loadingEsqueceuSenha: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}