import React from "react";
import { useSelector } from "react-redux";
import { Col,  TabContent, TabPane } from "reactstrap";
import Search from "./tabs/search";

import Breadcrumb from '../../components/breadcrumbs';

import '../../style.css'

export default function  Aceite() {
  const { activeTab } = useSelector((state) => state.aceiteCp)

  return (
    <>
      <Col>
        <Breadcrumb activeTab={activeTab} />
      </Col>
      <TabContent style={{ width: '100%' }} activeTab={activeTab}>
        <TabPane tabId='Search'>
          <br />
          <Search />
        </TabPane>
      </TabContent>
    </>
  )
}