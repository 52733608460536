import { STORE_ID, TYPES } from "./types";
import store from "../../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setFiltros(campo, value) {
    dispatch({
        type: TYPES.SET_FILTROS,
        campo,
        value
    });
}

export function setModalLojas(campo, value) {
    dispatch({
        type: TYPES.SET_MODAL_LOJAS,
        campo,
        value
    });
}

export function setModalConsultores(campo, value) {
    dispatch({
        type: TYPES.SET_MODAL_CONSULTORES,
        campo,
        value
    });
}

export function setBigNumbers(value) {
    dispatch({
        type: TYPES.SET_BIG_NUMBERS,
        value
    });
}

export function setBigNumbersCancelados(value) {
    dispatch({
        type: TYPES.SET_BIG_NUMBERS_CANCELADOS,
        value
    });
}

export function setTabelaEsteiraProducao(value) {
    dispatch({
        type: TYPES.SET_TABELA_ESTEIRA_PRODUCAO,
        value
    });
}

export function setTabelaEsteiraProducaoCancelados(value) {
    dispatch({
        type: TYPES.SET_TABELA_ESTEIRA_PRODUCAO_CANCELADOS,
        value
    });
}

export function setLojas(value) {
    dispatch({
        type: TYPES.SET_LOJAS,
        value
    });
}

export function setGetAllLojas(value) {
    dispatch({
        type: TYPES.SET_GET_ALL_LOJAS,
        value
    });
}

export function setGetAllConsultores(value) {
    dispatch({
        type: TYPES.SET_GET_ALL_CONSULTORES,
        value
    });
}

export function setInsituicoes(value) {
    dispatch({
        type: TYPES.SET_INSTITUICOES,
        value
    });
}

export function setProdutosRiber(value) {
    dispatch({
        type: TYPES.SET_PRODUTOS_RIBER,
        value
    });
}

export function setTiposOperacao(value) {
    dispatch({
        type: TYPES.SET_TIPOS_OPERACAO,
        value
    });
}

export function setCanais(value) {
    dispatch({
        type: TYPES.SET_CANAIS,
        value
    });
}

export function setHierarquias(value) {
    dispatch({
        type: TYPES.SET_HIERARQUIAS,
        value
    });
}

export function setParcerias(value) {
    dispatch({
        type: TYPES.SET_PARCERIAS,
        value
    });
}

export function setConvenios(value) {
    dispatch({
        type: TYPES.SET_CONVENIOS,
        value
    })
}

export function setGrupos(value) {
    dispatch({
        type: TYPES.SET_GRUPOS,
        value
    })
}

export function setConsultores(value) {
    dispatch({
        type: TYPES.SET_CONSULTORES,
        value
    })
}

export function cleanFiltros() {
    dispatch({
        type: TYPES.CLEAN_FILTROS
    })
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setFiltrosSelecionados(value) {
    dispatch({
        type: TYPES.SET_FILTROS_SELECIONADOS,
        value
    })
}

export function setLoadingLojas(value) {
    dispatch({
        type: TYPES.SET_LOADING_LOJAS,
        value
    });
}

export function setLoadingConsultores(value) {
    dispatch({
        type: TYPES.SET_LOADING_CONSULTORES,
        value
    });
}