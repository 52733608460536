import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    isLoading: false,
}

export default function convenio(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_LOADING:
                    return { ...state, isLoading: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}