import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    item: {
        descricao: "",
        id: "",
        geraParcela: false,
        tipoComissaoBanco: []
    },
    copy: {},
    invalid: {
        descricao: false,
        bancoId: false,
        unidadeComissaoId: false,
        periodicidadeId: false,
        baseCalculoId: false
    },
    tipoComissaoBanco: {
        bancoId: "0",
        produtoBancoId: '0',
        unidadeComissaoId: "0",
        periodicidadeId: "0",
        baseCalculoId: "0",
    },
    items: [],
    instituicoes: [],
    allProdutosBanco: [],
    produtosBanco: [],
    produtosBancoII: [],
    basesCalculo: [],
    periodicidades: [],
    unidadeComissoes: [],
    tiposComissoes: [],
    activeTab: "Search",
    seeRegister: false,
    permitions: {
        criar: false,
        deletar: false,
        autorizar: false,
    },
    filtros: {
        produtoBancoId: '0',
        instituicaoId: '0'
    }
}

export default function tipoComissao(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.COMISSOES:
                    return { ...state, items: action.value }
                case TYPES.SET_COMISSAO:
                    return { ...state, item: {...state.item, [action.field]: action.value} }
                case TYPES.SET_INSTITUICOES:
                    return { ...state, instituicoes: action.value }
                case TYPES.SET_PRODUTOS_BANCO:
                    return { ...state, produtosBanco: action.value }
                case TYPES.SET_ALL_PRODUTOS_BANCO:
                    return { ...state, allProdutosBanco: action.value }
                case TYPES.SET_PRODUTOS_BANCO_II:
                    return { ...state, produtosBancoII: action.value }
                case TYPES.BASES_CALCULOS:
                    return { ...state, basesCalculo: action.value }
                case TYPES.PERIODICIDADES:
                    return { ...state, periodicidades: action.value }
                case TYPES.TIPOS_COMISSOES:
                    return { ...state, tiposComissoes: action.value }
                case TYPES.UNIDADE_COMISSAO:
                    return { ...state, unidadeComissoes: action.value }
                case TYPES.COMISSAO:
                    return { ...state, item: action.value }
                case TYPES.INVALID_COMISSAO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_COMISSAO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.COMISSAO_COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state, item: {
                            descricao: "",
                            tipoComissaoBanco: [],
                            id: "",
                            geraParcela: false,
                        }, 
                        invalid: INITIAL_STATE.invalid,
                        produtosBanco: INITIAL_STATE.produtosBanco,
                        filtros: INITIAL_STATE.filtros,
                        tipoComissaoBanco: INITIAL_STATE.tipoComissaoBanco
                    }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                case TYPES.SET_TIPO_COMISSAO_BANCO:
                    return { ...state, tipoComissaoBanco: {...state.tipoComissaoBanco, [action.field]: action.value} }
                case TYPES.CLEAN_TIPO_COMISSAO_BANCO:
                    return { ...state, tipoComissaoBanco: INITIAL_STATE.tipoComissaoBanco }
                case TYPES.ADD_TIPO_COMISSAO_BANCO:
                    return {
                        ...state,
                        item: {
                            ...state.item,
                            tipoComissaoBanco: [...state.item.tipoComissaoBanco, action.value]
                        }
                    }
                case TYPES.REMOVE_TIPO_COMISSAO_BANCO:
                    let tiposComissaoBanco = [...state.item.tipoComissaoBanco]
                    tiposComissaoBanco.splice(action.index, 1)
                    return {
                        ...state,
                        item: { ...state.item, tipoComissaoBanco: tiposComissaoBanco }
                    }
                case TYPES.CLEAN_PRODUTOS_BANCO:
                    return {
                        ...state,
                        produtosBanco: INITIAL_STATE.produtosBanco
                    }
                case TYPES.SET_FILTROS:
                    return {
                        ...state,
                        filtros: { 
                            ...state.filtros,
                            [action.field]: action.value
                        }
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}