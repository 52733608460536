import React, { useEffect, useMemo, useState } from 'react'
import { usePagination, useTable } from 'react-table';
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Modal, ModalBody, Col, Button, Input, Label, Row, Navbar, FormGroup } from "reactstrap";

import { InputCpfCnpj, Select } from '../../../components/Inputs/Input'
import LoadingIcon from '../../../components/Loading/LoadingIcon'

import * as actions from '../../../store/modules/aceiteCp/actions';
import * as controller from '../../../controllers/aceiteCpController';

import { maskCPF } from '../../../util/masks';


export default function ModalPessoas({ isOpen, toggle, isSearch }) {
    const { modalConsultores, consultores, getAllConsultores, hierarquias, canais, loadingConsultores } = useSelector(state => state.aceiteCp);

    const columns = useMemo(() => [{
        Header: "CPF"
    },{
        Header: "NOME",
    },], []); 
    const data = consultores

    const {
        page,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
       columns,
       data
    },
        usePagination);

    const { pageSize } = state;
    const [rows, setRows] = useState(0);
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    useEffect(() => {
        setRows(page.length);
    }, [page])

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);

        controller.getPessoas(modalConsultores, pageSelected+1, pageSize)
    }

    function handleInitialPage() {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }

    function onChangeItem(value, campo) {
        if(isSearch){
            // actions.search(campo, value)
        }else{
            actions.item(campo, value)
        }
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            controller.getPessoas(modalConsultores, 1, pageSize);
        }
    }

    useEffect(()=>{
        if(modalConsultores.canalId !== '0'){
            controller.getHierarquia(modalConsultores.canalId)
        }else{
            actions.setModalConsultores('pessoaId', '0')
            actions.setHierarquias([])
        }
    },[modalConsultores.canalId])
    return (
        <>
            <Modal
                className="text-center"
                isOpen={isOpen}
                toggle={toggle}
                backdrop={true}
                size="lg"
            >
                <Navbar expand="md" style={{ width: "100%", height: "40px", background: 'var(--color-gradiente-verde)', marginBottom: '20px' }}>
                    <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2" >Pesquisa de Pessoas</h5>
                </Navbar>
                <ModalBody >
                    <Row>
                        <Col md={4}>
                            <Label >CPF</Label>
                            <InputCpfCnpj
                                type="text"
                                placeholder="Digite aqui..."
                                tipo="cpf"
                                value={modalConsultores.cpf}
                                onChange={e => actions.setModalConsultores('cpf', e.target.value)}
                                onKeyPress={e => handleKeyPress(e)}
                                maxLength="18"
                            />
                        </Col>
                        <Col>
                            <FormGroup>
                            <Label >Nome</Label>
                            <Input
                                type="text"
                                placeholder="Digite aqui..."
                                value={modalConsultores.nome}
                                onChange={e => actions.setModalConsultores('nome', e.target.value)}
                                onKeyPress={e => handleKeyPress(e)}
                            />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup style={{display: 'flex', flexDirection: 'column'}}>
                                <Label >Canal</Label>
                                <Select
                                    first='Selecione'
                                    value={{ value: modalConsultores.canalId }}
                                    onChange={e => actions.setModalConsultores('canalId', e.value)}
                                    select={{ value: 'id', label: 'nome' }}
                                    options={canais}
                                    isSearchable
                                />
                            </FormGroup>
                         </Col>
                    </Row>
                    <fieldset className={consultores.length > 0 ? "fieldset-socio" : "fieldset-socio-no-results"}>
                        <legend style={{ textAlign: "left", fontSize: "20px" }}>Resultados</legend>
                        { loadingConsultores &&
                            <LoadingIcon />
                        }
                        <table>
                            <tr>
                                <th style={{ width: "150px" }}>CPF</th>
                                <th>Nome</th>
                            </tr>
                            {consultores && consultores.map(row => {
                                return (
                                    <tr onDoubleClick={() => { onChangeItem(row.nome, 'pessoa'); onChangeItem(row.id, 'pessoaId'); toggle() }}>
                                        <td index={row.id}>{maskCPF(row.cpfCnpj)}</td>
                                        <td index={row.id}>{row.nome}</td>
                                    </tr>
                                )
                            })}

                        </table>
                        <br />
                    </fieldset>
                    {consultores && <Row className="pt-4">
                            <Col md={3}>
                                <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {rows} de {getAllConsultores.quantidadeTotal}</span>
                            </Col>
                            <Col md={8} className="float-right">
                                <Row >
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={getAllConsultores.totalPaginas}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { setPageSize(Number(e.target.value)); handleInitialPage() }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>}
                </ModalBody>
                <div className="m-3">
                    <Button
                        onClick={() =>{}}
                        className="mr-4"
                        style={{
                            width: "150px",
                            background: "var(--color-branco)",
                            borderColor: "var(--color-laranja)",
                            color: "var(--color-laranja)",
                            borderRadius: "7px"
                        }}
                    >
                        Limpar
                    </Button>
                    <Button
                        onClick={() => controller.getPessoas(modalConsultores,1, pageSize)}
                        color="danger"
                        className="purpleButton"
                        style={{ width: "150px" }}
                    >
                        Pesquisar
                    </Button>
                </div>
            </Modal>
        </>
    )
}