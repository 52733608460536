import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as hierarquiaActions from "../store/modules/hierarquia/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("hierarquias");
    setLoading(false)

    if (!data.success) {
        return;
    }

    hierarquiaActions.getItems(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`hierarquias/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    const newContent =
            {
                id: data.content.id,
                descricao: data.content.descricao,
                canalId: data.content.canal.id,
                headCount: data.content.headCount,
                multiplosCentroCusto: data.content.multiplosCentroCusto,
                isMultiplosCentrosCustoChecked: false,
                consultor: data.content.consultor,
                isConsultorChecked: false,
            }

    hierarquiaActions.copy(newContent);
    hierarquiaActions.item(newContent);
}

export async function create(item) {

    if (item.descricao.trim() === "") {
        hierarquiaActions.setInvalid("descricao");
        notifyWarn("Descrição da hierarquia não pode estar em branco");
        return;
    }

    if (item.canalId === "0") {
        hierarquiaActions.setInvalid("canalId");
        notifyWarn("Canal de venda não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao,
        headCount: item.headCount,
        canalId: item.canalId,
        multiplosCentroCusto: item.multiplosCentroCusto,
        ordem: 99,
        consultor: item.consultor
    }

    setLoading(true)
    const data = await api.post("hierarquias", body);
    setLoading(false)

    if (!data.success) {
        return false;
    }

    notifySuccess("Hierarquia cadastrada");
    return true
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`hierarquias/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Hierarquia removida");
    get();
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (item.descricao.trim() === "") {
        hierarquiaActions.setInvalid("descricao");
        notifyWarn("Descrição da hierarquia não pode estar em branco");
        return;
    }

    if (item.canalId === "0") {
        hierarquiaActions.setInvalid("canalId");
        notifyWarn("Canal de venda não pode estar em branco");
        return;
    }

    const body = {
        id: item.id,
        descricao: item.descricao,
        headCount: item.headCount,
        canalId: item.canalId,
        multiplosCentroCusto: item.multiplosCentroCusto,
        consultor: item.consultor
    }

    setLoading(true)
    const data = await api.put(`hierarquias/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return false;
    }

    notifySuccess("Hierarquia atualizada");
    return true
}

export async function getCanaisVenda() {

    setLoading(true)
    const data = await api.get("Canal");
    setLoading(false)

    if (!data.success) {
        return;
    }

    hierarquiaActions.getCanaisVenda(data.content);
}

export async function getNiveisHierarquicos(canalId) {

    setLoading(true)
    const data = await api.get(`Hierarquias/hierarquia-canal/${canalId}`);
    setLoading(false)

    if (!data.success) {
        return false;
    }

    hierarquiaActions.setNiveisHierarquicos(data.content);
    return true
}

export async function updateNiveis(item) {

    const body = item.map((hierarquia)=>{
        return(
            {
                id: hierarquia.id,
                ordem: hierarquia.ordem
            }
        )
    })

    setLoading(true)
    const data = await api.put(`hierarquias/atualiza-ordem`, body);
    setLoading(false)

    if (!data.success) {
        return false;
    }

    notifySuccess("Niveis atualizados");
    get()
    return true
}
