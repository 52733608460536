import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import * as usuarioController from "../../../controllers/usuarioController";
import * as actions from "../../../store/modules/usuario/actions";
import { useSelector } from "react-redux";

const ModalUnblockUser = ({ isOpen, toggle, itemId }) => {
    const item = useSelector((state) => state.usuario);

    async function handleUnblockUser(id) {
        console.log("Tentando desbloquear o usuário com ID:", id); 
        if (id) {
            try {
                const data = await usuarioController.unblockUser(id);
                if (data) {
                    actions.cleanRegister();
                }
            } catch (error) {
                console.error("Erro ao desbloquear o usuário:", error);
            }
        } else {
            console.error("ID do usuário não encontrado.");
        }
    }

    return (
        <Modal
            className="text-center"
            isOpen={isOpen}
            toggle={toggle}
            backdrop={false}
        >
            <ModalHeader toggle={toggle} style={{ height: "10px", border: "none" }} />
            <ModalBody>
                <p style={{ fontSize: "18px", color: "var(--color-preto)" }}>
                    Tem certeza que deseja{" "}
                    <strong style={{ fontSize: "17px" }}>desbloquear</strong> esse
                    usuário?
                </p>
            </ModalBody>
            <div className="m-3">
                <Button
                    onClick={toggle}
                    color="danger"
                    style={{ width: "100px", marginRight: "15px" }}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        handleUnblockUser(itemId); 
                        toggle();
                    }}
                    style={{
                        width: "100px",
                        marginLeft: "15px",
                        background: "var(--color-verde)",
                        border: "none",
                    }}
                >
                    Confirmar
                </Button>
            </div>
        </Modal>
    );
};

export default ModalUnblockUser;
