import * as BiIcons from "react-icons/bi";

import { Col, Label, Row, Input } from "reactstrap";
import { InputAdornment, OutlinedInput } from "@material-ui/core";

import { useSelector } from "react-redux";

import * as actions from "../../../../store/modules/perfilAcesso/actions";

import LoadingIcon from "../../../../components/Loading/LoadingIcon";

export function TelasDisponiveisList() {
  const {
    telasSelecionadas,
    telasDisponiveis,
    activeTab,
    telasDisponiveisSearchTerm,
    telasDisponiveisSearchResults,
    telasDisponiveisSelectedSearchResults,

    loadingTelas,
  } = useSelector((state) => state.perfilAcesso);

  const telas =
    telasDisponiveisSearchTerm?.length > 0
      ? telasDisponiveisSearchResults
      : telasDisponiveis;

  const isAllSearchResultsSelected =
    telasDisponiveisSearchResults.length > 0 &&
    telasDisponiveisSearchResults.length ===
      telasDisponiveisSelectedSearchResults.length;

  const isAllSelected =
    telasDisponiveisSearchTerm.length > 0
      ? isAllSearchResultsSelected
      : telasSelecionadas.length > 0 &&
        telasSelecionadas.length === telasDisponiveis.length;

  const isTelasDisponiveisEmpty = telasDisponiveis?.length === 0;

  const isSelectAllVisible =
    telasDisponiveisSearchTerm.length > 0
      ? telasDisponiveisSearchResults.length > 0
      : !(isTelasDisponiveisEmpty || activeTab === "Visualize");

  function handleSearchTelasDisponiveis(inputValue) {
    actions.setTelasDisponiveisSearchTerm(inputValue);

    if (inputValue.trim() === "") {
      actions.setTelasDisponiveisSearchTerm("");
      actions.setTelasDisponiveisSearchResults([]);
      return;
    }

    const searchTermLowercase = inputValue.toLowerCase();

    const telasFiltradas = telasDisponiveis.filter((tela) =>
      tela.nomeTela.toLowerCase().includes(searchTermLowercase)
    );

    actions.setTelasDisponiveisSearchResults(telasFiltradas);

    actions.setTelasDisponiveisSelectedSearchResults(
      telasFiltradas.filter((telaDisponivel) =>
        telasSelecionadas.some(
          (telaSelecionada) => telaSelecionada.id === telaDisponivel.id
        )
      )
    );
  }

  function handleSelecionarTelaDisponivel(telaId) {
    const telaSelecionada = telasDisponiveis.find((tela) => tela.id === telaId);

    if (telaSelecionada !== undefined) {
      actions.setTelasSelecionadas([...telasSelecionadas, telaSelecionada]);
    }

    if (telasDisponiveisSearchTerm.length > 0) {
      actions.setTelasDisponiveisSelectedSearchResults([
        ...telasDisponiveisSelectedSearchResults,
        telaSelecionada,
      ]);
    }
  }

  function handleDeselecionarTelaDisponivel(telaId) {
    actions.setTelasSelecionadas(
      [...telasSelecionadas].filter((tela) => tela.id !== telaId)
    );

    if (telasDisponiveisSearchTerm.length > 0) {
      actions.setTelasDisponiveisSelectedSearchResults(
        [...telasDisponiveisSelectedSearchResults].filter(
          (tela) => tela.id !== telaId
        )
      );
    }
  }

  function handleSelectAllTelasDisponiveis() {
    if (telasDisponiveisSearchTerm.length > 0) {
      const searchResultsSelected = telasDisponiveisSearchResults.filter(
        (telaDisponivel) =>
          telasSelecionadas.some(
            (telaSelecionada) => telaSelecionada.id === telaDisponivel.id
          )
      );

      const isAllSearchResultsSelectedd =
        searchResultsSelected.length > 0 &&
        searchResultsSelected.length === telasDisponiveisSearchResults.length;

      const telasSelecionadasWithoutSelectedSearchResultsItems =
        telasSelecionadas.filter(
          (tela) =>
            !searchResultsSelected.some(
              (telaSelecionada) => telaSelecionada.id === tela.id
            )
        );

      if (isAllSearchResultsSelectedd) {
        actions.setTelasSelecionadas(
          telasSelecionadasWithoutSelectedSearchResultsItems
        );

        actions.setTelasDisponiveisSelectedSearchResults([]);
      } else {
        actions.setTelasSelecionadas([
          ...telasSelecionadasWithoutSelectedSearchResultsItems,
          ...telasDisponiveisSearchResults,
        ]);

        actions.setTelasDisponiveisSelectedSearchResults(
          telasDisponiveisSearchResults
        );
      }

      return;
    }

    if (isAllSelected) {
      actions.setTelasSelecionadas([]);
    } else {
      actions.setTelasSelecionadas([...telasDisponiveis]);
    }
  }

  function checkIfTelaDisponivelIsSelected(telaId) {
    const isTelaSelecionada = telasSelecionadas.some(
      (tela) => tela.id === telaId
    );

    return isTelaSelecionada;
  }

  return (
    <Col md={5}>
      <Label
        style={{
          fontWeight: "700",
          color: "var(--color-preto)",
          fontSize: "17px",
        }}
      >
        Telas Disponíveis
      </Label>
      <OutlinedInput
        className="input-default mb-2"
        placeholder="Procurar"
        value={telasDisponiveisSearchTerm}
        onChange={(e) => {
          handleSearchTelasDisponiveis(e.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            <BiIcons.BiSearchAlt></BiIcons.BiSearchAlt>
          </InputAdornment>
        }
      />
      <div
        style={{
          border: "1px solid #B5B5B5",
          borderRadius: "10px",
          height: "350px",
          overflow: "auto",
        }}
      >
        {isSelectAllVisible ? (
          <Row
            style={{
              flexWrap: "nowrap",
              margin: "15px 15px 0 15px",
              height: "35px",
              borderBottom: "1px solid #B5B5B5",
            }}
          >
            <Col md={1}>
              <Input
                type="checkbox"
                style={{display: 'flex'}}
                checked={isAllSelected}
                onChange={() => handleSelectAllTelasDisponiveis()}
              />
            </Col>
            <Col>
              <p
                style={{
                  whiteSpace: "nowrap",
                  color: "var(--color-preto)",
                }}
              >
                Selecionar todos
              </p>
            </Col>
          </Row>
        ) : null}
        {loadingTelas && <LoadingIcon color="var(--color-roxo)" />}
        {telas
          ?.sort((a, b) => a.nomeTela.localeCompare(b.nomeTela))
          .map((tela, i) => {
            return (
              <Row
                key={tela.id}
                style={{
                  flexWrap: "nowrap",
                  margin: "15px 15px 0 15px",
                  height: "35px",
                  borderBottom: "1px solid #B5B5B5",
                }}
              >
                {!(activeTab === "Visualize") && (
                  <Col md={1}>
                    <Input
                      key={tela.id}
                      type="checkbox"
                      style={{display: 'flex'}}
                      checked={checkIfTelaDisponivelIsSelected(tela.id)}
                      onChange={() => {
                        checkIfTelaDisponivelIsSelected(tela.id)
                          ? handleDeselecionarTelaDisponivel(tela.id)
                          : handleSelecionarTelaDisponivel(tela.id);
                      }}
                    />
                  </Col>
                )}
                <Col>
                  <p
                    style={{
                      whiteSpace: "nowrap",
                      color: "var(--color-preto)",
                    }}
                  >
                    {tela.nomeTela}
                  </p>
                </Col>
              </Row>
            );
          })}
      </div>
    </Col>
  );
}
