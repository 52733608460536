import React from 'react';
import { useSelector } from "react-redux";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import PessoaFisica from './components/pessoaFisica';
import PessoaJuridica from './components/pessoaJuridica';
import * as informacoesActions from "../../../../../store/modules/personas/informacoes_store/actions";
import * as papeisActions from "../../../../../store/modules/personas/papeis_store/actions";
import * as dadosBancariosActions from '../../../../../store/modules/personas/conta_corrente_store/actions'

const Informacoes = () => {

    const { isJuridica, seeRegister } = useSelector(state => state.personasInformacoes);

    function changeTab(tab) {
        informacoesActions.cleanRegister();
        papeisActions.cleanRegister();
        dadosBancariosActions.cleanRegister();

        informacoesActions.setActivePersona(tab);
    }

    return (
        <>
            <Col className="pt-2" style={{ margin: '25px 0 0 25px' }}>
                <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>Preencha os campos para cadastrar uma nova Persona</p>
            </Col>
            <Form style={{ margin: '25px 0 0 25px' }}>
                <Col>
                    <FormGroup tag="fieldset">
                        <p style={{ fontSize: "13px", fontWeight: '700', marginBottom: '25px' }}>Tipo de Persona</p>
                        <Col>
                            <Row>
                                <FormGroup check className="mr-2">
                                    <Label check style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>
                                        <Input
                                            type="radio"
                                            name="radio1"
                                            checked={!isJuridica}
                                            onClick={() => changeTab(!isJuridica)}
                                            disabled={seeRegister}
                                        />
                                        {' '}
                                        Pessoa Física
                                    </Label>
                                </FormGroup>
                                <FormGroup check className="ml-2">
                                    <Label check style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>
                                        <Input
                                            type="radio"
                                            name="radio1"
                                            checked={isJuridica}
                                            onClick={() => changeTab(!isJuridica)}
                                            disabled={seeRegister}
                                        />
                                        {' '}
                                        Pessoa Jurídica
                                    </Label>
                                </FormGroup>
                            </Row>
                        </Col>
                    </FormGroup>
                    {
                        !isJuridica ?
                            <PessoaFisica />
                            :
                            <PessoaJuridica />
                    }
                </Col>
            </Form>
        </>
    );
}

export default Informacoes;