import React, { useEffect } from "react";
import { IoIosArrowBack } from 'react-icons/io';
import "../../style.css";
import { Card, Col, Form, Row, Button, TabContent, TabPane } from "reactstrap";
import Search from "./tabs/search";
import Register from "./tabs/register";
import { useSelector } from "react-redux";
import * as perfilActions from "../../store/modules/perfilCliente/actions";
import * as perfilController from "../../controllers/perfilClienteController";
import history from "../../util/history";
import Breadcrumb from "../../components/breadcrumbs";

const PerfilCliente = () => {

    const { item, copy, activeTab, seeRegister, activePersona } = useSelector(state => state.perfilCliente);

    useEffect(() => {
        perfilActions.setActiveTab("Search")
    }, [])

    function saveItem() {
        if (item.id === "") {
            perfilController.create(item);
        } else {
            perfilController.update(item, copy);
        }
    }

    function changeTab(tab) {
        perfilActions.setActiveTab(tab);
        perfilActions.cleanRegister();
    }

    function seeRegisterF() {
        if (item.id !== "") {
            perfilActions.cleanRegister();
        }
        perfilActions.setSeeRegister(false);
    }

    function changeView() {
        history.push("/personas");
        perfilActions.setActivePersona(false);
        perfilActions.setActiveTab("Search");
    }

    return (
        <>
                <Col>
                    <Breadcrumb activeTab={activeTab} />
                    <Card>
                        <Form>
                            {
                                activeTab === "Search"

                                    ?
                                    <Col md={12}>
                                        <Row className="pt-2 pb-2">
                                            <Col md={8}>
                                                <Button
                                                    onClick={() => changeTab("Register")}
                                                    style={{ width: "210px" }}
                                                    className='purpleButton'
                                                >
                                                    Cadastrar Perfil do Cliente
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    :
                                    <Col className="pt-2 pb-2">
                                        {
                                            !activePersona ?
                                                <Button
                                                    onClick={() => { changeTab("Search"); seeRegisterF() }}
                                                    style={{ width: "150px", color: "white" }}
                                                    className='purpleButton'>
                                                    <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar
                                        </Button>
                                                :
                                                <Button
                                                    onClick={() => changeView()}
                                                    style={{ width: "200px", color: "white" }}
                                                    className='purpleButton'>
                                                    <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar para Personas
                                        </Button>
                                        }
                                        {!seeRegister && <Button
                                            onClick={() => saveItem()}
                                            style={{ width: "242px", float: 'right' }}
                                            className="purpleButton"
                                        >
                                            Salvar Cadastro
                                                        </Button>}
                                    </Col>
                            }
                        </Form>
                    </Card>
                </Col>

                <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <br />
                        <Search />
                    </TabPane>
                    <TabPane tabId="Register">
                        <br />
                        <Register />
                    </TabPane>
                </TabContent>
        </>
    );
}

export default PerfilCliente;