import api from '../util/api'
import * as actions from '../store/modules/inconsistenciaComissao/actions'
import { setLoading } from '../store/modules/globalLoading/actions'

import { maskDate } from '../util/masks'

export async function getQuantidade(inconsistencias) {

    setLoading(true)
    const data = await api.get(`inconsistencia-sistema-proposta/quantidades/possivel-corrigir`)
    setLoading(false)

    if (!data.success) {
        return
    }

    inconsistencias.forEach(inconsistencia =>{
        let inconsistenciaFound = data.content.find((e) => inconsistencia.id === e.id)
        if(inconsistenciaFound){
            inconsistencia.quantidade = inconsistenciaFound.quantidade
        }else{
            inconsistencia.quantidade = 0
        }
    })
    actions.setTabs(inconsistencias)
};

export async function getQuantidadeComissao(inconsistencias) {

    setLoading(true)
    const data = await api.get(`inconsistencia-sistema-tabela-comissao/quantidades/possivel-corrigir`)
    setLoading(false)

    if (!data.success) {
        return
    }

    data.content.forEach((e)=>{
        inconsistencias.forEach((j)=>{
            if (e.id === j.id){
                j.quantidade = e.quantidade
            }
        })
    })

    actions.setTabs(inconsistencias)
};

export async function getInconsistencias(tab){

    setLoading(true)
    const data = await api.get(`inconsistencia-sistema-proposta/tipo-inconsistencia/${tab.id}`)

    if (!data.success) {
        return setLoading(false)
    }

    if(tab.tab === "1"){
        let comissoes = [];
        data.content.forEach((e)=>{
            let propostas = []
            e.propostas.forEach((proposta) => {
                propostas.push({
                    numeroProposta : proposta.numeroProposta,
                    dataEntradaProposta : String(proposta.dataEntradaProposta).substring(0,10)
                })
            })
            comissoes.push({
                tabelaComissao: e.codigoTabela,
                produto: e.produto,
                convenio: e.codigoConvenio,
                instituicao: e.nomeBanco,
                bancoId: e.bancoId,
                quantidadeParcelas: e.quantidadeParcelas,
                nomeArquivoImportado: e.nomeArquivo,
                propostas: propostas,
                dataEntradaProposta: maskDate(e.dataEntradaProposta, 'YYYY-MM-DD')
            },)
        })
        actions.setComissoes(comissoes)
        return setLoading(false)
    }
}

export async function getInconsistenciasComissao(tab){

    setLoading(true)
    const data = await api.get(`inconsistencia-sistema-tabela-comissao/tipo-inconsistencia/${tab.id}`)

    if (!data.success) {
        return setLoading(false)
    }

    if(tab.tab === "2"){
        const produtos = data.content.map((item)=>{
            return ({
                id: item.id,
                descricao: item.descricao,
                bancoId: item.bancoId,
                nomeBanco: item.nomeBanco,
                nomeArquivo: item.nomeArquivo
            })
        });
        actions.setProdutos(produtos)
        return setLoading(false)
    }
    if(tab.tab === "3"){
        const tiposOperacao = data.content.map((item)=>{
            return ({
                id: item.id,
                descricao: item.descricao,
                bancoId: item.bancoId,
                nomeBanco: item.nomeBanco,
                nomeArquivo: item.nomeArquivo
            })
        });
        actions.setTiposOperacao(tiposOperacao)
        return setLoading(false)
    }
    if(tab.tab === "4"){
        const convenios = data.content.map((item)=>{
            return ({
                id: item.id,
                descricao: item.descricao,
                bancoId: item.bancoId,
                nomeBanco: item.nomeBanco,
                nomeArquivo: item.nomeArquivo
            })
        });
        actions.setConvenios(convenios)
        return setLoading(false)
    }
    if(tab.tab === "5"){
        const tiposComissao = data.content.map((item)=>{
            return ({
                id: item.id,
                descricao: item.descricao,
                bancoId: item.bancoId,
                nomeBanco: item.nomeBanco,
                nomeArquivo: item.nomeArquivo
            })
        });
        actions.setTiposComissao(tiposComissao)
        return setLoading(false)
    }
}

export async function correcaoInconsistencia(inconsistencia, tab) {

    let body = {}
    if(tab.tab === "1"){
        if(inconsistencia.tabelaComissao === null || inconsistencia.tabelaComissao === ''){
            body = {
                tipoInconsistenciaId: tab.id,
                produto: inconsistencia.produto,
                convenio: inconsistencia.convenio,
                quantidade: inconsistencia.quantidadeParcelas,
                bancoId: inconsistencia.bancoId,
                dataEntrada: inconsistencia.propostas[0].dataEntradaProposta
            }
        }else{
            body = {
                tipoInconsistenciaId: tab.id,
                descricao: inconsistencia.tabelaComissao,
                bancoId: inconsistencia.bancoId,
                dataEntrada: inconsistencia.propostas[0].dataEntradaProposta,
                quantidade: inconsistencia.quantidadeParcelas,
            }
        }
    }

    setLoading(true)
    const data = await api.post("inconsistencia-sistema-proposta/correcao", body);
    setLoading(false)

    if (!data.success) {
    return;
    }

    await getInconsistencias(tab)
}

export async function correcaoInconsistenciaComissao(inconsistencia, tab) {

    let body = {
        tipoInconsistenciaId: tab.id,
        descricao: inconsistencia.descricao,
        bancoId: inconsistencia.bancoId
    }

    setLoading(true)
    const data = await api.post("inconsistencia-sistema-tabela-comissao/correcao", body);
    setLoading(false)

    if (!data.success) {
    return;
    }

    getInconsistenciasComissao(tab)
}