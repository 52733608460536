import React from "react";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import { AiOutlineMinus } from "react-icons/ai";

import { maskMoeda } from "../../../../util/masks";

import "./style.css";
import { useSelector } from "react-redux";
import { useState } from "react";

import * as controller from "../../../../controllers/dashboardController/producaoController";
import { CustomTooltip } from "../../../../components/CustomTooltip";

function getCanceladoColor(crescimento) {
  return crescimento > 0
    ? "linhaRed"
    : crescimento === 0
    ? "linhaAmarela"
    : "linhaGreen";
}

function getCanceladoArrow(crescimento) {
  if (crescimento === 0)
    return <AiOutlineMinus style={{ color: "var(--color-laranja)" }} />;

  if (crescimento > 0) return <BsArrowUp style={{ color: "red" }} />;

  return <BsArrowDown style={{ color: "var(--color-verde-claro)" }} />;
}

export default function Table({ data }) {
  return (
    <table style={{ maxWidth: "100%" }}>
      <thead style={{ maxWidth: "100%" }}>
        <tr style={{ maxWidth: "100%" }}>
          <th className="coluna">Nome do Produto</th>
          <th className="coluna">Digitado</th>
          <th className="coluna">Total Pago</th>
          <th className="coluna">Andamento</th>
          <th className="coluna">Valor projetado</th>
          <th className="coluna">Cancelado</th>
          <th className="coluna">Ticket médio</th>
          <th className="coluna">Share</th>
        </tr>
      </thead>
      <tbody style={{ maxWidth: "100%" }}>
        {data?.map((e, i) => {
          return <FirstLevelTableRow data={e} index={i} />;
        })}
      </tbody>
    </table>
  );
}

function FirstLevelTableRow({ data, index }) {
  const { filtrosSelecionados, tabelaCanais } = useSelector(
    (state) => state.dashboardProducao
  );

  const [isBancoActive, setIsBancoActive] = useState(false);

  function handleOnClick() {
    if (!isBancoActive) {
      var isSuccessed = controller.getTabelaProducaoPorCanalBanco(
        filtrosSelecionados,
        { canalId: data.id },
        tabelaCanais
      );
      isSuccessed && setIsBancoActive(!isBancoActive);
    } else {
      setIsBancoActive(!isBancoActive);
    }
  }

  return (
    <>
      <tr key={index} className="tr">
        <td className="linhaGreen" style={{ filter: "brightness(85%)" }}>
          <div style={{ display: "flex" }}>
            <div className="button" onClick={(e) => handleOnClick()}>
              {!isBancoActive ? "+" : "-"}
            </div>
            {data.nome}
          </div>
        </td>
        {data.statusPropostas.map((e, i) => {
          return e.descricao !== "SHARE" ? (
            <CustomTooltip
              title={`Valor Comparado: R$${maskMoeda(
                e.valorComparado.toFixed(2)
              )}`}
            >
              <td
                className={
                  e.descricao === "CANCELADO"
                    ? getCanceladoColor(e.crescimento)
                    : e.crescimento > 0
                    ? "linhaGreen"
                    : e.crescimento === 0
                    ? "linhaAmarela"
                    : "linhaRed"
                }
                style={{ filter: "brightness(85%)" }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      color: "var(--color-preto)",
                    }}
                  >
                    {e.descricao !== "SHARE" ? `(${e.quantidade})` : ""}{" "}
                    {e.descricao === "CANCELADO" ? (
                      getCanceladoArrow(e.crescimento)
                    ) : e.crescimento > 0 ? (
                      <BsArrowUp
                        style={{ color: "var(--color-verde-claro)" }}
                      />
                    ) : e.crescimento === 0 ? (
                      <AiOutlineMinus
                        style={{ color: "var(--color-laranja)" }}
                      />
                    ) : (
                      <BsArrowDown style={{ color: "red" }} />
                    )}{" "}
                    {e.descricao !== "SHARE" ? `(${e.crescimento}%)` : ""}
                  </p>
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: "700",
                      marginBottom: "3px",
                      color: "var(--color-preto)",
                    }}
                  >
                    {e.descricao !== "SHARE"
                      ? `R$${maskMoeda(e.valorTotal.toFixed(2))}`
                      : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                  </p>
                </div>
              </td>
            </CustomTooltip>
          ) : (
            <td
              className={
                e.descricao === "CANCELADO"
                  ? getCanceladoColor(e.crescimento)
                  : e.crescimento > 0
                  ? "linhaGreen"
                  : e.crescimento === 0
                  ? "linhaAmarela"
                  : "linhaRed"
              }
              style={{ filter: "brightness(85%)" }}
            >
              <div>
                <p
                  style={{
                    fontSize: "11px",
                    marginBottom: "3px",
                    color: "var(--color-preto)",
                  }}
                >
                  {e.descricao !== "SHARE" ? `(${e.quantidade})` : ""}{" "}
                  {e.descricao === "CANCELADO" ? (
                    getCanceladoArrow(e.crescimento)
                  ) : e.crescimento > 0 ? (
                    <BsArrowUp style={{ color: "var(--color-verde-claro)" }} />
                  ) : e.crescimento === 0 ? (
                    <AiOutlineMinus style={{ color: "var(--color-laranja)" }} />
                  ) : (
                    <BsArrowDown style={{ color: "red" }} />
                  )}{" "}
                  {e.descricao !== "SHARE" ? `(${e.crescimento}%)` : ""}
                </p>
                <p
                  style={{
                    fontSize: "11px",
                    fontWeight: "700",
                    marginBottom: "3px",
                    color: "var(--color-preto)",
                  }}
                >
                  {e.descricao !== "SHARE"
                    ? `R$${maskMoeda(e.valorTotal.toFixed(2))}`
                    : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                </p>
              </div>
            </td>
          );
        })}
      </tr>
      {isBancoActive &&
        data.bancos.map((e, i) => {
          return <SecondLevelTableRow data={e} index={i} canalId={data.id} />;
        })}
    </>
  );
}

function SecondLevelTableRow({ data, index, canalId }) {
  const { filtrosSelecionados, tabelaCanais } = useSelector(
    (state) => state.dashboardProducao
  );

  const [isProdutoActive, setIsProdutoActive] = useState(false);

  var elemento = { bancoId: data.id, canalId: canalId };

  function handleOnClick() {
    if (!isProdutoActive) {
      var isSuccessed = controller.getTabelaProducaoPorCanalBancoProduto(
        filtrosSelecionados,
        elemento,
        tabelaCanais
      );
      isSuccessed && setIsProdutoActive(!isProdutoActive);
    } else {
      setIsProdutoActive(!isProdutoActive);
    }
  }

  return (
    <>
      <tr key={index} className="tr">
        <td className="linhaGreen" style={{ filter: "brightness(90%)" }}>
          <div style={{ display: "flex", marginLeft: "30px" }}>
            <div className="button" onClick={(e) => handleOnClick()}>
              {!isProdutoActive ? "+" : "-"}
            </div>
            {data.nome}
          </div>
        </td>
        {data.statusPropostas.map((e) => {
          return e.descricao !== "SHARE" ? (
            <CustomTooltip
              title={`Valor Comparado: R$${maskMoeda(
                e.valorComparado.toFixed(2)
              )}`}
            >
              <td
                className={
                  e.descricao === "CANCELADO"
                    ? getCanceladoColor(e.crescimento)
                    : e.crescimento > 0
                    ? "linhaGreen"
                    : e.crescimento === 0
                    ? "linhaAmarela"
                    : "linhaRed"
                }
                style={{ filter: "brightness(90%)" }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      color: "var(--color-preto)",
                    }}
                  >
                    {e.descricao !== "SHARE" ? `(${e.quantidade})` : ""}{" "}
                    {e.descricao === "CANCELADO" ? (
                      getCanceladoArrow(e.crescimento)
                    ) : e.crescimento > 0 ? (
                      <BsArrowUp
                        style={{ color: "var(--color-verde-claro)" }}
                      />
                    ) : e.crescimento === 0 ? (
                      <AiOutlineMinus
                        style={{ color: "var(--color-laranja)" }}
                      />
                    ) : (
                      <BsArrowDown style={{ color: "red" }} />
                    )}{" "}
                    {e.descricao !== "SHARE" ? `(${e.crescimento}%)` : ""}
                  </p>
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: "700",
                      marginBottom: "3px",
                      color: "var(--color-preto)",
                    }}
                  >
                    {e.descricao !== "SHARE"
                      ? `R$${maskMoeda(e.valorTotal.toFixed(2))}`
                      : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                  </p>
                </div>
              </td>
            </CustomTooltip>
          ) : (
            <td
              className={
                e.descricao === "CANCELADO"
                  ? getCanceladoColor(e.crescimento)
                  : e.crescimento > 0
                  ? "linhaGreen"
                  : e.crescimento === 0
                  ? "linhaAmarela"
                  : "linhaRed"
              }
              style={{ filter: "brightness(90%)" }}
            >
              <div>
                <p
                  style={{
                    fontSize: "11px",
                    marginBottom: "3px",
                    color: "var(--color-preto)",
                  }}
                >
                  {e.descricao !== "SHARE" ? `(${e.quantidade})` : ""}{" "}
                  {e.descricao === "CANCELADO" ? (
                    getCanceladoArrow(e.crescimento)
                  ) : e.crescimento > 0 ? (
                    <BsArrowUp style={{ color: "var(--color-verde-claro)" }} />
                  ) : e.crescimento === 0 ? (
                    <AiOutlineMinus style={{ color: "var(--color-laranja)" }} />
                  ) : (
                    <BsArrowDown style={{ color: "red" }} />
                  )}{" "}
                  {e.descricao !== "SHARE" ? `(${e.crescimento}%)` : ""}
                </p>
                <p
                  style={{
                    fontSize: "11px",
                    fontWeight: "700",
                    marginBottom: "3px",
                    color: "var(--color-preto)",
                  }}
                >
                  {e.descricao !== "SHARE"
                    ? `R$${maskMoeda(e.valorTotal.toFixed(2))}`
                    : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                </p>
              </div>
            </td>
          );
        })}
      </tr>
      {isProdutoActive &&
        data.produtos.map((e, i) => {
          return <ThirdLevelTableRow data={e} index={i} e={elemento} />;
        })}
    </>
  );
}

function ThirdLevelTableRow({ data, index, e }) {
  const { filtrosSelecionados, tabelaCanais } = useSelector(
    (state) => state.dashboardProducao
  );

  const [isCentroCustoActive, setIsCentroCustoActive] = useState(false);

  var elemento = {
    canalId: e.canalId,
    bancoId: e.bancoId,
    produtoId: data.id,
  };

  function handleOnClick() {
    if (!isCentroCustoActive) {
      var isSuccessed = controller.getTabelaProducaoPorCanalProdutoCentroCusto(
        filtrosSelecionados,
        elemento,
        tabelaCanais
      );
      isSuccessed && setIsCentroCustoActive(!isCentroCustoActive);
    } else {
      setIsCentroCustoActive(!isCentroCustoActive);
    }
  }

  return (
    <>
      <tr key={index} className="tr">
        <td className="linhaGreen" style={{ borderColor: "white!important", filter: 'brightness(95%)' }}>
          <div style={{ display: "flex", marginLeft: "60px" }}>
            <div className="button" onClick={(e) => handleOnClick()}>
              {!isCentroCustoActive ? "+" : "-"}
            </div>
            {data.nome}
          </div>
        </td>
        {data.statusPropostas.map((e) => {
          return e.descricao !== "SHARE" ? (
            <CustomTooltip
              title={`Valor Comparado: R$${maskMoeda(
                e.valorComparado.toFixed(2)
              )}`}
            >
              <td
                className={
                  e.descricao === "CANCELADO"
                    ? getCanceladoColor(e.crescimento)
                    : e.crescimento > 0
                    ? "linhaGreen"
                    : e.crescimento === 0
                    ? "linhaAmarela"
                    : "linhaRed"
                }
                style={{ filter: "brightness(95%)" }}
              >
                <div>
                  <p
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      color: "var(--color-preto)",
                    }}
                  >
                    {e.descricao !== "SHARE" ? `(${e.quantidade})` : ""}{" "}
                    {e.descricao === "CANCELADO" ? (
                      getCanceladoArrow(e.crescimento)
                    ) : e.crescimento > 0 ? (
                      <BsArrowUp
                        style={{ color: "var(--color-verde-claro)" }}
                      />
                    ) : e.crescimento === 0 ? (
                      <AiOutlineMinus
                        style={{ color: "var(--color-laranja)" }}
                      />
                    ) : (
                      <BsArrowDown style={{ color: "red" }} />
                    )}{" "}
                    {e.descricao !== "SHARE" ? `(${e.crescimento}%)` : ""}
                  </p>
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: "700",
                      marginBottom: "3px",
                      color: "var(--color-preto)",
                    }}
                  >
                    {e.descricao !== "SHARE"
                      ? `R$${maskMoeda(e.valorTotal.toFixed(2))}`
                      : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                  </p>
                </div>
              </td>
            </CustomTooltip>
          ) : (
            <td
              className={
                e.descricao === "CANCELADO"
                  ? getCanceladoColor(e.crescimento)
                  : e.crescimento > 0
                  ? "linhaGreen"
                  : e.crescimento === 0
                  ? "linhaAmarela"
                  : "linhaRed"
              }
              style={{ filter: "brightness(95%)" }}
            >
              <div>
                <p
                  style={{
                    fontSize: "11px",
                    marginBottom: "3px",
                    color: "var(--color-preto)",
                  }}
                >
                  {e.descricao !== "SHARE" ? `(${e.quantidade})` : ""}{" "}
                  {e.descricao === "CANCELADO" ? (
                    getCanceladoArrow(e.crescimento)
                  ) : e.crescimento > 0 ? (
                    <BsArrowUp style={{ color: "var(--color-verde-claro)" }} />
                  ) : e.crescimento === 0 ? (
                    <AiOutlineMinus style={{ color: "var(--color-laranja)" }} />
                  ) : (
                    <BsArrowDown style={{ color: "red" }} />
                  )}{" "}
                  {e.descricao !== "SHARE" ? `(${e.crescimento}%)` : ""}
                </p>
                <p
                  style={{
                    fontSize: "11px",
                    fontWeight: "700",
                    marginBottom: "3px",
                    color: "var(--color-preto)",
                  }}
                >
                  {e.descricao !== "SHARE"
                    ? `R$${maskMoeda(e.valorTotal.toFixed(2))}`
                    : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                </p>
              </div>
            </td>
          );
        })}
      </tr>
      {isCentroCustoActive &&
        data.centrosCusto.map((e, i) => {
          return <FourthLevelTableRow data={e} index={i} />;
        })}
    </>
  );
}

function FourthLevelTableRow({ data, index }) {
  return (
    <>
      <tr className="tr" key={index}>
        <td className="linhaGreen" style={{ borderColor: "white!important" }}>
          <div style={{ display: "flex", marginLeft: "90px" }}>
            {data.nome}
          </div>
        </td>
        {data.statusPropostas.map((e) => {
          return e.descricao !== "SHARE" ? (
            <CustomTooltip
              title={`Valor Comparado: R$${maskMoeda(
                e.valorComparado.toFixed(2)
              )}`}
            >
              <td
                className={
                  e.descricao === "CANCELADO"
                    ? getCanceladoColor(e.crescimento)
                    : e.crescimento > 0
                    ? "linhaGreen"
                    : e.crescimento === 0
                    ? "linhaAmarela"
                    : "linhaRed"
                }
              >
                <div>
                  <p
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      color: "var(--color-preto)",
                    }}
                  >
                    {e.descricao !== "SHARE" ? `(${e.quantidade})` : ""}{" "}
                    {e.descricao === "CANCELADO" ? (
                      getCanceladoArrow(e.crescimento)
                    ) : e.crescimento > 0 ? (
                      <BsArrowUp
                        style={{ color: "var(--color-verde-claro)" }}
                      />
                    ) : e.crescimento === 0 ? (
                      <AiOutlineMinus
                        style={{ color: "var(--color-laranja)" }}
                      />
                    ) : (
                      <BsArrowDown style={{ color: "red" }} />
                    )}{" "}
                    {e.descricao !== "SHARE" ? `(${e.crescimento}%)` : ""}
                  </p>
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: "700",
                      marginBottom: "3px",
                      color: "var(--color-preto)",
                    }}
                  >
                    {e.descricao !== "SHARE"
                      ? `R$${maskMoeda(e.valorTotal.toFixed(2))}`
                      : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                  </p>
                </div>
              </td>
            </CustomTooltip>
          ) : (
            <td
              className={
                e.descricao === "CANCELADO"
                  ? getCanceladoColor(e.crescimento)
                  : e.crescimento > 0
                  ? "linhaGreen"
                  : e.crescimento === 0
                  ? "linhaAmarela"
                  : "linhaRed"
              }
            >
              <div>
                <p
                  style={{
                    fontSize: "11px",
                    marginBottom: "3px",
                    color: "var(--color-preto)",
                  }}
                >
                  {e.descricao !== "SHARE" ? `(${e.quantidade})` : ""}{" "}
                  {e.descricao === "CANCELADO" ? (
                    getCanceladoArrow(e.crescimento)
                  ) : e.crescimento > 0 ? (
                    <BsArrowUp style={{ color: "var(--color-verde-claro)" }} />
                  ) : e.crescimento === 0 ? (
                    <AiOutlineMinus style={{ color: "var(--color-laranja)" }} />
                  ) : (
                    <BsArrowDown style={{ color: "red" }} />
                  )}{" "}
                  {e.descricao !== "SHARE" ? `(${e.crescimento}%)` : ""}
                </p>
                <p
                  style={{
                    fontSize: "11px",
                    fontWeight: "700",
                    marginBottom: "3px",
                    color: "var(--color-preto)",
                  }}
                >
                  {e.descricao !== "SHARE"
                    ? `R$${maskMoeda(e.valorTotal.toFixed(2))}`
                    : `${maskMoeda(e.valorTotal.toFixed(2))}%`}
                </p>
              </div>
            </td>
          );
        })}
      </tr>
    </>
  );
}
