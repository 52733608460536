import api from "../util/api";
import * as actions from "../store/modules/formalizacaoLoja/actions";
import {  notifyError, notifySuccess, notifyWarn } from "../components/message/message";
import { maskDate } from "../util/masks";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get(pesquisa, pageSize, page) {
    var url = `Bordero/paginado?pagina=${page}&registros=${pageSize}`

    if (pesquisa.numeroBordero !== '') url = url + `&numBordero=${pesquisa.numeroBordero}`
    if (pesquisa.classificacaoId !== '') url = url + `&classificacao=${pesquisa.classificacaoId}`
    if (pesquisa.lojaId !== '0') url = url + `&lojaId=${pesquisa.lojaId}`
    if (pesquisa.statusId !== '') url = url + `&status=${pesquisa.statusId}`
    if (pesquisa.dataInicial !== '') url = url + `&dtInicio=${pesquisa.dataInicial}`
    if (pesquisa.dataFinal !== '') url = url + `&dtFim=${pesquisa.dataFinal}`

    setLoading(true)
    const data = await api.get(url);
    setLoading(false)

    if (!data.success) {
        return;
    }

    let newContent = []

    data.content.registros.forEach(e=>{
        newContent.push(
            {id: e.id,
            numBordero: e.numeroBordero, 
            dataEnvio: maskDate(e.dataEnvio, 'DD-MM-YYYY'), 
            numPostagem: e.ticketCorreios, 
            statusEnvio: e.historicoCorreios[0] ? e.historicoCorreios[0].descricao ? e.historicoCorreios[0].descricao : 'AGUARDANDO POSTAGEM' : 'AGUARDANDO POSTAGEM'
        })
    })
    actions.setFormalizacoes(newContent)
    actions.getAllItems({ quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas,
        tamanhoPagina: data.content.tamanhoPagina });
}

export async function getById(id, operacao) {
    setLoading(true)
    const data = await api.get(`Bordero/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    let propostas = []

    if(operacao === 'Conferencia'){  
        data.content.borderoPropostas.forEach(e=>{
            propostas.push({
                id: e.id,
                numProposta: e.propostaFormalizacao.numeroProposta,
                numContrato: e.propostaFormalizacao.numeroContrato,
                codBarras: e.propostaFormalizacao.codigoBarras,
                instituicao: e.propostaFormalizacao.nomeBanco,
                statusEnvio: e.status
            })
        })

        actions.modal('id', data.content.id);
        actions.modal('numeroBordero', data.content.numeroBordero);
        actions.modal('steps', data.content.historicoCorreios);
        actions.modal('flagFinalizado', data.content.flagFinalizado);
        actions.modal('propostas', propostas);
    }else if(operacao === 'Impressao'){
        data.content.borderoPropostas.forEach(e=>{
            propostas.push({
                id: e.id,
                cliente: e.propostaFormalizacao.nomeCliente,
                numProposta: e.propostaFormalizacao.numeroProposta,
                digitacao: maskDate(e.propostaFormalizacao.dataDigitacao, 'DD/MM/YYYY'),
                instituicao: e.propostaFormalizacao.nomeBanco,
                produto: e.propostaFormalizacao.nomeProduto,
                operacao: e.propostaFormalizacao.nomeOperacao
            })
        })

        let impressao = {
            codBarras: String(data.content.numeroBordero).padStart(9,'0'),
            parceiro: data.content.enderecoDestinatario.pessoa.nome,
            usuario: data.content.usuario.nome,
            etiqueta: data.content.ticketCorreios,
            dataGerado: `${maskDate(data.content.dataCriacao, 'DD/MM/YYYY')} ${data.content.dataCriacao.substring(11,16)}`,
            totalPropostas: propostas.length,
            propostas:propostas
        }
        actions.setImpressao(impressao)
    }
}

export async function create(item) {
    if (item.destinatarioId === '0') {
        notifyWarn("Destinatário não pode estar em branco");
        return;
    }

    let propostas = []

    item.propostas.forEach(proposta=>{
        proposta.propostasItem.forEach(item=>{
            if(item.isSelected){
                propostas.push({
                    propostaFormalizacaoId: proposta.id
                })
            }
        })
    })

    const body = {
        bancoId: item.instituicaoId !== '0' ? item.instituicaoId : null,
        numeroBordero: 0,
        flagFinalizado: false,
        enderecoDestinatarioId:item.destinatarioId,
        borderoPropostas: propostas,
        lojaId: item.lojaId
    }

    setLoading(true)
    const data = await api.post("Bordero", body);
    setLoading(false)

    if (data.success && data.messages) {
        data.messages.forEach((e)=>{
            notifyError(e)
        })
        return false;
    }

    if(!data.sucess){
        return false
    }

    let etiqueta = {
        codRastreio: data.content.ticketCorreios,
        nomeDestinatario: data.content.enderecoDestinatario.pessoa.nomeFantasia ? data.content.enderecoDestinatario.pessoa.nomeFantasia : data.content.enderecoDestinatario.pessoa.nome,
        ruaDestinatario:data.content.enderecoDestinatario.logradouro,
        numeroDestinatario: data.content.enderecoDestinatario.numero,
        bairroDestinatario: data.content.enderecoDestinatario.bairro,
        complementoDestinatario: data.content.enderecoDestinatario.complemento,
        cepDestinatario: data.content.enderecoDestinatario.cep,
        cidadeDestinatario: data.content.enderecoDestinatario.cidade,
        ufDestinatario: data.content.enderecoDestinatario.estado.sigla,
        nomeRemetente: data.content.enderecoRemetente.pessoa.nomeFantasia ? data.content.enderecoRemetente.pessoa.nomeFantasia : data.content.enderecoRemetente.pessoa.nome,
        ruaRemetente:data.content.enderecoRemetente.logradouro,
        numeroRemetente: data.content.enderecoRemetente.numero,
        bairroRemetente: data.content.enderecoRemetente.bairro,
        complementoRemetente: data.content.enderecoRemetente.complemento,
        cepRemetente: data.content.enderecoRemetente.cep,
        cidadeRemetente: data.content.enderecoRemetente.cidade,
        ufRemetente: data.content.enderecoRemetente.estado.sigla,
    }
    notifySuccess("Borderô gerado com sucesso");
    actions.cleanRegister()
    actions.setEtiqueta(etiqueta)
    getById(data.content.id, 'Impressao')
    get({
        numeroBordero: '',
        classificacaoId: '0',
        lojaId: '0',
        statusId: '0',
        dataInicial: '',
        dataFinal: '',
    }, 10, 1);
    return true
}

export async function getInstituicoes() {

    const data = await api.get(`Bancos`);

    if (!data.success) {
        return;
    }

    actions.setInstituicoes(data.content)

}

export async function getPropostas(bancoId, lojaId) {

    let url = `Proposta/proposta-banco?lojaId=${lojaId}`

    if(bancoId && bancoId !== '0') {
        url = url + `&bancoId=${bancoId}`
    }

    setLoading(true)
    const data = await api.get(url);
    setLoading(false)

    if (!data.success) {
        return;
    }

    let propostas = []

    data.content.forEach(e=>{
        propostas.push({
            id: e.propostaId,
            numProposta: e.numeroProposta,
            classificacao: e.classificacao,
            diasPendentes: e.diasPendentes,
            nomeBanco: e.nomeBanco,
            nomeProduto: e.nomeProduto,
            digitador: e.digitador,
            propostasItem: []
        })
    })

    actions.item('propostasItem', [])
    actions.item('propostas', propostas)
}

export async function getPropostasItem(propostaId, propostas, index) {

    setLoading(true)
    const data = await api.get(`Proposta/formalizacoes-proposta?propostaId=${propostaId}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    let propostasItem = []

    data.content.forEach(e=>{
        propostasItem.push({
            id: e.propostaFormalizacaoId,
            motivo: e.motivo,
            dataPendencia: maskDate(e.dataPendencia, 'DD/MM/YYYY'),
            nomeTipoPendencia: e.nomeTipoPendencia,
            diasPendentes: e.diasPendentes,
            isSelected: false
        })
    })

    propostas[index].propostasItem = propostasItem

    actions.item('propostas', propostas)

}

export async function getDestinatarios(lojaId) {

    const data = await api.get(`Endereco?lojaId=${lojaId}`);

    if (!data.success) {
        return;
    }

    actions.setDestinatarios(data.content)

}

export async function getLoja() {

    const data = await api.get(`lojas/loja-usuario-logado`);

    if (!data.success) {
        return;
    }

    actions.setLojas(data.content)

}

export async function getDetalhamento(id) {

    const data = await api.get(`Proposta/formalizacoes-proposta?propostaId=${id}`);

    if (!data.success) {
        return;
    }

    actions.item('detalhamento', data.content)

}


export async function update(item) {
    let propostas = []
    item.propostas.forEach(e=>{
        propostas.push({
            id: e.id,
            status: e.statusEnvio,
        })
    })
    const body = {
        id: item.id,
        bancoId: item.bancoId,
        numeroBordero: item.numeroBordero,
        flagFinalizado: item.flagFinalizado,
        borderoPropostas: propostas
    }

    setLoading(true)
    const data = await api.put(`Bordero/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return false;
    }

    notifySuccess("Borderô conferido com sucesso");
    get({
        numeroBordero: '',
        classificacaoId: '0',
        instituicaoId: '0',
        statusId: '0',
        dataInicial: '',
        dataFinal: '',
    }, 10, 1)
    return true
}
