import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import { VscDiscard } from "react-icons/vsc";
import ReactPaginate from "react-paginate";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import {
  Button,
  Card,
  Col,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Form,
  FormGroup,
} from "reactstrap";
import { removeAccentsAndUppercase } from "../../../util/masks";

import { COLUMNS } from "../constants/columns";
import * as actions from "../../../store/modules/uploadComissao/actions";
import * as controller from "../../../controllers/uploadComissao";
import { Select } from "../../../components/Inputs/Input";

const Search = () => {
  const {
    pesquisa,
    uploads,
    search,
    getAllItems,
    permitions,
    download,
    layouts,
    tiposArquivo,
    statusImportacoes,
  } = useSelector((state) => state.uploadComissao);
  const columns = useMemo(() => COLUMNS, []);
  const data = uploads;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageSize } = state;
  const [itemSelected, setItem] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalExtorno, setModalExtorno] = useState(false);
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  const handlePageClick = (e) => {
    const pageSelected = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
    controller.get(pesquisa, pageSize, pageSelected + 1);
  };

  useEffect(() => {
    controller.getStatusImportacao();
  }, []);

  useEffect(() => {
    if (getAllItems.tamanhoPagina) {
      setPageSize(getAllItems.tamanhoPagina);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllItems]);

  async function handleUpdate(id) {
    await controller.getById(id);
    actions.setActiveTab("Register");
  }

  function seeRegister(id) {
    actions.setSeeRegister(true);
    handleUpdate(id);
  }
  function onChangeSearch(value, field) {
    actions.pesquisar({ ...pesquisa, [field]: value });
  }

  function onChangeTipoArquivo(value, field) {
    actions.pesquisar({ ...pesquisa, [field]: value });
    controller.getLayoutsPlanilha(value);
  }

  function handleDelete(id) {
    setItem(id);

    toggleModalDelete();
  }

  function handleEstorno(id) {
    setItem(id);
    setModalExtorno(!modalExtorno);
  }

  function toggleModalDelete() {
    setModalDelete(!modalDelete);
  }

  function toggleModalExtorno() {
    setModalExtorno(!modalExtorno);
  }

  function deleteComissao() {
    controller.remove(itemSelected, pesquisa, pageSize, selectedPage);
  }

  function estornoComissao() {
    controller.estorno(itemSelected, pesquisa, pageSize, selectedPage);
  }

  function handleInitialPage(pageSize) {
    const pageSelected = 0;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
    controller.get(pesquisa, pageSize, pageSelected + 1);
  }

  function onChange(value, field) {
    actions.pesquisar({ ...pesquisa, [field]: value });
  }

  function getAll() {
    controller.get(pesquisa, pageSize, 1);
    gotoPage(0);
    setInitialPage(true);
    setSelectedPage(0);
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      getAll();
    }
  }

  return (
    <>
      <Modal
        className="text-center"
        isOpen={modalDelete}
        toggle={toggleModalDelete}
        backdrop={false}
      >
        <ModalHeader
          toggle={toggleModalDelete}
          style={{ height: "10px", border: "none" }}
        />
        <ModalBody>
          <p style={{ fontSize: "18px", color: "var(--color-preto)" }}>
            Tem certeza que deseja{" "}
            <strong style={{ fontSize: "17px" }}>remover</strong> esse item?
          </p>
        </ModalBody>
        <div className="m-3">
          <Button
            onClick={() => toggleModalDelete()}
            color="danger"
            style={{ width: "100px", marginRight: "15px" }}
          >
            {" "}
            Cancelar{" "}
          </Button>
          <Button
            onClick={() => {
              deleteComissao();
              toggleModalDelete();
            }}
            style={{
              width: "100px",
              marginLeft: "15px",
              background: "var(--color-verde)",
              border: "none",
            }}
          >
            {" "}
            Confirmar{" "}
          </Button>
        </div>
      </Modal>

      <Modal
        className="text-center"
        isOpen={modalExtorno}
        toggle={toggleModalExtorno}
        backdrop={false}
      >
        <ModalHeader
          toggle={toggleModalExtorno}
          style={{ height: "10px", border: "none" }}
        />
        <ModalBody>
          <p style={{ fontSize: "18px", color: "var(--color-preto)" }}>
            Tem certeza que deseja{" "}
            <strong style={{ fontSize: "17px" }}>estornar</strong> esse item?
          </p>
          <p style={{ fontSize: "18px", color: "var(--color-preto)" }}>
            Todos os registros do sistema incluídos por ele serão perdidos
          </p>
        </ModalBody>
        <div className="m-3">
          <Button
            onClick={() => setModalExtorno(!modalExtorno)}
            color="danger"
            style={{ width: "100px", marginRight: "15px" }}
          >
            {" "}
            Cancelar{" "}
          </Button>
          <Button
            onClick={() => {
              estornoComissao();
              setModalExtorno(!modalExtorno);
            }}
            style={{
              width: "100px",
              marginLeft: "15px",
              background: "var(--color-verde)",
              border: "none",
            }}
          >
            {" "}
            Confirmar{" "}
          </Button>
        </div>
      </Modal>

      <Col>
        <Card className="pt-3 pb-2 mb-5">
          <Col>
            <div style={{ width: "25%" }} className="pb-3">
              <Label
                style={{
                  fontWeight: "400",
                  fontSize: "18px",
                  margin: "5px 0 8px 15px",
                }}
              >
                Pesquisar
              </Label>
            </div>
            <Form style={{ margin: "0px 0px 15px 15px" }}>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label>Nome do Usuário</Label>
                    <Input
                      type="text"
                      placeholder="Digite aqui"
                      value={pesquisa.nomeUser}
                      onChange={(e) =>
                        onChange(
                          removeAccentsAndUppercase(e.target.value),
                          "nomeUser"
                        )
                      }
                      style={{ height: "35px" }}
                      onKeyPress={(e) => handleKeyPress(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>Data Inicial</Label>
                    <Input
                      type="date"
                      value={pesquisa.dataInicial}
                      max="9999-12-31"
                      onChange={(e) => onChange(e.target.value, "dataInicial")}
                      style={{ height: "35px" }}
                      onKeyPress={(e) => handleKeyPress(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>Data Final</Label>
                    <Input
                      type="date"
                      max="9999-12-31"
                      value={pesquisa.dataFinal}
                      onChange={(e) => onChange(e.target.value, "dataFinal")}
                      style={{ height: "35px" }}
                      onKeyPress={(e) => handleKeyPress(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label>Nome do Arquivo</Label>
                    <Input
                      type="text"
                      placeholder="Digite aqui"
                      value={pesquisa.nomeArquivo}
                      onChange={(e) => onChange(e.target.value, "nomeArquivo")}
                      style={{ height: "35px" }}
                      onKeyPress={(e) => handleKeyPress(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="papel">
                    <Label>Tipo Arquivo</Label>
                    <Select
                      first="Selecione"
                      onChange={(e) =>
                        onChangeTipoArquivo(e.value, "tipoArquivo")
                      }
                      options={tiposArquivo}
                      select={{ value: "id", label: "descricao" }}
                      value={{ value: pesquisa.tipoArquivo }}
                      isSearchable={true}
                    />
                  </FormGroup>
                </Col>
                {pesquisa.tipoArquivo !== "0" && (
                  <Col md={3}>
                    <FormGroup className="papel">
                      <Label>Layout</Label>
                      <Select
                        first="Selecione"
                        onChange={(e) =>
                          onChangeSearch(e.value, "layoutPlanilha")
                        }
                        options={layouts}
                        select={{ value: "id", label: "descricao" }}
                        value={{ value: pesquisa.layoutPlanilha }}
                        isSearchable={true}
                      />
                    </FormGroup>
                  </Col>
                )}

                <Col md={3}>
                  <FormGroup className="papel">
                    <Label>Status</Label>
                    <Select
                      first="Selecione"
                      onChange={(e) =>
                        onChangeSearch(e.value, "statusImportacao")
                      }
                      options={statusImportacoes}
                      select={{ value: "id", label: "descricao" }}
                      value={{ value: pesquisa.statusImportacao }}
                      isSearchable={false}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="mr-4"
                    style={{
                      width: "150px",
                      background: "var(--color-branco)",
                      borderColor: "var(--color-laranja)",
                      color: "var(--color-laranja)",
                      borderRadius: "7px",
                    }}
                    onClick={actions.cleanSearch}
                  >
                    Limpar
                  </Button>
                  <Button
                    className="purpleButton"
                    style={{ width: "150px" }}
                    onClick={() => getAll()}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
            <div
              style={{
                width: "99%",
                overflow: `auto`,
                maxHeight: "700px",
                // minWidth: "1400px",
              }}
            >
              <table {...getTableProps()} className="custom-table">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th style={{ minWidth: "160px", padding: "8px" }}>
                          {column.render("Header")}
                          <span className="float-right">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <BsIcons.BsArrowDown
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps({
                                      title: undefined,
                                    })
                                  )}
                                />
                              ) : (
                                <BsIcons.BsArrowUp
                                  id="OrdenarAsc"
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps({
                                      title: undefined,
                                    })
                                  )}
                                />
                              )
                            ) : (
                              <BsIcons.BsArrowDownUp
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps({
                                    title: undefined,
                                  })
                                )}
                              />
                            )}
                          </span>
                        </th>
                      ))}
                      <th style={{ textAlign: "center", minWidth: "150px" }}>
                        Ações
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  style={{ textAlign: "left", padding: "10px" }}
                >
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr className="text-left" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              style={{ minWidth: "150px", textAlign: "left" }}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                        <td style={{ textAlign: "left", minWidth: "150px" }}>
                          <>
                            <AiIcons.AiOutlineEye
                              style={{
                                fontSize: "1.2rem",
                                cursor: "pointer",
                                color: "#C4C7CA",
                                marginRight: "7px",
                              }}
                              onClick={() => seeRegister(row.original.id)}
                            />

                            <AiIcons.AiOutlineDownload
                              style={{
                                fontSize: "1.2rem",
                                cursor: "pointer",
                                color: "#C4C7CA",
                              }}
                              onClick={async () => {
                                await controller.downloadPlanilha(
                                  row.original.id
                                );
                                document.getElementById("download").click();
                              }}
                            />
                            {permitions.deletar && (
                              <BiIcons.BiTrash
                                style={{
                                  fontSize: "1.2rem",
                                  cursor: "pointer",
                                  color: "#C4C7CA",
                                }}
                                onClick={() => handleDelete(row.original.id)}
                              />
                            )}
                            {permitions.estornar && (
                              <VscDiscard
                                style={{
                                  fontSize: "1.2rem",
                                  cursor: "pointer",
                                  color: "#C4C7CA",
                                }}
                                onClick={() => handleEstorno(row.original.id)}
                              />
                            )}
                          </>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <Row className="pt-4">
              <Col>
                <span style={{ color: "var(--color-cinza-medio)" }}>
                  Mostrando{" "}
                  {getAllItems.quantidade ? getAllItems.quantidade : "0"} de{" "}
                  {getAllItems.quantidadeTotal
                    ? getAllItems.quantidadeTotal
                    : "0"}
                </span>
              </Col>
              <Col md={8}>
                <Row className="float-right">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    initialPage={0}
                    pageCount={getAllItems.totalPaginas}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={initialPage ? 0 : selectedPage}
                  />
                  <Input
                    type="select"
                    className="pageSize"
                    value={pageSize}
                    onChange={(e) => {
                      handleInitialPage(e.target.value);
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 25, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Input>
                </Row>
              </Col>
            </Row>
          </Col>
        </Card>
      </Col>
      <div style={{ display: "none" }}>
        <a
          id="download"
          href={`data:application/pdf;base64,${download.arquivoBase64}`}
          download={download.nomeArquivo}
        >
          .
        </a>
      </div>
    </>
  );
};

export default Search;
