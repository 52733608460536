import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as centroCustoActions from "../store/modules/vinculoCentroDeCusto/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("CentrosCusto/vinculo");
    setLoading(false)

    if (!data.success) {
        return;
    }

    centroCustoActions.getItems(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`CentrosCusto/vinculo/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    const newData = {
        ...data.content,
        codDepartamento: data.content.depara,
        lojaPessoas: data.content.lojaPessoas,
        lojaId: data.content.loja.id
    }

    centroCustoActions.copy(newData);
    centroCustoActions.item(newData);
}

export async function create(item) {


    if (item.lojaId.trim() === "0") {
        centroCustoActions.setInvalid("lojaId");
        return notifyWarn("Loja não pode estar em branco");
    }
    if (item.centroCustoId.trim() === "0") {
        centroCustoActions.setInvalid("centroCustoId");
        return notifyWarn("Centro de custo não pode estar em branco");
    }

    const body = {
        centroCustoId: item.centroCustoId,
        lojaId: item.lojaId
    }

    setLoading(true)
    const data = await api.post("CentrosCusto/vinculo", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Centro de custo cadastrado");
    centroCustoActions.cleanRegister();
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`CentrosCusto/vinculo/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Centro de custo removido");
    get();
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (item.lojaId.trim() === "0") {
        centroCustoActions.setInvalid("lojaId");
        return notifyWarn("Loja não pode estar em branco");
    }

    if (item.centroCustoId.trim() === "0") {
        centroCustoActions.setInvalid("centroCustoId");
        return notifyWarn("Centro de custo não pode estar em branco");
    }

    const body = {
        centroCustoId: item.centroCustoId,
        lojaId: item.lojaId
    }

    setLoading(true)
    const data = await api.put(`CentrosCusto/vinculo/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Centro de custo atualizado");
    centroCustoActions.cleanRegister();
    centroCustoActions.setActiveTab("Search");
    get();
}

export async function getLojas() {

    setLoading(true)
    const data = await api.get("lojas");
    setLoading(false)

    if (!data.success) {
        return;
    }

    centroCustoActions.getLojas(data.content);
}

export async function getCentrosCusto() {

    setLoading(true)
    const data = await api.get("centrosCusto");
    setLoading(false)

    if (!data.success) {
        return;
    }

    centroCustoActions.getCentrosCusto(data.content);
}
