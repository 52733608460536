const STORE_ID = "INCONSISTENCIA_COMISSAO_RECEBIDA";

const TYPES = {
    SET_PERMITIONS: "SET_PERMITIONS",
    SET_TAB: "SET_TAB",
    SET_TABS: "SET_TABS",
    SET_LOJAS_BANCO: "SET_LOJAS_BANCO",
    SET_PROPOSTAS: "SET_PROPOSTAS",
    SET_TIPOS_OPERACAO: "SET_TIPOS_OPERACAO",
    SET_TIPOS_COMISSAO: "SET_TIPOS_COMISSAO",
    SET_PRODUTO_BANCO: "SET_PRODUTO_BANCO",
    CLEAN_TABS: "CLEAN_TABS",
    GET_ALL_ITEMS: "GET_ALL_ITEMS",
    SET_IS_FIRST_PAGE: "SET_IS_FIRST_PAGE",
}

export { STORE_ID, TYPES }