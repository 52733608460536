import {STORE_ID, TYPES} from "./types";

const INITIAL_STATE = {
    tab: 1,
    log: [],
    getAll: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    }
}

export default function logW3Erp(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_GET_ALL:
                    return { ...state, getAll: action.value }
                case TYPES.LOG:
                    return { ...state, log: action.value }
                case TYPES.SET_TAB:
                    return { ...state, tab: action.value }
                default:
                    return { ...state }
            }
        default:
            return {...state}
    }
}
