import {STORE_ID, TYPES} from "./types"

const INITIAL_STATE = {
    item: {
        descricao: "",
        id: "",
        codCentroCustoW3Erp: "",
        headCountOrcadoLojasCentroCusto: []
    },
    headcount: {
        id: '0',
        headCountOrcadoLojasCentroCusto: '',
        periodo: ''
    },
    copy: {},
    items: [],
    activeTab: "Search",
    seeRegister: false,
    permitions: {
        criar: false,
        deletar: false
    }
}

export default function centroCusto(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return {...state, permitions: action.value}
                case TYPES.CENTROS_CUSTO:
                    return {...state, items: action.value}
                case TYPES.HEADCOUNT:
                    return {...state, headcount: action.value}
                case TYPES.SET_HEADCOUNT:
                    return {...state, headcount: {...state.headcount, [action.field]: action.value}}
                case TYPES.CENTRO_CUSTO:
                    return {...state, item: action.value}
                case TYPES.CENTRO_CUSTO_COPY:
                    return {...state, copy: action.value}
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state, item: {
                            descricao: "",
                            id: "",
                            codCentroCustoW3Erp: "",
                            headCountOrcadoLojasCentroCusto: []
                        }, headCount: INITIAL_STATE.headcount
                    }
                case TYPES.CLEAN_HEADCOUNT:
                    return {...state, headcount: INITIAL_STATE.headcount}
                case TYPES.SET_ACTIVE_TAB:
                    return {...state, activeTab: action.value}
                case TYPES.SET_SEE:
                    return {...state, seeRegister: action.value}
                default:
                    return {...state}
            }
        default:
            return {...state}
    }
}
