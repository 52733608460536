import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function item(value) {
    dispatch({
        type: TYPES.DIVISAO_COMISSAO,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_DIVISAO_COMISSAO,
        field
    });
}

export function getItems(value) {
    dispatch({
        type: TYPES.DIVISAO_COMISSOES,
        value
    });
}

export function getAllItems(value) {
    dispatch({
        type: TYPES.GET_ALL_ITEMS,
        value
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_DIVISAO_COMISSAO,
        field
    });
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function cleanSearch() {
    dispatch({
        type: TYPES.CLEAN_SEARCH
    });
}

export function cleanAllSearch() {
    dispatch({
        type: TYPES.CLEAN_ALL_SEARCH
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function setSearch(value) {
    dispatch({
        type: TYPES.SEARCH,
        value
    });
}

export function setModalLoja(campo, value) {
    dispatch({
        type: TYPES.SET_MODAL_LOJA,
        campo,
        value
    })
}

export function setLojas(value) {
    dispatch({
        type: TYPES.SET_LOJAS,
        value
    })
}

export function setLojasBanco(value) {
    dispatch({
        type: TYPES.SET_LOJAS_BANCO,
        value
    })
}

export function setTiposComissao(value) {
    dispatch({
        type: TYPES.SET_TIPOS_COMISSAO,
        value
    })
}

export function getAllLojas(value) {
    dispatch({
        type: TYPES.SET_GET_ALL_LOJA,
        value
    })
}

export function setModalLojaMatriz(campo, value) {
    dispatch({
        type: TYPES.SET_MODAL_LOJA_MATRIZ,
        campo,
        value
    })
}

export function setLojasMatrizes(value) {
    dispatch({
        type: TYPES.SET_LOJAS_MATRIZES,
        value
    })
}

export function getAllLojasMatrizes(value) {
    dispatch({
        type: TYPES.SET_GET_ALL_LOJA_MATRIZ,
        value
    })
}

export function addLoja(value) {
    dispatch({
        type: TYPES.ADD_LOJA,
        value
    })
}

export function removeLoja(index) {
    dispatch({
        type: TYPES.REMOVE_LOJA,
        index
    })
}

export function setBancos(value) {
    dispatch({
        type: TYPES.SET_BANCOS,
        value
    })
}

export function setLojasRegister(value) {
    dispatch({
        type: TYPES.SET_LOJAS_REGISTER,
        value
    })
}

export function setLojasSemDivisaoRegister(value) {
    dispatch({
        type: TYPES.SET_LOJAS_SEM_DIVISAO_REGISTER,
        value
    })
}

export function setLojasMatrizesRegister(value) {
    dispatch({
        type: TYPES.SET_LOJAS_MATRIZES_REGISTER,
        value
    })
}

export function cleanLojasRegister() {
    dispatch({
        type: TYPES.CLEAN_LOJAS_REGISTER
    })
}

export function cleanLojasSemDivisaoRegister() {
    dispatch({
        type: TYPES.CLEAN_LOJAS_SEM_DIVISAO_REGISTER
    })
}

export function cleanLojasMatrizesRegister() {
    dispatch({
        type: TYPES.CLEAN_LOJAS_MATRIZES_REGISTER
    })
}

export function setDivisaoComissaoCard(value) {
    dispatch({
        type: TYPES.SET_DIVISAO_COMISSAO_CARD,
        value
    })
}

export function cleanDivisaoComissaoCard() {
    dispatch({
        type: TYPES.CLEAN_DIVISAO_COMISSAO_CARD
    })
}

export function cleanModalLoja() {
    dispatch({
        type: TYPES.CLEAN_MODAL_LOJA
    })
}

export function cleanModalLojaMatriz() {
    dispatch({
        type: TYPES.CLEAN_MODAL_LOJA_MATRIZ
    })
}

export function cleanGetAllLoja() {
    dispatch({
        type: TYPES.CLEAN_GET_ALL_LOJA
    })
}

export function cleanGetAllLojaMatriz() {
    dispatch({
        type: TYPES.CLEAN_GET_ALL_LOJA_MATRIZ
    })
}

export function setLoadingLoja(value) {
    dispatch({
        type: TYPES.SET_LOADING_LOJA,
        value
    })
}

export function setLoadingLojaMatriz(value) {
    dispatch({
        type: TYPES.SET_LOADING_LOJA_MATRIZ,
        value
    })
}