import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as produtoInstActions from "../store/modules/produtosInstituicoes/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("produtoBanco");
    setLoading(false)

    if (!data.success) {
        return;
    }

    produtoInstActions.getItems(data.content);
}

export async function getOperacoes() {

    const data = await api.get("tipos-operacao");

    if (!data.success) {
        return;
    }

    produtoInstActions.setOperacoes(data.content);
}

export async function getProdutosRc() {

    const data = await api.get("produtos");

    if (!data.success) {
        return;
    }

    produtoInstActions.setProdutosRc(data.content);
}

export async function getBancos() {

    const data = await api.get("bancos");

    if (!data.success) {
        return;
    }

    produtoInstActions.setBancos(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`produtoBanco/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    const dataUpdate = {
        descricao: data.content.descricao,
        produtoRcId: data.content.produto.id,
        bancoId: data.content.banco.id,
        tipoOperacaoId: data.content.tipoOperacao.id,
        id: data.content.id
    }

    produtoInstActions.copy(dataUpdate);
    produtoInstActions.item(dataUpdate);
}

export async function create(item) {

    if (!item.descricao || item.descricao.trim() === "") {
        produtoInstActions.setInvalid("descricao");
        return notifyWarn("Descrição do produto instituição não pode estar em branco");

    }

    if (item.bancoId === "0") {
        produtoInstActions.setInvalid("bancoId");
        return notifyWarn("Selecione uma instituição");

    }

    if (item.tipoOperacaoId === "0") {
        produtoInstActions.setInvalid("tipoOperacaoId");
        return notifyWarn("Selecione um tipo de operação ribercred");
    }

    if (item.produtoRcId === "0") {
        produtoInstActions.setInvalid("produtoRcId");
        return notifyWarn("Selecione um produto ribercred");
    }

    const body = {
        descricao: item.descricao,
        bancoId: item.bancoId,
        tipoOperacaoId: item.tipoOperacaoId,
        produtoId: item.produtoRcId
    }

    setLoading(true)
    const data = await api.post("produtoBanco", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Produto instituição cadastrado");
    produtoInstActions.cleanRegister();
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`produtoBanco/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Produto instituição removido");
    get();
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (!item.descricao || item.descricao.trim() === "") {
        produtoInstActions.setInvalid("descricao");
        return notifyWarn("Descrição do produto instituição não pode estar em branco");

    }

    if (item.bancoId === "0") {
        produtoInstActions.setInvalid("bancoId");
        return notifyWarn("Selecione uma instituição");

    }

    if (item.tipoOperacaoId === "0") {
        produtoInstActions.setInvalid("tipoOperacaoId");
        return notifyWarn("Selecione um tipo de operação");
    }

    if (item.produtoRcId === "0") {
        produtoInstActions.setInvalid("produtoRcId");
        return notifyWarn("Selecione um produto ribercred");
    }

    const body = {
        descricao: item.descricao,
        bancoId: item.bancoId,
        tipoOperacaoId: item.tipoOperacaoId,
        produtoId: item.produtoRcId
    }

    setLoading(true)
    const data = await api.put(`produtoBanco/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Produto instituição atualizado");
    produtoInstActions.cleanRegister();
    produtoInstActions.setActiveTab("Search");
    get();
}
