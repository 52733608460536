import React from "react";

import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from "react-icons/md";
import {Col, Row} from "reactstrap";
import {Tooltip} from "@material-ui/core";

import {useSelector} from "react-redux";
import * as actions from "../../../../store/modules/perfilAcesso/actions";
import * as controller from "../../../../controllers/perfilAcessoController";
import * as message from "../../../../components/message/message";

import {LojasDisponiveisList} from "./LojasDisponiveisList";
import {LojasAssociadasList} from "./LojasAssociadasList";

function AssociacaoLojas() {
    const {
        lojasDisponiveis,
        lojasSelecionadas,
        lojasAssociadasSelecionadas,
        lojasAssociadas,
        perfilDeAcessoBeingEditedId,
        lojasDisponiveisSearchResults,
        lojasDisponiveisInitialValueBackup,
        isInEditMode,
        activeTab,
    } = useSelector((state) => state.perfilAcesso);

    async function handleAddAllLojasSelectedToLojasAssociadas() {
        if (isInEditMode) {
            let idsOfLojasThatWereSuccessfullyAssociated = [];

            if (lojasSelecionadas.length === lojasDisponiveis.length) {
                if (lojasSelecionadas.length > 5) {
                    await controller.addAllLojasAssociadas({
                        perfilDeAcessoId: perfilDeAcessoBeingEditedId
                    });
                    for await (const lojaSelecionada of lojasSelecionadas) {
                        idsOfLojasThatWereSuccessfullyAssociated.push(lojaSelecionada.id);
                    }
                    message.notifySuccess("Lojas Associadas com sucesso!");
                } else {
                    for await (const lojaSelecionada of lojasSelecionadas) {
                        const isSuccessed = await controller.addLojasAssociadas({
                            perfilDeAcessoId: perfilDeAcessoBeingEditedId,
                            loja: lojaSelecionada,
                        });

                        if (isSuccessed) {
                            idsOfLojasThatWereSuccessfullyAssociated.push(lojaSelecionada.id);
                        }
                    }

                    if(lojasSelecionadas.length === 1){
                        message.notifySuccess("Loja Associada com sucesso!");
                    }else {
                        message.notifySuccess("Lojas Associadas com sucesso!");
                    }
                }
            } else {
                for await (const lojaSelecionada of lojasSelecionadas) {
                    const isSuccessed = await controller.addLojasAssociadas({
                        perfilDeAcessoId: perfilDeAcessoBeingEditedId,
                        loja: lojaSelecionada,
                    });

                    if (isSuccessed) {
                        idsOfLojasThatWereSuccessfullyAssociated.push(lojaSelecionada.id);
                    }
                }

                if(lojasSelecionadas.length === 1){
                    message.notifySuccess("Loja Associada com sucesso!");
                }else {
                    message.notifySuccess("Lojas Associadas com sucesso!");
                }
            }

            const newLojasAssociadas = lojasSelecionadas.filter((lojaSelecionada) =>
                idsOfLojasThatWereSuccessfullyAssociated.some(
                    (id) => id === lojaSelecionada.id
                )
            );

            actions.setLojasDisponiveisSearchTerm("");

            actions.setPerfilDeAcessoBeingEditedData({
                campo: "lojasAssociadas",
                value: [...newLojasAssociadas, ...lojasAssociadas],
            });

            // clear
            actions.setLojasAssociadasSearchResults([]);
            actions.setLojasAssociadas([...newLojasAssociadas, ...lojasAssociadas]);

            const lojasDisponiveisWithoutAssociatedValues = lojasDisponiveis.filter(
                (lojaDisponivel) => {
                    return ![...newLojasAssociadas, ...lojasAssociadas].some(
                        (lojaAssociada) => lojaAssociada.id === lojaDisponivel.id
                    );
                }
            );

            actions.setLojasSelecionadas([]);
            actions.setLojasDisponiveisSelectedSearchResults([]);
            actions.setLojasDisponiveis(lojasDisponiveisWithoutAssociatedValues);

            if (
                lojasDisponiveisSearchResults.length > 0 &&
                lojasDisponiveisSearchResults.length !== 1
            ) {
                const lojasDisponiveisSearchResultsWithoutAssociatedValues =
                    lojasDisponiveisSearchResults.filter((lojaDisponivel) => {
                        return ![...newLojasAssociadas, ...lojasAssociadas].some(
                            (lojaAssociada) => lojaAssociada.id === lojaDisponivel.id
                        );
                    });
                actions.setLojasDisponiveisSearchResults(
                    lojasDisponiveisSearchResultsWithoutAssociatedValues
                );
            }
        } else {
            actions.setPerfilDeAcessoBeingCreatedData({
                campo: "lojasAssociadas",
                value: [...lojasSelecionadas, ...lojasAssociadas],
            });

            // clear
            actions.setLojasAssociadasSearchResults([]);

            actions.setLojasAssociadas([...lojasSelecionadas, ...lojasAssociadas]);

            const lojasDisponiveisWithoutAssociatedValues = lojasDisponiveis.filter(
                (lojaDisponivel) => {
                    return ![...lojasSelecionadas, ...lojasAssociadas].some(
                        (lojaAssociada) => lojaAssociada.id === lojaDisponivel.id
                    );
                }
            );

            actions.setLojasSelecionadas([]);
            actions.setLojasDisponiveisSelectedSearchResults([]);
            actions.setLojasDisponiveis(lojasDisponiveisWithoutAssociatedValues);
            actions.setLojasDisponiveisSearchTerm("");

            if (
                lojasDisponiveisSearchResults.length > 0 &&
                lojasDisponiveisSearchResults.length !== 1
            ) {
                const lojasDisponiveisSearchResultsWithoutAssociatedValues =
                    lojasDisponiveisSearchResults.filter((lojaDisponivel) => {
                        return ![...lojasSelecionadas, ...lojasAssociadas].some(
                            (lojaAssociada) => lojaAssociada.id === lojaDisponivel.id
                        );
                    });
                actions.setLojasDisponiveisSearchResults(
                    lojasDisponiveisSearchResultsWithoutAssociatedValues
                );
            }
        }
    }

    async function handleRemoveAllFromLojasAssociadas() {

        let idsOfLojasThatWereSuccessfullyRemoved = [];

        if (isInEditMode) {
            await controller.removeAllLojasAssociada({
                perfilDeAcessoId: perfilDeAcessoBeingEditedId,
            });
            for await (const lojaAssociada of lojasAssociadasSelecionadas) {
                idsOfLojasThatWereSuccessfullyRemoved.push(lojaAssociada.id);
            }
        }

        actions.setLojasDisponiveisSearchResults([]);
        actions.setLojasDisponiveisSelectedSearchResults([]);
        actions.setLojasDisponiveisSearchTerm("");

        actions.setLojasAssociadasSearchResults([]);
        actions.setLojasAssociadasSearchTerm("");

        actions.setLojasSelecionadas([]);

        if (lojasAssociadas.length > 0 && isInEditMode) {
            const newLojasDisponiveisValue = [
                ...lojasDisponiveisInitialValueBackup.filter((loja) => {
                    return idsOfLojasThatWereSuccessfullyRemoved.some(
                        (id) => id === loja.id
                    );
                }),
                ...lojasDisponiveisInitialValueBackup.filter((loja) => {
                    return lojasDisponiveis.some(
                        (lojaDisponivel) => lojaDisponivel.id === loja.id
                    );
                }),
            ];

            const newLojasAssociadasValue = lojasAssociadas.filter((loja) => {
                return !idsOfLojasThatWereSuccessfullyRemoved.some(
                    (id) => id === loja.id
                );
            });
            actions.setLojasAssociadas(newLojasAssociadasValue);
            actions.setLojasDisponiveis(newLojasDisponiveisValue);
        }

        if (lojasAssociadas.length > 0 && !isInEditMode) {
            const newLojasDisponiveisValue = [
                ...lojasDisponiveisInitialValueBackup.filter((loja) => {
                    return lojasAssociadasSelecionadas.some(
                        (lojaAssociadaSelecionada) =>
                            lojaAssociadaSelecionada.id === loja.id
                    );
                }),
                ...lojasDisponiveisInitialValueBackup.filter((loja) => {
                    return lojasDisponiveis.some(
                        (lojaDisponivel) => lojaDisponivel.id === loja.id
                    );
                }),
            ];

            const newLojasAssociadasValue = lojasAssociadas.filter(
                (lojaAssociada) => {
                    return !lojasAssociadasSelecionadas.some(
                        (lojaAssociadaSelecionada) =>
                            lojaAssociadaSelecionada.id === lojaAssociada.id
                    );
                }
            );

            actions.setLojasAssociadas(newLojasAssociadasValue);
            actions.setLojasDisponiveis(newLojasDisponiveisValue);
        }

        actions.setLojasAssociadasSelecionadas([]);
    }

    return (
        <div>
            <Row>
                <LojasDisponiveisList/>
                <Col
                    md={2}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    {!(activeTab === "Visualize") && (
                        <>
                            <Tooltip title="Adicionar lojas selecionadas" placement="right">
                                <div
                                    onClick={() => handleAddAllLojasSelectedToLojasAssociadas()}
                                    style={{cursor: "pointer", alignSelf: "center"}}
                                >
                                    <MdKeyboardArrowRight style={{fontSize: "80px"}}/>
                                </div>
                            </Tooltip>
                            <Tooltip
                                title="Remover todas as lojas associadas"
                                placement="right"
                            >
                                <div
                                    style={{cursor: "pointer", alignSelf: "center"}}
                                    onClick={() => handleRemoveAllFromLojasAssociadas()}
                                >
                                    <MdKeyboardArrowLeft style={{fontSize: "80px"}}/>
                                </div>
                            </Tooltip>
                        </>
                    )}
                </Col>
                <LojasAssociadasList/>
            </Row>
        </div>
    );
}

export default AssociacaoLojas;
