import React, { useState, useEffect } from 'react'
import ReactSelect from "react-select";
import { Input } from 'reactstrap';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { OutlinedInput } from '@material-ui/core';
import { BsEyeSlash, BsEyeFill } from 'react-icons/bs'
import { Col, Label } from 'reactstrap';

import './styles.css'

export function Switch(props) {
  const ComponentTop = () => (props.componentTop || <></>)
  const ComponentLeft = () => (props.componentLeft || <></>)
  const ComponentRight = () => (props.componentRight || <></>)
  return (
    <div className="switch-body">
      <div className="switch-container">
        {props.componentTop && <ComponentTop className="switch-text-top" />}

        <div className="switch-row">

          {props.componentLeft &&
            <div className="switch-text-left" >
              <ComponentLeft />
            </div>
          }

          <label className={props.className} style={{margin: '0'}}>
            <input
              {...props}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>

          {props.componentRight &&
            <div className="switch-text-right" >
              <ComponentRight />
            </div>
          }

        </div>
      </div>
    </div>
  )
}

export function Select(props = { options: [] }) {
  const [selected, setselected] = useState({ value: "0", label: props.first, unity: props.second });
  const [options, setoptions] = useState([]);

  useEffect(() => {
    let obj = [];
    if (props.first) {
      obj.push({ value: "0", label: props.first, unity: props.second })
    }

    if (props.options !== undefined) {
      props.options.forEach(option => {
        if (props.select) {
          obj.push({ value: option[props.select.value], label: option[props.select.label], unity: option[props.select.unity] })
        } else {
          obj.push({ value: option.value, label: option.label, unity: option.unity })
        }
      });
      setoptions(obj)
      setselected(props.options.find(option => option.value === "0"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options])

  useEffect(() => {
    options.length > 0 &&
      setselected(options.find(option => option.value === props.value?.value || option.label === props.value?.label))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, props.value])

  return (
    <ReactSelect
      {...props}
      className="react-select info"
      classNamePrefix="react-select"
      value={selected}
      options={options}
      onChange={value => props.onChange(value)}
      noOptionsMessage={() => "Não encontrado"}
      isDisabled={props.disabled}
      styles={{
        control: (base) => ({
          ...base,
          borderColor: props.invalid ? "#ff0000" : "#d3d3d3",
          color: props.invalid ? "#ff0000" : "#000000",
          minHeight: "35px",
          height: "35px"
        })
      }}
    />
  )
}

export function SelectPessoaLoja(props = { options: [] }) {
  const [selected, setselected] = useState({ value: "0", label: props.first, dataInicio: props.second, dataFim: props.third });
  const [options, setoptions] = useState([]);

  useEffect(() => {
    let obj = [];
    if (props.first) {
      obj.push({ value: "0", label: props.first,  dataInicio: props.second, dataFim: props.third })
    }

    if (props.options !== undefined) {
      props.options.forEach(option => {
        if (props.select) {
          obj.push({ value: option[props.select.value], label: option[props.select.label], dataInicio:  option[props.select.dataInicio], dataFim:  option[props.select.dataFim] })
        } else {
          obj.push({ value: option.value, label: option.label, dataInicio: props.second, dataFim: props.third })
        }
      });
      setoptions(obj)
      setselected(props.options.find(option => option.value === "0"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options])

  useEffect(() => {
    options.length > 0 &&
      setselected(options.find(option => option.value === props.value.value || option.label === props.value.label))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  return (
    <ReactSelect
      {...props}
      className="react-select info"
      classNamePrefix="react-select"
      value={selected}
      options={options}
      onChange={value => props.onChange(value)}
      noOptionsMessage={() => "Não encontrado"}
      isDisabled={props.disabled}
      styles={{
        control: (base) => ({
          ...base,
          borderColor: props.invalid ? "#ff0000" : "#d3d3d3",
          color: props.invalid ? "#ff0000" : "#000000",
          minHeight: "35px",
          height: "35px"
        })
      }}
    />
  )
}

export function SelectUnity(props = { options: [] }) {
  const [selected, setselected] = useState({ value: "0", label: props.first, unity: "" });
  const [options, setoptions] = useState([]);

  useEffect(() => {
    let obj = [];
    if (props.first) {
      obj.push({ value: "0", label: props.first, unity: "" })
    }

    if (props.options !== undefined) {
      props.options.forEach(option => {
        if (props.select) {
          obj.push({ value: option[props.select.value], label: option[props.select.label], unity: option[props.select.unity] })
        } else {
          obj.push({ value: option.value, label: option.label, unity: option.unity })
        }
      });
      setoptions(obj)
      setselected(props.options.find(option => option.value === "0"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options])

  useEffect(() => {
    options.length > 0 &&
      setselected(options.find(option => option.value === props.value.value || option.label === props.value.label))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  return (
    <ReactSelect
      {...props}
      className="react-select info"
      classNamePrefix="react-select"
      value={selected}
      options={options}
      onChange={value => { props.onChange(value) }}
      noOptionsMessage={() => "Não encontrado"}
      isDisabled={props.disabled}
      styles={{
        control: (base) => ({
          ...base,
          borderColor: props.invalid ? "#ff0000" : "#d3d3d3",
          color: props.invalid ? "#ff0000" : "#000000",
          minHeight: "35px",
          height: "35px"
        })
      }}
      isMulti={props.multi}
    />
  )
}

export function InputCpfCnpj(props) {
  const [valueInput, setValue] = useState('');
  const tipo = props.tipo === 'cnpj' ? props.tipo : 'cpf';

  useEffect(() => {
    setValue(props.value);
    setMask(props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  function setMask(value) {
    let stringFormated = "";

    if (value.length < valueInput.length) {
      setValue(value);
      return
    }

    value = value.replace(/\D/g, '')
    if (tipo === 'cpf') {

      if (value.length > 3 && value.length <= 6) {
        stringFormated = value.replace(/(\d{3})(\d{1})/, "$1.$2");
        setValue(stringFormated)
        return
      }

      if (value.length === 7) {
        stringFormated = value.replace(/(\d{3})(\d{3})/, "$1.$2.");
        setValue(stringFormated)
        return
      }

      if (value.length <= 9) {
        stringFormated = value.replace(/(\d{3})(\d{3})(\d{1})/, "$1.$2.$3");
        setValue(stringFormated)
        return
      }

      if (value.length > 9) {
        stringFormated = value.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4");
        setValue(stringFormated)
        return
      }
    } else if (tipo === 'cnpj') {

      if (value.length > 2 && value.length <= 5) {
        stringFormated = value.replace(/(\d{2})(\d{1})/, "$1.$2");
        setValue(stringFormated)
        return
      }

      if (value.length > 5 && value.length <= 8) {
        stringFormated = value.replace(/(\d{2})(\d{3})(\d{1})/, "$1.$2.$3");
        setValue(stringFormated)
        return
      }

      if (value.length > 8 && value.length <= 12) {
        stringFormated = value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3/$4");
        setValue(stringFormated)
        return
      }

      if (value.length === 13) {
        stringFormated = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/, "$1.$2.$3/$4-$5");
        setValue(stringFormated)
        return
      }

      if (value.length === 14) {
        stringFormated = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
        setValue(stringFormated)
        return
      }
    }

    setValue(value);
  }

  return (
    <Input
      {...props}
      type="text"
      onChange={e => { props.onChange(e) }}
      value={valueInput}
      maxLength={props.maxLength ? props.maxLength : props.tipo === 'cnpj' ? 18 : 14}
    />
  )
}


/**
 * @param placeholder ?: String | default: ''
 * @param value : any | default: ''
 * @param isInvalid ?: boolean | defaul: false 
 * @param onChange : Function 
 * @param onBlur ?: Function
 * @param label ?: String | default: ''
 **/
export function InputPassword(props) {

  const [showState, setShowState] = useState(false)
  return (
    <>
      <Col style={{ padding: "0" }}>
        <Label for="descricao" style={{ color: 'var(--color-preto)' }}>
          {props.label}
        </Label>
      </Col>
      <Col style={{ padding: "0" }}>
        <OutlinedInput
          id="filled-adornment-password"
          className={'input-default'}
          type={showState ? 'text' : 'password'}
          placeholder={props.placeholder}
          value={props.value}
          error={props.isInvalid}
          onChange={props.onChange}
          onKeyPress={props.onKeyPress}
          onBlur={props.onBlur}
          endAdornment={
            <InputAdornment position="end"  >
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowState(!showState)}
                edge="end"
              >
                {showState ? <BsEyeSlash /> : <BsEyeFill />}
              </IconButton>
            </InputAdornment>
          }
        />
      </Col>

    </>
  )
}


