import React, { useEffect } from "react";

import {
  Modal,
  ModalBody,
  Col,
  Button,
  Input,
  Label,
  Row,
  Navbar,
} from "reactstrap";

import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

import { useSelector } from "react-redux";
import * as actions from "../../../store/modules/layoutParametrizacao/actions";
import * as controller from "../../../controllers/layoutParametrizacaoController";

import { Switch } from "../../../components/Inputs/Input";
import { notifyWarn } from "../../../components/message/message";

export function ModalStatus({ isOpen, handleAddMapeamento, isEditable }) {
  const {
    status,
    isInVisualizeMode,
    isInEditMode,
    mapeamento,
    layoutBeingCreated,
    layoutBeingEdited,
    layoutBeingVisualized,
  } = useSelector((state) => state.layoutParametrizacao);

  const layout = isInVisualizeMode
    ? layoutBeingVisualized
    : isInEditMode
    ? layoutBeingEdited
    : layoutBeingCreated;

  const statusAlreadyAdded =
    layout.layoutPlanilhaParametros?.find((layout) => layout.status.length > 0)
      ?.status ?? [];

  const statusList = isEditable ? statusAlreadyAdded : mapeamento.status;

  const hasCampoVazio = statusList.some(
    (status) => status.aceitaVazio === true
  );

  useEffect(() => {
    actions.clearStatus();
  }, []);

  function handleAddStatus() {
    if (status.aceitaVazio && hasCampoVazio) {
      return notifyWarn("Apenas um campo vazio é permitido.");
    }
    if (!status.aceitaVazio && status.descricao === "") {
      return notifyWarn("Informe o nome do campo ou marque como vazio.");
    }
    if (status.tiposComissaoId === "0") {
      return notifyWarn("Informe o tipo comissão.");
    }

    if (status.aceitaVazio) {
      status.descricao = "";
    }

    if (!isEditable) {
      actions.setMapeamento("status", [status, ...mapeamento.status]);
      actions.clearStatus();
    } else {
      const oldParametroValue = layout.layoutPlanilhaParametros.find(
        (layout) => layout.status.length > 0
      );

      const newLayoutPlanilhaParametros = {
        ...layout,
        layoutPlanilhaParametros: [
          {
            ...oldParametroValue,
            status: [status, ...statusList],
          },
          ...layout.layoutPlanilhaParametros.filter(
            (layout) =>
              layout.layoutPlanilhaCamposEquivalenteId !==
              "53454cd7-0d70-47ef-aea7-f850d3c5f97c"
          ),
        ],
      };

      if (isInEditMode) {
        actions.setLayoutBeingEdited(newLayoutPlanilhaParametros);
      } else {
        actions.setLayoutBeingCreated(newLayoutPlanilhaParametros);
      }

      actions.clearStatus();
    }
  }

  async function handleDeleteStatus(id, i) {
    const newStatusValue = [...statusList];
    newStatusValue.splice(i, 1);

    const oldParametroValue = layout.layoutPlanilhaParametros.find(
      (layout) => layout.status.length > 0
    );
    const newLayoutPlanilhaParametros = {
      ...layout,
      layoutPlanilhaParametros: [
        {
          ...oldParametroValue,
          status: newStatusValue,
        },
        ...layout.layoutPlanilhaParametros.filter(
          (layout) =>
            layout.layoutPlanilhaCamposEquivalenteId !==
            "53454cd7-0d70-47ef-aea7-f850d3c5f97c"
        ),
      ],
    };

    if (!id) {
      if (isEditable) {
        if (isInEditMode) {
          actions.setLayoutBeingEdited(newLayoutPlanilhaParametros);
        } else {
          actions.setLayoutBeingCreated(newLayoutPlanilhaParametros);
        }
      } else {
        actions.setMapeamento("status", newStatusValue);
      }
    } else {
      var isSucceeded = await controller.removeStatus(id);
      if (isSucceeded) {
        if (isInEditMode) {
          actions.setLayoutBeingEdited(newLayoutPlanilhaParametros);
        } else {
          actions.setLayoutBeingCreated(newLayoutPlanilhaParametros);
        }
      }
    }

    if (newStatusValue.length === 0 && isEditable) {
      actions.setIsModalStatusOpen(!isOpen);
    }
  }

  async function handleConfirmar(e) {
    if (!isEditable) {
      if (mapeamento.status.length === 0) {
        return notifyWarn("Informe ao menos um status");
      }
      await handleAddMapeamento(e);
    }

    actions.clearStatus();
    actions.setIsModalStatusOpen(!isOpen);
  }

  return (
    <>
      <Modal
        className="text-center"
        isOpen={isOpen}
        toggle={() => actions.setIsModalStatusOpen(!isOpen)}
        backdrop={true}
        size="lg"
      >
        <Navbar
          expand="md"
          style={{
            width: "100%",
            height: "40px",
            background: "var(--color-gradiente-verde)",
            marginBottom: "20px",
          }}
        >
          <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2">
            Detalhamento do status
          </h5>
        </Navbar>
        <ModalBody>
          <div>
            {!isInVisualizeMode ? (
              <Row md={12} style={{ marginLeft: "5rem", paddingRight: "0" }}>
                <Col md={1} style={{ display: "flex" }}>
                  {!hasCampoVazio && (
                    <>
                      <Label
                        style={{
                          fontWeight: "700",
                          color: "var(--color-preto)",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Vazio
                      </Label>
                      <div style={{ marginLeft: "-50px", marginTop: "25px" }}>
                        <Switch
                          className="switch"
                          checked={status.aceitaVazio}
                          onChange={() =>
                            actions.setStatus(
                              "aceitaVazio",
                              !status.aceitaVazio
                            )
                          }
                          disabled={isInVisualizeMode}
                          readOnly={isInVisualizeMode}
                        />
                      </div>
                    </>
                  )}
                </Col>
                <Col md={8}>
                  <Label
                    for="convenio"
                    style={{
                      fontWeight: "700",
                      color: "var(--color-preto)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Campo planilha
                  </Label>
                  <Input
                    type="text"
                    placeholder={status.aceitaVazio ? "" : "Digite aqui..."}
                    value={status.aceitaVazio ? "" : status.descricao}
                    onChange={(e) =>
                      actions.setStatus("descricao", e.target.value)
                    }
                    disabled={isInVisualizeMode ? true : status.aceitaVazio}
                    style={{ height: "37px" }}
                  />
                </Col>
                <Col md={1}>
                  <button
                    style={{
                      border: "none",
                      background: "transparent",
                      margin: "25px 0 0 -0",
                      color: "var(--color-preto)",
                      cursor: "pointer",
                    }}
                    onClick={handleAddStatus}
                  >
                    <AiOutlinePlusCircle
                      style={{ color: "var(--color-verde)", fontSize: "30px" }}
                    />
                  </button>
                </Col>
              </Row>
            ) : null}
            {statusList.map((s, i) => (
              <Row md={12} style={{ marginLeft: "5rem" }} key={`${s.descricao}-${i}`}>
                <Col md={1} style={{ display: "flex" }}>
                  <Label
                    style={{
                      fontWeight: "700",
                      color: "var(--color-preto)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Vazio
                  </Label>
                  <div style={{ marginLeft: "-50px", marginTop: "25px" }}>
                    <Switch
                      className="switch"
                      checked={s.aceitaVazio}
                      disabled
                      readOnly
                    />
                  </div>
                </Col>
                <Col md={8}>
                  <Label
                    for="convenio"
                    style={{
                      fontWeight: "700",
                      color: "var(--color-preto)",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Campo planilha
                  </Label>
                  <Input
                    type="text"
                    value={s.descricao}
                    disabled
                    readOnly
                    style={{ height: "37px" }}
                  />
                </Col>
                {!isInVisualizeMode ? (
                  <Col md={1}>
                    <button
                      style={{
                        border: "none",
                        background: "transparent",
                        margin: "25px 0 0 -0",
                        color: "var(--color-preto)",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteStatus(s.id, i)}
                    >
                      <AiOutlineMinusCircle
                        style={{
                          color: "var(--color-laranja)",
                          fontSize: "30px",
                        }}
                      />
                    </button>
                  </Col>
                ) : null}
              </Row>
            ))}
          </div>
        </ModalBody>
        <div className="m-3">
          <Button
            onClick={() => {
              actions.clearStatus();
              actions.setIsModalStatusOpen(!isOpen);
            }}
            className="mr-4"
            style={{
              width: "150px",
              background: "var(--color-branco)",
              borderColor: "var(--color-laranja)",
              color: "var(--color-laranja)",
              borderRadius: "7px",
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={(e) => handleConfirmar(e)}
            color="danger"
            className="purpleButton"
            style={{ width: "150px" }}
          >
            Confirmar
          </Button>
        </div>
      </Modal>
    </>
  );
}
