const STORE_ID = "DIVISAO_COMISSAO";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    SET_LOJAS_BANCO: 'SET_LOJAS_BANCO',
    SET_TIPOS_COMISSAO: 'SET_TIPOS_COMISSAO',
    SEARCH: "SEARCH",
    DIVISAO_COMISSAO: "DIVISAO_COMISSAO",
    INVALID_DIVISAO_COMISSAO: "INVALID_DIVISAO_COMISSAO",
    DIVISAO_COMISSOES: "DIVISAO_COMISSOES",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    CLEAN_INVALID_DIVISAO_COMISSAO: "CLEAN_INVALID_DIVISAO_COMISSAO",
    CLEAN_ALL_SEARCH: "CLEAN_ALL_SEARCH",
    CLEAN_SEARCH: "CLEAN_SEARCH",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    GET_ALL_ITEMS: "GET_ALL_ITEMS",
    SET_MODAL_LOJA: "SET_MODAL_LOJA",
    CLEAN_MODAL_LOJA: "CLEAN_MODAL_LOJA",
    SET_LOJAS: "SET_LOJAS",
    SET_GET_ALL_LOJA: "SET_GET_ALL_LOJA",
    CLEAN_GET_ALL_LOJA: "CLEAN_GET_ALL_LOJA",
    SET_MODAL_LOJA_MATRIZ: "SET_MODAL_LOJA_MATRIZ",
    CLEAN_MODAL_LOJA_MATRIZ: "CLEAN_MODAL_LOJA_MATRIZ",
    SET_LOJAS_MATRIZES: "SET_LOJAS_MATRIZES",
    SET_GET_ALL_LOJA_MATRIZ: "SET_GET_ALL_LOJA_MATRIZ",
    CLEAN_GET_ALL_LOJA_MATRIZ: "CLEAN_GET_ALL_LOJA_MATRIZ",
    ADD_LOJA: "ADD_LOJA",
    REMOVE_LOJA: "REMOVE_LOJA",
    SET_BANCOS: "SET_BANCOS",
    SET_LOJAS_SEM_DIVISAO_REGISTER: "SET_LOJAS_SEM_DIVISAO_REGISTER",
    SET_LOJAS_REGISTER: "SET_LOJAS_REGISTER",
    SET_LOJAS_MATRIZES_REGISTER: "SET_LOJAS_MATRIZES_REGISTER",
    CLEAN_LOJAS_SEM_DIVISAO_REGISTER: "CLEAN_LOJAS_SEM_DIVISAO_REGISTER",
    CLEAN_LOJAS_REGISTER: "CLEAN_LOJAS_REGISTER",
    CLEAN_LOJAS_MATRIZES_REGISTER: "CLEAN_LOJAS_MATRIZES_REGISTER",
    SET_DIVISAO_COMISSAO_CARD: "SET_DIVISAO_COMISSAO_CARD",
    CLEAN_DIVISAO_COMISSAO_CARD: "CLEAN_DIVISAO_COMISSAO_CARD",
    SET_SEE: "SET_SEE",
    SET_LOADING_LOJA: "SET_LOADING_LOJA",
    SET_LOADING_LOJA_MATRIZ: "SET_LOADING_LOJA_MATRIZ"
}

export { STORE_ID, TYPES }