export const COLUMNS = [
    {
        Header: "Descrição",
        accessor: "descricao"
    },
    {
        Header: "Instituição Financeira",
        accessor: "banco.nome"
    },
    {
        Header: "Tipo de Pendência Ribercred",
        accessor: "tipoPendencia.descricao"
    },
]


