import { STORE_ID, TYPES } from "./types";

const INITIAL_STATE = {
  search: {
    lojaBanco: "",
    instituicaoId: "0",
    centroCustoId: "0",
    loja: "",
    lojaId: "0",
    tipoComissaoId: "0",
    DataEmissaoInicial: "",
    DataEmissaoFinal: "",
    DataVencimentoInicial: "",
    DataVencimentoFinal: "",
    nomeArquivo: "",
    contaBancaria: "0",
    statusComissaoRecebidaId: "0",
    documento: "",
    titulo: "",
    integrado: "0",
    contaCorrente: "",
    canais: "0",
    lojasMatrizes: "",
  },
  comissoesRecebidaAgrupado: [],
  getAllAgrupado: {
    quantidade: "0",
    quantidadeTotal: "0",
    totalPaginas: "0",
  },
  comissoesRecebida: [],
  getAll: {
    quantidade: "0",
    quantidadeTotal: "0",
    totalPaginas: "0",
  },
  lojas: [],
  lojasMatrizes: [],
  canaisAll: [],
  getAllLoja: {
    quantidade: "0",
    quantidadeTotal: "0",
    totalPaginas: "0",
  },
  arquivo: "",
  arquivoCp: "",
  instituicoes: [],
  centrosCusto: [],
  tiposComissao: [],
  contasBancaria: [],
  documento: [],
  contaCorrente: [],
  canalId: [
    {
      id: "7dd40f59-82e4-4d1e-8792-84a70a44ee1c",
      nome: "TERCEIRIZADO",
    },
    {
      id: "eeafe6f2-8e3a-436c-9065-daf6d0b483c0",
      nome: "PROPRIO",
    },
  ],
  integrado: [
    {
      id: true,
      descricao: "Integrado",
    },
    {
      id: false,
      descricao: "Não Integrado",
    },
  ],
  activeTab: "Search",
  permitions: {
    criar: false,
    deletar: false,
    autorizar: false,
  },
  statusComissaoRecebida: [],
};

export default function conferenciaCr(state = INITIAL_STATE, action) {
  switch (action.storeId) {
    case STORE_ID:
      switch (action.type) {
        case TYPES.SET_PERMITIONS:
          return { ...state, permitions: action.value };
        case TYPES.SET_ACTIVE_TAB:
          return {
            ...state,
            activeTab: action.value,
          };
        case TYPES.SEARCH:
          return {
            ...state,
            search: { ...state.search, [action.field]: action.value },
          };
        case TYPES.SET_LOJAS:
          return { ...state, lojas: action.value };
        case TYPES.SET_ALL_LOJA:
          return { ...state, getAllLoja: action.value };
        case TYPES.SET_LOJAS_MATRIZES:
          return { ...state, lojasMatrizes: action.value };
        case TYPES.SET_All_CONTA_CORRENTE:
          return { ...state, contaCorrente: action.value };
        case TYPES.SET_INSTITUICOES:
          return { ...state, instituicoes: action.value };
        case TYPES.SET_CENTROS_CUSTO:
          return { ...state, centrosCusto: action.value };
        case TYPES.SET_TIPOS_COMISSAO:
          return { ...state, tiposComissao: action.value };
        case TYPES.SET_CONTAS_BANCARIA:
          return { ...state, contasBancaria: action.value };
        case TYPES.SET_CANAL_ID:
          return { ...state, canalId: action.value };
        case TYPES.SET_CANAL_ALL:
          return { ...state, canaisAll: action.value };
        case TYPES.SET_COMISSOES_AGRUPADO:
          return { ...state, comissoesRecebidaAgrupado: action.value };
        case TYPES.SET_ALL_AGRUPADO:
          return { ...state, getAllAgrupado: action.value };
        case TYPES.SET_COMISSOES:
          return { ...state, comissoesRecebida: action.value };
        case TYPES.SET_DOWNLOAD:
          return { ...state, arquivo: action.value };
        case TYPES.SET_DOWNLOAD_CP:
          return { ...state, arquivoCp: action.value };
        case TYPES.INTEGRADO:
          return { ...state, integrado: action.value };

        case TYPES.SET_ALL:
          return { ...state, getAll: action.value };
        case TYPES.CLEAN_SEARCH:
          return {
            ...state,
            search: INITIAL_STATE.search,
          };
        case TYPES.CLEAN_ALL_SEARCH:
          return {
            ...state,
            search: INITIAL_STATE.search,
            comissoesRecebidaAgrupado: [],
            getAllAgrupado: [],
            comissoesRecebida: [],
            getAll: [],
          };
        case TYPES.SET_STATUS_COMISSAO_RECEBIDA:
          return { ...state, statusComissaoRecebida: action.value };
        default:
          return {
            ...state,
          };
      }
    default:
      return {
        ...state,
      };
  }
}
