import api from "../util/api";
import {setLoading} from "../store/modules/globalLoading/actions";
import * as contaGerencialActions from "../store/modules/contaGerencial/actions";
import {notifyInfo, notifySuccess} from "../components/message/message";
import * as actions from "../store/modules/contaGerencial/actions";

const contaGerencialTipoDespesa = "1B4134BA-849B-420A-AB0E-E535B292D2BB";
const contaGerencialTipoReceita = "78544D5D-F2D7-4DA5-8FAF-14EB7ACB3A71";

function pageInitialState() {
    actions.cleanProdutoContaGerencial();
    actions.cleanDescricaoContaGerencial();
    actions.cleanContaGerencial();
    getProdutosRiber();
    getTiposComissao();
    actions.setIsDespesa(false);
    actions.setTipoIsProduto(false);
    actions.setSeeRegisterContaGerencial(false);
    actions.setIsUpdate(false);
    actions.setActiveTabContaGerencial("Search");
    getAll(1, 10);
}

export async function getProdutosRiber() {
    setLoading(true);
    const data = await api.get("Produtos");
    setLoading(false);

    if (!data.success) {
        return
    }

    contaGerencialActions.setProdutosRiberContaGerencial(data.content)
}

export async function getTiposComissao() {
    setLoading(true);
    const data = await api.get("TipoComissao")
    setLoading(false);

    if (!data.success) {
        return
    }

    contaGerencialActions.setTiposComissaoContaGerencial(data.content)
}

export async function getTiposContaGerencial(){
    setLoading(true);
    const data = await api.get(`ContaGerencial/tipos-conta-gerencial`);
    setLoading(false);

    if (!data.success){
        return { valid: false }
    }

    actions.setTiposConta(data.content);
}

export async function registraProdutoContaGerencial(produto, isDespesa) {
    if (produto.produtoRiber === '0') {
        notifyInfo("Favor selecionar o Produto Riber!")
        return {valid: false};
    }

    if (produto.tipoComissao === '0') {
        notifyInfo("Favor selecionar o tipo comissão!")
        return {valid: false};
    }

    if (produto.codigoContaGerencial === "") {
        notifyInfo("Favor informar o Código da conta gerencial")
        return {valid: false}
    }

    let body = {
        codigoContaGerencial: produto.codigoContaGerencial,
        produtoId: produto.produtoRiber,
        tipoComissaoId: produto.tipoComissao,
        contaGerencialTipoId: isDespesa === true ? contaGerencialTipoDespesa : contaGerencialTipoReceita
    }
    setLoading(true);
    const data = await api.post(`ContaGerencial/registra-produto-conta-gerencial`, body);
    setLoading(false);

    if (!data.success) {
        return {valid: false}
    }

    notifySuccess("Conta gerencial cadastrada");
    pageInitialState();
}

export async function registraDescricaoContaGerencial(descricao, isDespesa) {
    if (descricao.informacao === '') {
        notifyInfo("Favor descrever uma informação");
        return {valid: false}
    }

    if (descricao.classificacaoReceita === '') {
        notifyInfo("Favor informar uma classificação da receita");
        return {valid: false}
    }

    if (descricao.codigoContaGerencial === "") {
        notifyInfo("Favor informar o Código da conta gerencial")
        return {valid: false}
    }

    const body = {
        informacao: descricao.informacao,
        classificacaoReceita: descricao.classificacaoReceita,
        codigoContaGerencial: descricao.codigoContaGerencial,
        contaGerencialTipoId: isDespesa === true ? contaGerencialTipoDespesa : contaGerencialTipoReceita
    }

    setLoading(true);
    const data = await api.post(`ContaGerencial/registra-descricao-conta-gerencial`, body);
    setLoading(false);

    if (!data.success) {
        return {valid: false}
    }

    notifySuccess("Conta gerencial cadastrada");
    pageInitialState();
}

export async function atualizaProdutoContaGerencial(produto, isDespesa) {
    if (produto.produtoRiber === '0') {
        notifyInfo("Favor selecionar o Produto Riber!")
        return {valid: false};
    }

    if (produto.tipoComissao === '0') {
        notifyInfo("Favor selecionar o tipo comissão!")
        return {valid: false};
    }

    if (produto.codigoContaGerencial === "") {
        notifyInfo("Favor informar o Código da conta gerencial")
        return {valid: false}
    }

    let body = {
        codigoContaGerencial: produto.codigoContaGerencial,
        produtoId: produto.produtoRiber,
        tipoComissaoId: produto.tipoComissao,
        contaGerencialTipoId: isDespesa === true ? contaGerencialTipoDespesa : contaGerencialTipoReceita

    }
    setLoading(true);
    const data = await api.put(`ContaGerencial/atualiza-produto-conta-gerencial/${produto.id}`, body);
    setLoading(false);

    if (!data.success) {
        return { valid: false };
    }

    notifySuccess("Conta gerencial atualizada com sucesso!");
    pageInitialState();
}

export async function atualizaDescricaoContaGerencial(descricao, isDespesa) {
    if (descricao.informacao === '') {
        notifyInfo("Favor descrever uma informação");
        return {valid: false}
    }

    if (descricao.classificacaoReceita === '') {
        notifyInfo("Favor informar uma classificação da receita");
        return {valid: false}
    }

    if (descricao.codigoContaGerencial === "") {
        notifyInfo("Favor informar o Código da conta gerencial")
        return {valid: false}
    }

    let body = {
        informacao: descricao.informacao,
        classificacaoReceita: descricao.classificacaoReceita,
        codigoContaGerencial: descricao.codigoContaGerencial,
        contaGerencialTipoId: isDespesa === true ? contaGerencialTipoDespesa : contaGerencialTipoReceita
    }

    setLoading(true)
    const data = await api.put(`ContaGerencial/altera-descricao-conta-gerencial/${descricao.id}`, body);
    setLoading(false);

    if (!data.success) {
        return {valid: false}
    }

    notifySuccess("Conta gerencial atualizada com sucesso!");
    pageInitialState();
}

export async function removeContaGerencial(id) {
    setLoading(true)
    const data = await api.delete(`ContaGerencial/remove-conta-gerencial/${id}`);
    setLoading(false)

    if (!data.success) {
        return {valid: false}
    }

    notifySuccess("Conta gerencial removida!")
    pageInitialState();
}

export async function getAll(page, pageSize) {
    let url = `ContaGerencial?pagina=${page}&registros=${pageSize}`;

    setLoading(true)
    const data = await api.get(url,
        {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("TOKEN_KEY"),
                'Content-Type': 'aplication/json'
            }
        });
    setLoading(false)

    if (!data.success) {
        return {valid: false};
    }

    let body = [];

    data.content.registros.forEach((e) => {
        body.push({
            id: e.id,
            classificacaoReceitaContaGerencial: e.classificacaoReceita,
            codigoContaGerencial: e.codigoContaGerencial,
            tipoConta: e.contaGerencialTipo,
            identificadorContaGerencial: e.identificadorW3ERP,
            informacaoContaGerencial: e.informacao,
            produtoRiberContaGerencial: e.produto,
            tipoComissaoContaGerencial: e.tipoComissao
        })
    })

    actions.setContasGerenciais(body);
    actions.setGetAll({
        quantidade: data.content.quantidade,
        quantidadeTotal: data.content.quantidadeTotal,
        totalPaginas: data.content.totalPaginas,
        tamanhoPagina: data.content.tamanhoPagina
    });
}

export async function getWithFilter(codigoContaGerencial, produtoRiber, tipoComissao, informacao, classificacaoReceita, tipoContaGerencial, isProduto, isDescricao, page, pageSize) {
    produtoRiber = produtoRiber === '0' ? '00000000-0000-0000-0000-000000000000' : produtoRiber;
    tipoComissao = tipoComissao === '0' ? '00000000-0000-0000-0000-000000000000' : tipoComissao;
    tipoContaGerencial = tipoContaGerencial === '0' ? '00000000-0000-0000-0000-000000000000' : tipoContaGerencial;

    let url =
        `ContaGerencial/pesquisa-conta-gerencial?codigoContaGerencial=${codigoContaGerencial}
        &produtoRiberId=${produtoRiber}&tipoComissaoId=${tipoComissao}&informacao=${informacao}
        &classificacaoReceita=${classificacaoReceita}&tipoContaGerencialId=${tipoContaGerencial}
        &isProduto=${isProduto}&isDescricao=${isDescricao}&pagina=${page}&registros=${pageSize}`

    setLoading(true)
    const data = await api.get(url);
    setLoading(false)

    if (!data.success) {
        return {valid: false};
    }

    let body = []

    data.content.registros.forEach((e) => {
        body.push({
            id: e.id,
            classificacaoReceitaContaGerencial: e.classificacaoReceita,
            codigoContaGerencial: e.codigoContaGerencial,
            tipoConta: e.contaGerencialTipo,
            identificadorContaGerencial: e.identificadorW3ERP,
            informacaoContaGerencial: e.informacao,
            produtoRiberContaGerencial: e.produto,
            tipoComissaoContaGerencial: e.tipoComissao
        })
    })

    actions.setContasGerenciais(body);
    actions.setGetAll({
        quantidade: data.content.quantidade,
        quantidadeTotal: data.content.quantidadeTotal,
        totalPaginas: data.content.totalPaginas,
        tamanhoPagina: data.content.tamanhoPagina
    });
}

export async function getById(id) {
    setLoading(true);
    const data = await api.get(`ContaGerencial/${id}`);
    setLoading(false);

    if (!data.success) {
        return {valid: false}
    }

    const newData = {
        item: {
            produto: {
                codigoContaGerencial: data.content.codigoContaGerencial,
                isProduto: data.content.produtoId !== null ? true : false,
                produtoRiber: data.content.produtoId,
                tipoComissao: data.content.tipoComissaoId
            },
            descricao: {
                informacao: data.content.informacao,
                classificacaoReceita: data.content.classificacaoReceita,
                codigoContaGerencial: data.content.codigoContaGerencial,
                isDescricao: data.content.produtoId === null ? true : false,
            }
        }
    }

    const produto = {
        id: data.content.id,
        codigoContaGerencial: data.content.codigoContaGerencial,
        isProduto: data.content.produtoId !== null ? true : false,
        produtoRiber: data.content.produtoId,
        tipoComissao: data.content.tipoComissaoId
    }

    const descricao = {
        id: data.content.id,
        informacao: data.content.informacao,
        classificacaoReceita: data.content.classificacaoReceita,
        codigoContaGerencial: data.content.codigoContaGerencial,
        isDescricao: data.content.produtoId === null ? true : false,
    }

    if (data.content.contaGerencialTipoId.toLowerCase() === contaGerencialTipoDespesa.toLowerCase()) {
        actions.setIsDespesa(true);
    } else if (data.content.contaGerencialTipoId.toLowerCase() === contaGerencialTipoReceita.toLowerCase()) {
        actions.setIsDespesa(false);
    }

    if (data.content.produtoId !== null) {
        actions.setTipoIsProduto(true)
    } else if (data.content.produtoId === null) {
        actions.setTipoIsProduto(false);
    }

    actions.copy(newData);
    actions.setProdutoContaGerencial(produto);
    actions.setDescricaoContaGerencial(descricao);
}
