import { STORE_ID, TYPES } from './types'
import store from '../../index'

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data)
}

export function setItem(value) {
    dispatch({
        type: TYPES.SET_ITEM,
        value
    })
}

export function setItems(value) {
    dispatch({
        type: TYPES.SET_ITEMS,
        value
    })
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function setPerfis(value) {
    dispatch({
        type: TYPES.SET_PERFIS,
        value
    })
}

export function copy(value) {
    dispatch({
        type: TYPES.USUARIO_COPY,
        value
    })
}
export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_USUARIO,
        field
    })

}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_USUARIO,
        field
    })
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function setSearch(value) {
    dispatch({
        type: TYPES.SEARCH,
        value
    })

}

export function cleanSearch() {
    dispatch({
        type: TYPES.CLEAN_SEARCH
    })
}

export function setVerifyPass(value) {
    dispatch({
        type: TYPES.VERIFYPASS,
        value
    })
}

export function setSeeUpdate(value) {
    dispatch({
        type: TYPES.SEEUPDATE,
        value
    })
}

export function setChangePass(value) {
    dispatch({
        type: TYPES.CHANGEPASS,
        value
    })

}

export function setPass(value) {
    dispatch({
        type: TYPES.SETSENHA,
        value
    })
}

export function getAllItems(value) {
    dispatch({
        type: TYPES.GET_ALL_ITEMS,
        value
    })
}

export function setSistemas(value) {
    dispatch({
        type: TYPES.SET_SISTEMAS,
        value
    })
}

export function setBlock(value) {
    dispatch({
        type: TYPES.SET_BLOCK,
        value
    })
}