import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function setItem(field, value) {
    dispatch({
        type: TYPES.SET_ITEM,
        field,
        value
    });
}

export function copy(value) {
    dispatch({
        type: TYPES.SET_COPY,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.SET_INVALID,
        field
    });
}

export function getTipos(value) {
    dispatch({
        type: TYPES.SET_TIPOS,
        value
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID,
        field
    });
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}
