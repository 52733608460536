import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function pesquisar(campo, value) {
    dispatch({
        type: TYPES.PESQUISAR,
        campo,
        value
    })

}

export function item(value) {
    dispatch({
        type: TYPES.ITEM,
        value
    })

}

export function setItem(campo, value) {
    dispatch({
        type: TYPES.SET_ITEM,
        campo,
        value
    })

}

export function setModalFornecedor(campo, value) {
    dispatch({
        type: TYPES.SET_MODAL_FORNECEDOR,
        campo,
        value
    })

}

export function setModalFilial(campo, value) {
    dispatch({
        type: TYPES.SET_MODAL_FILIAL,
        campo,
        value
    })

}

export function setModalFavorecido(campo, value) {
    dispatch({
        type: TYPES.SET_MODAL_FAVORECIDO,
        campo,
        value
    })

}

export function setEmpresas(value) {
    dispatch({
        type: TYPES.SET_EMPRESAS,
        value
    })

}
export function setFiliais(value) {
    dispatch({
        type: TYPES.SET_FILIAIS,
        value
    })

}
export function setFornecedores(value) {
    dispatch({
        type: TYPES.SET_FORNECEDORES,
        value
    })

}
export function setFavorecidos(value) {
    dispatch({
        type: TYPES.SET_FAVORECIDOS,
        value
    })

}
export function getFavorecidos(value) {
    dispatch({
        type: TYPES.SET_GET_FAVORECIDO,
        value
    })

}
export function getFornecedores(value) {
    dispatch({
        type: TYPES.SET_GET_FORNECEDOR,
        value
    })

}
export function getFiliais(value) {
    dispatch({
        type: TYPES.SET_GET_FILIAL,
        value
    })

}
export function setAllItens(value) {
    dispatch({
        type: TYPES.SET_ALL_ITENS,
        value
    })

}
export function setContas(value) {
    dispatch({
        type: TYPES.SET_CONTAS,
        value
    })

}

export function setCopia(value) {
    dispatch({
        type: TYPES.SET_COPIA,
        value
    })

}

export function cleanSearch() {
    dispatch({
        type: TYPES.CLEAN_SEARCH
    })
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function setLoadingFilial(value) {
    dispatch({
        type: TYPES.SET_LOADING_FILIAL,
        value
    })

}

export function setLoadingFavorecido(value) {
    dispatch({
        type: TYPES.SET_LOADING_FAVORECIDO,
        value
    })

}

export function setLoadingFornecedor(value) {
    dispatch({
        type: TYPES.SET_LOADING_FORNECEDOR,
        value
    })

}
