const STORE_ID = "UPLOAD_COMISSAO";

const TYPES = {
    PESQUISAR: 'PESQUISAR',
    COPIA: 'COPIA',
    SET_TIPOS: 'SET_TIPOS',
    SET_PERMITIONS: 'SET_PERMITIONS',
    SET_BANCOS: 'SET_BANCOS',
    SET_LAYOUT_PLANILHA: 'SET_LAYOUT_PLANILHA',
    SET_UPLOADS: 'SET_UPLOADS',
    SEARCH: "SEARCH",
    LAYOUTS: "LAYOUTS",
    STATUS_IMPORTACOES: "STATUS_IMPORTACOES",
    SET_STATUS_IMPORTACAO: "STATUS_IMPORTACAO",
    SET_NOME_LAYOUT: "SET_NOME_LAYOUT",
    SET_ITEM: 'SET_ITEM',
    SET_DOWNLOAD: 'SET_DOWNLOAD',
    CLEAN_DOWNLOAD: 'CLEAN_DOWNLOAD',
    CLEAN_SEARCH: 'CLEAN_SEARCH',
    CLEAN_REGISTER: 'CLEAN_REGISTER',
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    GET_ALL_ITEMS: "GET_ALL_ITEMS",
    SET_POSSUI_INCONSISTENCIA: "SET_POSSUI_INCONSISTENCIA",
    GET_ALL_ITEMS_INCONSISTENCIA: "GET_ALL_ITEMS_INCONSISTENCIA"
}

export { STORE_ID, TYPES }