import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    filtros:{
        consultor: '',
        consultorId: '0',
        tipoParceriaId: '0',
        campanhaId: '0',
        grupoId: '0',
        canalId: '0',
        centroCustoId: '0',
        status: '0',
    },
    modalConsultores: {
        id: '',
        nome: '',
        cpf: '',
        hierarquiaId: '0',
        canalId: '0',
        status: '0',
    },
    getAllConsultores: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    campanha:{
        metaMes: {
            valor: '',
            quantidade:''
        },
        producaoMes: {
            valor: '',
            quantidade:''
        },
        metaMesRestante: {
            valor: '',
            quantidade:''
        },
        metasAlcancadas: {
            valor: '',
            quantidade:''
        },
        projecaoGanho: {
            valor: '',
            quantidade:''
        },
        posicaoGeral: {
            valor: '',
            quantidade:''
        },
        metaDu: {
            valor: '',
            quantidade:''
        },
        metaDuRecalculada: {
            valor: '',
            quantidade:''
        },
        valorProjetado: {
            valor: '',
            quantidade:''
        }
    },
    produtos: [],
    tabela: [],
    tiposParceria: [],
    campanhas: [],
    grupos: [],
    centrosCusto: [],
    hierarquias: [],
    consultores: [],
    canais: [],
    statusFuncionario:[{
        id: true,
        descricao: 'Ativo'
    },{
        id: false,
        descricao: 'Inativo'
    
    }],
    isConsultor: true,
    loadingLojas: false,
    loadingConsultores: false,
}

export default function dashboardCampanha(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_FILTROS:
                    return { ...state, filtros:{...state.filtros, [action.campo] : action.value} }
                case TYPES.SET_MODAL_CONSULTORES:
                    return { ...state, modalConsultores: { ...state.modalConsultores, [action.campo]: action.value } }
                case TYPES.SET_GET_ALL_CONSULTORES:
                    return { ...state, getAllConsultores: action.value }
                case TYPES.SET_CAMPANHA:
                    return { ...state, campanha: action.value  }
                case TYPES.SET_PRODUTOS:
                    return { ...state, produtos: action.value }
                case TYPES.SET_TABELA:
                    return { ...state, tabela: action.value }
                case TYPES.SET_TIPOS_PARCERIA:
                    return { ...state, tiposParceria: action.value }
                case TYPES.SET_CAMPANHAS:
                    return { ...state, campanhas: action.value }
                case TYPES.SET_GRUPOS:
                    return { ...state, grupos: action.value }
                case TYPES.SET_CENTROS_CUSTO:
                    return { ...state, centrosCusto: action.value }
                case TYPES.SET_HIERARQUIAS:
                    return { ...state, hierarquias: action.value }
                case TYPES.SET_CONSULTORES:
                    return { ...state, consultores: action.value }
                case TYPES.SET_CANAIS:
                    return { ...state, canais: action.value }
                case TYPES.SET_STATUS:
                    return { ...state, statusFuncionario: action.value }
                case TYPES.SET_IS_CONSULTOR:
                    return { ...state, isConsultor: action.value }
                case TYPES.CLEAN_FILTROS:
                    return { ...state, filtros: INITIAL_STATE.filtros}
                case TYPES.SET_LOADING_LOJAS:
                    return { ...state, loadingLojas: action.value }
                case TYPES.SET_LOADING_CONSULTORES:
                    return { ...state, loadingConsultores: action.value }
                
                    default:
                        return { ...state }
            }
        default:
            return { ...state }
    }
}