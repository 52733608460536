export const COLUMNS = [
    {
        Header: "Número Protocolo",
        accessor: "numProtocolo"
    },
    {
        Header: "Data de envio",
        accessor: "dataEnvio"
    },
    {
        Header: "Código de Postagem",
        accessor: "numPostagem"
    },
    {
        Header: "Status de envio",
        accessor: "statusEnvio"
    }
]

export const COLUMNS_PROPOSTAS = [
    {
        Header: "Nome pendência",
        accessor: "nomeTipoPendencia"
    },
    {
        Header: "Motivo",
        accessor: "motivo"
    },
    {
        Header: "Data pendência",
        accessor: "dataPendencia"
    },
    {
        Header: "Dias pendentes",
        accessor: "diasPendentes"
    },
]

export const COLUMNS_MODAL = [
    {
        Header: "Cod. Barras",
        accessor: "codBarras"
    },
    {
        Header: "Propostas",
        accessor: "numProposta"
    },
    {
        Header: "Contrato",
        accessor: "numContrato"
    },
    {
        Header: "Instituição",
        accessor: "instituicao"
    },
    {
        Header: "Status de envio",
        accessor: "statusEnvio"
    }
]
