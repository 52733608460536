const STORE_ID = "CONVENIO";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    SET_TIPOS: "SET_TIPOS",
    SET_COPY: "SET_COPY",
    SET_INVALID: "SET_INVALID",
    SET_ITEM: "SET_ITEM",
    CLEAN_INVALID: "CLEAN_INVALID",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE"
}

export { STORE_ID, TYPES }