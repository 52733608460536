export const COLUMNS = [
    {
        Header: "Instituição Financeira",
        accessor: "banco.nome",
    },
    {
        Header: "Loja Banco",
        accessor: "lojaBanco.nome",
    },
    {
        Header: "Data de inicio",
        accessor: "dtInicio",
    },
    {
        Header: "Data de fim",
        accessor: "dtFim",
    }
]
export const COLUMNS_LOJA = [
    {
        Header: "Tipo Comissao",
        accessor: "tipoComissao",
    },
    {
        Header: "Valor",
        accessor: "percentual",
    }
]