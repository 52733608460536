import React, { useEffect } from 'react';
import * as documentacaoActions from "../../../../../store/modules/personas/documentacao_store/actions";
import { Button, Card, Col, FormGroup, Label, Row } from 'reactstrap';
import * as AiIcons from "react-icons/ai";
import { useSelector } from 'react-redux';
import TableDocumento from "./components/TableDocumento";
import { FiUpload } from 'react-icons/fi';
import * as documentacaoController from "../../../../../controllers/personasController/documentacaoController";
import history from '../../../../../util/history';
import * as docActions from "../../../../../store/modules/tipoDocumento/actions";
import * as personasActions from '../../../../../store/modules/personas/documentacao_store/actions'
import { Select } from "../../../../../components/Inputs/Input";
import { AiOutlinePaperClip } from 'react-icons/ai';
import * as personasController from "../../../../../controllers/personasController/personasController"
const Documentacao = () => {

    const { documentos, seeRegister, documento, tiposDocumentos } = useSelector(state => state.personasDocumentacao);
    const { informacao } = useSelector(state => state.personasInformacoes);

    useEffect(() => {
        documentacaoController.getTiposDocumento();
    }, []);


    async function salveDocumentacao() {
        
       await personasController.salveDocumentacao(informacao.id, documento)

    }

    function onChangeArchive(value, field) {

        let file = value.target.files[0]
        var fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        let ReadedFile;
        fileReader.onload = () => {
            if (fileReader.result !== undefined) {
                ReadedFile = fileReader.result
            }

            let fileUpload = ReadedFile.split(",")[1];
            let fileType = file.type.split("/")[1];

            documentacaoActions.setDocumento({
                ...documento,
                tipoDoc: fileType,
                nome: file.name,
                [field]: fileUpload
            })

        }
    }

    function onChangeItem(value, field) {

        documentacaoActions.setDocumento({ ...documento, [field]: value })
    }

    function changeView() {
        history.push("/tipodedocumento");
        docActions.setActivePersona(true);
        docActions.setActiveTab("Register");
    }

    return (
        <>
            <Card className="p-3 m-4" style={{ backgroundColor: "var(--color-quase-branco)" }}>

                <p>Envio de Documentos</p>
                <Col>
                    <Row>
                        <Card className="p-3 m-3" style={{ width: "30%", backgroundColor: "var(--color-quase-branco)" }}>
                            <FormGroup>
                                <label
                                    style={{ width: "172px", height: "38px", color: "white", paddingTop: "10px", cursor: "pointer" }}
                                    className={"purpleButton"}
                                    htmlFor={`file`}
                                >
                                    <FiUpload
                                        style={{ marginTop: '2px', marginLeft: "15px", marginRight: "10px", float: 'left' }} />
                                        Clique para enviar
                                </label>
                                <input
                                    onChange={e => onChangeArchive(e, "base64")}
                                    type="file"
                                    id={`file`}
                                    style={{ display: "none" }}
                                    disabled={seeRegister}
                                    accept=".pdf, .png, .jpg"
                                />
                                <br />
                                <br />
                                <Row>
                                    <AiOutlinePaperClip
                                        style={{ fontSize: "1.25rem", marginRight: "7px", marginLeft: "15px", color: "grey" }}
                                    />

                                    <p>{documento.nome === "" ? "Nome do Arquivo" : documento.nome}</p>
                                    {documento.base64 !== "" &&
                                        (documento.tipoDoc === "png" ?
                                        <>
                                        <a href={`data:application/png;base64,${documento.base64}`} download={documento.nome}>
                                                <AiIcons.AiOutlineDownload
                                                    style={{
                                                        marginLeft: "10px",
                                                        fontSize: "1.5rem",
                                                        cursor: "pointer",
                                                        color: "grey"
                                                    }}
                                                />
                                            </a> 
                                            <button 
                                                style={{border: 'none', background: 'transparent', height: '7px', color: 'var(--color-preto)'}}
                                                onClick={() => personasActions.cleanDocumento()}
                                            >
                                                x
                                            </button>
                                        </>
                                            :
                                            <>
                                            <a href={`data:application/pdf;base64,${documento.base64}`} download={documento.nome}>
                                                <AiIcons.AiOutlineDownload
                                                    style={{
                                                        marginLeft: "10px",
                                                        fontSize: "1.5rem",
                                                        cursor: "pointer",
                                                        color: "grey"
                                                    }}
                                                />
                                            </a>
                                            <button 
                                                style={{border: 'none', background: 'transparent', height: '7px', color: 'var(--color-preto)'}}
                                                onClick={() => personasActions.cleanDocumento()}
                                            >
                                                x
                                            </button>
                                            </>
                                        )
                                    }
                                </Row>
                            </FormGroup>
                        </Card>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Tipo de Documento</Label>
                                <Select
                                    first="Selecione"
                                    disabled={seeRegister}
                                    onChange={e => onChangeItem(e.value, "tipoDocumentoId")}
                                    value={{ value: documento.tipoDocumentoId }}
                                    options={tiposDocumentos}
                                    select={{ value: "id", label: "descricao" }}
                                    isSearchable={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <Button
                                style={{
                                    marginTop: "25px",
                                    width: "200x",
                                    background: "var(--color-branco)",
                                    borderColor: "var(--color-verde)",
                                    color: "var(--color-verde)",
                                    borderRadius: "7px"
                                }}
                                disabled={seeRegister}
                                onClick={() => changeView()}
                            >
                                Novo Tipo de Documento
                            </Button>
                        </Col>
                    </Row>
                </Col>

               {!seeRegister && <Label
                    className="mt-2"
                    onClick={() => salveDocumentacao()}
                    style={{ cursor: "pointer" }}>
                        
                    <AiIcons.AiOutlinePlusCircle
                        style={{ fontSize: "1.5rem", marginRight: "7px", color: "green" }}

                        disabled={seeRegister}
                    />
                    Adicionar Documento
                </Label>}
            </Card>

            {documentos.length > 0 &&
                <TableDocumento documentos={documentos}/>}
        </>
    );
}

export default Documentacao;