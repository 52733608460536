import React, {useState} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useSelector } from 'react-redux'
import { AiOutlineEye } from 'react-icons/ai'
import { BsArrowUp, BsArrowDown } from 'react-icons/bs'
import { MdContentCopy } from 'react-icons/md'

import { maskDate, maskMoeda } from '../../../../util/masks'

import * as controller from '../../../../controllers/dashboardController/esteiraProducaoController'

import './style.css'

export default function Table({data}) {
    return(
        <table style={{maxWidth: '100%'}}>
            <thead style={{maxWidth: '100%'}}>
                <tr style={{maxWidth: '100%'}}>
                    <th className='colunaEsteira'>Seleção</th>
                    <th className='colunaEsteira'>Solicitado</th>
                    <th className='colunaEsteira'>Pré-consistência</th>
                    <th className='colunaEsteira'>Restrição</th>
                    <th className='colunaEsteira'>Domicílio bancário </th>
                    <th className='colunaEsteira'>Análise banco</th>
                </tr>
            </thead>
            <tbody style={{maxWidth: '100%'}}>
                {data.map((e, i)=>{ 
                    return( 
                      <FirstLevelTableRow data={e} index={i} />
                    ) 
                })} 
            </tbody>
        </table>
    )
}

function FirstLevelTableRow({data,index}){

    const { filtrosSelecionados, tabelaEsteiraProducao} = useSelector(state => state.dashboardEsteiraProducao);

    const [isPropostaActive, setisPropostaActive] = useState(false)

    function handleOnClick(){
        var isSuccessed = controller.getTabelaEsteiraProducaoPropostas(filtrosSelecionados, data.id, tabelaEsteiraProducao)
        isSuccessed && setisPropostaActive(!isPropostaActive)
    }

    return(
        <>
            <tr key={index} className='tr'>
                <td className='linhaGreen' style={{filter: 'brightness(85%)',}}>
                    <div style={{display: 'flex'}}>
                        <div className='button' onClick={(e) => handleOnClick()}>{!isPropostaActive ? '+' : '-'}</div>
                        {data.nome}
                    </div>
                </td>
                {data.statusPropostas.map((e)=>{
                    return(
                        <td className={e.crescimento >= 0 ? 'linhaGreen' : 'linhaRed'} style={{filter: 'brightness(85%)',}}>
                            <div>
                            <p style={{fontSize: '11px', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                {`(${e.quantidade})`} {e.crescimento >= 0 ? <BsArrowUp style={{color: 'var(--color-verde-claro)'}}/> : <BsArrowDown style={{color: 'red'}}/> }
                                </p>
                                <p style={{fontSize: '11px', fontWeight: '700', marginBottom: '3px', color: 'var(--color-preto)'}}>
                                    {`R$${maskMoeda(e.valorTotal.toFixed(2))}`}
                                </p> 
                            </div>
                        </td>
                    )
                })}
            </tr>
            { isPropostaActive &&
             <>
                <tr className='tr'>   
                    <td style={{padding: '0', margin: '0', width:'365px'}}>
                        <th style={{height: '40px',width: '165px',color: 'var(--color-preto)', background: '#F5F6FA'}}>Adesão</th>
                        <th style={{height: '40px',width: '200px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Digitador</th>
                    </td>
                    <td style={{padding: '0', margin: '0', background: '#F5F6FA'}}>
                        <th style={{height: '40px', width: '200px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Cliente</th>
                    </td>
                    <td style={{padding: '0', margin: '0', background: '#F5F6FA'}}>
                        <th style={{height: '40px',width: '100px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Data</th>
                        <th style={{height: '40px', width: '50px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Convênio</th>
                    </td>
                    <td style={{padding: '0', margin: '0', background: '#F5F6FA'}}>
                        <th style={{height: '40px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Produto</th>
                    </td>
                    <td style={{padding: '0', margin: '0', background: '#F5F6FA'}}>
                        <th style={{height: '40px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Status do Banco</th>
                    </td>
                    <td style={{padding: '0', margin: '0', background: '#F5F6FA'}}>
                    <th style={{height: '40px',width: '120px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Valor</th>
                    <th style={{height: '40px', width: '100px', color: 'var(--color-preto)', background: '#F5F6FA'}}>Ações</th>
                    </td>
                </tr>
            </>}
            {isPropostaActive &&
          data.propostas.map((e, i) => {
            return (
              <SecondLevelTableRow data={e} index={i} />
            )
          }) 
            }
        </>
    )
}
function SecondLevelTableRow({ data, index }) {
    const [inconsistencias, setInconsistencias] = useState([])
    const [ isInconsistenciasOpen, setIsInconsistenciaOpen] = useState(false)
  
    async function changeView(){
        window.open(`/propostas?id=${data.id}`, '_blank');
    }
  
    async function handleExpand(propostaId) {
      const inconsistencias = await controller.getInconsistenciasDaProposta(propostaId)
      setInconsistencias(inconsistencias)
      setIsInconsistenciaOpen(!isInconsistenciasOpen)
    }

     return(
        <>
        <tr className='tr' key={index}>
            <td style={{ padding: '7px', filter: 'brightness(90%)'}} className='linhaGreen'>
                <td style={{width: '4%', borderBottom: '0'}}>
                    <CopyToClipboard text={data.adesao}>
                        <button style={{background: 'transparent', border: 'none'}}> <MdContentCopy style={{color: 'var(--color-verde-claro)', marginRight: '10px'}}/></button>
                    </CopyToClipboard>
                    <div onClick={() => { handleExpand(data.id)}} style={{cursor: data.possuiInconsistencia ? "pointer" : "auto", fontWeight: data.possuiInconsistencia ? "bold" : "normal",color: data.possuiInconsistencia ? "black" : "normal"}}>
                      {data.adesao}
                    </div>
                 
                </td>
                <td style={{width: '5%', borderBottom: '0'}}>{data.digitador}</td>
            </td>
            <td style={{width: '10%' ,paddingLeft: '18px', filter: 'brightness(90%)'}} className='linhaGreen'>{data.cliente}</td>
            <td style={{ padding: '7px', filter: 'brightness(90%)'}} className='linhaGreen'>
                <td style={{width: '100px', borderBottom: '0',paddingLeft: '10px'}}>{maskDate(data.dataProposta, 'DD/MM/YYYY')}</td>
                <td style={{width: '25px', borderBottom: '0',paddingLeft: '12px'}}>{data.convenio}</td>
            </td>
            <td style={{width: '13%', paddingLeft: '20px', filter: 'brightness(90%)'}} className='linhaGreen'>{data.produto}</td>
            <td style={{width: '10%', paddingLeft: '18px', filter: 'brightness(90%)'}} className='linhaGreen'>{data.status}</td>
            <td style={{ padding: '7px',width:'220px', filter: 'brightness(90%)'}} className='linhaGreen'>
                <td style={{width: '120px', borderBottom: '0',paddingLeft:'13px'}}>{`R$ ${maskMoeda(data.valorFinanciado.toFixed(2))}`}</td>
                <td style={{width: '100px', borderBottom: '0'}}>{
                     <div style={{display: 'flex'}}> 
                     <AiOutlineEye style={{fontSize: '25px', paddingBottom: '5px', cursor: 'pointer', marginLeft:'15px'}} onClick={()=> changeView()}/> 
                    </div>  
                }</td>
            </td>
         </tr>
         {(isInconsistenciasOpen && inconsistencias.length > 0) ? (
           <ThirdLevelTable inconsistencias={inconsistencias} />
         ): null}
        </>
    )
}

function ThirdLevelTable({ inconsistencias }) {
  return(
      <>
              <tr className='tr'>   
                <th  style={{height: '40px',color: 'var(--color-preto)', background: '#F5F6FA'}}>Consistência</th>
                <th colSpan={2} style={{ height: '40px', color: 'var(--color-preto)', background: '#F5F6FA' }}>Descrição</th>
                <th colSpan={2} style={{ height: '40px', color: 'var(--color-preto)', background: '#F5F6FA' }}>Responsável</th>
                <th style={{
                  height: '40px', color: 'var(--color-preto)', background: '#F5F6FA', display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "25px"
                }}>Ações</th>
              </tr>
              {inconsistencias?.map(inconsistencia => (
                <ThirdLevelTableRow inconsistencia={inconsistencia}/>
              ))}
      </>
  )
}

function ThirdLevelTableRow({ inconsistencia }) {
  const [isInconsistenciaCorrigida, setIsInconsistenciaCorrigida] = useState(null)

  async function handleToggleInconsistenciaStatus(inconsistenciaStatus, propostaInconsistenciaId) {
    const newStatusInconsistencia = !inconsistenciaStatus
    const isSuccessed = await controller.updateFlagDaInconsistenciaProposta(propostaInconsistenciaId, newStatusInconsistencia)
    if (isSuccessed) {
      setIsInconsistenciaCorrigida(newStatusInconsistencia)
    }
  }

  const isResponsavelPorAcaoInstituicaoFinanceira = inconsistencia.reponsavelPorAcaoId.toUpperCase() === 'D60DD5FA-B56A-4DDA-9E1D-3073C841F1FB'

  return(
      <>
                <tr className='tr' key={inconsistencia.codigoInconsistencia}>   
                    <td >{inconsistencia.codigoInconsistencia}</td>
                    <td colSpan={2}>{inconsistencia.descricao}</td>
                    <td colSpan={2}>
                          {inconsistencia.responsavelPorAcao}
                  </td>
                  <td>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "25px"}}>
                        {
                            inconsistencia.responsavelPorAcao !== 'INSTITUICAO FINANCEIRA' && (
                                <input
                                type="checkbox"
                                disabled={isResponsavelPorAcaoInstituicaoFinanceira}
                                defaultChecked={isInconsistenciaCorrigida ?? inconsistencia.codigoConsistenciaCorrigido}
                                onChange={() => handleToggleInconsistenciaStatus(isInconsistenciaCorrigida ?? inconsistencia.codigoConsistenciaCorrigido, inconsistencia.idPropostaInconsistencia)} />
                            )
                        }
                    </div>
                  </td>
                </tr>
      </>
  )
}