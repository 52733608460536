import React, { useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { Card, Col, Input, Label, Form, FormGroup, Row } from "reactstrap";
import * as BsIcons from "react-icons/bs";
import { useTable, useSortBy, usePagination } from "react-table";
import { COLUMNS_LOJA_PESSOA } from "../constants/columns";
import { useSelector } from "react-redux";
import * as centroCustoActions from "../../../store/modules/vinculoCentroDeCusto/actions";
import * as centroCustoController from "../../../controllers/vinculoCentroCustoController";
import { Select } from "../../../components/Inputs/Input";

const Register = () => {

    const { item, lojas,centrosCusto, seeRegister } = useSelector(state => state.vinculoCentroDeCusto);

    const columns = useMemo(() => COLUMNS_LOJA_PESSOA, []);
    const data = item.lojaPessoas;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
        columns,
        data
    },
        useSortBy,
        usePagination);

    const { pageSize } = state;
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
    }

    function handleInitialPage() {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }

    const pageCount = Math.ceil(data.length / pageSize);

    useEffect(()=>{
        centroCustoController.getLojas()
        centroCustoController.getCentrosCusto()
    },[])


    function onChangeItem(value, field) {
        centroCustoActions.cleanInvalid(field);
        centroCustoActions.item({ ...item, [field]: value });
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2" style={{ margin: '25px 0 0 25px' }}>
                        <p
                            style={{
                                fontSize: "18px",
                                color: 'var(--color-preto)'
                            }}
                        >
                            Preencha os campos para cadastrar um novo Centro de Custo
                        </p>
                    </Col>
                    <Col className="pt-2">
                        <Form>
                            <div style={{ width: "100%", margin: '40px 0 0 25px', display: 'flex' }}>
                                {
                                    item.id !== '' && (
                                        <FormGroup style={{width: '20%', marginRight: '25px'}}>
                                        <Label
                                            for="productDescription"
                                            style={{ fontWeight: '700', color: 'var(--color-preto)' }}
                                        >
                                            Código do Centro de Custo
                                        </Label>
                                        <Input
                                            type="text"
                                            value={item.codigo}
                                            disabled={true}
                                        />
                                        </FormGroup>
                                    )
                                }
                                <FormGroup style={{width:'20%' , marginRight: '25px'}}>
                                    <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>
                                        Loja
                                    </Label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: item.lojaId }}
                                        select={{ value: "id", label: "nome" }}
                                        options={lojas}
                                        onChange={(e) => onChangeItem(e.value, "lojaId")}
                                        isSearchable={true}
                                        autoComplete="off"
                                        disabled={item.id !== '' ? true : seeRegister}
                                    />
                                </FormGroup>
                                <FormGroup style={{width: '20%', marginRight: '25px'}}>
                                <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>
                                        Centro de Custo
                                    </Label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: item.centroCustoId }}
                                        select={{ value: "id", label: "descricao" }}
                                        options={centrosCusto}
                                        onChange={(e) => onChangeItem(e.value, "centroCustoId")}
                                        isSearchable={true}
                                        autoComplete="off"
                                        disabled={item.id !== '' ? true : seeRegister}
                                    />
                                </FormGroup>
                            </div>
                        </Form>
                        {
                            item.id !== '' && (
                                <>
                                    <table {...getTableProps()}>
                                        <thead>
                                            {headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <th>
                                                            {column.render("Header")}
                                                            <span className="float-right">
                                                                {column.isSorted ?
                                                                    (column.isSortedDesc ?
                                                                        <BsIcons.BsArrowDown
                                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                                        />
                                                                        :
                                                                        <BsIcons.BsArrowUp
                                                                            id="OrdenarAsc"
                                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                                        />)
                                                                    :
                                                                    <BsIcons.BsArrowDownUp
                                                                        {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                                    />}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {page.map(row => {
                                                prepareRow(row)
                                                return (
                                                    <tr className="text-center" {...row.getRowProps()}>
                                                        {row.cells.map((cell) => {
                                                            return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <Row className="pt-4">
                                        <Col md={8}>
                                            <Row className="float-right">
                                                <ReactPaginate
                                                    previousLabel={'<'}
                                                    nextLabel={'>'}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}
                                                    forcePage={initialPage ? 0 : selectedPage}
                                                />
                                                <Input
                                                    type="select"
                                                    className="pageSize"
                                                    value={pageSize}
                                                    onChange={e => { setPageSize(Number(e.target.value)); handleInitialPage() }}>
                                                    {
                                                        [10, 25, 100].map(pageSize => (
                                                            <option key={pageSize} value={pageSize}>
                                                                {pageSize}
                                                            </option>
                                                        ))
                                                    }
                                                </Input>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    </Col>
                </Card>
            </Col>
        </>
    );
}

export default Register;
