import React from "react";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useSelector } from "react-redux";
import * as perfilActions from "../../../store/modules/perfilCliente/actions";
import * as perfilController from "../../../controllers/perfilClienteController";
import { removeAccentsAndUppercase } from "../../../util/masks";

const Register = () => {

    const { item, invalid, copy, seeRegister } = useSelector(state => state.perfilCliente);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                perfilController.create(item);
            } else {
                perfilController.update(item, copy);
            }
        }
    }

    function onChangeItem(value, field) {
        perfilActions.cleanInvalid(field);
        perfilActions.item({ ...item, [field]: value });
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2" style={{ margin: '25px 0 0 25px' }}>
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>
                            Preencha o campo para cadastrar um novo Perfil do Cliente
                        </p>
                    </Col>
                    <Col className="pt-2">
                        <Form>
                            <Row style={{ width: "35%", margin: '40px 0 0 15px' }}>
                                <Col>
                                    <FormGroup>
                                        <Label for="inconsistencia" style={{ color: 'var(--color-preto)' }}>
                                            Descrição do Perfil do CIliente
                                        </Label>
                                        <Input
                                            type="text"
                                            id="inconsistencia"
                                            placeholder="Digite aqui..."
                                            value={item.descricao}
                                            onChange={e => onChangeItem(removeAccentsAndUppercase(e.target.value), "descricao")}
                                            onKeyPress={e => handleKeyPress(e)}
                                            className={invalid.descricao ? "is-invalid" : ""}
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Card>
            </Col>
        </>
    );
}

export default Register;