import { STORE_ID, TYPES } from './types'

const INITIAL_STATE = {

    login: {
        email: "",
        password: "",
        saveCredentials: false
    },
    invalidLogin: {
        email: false,
        password: false
    },
    userControllKeys: [],
    request: false,
    loadingLogin: false
}

export default function login(state = INITIAL_STATE, action) {

    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.LOGIN:
                    return { ...state, login: { ...state.login, [action.field]: action.value } }
                case TYPES.INVALID_LOGIN:
                    return { ...state, invalidLogin: { ...state.invalidLogin, [action.field]: true } }
                case TYPES.CLEAN_INVALID_LOGIN:
                    return { ...state, invalidLogin: { ...state.invalidLogin, [action.field]: false } }
                case TYPES.CLEAN_ALL:
                    return {...state, login: INITIAL_STATE.login, invalidLogin: INITIAL_STATE.invalidLogin}
                case TYPES.SET_USER_CONTROLL_KEYS:
                    return {...state, userControllKeys: action.value}
                case TYPES.SET_REQUEST:
                    return {...state, request: action.value}
                case TYPES.SET_LOADING_LOGIN:
                    return {...state, loadingLogin: action.value}
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}
