import api from "../util/api";
import * as actions from "../store/modules/propostas/httpRequest/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function getAll(page, pageSize){
    let url = `httpRequest?pagina=${page}&registros=${pageSize}`;

    setLoading(true);
    const data = await api.get(url,
        {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem("TOKEN_KEY"),
                'Content-Type': 'aplication/json'
            }
        });
    setLoading(false);
    if (!data.success){
        return;
    }
    let body = [];
    let stringLogCamposError = "";

    data.content.registros.forEach((e) => {
        stringLogCamposError = e.logCamposErrorItauHttpRequests == null ?
            []:
            e.logCamposErrorItauHttpRequests.map((l) =>
                l.mensagemError).join("; ")

        let dataSplit = e.dataCriacao.split("/");
        let dia = dataSplit[1];
        let mes = dataSplit[0];
        let ano = dataSplit[2];

        e.dataCriacao = `${dia}/${mes}/${ano}`;
        body.push({
            id: e.id,
            dataCriacao: e.dataCriacao,
            codigoError: e.codigoError,
            mensagem: e.mensagem,
            httpStatusCode: e.httpStatusCode,
            statusCode: e.statusCode,
            logCamposError: stringLogCamposError
        })
    })

    actions.setLog(body);
    actions.setGetAll({
        quantidade: data.content.quantidade,
        quantidadeTotal: data.content.quantidadeTotal,
        totalPaginas: data.content.totalPaginas,
        tamanhoPagina: data.content.tamanhoPagina
    })
}