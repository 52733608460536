import { STORE_ID, TYPES } from "./types";
import store from "../../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function setTab(value) {
    dispatch({
        type: TYPES.SET_TAB,
        value
    })
}

export function item(value) {
    dispatch({
        type: TYPES.ITEM,
        value
    });
}

export function setItem(field, value) {
    dispatch({
        type: TYPES.SET_ITEM,
        field,
        value
    });
}

export function setRegraQualidade(field, value) {
    dispatch({
        type: TYPES.SET_REGRA_QUALIDADE,
        field,
        value
    });
}

export function regraQualidade(value) {
    dispatch({
        type: TYPES.REGRA_QUALIDADE,
        value
    });
}


export function cleanRegraQualidade() {
    dispatch({
        type: TYPES.CLEAN_REGRA_QUALIDADE
    })
}

export function setRegraProdutividade(field, value) {
    dispatch({
        type: TYPES.SET_REGRA_PRODUTIVIDADE,
        field,
        value
    });
}

export function regraProdutividade(value) {
    dispatch({
        type: TYPES.REGRA_PRODUTIVIDADE,
        value
    });
}


export function cleanRegraProdutividade() {
    dispatch({
        type: TYPES.CLEAN_REGRA_PRODUTIVIDADE
    })
}

export function setMetaProdutividade(field, value) {
    dispatch({
        type: TYPES.SET_META_PRODUTIVIDADE,
        field,
        value
    });
}

export function metaProdutividade(value) {
    dispatch({
        type: TYPES.META_PRODUTIVIDADE,
        value
    });
}


export function cleanMetaProdutividade() {
    dispatch({
        type: TYPES.CLEAN_META_PRODUTIVIDADE
    })
}

export function setRegraRepasse(field, value) {
    dispatch({
        type: TYPES.SET_REGRA_REPASSE,
        field,
        value
    });
}

export function RegraRepasse(value) {
    dispatch({
        type: TYPES.REGRA_REPASSE,
        value
    });
}


export function cleanRegraRepasse() {
    dispatch({
        type: TYPES.CLEAN_REGRA_REPASSE
    })
}

export function setMetaSuperacao(field, value) {
    dispatch({
        type: TYPES.SET_META_SUPERACAO,
        field,
        value
    });
}

export function metaSuperacao(value) {
    dispatch({
        type: TYPES.META_SUPERACAO,
        value
    });
}


export function cleanMetaSuperacao() {
    dispatch({
        type: TYPES.CLEAN_META_SUPERACAO
    })
}

export function copy(value) {
    dispatch({
        type: TYPES.ITEM_COPY,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_ITEM,
        field
    });
}

export function getItems(value) {
    dispatch({
        type: TYPES.ITEMS,
        value
    });
}

export function getHierarquias(value) {
    dispatch({
        type: TYPES.HIERARQUIAS,
        value
    });
}

export function hierarquiaRegraCampanha(value) {
    dispatch({
        type: TYPES.HIERARQUIAS_REGRA,
        value
    });
}

export function hierarquiaMetaProdutiviadade(value) {
    dispatch({
        type: TYPES.HIERARQUIAS_PRODUTIVIDADE,
        value
    });
}

export function getGrupos(value) {
    dispatch({
        type: TYPES.GRUPOS,
        value
    });
}

export function getProdutos(value) {
    dispatch({
        type: TYPES.PRODUTOS,
        value
    });
}

export function getTiposComissao(value) {
    dispatch({
        type: TYPES.TIPOS_COMISSAO,
        value
    });
}

export function getTiposFuncionario(value) {
    dispatch({
        type: TYPES.TIPOS_FUNCIONARIO,
        value
    });
}

export function getTiposComissaoProduto(value) {
    dispatch({
        type: TYPES.TIPOS_COMISSAO_PRODUTO,
        value
    });
}

export function getTiposPendencia(value) {
    dispatch({
        type: TYPES.TIPOS_PENDENCIA,
        value
    });
}

export function getPeriodos(value) {
    dispatch({
        type: TYPES.PERIODOS,
        value
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_ITEM,
        field
    });
}

export function cleanInvalids(field) {
    dispatch({
        type: TYPES.CLEAN_INVALIDS,
        field
    });
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function setSearch(value) {
    dispatch({
        type: TYPES.SEARCH,
        value
    });
}

export function setPeriodicidades(value) {
    dispatch({
        type: TYPES.PERIODICIDADES,
        value
    });
}

export function cleanSearch() {
    dispatch({
        type: TYPES.CLEAN_SEARCH
    });
}

export function setCanais(value) {
    dispatch({
        type: TYPES.CANAIS,
        value
    });
}

export function setTiposOperacao(value) {
    dispatch({
        type: TYPES.TIPOS_OPERACAO,
        value
    });
}

export function setTiposParceria(value) {
    dispatch({
        type: TYPES.TIPOS_PARCERIA,
        value
    });
}

export function setConvenios(value) {
    dispatch({
        type: TYPES.CONVENIOS,
        value
    });
}

export function setCentrosCusto(value) {
    dispatch({
        type: TYPES.CENTROS_CUSTO,
        value
    });
}

export function setBancos(value) {
    dispatch({
        type: TYPES.BANCOS,
        value
    });
}

export function getAllItems(value) {
    dispatch({
        type: TYPES.GET_ALL_ITEMS,
        value
    });
}
