import api from "../../util/api";
import * as contatoActions from "../../store/modules/personas/contato_store/actions";
import { setLoading } from "../../store/modules/globalLoading/actions";

export async function getTiposEmail() {
    const data = await api.get("tipoEmail");

    if (!data.success) {
        return;
    }

    contatoActions.getItemsTiposEmails(data.content);
}

export async function getTiposTelefone() {
    const data = await api.get("tipoTelefone");

    if (!data.success) {
        return;
    }

    contatoActions.getItemsTiposTelefones(data.content);
}

export async function deleteEmail(id) {
    setLoading(true);
    const data = await api.delete(`Pessoa/${id}/emails`);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    
    return true
}