export const COLUMNS = [{
        Header: "Descrição",
        accessor: "descricao"
    },
    {
        Header: "Instituição Financeira",
        accessor: "banco"
    },
    {
        Header: "Produto",
        accessor: "produto"
    },
    {
        Header: "Convênio",
        accessor: "convenio"
    },
    {
        Header: "Vigência",
        accessor: "vigencia"
    },
]