import React, { useEffect } from 'react';
import { IoIosArrowBack } from "react-icons/io";
import { Card, Col, Form, Button, TabContent, TabPane } from "reactstrap";
import Search from "./tabs/search";
import Register from "./tabs/register";
import { useSelector } from "react-redux";
import * as actions from "../../store/modules/agrupamentoPropostaCentroCusto/actions"
import Breadcrumb from '../../components/breadcrumbs';

const AgrupamentoPropostasCentrocusto = () => {

    const {  activeTab } = useSelector(state => state.agrupamentoPropostasCentroCusto);

    useEffect(() => {
        actions.setActiveTab("Search")
    }, [])

    function changeTab(tab) {
        actions.setActiveTab(tab);
        actions.cleanRegister();
    }

    function seeRegisterF() {
            actions.cleanRegister();
    }

    return (
        <>
                <Col>
                    <Breadcrumb activeTab={activeTab} />
                    <Card>
                        <Form>
                            {
                                activeTab !== "Search" &&

                                    <Col className="pt-2 pb-2">
                                        <Button
                                            onClick={() => { changeTab("Search"); seeRegisterF() }}
                                            style={{ width: "127px", color: "white" }}
                                            className="purpleButton"
                                        >
                                            <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar
                                        </Button>
                                    </Col>
                            }
                        </Form>
                    </Card>
                </Col>

                <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <br />
                        <Search />
                    </TabPane>
                    <TabPane tabId="Register">
                        <br />
                        <Register />
                    </TabPane>
                </TabContent>
        </>
    );
}

export default AgrupamentoPropostasCentrocusto;