import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BsClipboard, BsPeople } from "react-icons/bs";
import { BiShoppingBag } from "react-icons/bi";
import { VscMegaphone } from "react-icons/vsc";
import { AiOutlineIdcard, AiOutlinePushpin, AiOutlineTags } from "react-icons/ai";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Col } from 'reactstrap';

import classnames from 'classnames';
import Breadcrumb from "../../components/breadcrumbs";

import Usuario from "./Usuario";
import Clientes from "./Cliente";
import Produto from "./Produto";
import TipoOperacao from "./TipoOperacao";
import StatusProposta from "./StatusProposta";
import Convenio from "./Convenio";
import TipoFormalizacao from "./TipoFormalizacao";

import * as actions from '../../store/modules/inconsistenciaProducao/actions';
import * as controller from '../../controllers/inconsistenciasProducaoController'

const IncosistenciaProducao = () => {
    const { tab,tabs} = useSelector(state => state.inconsistenciaProducao);

    useEffect(()=>{
        async function get(){
            if(tab.tab === '1'){
                await controller.getInconsistencias(tab, [])
            }else{
                await controller.getInconsistencias(tab)
            }
            await controller.getQuantidade(tabs)
        }
        get()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function changeTab(value){
        actions.cleanTabs()
        actions.setTab(value)
        controller.getInconsistencias(value, [])
    }
    return (
        <Col>
         <Breadcrumb activeTab={"Search"} />
            <Card className="pt-3 pb-2 mb-5">
                <div>
                    <Nav style={{ marginBottom: '10px', marginLeft: '25px'}}>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: tab.tab === '1' })}
                                onClick={() => { changeTab({tab: '1', id: tabs[0].id}); }}
                                style={
                                    tab.tab === '1' ?
                                        { color: 'var(--color-preto)', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s', cursor: 'pointer' }
                                        : { color: 'var(--color-preto)', fontWeight: '700', cursor: 'pointer' }}
                            >
                               <BsPeople style={{fontSize: '18px', paddingBottom: '2px', marginRight: '5px'}}/> 
                               {`Usuário (${tabs[0].quantidade})`}
                            </NavLink>                
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: tab.tab === '2' })}
                                onClick={() => { changeTab({tab: '2', id: tabs[1].id}); }}
                                style={
                                    tab.tab === '2' ?
                                        { color: 'var(--color-preto)', fontWeight: '700', cursor: 'pointer', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s' }
                                        : { color: 'var(--color-preto)', cursor: 'pointer', fontWeight: '700' }}
                            >
                               <BiShoppingBag style={{fontSize: '18px', paddingBottom: '2px', marginRight: '5px'}}/> 
                               {`Produto (${tabs[1].quantidade})`}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: tab.tab === '3' })}
                                onClick={() => { changeTab({tab: '3', id: tabs[2].id}); }}
                                style={
                                    tab.tab === '3' ?
                                        { color: 'var(--color-preto)', cursor: 'pointer', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s' }
                                        : { color: 'var(--color-preto)', cursor: 'pointer', fontWeight: '700' }}
                            >
                               <VscMegaphone style={{fontSize: '18px', paddingBottom: '2px', marginRight: '5px'}}/> 
                               {`Tipo de Operação (${tabs[2].quantidade})`}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: tab.tab === '4' })}
                                onClick={() => { changeTab({tab: '4', id: tabs[3].id}); }}
                                style={
                                    tab.tab === '4' ?
                                        { color: 'var(--color-preto)', cursor: 'pointer', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s' }
                                        : { color: 'var(--color-preto)', cursor: 'pointer', fontWeight: '700' }}
                            >
                                <BsClipboard style={{fontSize: '18px', paddingBottom: '2px', marginRight: '5px'}}/> 
                                {`Status Proposta (${tabs[3].quantidade})`}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: tab.tab === '5' })}
                                onClick={() => { changeTab({tab: '5', id: tabs[4].id}); }}
                                style={
                                    tab.tab === '5' ?
                                        { color: 'var(--color-preto)', cursor: 'pointer', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s' }
                                        : { color: 'var(--color-preto)', cursor: 'pointer', fontWeight: '700' }}
                            >
                               <AiOutlineTags style={{fontSize: '18px', paddingBottom: '2px', marginRight: '5px'}}/> 
                               {`Convênio (${tabs[4].quantidade})`}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: tab.tab === '6' })}
                                onClick={() => { changeTab({tab: '6', id: tabs[5].id}); }}
                                style={
                                    tab.tab === '6' ?
                                        { color: 'var(--color-preto)', cursor: 'pointer', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s' }
                                        : { color: 'var(--color-preto)', cursor: 'pointer', fontWeight: '700' }}
                            >
                               <AiOutlinePushpin style={{fontSize: '18px', paddingBottom: '2px', marginRight: '5px'}}/> 
                               {`Tipo Formalização (${tabs[5].quantidade})`}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: tab.tab === '7' })}
                                onClick={() => { changeTab({tab: '7', id: tabs[6].id}); }}
                                style={
                                    tab.tab === '7' ?
                                        { color: 'var(--color-preto)', cursor: 'pointer', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s' }
                                        : { color: 'var(--color-preto)', cursor: 'pointer', fontWeight: '700' }}
                            >
                                <AiOutlineIdcard style={{fontSize: '18px', paddingBottom: '2px', marginRight: '5px'}}/> 
                                {`Cliente (${tabs[6].quantidade})`}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={tab.tab}>
                        <TabPane tabId="1">
                           <Usuario/>
                        </TabPane>
                        <TabPane tabId="2">
                           <Produto/>
                        </TabPane>
                        <TabPane tabId="3">
                           <TipoOperacao/>
                        </TabPane>
                        <TabPane tabId="4">
                            <StatusProposta/>
                        </TabPane>
                        <TabPane tabId="5">
                            <Convenio/>
                        </TabPane>
                        <TabPane tabId="6">
                            <TipoFormalizacao/>
                        </TabPane>
                        <TabPane tabId="7">
                            <Clientes/>
                        </TabPane>
                    </TabContent>
                </div>
            </Card>
        </Col>

    );
}

export default IncosistenciaProducao;