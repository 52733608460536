import api from "../util/api";
import * as actions from "../store/modules/agrupamentoPropostaCentroCusto/actions";
import { setLoading } from "../store/modules/globalLoading/actions";
import { maskDate, maskMoeda } from "../util/masks";

export async function get() {

    setLoading(true)
    const data = await api.get("planilhaimportacao/eligivel-comissao-recebida");
    setLoading(false)

    if (!data.success) {
        return;
    }

    actions.setSearch(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`agrupamento-comissa-recebida/${id}/centro-custo`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    data.content.forEach((item) =>{
        item.dataVencimento = new Date(Date.parse(item.dataVencimento));
        item.dataEmissao = new Date(Date.parse(item.dataEmissao));
        item.valorBruto = item.valorBruto < 0 ?  `- ${maskMoeda(item.valorBruto.toFixed(2))}`: maskMoeda(item.valorBruto.toFixed(2))
        item.valorLiquido = item.valorLiquido < 0 ? `- ${maskMoeda(item.valorLiquido.toFixed(2))}`: maskMoeda(item.valorLiquido.toFixed(2))
        item.valorIr = item.valorIr < 0 ? `- ${maskMoeda(item.valorIr.toFixed(2))}`: maskMoeda(item.valorIr.toFixed(2))
        item.produto = item.produto
    })

    actions.setItens(data.content);
}