import React from "react";
import { Input } from "reactstrap";
import {removeAccentsPunctuationAndUpperCase} from "../../util/masks";

const Filter = ({ filter, setFilter }) => {

    return (
        <span>
            <Input
                placeholder="Digite aqui..."
                value={filter}
                onChange={e => setFilter(removeAccentsPunctuationAndUpperCase(e.target.value))}
            />
        </span>
    )
}

export default Filter;
