const STORE_ID = "STATUSRC";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    STATUSRC: "STATUSRC",
    STATUSRC_COPY: "STATUSRC_COPY",
    INVALID_STATUSRC: "INVALID_STATUSRC",
    STATUS_RC: "STATUS_RC",
    CLEAN_INVALID_STATUSRC: "CLEAN_INVALID_STATUSRC",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE"
}

export { STORE_ID, TYPES }