/* eslint-disable default-case */
import React, { useEffect } from "react";

import {
  Card,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
  Button,
} from "reactstrap";

import { IoIosArrowBack } from "react-icons/io";

import { useSelector } from "react-redux";
import * as actions from "../../../store/modules/perfilAcesso/actions";
import * as controller from "../../../controllers/perfilAcessoController";

import AssociacaoLojas from "../components/AssociacaoLojas";
import { Switch } from "../../../components/Inputs/Input";
import AssociacaoTelas from "../components/AssociacaoTelas";
import Breadcrumb from "../../../components/breadcrumbs";

import { telasSistema } from "../../../util/telas";
import { removeAccentsAndUppercase } from "../../../util/masks";

export function Edit() {
  const {
    perfilDeAcessoBeingEditedId,
    perfilDeAcessoBeingEditedData,
    activeTab,
  } = useSelector((state) => state.perfilAcesso);

  async function saveItem() {
    const isSuccessed = await controller.updatePerfilDeAcesso(
      perfilDeAcessoBeingEditedData
    );

    if (isSuccessed) {
      actions.setActiveTab("Search");
      const data = await controller.getPerfisDeAcesso();
      actions.setPerfisDeAcesso(data);
    }
  }

  function changeTab(tab) {
    actions.setActiveTab(tab);
  }

  function handleVoltar() {
    changeTab("Search");
  }

  useEffect(() => {
    if (perfilDeAcessoBeingEditedId) {
      async function fetchPageInitialContent() {
        const responseData = {
          lojas: [],
          telas: [],
        };

        const lojasDisponiveis = await controller.getLojas();
        const telasDisponiveis = await controller.getTelas();

        actions.setLojasDisponiveisInitialValueBackup(lojasDisponiveis);

        actions.setTelasDisponiveisInitialValueBackup(telasDisponiveis);

        responseData.lojas = lojasDisponiveis;
        responseData.telas = telasDisponiveis;

        const { administrativo, descricao, hierarquico, id, lojas, telas } =
          await controller.getById(perfilDeAcessoBeingEditedId);

        const telasAssociadas = telas.map((tela) => {
          let permissoes = {
            criar: false,
            deletar: false,
            autorizar: false,
            filtros: false,
            estornar: false,
            desbloquear: false,
          };

          tela.permissoes.forEach((permission) => {
            const operationType = permission.id.toUpperCase();

            switch (operationType) {
              case "2A4A690A-01D2-4364-ABA4-6B2D977A3442":
                permissoes.criar = true;
                break;
              case "A02B98DB-5DD3-4803-91B6-CDE757D86C00":
                permissoes.deletar = true;
                break;
              case "1702FE06-7E33-48EE-BE6C-0B96B5209ACA":
                permissoes.estornar = true;
                break;
              case "D4BB230A-9583-4A04-9583-1A8B5D14F750":
                permissoes.autorizar = true;
                break;
              case "4EC5FA97-45ED-4659-901A-F50DCC4F5575":
                permissoes.filtros = true;
                break;
              case "CDDB1F17-A507-461C-A004-5B040EA330C9":
                permissoes.desbloquear = true;
                break;
            }
          });

          let nomeTela = telasSistema.find(
            (telaSistema) => tela.id.toUpperCase() === telaSistema.id
          ).nomeTela;

          return {
            id: tela.id,
            nomeTela: nomeTela,
            permissoes: permissoes,
          };
        });

        // this is needed because API returns only the "loja" id
        const lojasAssociadas = [];

        lojas.forEach((loja) => {
          const lojaEquivalente = responseData.lojas.find((lojaDisponivel) => {
            return lojaDisponivel.id === loja.lojaId;
          });

          if (lojaEquivalente) {
            lojasAssociadas.push(lojaEquivalente);
          }
        });

        actions.setTelasAssociadas(telasAssociadas);
        actions.setLojasAssociadas(lojasAssociadas);

        const telasDisponiveisWithoutAssociatedValues =
          responseData.telas.filter((telaDisponivel) => {
            return !telasAssociadas.some(
              (telaAssociada) => telaAssociada.id === telaDisponivel.id
            );
          });

        const lojasDisponiveisWithoutAssociatedValues =
          responseData.lojas.filter((lojaDisponivel) => {
            return !lojasAssociadas.some(
              (lojaAssociada) => lojaAssociada.id === lojaDisponivel.id
            );
          });

        actions.setTelasDisponiveis(telasDisponiveisWithoutAssociatedValues);
        actions.setLojasDisponiveis(lojasDisponiveisWithoutAssociatedValues);

        actions.setPerfilDeAcessoBeingEditedData({ campo: "id", value: id });
        actions.setPerfilDeAcessoBeingEditedData({
          campo: "descricao",
          value: descricao,
        });
        actions.setPerfilDeAcessoBeingEditedData({
          campo: "isHierarquia",
          value: hierarquico,
        });
        actions.setPerfilDeAcessoBeingEditedData({
          campo: "isAdministrativo",
          value: administrativo,
        });
        actions.setPerfilDeAcessoBeingEditedData({
          campo: "telasAssociadas",
          value: telasAssociadas,
        });
        actions.setPerfilDeAcessoBeingEditedData({
          campo: "telasDisponiveis",
          value: telasDisponiveis,
        });
      }

      // clear previous data
      actions.setTelasAssociadas([]);
      actions.setLojasAssociadas([]);
      actions.setTelasDisponiveis([]);
      actions.setLojasDisponiveis([]);
      actions.setTelasSelecionadas([]);
      actions.setLojasSelecionadas([]);
      actions.clearPerfilDeAcessoBeingEditedData();
      actions.setTelasAssociadasSearchTerm("");
      actions.setTelasDisponiveisSearchTerm("");
      actions.setLojasAssociadasSearchTerm("");
      actions.setLojasDisponiveisSearchTerm("");

      fetchPageInitialContent();
    }
  }, [perfilDeAcessoBeingEditedId]);

  return (
    <>
      <Col>
        <Breadcrumb activeTab={activeTab} />
        <Card>
          <Form>
            <Col className="pt-2 pb-2">
              <Button
                onClick={() => {
                  handleVoltar();
                }}
                style={{ width: "150px", color: "white" }}
                className="purpleButton"
              >
                <IoIosArrowBack style={{ marginTop: "3px", float: "left" }} />{" "}
                Voltar
              </Button>
              <Button
                onClick={() => saveItem()}
                style={{ width: "242px", float: "right" }}
                className="purpleButton"
              >
                Salvar Cadastro
              </Button>
            </Col>
          </Form>
        </Card>
      </Col>
      <Col>
        <Card className="radius pb-5">
          <Col className="pt-2 m-4">
            <p style={{ fontSize: "18px", color: "var(--color-preto)" }}>
              Preencha os campos para cadastrar um novo Perfil de acesso
            </p>
          </Col>
          <Col md={12} className="pt-2">
            <Form className="m-4">
              <div
                style={{ padding: "20px", borderBottom: "1px solid #D5D5D5" }}
              >
                <Row>
                  <Col md={5}>
                    <FormGroup>
                      <Label
                        style={{
                          fontWeight: "700",
                          color: "var(--color-preto)",
                        }}
                      >
                        Descrição
                      </Label>
                      <Input
                        type="text"
                        placeholder="Digite aqui..."
                        value={perfilDeAcessoBeingEditedData?.descricao}
                        onChange={(e) =>
                          actions.setPerfilDeAcessoBeingEditedData({
                            campo: "descricao",
                            value: removeAccentsAndUppercase(e.target.value),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div
                style={{ padding: "20px", borderBottom: "1px solid #D5D5D5" }}
              >
                <Row>
                  <Col md={6}>
                    <Switch
                      componentLeft="Este perfil irá utilizar a hierarquia como filtro de visualização?"
                      className="switch mt-4"
                      checked={perfilDeAcessoBeingEditedData?.isHierarquia}
                      readOnly
                      disabled={true}
                    />
                  </Col>
                  <Col md={6}>
                    <Switch
                      componentLeft="Exibir CPF"
                      className="switch mt-4"
                      checked={perfilDeAcessoBeingEditedData?.isAdministrativo}
                      onChange={() =>
                        actions.setPerfilDeAcessoBeingEditedData({
                          campo: "isAdministrativo",
                          value:
                            !perfilDeAcessoBeingEditedData.isAdministrativo,
                        })
                      }
                    />
                  </Col>
                </Row>
              </div>
              <div
                style={{ padding: "20px", borderBottom: "1px solid #D5D5D5" }}
              >
                <AssociacaoTelas />
              </div>
              {!perfilDeAcessoBeingEditedData.isHierarquia && (
                <div
                  style={{ padding: "20px", borderBottom: "1px solid #D5D5D5" }}
                >
                  <AssociacaoLojas />
                </div>
              )}
            </Form>
          </Col>
        </Card>
      </Col>
    </>
  );
}
