import React from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardImg, Col, CustomInput, FormGroup, Input, Label, Row } from "reactstrap";
import logo from "../../assets/images/logo.svg";
import { useEffect } from "react";
import * as controllerLogin from "../../controllers/loginController"
import * as loginAction from "../../store/modules/login/actions";
import version from "../../../package.json";
import TopLoading from "../../components/Loading/TopLoading";

const Login = () => {

    const { login, invalidLogin, request, loadingLogin } = useSelector(state => state.login);

    function handleKeyPress(event, type) {
        if (event.key === "Enter") {
            switch (type) {
                case "login":
                    controllerLogin.LogIn(login);
                    break;
                default:
                    break;
            }
        }
    }

    function onChangeLogin(value, field) {
        loginAction.cleanInvalidLogin(field);
        loginAction.login(field, value);
    }

    function logIn() {
        controllerLogin.LogIn(login);
    }

    function saveCredentials() {
        loginAction.login("saveCredentials", login.saveCredentials ? false : true);
    }

    function sendEmail() {
        controllerLogin.requestEmail(login.email)
    }

    useEffect(() => {
        if (localStorage.getItem("credentials")) {
            let credentials = JSON.parse(localStorage.getItem("credentials"));
            if (credentials.checked) {
                loginAction.login("email", credentials.email);
                loginAction.login("saveCredentials", credentials.checked);
            }
        }
        loginAction.setRequest(false)
    }, [])

    return (
        <>
            <div className="container-full background-login">
                { loadingLogin &&
                    <TopLoading />
                    }
                <Col>
                    <Card style={{ width: "300px" }} className="card-lock align-center ml-auto mr-auto">
                        <CardBody>
                            <Col>
                                <Col className="text-center" style={{ margin: " 1rem auto 2rem auto", width: "250px" }}>
                                    <CardImg top src={logo} style={{ width: '40%' }} />
                                </Col>
                                <Row>
                                    <Col >
                                        <Label style={{ fontWeight: '700' }}>Email</Label>
                                        <FormGroup>
                                            <Input
                                                type="email"
                                                name="email"
                                                id="loginEmail"
                                                placeholder="Digite seu email"
                                                value={login.email}
                                                onChange={e => onChangeLogin(e.target.value, "email")}
                                                autoComplete="off"
                                                className={invalidLogin.email ? "is-invalid" : ""}
                                            />
                                        </FormGroup>
                                        <Label style={{ fontWeight: '700' }}>Senha</Label>
                                        <FormGroup>
                                            <Input
                                                type="password"
                                                name="password"
                                                id="loginPassword"
                                                value={login.password}
                                                onChange={e => onChangeLogin(e.target.value, "password")}
                                                placeholder="Digite sua senha"
                                                onKeyPress={e => handleKeyPress(e, "login")}
                                                className={invalidLogin.password ? "is-invalid" : ""}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col className="text-center">
                                        <Button
                                            className="login-button"
                                            onClick={() => logIn()}
                                        >
                                            Login
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    {!request ?
                                        <Col className="text-center mt-2">
                                            <u ><span style={{ cursor: 'pointer' }} onClick={() => { sendEmail() }} className="default-color-login">Esqueci minha senha</span></u>
                                        </Col>
                                        :
                                        <Col className="text-center mt-2">
                                            <u ><span style={{ cursor: 'pointer' }} onClick={() => { sendEmail() }} className="default-color-login">Não recebi o email</span></u>
                                        </Col>
                                    }
                                </Row>
                                <Row className="mt-4">
                                    <Col className="text-center">
                                        <CustomInput
                                            type="checkbox"
                                            id="rememberCredentials"
                                            label="Lembrar minhas credenciais"
                                            checked={login.saveCredentials}
                                            onChange={() => saveCredentials()}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </CardBody >
                    </Card >
                </Col >
                <p style={{ color: "white", display: "flex", marginTop: "auto", marginRight: "10px" }}>{`v${version.version}`}</p>
            </div>
        </>
    );
}

export default Login;