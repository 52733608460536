const STORE_ID = "TIPO_COMISSAO";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    COMISSAO: "COMISSAO",
    SET_COMISSAO: "SET_COMISSAO",
    COMISSAO_COPY: "COMISSAO_COPY",
    INVALID_COMISSAO: "INVALID_COMISSAO",
    SET_INSTITUICOES: "SET_INSTITUICOES",
    SET_PRODUTOS_BANCO: "SET_PRODUTOS_BANCO",
    SET_ALL_PRODUTOS_BANCO: "SET_ALL_PRODUTOS_BANCO",
    SET_PRODUTOS_BANCO_II: "SET_PRODUTOS_BANCO_II",
    UNIDADE_COMISSAO: "UNIDADE_COMISSAO",
    COMISSOES: "COMISSOES",
    CLEAN_INVALID_COMISSAO: "CLEAN_INVALID_COMISSAO",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    PERIODICIDADES: "PERIODICIDADES",
    TIPOS_COMISSOES: "TIPOS_COMISSOES",
    BASES_CALCULOS: "BASES_CALCULOS",
    SET_TIPO_COMISSAO_BANCO: "SET_TIPO_COMISSAO_BANCO",
    CLEAN_TIPO_COMISSAO_BANCO: "CLEAN_TIPO_COMISSAO_BANCO",
    ADD_TIPO_COMISSAO_BANCO: "ADD_TIPO_COMISSAO_BANCO",
    REMOVE_TIPO_COMISSAO_BANCO: "REMOVE_TIPO_COMISSAO_BANCO",
    CLEAN_PRODUTOS_BANCO: "CLEAN_PRODUTOS_BANCO",
    SET_FILTROS: "SET_FILTROS"
}

export { STORE_ID, TYPES }