import React from "react";
import { useSelector } from "react-redux";
import { Col, Form, FormGroup, Label, Row, Input } from "reactstrap";
import * as actions from "../../../../../../store/modules/personas/papeis_store/actions";

export default function Fornecedor() {
  const { seeRegister, fornecedor } = useSelector(
    (state) => state.personasPapeis
  );
  const onChange = (value, field) => {
    actions.setFonecedor({ ...fornecedor, [field]: value });
  };

  return (
    <div style={{ marginTop: "25px" }}>
      <Col md={12}>
        <Form>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Código Fornecedor</Label>
                <Input
                  placeholder="Digite aqui..."
                  value={fornecedor.codigoFornecedor}
                  onChange={(e) => onChange(e.target.value, "codigoFornecedor")}
                  maxLength="150"
                  disabled={seeRegister}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Col>
    </div>
  );
}
