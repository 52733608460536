import api from "../../util/api";
import * as actions from "../../store/modules/dashboard/receita/actions";
import { setLoading } from "../../store/modules/globalLoading/actions";
import { removeMaskCNPJ } from "../../util/masks";

export async function get(filtros){
    setLoading(true);
    var isBigNumbersSuccessed = await getBigNumbers(filtros)
    
    var isTabelaSuccessed = await getTabelaReceitaBancos(filtros)
    
    var isTabela2Successed = await getTabelaReceitaProdutos(filtros)
    
    var isGraficoProdutoSuccessed = await getGraficoProduto(filtros)
    
    var isGraficoProjetadoSuccessed = await getGraficoProjetado(filtros)
    setLoading(false);

    if( isBigNumbersSuccessed
         && isGraficoProjetadoSuccessed
         && isGraficoProdutoSuccessed
         && isTabelaSuccessed
         && isTabela2Successed){
        return true
    }
}

export async function getGraficoProjetado(filtros){
    var url = `Receita/GraficoReceitaMensal?periodo=${filtros.periodo === '0' ? '3' : filtros.periodo}`

    if(filtros.instituicaoId !== '0'){
        url = url + `&BancoId=${filtros.instituicaoId}`
    }

    if(filtros.lojaId !== '0'){
        url = url + `&LojaId=${filtros.lojaId }`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&TipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.parceriaId !== '0'){
        url = url + `&ParceriaId=${filtros.parceriaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&ConvenioId=${filtros.convenioId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&GrupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&ConsultorId=${filtros.consultorId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    var newContent = [['Mês', "Receita"]]
    data.content.forEach((e)=>{
        newContent.push([e.mes, Number(e.valor.toFixed(2))])
    })
    actions.setGraficoProjetado(newContent);
    return true
}
export async function getGraficoProduto(filtros){
    var url = `Receita/GetGraficoReceitaProduto?periodo=${filtros.periodo === '0' ? '3' : filtros.periodo}`

    if(filtros.instituicaoId !== '0'){
        url = url + `&BancoId=${filtros.instituicaoId}`
    }

    if(filtros.lojaId !== '0'){
        url = url + `&LojaId=${filtros.lojaId }`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&TipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.parceriaId !== '0'){
        url = url + `&ParceriaId=${filtros.parceriaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&ConvenioId=${filtros.convenioId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&GrupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&ConsultorId=${filtros.consultorId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    var newContent = [['Produto', "Valor"]]
    data.content.forEach((e)=>{
        newContent.push([e.nomeProduto, Number(e.valor.toFixed(2))])
    })
    actions.setGraficoProduto(newContent);
    return true
}

export async function getBigNumbers(filtros){
    var url = `Receita/BigNumbers?dataInicio=`

    if(filtros.instituicaoId !== '0'){
        url = url + `&BancoId=${filtros.instituicaoId}`
    }

    if(filtros.lojaId !== '0'){
        url = url + `&LojaId=${filtros.lojaId }`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&TipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.parceriaId !== '0'){
        url = url + `&ParceriaId=${filtros.parceriaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&ConvenioId=${filtros.convenioId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&GrupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&ConsultorId=${filtros.consultorId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    actions.setBigNumbers(data.content);
    return true
}

export async function getTabelaReceitaProdutos(filtros){
    var url = `Receita/Tabela2Produto?periodo=${filtros.periodoTabela === '0' ? '3' : filtros.periodoTabela}`

           
    if(filtros.instituicaoId !== '0'){
        url = url + `&BancoId=${filtros.instituicaoId}`
    }

    if(filtros.lojaId !== '0'){
        url = url + `&LojaId=${filtros.lojaId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&GrupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&ConsultorId=${filtros.consultorId}`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&TipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.parceriaId !== '0'){
        url = url + `&ParceriaId=${filtros.parceriaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&ConvenioId=${filtros.convenioId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.tiposOperacao = []
    })
    actions.setTabelaProd(data.content);
    return true
}

export async function getTabelaReceitaTiposOperacoes(filtros, produtoId, tabela){
    var url = `Receita/Tabela2TipoOperacao?periodo=${filtros.periodoTabela === '0' ? '3' : filtros.periodoTabela}&ProdutoId=${produtoId}`

    if(filtros.instituicaoId !== '0'){
        url = url + `&BancoId=${filtros.instituicaoId}`
    }

    if(filtros.lojaId !== '0'){
        url = url + `&LojaId=${filtros.lojaId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&GrupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&ConsultorId=${filtros.consultorId}`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&TipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.parceriaId !== '0'){
        url = url + `&ParceriaId=${filtros.parceriaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&ConvenioId=${filtros.convenioId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.lojas = []
    })

    tabela.forEach((e)=>{
        if(e.id === produtoId){
            e.tiposOperacao = data.content
        }
    })

    actions.setTabelaProd(tabela);
    return true
}

export async function getTabelaReceita2Lojas(filtros, elemento, tabela){
    var url = `Receita/Tabela2Loja?periodo=${filtros.periodoTabela === '0' ? '3' : filtros.periodoTabela}&ProdutoId=${elemento.produtoId}&TipoOperacaoId=${elemento.tipoOperacaoId}`

    if(filtros.instituicaoId !== '0'){
        url = url + `&BancoId=${filtros.instituicaoId}`
    }

    if(filtros.lojaId !== '0'){
        url = url + `&LojaId=${filtros.lojaId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&GrupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&ConsultorId=${filtros.consultorId}`
    }

    if(filtros.parceriaId !== '0'){
        url = url + `&ParceriaId=${filtros.tipoParceriaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&ConvenioId=${filtros.convenioId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.consultores = []
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.produtoId){
            e.tiposOperacao.forEach((e)=>{
                if(e.id === elemento.tipoOperacaoId){
                    e.lojas = data.content
                }
            })
        }
    })

    actions.setTabelaProd(tabela);
    return true
}

export async function getTabelaReceita2Consultores(filtros, elemento, tabela){
    var url = `Receita/Tabela2Consultor?periodo=${filtros.periodoTabela === '0' ? '3' : filtros.periodoTabela}&ProdutoId=${elemento.produtoId}&TipoOperacaoId=${elemento.tipoOperacaoId}&LojaId=${elemento.lojaId}`


    if(filtros.instituicaoId !== '0'){
        url = url + `&BancoId=${filtros.instituicaoId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&GrupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&ConsultorId=${filtros.consultorId}`
    }

    if(filtros.parceriaId !== '0'){
        url = url + `&ParceriaId=${filtros.tipoParceriaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&ConvenioId=${filtros.convenioId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    tabela.forEach((e)=>{
        if(e.id === elemento.produtoId){
            e.tiposOperacao.forEach((e)=>{
                if(e.id === elemento.tipoOperacaoId){
                    e.lojas.forEach((e)=>{
                        if(e.id === elemento.lojaId){
                            e.consultores= data.content
                        }
                    })
                }
            })
        }
    })

    actions.setTabelaProd(tabela);
    return true
}

//-----------------------------------------------------------------------------------   
export async function getTabelaReceitaBancos(filtros){
    var url = `Receita/TabelaReceitaBanco?periodo=${filtros.periodoTabela === '0' ? '3' : filtros.periodoTabela}`

           
    if(filtros.instituicaoId !== '0'){
        url = url + `&BancoId=${filtros.instituicaoId}`
    }

    if(filtros.lojaId !== '0'){
        url = url + `&LojaId=${filtros.lojaId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&GrupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&ConsultorId=${filtros.consultorId}`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&TipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.parceriaId !== '0'){
        url = url + `&ParceriaId=${filtros.parceriaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&ConvenioId=${filtros.convenioId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.tiposParceria = []
    })
    actions.setTabelaProducao(data.content);
    return true
}

export async function getTabelaReceitaTiposParceria(filtros, instituicaoId, tabela){
    var url = `Receita/TabelaReceitaTipoParceria?periodo=${filtros.periodoTabela === '0' ? '3' : filtros.periodoTabela}&BancoId=${instituicaoId}`

    if(filtros.lojaId !== '0'){
        url = url + `&LojaId=${filtros.lojaId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&GrupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&ConsultorId=${filtros.consultorId}`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&TipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.parceriaId !== '0'){
        url = url + `&ParceriaId=${filtros.parceriaId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&ConvenioId=${filtros.convenioId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.canais = []
    })

    tabela.forEach((e)=>{
        if(e.id === instituicaoId){
            e.tiposParceria = data.content
        }
    })

    actions.setTabelaProducao(tabela);
    return true
}

export async function getTabelaReceitaCanais(filtros, elemento, tabela){
    var url = `Receita/TabelaReceitaCanal?periodo=${filtros.periodoTabela === '0' ? '3' : filtros.periodoTabela}&BancoId=${elemento.bancoId}&ParceriaId=${elemento.tipoParceriaId}`

    if(filtros.lojaId !== '0'){
        url = url + `&LojaId=${filtros.lojaId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&GrupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&ConsultorId=${filtros.consultorId}`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&TipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&ConvenioId=${filtros.convenioId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.lojas = []
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.bancoId){
            e.tiposParceria.forEach((e)=>{
                if(e.id === elemento.tipoParceriaId){
                    e.canais = data.content
                }
            })
        }
    })

    actions.setTabelaProducao(tabela);
    return true
}

export async function getTabelaReceitaLojas(filtros, elemento, tabela){
    var url = `Receita/TabelaReceitaLoja?periodo=${filtros.periodoTabela === '0' ? '3' : filtros.periodoTabela}&BancoId=${elemento.bancoId}&ParceriaId=${elemento.tipoParceriaId}&CanalId=${elemento.canalId}`

    if(filtros.lojaId !== '0'){
        url = url + `&LojaId=${filtros.lojaId}`
    }

    if(filtros.grupoId !== '0'){
        url = url + `&GrupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&ConsultorId=${filtros.consultorId}`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&TipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&ConvenioId=${filtros.convenioId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) =>{
        e.consultores = []
    })

    tabela.forEach((e)=>{
        if(e.id === elemento.bancoId){
            e.tiposParceria.forEach((e)=>{
                if(e.id === elemento.tipoParceriaId){
                    e.canais.forEach((e)=>{
                        if(e.id === elemento.canalId){
                            e.lojas= data.content
                        }
                    })
                }
            })
        }
    })

    actions.setTabelaProducao(tabela);
    return true
}

export async function getTabelaReceitaConsultores(filtros, elemento, tabela){
    var url = `Receita/TabelaReceitaConsultor?periodo=${filtros.periodoTabela === '0' ? '3' : filtros.periodoTabela}&BancoId=${elemento.bancoId}&ParceriaId=${elemento.tipoParceriaId}&CanalId=${elemento.canalId}&LojaId=${elemento.lojaId}`

    if(filtros.grupoId !== '0'){
        url = url + `&GrupoId=${filtros.grupoId}`
    }

    if(filtros.consultorId !== '0'){
        url = url + `&ConsultorId=${filtros.consultorId}`
    }

    if(filtros.tipoOperacaoId !== '0'){
        url = url + `&TipoOperacaoId=${filtros.tipoOperacaoId}`
    }

    if(filtros.convenioId !== '0'){
        url = url + `&ConvenioId=${filtros.convenioId}`
    }

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    tabela.forEach((e)=>{
        if(e.id === elemento.bancoId){
            e.tiposParceria.forEach((e)=>{
                if(e.id === elemento.tipoParceriaId){
                    e.canais.forEach((e)=>{
                        if(e.id === elemento.canalId){
                            e.lojas.forEach((e)=>{
                                if(e.id === elemento.lojaId){
                                    e.consultores = data.content
                                }
                            })
                        }
                    })
                }
            })
        }
    })

    actions.setTabelaProducao(tabela);
    return true
}

export async function getInstituicoes() {
    const data = await api.get(`Bancos`);

    if (!data.success) {
        return;
    }
    actions.setInsituicoes(data.content);
}

export async function getLojas(pesquisa, page, pageSize) {
    var url = `Pessoa?pagina=${page}&registros=${pageSize}&papel=5`
    if (pesquisa.nome !== ''){
        url = `Pessoa/busca-com-nome/${pesquisa.nome}?pagina=${page}&registros=${pageSize}&papel=5`
    } 

    else if (pesquisa.cnpj !== '') url = url + `&cpfCnpj=${removeMaskCNPJ(pesquisa.cnpj)}`

    actions.setLoadingLojas(true);
    const data = await api.get(url);
    actions.setLoadingLojas(false);

    if (!data.success) {
        return;
    }
    
    actions.setLojas(data.content.registros);
    actions.setGetAllLojas({
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas
    })
}

export async function getTiposOperacao() {
    const data = await api.get(`tipos-operacao`);

    if (!data.success) {
        return;
    }
    actions.setTiposOperacao(data.content);
}

export async function getParcerias() {
    const data = await api.get(`TipoParceira`);

    if (!data.success) {
        return;
    }
    actions.setParcerias(data.content);
}

export async function getConvenios() {
    const data = await api.get(`Convenios`);

    if (!data.success) {
        return;
    }
    actions.setConvenios(data.content);
}

export async function getGrupos() {
    const data = await api.get(`grupos`);

    if (!data.success) {
        return;
    }
    actions.setGrupos(data.content);
}

export async function getConsultores(pesquisa, page, pageSize) {
    var url = `lojas/consultores?pagina=${page}&registros=${pageSize}`

    if (pesquisa.cpf !== '') url = url + `&cpf=${pesquisa.cpf}`
    if (pesquisa.nome !== '') url = url + `&nome=${pesquisa.nome}`

    actions.setLoadingConsultores(true);
    const data = await api.get(url);
    actions.setLoadingConsultores(false);

    if (!data.success) {
        return;
    }
    
    actions.setGetAllConsultores({
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas
    })
    actions.setConsultores(data.content.registros);
}