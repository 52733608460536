import styled from "styled-components";

export const YearSelector = styled.select`
  margin: 0 10px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 16px;
`;

export const MonthSelector = styled.select`
  margin: 0 10px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 16px;
`;

export const CustomButton = styled.button`
  padding: 10px;
  margin: 5px 0;
  border: none;
  border-radius: 8px;
  background-color: #c9e9d0;
  color: #696969;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  width: 100%;

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #28a745;
    color: #fff;
  }
`;

export const ButtonArrow = styled.button`
  padding: 5px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  color: #00000;
  cursor: pointer;
  font-size: 24px;

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

export const ButtonClear = styled.button`
  padding: 8px;
  margin: 5px 0;
  border: 2px solid #696969;
  border-radius: 8px;
  background-color: transparent;
  color: #696969;
  cursor: pointer;
  font-size: 14px;
  width: 100%;

  &:disabled {
    background-color: #ddd;
    color: #aaa;
    border: 2px solid #ddd;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #28a745;
    color: #fff;
    border: none;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;


export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: unset;

  & .react-datepicker__month-container {
    border: solid #aeaeae 1px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: flex-start;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  flex-grow: 1;
`;

export const CalendarWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;




