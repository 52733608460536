import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    pesquisar: {
        cnpj: '',
        titulo: '',
        empresa: '',
        fornecedor: '',
        dataEntrada: '',
        dataEmissao: '',
        dataVencimento: '',
    },
    item: {
        id: '',
        empresaId: 'RiberCred-RP',
        filialId: '0',
        filial: '',
        cnpjFilial: 'xx.xxx.xxx/xxxx-xx',
        titulo: '',
        tipoTitulo: '',
        fornecedorId: '0',
        cnpjFornecedor: 'xx.xxx.xxx/xxxx-xx',
        fornecedor: '',
        favorecidoId: '0',
        favorecido: '',
        valor: 0,
        formaPagamento: '',
        transacaoValor: '',
        dataEntrada: '',
        dataEmissao: '',
        dataVencimento: '',
        considerarJuros: 0,
        percJuros: 0,
        percMulta: 0,
        portador: 0,
        carteira: 0,
        observacao: '',
        agencia: '',
        conta: '',
        contaFinanceira: '',
        contaContabil: '',
        projeto: '',
        orcamento: '',
        percRateio: 0,
        rateio: 0,
        centroCusto: '',
        valorRateioCentroCusto: 0,
        percRateioCentroCusto: 0,
    },
    modalFornecedor: {
        id: '',
        cnpj: '',
        nome: ''
    },
    modalFilial: {
        id: '',
        cnpj: '',
        nome: ''
    },
    modalFavorecido: {
        id: '',
        cpfCnpj: '',
        nome: '',
        filtro: '',
        filtroId: '',
    },
    getAllFornecedor: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'

    },
    getAllFilial: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    getAllFavorecido: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    allItens: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
    contas: [],
    empresas: [],
    filiais: [],
    fornecedores: [],
    favorecidos: [],
    copia: {},
    activeTab: "Search",
    seeRegister: false,
    modalFilialLoading: false,
    modalFavorecidoLoading: false,
    modalFornecedorLoading: false,
    permitions: {
        criar: false,
        deletar: false,
    }
}

export default function contasAPagar(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.PESQUISAR:
                    return { ...state, pesquisar: { ...state.pesquisar, [action.campo]: action.value } }
                case TYPES.SET_ITEM:
                    return { ...state, item: { ...state.item, [action.campo]: action.value } }
                case TYPES.SET_MODAL_FORNECEDOR:
                    return { ...state, modalFornecedor: { ...state.modalFornecedor, [action.campo]: action.value } }
                case TYPES.SET_MODAL_FILIAL:
                    return { ...state, modalFilial: { ...state.modalFilial, [action.campo]: action.value } }
                case TYPES.SET_MODAL_FAVORECIDO:
                    return { ...state, modalFavorecido: { ...state.modalFavorecido, [action.campo]: action.value } }
                case TYPES.ITEM:
                    return { ...state, item: action.value }
                case TYPES.SET_CONTAS:
                    return { ...state, contas: action.value }
                case TYPES.SET_EMPRESAS:
                    return { ...state, empresas: action.value }
                case TYPES.SET_FILIAIS:
                    return { ...state, filiais: action.value }
                case TYPES.SET_FORNECEDORES:
                    return { ...state, fornecedores: action.value }
                case TYPES.SET_FAVORECIDOS:
                    return { ...state, favorecidos: action.value }
                case TYPES.SET_ALL_ITENS:
                    return { ...state, allItens: action.value }
                case TYPES.SET_GET_FAVORECIDO:
                    return { ...state, getAllFavorecido: action.value }
                case TYPES.SET_GET_FORNECEDOR:
                    return { ...state, getAllFornecedor: action.value }
                case TYPES.SET_GET_FILIAL:
                    return { ...state, getAllFilial: action.value }
                case TYPES.SET_COPIA:
                    return { ...state, copia: action.value }
                case TYPES.CLEAN_SEARCH:
                    return { ...state, pesquisar: INITIAL_STATE.pesquisar, comissoes: [] }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, item: INITIAL_STATE.item }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                case TYPES.SET_LOADING_FILIAL:
                    return { ...state, modalFilialLoading: action.value }
                case TYPES.SET_LOADING_FAVORECIDO:
                    return { ...state, modalFavorecidoLoading: action.value }
                case TYPES.SET_LOADING_FORNECEDOR:
                    return { ...state, modalFornecedorLoading: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}