import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as statusRcActions from "../store/modules/statusRibercred/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("status-propostas");
    setLoading(false)

    if (!data.success) {
        return;
    }

    statusRcActions.getItems(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`status-propostas/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    statusRcActions.copy(data.content);
    statusRcActions.item(data.content);
}

export async function create(item) {

    if (item.descricao.trim() === "") {
        statusRcActions.setInvalid("descricao");
        notifyWarn("Descrição do status ribercred não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao,
    }

    setLoading(true)
    const data = await api.post("status-propostas", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Status ribercred cadastrado");
    statusRcActions.cleanRegister();
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`status-propostas/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Status ribercred removido");
    get();
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (item.descricao === "") {
        statusRcActions.setInvalid("descricao");
        notifyWarn("Descrição do status ribercred não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao
    }

    setLoading(true)
    const data = await api.put(`status-propostas/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Status ribercred atualizado");
    statusRcActions.cleanRegister();
    statusRcActions.setActiveTab("Search");
    get();
}
