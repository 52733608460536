import api from "../../util/api";
import * as informacoesActions from "../../store/modules/personas/informacoes_store/actions";
import { maskDate, removeMaskCNPJ, removeMaskCPF } from "../../util/masks";
import { setLoading } from "../../store/modules/globalLoading/actions";
import { notifySuccess } from "../../components/message/message";

export async function getOrgaosRg() {
    const data = await api.get(`OrgaoEmissor`);

    if (!data.success) {
        return;
    }

    informacoesActions.getItemsOrgaoRg(data.content);
}

export async function getUfDoc() {
    const data = await api.get("Estados");

    if (!data.success) {
        return;
    }

    informacoesActions.getItemsUfDoc(data.content);
}

export async function getSocios(pesquisa) {

    let cpfNoMask = removeMaskCPF(pesquisa.cpf)

    setLoading(true);
    const data = await api.get(`Pessoa/PessoaSocio?cpfCnpj=${cpfNoMask}&nome=${pesquisa.nome}&flagJuridica=false`)
    setLoading(false);

    informacoesActions.setSociosPesquisa(data.content);

}

export async function getSerpro(cnpj) {
    setLoading(true);
    const data = await api.get(`Pessoa/sepro?cnpj=${removeMaskCNPJ(cnpj)}`);
    setLoading(false);

    if (!data.success) {
        return;
    }

    informacoesActions.setSerpro(true);

    let informacoes = {
        id: data.content.id,
        razaoSocial: data.content.nome,
        nomeFantasia: data.content.nomeFantasia,
        isento: data.content.isento,
        cnpj: data.content.cpfCnpj,
        dataCriacao: maskDate(data.content.dataNascimento, "YYYY-MM-DD"),
        inscricaoEstadual: data.content.ie,
        inscricaoMunicipal: data.content.im,
    }

    let socios = []

    data.content.socios.forEach((socio) => {
        socios.push({
            id: socio.id,
            socio: socio.pessoaSocio.nome,
            pessoaSocioId: socio.pessoaSocioId,
        });
    })

    informacoesActions.item(informacoes);
    informacoesActions.setSocios(socios);
}

export async function removeSocios(idSocio) {
    setLoading(true);
    const data = await api.delete(`Pessoa/${idSocio}/socios`);
    setLoading(false);

    if (!data.success) {
        return;
    }

    notifySuccess("Sócio removido")
}