import { STORE_ID, TYPES } from "./types";
import store from "../../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function item(value) {
    dispatch({
        type: TYPES.INFORMACAO,
        value
    });
}

export function setSocios(value) {
    dispatch({
        type: TYPES.SOCIOS,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_INFORMACAO,
        field
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_INFORMACAO,
        field
    });
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setActivePersona(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_PERSONA,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function setSerpro(value) {
    dispatch({
        type: TYPES.SET_SERPRO,
        value
    })
}

export function getItemsOrgaoRg(value) {
    dispatch({
        type: TYPES.ITEMS_ORGAO_RG,
        value
    })
}

export function getItemsUfDoc(value) {
    dispatch({
        type: TYPES.ITEMS_UF_DOC,
        value
    })
}

export function setPesquisa(value) {
    dispatch({
        type: TYPES.SET_PESQUISA,
        value
    })
}

export function setSociosPesquisa(value) {
    dispatch({
        type: TYPES.SET_SOCIOS_PESQUISA,
        value
    })
}

export function cleanSearch() {
    dispatch({
        type: TYPES.CLEAN_SEARCH
    })
}

export function addSocio(value) {
    dispatch({
        type: TYPES.ADD_SOCIO,
        value
    })
}

export function removeSocio(index) {
    dispatch({
        type: TYPES.REMOVE_SOCIO,
        index
    })
}