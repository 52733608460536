import React from "react";
import * as BiIcons from "react-icons/bi";
import * as contatoActions from "../../../../../../store/modules/personas/contato_store/actions";
import * as controller from '../../../../../../controllers/personasController/contatosController'
import { useSelector } from "react-redux";
import { notifySuccess } from "../../../../../../components/message/message";

function TableEmail({emails}) {

    const { seeRegister, tiposEmails } = useSelector(state => state.personasContato);

    const data = emails;

    async function handleRemoverItem(id, index) {
        var isValid = await controller.deleteEmail(id)
        if(isValid){
            const arrEmails = emails;

            arrEmails.splice(index, 1);
            contatoActions.setEmails(arrEmails);

            notifySuccess('E-mail excluído')
        }       
    }

    return (
        <>
            <table style={{marginTop: '20px', width: '95%', marginLeft: '2%'}}>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Tipo do email</th>
                        <th style={{ textAlign: 'center' }}>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => {
                        var tipoEmail = tiposEmails.find(e=> e.id === row.tipoEmailId)
                        return (
                            <>
                                <tr>
                                    <td>{row.nome}</td>
                                    <td>{tipoEmail!== undefined && tipoEmail.nome}</td>                                 
                                    <td style={{ textAlign: 'center' }}>
                                        <>
                                           { !seeRegister && 
                                             <BiIcons.BiTrash
                                                style={{ fontSize: '1.2rem', cursor: 'pointer', color: '#C4C7CA' }}
                                                onClick={() =>{ handleRemoverItem(row.id, index)}}
                                            />}
                                        </>
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

export default TableEmail
