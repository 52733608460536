import React, { useState } from "react";
import "./styles.css";
import { SidebarLink, SidebarLabel, DropDownLink, SidebarLinkMini } from './styles';
import { Tooltip } from "@material-ui/core";
import { useLocation } from "react-router-dom";

export const SubMenu = ({ item, expand }) => {

    const [subnav, setSubnav] = useState(false);

    const { pathname } = useLocation();

    const showSubnav = () => setSubnav(!subnav);
    return (
        <>
             <SidebarLink key={item.id} to={item.path !== undefined && item.path} onClick={item.subNav ? showSubnav : expand} style={pathname === item.path ? {background: '#dbead5'} : {color: `black`}}>
                <div>
                    {item.icon}
                    <SidebarLabel key={item.id} icon-activated>{item.title}</SidebarLabel>
                </div>
                <div>
                    {item.subNav && subnav
                        ? item.iconOpened
                        : item.subNav
                            ? item.iconClosed
                            : null
                    }
                </div>
            </SidebarLink>
            {
                subnav && item.subNav?.map(item => {
                    return (
                        <DropDownLink key={item.id} to={item.path} onClick={expand} style={pathname === item.path ? {background: '#dbead5', height: '50px'}: {height: '50px'}}>
                            <SidebarLabel key={item.id} style={{color: 'var(--color-roxo)', fontWeight: '600'}}>{item.title}</SidebarLabel>
                        </DropDownLink>
                    )
                })
            }
        </>
    )
}

export const SubMenuMini = ({ item, expand }) => {
    const [toggle, setToggle] = useState(false);
    return (
        <>
            {!item.subNav ?
                <Tooltip title={item.title} placement="right">
                    <SidebarLinkMini key={item.id} to={item.path || '/'} onClick={expand}>
                        <div>{item.icon}</div>
                    </SidebarLinkMini>
                </Tooltip>
                :
                <>
                    <Tooltip title={item.title} placement="right">
                        <SidebarLinkMini style={{ background: toggle && '#dbead5' }} key={item.id} onClick={() => { setToggle(!toggle); expand() }} to={'#'}>
                            <div>{item.icon}</div>
                        </SidebarLinkMini>
                    </Tooltip>
                    {toggle && item.subNav.map((item, i) => {
                        return (
                            <Tooltip key={i} title={item.title} placement="right">
                                <SidebarLinkMini style={{ background: '#dbead5' }} to={item.path} onClick={expand}>
                                    <div>{item.icon}</div>
                                </SidebarLinkMini>
                            </Tooltip>
                        )
                    })
                    }
                </>
            }

        </>
    )
}

export default SubMenuMini;