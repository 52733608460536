const STORE_ID = "CENTRO_CUSTO";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    CENTRO_CUSTO: "CENTRO_CUSTO",
    HEADCOUNT: "HEADCOUNT",
    SET_HEADCOUNT: "SET_HEADCOUNT",
    CLEAN_HEADCOUNT: "CLEAN_HEADCOUNT",
    CENTRO_CUSTO_COPY: "CENTRO_CUSTO_COPY",
    INVALID_CENTRO_CUSTO: "INVALID_CENTRO_CUSTO",
    CENTROS_CUSTO: "CENTROS_CUSTO",
    CLEAN_INVALID_CENTRO_CUSTO: "CLEAN_INVALID_CENTRO_CUSTO",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE"
}

export { STORE_ID, TYPES }