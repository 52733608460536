import React from 'react'
import { BsArrowUp, BsArrowDown } from 'react-icons/bs'

import { maskMoeda } from '../../../../util/masks'

export default function BigNumbersCancelados({element}){
    return(
        <>
            <div style={{background: 'rgba(151, 224, 79, 0.25)',  borderRadius: '20px',width: '100%', height: '110px', marginRight: '20px', paddingTop: '15px', display: 'flex'}}>
                <div style={{width: '33%', paddingTop: '10px'}}>
                    <p style={{textAlign: 'center', fontsize: '18px', marginBottom: '3px', color: 'var(--color-verde-claro)'}}>
                        {`${element.qtdDigitado}/${element.qtdCancelado}`}
                    </p>
                    <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                        {`R$${maskMoeda(element.valorCancelado.toFixed(2))}`}
                    </p>
                    <p style={{textAlign: 'center', fontSize: '13px'}}>
                        Cancelado
                    </p>
                </div>
                <div style={{width: '33%', paddingTop: '15px'}}>
                    <p style={{textAlign: 'center',  fontsize: '18px', marginBottom: '3px', color: 'var(--color-verde-claro)'}}>
                        {`${element.percentualMesAtual}%`}
                    </p>
                    <p style={{textAlign: 'center', fontSize: '13px'}}>
                        Mês atual
                    </p>
                </div>
                <div style={{width: '33%', paddingTop: '12px'}}>
                    {element.percentualMesAnterior >= 0 ? 
                        <p style={{textAlign: 'center', fontsize: '18px', marginBottom: '3px', color: 'var(--color-verde-claro)'}}>
                           <BsArrowUp style={{fontSize: '20px'}}/> {`${element.percentualMesAnterior}%`}
                        </p>
                    :
                        <p style={{textAlign: 'center', fontsize: '18px', marginBottom: '3px', color: '#FF6635'}}>
                            <BsArrowDown style={{fontSize: '20px'}}/> {`${element.percentualMesAnterior}%`}
                        </p>
                    }
                    <p style={{textAlign: 'center', fontSize: '13px'}}>
                        Mês anterior
                    </p>
                </div>
            </div>
            <div style={{background: 'rgba(151, 224, 79, 0.25)',  borderRadius: '20px',width: '100%', height: '110px', marginRight: '20px', paddingTop: '25px'}}>
                <p style={{textAlign: 'center',  fontsize: '18px', marginBottom: '3px', color: 'var(--color-verde-claro)'}}>
                    {`${element.qtdCancelado}/${element.qtdNaoTratado}`}
                </p>
                <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                    {`R$${maskMoeda(element.valorNaoTratado.toFixed(2))}`}
                </p>
                <p style={{textAlign: 'center', fontSize: '13px'}}>
                    Não tratado
                </p>
            </div>
            <div style={{background: 'rgba(151, 224, 79, 0.25)',  borderRadius: '20px',width: '100%', height: '110px', marginRight: '20px', paddingTop: '15px', display: 'flex'}}>
                <div style={{width: '50%', paddingTop: '10px', marginLeft: '40px'}}>
                    <p style={{textAlign: 'center', fontsize: '18px', marginBottom: '3px', color: 'var(--color-verde-claro)'}}>
                        {`${element.qtdCancelado}/${element.qtdReaproveitado}`}
                    </p>
                    <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                        {`R$${maskMoeda(element.valoreaproveitado.toFixed(2))}`}
                    </p>
                    <p style={{textAlign: 'center', fontSize: '13px'}}>
                        Reaproveitado
                    </p>
                </div>
                <div style={{paddingTop: '30px'}}>
                    {element.percentReaproveitamento >= 0 ?
                        <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px', color: 'var(--color-verde-claro)'}}>
                           <BsArrowUp style={{fontSize: '20px'}}/> {`${element.percentReaproveitamento}%`}
                        </p>
                    :
                        <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px', color: '#FF6635'}}>
                            <BsArrowDown style={{fontSize: '20px'}}/>{`${element.percentReaproveitamento}%`}
                        </p>
                    }
                </div>
            </div>
        </>
    )
}