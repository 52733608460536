import React, { useEffect, useState } from "react";
import * as BsIcons from "react-icons/bs";
import { Collapse, Navbar, NavbarToggler, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Col, Row } from 'reactstrap';
import {currentRoutes} from "../../routes";
import { SubMenu, SubMenuMini } from "./subMenu/subMenu";
import logo from "../../assets/images/logo.svg";
import RoutesPath from "../../routes/routesPath";
import { SidebarNav, SidebarNavMini, SidebarWrap, SidebarContainer, Container, PageContentContainer } from './styles';
import history from "../../util/history";
import ModalSenha from "./modal/modalSenha";
import version from "../../../package.json";
// import { UserAccessControll } from "../../util/auth";
// import { NotFound } from "../../views/notFound/notFound";
import { RiPushpin2Fill, RiPushpin2Line } from "react-icons/ri";
import BlurLoading from "../Loading/BlurLoading";
import { useSelector } from "react-redux";

import * as loginActions from '../../store/modules/login/actions'
import * as controller from '../../controllers/loginController'
import { AiOutlineSearch } from "react-icons/ai";

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [modalSenha, setModalSenha] = useState(false);
    const toggleModal = () => setModalSenha(!modalSenha);

    const [ user, setUser ] = useState({})

    function logout() {
        history.push("/login");
        localStorage.removeItem("TOKEN_KEY");
        localStorage.removeItem("id");
        localStorage.removeItem("userControllkeys");
        loginActions.setUserControllKeys([])
    }

    useEffect(()=>{
        getUser()
    },[])

    async function getUser(){
        const { nome, email } = await controller.getPermitions()
        setUser({
            nome,
            email
        })
    }

    return (
        <>
            {modalSenha && <ModalSenha isOpen={true} toggle={toggleModal} />}
            <Navbar expand="md" style={{ width: "100%", background: 'white', padding: '0px 15px', height: '50px' }}>
                <div >
                    <img src={logo} alt="Logo" style={{ width: '30px', height: '30px' }} />
                </div>
                <div style={{ marginLeft: 'auto'}}>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="mr-auto" navbar style={{display: 'flex', alignItems: 'center'}}>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle className="mr-2" nav>
                                    <BsIcons.BsBell style={{ fontSize: "1.5rem", color: 'var(--color-roxo)' }} />
                                    <span className="badge rounded-pill bg-warning text-white">3</span>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                        Option 1
                                    </DropdownItem>
                                    <DropdownItem>
                                        Option 2
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        Reset
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <h3 style={{margin: '0', marginRight: '1rem', fontSize: '14px', color: 'var(--color-roxo)'}}> 
                                { user.nome }
                            </h3>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav style={{ background: 'linear-gradient(264.61deg, #87DF41 -9.34%, #00AB4F 101.57%)', borderRadius: '7px' }}>
                                    <BsIcons.BsPerson style={{ fontSize: "1.5rem", color: 'white' }} />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem onClick={toggleModal}>
                                        Alterar Senha
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => logout()}>
                                        Sair
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </div>
            </Navbar>
        </>
    )
}

const Sidebar = () => {
    const { userControllKeys } = useSelector(state => state.login)
    const { routes } = useSelector(state => state.routes)

    const [items, setItems] = useState(routes)

    const { isLoading } = useSelector(state => state.loading)

    const [sidebar, setSidebar] = useState(false);
    const [isSidebarFixed, setIsSidebarFixed] = useState(false);
    const expandSidebar = () => !isSidebarFixed && setSidebar(!sidebar);

    const handleClickOutside = () => !sidebar ? expandSidebar() : {}

    useEffect(()=>{
        currentRoutes(userControllKeys)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        handleInputChange('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[routes])

    function filterItems(inputValue) {
        const searchInputIsEmpty = inputValue.length === 0;

        if (searchInputIsEmpty) {
          setItems(routes);
          return;
        }

        function executeRecursiveFilter(initialData) {
          let matches = [];

          for (let i = 0; i < initialData?.length; i++) {
            if (initialData[i].title.toLowerCase().includes(inputValue.toLowerCase())) {
              matches.push(initialData[i]);
            } else {
              let subItemResult = executeRecursiveFilter(initialData[i].subNav);
              if (subItemResult.length > 0) {
                matches.push(...subItemResult);
              }
            }
          }

          return matches;
        }
        const filterResults = executeRecursiveFilter(routes);
        setItems(filterResults);
      }
    
      function handleInputChange(inputValue) {
        filterItems(inputValue);
      }

    return (
        <>
            <div style={{ width: "100%", display: "flex" }}>
                <SidebarContainer>
                    <SidebarNav className={!sidebar ? "sidebar-open" : "sidebar-close"} >
                        <SidebarWrap>
                            <div style={{ display: 'flex' }}>
                                {!isSidebarFixed && <BsIcons.BsX
                                    style={{
                                        marginLeft: '10px',
                                        marginTop: '5px',
                                        fontSize: '15px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={expandSidebar} />
                                }
                                {!isSidebarFixed ?
                                    <RiPushpin2Line
                                        style={{
                                            marginLeft: '80%',
                                            marginTop: '5px',
                                            fontSize: '15px',
                                            color: 'var(--color-roxo)',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setIsSidebarFixed(!isSidebarFixed)}
                                    />
                                    :
                                    <RiPushpin2Fill
                                        style={{
                                            marginLeft: '90%',
                                            marginTop: '5px',
                                            fontSize: '15px',
                                            color: 'var(--color-roxo)',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setIsSidebarFixed(!isSidebarFixed)}
                                    />}
                            </div>

                            <div style={{display: 'flex', padding: '5px', borderBottom: '1px solid #c4c7ca', width:'80%', marginLeft: '10%'}}>
                                <AiOutlineSearch style={{color: 'var(--color-preto)', fontSize: '20px', marginTop: '15px'}}/>
                                <input
                                    placeholder="Search"
                                    style={{marginTop: '10px', border: 'none', outline: 'none'}}
                                    id="search-sidebar-routes"
                                    name="search-sidebar-routes"
                                    onChange={(e) => handleInputChange(e.target.value)}
                                />
                            </div>
                            {items.map((item, index) => {
                                return (
                                    <>
                                        { <SubMenu item={item} key={index} expand={expandSidebar} />}
                                    </>
                                )
                            })}
                        </SidebarWrap>
                    </SidebarNav>
                    <SidebarNavMini>
                        <SidebarWrap>
                            <BsIcons.BsList onClick={expandSidebar} className="btn-icon-sidebar" style={{ cursor: 'pointer', fontSize: "2rem", marginTop: '10px' }} />
                            {routes.map((item, index) => {
                                return (
                                    <>
                                        {item.miniMenu ? <SubMenuMini item={item} index={index} key={index} expand={expandSidebar} /> : null}
                                    </>
                                )
                            })}
                            <Col className="text-center pb-4">
                                <p style={{ marginTop: "-15px", color: "grey" }}>{`v${version.version}`}</p>
                            </Col>
                        </SidebarWrap>
                    </SidebarNavMini>
                </SidebarContainer>
                <Container onClick={!isSidebarFixed && handleClickOutside}>
                    {isLoading &&
                        <BlurLoading />
                    }
                    <Col >
                        <Row>
                            <Navigation />
                        </Row>

                        <Row>

                            {
                            // UserAccessControll() ?
                                isSidebarFixed ?
                                    <PageContentContainer isSidebarFixed={isSidebarFixed}>
                                        <RoutesPath/>
                                    </PageContentContainer> 
                                :
                                    <div style={{width: '100%'}}>
                                    <RoutesPath/>
                                    </div> 
                                // : <NotFound />
                            }
                        </Row>
                    </Col>
                </Container>
            </div>
        </>
    )
}

export default Sidebar;
