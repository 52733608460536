import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function login(field, value) {
    dispatch({
        type: TYPES.LOGIN,
        field,
        value
    });
}

export function invalidLogin(field) {
    dispatch({
        type: TYPES.INVALID_LOGIN,
        field
    });
}

export function cleanInvalidLogin(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_LOGIN,
        field
    });
}

export function cleanAll() {
    dispatch({
        type: TYPES.CLEAN_ALL
    });
}

export function setUserControllKeys(value){
    dispatch({
        type: TYPES.SET_USER_CONTROLL_KEYS,
        value
    })
}

export function setRequest(value){
    dispatch({
        type: TYPES.SET_REQUEST,
        value
    })
}

export function setLoadingLoagin(value){
    dispatch({
        type: TYPES.SET_LOADING_LOGIN,
        value
    })
}
