import api from "../../util/api";
import * as actions from "../../store/modules/dashboard/producao/actions";
import { setLoading } from "../../store/modules/globalLoading/actions";
import { maskMoeda, removeMaskCNPJ } from "../../util/masks";
import { notifyWarn } from "../../components/message/message";

export async function get(filtros) {
    const currentDate = new Date().toISOString().slice(0, 10)

    for (let filtro of filtros) {
        if (filtro.chave === 'dataInicio' && filtro.descricao === '') {
            return notifyWarn('É necessário informar a data de término do período')
        }
        if (filtro.chave === 'dataFim' && filtro.descricao === '') {
            return notifyWarn('É necessário informar data de fim')
        }

        if (filtro.chave === 'dataInicio' && filtro.descricao > currentDate) {
            return notifyWarn('A data de início é maior do que a data atual')
        }
        if (filtro.chave === 'dataFim' && filtro.descricao > currentDate) {
            return notifyWarn('A data fim é maior do que a data atual')
        }
        if (filtro.chave === 'dataInicio' && filtro.descricao < '1900-01-01') {
            return notifyWarn('Filtro de data inválido')
        }
    }

    try {
        setLoading(true);
        const [
            isBigNumbersSuccessed,
            isTabelaSuccessed,
            isTabelaProdutos,
            isTabelaCanais
        ] = await Promise.all([
            getBigNumbers(filtros),
            getTabelaProducaoBancos(filtros),
            getTabelaProducaoPorProdutos(filtros),
            getTabelaProducaoPorCanal(filtros)
        ]);
    
        if (isBigNumbersSuccessed.success && isTabelaSuccessed.success && isTabelaProdutos.success && isTabelaCanais.success) {
            actions.setBigNumbers(isBigNumbersSuccessed.data);
            actions.setTabelaProducao(isTabelaSuccessed.data);
            actions.setTabelaProduto(isTabelaProdutos.data);
            actions.setTabelaCanal(isTabelaCanais.data);
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    } finally {
        setLoading(false);
    }
}

export async function getBigNumbers(filtros) {
    var url = `BigNumbers/GetBigNumbers`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }

        if (e.chave === 'produtosRibercredId') {
            url = url + `&produtoBancoId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }
    })

    const data = await api.get(url);

    if (!data.success) {
        return { success: false };
    }
    return { success: true, data: data.content }
}

export async function getTabelaProducaoBancos(filtros) {
    var url = `TabelaProducao/GetTabelaProducaoBanco`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }

        if (e.chave === 'produtosRibercredId') {
            url = url + `&produtoId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }
    })

    const data = await api.get(url);

    if (!data.success) {
        return { success: false };
    }

    data.content.forEach((e) => {
        e.produtos = []
    })
    return { success: true, data: data.content }
}

export async function getTabelaProducaoProdutos(filtros, instituicaoId, tabela) {
    var url = `TabelaProducao/GetTabelaProducaoProdutos`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'produtosRibercredId') {
            url = url + `&produtoId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }
    })

    url = url + `&bancoId=${instituicaoId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) => {
        e.lojas = []
    })


    tabela.forEach((e) => {
        if (e.id === instituicaoId) {
            e.produtos = data.content
        }
    })
    actions.setTabelaProducao(tabela);
    return true
}

export async function getTabelaProducaoPorProdutos(filtros) {
    var url = `TabelaProducao/tabela-produtos`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'produtosRibercredId') {
            url = url + `&produtoId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }
    })

    const data = await api.get(url);

    if (!data.success) {
        return { success: false };
    }

    data.content.forEach((e) => {
        e.lojas = []
    })

    return { success: true, data: data.content }
}

export async function getTabelaProducaoPorProdutoLojas(filtros, elemento, tabela) {
    var url = `TabelaProducao/tabela-produtos-loja`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }
    })

    url = url + `&produtoId=${elemento.produtoId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) => {
        e.consultores = []
    })


    tabela.forEach((e) => {
        if (e.id === elemento.produtoId) {
            e.lojas = data.content
        }
    })
    actions.setTabelaProduto(tabela);
    return true
}

export async function getTabelaProducaoPorProdutoConsultores(filtros, elemento, tabela) {
    var url = `TabelaProducao/tabela-produtos-consultor`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }
    })

    url = url + `&produtoId=${elemento.produtoId}`
    url = url + `&lojaId=${elemento.lojaId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) => {
        e.propostas = []
    })

    tabela.forEach((e) => {
        if (e.id === elemento.produtoId) {
            e.lojas.forEach((e) => {
                if (e.id === elemento.lojaId) {
                    e.consultores = data.content
                }
            })
        }
    })
    actions.setTabelaProduto(tabela);
    return true
}

export async function getTabelaProducaoPorProdutoPropostas(filtros, elemento, tabela) {
    var url = `TabelaProducao/tabela-produtos-proposta`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }
    })

    url = url + `&produtoId=${elemento.produtoId}`
    url = url + `&lojaId=${elemento.lojaId}`
    url = url + `&consultorId=${elemento.consultorId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    tabela.forEach((e) => {
        if (e.id === elemento.produtoId) {
            e.lojas.forEach((e) => {
                if (e.id === elemento.lojaId) {
                    e.consultores.forEach((e) => {
                        if (e.id === elemento.consultorId) {
                            e.propostas = data.content
                        }
                    })
                }
            })
        }
    })
    actions.setTabelaProduto(tabela);
    return true
}

export async function getTabelaProducaoLojas(filtros, elemento, tabela) {
    var url = `TabelaProducao/GetTabelaProducaoLojas`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }
    })

    url = url + `&bancoId=${elemento.instituicaoId}`
    url = url + `&produtoId=${elemento.produtoId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) => {
        e.consultores = []
    })


    tabela.forEach((e) => {
        if (e.id === elemento.instituicaoId) {
            e.produtos.forEach((e) => {
                if (e.id === elemento.produtoId) {
                    e.lojas = data.content
                }
            })
        }
    })
    actions.setTabelaProducao(tabela);
    return true
}

export async function getTabelaProducaoConsultores(filtros, elemento, tabela) {
    var url = `TabelaProducao/GetTabelaProducaoConsultores`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }
    })

    url = url + `&bancoId=${elemento.instituicaoId}`
    url = url + `&produtoId=${elemento.produtoId}`
    url = url + `&lojaId=${elemento.lojaId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) => {
        e.propostas = []
    })


    tabela.forEach((e) => {
        if (e.id === elemento.instituicaoId) {
            e.produtos.forEach((e) => {
                if (e.id === elemento.produtoId) {
                    e.lojas.forEach((e) => {
                        if (e.id === elemento.lojaId) {
                            e.consultores = data.content
                        }
                    })
                }
            })
        }
    })
    actions.setTabelaProducao(tabela);
    return true
}

export async function getTabelaProducaoPropostas(filtros, elemento, tabela) {
    var url = `TabelaProducao/GetTabelaProducaoPropostas`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }
    })

    url = url + `&bancoId=${elemento.instituicaoId}`
    url = url + `&produtoId=${elemento.produtoId}`
    url = url + `&lojaId=${elemento.lojaId}`
    url = url + `&consultorId=${elemento.consultorId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    tabela.forEach((e) => {
        if (e.id === elemento.instituicaoId) {
            e.produtos.forEach((e) => {
                if (e.id === elemento.produtoId) {
                    e.lojas.forEach((e) => {
                        if (e.id === elemento.lojaId) {
                            e.consultores.forEach((e) => {
                                if (e.id === elemento.consultorId) {
                                    e.propostas = data.content
                                }
                            })
                        }
                    })
                }
            })
        }
    })
    actions.setTabelaProducao(tabela);
    return true
}

export async function getGraficoProducao(filtros) {
    var url = `TabelaProducao/GetGraficoProducao`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }

        if (e.chave === 'produtosRibercredId') {
            url = url + `&produtoId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }
    })

    setLoading(true);
    const data = await api.get(url);

    if (!data.success) {
        return { success: false };
    }

    let dadosGrafico = [['ID', 'Parent', 'Size'], ['Bancos', null, 1]]

    data.content.forEach(banco => {
        let idBanco = `${banco.nome}(R$${maskMoeda(banco.valorTotal.toFixed(2))})`
        dadosGrafico.push([idBanco, 'Bancos', banco.percentual])
        banco.produtos.forEach(produto => {
            let idProduto = `${produto.nome}(R$${maskMoeda(produto.valorTotal.toFixed(2))})`
            dadosGrafico.push([idProduto, idBanco, produto.percentual])
            produto.lojas.forEach(loja => {
                let idLoja = `${loja.nome}(R$${maskMoeda(loja.valorTotal.toFixed(2))})`
                dadosGrafico.push([idLoja, idProduto, loja.percentual])
                loja.consultores.forEach(consultor => {
                    dadosGrafico.push([`${consultor.nome}(R${maskMoeda(consultor.valorTotal.toFixed(2))})`, idLoja, consultor.percentual])
                })
            })
        })
    })
    setLoading(false);
    return { success: true, data: dadosGrafico }
}

export async function getInstituicoes() {
    const data = await api.get(`Bancos`);

    if (!data.success) {
        return;
    }
    actions.setInsituicoes(data.content);
}

export async function getLojas(pesquisa, page, pageSize, canalId) {
    var url = `CentrosCusto/paginado?pagina=${page}&registros=${pageSize}&nome=${pesquisa.nome}`

    actions.setLoadingLojas(true);
    const data = await api.get(url);
    actions.setLoadingLojas(false);

    if (!data.success) {
        return;
    }

    actions.setLojas(data.content.registros);
    actions.setGetAllLojas({
        quantidade: data.content.quantidade,
        quantidadeTotal: data.content.quantidadeTotal,
        totalPaginas: data.content.totalPaginas
    })
}

export async function getProdutos(bancoId) {
    const data = await api.get(`Produtos/bancos/${bancoId}`);

    if (!data.success) {
        return;
    }
    actions.setProdutosRiber(data.content);
}

export async function getTiposOperacao() {
    const data = await api.get(`tipos-operacao`);

    if (!data.success) {
        return;
    }
    actions.setTiposOperacao(data.content);
}

export async function getCanais() {
    const data = await api.get(`canal`);

    if (!data.success) {
        return;
    }
    actions.setCanais(data.content);
}

export async function getHierarquias(canalId) {
    const data = await api.get(`hierarquias/hierarquia-canal/${canalId}`);

    if (!data.success) {
        return;
    }
    actions.setHierarquias(data.content);
}

export async function getParcerias() {
    const data = await api.get(`TipoParceira`);

    if (!data.success) {
        return;
    }
    actions.setParcerias(data.content);
}

export async function getConvenios() {
    const data = await api.get(`Convenios`);

    if (!data.success) {
        return;
    }
    actions.setConvenios(data.content);
}

export async function getGruposComPessoaJuridica() {
    const data = await api.get(`grupos/GetAllGruposComPessoaJuridica`);

    if (!data.success) {
        return;
    }
    actions.setGrupos(data.content);
}

export async function getConsultores(pesquisa, page, pageSize) {
    var url = `lojas/consultores?pagina=${page}&registros=${pageSize}`

    if (pesquisa.cpf !== '') url = url + `&cpf=${pesquisa.cpf}`
    if (pesquisa.nome !== '') url = url + `&nome=${pesquisa.nome}`
    if (pesquisa.hierarquiaId !== '0') url = url + `&hierarquiaId=${pesquisa.hierarquiaId}`

    actions.setLoadingConsultores(true);
    const data = await api.get(url);
    actions.setLoadingConsultores(false);

    if (!data.success) {
        return;
    }

    actions.setGetAllConsultores({
        quantidade: data.content.quantidade,
        quantidadeTotal: data.content.quantidadeTotal,
        totalPaginas: data.content.totalPaginas
    })
    actions.setConsultores(data.content.registros);
}

export async function getTabelaProducaoPorCanal(filtros) {
    var url = `TabelaProducao/tabela-canal`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'canalId') {
            url = url + `&canalId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'produtosRibercredId') {
            url = url + `&produtoId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }
    })

    const data = await api.get(url);

    if (!data.success) {
        return { success: false };
    }

    data.content.forEach((e) => {
        e.bancos = []
    })

    return { success: true, data: data.content }
}


export async function getTabelaProducaoPorCanalBanco(filtros, elemento, tabela) {
    var url = `TabelaProducao/tabela-canal-banco`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'produtoId') {
            url = url + `&produtoId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }

        if (e.chave === 'instituicaoId') {
            url = url + `&bancoId=${e.id}`
        }
    })

    url = url + `&canalId=${elemento.canalId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) => {
        e.produtos = []
    })


    tabela.forEach((e) => {
        if (e.id === elemento.canalId) {
            e.bancos = data.content
        }
    })
    actions.setTabelaCanal(tabela);
    return true
}

export async function getTabelaProducaoPorCanalBancoProduto(filtros, elemento, tabela) {
    var url = `TabelaProducao/tabela-canal-banco-produto`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'produtoId') {
            url = url + `&produtoId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }
    })

    url = url + `&canalId=${elemento.canalId}`
    url = url + `&bancoId=${elemento.bancoId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    data.content.forEach((e) => {
        e.centrosCusto = []
    })

    tabela.forEach((e) => {
        if (e.id === elemento.canalId) {
            e.bancos.forEach((e) => {
                if (e.id === elemento.bancoId) {
                    e.produtos = data.content
                }
            })
        }
    })
    actions.setTabelaCanal(tabela);
    return true
}

export async function getTabelaProducaoPorCanalProdutoCentroCusto(filtros, elemento, tabela) {
    var url = `TabelaProducao/tabela-canal-banco-produto-centro-custo`

    filtros.forEach((e) => {
        if (e.chave === 'dataInicio' && e.descricao !== '') {
            url = url + `?dataInicio=${e.descricao}`
        } else if (e.chave === 'dataInicio' && e.descricao === '') {
            url = url + `?dataInicio=`
        }

        if (e.chave === 'dataFim' && e.descricao !== '') {
            url = url + `&dataFim=${e.descricao}`
        } else if (e.chave === 'dataFim' && e.descricao === '') {
            url = url + `&dataFim=`
        }

        if (e.chave === 'consultorId') {
            url = url + `&consultorId=${e.id}`
        }

        if (e.chave === 'tipoOperacaoId') {
            url = url + `&tipoOperacaoId=${e.id}`
        }

        if (e.chave === 'tipoData') {
            url = url + `&tipoData=${e.descricao}`
        }

        if (e.chave === 'parceriaId') {
            url = url + `&parceriaId=${e.id}`
        }

        if (e.chave === 'convenioId') {
            url = url + `&convenioBancoId=${e.id}`
        }

        if (e.chave === 'grupoId') {
            url = url + `&grupoId=${e.id}`
        }

        if (e.chave === 'lojaId') {
            url = url + `&lojaId=${e.id}`
        }
    })

    url = url + `&canalId=${elemento.canalId}`
    url = url + `&bancoId=${elemento.bancoId}`
    url = url + `&produtoId=${elemento.produtoId}`

    setLoading(true);
    const data = await api.get(url);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    tabela.forEach((e) => {
        if (e.id === elemento.canalId) {
            e.bancos.forEach((e) => {
                if (e.id === elemento.bancoId) {
                    e.produtos.forEach((e) => {
                        if (e.id === elemento.produtoId) {
                            e.centrosCusto = data.content
                        }
                    })
                }
            })
        }
    })
    actions.setTabelaCanal(tabela);
    return true
}

