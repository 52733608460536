const STORE_ID = "REGRASREPASSE";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    SEARCH: "SEARCH",
    SET_ALL_ITEMS: "SET_ALL_ITEMS",
    SET_TIPOS_COMISSAO: "SET_TIPOS_COMISSAO",
    SET_PRODUTOS_PESQUISA: "SET_PRODUTOS_PESQUISA",
    SET_BANCOS_PESQUISA: "SET_BANCOS_PESQUISA",
    SET_CONVENIOS_PESQUISA: "SET_CONVENIOS_PESQUISA",
    SET_CANAL_VENDAS_PESQUISA: "SET_CANAL_VENDAS_PESQUISA",
    CLEAN_SEARCH: "CLEAN_SEARCH",
    CLEAN_ALL_SEARCH: "CLEAN_ALL_SEARCH",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    CLEAN_SUBREGRAS: "CLEAN_SUBREGRAS",
    SET_PRODUTOS: "SET_PRODUTOS",
    SET_CONVENIO: "SET_CONVENIO",
    SET_TABELA: "SET_TABELA",
    SET_TABELA_COMISSAO: "SET_TABELA_COMISSAO",
    SET_INSTITUICAO: "SET_INSTITUICAO",
    SET_TIPO_OPERACAO: "SET_TIPO_OPERACAO",
    SET_TIPO_PARCERIA: "SET_TIPO_PARCERIA",
    SET_CANAL_VENDAS: "SET_CANAL_VENDAS",
    SET_NIVEL_HIERARQUICO: "SET_NIVEL_HIERARQUICO",
    SET_GRUPO_PERSONAS: "SET_GRUPO_PERSONAS",
    SET_REGRA_REPASSE: "SET_REGRA_REPASSE",
    GET_ALL_ITEMS: "GET_ALL_ITEMS",
    REGRAS_REPASSE: "REGRAS_REPASSE",
    REGRA_REPASSE_COPY: "REGRA_REPASSE_COPY",
    INVALID_REGRA_REPASSE: "INVALID_REGRA_REPASSE",
    CLEAN_INVALID: "CLEAN_INVALID"
}

export { STORE_ID, TYPES }