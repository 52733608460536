import { maskDate } from "../../../../util/masks"

export const COLUMNS_PROPOSTAS = [
    {
        Header: "Número",
        accessor: "numeroProposta"
    },
    {
        Header: "Valor Produção",
        accessor: "valorProposta"
    },
    {
        Header: "Valor Comissão Recebida",
        accessor: "valorComissaoRecebida"
    },
    {
        Header: "% Comissão Recebida",
        accessor: "percentualComissaoRecebida"
    },
    {
        Header: "Valor Comissão Prevista",
        accessor: "valorProvisionamento"
    },
    {
        Header: "% Comissão Prevista",
        accessor: "percentualPrevisto"
    },
    {
        Header: "Data Agendamento",
        accessor: "dataAgendamento",
        Cell: ({ value }) => { return maskDate(value, 'DD/MM/YYYY')}
    },
    {
        Header: "Data Entrada",
        accessor: "dataEntrada",
        Cell: ({ value }) => { return maskDate(value, 'DD/MM/YYYY')}
    },
    {
        Header: "Data Status",
        accessor: "dataStatus",
        Cell: ({ value }) => { return maskDate(value, 'DD/MM/YYYY')}
    },
    {
        Header: "Tabela",
        accessor: "tabela"
    },
    {
        Header: "Qtd. Parcelas",
        accessor: "quantidadeParcela"
    },
]