const STORE_ID = "GRUPO";

const TYPES = {
    GRUPO: "GRUPO",
    GRUPO_COPY: "GRUPO_COPY",
    INVALID_GRUPO: "INVALID_GRUPO",
    GRUPOS: "GRUPOS",
    CLEAN_INVALID_GRUPO: "CLEAN_INVALID_GRUPO",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE"
}

export { STORE_ID, TYPES }