import moment from 'moment';
export const COLUMNS = [
    {
        Header: "Instituição Financeira",
        accessor: "banco.nome"
    },
    {
        Header: "Início Vigência",
        accessor: "dataInicioVigencia",
        Cell: ({ value }) => {
            return moment(value).format("DD/MM/YYYY");
        },
        sortType: "datetime",
    },
    {
        Header: "Fim Vigência",
        accessor: "dataFimVigencia",
        Cell: ({ value }) => {
            return moment(value).format("DD/MM/YYYY");
        },
        sortType: "datetime",
    },
]