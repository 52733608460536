import api from "../util/api";
import * as actions from "../store/modules/contasAPagar/actions";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import { maskCNPJ, maskDate, maskMoeda, removeMaskCNPJ, removeMaskMoeda } from "../util/masks";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get(pesquisa, pageSize, page) {
    var url = `contaPagar?pagina=${page}&registros=${pageSize}`

    if (pesquisa.cnpj !== '') url = url + `&cnpj=${pesquisa.cnpj}`
    if (pesquisa.titulo !== '') url = url + `&titulo=${pesquisa.titulo}`
    if (pesquisa.empresa !== '') url = url + `&empresa=${pesquisa.empresa}`
    if (pesquisa.fornecedor !== '') url = url + `&fornecedor=${pesquisa.fornecedor}`
    if (pesquisa.dataEntrada !== '') url = url + `&dataEntrada=${pesquisa.dataEntrada}`
    if (pesquisa.dataEmissao !== '') url = url + `&dataEmissao=${pesquisa.dataEmissao}`
    if (pesquisa.dataVencimento !== '') url = url + `&dataVencimento=${pesquisa.dataVencimento}`

    setLoading(true)
    const data = await api.get(url);
    setLoading(false)

    if (!data.success) {
        return;
    }

    let body = []
    data.content.registros.forEach((regs) => {
        body.push({
           id: regs.id,
           cnpj: regs.cnpj,
           titulo: regs.titulo,
           empresa: regs.empresa,
           fornecedor: regs.fornecedor,
           dataEntrada:new Date(Date.parse(regs.dataEntrada)),
        })
    })
    actions.setContas(body)
    actions.setAllItens({
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas,
        tamanhoPagina: data.content.tamanhoPagina
    })
}

export async function getById(id) {
     
    setLoading(true)
    const data = await api.get(`contaPagar/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    const dataUpdate = {
        id: id,
        empresaId: data.content.empresa,
        filialId: data.content.pessoaId,
        filial: data.content.filial,
        cnpjFilial: maskCNPJ(data.content.cnpjFilial),
        titulo: data.content.titulo,
        tipoTitulo: data.content.tipoTitulo,
        fornecedorId: '',
        cnpjFornecedor: maskCNPJ(data.content.cnpj),
        fornecedor: data.content.fornecedor,
        favorecidoId: '',
        favorecido: data.content.favorecido,
        valor: maskMoeda(data.content.valor.toFixed(2)),
        formaPagamento:data.content.formaPagamento,
        transacaoValor: data.content.transacao,
        dataEntrada: data.content.dataEntrada.substring(0,10),
        dataEmissao: data.content.dataEmissao.substring(0,10),
        dataVencimento: data.content.dataVencimento.substring(0,10),
        considerarJuros: data.content.consideraJuros === 'N' ? 0 : 1,
        percJuros: data.content.percentualJuros,
        percMulta: data.content.percentualMulta,
        portador: data.content.portador,
        carteira: data.content.carteira,
        observacao: data.content.observacao,
        agencia: data.content.agencia,
        conta: data.content.conta,
        contaFinanceira: data.content.contaFinanceira,
        contaContabil: data.content.contaContabel,
        projeto: data.content.projeto,
        orcamento: data.content.orcamento,
        percRateio: data.content.percentualRateio,
        rateio: data.content.rateio,
        centroCusto: data.content.centroCusto,
        valorRateioCentroCusto: data.content.valorRateioCentroCusto,
        percRateioCentroCusto: data.content.percentualRateio,
    }
    actions.setCopia(dataUpdate);
    actions.item(dataUpdate);
}

export async function getFiliais(pesquisa, page, pageSize) {
    var url = `Pessoa?pagina=${page}&registros=${pageSize}`

    if (pesquisa.cnpj !== '') url = url + `&cpfCnpj=${removeMaskCNPJ(pesquisa.cnpj)}`
    if (pesquisa.nome !== '') url = url + `&nome=${pesquisa.nome}`

    actions.setLoadingFilial(true)
    const data = await api.get(url);
    actions.setLoadingFilial(false)

    if (!data.success) {
        return;
    }
    actions.setFiliais(data.content.registros);
    actions.getFiliais({
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas
    });
}

export async function getFornecedores(pesquisa, page, pageSize) {
    var url = `Pessoa/PessoaFornecedor?pagina=${page}&registros=${pageSize}`

    if (pesquisa.cnpj !== '') url = url + `&cnpj=${pesquisa.cnpj}`
    if (pesquisa.nome !== '') url = url + `&nome=${pesquisa.nome}`

    actions.setLoadingFornecedor(true)
    const data = await api.get(url);
    actions.setLoadingFornecedor(false)

    if (!data.success) {
        return;
    }
    actions.setFornecedores(data.content.registros);
    actions.getFornecedores({
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas
    });
}

export async function getFavorecidos(pesquisa, page, pageSize) {
    var url = `Pessoa/PessoaFornecedor?pagina=${page}&registros=${pageSize}`

    if (pesquisa.cpfCnpj !== '') url = url + `&cpfCnpj=${pesquisa.cpfCnpj}`
    if (pesquisa.nome !== '') url = url + `&nome=${pesquisa.nome}`
    if (pesquisa.filtro !== '') url = url + `&filtro=${pesquisa.filtroId}`

    actions.setLoadingFavorecido(true)
    const data = await api.get(url);
    actions.setLoadingFavorecido(false)

    if (!data.success) {
        return;
    }
    actions.setFavorecidos(data.content.registros);
    actions.getFavorecidos({
                            quantidade: data.content.quantidade, 
                            quantidadeTotal: data.content.quantidadeTotal, 
                            totalPaginas: data.content.totalPaginas
                        });
}

export async function create(registro) {
    if (registro.filialId === "0") {
        notifyWarn("Filial não pode estar em branco");
        return;
    }
    if (registro.favorecido === "") {
        notifyWarn("Favorecido não pode estar em branco");
        return;
    }
    if (registro.titulo === "") {
        notifyWarn("Título não pode estar em branco");
        return;
    }
    if (registro.tipoTitulo === "") {
        notifyWarn("Tipo do Título não pode estar em branco");
        return;
    }
    if (registro.valor === "") {
        notifyWarn("Valor não pode estar em branco");
        return;
    }
    if (registro.formaPagamento === "") {
        notifyWarn("Forma de pagamento não pode estar em branco");
        return;
    }
    if (registro.dataEntrada === "") {
        notifyWarn("Data de entrada não pode estar em branco");
        return;
    }
    if (registro.dataEmissao === "") {
        notifyWarn("Data de emissão não pode estar em branco");
        return;
    }
    if (registro.dataVencimento === "") {
        notifyWarn("Data de vencimento não pode estar em branco");
        return;
    }

    const body = {
        pessoaId: registro.filialId,
        regraRepasseId: null,
        empresa: registro.empresaId,
        filial: registro.filial,
        cnpjFilial:removeMaskCNPJ(registro.cnpjFilial) ,
        titulo: registro.titulo,
        tipoTitulo: registro.tipoTitulo,
        fornecedor: registro.fornecedor,
        cnpj: registro.cnpjFornecedor !== 'xx.xxx.xxx/xxxx-xx' ? removeMaskCNPJ(registro.cnpjFornecedor): '',
        favorecido: registro.favorecido,
        valor: removeMaskMoeda(registro.valor),
        formaPagamento: registro.formaPagamento,
        transacao: registro.transacaoValor,
        dataEmissao: registro.dataEmissao,
        dataEntrada: registro.dataEntrada,
        dataVencimento: registro.dataVencimento,
        consideraJuros: registro.considerarJuros === 0 ? 'N': 'S',
        juros: '',
        percentualJuros: removeMaskMoeda(registro.percJuros),
        percentualMulta: removeMaskMoeda(registro.percMulta),
        percentualDesconto: 0,
        valorDesconto: 0,
        portador: registro.portador,
        carteira: registro.carteira,
        moeda: "R",
        observacao: registro.observacao,
        agencia: registro.agencia,
        conta: registro.conta,
        contaFinanceira: registro.contaFinanceira,
        contaContabil: registro.contaContabil,
        projeto: registro.projeto,
        orcamento: registro.orcamento,
        percentualRateio: registro.percRateio,
        rateio: registro.rateio,
        centroCusto: registro.centroCusto,
        percentualCentroCusto: registro.percRateioCentroCusto,
        valorRateioCentroCusto: registro.valorRateioCentroCusto
    }

    const data = await api.post("contaPagar", body);

    if (!data.success) {
        return;
    }

    notifySuccess("Conta a pagar cadastrada");
    actions.cleanRegister();
    get({
        cnpj: '',
        titulo: '',
        empresa: '',
        fornecedor: '',
        dataEntrada: '',
        dataEmissao: '',
        dataVencimento: '',
    }, 10, 1);
    actions.setActiveTab("Search")
}

export async function update(registro, copia) {
    if (JSON.stringify(registro) === JSON.stringify(copia)) {
        notifyInfo("Nada foi alterado");
        return;
    }
    if (registro.filialId === "0") {
        notifyWarn("Filial não pode estar em branco");
        return;
    }
    if (registro.titulo === "") {
        notifyWarn("Título não pode estar em branco");
        return;
    }
    if (registro.tipoTitulo === "") {
        notifyWarn("Tipo do Título não pode estar em branco");
        return;
    }
    if (registro.valor === "") {
        notifyWarn("Valor não pode estar em branco");
        return;
    }
    if (registro.formaPagamento === "") {
        notifyWarn("Forma de pagamento não pode estar em branco");
        return;
    }
    if (registro.dataEntrada === "") {
        notifyWarn("Data de entrada não pode estar em branco");
        return;
    }
    if (registro.dataEmissao === "") {
        notifyWarn("Data de emissão não pode estar em branco");
        return;
    }
    if (registro.dataVencimento === "") {
        notifyWarn("Data de vencimento não pode estar em branco");
        return;
    }

    const body = {
        pessoaId: registro.filialId,
        regraRepasseId: null,
        empresa: registro.empresaId,
        filial: registro.filial,
        cnpjFilial:removeMaskCNPJ(registro.cnpjFilial) ,
        titulo: registro.titulo,
        tipoTitulo: registro.tipoTitulo,
        fornecedor: registro.fornecedor,
        cnpj: registro.cnpjFornecedor !== 'xx.xxx.xxx/xxxx-xx' ? removeMaskCNPJ(registro.cnpjFornecedor): '',
        favorecido: registro.favorecido,
        valor: removeMaskMoeda(registro.valor),
        formaPagamento: registro.formaPagamento,
        transacao: registro.transacaoValor,
        dataEmissao: registro.dataEmissao,
        dataEntrada: registro.dataEntrada,
        dataVencimento: registro.dataVencimento,
        consideraJuros: registro.considerarJuros === 0 ? 'N': 'S',
        juros: '',
        percentualJuros: removeMaskMoeda(registro.percJuros),
        percentualMulta: removeMaskMoeda(registro.percMulta),
        percentualDesconto: 0,
        valorDesconto: 0,
        portador: registro.portador,
        carteira: registro.carteira,
        moeda: "R",
        observacao: registro.observacao,
        agencia: registro.agencia,
        conta: registro.conta,
        contaFinanceira: registro.contaFinanceira,
        contaContabil: registro.contaContabil,
        projeto: registro.projeto,
        orcamento: registro.orcamento,
        percentualRateio: registro.percRateio,
        rateio: registro.rateio,
        centroCusto: registro.centroCusto,
        percentualCentroCusto: registro.percRateioCentroCusto,
        valorRateioCentroCusto: registro.valorRateioCentroCusto
    }

    const data = await api.put(`contaPagar/${registro.id}`, body);

    if (!data.success) {
        return;
    }
    notifySuccess("Conta a pagar atualizada");
    actions.cleanRegister();
    actions.setActiveTab("Search");
    get({cnpj: '',
    titulo: '',
    empresa: '',
    fornecedor: '',
    dataEntrada: '',
    dataEmissao: '',
    dataVencimento: '',
}, 10, 1);
}

export async function remove(id) {
    const data = await api.delete(`ContaPagar/${id}`);

    if (!data.success) {
        return;
    }

    notifySuccess("Conta a Pagar removida");
    get({
        cnpj: '',
        titulo: '',
        empresa: '',
        fornecedor: '',
        dataEntrada: '',
        dataEmissao: '',
        dataVencimento: '',
    }, 10, 1);
}
