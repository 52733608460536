import React, { useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Col, Row, Label, Input } from 'reactstrap'
import { useSelector } from 'react-redux'
import { Switch } from '../../../components/Inputs/Input'
import Select from '../../../components/Inputs/Select'
import * as actions from '../../../store/modules/comissao/actions'
import { removeEvento } from '../../../controllers/comissaoController'

const CardItem = ({ e, i }) => {

    const { tiposComissaoBanco, registros, seeRegister } = useSelector(state => state.comissao)

    const [tipoComissaoBancoId, setTipoComissaoBancoId] = useState('0')

    useEffect(() => {
        if(e.tiposComissaoBanco){
            setTipoComissaoBancoId(e.tiposComissaoBanco.id)
        }else{
            setTipoComissaoBancoId(e.tiposComissaoBancoId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [e.tiposComissaoBanco])

    async function handleRemoverItem(eventoId) {
        let isSuccessed = false

        if (registros.id) {
          isSuccessed = await removeEvento(eventoId)
        } else {
          isSuccessed = true
        }
      
      if (isSuccessed) {
        const array = registros.tabelaComissaoEventos
        array.splice(i, 1)
        registros.tabelaComissaoEventos = array
        actions.setRegistros(registros)
      }  
    }

    return (
        <div className='p-3 mt-2' style={{ width: '100%', borderRadius: '5px', border: '1px solid #CCCFDE' }}>
            <Row style={{ marginRight: '-200px' }}>
                <Col md={4}>
                    <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Tipo de comissão</Label>
                    <Select
                        value={tipoComissaoBancoId}
                        label='descricao'
                        options={tiposComissaoBanco}
                        disabled
                    />
                </Col>
                <Col md={1}>
                    <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Meses de</Label>
                    <Input
                        type='text'
                        value={e.mesesDe}
                        disabled
                    />
                </Col>
                <Col md={1}>
                    <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Meses até</Label>
                    <Input
                        type='text'
                        value={e.mesesAte}
                        disabled
                    />
                </Col>
                <Col md={1.1}>
                    <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>{e.unidade === 'UNIDADE' ? 'Unidade' : 'Percentual'}</Label>
                    <Input
                        type='text'
                        value={e.valor}
                        disabled
                        style={{ width: '110px' }}
                        maxLength={e.unidade === 'UNIDADE' ? '12' : '6'}
                    />
                </Col>
                <Col md={2}>
                    <Label style={{ fontWeight: '700', color: 'var(--color-preto)', paddingTop: '20px' }}>Impede Repasse</Label>
                    <Switch
                        className='switch mt-4'
                        checked={e.impedeRepasse}
                    />
                </Col>

                {
                    !seeRegister ?
                        <>
                            <AiOutlineCloseCircle
                                style={{
                                    fontSize: '20px',
                                    color: 'var(--color-laranja)',
                                    marginTop: '20px',
                                    marginRight: '5px',
                                    marginLeft: '-10px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleRemoverItem(e.id)}
                            />
                            < Label onClick={() => handleRemoverItem(e.id)} style={{ fontWeight: '700', color: 'var(--color-preto)', paddingTop: '20px', cursor: 'pointer' }}>Remover</Label>
                        </>
                        :
                        ''
                }
            </Row>
        </div >
    )
}

export default CardItem