import api from "../util/api";
import * as actions from "../store/modules/propostas/actions";
import { maskCPF, maskDate, maskMoeda } from "../util/masks";
import { setLoading } from "../store/modules/globalLoading/actions";
import { notifyError, notifyWarn } from "../components/message/message";


export async function get(pesquisa, pageSize, page) {
    var url = `proposta?pagina=${page}&registros=${pageSize}`
    if (pesquisa.numeroProposta !== '') {
        url = url + `&numProposta=${pesquisa.numeroProposta}`
    }
    if (pesquisa.codInstituicao !== '0') {
        url = url + `&instituicaoId=${pesquisa.codInstituicao}`
    }
    if (pesquisa.codProduto !== '0') {
        url = url + `&produtoId=${pesquisa.codProduto}`
    }
    if (pesquisa.codConvenio !== '0') {
        url = url + `&convenioId=${pesquisa.codConvenio}`
    }
    if (pesquisa.nomeCliente !== '') {
        url = url + `&nomeCliente=${pesquisa.nomeCliente}`
    }
    if (pesquisa.cpf !== '') {
        url = url + `&cpf=${pesquisa.cpf}`
    }
   
    if (pesquisa.statusPropostaId !== '0') {
        url = url + `&statusPropostaId=${pesquisa.statusPropostaId}`
    }
    if (pesquisa.tipoOperacaoId !== '0') {
        url = url + `&tipoOperacaoId=${pesquisa.tipoOperacaoId}`
    }
    
    if (pesquisa.tipoData !== null && pesquisa.dataEntrada !== '' && pesquisa.dataStatus !== '') {

    url = url + `&dataInicio=${pesquisa.dataEntrada}&dataFim=${pesquisa.dataStatus}&tipoData=${pesquisa.tipoData}`

    }
    

  

    setLoading(true)
    const data = await api.get(url);
    setLoading(false)
    if (!data.success) {
        return;
    }
    var body = []


    data.content.registros.forEach((e) => {
        let cpfFormated
        cpfFormated = e.cpf.indexOf("x") !== -1 ? e.cpf : maskCPF(e.cpf);

        body.push({
            id: e.id,
            instituicao: e.banco,
            proposta: e.numeroProposta,
            convenio: e.convenio,
            cpfCliente: cpfFormated,
            nomeCliente: e.cliente,
            dataStatus: new Date(Date.parse(e.dataStatus)),
            dataEntrada: new Date(Date.parse(e.dataEntrada)),
            produto: e.produto,
            tipoOperacao: e.tipoOperacao,
            status: e.status,
            valorFinanciado: `R$ ${maskMoeda(Number(e.valorFinanciado).toFixed(2))}`,


        })
    })

    actions.setPropostas(body)
    actions.getPropostas({
        quantidade: data.content.quantidade,
        quantidadeTotal: data.content.quantidadeTotal,
        totalPaginas: data.content.totalPaginas,
        tamanhoPagina: data.content.tamanhoPagina
    });
}


export async function getInstituicoes() {
    const data = await api.get("bancos");

    if (!data.success) {
        return;
    }
    actions.setInstituicoes(data.content)
}

export async function getProdutos() {
    const data = await api.get("produtos");

    if (!data.success) {
        return;
    }
    actions.setProdutos(data.content)
}

export async function getConvenios() {
    const data = await api.get("convenios");

    if (!data.success) {
        return;
    }
    actions.setConvenios(data.content)
}
export async function getTipoOperacao() {
    const data = await api.get("tipos-operacao");

    if (!data.success) {
        return;
    }
    actions.setTipoOperacao(data.content)
}
export async function getStatus() {
    const data = await api.get("status-propostas");

    if (!data.success) {
        return;
    }
    actions.setStatus(data.content)
}


export async function getDataStatus(dataInicio, dataFim) {
    const data = await api.get(`Proposta/dataFim/${dataInicio}/${dataFim}`);
    if (!data.success) {
        return;
    }
    actions.setDataStatus(data.content)
}
export async function getDataEntrada(datainicio, datafim) {
    const data = await api.get(`Proposta/dataInicio/${datainicio}/${datafim}`);
    if (!data.success) {
        return;
    }
    actions.setDataEntrega(data.content)
}
export async function getById(id) {
    setLoading(true)
    const data = await api.get(`Proposta/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    var arrProdutos = [];
    let comissoes = []
    let contasReceber
    let contasPagar
    data.content.itens.forEach((e) => {
        e.comissoes.forEach(comissao => {
            comissao.valorBruto = `${comissao.valorBruto < 0 ? '-' : ''}${maskMoeda(Number(comissao.valorBruto).toFixed(2))}`;
            comissao.valorBase = `${comissao.valorBase < 0 ? '-' : ''}${maskMoeda(Number(comissao.valorBase).toFixed(2))}`;
            comissao.dataAgendamento = maskDate(comissao.dataAgendamento, 'DD/MM/YYYY');
            comissao.dataOperacao = maskDate(comissao.dataOperacao, 'DD/MM/YYYY');

            comissoes.push(comissao)
            comissao.comissaoParcelas?.forEach(comissaoItem => {
                comissoes.push({
                    tipoComissao: 'Parcela Kgiro',
                    valorBruto: `${comissaoItem.valor < 0 ? '-' : ''}${maskMoeda(Number(comissaoItem.valor).toFixed(2))}`,
                    valorBase: `${comissao.valorBase < 0 ? '-' : ''}${maskMoeda(Number(comissao.valorBase).toFixed(2))}`,
                    loja: comissao.loja,
                    parcelas: comissaoItem.numero,
                    arquivoImportado: comissao.arquivoImportado,
                    dataAgendamento: maskDate(comissaoItem.dataVencimento, 'DD/MM/YYYY')
                })
            })
        })
        e.contasReceber?.forEach(comissao => {
            comissao.valor = maskMoeda(Number(comissao.valor).toFixed(2));
            comissao.dataVencimento = maskDate(comissao.dataVencimento, 'DD/MM/YYYY');
            comissao.contasReceberDivisao.forEach((divisao) => {
                divisao.valor = maskMoeda(Number(divisao.valor).toFixed(2));
                divisao.master = divisao.master ? 'Sim' : 'Não';
            })
        })
        e.contaPagar?.forEach(comissao => {
            comissao.valorPago = maskMoeda(Number(comissao.valorPago).toFixed(2));
            comissao.dataVencimento = maskDate(comissao.dataVencimento, 'DD/MM/YYYY');
        })
        contasReceber = e.contasReceber == null ? [] : e.contasReceber
        contasPagar = e.contaPagar == null ? [] : e.contaPagar
        arrProdutos.push({
            id: e.id,
            produtoBanco: e.produto,
            tipoOperacao: e.tipoOperacao,
            tabela: e.tabela,
            codtabela: e.codigoTabela,
            status: e.status,
            dataStatusProposta: maskDate(e.dataStatusProposta, 'YYYY-MM-DD'),
            dataEntradaProposta: maskDate(e.dataEntradaProposta, 'YYYY-MM-DD'),
            qtdParcelas: e.quantidadeParcelas,
            valorParcela: maskMoeda(e.valorParcelas.toFixed(2)),
            baseCalculo: maskMoeda(e.valorFinanciado.toFixed(2)),
            tipoFormalizacao: e.tipoFormalizacao,
        })
    })

    let cpfFormated
    cpfFormated = data.content.cpfCliente.indexOf("x") !== -1 ? data.content.cpfCliente : maskCPF(data.content.cpfCliente);


    const dataUpdate = {
        id: data.content.id,
        instituicao: data.content.banco,
        proposta: data.content.numeroProposta,
        nomeCliente: data.content.nomeCliente,
        cpfCliente: cpfFormated,
        loja: data.content.loja,
        convenio: data.content.convenio,
        banco: data.content.banco,
        tipoOperacao: data.content.tipoOperacao,
        status: data.content.status,
        cpfDigitador: data.content.cpfDigitador,
        nomeDigitador: data.content.nomeDigitador,
        codDigitador: data.content.codigoDigitador,
        produtos: arrProdutos,
        comissoes,
        contasReceber,
        contasPagar
    }
    actions.setItem(dataUpdate);
}
