import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import { Card, Col, Form, Button, TabContent, TabPane } from "reactstrap";

import Breadcrumb from "../../components/breadcrumbs";
import Search from "./tabs/search";
import Register from "./tabs/register";

import * as actions from "../../store/modules/parametrosAutomacao/actions";
import * as controller from "../../controllers/parametrosAutomacaoController";

export default function ParametrosAutomacao(){

    const { item, activeTab, seeRegister } = useSelector(state => state.parametrosAutomacao);

    useEffect(() => {
        actions.setActiveTab("Search")
    }, [])

    async function saveItem() {
        if(item.id !== "0"){
            await controller.update(item)
        }
    }

    function changeTab(tab) {
        actions.setActiveTab(tab);
        actions.cleanRegister();
    }

    function seeRegisterF() {
        if (item.id !== "") {
            actions.cleanRegister();
        }
        actions.setSeeRegister(false);
    }

    return (
        <>
                <Col>
                    <Breadcrumb activeTab={activeTab} />
                    <Card>
                        <Form>
                            {
                                activeTab !== "Search"
                                    &&
                                    <Col className="pt-2 pb-2">
                                        <Button
                                            onClick={() => { changeTab("Search"); seeRegisterF() }}
                                            style={{ width: "127px", color: "white" }}
                                            className="purpleButton"
                                        >
                                            <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar
                                        </Button>
                                        {!seeRegister && (
                                            <Button
                                                onClick={() => saveItem()}
                                                style={{ width: "242px", float: 'right' }}
                                                className="purpleButton"
                                            >
                                                Salvar parâmetro
                                            </Button>
                                        )}
                                    </Col>
                            }
                        </Form>
                    </Card>
                </Col>

                 <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <br />
                        <Search />
                    </TabPane>
                    <TabPane tabId="Register">
                        <br />
                        <Register />
                    </TabPane>
                </TabContent>

        </>
    );
}