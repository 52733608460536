import moment from "moment"

export const COLUMNS = [
    {
        Header: "Propostas",
        accessor: "numeroProposta",
    },
    {
        Header: "Data de digitação",
        accessor: "dataEntradaProposta",
        Cell: ({ value }) => { return moment(value).local().format("DD/MM/YYYY") }
    },
]


