import { Button, Col, Collapse, FormGroup, Input, Label, Row } from "reactstrap"
import { Select } from "../../../components/Inputs/Input"
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";

import * as actions from '../../../store/modules/dashboard/previsao/actions'
import * as controller from '../../../controllers/dashboardController/previsaoController'
import { useSelector } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import { BigNumber } from "./Components/BigNumber";
import { useEffect, useState } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import ModalLoja from "./Components/ModalLojaPrevisao";
import { notifyWarn } from "../../../components/message/message";
import TabelaCentroCusto from "./Components/TabelaCentroCusto";
import TabelaBanco from "./Components/TabelaBanco";
import TabelaProduto from "./Components/TabelaProduto";
import TabelaCanal from "./Components/TabelaCanal";

export default function Previsao(){
    const { filtros,canais, centrosCusto, bancos, tiposComissao, produtos, convenios, grupos, bigNumbers:{ comissaoRecebida, provisionamento},tabelaBanco, tabelaCanal, tabelaCentroCusto, tabelaProduto } = useSelector(state => state.dashboardPrevisao);

    async function onChangeFiltros(value, campo){
        actions.setFiltros(campo, value)
    }

    function handleSearch(){
        controller.get(filtros)
    }

    const toggleLoja = () => setIsModalPessoaOpen(!isModalPessoaOpen)

    useEffect(()=>{
        controller.getCanais();
        controller.getCentrosCusto();
        controller.getBancos();
        controller.getTiposcomissao();
        controller.getProdutos();
        controller.getConvenios();
        controller.getGrupos();
    },[])

    const [isModalPessoaOpen, setIsModalPessoaOpen] = useState(false)


    const [isBigNumberOpen, setIsBigNumberOpen] = useState(false)
    const [isTableBancoOpen, setIsTableBancoOpen] = useState(false)
    const [isTableCanalOpen, setIsTableCanalOpen] = useState(false)
    const [isTableCentroCustoOpen, setIsTableCentroCustoOpen] = useState(false)
    const [isTableProdutoOpen, setIsTableProdutoOpen] = useState(false)
    return(
       <>
       <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '0 20px 0 20px', padding: '20px'}}>
                <p style={{fontSize: '16px', fontWeight: '700'}}>Filtros</p>
                <div>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label>Canal</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.canalId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={canais}
                                    onChange={(e) => {onChangeFiltros(e.value, 'canalId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Loja</Label>
                                <Input
                                 type="text"
                                 placeholder="Busque um favorecido..."
                                 value={filtros.loja}
                                 disabled
                                />
                            </FormGroup>
                        </Col>
                        <AiOutlineSearch
                            style={{color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer'}}
                            disabled={filtros.canalId === '0'}
                            onClick={()=> filtros.canalId === '0' ? notifyWarn('Escolha o Canal para pesquisar lojas'): toggleLoja()}
                        />
                        <Col md={4}>
                            <FormGroup>
                                <Label>Centro de Custo</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.centroCustoId }}
                                    select={{ value: "id", label: "descricao" }}
                                    options={centrosCusto}
                                    onChange={(e) => {onChangeFiltros(e.value, 'centroCustoId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormControl>
                                <FormLabel id="tipoData" style={{color: 'black'}}>Tipo data</FormLabel>
                                <RadioGroup
                                    aria-labelledby="tipoData"
                                    defaultValue="1"
                                    name="radio-buttons-group"
                                    value={filtros.tipoData}
                                    onChange={(e) => {onChangeFiltros(e.target.value, 'tipoData')}}
                                >
                                    <FormControlLabel value="1" control={<Radio color="primary" />} label="Data Status" />
                                    <FormControlLabel value="0" control={<Radio color="primary"/>} label="Data Agendamento" />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                        <Col md={2}>
                            <Label>Data Início</Label>
                            <Input
                                type='date'
                                value={filtros.dataInicio}
                                max="9999-12-31"
                                onChange={(e) => onChangeFiltros(e.target.value, 'dataInicio')}
                            />
                        </Col>
                        <Col md={2}>
                            <Label>Data Fim</Label>
                            <Input
                                type='date'
                                value={filtros.dataFim}
                                max="9999-12-31"
                                onChange={(e) => onChangeFiltros(e.target.value, 'dataFim')}
                            />
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Instituição</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.bancoId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={bancos}
                                    onChange={(e) => {onChangeFiltros(e.value, 'bancoId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Tipo Comissão</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.tipoComissaoId }}
                                    select={{ value: "id", label: "descricao" }}
                                    options={tiposComissao}
                                    onChange={(e) => {onChangeFiltros(e.value, 'tipoComissaoId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Produto Riber</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.produtoId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={produtos}
                                    onChange={(e) => {onChangeFiltros(e.value, 'produtoId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Convênio Riber</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.convenioId }}
                                    select={{ value: "id", label: "descricao" }}
                                    options={convenios}
                                    onChange={(e) => {onChangeFiltros(e.value, 'convenioId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>Grupo</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.grupoId }}
                                    select={{ value: "id", label: "descricao" }}
                                    options={grupos}
                                    onChange={(e) => {onChangeFiltros(e.value, 'grupoId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div style={{borderTop: '1px solid #C4C7CA', paddingTop: '20px'}}>
                    <Row>
                        <Col>
                        <Button
                                className="purpleButton"
                                style={{ width: "150px", float: 'right' }}
                                onClick={() => handleSearch()}>
                                Aplicar
                            </Button>
                            <Button className="mr-4"
                                style={{
                                    width: "150px",
                                    background: "var(--color-branco)",
                                    borderColor: "var(--color-laranja)",
                                    color: "var(--color-laranja)",
                                    borderRadius: "7px",
                                    float: 'right'
                                }}
                                onClick={() => actions.cleanFiltros()}>
                                Limpar Filtros
                                </Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px'}}>
                <p style={{fontSize: '16px', fontWeight: '700', cursor: 'pointer'}} onClick={() => setIsBigNumberOpen(!isBigNumberOpen)} >Big Numbers {isBigNumberOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine/>} </p>
                <Collapse isOpen={isBigNumberOpen} >
                    <div style={{display: 'flex', justifyContent: 'center'}}>    
                        <BigNumber element={comissaoRecebida} type='comissaoRecebida'/> 
                        <BigNumber element={provisionamento} type='provisionamento'/>
                    </div>
                </Collapse>
            </div>

            <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px'}}>
                <p style={{fontSize: '16px', fontWeight: '700', cursor: 'pointer'}} onClick={() => setIsTableCentroCustoOpen(!isTableCentroCustoOpen)} >Centro de Custo {isTableCentroCustoOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine/>} </p>
                <Collapse isOpen={isTableCentroCustoOpen} >
                    <div style={{width: '100%', overflowX: `auto`}}>  
                        <TabelaCentroCusto data={tabelaCentroCusto}/>
                    </div>
                </Collapse>
            </div>

            <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px'}}>
                <p style={{fontSize: '16px', fontWeight: '700', cursor: 'pointer'}} onClick={() => setIsTableBancoOpen(!isTableBancoOpen)} >Instituição {isTableBancoOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine/>} </p>
                <Collapse isOpen={isTableBancoOpen} >
                    <div style={{width: '100%', overflowX: `auto`}}>     
                        <TabelaBanco data={tabelaBanco}/>
                    </div>
                </Collapse>
            </div>

            <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px'}}>
                <p style={{fontSize: '16px', fontWeight: '700', cursor: 'pointer'}} onClick={() => setIsTableProdutoOpen(!isTableProdutoOpen)} >Produto Riber {isTableProdutoOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine/>} </p>
                <Collapse isOpen={isTableProdutoOpen} >
                <div style={{width: '100%', overflowX: `auto`}}>    
                        <TabelaProduto data={tabelaProduto}/>
                    </div>
                </Collapse>
            </div>

            <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px'}}>
                <p style={{fontSize: '16px', fontWeight: '700', cursor: 'pointer'}} onClick={() => setIsTableCanalOpen(!isTableCanalOpen)} >Canal {isTableCanalOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine/>} </p>
                <Collapse isOpen={isTableCanalOpen} >
                    <div style={{width: '100%', overflowX: `auto`}}>      
                        <TabelaCanal data={tabelaCanal}/>
                    </div>
                </Collapse>
            </div>

            {
                <ModalLoja
                    canalId={filtros.canalId}
                    toggle={toggleLoja}
                    isOpen={isModalPessoaOpen}
                />
            }
       </>
    )
}