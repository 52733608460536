/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle, AiOutlineSearch } from "react-icons/ai";
import { useSelector } from "react-redux";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Collapse,
} from "reactstrap";

import { Select, Switch } from "../../../components/Inputs/Input";
import Table from "./Components/Table.jsx";
import TableProduto from "./Components/TabelaProduto";
import TableCanal from "./Components/TabelaCanal";
import FiltroSelecionado from "./Components/FiltroSelecionado";
import BigNumber from "./Components/BigNumbers";
import ModalLoja from "./Components/ModaLoja";
import ModalConsultores from "./Components/ModalConsultores";
import CustomDatePicker from "./Components/Calendar";
import * as actions from "../../../store/modules/dashboard/producao/actions";
import * as controller from "../../../controllers/dashboardController/producaoController";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { GrFormClose } from "react-icons/gr";
import moment from "moment";

export default function Producao() {
  const { permitionsTabs } = useSelector((state) => state.dashboard);
  const {
    filtros,
    filtrosSelecionados,
    filtrosAplicados,
    canais,
    tabelaProducao,
    tabelaProdutos,
    tabelaCanais,
    bigNumbers,
    instituicoes,
    produtosRibercred,
    tiposOperacao,
    parcerias,
    convenios,
    grupos,
  } = useSelector((state) => state.dashboardProducao);

  const [modalLoja, setModalLoja] = useState(false);
  const [modalConsultor, setModalConsultor] = useState(false);
  const [isTabelaOpen, setisTabelaOpen] = useState(false);
  const [isGraficoOpen, setIsGraficoOpen] = useState(false);
  const [isGraficoCanalOpen, setIsGraficoCanalOpen] = useState(false);

  const [data, setData] = useState("");
  const [hora, setHora] = useState("");
  const toggleLoja = () => setModalLoja(!modalLoja);
  const toggleConsultor = () => setModalConsultor(!modalConsultor);

  async function onChangeFiltros(value, campo) {
    actions.setFiltros(campo, value);
    return;
  }

  async function handleAplicarFiltros(filtros) {
    let filtrosSelected = [];

    filtrosSelected.push({
      chave: "dataInicio",
      descricao: filtros.dataInicio,
    });
    filtrosSelected.push({ chave: "dataFim", descricao: filtros.dataFim });
    filtrosSelected.push({ chave: "tipoData", descricao: filtros.tipoData });

    if (filtros.instituicaoId !== "0" && filtros.instituicao !== "") {
      filtrosSelected.push({
        id: filtros.instituicaoId,
        chave: "instituicaoId",
        descricao: filtros.instituicao,
      });
    }
    if (filtros.lojaId !== "0" && filtros.loja !== "") {
      filtrosSelected.push({
        id: filtros.lojaId,
        chave: "lojaId",
        descricao: filtros.loja,
      });
    }
    if (filtros.canalId !== "0" && filtros.canal !== "") {
      filtrosSelected.push({
        id: filtros.canalId,
        chave: "canalId",
        descricao: filtros.canal,
      });
    }
    if (filtros.produtoRibercredId !== "0" && filtros.produtoRibercred !== "") {
      filtrosSelected.push({
        id: filtros.produtoRibercredId,
        chave: "produtosRibercredId",
        descricao: filtros.produtoRibercred,
      });
    }
    if (filtros.tipoOperacaoId !== "0" && filtros.tipoOperacao !== "") {
      filtrosSelected.push({
        id: filtros.tipoOperacaoId,
        chave: "tipoOperacaoId",
        descricao: filtros.tipoOperacao,
      });
    }
    if (filtros.parceriaId !== "0" && filtros.parceria !== "") {
      filtrosSelected.push({
        id: filtros.parceriaId,
        chave: "parceriaId",
        descricao: filtros.parceria,
      });
    }
    if (filtros.convenioId !== "0" && filtros.convenio !== "") {
      filtrosSelected.push({
        id: filtros.convenioId,
        chave: "convenioId",
        descricao: filtros.convenio,
      });
    }
    if (filtros.grupoId !== "0" && filtros.grupo !== "") {
      filtrosSelected.push({
        id: filtros.grupoId,
        chave: "grupoId",
        descricao: filtros.grupo,
      });
    }
    if (filtros.consultorId !== "0" && filtros.consultor !== "") {
      filtrosSelected.push({
        id: filtros.consultorId,
        chave: "consultorId",
        descricao: filtros.consultor,
      });
    }

    const isSuccessed = await controller.get(filtrosSelected);

    if (isSuccessed) {
      actions.setFiltrosSelecionados(filtrosSelected);
      actions.setFiltrosAplicados(filtros);
      actions.cleanFiltros();
      setData(moment().format("DD/MM/YYYY"));
      setHora(moment().format("hh:mm"));
    } else {
      actions.setFiltrosSelecionados([]);
      actions.setFiltrosAplicados([]);
      actions.cleanFiltros();
      actions.cleanRegister();
    }
  }

  useEffect(() => {
    if (filtrosSelecionados.length > 0) {
      handleAplicarFiltros(filtrosAplicados);
    } else {
      firstSearch();
    }
  }, []);

  async function firstSearch() {
    const isSuccessed = await controller.get([
      {
        chave: "dataInicio",
        descricao: `${moment().format("YYYY-MM")}-01`,
      },
      {
        chave: "dataFim",
        descricao: moment().format("YYYY-MM-DD"),
      },
      {
        chave: "tipoData",
        descricao: "1",
      },
    ]);

    if (isSuccessed) {
      actions.setFiltrosSelecionados([
        {
          chave: "dataInicio",
          descricao: `${moment().format("YYYY-MM")}-01`,
        },
        {
          chave: "dataFim",
          descricao: moment().format("YYYY-MM-DD"),
        },
        {
          chave: "tipoData",
          descricao: "1",
        },
      ]);
      actions.cleanFiltros();
    }
  }

  useEffect(() => {
    controller.getConvenios();
    controller.getGruposComPessoaJuridica();
    controller.getInstituicoes();
    controller.getParcerias();
    controller.getTiposOperacao();
    controller.getCanais();
  }, []);

  useEffect(() => {
    if (filtros.instituicaoId !== "0") {
      controller.getProdutos(filtros.instituicaoId);
    } else {
      onChangeFiltros("0", "produtoRibercredId");
      onChangeFiltros("", "produtoRibercred");
      actions.setProdutosRiber([]);
    }
  }, [filtros.instituicaoId]);
  return (
    <>
      <div
        style={{
          border: "1px solid #C4C7CA",
          borderRadius: "8px",
          margin: "0 20px 0 20px",
          padding: "20px",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: "700" }}>Filtros</p>
        <div>
          <Row>
            <Col md={2} style={{ display: "flex", flexDirection: "column" }}>
              <FormGroup
                check
                className="ml-2"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Label style={{ fontWeight: "700" }}>Data Status</Label>
                <Input
                  type="radio"
                  name="radio2"
                  style={{ marginLeft: "5%", marginTop: "2rem" }}
                  checked={filtros.tipoData === "1"}
                  onClick={() => onChangeFiltros("1", "tipoData")}
                />
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup className="mr-2">
                <Label style={{ fontWeight: "700" }}>Data Entrada</Label>
                <Input
                  type="radio"
                  name="radio2"
                  style={{ marginLeft: "5%" }}
                  checked={filtros.tipoData === "0"}
                  onClick={() => onChangeFiltros("0", "tipoData")}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <Label style={{ fontWeight: "700" }}>Selecione um período</Label>
              <CustomDatePicker onChangeFiltros={onChangeFiltros} />
            </Col>
            {/* <Col md={2}>
              <Label style={{ fontWeight: "700" }}>Data Início</Label>
              <Input
                type="date"
                value={filtros.dataInicio}
                max="9999-12-31"
                onChange={(e) => onChangeFiltros(e.target.value, "dataInicio")}
              />
            </Col>
            <Col md={2}>
              <Label style={{ fontWeight: "700" }}>Data Fim</Label>
              <Input
                type="date"
                value={filtros.dataFim}
                max="9999-12-31"
                onChange={(e) => onChangeFiltros(e.target.value, "dataFim")}
              />
            </Col> */}
            <Col md={2}>
              <FormGroup>
                <Label style={{ fontWeight: "700" }}>
                  Instituição Financeira
                </Label>
                <Select
                  first="Selecione"
                  value={{ value: filtros.instituicaoId }}
                  select={{ value: "id", label: "nome" }}
                  options={instituicoes}
                  onChange={(e) => {
                    onChangeFiltros(e.value, "instituicaoId");
                    onChangeFiltros(e.label, "instituicao");
                  }}
                  isSearchable={true}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label style={{ fontWeight: "700" }}>Canal</Label>
                <Select
                  first="Selecione"
                  value={{ value: filtros.canalId }}
                  select={{ value: "id", label: "nome" }}
                  options={canais}
                  onChange={(e) => {
                    onChangeFiltros(e.value, "canalId");
                    onChangeFiltros(e.label, "canal");
                  }}
                  isSearchable={true}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label style={{ fontWeight: "700" }}>Centro de custo</Label>
                <Input
                  type="text"
                  placeholder="Busque um favorecido..."
                  value={filtros.loja}
                  disabled
                />
              </FormGroup>
            </Col>
            <GrFormClose
              style={{ marginTop: "30px", fontSize: "20px", cursor: "pointer" }}
              onClick={() => {
                onChangeFiltros("", "loja");
                onChangeFiltros("0", "lojaId");
              }}
            />
            <AiOutlineSearch
              style={{
                color: "var(--color-verde)",
                marginTop: "30px",
                fontSize: "20px",
                cursor: "pointer",
              }}
              onClick={() => toggleLoja()}
            />
            <Col md={3}>
              <FormGroup>
                <Label style={{ fontWeight: "700" }}>Produto Ribercred</Label>
                <Select
                  first="Selecione"
                  value={{ value: filtros.produtoRibercredId }}
                  select={{ value: "id", label: "nome" }}
                  options={produtosRibercred}
                  onChange={(e) => {
                    onChangeFiltros(e.value, "produtoRibercredId");
                    onChangeFiltros(e.label, "produtoRibercred");
                  }}
                  isSearchable={true}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
            {filtros.filtrosAvancados && (
              <>
                <Col md={3}>
                  <FormGroup>
                    <Label style={{ fontWeight: "700" }}>
                      Tipo de Operação
                    </Label>
                    <Select
                      first="Selecione"
                      value={{ value: filtros.tipoOperacaoId }}
                      select={{ value: "id", label: "descricao" }}
                      options={tiposOperacao}
                      onChange={(e) => {
                        onChangeFiltros(e.value, "tipoOperacaoId");
                        onChangeFiltros(e.label, "tipoOperacao");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label style={{ fontWeight: "700" }}>Parceria</Label>
                    <Select
                      first="Selecione"
                      value={{ value: filtros.parceriaId }}
                      select={{ value: "id", label: "nome" }}
                      options={parcerias}
                      onChange={(e) => {
                        onChangeFiltros(e.value, "parceriaId");
                        onChangeFiltros(e.label, "parceria");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label style={{ fontWeight: "700" }}>Cônvenio</Label>
                    <Select
                      first="Selecione"
                      value={{ value: filtros.convenioId }}
                      select={{ value: "id", label: "descricao" }}
                      options={convenios}
                      onChange={(e) => {
                        onChangeFiltros(e.value, "convenioId");
                        onChangeFiltros(e.label, "convenio");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label style={{ fontWeight: "700" }}>Grupo</Label>
                    <Select
                      first="Selecione"
                      value={{ value: filtros.grupoId }}
                      select={{ value: "id", label: "descricao" }}
                      options={grupos}
                      disabled={filtros.lojaId !== "0" ? true : false}
                      onChange={(e) => {
                        onChangeFiltros(e.value, "grupoId");
                        onChangeFiltros(e.label, "grupo");
                      }}
                      isSearchable={true}
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label style={{ fontWeight: "700" }}>Hierarquia</Label>
                    <Input
                      type="text"
                      placeholder="Busque um consultor..."
                      value={filtros.consultor}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <GrFormClose
                  style={{
                    color: "#C4C7CA",
                    marginTop: "30px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onChangeFiltros("", "consultor");
                    onChangeFiltros("0", "consultorId");
                  }}
                />
                <AiOutlineSearch
                  style={{
                    color: "var(--color-verde)",
                    marginTop: "30px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleConsultor()}
                />
              </>
            )}
          </Row>
        </div>
        <div style={{ borderTop: "1px solid #C4C7CA", paddingTop: "20px" }}>
          <Row>
            <Col md={2}>
              <Label style={{ fontWeight: "700" }}>
                Exibir filtros avançados
              </Label>
              <div style={{ marginTop: "-20px", marginLeft: "-10px" }}>
                <Switch
                  className="switch mt-4"
                  checked={filtros.filtrosAvancados}
                  onClick={() =>
                    onChangeFiltros(
                      !filtros.filtrosAvancados,
                      "filtrosAvancados"
                    )
                  }
                />
              </div>
            </Col>
            <Col>
              <Button
                className="purpleButton"
                style={{ width: "150px", float: "right" }}
                onClick={() => handleAplicarFiltros(filtros)}
              >
                Aplicar
              </Button>
              <Button
                className="mr-4"
                style={{
                  width: "150px",
                  background: "var(--color-branco)",
                  borderColor: "var(--color-laranja)",
                  color: "var(--color-laranja)",
                  borderRadius: "7px",
                  float: "right",
                }}
                onClick={() => actions.cleanFiltros()}
              >
                Limpar Filtros
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      <div
        style={{
          border: "1px solid #C4C7CA",
          borderRadius: "8px",
          margin: "20px 20px 0 20px",
          padding: "20px",
          maxWidth: "90vw",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: "700" }}>
          Seleção de filtros
        </p>
        <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
          {filtrosSelecionados.map((e, i) => {
            return <FiltroSelecionado element={e} index={i} />;
          })}
          {!!filtrosSelecionados.length && (
            <button
              style={{
                border: "1px solid var(--color-laranja)",
                borderRadius: "7px",
                width: "154px",
                height: "25px ",
                display: "flex",
                marginRight: "1%",
                background: "transparent",
              }}
              onClick={() => {
                actions.setFiltrosSelecionados([]);
                actions.cleanFiltrosAplicados();
                actions.cleanRegister();
              }}
            >
              <p
                style={{
                  fontSize: "12px",
                  color: "var(--color-laranja)",
                  marginTop: "4px",
                  marginLeft: "2px",
                  width: "130px",
                }}
              >
                Limpar Seleção
              </p>
              <AiOutlineCloseCircle
                style={{
                  marginTop: "4px",
                  color: "var(--color-laranja)",
                  cursor: "pointer",
                }}
              />
            </button>
          )}
        </div>
      </div>
      {data && (
        <Row>
          <Col md={8} />
          <Col md={4} style={{ marginTop: "15px", marginLeft: "auto" }}>
            <span style={{ color: "#C4C7CA" }}>
              {`Última importação: ${data} às ${hora}`}
            </span>
          </Col>
        </Row>
      )}
      <div
        style={{
          border: "1px solid #C4C7CA",
          borderRadius: "8px",
          margin: "20px 20px 0 20px",
          padding: "20px",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: "700" }}>Total produção </p>
        <div style={{ display: "flex" }}>
          {bigNumbers.map((e) => {
            return <BigNumber element={e} />;
          })}
        </div>
      </div>
      <div
        style={{
          border: "1px solid #C4C7CA",
          borderRadius: "8px",
          margin: "20px 20px 0 20px",
          padding: "20px",
        }}
      >
        <p
          onClick={() => setisTabelaOpen(!isTabelaOpen)}
          style={{ fontSize: "16px", fontWeight: "700", cursor: "pointer" }}
        >
          {isTabelaOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />} Produção
          por Instituição Financeira
        </p>
        <Collapse isOpen={isTabelaOpen}>
          <Table data={tabelaProducao} />
        </Collapse>
      </div>
      <div
        style={{
          border: "1px solid #C4C7CA",
          borderRadius: "8px",
          margin: "20px 20px 0 20px",
          padding: "20px",
        }}
      >
        <p
          onClick={() => setIsGraficoOpen(!isGraficoOpen)}
          style={{ fontSize: "16px", fontWeight: "700", cursor: "pointer" }}
        >
          {isGraficoOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />} Produção
          por Produto
        </p>
        <Collapse isOpen={isGraficoOpen}>
          <TableProduto data={tabelaProdutos} />
        </Collapse>
      </div>
      {permitionsTabs.producao.tabelaCanal && (
        <div
          style={{
            border: "1px solid #C4C7CA",
            borderRadius: "8px",
            margin: "20px 20px 0 20px",
            padding: "20px",
          }}
        >
          <p
            onClick={() => setIsGraficoCanalOpen(!isGraficoCanalOpen)}
            style={{ fontSize: "16px", fontWeight: "700", cursor: "pointer" }}
          >
            {isGraficoCanalOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}{" "}
            Produção por Canal
          </p>
          <Collapse isOpen={isGraficoCanalOpen}>
            <TableCanal data={tabelaCanais} />
          </Collapse>
        </div>
      )}
      {
        <ModalLoja
          canalId={filtros.canalId}
          modalPessoa={modalLoja}
          toggle={toggleLoja}
        />
      }
      {<ModalConsultores isOpen={modalConsultor} toggle={toggleConsultor} />}
    </>
  );
}
