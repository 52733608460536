import {setLoading} from "../../store/modules/globalLoading/actions";
import api from "../../util/api";
import {notifySuccess} from "../../components/message/message";

export async function registraCodClienteW3ERP(idPessoa, codClienteW3erp){
    let body = {
        IdPessoa: idPessoa,
        codigoClienteW3ERP: codClienteW3erp
    }

    setLoading(true);
    const data = await api.put(`Pessoa/${idPessoa}/instituicao-financeira`, body)
    setLoading(false);

    if (!data.success){
        return {valid : false};
    }

    notifySuccess("Id Cliente W3ERP adicionado com sucesso!")
    return {valid: true, id: data.content}
}