import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    item: {
        descricao: "",
        produtoRcId: "0",
        bancoId: "0",
        tipoOperacaoId: "0",
        id: ""
    },
    copy: {},
    invalid: {
        descricao: false,
        produtoRcId: false,
        bancoId: false,
        tipoOperacaoId: false
    },
    items: [],
    operacoes: [],
    produtosRc: [],
    bancos: [],
    operacaoId: "",
    activeTab: "Search",
    seeRegister: false,
    permitions: {
        criar: false,
        deletar: false
    }
}

export default function produtoInst(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.PRODUTOSINST:
                    return { ...state, items: action.value }
                case TYPES.SET_OPERACOES:
                    return { ...state, operacoes: action.value }
                case TYPES.SET_PRODUTOSRC:
                    return { ...state, produtosRc: action.value }
                case TYPES.SET_BANCOS:
                    return { ...state, bancos: action.value }
                case TYPES.PRODUTOINST:
                    return { ...state, item: action.value }
                case TYPES.INVALID_PRODUTOINST:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_PRODUTOINST:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.PRODUTOINST_COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, item: INITIAL_STATE.item, invalid: INITIAL_STATE.invalid }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}