import api from "../util/api";
import {
  notifyInfo,
  notifySuccess,
  notifyWarn,
} from "../components/message/message";
import * as actions from "../store/modules/layoutParametrizacao/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function getLayouts() {
  setLoading(true);
  const data = await api.get("layoutPlanilha");
  setLoading(false);

  if (!data.success) {
    return;
  }

  return data.content;
}

export async function getLayoutsCamposEquivalentes(tipoArquivoId, layoutId) {
  setLoading(true);
  const data = await api.get(
    layoutId
      ? `LayoutPlanilha/camposEquivalentes?tipoArquivoId=${tipoArquivoId}&layoutId=${layoutId}`
      : `LayoutPlanilha/camposEquivalentes?tipoArquivoId=${tipoArquivoId}`
  );
  setLoading(false);

  if (!data.success) {
    return;
  }

  const codTabelaIndex = data.content.findIndex(
    (campo) => campo.campoModel === "CodigoTabela"
  );
  if (codTabelaIndex !== -1) {
    data.content[codTabelaIndex].campoModel = "DescricaoTabela";
  }

  return data.content;
}

export async function getAllLayoutsCamposEquivalentes(tipoArquivoId) {
  setLoading(true);
  const data = await api.get(
    `LayoutPlanilha/camposEquivalentes?tipoArquivoId=${tipoArquivoId}`
  );
  setLoading(false);

  if (!data.success) {
    return;
  }

  const codTabelaIndex = data.content.findIndex(
    (campo) => campo.campoModel === "CodigoTabela"
  );
  if (codTabelaIndex !== -1) {
    data.content[codTabelaIndex].campoModel = "DescricaoTabela";
  }
  return data.content;
}

export async function createLayout(item) {
  if (item.descricao.trim() === "") {
    notifyWarn("Descrição do layout não pode estar em branco");
    return;
  }
  if (item.tipoArquivoId === "0") {
    notifyWarn("Não é possível salvar o layout sem tipo de arquivo");
    return;
  }
  if (
    item.tipoArquivoId.toUpperCase() ===
      "4898DDC8-C0CB-49D8-BB72-EA946FB7963D" &&
    !item.layoutPlanilhaParametros.some(
      (parametro) => parametro.detalhes.length > 0
    ) &&
    !item.layoutPlanilhaParametros.some(
      (parametro) => parametro.status.length > 0
    ) &&
    item.tipoComissaoBancoId === "0"
  ) {
    notifyWarn("Não é possível salvar o layout sem tipo de comissão");
    return;
  }
  if (item.bancoId === "0") {
    notifyWarn("Não é possível salvar o layout sem a instituição financeira");
    return;
  }
  if (item.nomeAba === "") {
    notifyWarn("Número da aba não pode estar em branco");
    return;
  }
  var layoutPlanilhaParametros = [];

  item.layoutPlanilhaParametros.forEach((parametro) => {
    layoutPlanilhaParametros.push({
      descricao: parametro.descricao,
      campoModel: parametro.campoModel,
      nomeColuna: parametro.nomeColuna,
      layoutPlanilhaCamposEquivalenteId:
        parametro.layoutPlanilhaCamposEquivalenteId,
      valorDefault: parametro.default ?? null,
      detalhes: parametro.detalhes,
      filtros: parametro.status,
    });
    if (!!parametro.camposEquivalentesOpcionais) {
      parametro.camposEquivalentesOpcionais.forEach((campoOpcional) => {
        layoutPlanilhaParametros.push({
          descricao: parametro.descricao,
          layoutPlanilhaCamposEquivalenteId:
            campoOpcional.layoutPlanilhaCamposEquivalenteId,
          layoutPlanilhaCamposEquivalenteOpcionalId:
            parametro.layoutPlanilhaCamposEquivalenteId,
          valorDefault: parametro.default ?? null,
          detalhes: parametro.detalhes,
          filtros: parametro.status,
        });
      });
    }
  });
  const body = {
    descricao: item.descricao,
    tipoArquivoId: item.tipoArquivoId,
    lojaNomeArquivo: item.isIndividual,
    negativo: item.isNegativo,
    quitacao: item.isQuitacao,
    tipoComissaoId:
      item.tipoComissaoBancoId === "0" ? null : item.tipoComissaoBancoId,
    bancoId: item.bancoId,
    nomeAba: item.nomeAba,
    layoutPlanilhaParametros: layoutPlanilhaParametros,
    validaCpf: item.validaCpf,
    validaProduto: item.validaProduto,
  };

  setLoading(true);
  const data = await api.post("layoutPlanilha", body);
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Layout cadastrado com sucesso");

  return true;
}

export async function getLayoutById(id) {
  setLoading(true);
  const data = await api.get(`layoutPlanilha/${id}`);
  setLoading(false);

  if (!data.success) {
    return;
  }
  data.content.layoutPlanilhaParametros.forEach(
    (item) => (item.status = item.filtros)
  );

  var layoutAgrupados = data.content.layoutPlanilhaParametros.reduce(
    (obj, campo) => {
      if (!obj[campo.descricao]) obj[campo.descricao] = [];
      obj[campo.descricao].push(campo);
      return obj;
    },
    {}
  );

  const camposOpcionais = Object.keys(layoutAgrupados).map((equivalenteId) => {
    let selectedLayout = layoutAgrupados[equivalenteId].map((layout) => {
      return {
        id: layout.id,
        layoutPlanilhaCamposEquivalenteId:
          layout.layoutPlanilhaCamposEquivalenteId,
        main: layout.layoutPlanilhaCamposEquivalenteOpcionalId,
      };
    });
    selectedLayout = selectedLayout.filter((item) => item.main);

    return {
      ...layoutAgrupados[equivalenteId].find(
        (item) => !item.layoutPlanilhaCamposEquivalenteOpcionalId
      ),
      camposEquivalentesOpcionais: selectedLayout,
    };
  });

  camposOpcionais.forEach((item) => {
    item.default = item.valorDefault ?? "";
  });

  const newData = {
    id: data.content.id,
    descricao: data.content.descricao,
    tipoArquivoId: data.content.tipoArquivoId,
    isIndividual: data.content.lojaNomeArquivo,
    isQuitacao: data.content.quitacao,
    isNegativo: data.content.negativo,
    bancoId: data.content.bancoId,
    nomeAba: data.content.nomeAba,
    tipoComissaoBancoId: data.content?.tipoComissao?.id ?? null,
    layoutPlanilhaParametros: camposOpcionais,
    validaCpf: data.content.validaCpf,
    validaProduto: data.content.validaProduto,
  };

  return newData;
}

export async function getTiposArquivo() {
  const data = await api.get("tipoArquivo");

  if (!data.success) {
    return;
  }

  return data.content;
}

export async function getInstituicoesFinanceira() {
  const data = await api.get("Bancos");

  if (!data.success) {
    return;
  }

  const bancos = [];

  data.content.forEach((banco) => {
    bancos.push({
      nome: banco.nome,
      id: banco.id,
    });
  });

  return bancos;
}

export async function getTiposComissao() {
  const data = await api.get("TipoComissao");

  if (!data.success) {
    return;
  }

  return data.content;
}

export async function deleteLayout(id) {
  setLoading(true);
  const data = await api.delete(`layoutPlanilha/${id}`);
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Layout de Parametrização removido");
  return true;
}

export async function updateLayout(item, copy) {
  if (JSON.stringify(item) === JSON.stringify(copy)) {
    notifyInfo("Nada foi alterado");
    return;
  }
  if (item.descricao.trim() === "") {
    notifyWarn("Descrição do layout não pode estar em branco");
    return;
  }
  if (item.tipoArquivo === "0") {
    notifyWarn("Não é possível salvar o layout sem tipo de arquivo");
    return;
  }
  if (
    item.tipoArquivoId.toUpperCase() ===
      "4898DDC8-C0CB-49D8-BB72-EA946FB7963D" &&
    !item.layoutPlanilhaParametros.some(
      (parametro) => parametro.detalhes.length > 0
    ) &&
    item.tipoComissaoBancoId === "0"
  ) {
    notifyWarn("Não é possível salvar o layout sem tipo de comissão");
    return;
  }
  if (item.bancoId === "0") {
    notifyWarn("Não é possível salvar o layout sem a instituição financeira");
    return;
  }
  if (item.nomeAba === "") {
    notifyWarn("Número da aba não pode estar em branco");
    return;
  }

  var layoutPlanilhaParametros = [];

  item.layoutPlanilhaParametros.forEach((parametro) => {
    const detalhes = parametro.detalhes.filter((item) => !item.id);
    const filtros = parametro.status.filter((item) => !item.id);
    layoutPlanilhaParametros.push({
      id: parametro.id ?? null,
      descricao: parametro.descricao,
      campoModel: parametro.campoModel,
      nomeColuna: parametro.nomeColuna,
      layoutPlanilhaCamposEquivalenteId:
        parametro.layoutPlanilhaCamposEquivalenteId,
      valorDefault: parametro.default ?? null,
      detalhes: detalhes,
      filtros: filtros,
    });
    if (!!parametro.camposEquivalentesOpcionais) {
      parametro.camposEquivalentesOpcionais.forEach((campoOpcional) => {
        layoutPlanilhaParametros.push({
          id: campoOpcional.id ?? null,
          descricao: parametro.descricao,
          layoutPlanilhaCamposEquivalenteId:
            campoOpcional.layoutPlanilhaCamposEquivalenteId,
          layoutPlanilhaCamposEquivalenteOpcionalId:
            parametro.layoutPlanilhaCamposEquivalenteId,
          valorDefault: parametro.default ?? null,
          detalhes: detalhes,
          filtros: filtros.status,
        });
      });
    }
  });

  let layoutPlanilhaParametrosDiff = layoutPlanilhaParametros.filter(
    (x) =>
      !x.id ||
      (x?.detalhes?.length > 0 && x?.detalhes?.find((d) => !d.id)) ||
      (x?.filtros?.length > 0 && x?.filtros?.find((d) => !d.id))
  );

  const body = {
    id: item.id,
    descricao: item.descricao,
    lojaNomeArquivo: item.isIndividual,
    negativo: item.isNegativo,
    quitacao: item.isQuitacao,
    tipoArquivoId: item.tipoArquivoId,
    tipoComissaoId:
      item.tipoComissaoBancoId === "0" ? null : item.tipoComissaoBancoId,
    bancoId: item.bancoId,
    nomeAba: item.nomeAba,
    layoutPlanilhaParametros: layoutPlanilhaParametrosDiff,
    validaCpf: item.validaCpf,
    validaProduto: item.validaProduto,
  };

  setLoading(true);
  const data = await api.put(`layoutPlanilha/${item.id}`, body);
  setLoading(false);

  if (!data.success) {
    return;
  }

  notifySuccess("Layout atualizado");
  actions.clearMapeamento();
  actions.clearDetalhamento();
  actions.clearStatus();
  actions.setActiveTab("Search");
  getLayouts();
}

export async function removeParametro(id) {
  setLoading(true);
  const data = await api.delete(`layoutPlanilha/parametro/${id}`);
  setLoading(false);

  if (!data.success) {
    return;
  }

  notifySuccess("Parâmetro removido");
}

export async function removeDetalhamento(id) {
  setLoading(true);
  const data = await api.delete(`layoutPlanilha/parametro-detalhe/${id}`);
  setLoading(false);

  if (!data.success) {
    return false;
  }
  notifySuccess("Detalhamento removido");
  return true;
}

export async function removeStatus(id) {
  setLoading(true);
  const data = await api.delete(`layoutPlanilha/parametro-filtro/${id}`);
  setLoading(false);

  if (!data.success) {
    return false;
  }
  notifySuccess("Detalhamento de status removido");
  return true;
}

export async function updateLayoutStatus(id, status) {
  setLoading(true);
  const data = await api.put(
    `LayoutPlanilha/${id}/altera-status?status=${status}`
  );
  setLoading(false);

  if (!data.success) {
    return false;
  }
  notifySuccess("Status atualizado");
  return true;
}
