const STORE_ID = "DASHBOARD_CAMPANHA";

const TYPES = {
    SET_FILTROS: "SET_FILTROS",
    SET_MODAL_CONSULTORES: "SET_MODAL_CONSULTORES",
    SET_GET_ALL_CONSULTORES: "SET_GET_ALL_CONSULTORES",
    SET_CAMPANHA: "SET_CAMPANHA",
    SET_PRODUTOS: "SET_PRODUTOS",
    SET_TABELA: "SET_TABELA",
    SET_IS_CONSULTOR: "SET_IS_CONSULTOR",
    SET_TIPOS_PARCERIA: "SET_TIPOS_PARCERIA",
    SET_CAMPANHAS: "SET_CAMPANHAS",
    SET_GRUPOS: "SET_GRUPOS",
    SET_CENTROS_CUSTO: "SET_CENTROS_CUSTO",
    SET_HIERARQUIAS: "SET_HIERARQUIAS",
    SET_CONSULTORES: "SET_CONSULTORES",
    SET_CANAIS: "SET_CANAIS",
    SET_STATUS: "SET_STATUS",
    CLEAN_FILTROS: "CLEAN_FILTROS",
    SET_LOADING_LOJAS: "SET_LOADING_LOJAS",
    SET_LOADING_CONSULTORES: "SET_LOADING_CONSULTORES"
}

export { STORE_ID, TYPES }