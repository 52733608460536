import { useEffect, useState } from "react";

import { Col, Label, Row, Input } from "reactstrap";
import { InputAdornment, OutlinedInput } from "@material-ui/core";

import { useSelector } from "react-redux";

import * as BiIcons from "react-icons/bi";

import * as actions from "../../../../store/modules/perfilAcesso/actions";
import * as controller from "../../../../controllers/perfilAcessoController";

import LoadingIcon from "../../../../components/Loading/LoadingIcon";
import { Select } from "../../../../components/Inputs/Input";
import '../styles.css';
export function LojasDisponiveisList() {
  const [canaisOptions, setCanaisOptions] = useState([]);

  const {
    lojasDisponiveis,
    lojasSelecionadas,
    lojasDisponiveisSearchResults,
    lojasDisponiveisSelectedSearchResults,
    lojasDisponiveisSearchTerm,
    lojasAssociadas,
    loadingLojas,
    activeTab,
  } = useSelector((state) => state.perfilAcesso);

  const lojas =
    lojasDisponiveisSearchTerm.length > 0
      ? lojasDisponiveisSearchResults
      : lojasDisponiveis;

  const isAllSearchResultsSelected =
    lojasDisponiveisSearchResults.length > 0 &&
    lojasDisponiveisSearchResults.length ===
      lojasDisponiveisSelectedSearchResults.length;

  const isAllSelected =
    lojasDisponiveisSearchTerm.length > 0
      ? isAllSearchResultsSelected
      : lojasSelecionadas.length > 0 &&
        lojasSelecionadas.length === lojasDisponiveis.length;

  const isLojasDisponiveisEmpty = lojasDisponiveis.length === 0;

  const isSelectAllVisible =
    lojasDisponiveisSearchTerm.length > 0
      ? lojasDisponiveisSearchResults.length > 0
      : !(isLojasDisponiveisEmpty || activeTab === "Visualize");

  function handleSearchLojasDisponiveis(inputValue) {
    actions.setLojasDisponiveisSearchTerm(inputValue);

    if (inputValue.trim() === "") {
      actions.setLojasDisponiveisSearchTerm("");
      actions.setLojasDisponiveisSearchResults([]);
      return;
    }

    const searchTermLowercase = inputValue.toLowerCase();
    const lojasFiltradas = lojasDisponiveis.filter((loja) =>
      loja.nome.toLowerCase().includes(searchTermLowercase)
    );

    actions.setLojasDisponiveisSearchResults(lojasFiltradas);

    actions.setLojasDisponiveisSelectedSearchResults(
      lojasFiltradas.filter((lojaDisponivel) =>
        lojasSelecionadas.some(
          (lojaSelecionada) => lojaSelecionada.id === lojaDisponivel.id
        )
      )
    );
  }

  function handleSelectAllLojasDisponiveis() {
    if (lojasDisponiveisSearchTerm.length > 0) {
      const searchResultsSelected = lojasDisponiveisSearchResults.filter(
        (lojaDisponivel) =>
          lojasSelecionadas.some(
            (lojaSelecionada) => lojaSelecionada.id === lojaDisponivel.id
          )
      );

      const isAllSearchResultsSelectedd =
        searchResultsSelected.length > 0 &&
        searchResultsSelected.length === lojasDisponiveisSearchResults.length;

      const lojasSelecionadasWithoutSelectedSearchResultsItems =
        lojasSelecionadas.filter(
          (loja) =>
            !searchResultsSelected.some(
              (lojaSelecionada) => lojaSelecionada.id === loja.id
            )
        );

      if (isAllSearchResultsSelectedd) {
        actions.setLojasSelecionadas(
          lojasSelecionadasWithoutSelectedSearchResultsItems
        );

        actions.setLojasDisponiveisSelectedSearchResults([]);
      } else {
        actions.setLojasSelecionadas([
          ...lojasSelecionadasWithoutSelectedSearchResultsItems,
          ...lojasDisponiveisSearchResults,
        ]);

        actions.setLojasDisponiveisSelectedSearchResults(
          lojasDisponiveisSearchResults
        );
      }

      return;
    }

    if (isAllSelected) {
      actions.setLojasSelecionadas([]);
    } else {
      actions.setLojasSelecionadas([...lojasDisponiveis]);
    }
  }

  function handleSelecionarLojaDisponivel(lojaId) {
    const lojaSelecionada = lojasDisponiveis.find((loja) => loja.id === lojaId);
    if (lojaSelecionada !== undefined) {
      actions.setLojasSelecionadas([...lojasSelecionadas, lojaSelecionada]);
    }

    if (lojasDisponiveisSearchTerm.length > 0) {
      actions.setLojasDisponiveisSelectedSearchResults([
        ...lojasDisponiveisSelectedSearchResults,
        lojaSelecionada,
      ]);
    }
  }

  function handleDeselecionarLojaDisponivel(lojaId) {
    actions.setLojasSelecionadas(
      [...lojasSelecionadas].filter((loja) => loja.id !== lojaId)
    );

    if (lojasDisponiveisSearchTerm.length > 0) {
      actions.setLojasDisponiveisSelectedSearchResults(
        [...lojasDisponiveisSelectedSearchResults].filter(
          (loja) => loja.id !== lojaId
        )
      );
    }
  }

  function checkIfLojaDisponivelIsSelecionada(lojaId) {
    const isLojaSelecionada = lojasSelecionadas.some(
      (loja) => loja.id === lojaId
    );

    return isLojaSelecionada;
  }

  async function handleFilterByCanal(selectValue) {
    actions.setLojasDisponiveisSearchTerm("");
    actions.setLojasDisponiveisSearchResults([]);
    actions.setLojasDisponiveisSelectedSearchResults([]);

    let lojas;

    if (selectValue === "0") {
      lojas = await controller.getLojas();
    } else {
      lojas = await controller.getLojasByCanal(selectValue);
    }

    const newLojaDisponiveis = lojas.filter(
      (loja) =>
        !lojasAssociadas.some((lojaAssociada) => lojaAssociada.id === loja.id)
    );
    actions.setLojasDisponiveis(newLojaDisponiveis);
    actions.setLojasDisponiveisInitialValueBackup(lojas);
  }

  useEffect(() => {
    async function fetchCanais() {
      const canais = await controller.getCanais();
      setCanaisOptions(canais);
    }

    fetchCanais();
  }, []);

  return (
    <Col md={5}>
      <Label
        style={{
          fontWeight: "700",
          color: "var(--color-preto)",
          fontSize: "17px",
        }}
      >
        Lojas Disponíveis
      </Label>
      <div>
        <OutlinedInput
          className="input-default mb-2"
          placeholder="Procurar"
          value={lojasDisponiveisSearchTerm}
          onChange={(e) => handleSearchLojasDisponiveis(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <BiIcons.BiSearchAlt></BiIcons.BiSearchAlt>
            </InputAdornment>
          }
        />
        <div className="mb-2">
          <Select
            first="Selecione"
            select={{ value: "id", label: "nome" }}
            options={canaisOptions}
            onChange={(e) => {
              handleFilterByCanal(e.value);
            }}
            autoComplete="off"
          />
        </div>
      </div>
      <div
        style={{
          border: "1px solid #B5B5B5",
          borderRadius: "10px",
          height: "350px",
          overflow: "auto",
        }}
      >
        {isSelectAllVisible ? (
          <Row
            style={{
              flexWrap: "nowrap",
              margin: "15px 15px 0 15px",
              height: "35px",
              borderBottom: "1px solid #B5B5B5",
            }}
          >
            <Col md={1}>
              <Input
                type="checkbox"
                style={{display: 'flex'}}
                checked={isAllSelected}
                onChange={() => handleSelectAllLojasDisponiveis()}
              />
            </Col>
            <Col>
              <p
                style={{
                  whiteSpace: "nowrap",
                  color: "var(--color-preto)",
                }}
              >
                Selecionar todos
              </p>
            </Col>
          </Row>
        ) : null}
        {loadingLojas && <LoadingIcon color="var(--color-roxo)" />}
        {lojas
          .sort((a, b) => a.nome.localeCompare(b.nome))
          .map((loja, i) => {
            return (
              <Row
                key={loja.id}
                style={{
                  flexWrap: "nowrap",
                  margin: "15px 15px 0 15px",
                  height: "35px",
                  borderBottom: "1px solid #B5B5B5",
                }}
              >
                {!(activeTab === "Visualize") && (
                  <Col md={1}>
                    <Input
                      key={loja.id}
                      type="checkbox"
                      style={{display: 'flex'}}
                      checked={checkIfLojaDisponivelIsSelecionada(loja.id)}
                      onChange={() => {
                        checkIfLojaDisponivelIsSelecionada(loja.id)
                          ? handleDeselecionarLojaDisponivel(loja.id)
                          : handleSelecionarLojaDisponivel(loja.id);
                      }}
                    />
                  </Col>
                )}
                <Col>
                  <p
                    style={{
                      whiteSpace: "nowrap",
                      color: "var(--color-preto)",
                    }}
                  >
                    {loja.nome}
                  </p>
                </Col>
              </Row>
            );
          })}
      </div>
    </Col>
  );
}
