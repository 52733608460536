import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useSelector } from "react-redux";
import * as AiIcons from "react-icons/ai";
import * as gruposActions from "../../../store/modules/grupos/actions";
import * as gruposController from "../../../controllers/gruposController";
import { SessaoCNPJ, SessaoCPF } from "../components/searchPersona";
import { notifyWarn } from "../../../components/message/message";
import ModalPessoa from "../components/modalPessoa";
import TabelaPessoas from "../components/tabelaPessoas";
import { removeAccentsAndUppercase } from "../../../util/masks";
import * as actions from '../../../store/modules/grupos/actions';

const Register = () => {
    const { item, invalid, seeRegister } = useSelector(state => state.grupo);


    const [modalPersona, setModalPersona] = useState(false)

    const toggle = () => setModalPersona(!modalPersona)

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                gruposController.create(item);
            } else {
                gruposController.update(item);
            };
        }
    }

    function onChangeGrupo(value, field) {
        gruposActions.cleanInvalid(field);
        gruposActions.item({ ...item, [field]: value });
    }
  
    function handleOpenModalPessoas() {
      actions.setModal("", 'cpfCnpj')
      actions.setModal("", 'nome')
      toggle();
    }

    
    const handleClickPersona = (e) => {
      e.preventDefault()
      if (item.nome === "" || item.nome === undefined) {
        return notifyWarn('Informe um CPF/CNPJ válido')
      }
      
      if (item.pessoas.some(pessoa => pessoa.id === item.idPessoa)) {
        notifyWarn(`A pessoa ${item.nome} já foi adicionada`)
        return
      }

        var obj = {
            cpfCnpj: item.cpfCnpj,
            nome: item.nome,
            flagJuridica: item.flagJuridica,
            id: item.idPessoa,
        }
        item.pessoas.push(obj);
        gruposActions.setPessoas(item.pessoas);
        gruposActions.setPessoa('', 'nome')
        gruposActions.setPessoa('', 'cpfCnpj')
        gruposActions.setPessoa(false, 'flagJuridica')
        gruposActions.setPessoa('', 'idPessoa')
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2">
                        <p style={{ fontSize: "20px" }}>Preencha os campos para cadastrar um novo Grupo</p>
                    </Col>
                    <Col className="pt-2">
                        <Form>
                            <Col>
                                <FormGroup>
                                    <Row>
                                        <Col md="6">
                                            <Label for="grupo">Descrição do Grupo</Label>
                                            <Input
                                                type="text"
                                                name="grupo"
                                                id="grupo"
                                                placeholder="Digite aqui..."
                                                value={item.descricao}
                                                onChange={e => onChangeGrupo(removeAccentsAndUppercase(e.target.value), "descricao")}
                                                onKeyPress={e => handleKeyPress(e)}
                                                className={invalid.descricao ? "is-invalid" : ""}
                                                disabled={seeRegister ? true : false}
                                            />
                                        </Col>
                                    </Row>
                                    <Label className="mt-4">Personas</Label>
                                    {!seeRegister &&
                                        <Col md={6}>
                                            <Row>
                                                <Card>
                                                    <CardHeader>
                                                        <Row>
                                                            <Col>
                                                                <Label>Tipo de Personas</Label>
                                                            </Col>
                                                        </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={4}>
                                                                <input
                                                                    type="radio"
                                                                    checked={!item.flagJuridica}
                                                                    onClick={() => {
                                                                        gruposActions.setPessoa('', 'nome')
                                                                        gruposActions.setPessoa('', 'cpfCnpj')
                                                                        gruposActions.getTipoPessoa(false)
                                                                    }
                                                                    }
                                                                />
                                                                <label style={{ marginLeft: "10px" }}>Física</label>
                                                            </Col>
                                                            <Col>
                                                                <input
                                                                    style={{ marginLeft: '25px' }}
                                                                    type="radio"
                                                                    checked={item.flagJuridica}
                                                                    onClick={() => {
                                                                        gruposActions.setPessoa('', 'nome')
                                                                        gruposActions.setPessoa('', 'cpfCnpj')
                                                                        gruposActions.getTipoPessoa(true)
                                                                    }
                                                                    }
                                                                />
                                                                <label style={{ marginLeft: "10px" }}>Jurídica</label>
                                                            </Col>
                                                        </Row>
                                                        <Row >
                                                            <Col md={10} style={{ marginLeft: '5px' }}>
                                                                {!item.flagJuridica ? (
                                                                    <SessaoCPF />
                                                                ) : (
                                                                    <SessaoCNPJ />
                                                                )}
                                                            </Col>
                                                            <Col>
                                                                <AiIcons.AiOutlineSearch
                                                                    style={{ margin: '37% 0 0 -85%', fontSize: '20px', color: 'var(--color-verde)', cursor: 'pointer' }}
                                                                    onClick={() => handleOpenModalPessoas()} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <button
                                                                style={{ border: 'none', background: 'transparent', margin: '15px 0 0 15px' }}
                                                                onClick={(e) => handleClickPersona(e)}>
                                                                <AiIcons.AiOutlinePlusCircle
                                                                    style={{ color: "green", marginRight: "5px", fontSize: '20px' }}
                                                                />
                                                                Adicionar
                                                            </button>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Row>
                                        </Col>
                                    }
                                </FormGroup>
                                {item.pessoas.length !== 0 &&
                                   <>
                                        <TabelaPessoas 
                                            data={item.pessoas}
                                        />
                                   </>
                                }
                            </Col>
                        </Form>
                    </Col>
                </Card>
            </Col>
            {
                <ModalPessoa
                    modalPessoa={modalPersona}
                    toggle={toggle}
                    isJuridica={item.flagJuridica}
                />
            }
        </>
    );
}

export default Register;