import React from 'react'
import { BsArrowUp, BsArrowDown } from 'react-icons/bs'

import { maskMoeda } from '../../../../util/masks'

export default function BigNumber({element}){
    return(
        element.crescimento > 0 ? (
            <div style={{background: 'rgba(151, 224, 79, 0.25)',  borderRadius: '20px',width: '100%', height: '110px', marginRight: '20px', paddingTop: '15px'}}>
                <p style={{textAlign: 'center', color: 'var(--color-verde-claro)', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                    <BsArrowUp style={{fontSize: '20px'}}/> {`${element.crescimento}%`}
                </p>
                <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                    {`(${element.qtdPropostas})`}
                </p>
                <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                    {`R$${maskMoeda(element.valorTotal.toFixed(2))}`}
                </p>
                <p style={{textAlign: 'center', fontSize: '13px'}}>
                    {element.status}
                </p>
            </div>
        ) :
        (
            <div style={{background: 'rgba(253, 105, 130, 0.2)', borderRadius: '20px', width: '100%', height: '110px', marginRight: '20px', paddingTop: '15px'}}>
                <p style={{textAlign: 'center', color: '#F43656', fontWeight: '700', fontsize: '18px', marginBottom: '1px'}}>
                    <BsArrowDown style={{fontSize: '20px'}}/> {`${element.crescimento}%`}
                </p>
                <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                    {`(${element.qtdPropostas})`}
                </p>
                <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                    {`R$${maskMoeda(element.valorTotal.toFixed(2))}`}
                </p>
                <p style={{textAlign: 'center', fontSize: '13px'}}>
                    {element.status}
                </p>
            </div>
        )
    )
}