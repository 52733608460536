/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { AiOutlineCloseCircle, AiOutlineSearch } from 'react-icons/ai'
import { useSelector } from 'react-redux';
import { Col, Row, FormGroup, Label, Input, Button, Collapse } from 'reactstrap'

import { Select, Switch } from '../../../components/Inputs/Input'
import Table from './Components/Table.jsx';
import FiltroSelecionado from './Components/FiltroSelecionado';
import BigNumber from './Components/BigNumbers';
import BigNumbersCancelados from './Components/BigNumbersCancelados';
import TableCancelados from './Components/TableCancelados';
import ModalLoja from './Components/ModalLoja'

import * as actions from '../../../store/modules/dashboard/esteiraProducao/actions'
import * as controller from '../../../controllers/dashboardController/esteiraProducaoController'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { GrFormClose } from 'react-icons/gr';
import ModalConsultores from './Components/ModalConsultores';

import moment from 'moment';

export default function EsteiraProducao() {
    const { filtros, filtrosSelecionados, canais, tabelaEsteiraProducao, tabelaEsteiraProducaoCancelados, bigNumbers, bigNumbersCancelados, instituicoes, produtosRibercred, tiposOperacao, parcerias, convenios, grupos } = useSelector(state => state.dashboardEsteiraProducao);
    const { tab } = useSelector(state => state.dashboard);

    const [modalLoja, setModalLoja] = useState(false)
    const [modalConsultor, setModalConsultor] = useState(false)
    const [isTabelaOpen, setisTabelaOpen] = useState(false)
    const [isTabelaCanceladosOpen, setIsTabelaCanceladosOpen] = useState(false)
    const [isFirstAccess, setIsFirstAccess] = useState(true)

    const toggleLoja = () => setModalLoja(!modalLoja)
    const toggleConsultor = () => setModalConsultor(!modalConsultor)

    function onChangeFiltros(value, campo) {
        actions.setFiltros(campo, value)
    }

    async function handleLimparFiltrosSelecionados() {
        actions.cleanRegister()
        actions.setFiltrosSelecionados([])
    }

    async function handleAplicarFiltros() {
        let filtrosSelected = []

        filtrosSelected.push({ chave: 'dataInicio', descricao: filtros.dataInicio })
        filtrosSelected.push({ chave: 'dataFim', descricao: filtros.dataFim })
        filtrosSelected.push({ chave: 'tipoData', descricao: filtros.tipoData })

        if (filtros.instituicaoId !== '0' && filtros.instituicao !== '') {
            filtrosSelected.push({ id: filtros.instituicaoId, chave: 'instituicaoId', descricao: filtros.instituicao })
        }
        if (filtros.lojaId !== '0' && filtros.loja !== '') {
            filtrosSelected.push({ id: filtros.lojaId, chave: 'lojaId', descricao: filtros.loja })
        }
        if (filtros.canalId !== '0' && filtros.canal !== '') {
            filtrosSelected.push({ id: filtros.canalId, chave: 'canalId', descricao: filtros.canal })
        }
        if (filtros.produtoRibercredId !== '0' && filtros.produtoRibercred !== '') {
            filtrosSelected.push({ id: filtros.produtoRibercredId, chave: 'produtoRibercredId', descricao: filtros.produtoRibercred })
        }
        if (filtros.tipoOperacaoId !== '0' && filtros.tipoOperacao !== '') {
            filtrosSelected.push({ id: filtros.tipoOperacaoId, chave: 'tipoOperacaoId', descricao: filtros.tipoOperacao })
        }
        if (filtros.parceriaId !== '0' && filtros.parceria !== '') {
            filtrosSelected.push({ id: filtros.parceriaId, chave: 'parceriaId', descricao: filtros.parceria })
        }
        if (filtros.convenioId !== '0' && filtros.convenio !== '') {
            filtrosSelected.push({ id: filtros.convenioId, chave: 'convenioId', descricao: filtros.convenio })
        }
        if (filtros.grupoId !== '0' && filtros.grupo !== '') {
            filtrosSelected.push({ id: filtros.grupoId, chave: 'grupoId', descricao: filtros.grupo })
        }
        if (filtros.consultorId !== '0' && filtros.consultor !== '') {
            filtrosSelected.push({ id: filtros.consultorId, chave: 'consultorId', descricao: filtros.consultor })
        }

        const isSuccessed = await controller.get(filtrosSelected)

        if (isSuccessed) {
            actions.setFiltrosSelecionados(filtrosSelected);
            actions.cleanFiltros();
        } else {
            actions.setFiltrosSelecionados([]);
            actions.cleanFiltros()
            actions.cleanRegister()
        }
    }

    async function firstSearch() {
        setIsFirstAccess(false)

        const today = new Date();

        // 30 days before today
        const startDate = new Date(new Date().setDate(today.getDate() - 30));

        const isSuccessed = await controller.get([
            {
                chave: 'dataInicio',
                descricao: moment(startDate).format('YYYY-MM-DD')
            },
            {
                chave: 'dataFim',
                descricao: moment().format('YYYY-MM-DD')
            },
            {
                chave: 'tipoData',
                descricao: '1'
            },
        ])

        if (isSuccessed) {
            actions.setFiltrosSelecionados([
                {
                    chave: 'dataInicio',
                    descricao: moment(startDate).format('YYYY-MM-DD')
                },
                {
                    chave: 'dataFim',
                    descricao: moment().format('YYYY-MM-DD')
                },
                {
                    chave: 'tipoData',
                    descricao: '1'
                },
            ]);
            actions.cleanFiltros();
        }
    }

    useEffect(() => {
        controller.getConvenios()
        controller.getGrupos()
        controller.getInstituicoes()
        controller.getParcerias()
        controller.getProdutos()
        controller.getTiposOperacao()
        controller.getCanais()
    }, [])

    useEffect(() => {
        if (tab === '2' && isFirstAccess) {
            firstSearch()
        }
    }, [tab])

    return (
        <>
            <div style={{ border: '1px solid #C4C7CA', borderRadius: '8px', margin: '0 20px 0 20px', padding: '20px' }}>
                <p style={{ fontSize: '16px', fontWeight: '700' }}>Filtros</p>
                <div>
                    <Row>
                        <Col md={1}>
                            <FormGroup className="mr-2">
                                <Label style={{ fontWeight: '700' }}>
                                    Data Entrada
                                </Label>
                                <Input
                                    type="radio"
                                    name="radio1"
                                    style={{ marginLeft: '5%' }}
                                    checked={filtros.tipoData === '0'}
                                    onClick={() => onChangeFiltros('0', 'tipoData')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2} style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormGroup check className="ml-2">
                                <Label style={{ fontWeight: '700' }}>
                                    Data Status
                                </Label>
                                <Input
                                    type="radio"
                                    name="radio1"
                                    style={{ marginLeft: '5%', marginTop: '2rem' }}
                                    checked={filtros.tipoData === '1'}
                                    onClick={() => onChangeFiltros('1', 'tipoData')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <Label style={{ fontWeight: '700' }}>Data Início</Label>
                            <Input
                                type='date'
                                value={filtros.dataInicio}
                                max="9999-12-31"
                                onChange={(e) => onChangeFiltros(e.target.value, 'dataInicio')}
                            />
                        </Col>
                        <Col md={2}>
                            <Label style={{ fontWeight: '700' }}>Data Fim</Label>
                            <Input
                                type='date'
                                value={filtros.dataFim}
                                max="9999-12-31"
                                onChange={(e) => onChangeFiltros(e.target.value, 'dataFim')}
                            />
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label style={{ fontWeight: '700' }}>Instituição Financeira</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.instituicaoId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={instituicoes}
                                    onChange={(e) => { onChangeFiltros(e.value, 'instituicaoId'); onChangeFiltros(e.label, 'instituicao') }}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label style={{ fontWeight: '700' }}>Canal</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.canalId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={canais}
                                    onChange={(e) => { onChangeFiltros(e.value, 'canalId'); onChangeFiltros(e.label, 'canal') }}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Label style={{ fontWeight: '700' }}>Centro de Custo</Label>
                                <Input
                                    type="text"
                                    placeholder="Busque um favorecido..."
                                    value={filtros.loja}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                        <GrFormClose
                            style={{ marginTop: '30px', fontSize: '20px', cursor: 'pointer' }}
                            onClick={() => { onChangeFiltros('', 'loja'); onChangeFiltros('0', 'lojaId'); }}
                        />
                        <AiOutlineSearch
                            style={{ color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer' }}
                            onClick={() => toggleLoja()}
                        />
                        <Col md={3}>
                            <FormGroup>
                                <Label style={{ fontWeight: '700' }}>Produto Ribercred</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.produtoRibercredId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={produtosRibercred}
                                    onChange={(e) => { onChangeFiltros(e.value, 'produtoRibercredId'); onChangeFiltros(e.label, 'produtoRibercred') }}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                        {filtros.filtrosAvancados && (
                            <>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label style={{ fontWeight: '700' }}>Tipo de Operação</Label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: filtros.tipoOperacaoId }}
                                            select={{ value: "id", label: "descricao" }}
                                            options={tiposOperacao}
                                            onChange={(e) => { onChangeFiltros(e.value, 'tipoOperacaoId'); onChangeFiltros(e.label, 'tipoOperacao') }}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label style={{ fontWeight: '700' }}>Parceria</Label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: filtros.parceriaId }}
                                            select={{ value: "id", label: "nome" }}
                                            options={parcerias}
                                            onChange={(e) => { onChangeFiltros(e.value, 'parceriaId'); onChangeFiltros(e.label, 'parceria') }}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label style={{ fontWeight: '700' }}>Cônvenio</Label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: filtros.convenioId }}
                                            select={{ value: "id", label: "descricao" }}
                                            options={convenios}
                                            onChange={(e) => { onChangeFiltros(e.value, 'convenioId'); onChangeFiltros(e.label, 'convenio') }}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label style={{ fontWeight: '700' }}>Grupo</Label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: filtros.grupoId }}
                                            select={{ value: "id", label: "descricao" }}
                                            options={grupos}
                                            onChange={(e) => { onChangeFiltros(e.value, 'grupoId'); onChangeFiltros(e.label, 'grupo') }}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label style={{ fontWeight: '700' }}>Hierarquia</Label>
                                        <Input
                                            type="text"
                                            placeholder="Busque um consultor..."
                                            value={filtros.consultor}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                                <GrFormClose
                                    style={{ color: '#C4C7CA', marginTop: '30px', fontSize: '20px', cursor: 'pointer' }}
                                    onClick={() => { onChangeFiltros('', 'consultor'); onChangeFiltros('0', 'consultorId'); }}
                                />
                                <AiOutlineSearch
                                    style={{ color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer' }}
                                    onClick={() => toggleConsultor()}
                                />
                            </>
                        )}
                    </Row>
                </div>
                <div style={{ borderTop: '1px solid #C4C7CA', paddingTop: '20px' }}>
                    <Row>
                        <Col md={2}>
                            <Label style={{ fontWeight: '700' }}>Exibir filtros avançados</Label>
                            <div style={{ marginTop: '-20px', marginLeft: '-10px' }}>
                                <Switch
                                    className='switch mt-4'
                                    checked={filtros.filtrosAvancados}
                                    onClick={() => onChangeFiltros(!filtros.filtrosAvancados, 'filtrosAvancados')}
                                />
                            </div>
                        </Col>
                        <Col>
                            <Button
                                className="purpleButton"
                                style={{ width: "150px", float: 'right' }}
                                onClick={() => handleAplicarFiltros()}>
                                Aplicar
                            </Button>
                            <Button className="mr-4"
                                style={{
                                    width: "150px",
                                    background: "var(--color-branco)",
                                    borderColor: "var(--color-laranja)",
                                    color: "var(--color-laranja)",
                                    borderRadius: "7px",
                                    float: 'right'
                                }}
                                onClick={() => actions.cleanFiltros()}>
                                Limpar Filtros
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>

            <div style={{ border: '1px solid #C4C7CA', borderRadius: '8px', margin: '20px 20px 0 20px', padding: '20px' }}>
                <p style={{ fontSize: '16px', fontWeight: '700' }}>Seleção de filtros</p>
                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                    {filtrosSelecionados.map((e, i) => {
                        return (
                            <FiltroSelecionado element={e} index={i} />
                        )
                    })}
                    {!!filtrosSelecionados.length &&
                        <button
                            style={{ border: '1px solid var(--color-laranja)', borderRadius: '7px', width: '154px', height: '25px ', display: 'flex', marginRight: '1%', background: 'transparent' }}
                            onClick={() => handleLimparFiltrosSelecionados()}>
                            <p style={{ fontSize: "12px", color: 'var(--color-laranja)', marginTop: '4px', marginLeft: '2px', width: '130px' }}>
                                Limpar Seleção
                            </p>
                            <AiOutlineCloseCircle
                                style={{ marginTop: '4px', color: 'var(--color-laranja)', cursor: 'pointer' }}
                            />
                        </button>
                    }
                </div>
            </div>
            <div style={{ border: '1px solid #C4C7CA', borderRadius: '8px', margin: '20px 20px 0 20px', padding: '20px' }}>
                <p style={{ fontSize: '16px', fontWeight: '700' }}>Esteira de produção </p>
                <div style={{ display: 'flex' }}>
                    {bigNumbers.map((e) => {
                        return (
                            <BigNumber element={e} />
                        )
                    })}
                </div>
            </div>
            <div style={{ border: '1px solid #C4C7CA', borderRadius: '8px', margin: '20px 20px 0 20px', padding: '20px' }}>
                <p onClick={() => setisTabelaOpen(!isTabelaOpen)} style={{ fontSize: '16px', fontWeight: '700', cursor: 'pointer' }}>
                    {isTabelaOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />} Esteira de produção
                </p>
                <Collapse isOpen={isTabelaOpen}>
                    <Table data={tabelaEsteiraProducao} />
                </Collapse>
            </div>
            <div style={{ border: '1px solid #C4C7CA', borderRadius: '8px', margin: '20px 20px 0 20px', padding: '20px' }}>
                <p style={{ fontSize: '16px', fontWeight: '700' }}>Cancelados</p>
                <div style={{ display: 'flex' }}>
                    {bigNumbersCancelados.map((e) => {
                        return (
                            <BigNumbersCancelados element={e} />
                        )
                    })}
                </div>
            </div>
            <div style={{ border: '1px solid #C4C7CA', borderRadius: '8px', margin: '20px 20px 0 20px', padding: '20px' }}>
                <p onClick={() => setIsTabelaCanceladosOpen(!isTabelaCanceladosOpen)} style={{ fontSize: '16px', fontWeight: '700', cursor: 'pointer' }}>
                    {isTabelaCanceladosOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />} Cancelados
                </p>
                <Collapse isOpen={isTabelaCanceladosOpen}>
                    <TableCancelados data={tabelaEsteiraProducaoCancelados} />
                </Collapse>
            </div>
            {
                <ModalLoja
                    canalId={filtros.canalId}
                    modalPessoa={modalLoja}
                    toggle={toggleLoja} />
            }
            {
                <ModalConsultores
                    isOpen={modalConsultor}
                    toggle={toggleConsultor} />
            }
        </>
    )
}