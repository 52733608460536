const STORE_ID = "PERSONAS_INFORMACOES";

const TYPES = {
    INFORMACAO: "INFORMACAO",
    SOCIOS: "SOCIOS",
    INVALID_INFORMACAO: "INVALID_INFORMACAO",
    INFORMACOES: "INFORMACOES",
    CLEAN_INVALID_INFORMACAO: "CLEAN_INVALID_INFORMACAO",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_PERSONA: "SET_ACTIVE_PERSONA",
    SET_SEE: "SET_SEE",
    SET_SERPRO: "SET_SERPRO",
    ITEMS_ORGAO_RG: "ITEMS_ORGAO_RG",
    ITEMS_UF_DOC: "ITEMS_UF_DOC",
    SET_PESQUISA: "SET_PESQUISA",
    SET_SOCIOS_PESQUISA: "SET_SOCIOS_PESQUISA",
    CLEAN_SEARCH: "CLEAN_SEARCH",
    ADD_SOCIO: "ADD_SOCIO",
    REMOVE_SOCIO: "REMOVE_SOCIO"
}

export { STORE_ID, TYPES }