import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

const RoutesPath = ()=> {
    const { routes } = useSelector(state => state.routes)
    const getRoutes = routes => {
        return routes.map(prop => {
            if (prop.subNav) {
                return prop.subNav.map(subProp => {
                    return (
                        <Route
                            exact
                            path={subProp.path}
                            component={subProp.component}
                        />
                    )
                })
            } else {
                return (
                    <Route
                        exact
                        path={prop.path}
                        component={prop.component}
                    />
                );
            }
        });
    }

    return (
        <>
            {getRoutes(routes)}
        </>
    )
}

export default RoutesPath;