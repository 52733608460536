import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
  data = { ...data, storeId: STORE_ID };
  store.dispatch(data);
}

export function setItem(value) {
  dispatch({
    type: TYPES.SET_ITEM,
    value,
  });
}

export function setItems(value) {
  dispatch({
    type: TYPES.SET_ITEMS,
    value,
  });
}

export function setPermitions(value) {
  dispatch({
    type: TYPES.SET_PERMITIONS,
    value,
  });
}

export function cleanRegister() {
  dispatch({
    type: TYPES.CLEAN_REGISTER,
  });
}

export function setActiveTab(value) {
  dispatch({
    type: TYPES.SET_ACTIVE_TAB,
    value,
  });
}

export function getAllItems(value) {
  dispatch({
      type: TYPES.GET_ALL_ITEMS,
      value
  });
}

export function setSeeRegister(value) {
  dispatch({
    type: TYPES.SET_SEE_REGISTER,
    value,
  });
}

export function setSearch(value) {
  dispatch({
    type: TYPES.SET_SEARCH,
    value,
  });
}

export function cleanSearchField(value) {
  dispatch({
    type: TYPES.CLEAN_SEARCH_FIELD,
    value,
  });
}

export function cleanItemField(value) {
  dispatch({
    type: TYPES.CLEAN_ITEM_FIELD,
    value,
  });
}

export function cleanSearch() {
  dispatch({
    type: TYPES.CLEAN_SEARCH,
  });
}

export function setBancos(value) {
  dispatch({
    type: TYPES.SET_BANCOS,
    value,
  });
}

export function setLojasArrecadadoras(value) {
  dispatch({
    type: TYPES.SET_LOJAS_ARRECADADORAS,
    value,
  });
}

export function setContas(value) {
  dispatch({
    type: TYPES.SET_CONTAS,
    value,
  });
}

export function setTiposComissao(value) {
  dispatch({
    type: TYPES.SET_TIPOS_COMISSAO,
    value,
  });
}
