import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Col, FormGroup, Label, Row } from 'reactstrap'
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import * as  comissaoActions from "../../../store/modules/tipoComissao/actions";
import * as comissaoController from "../../../controllers/tipoComissaoController";
import Select from '../../../components/Inputs/Select';
import { notifySuccess, notifyWarn } from '../../../components/message/message';

const CardTipoComissaoBanco = ({ dados, index }) => {

    const { item, instituicoes, allProdutosBanco, produtosBancoII, seeRegister, basesCalculo, periodicidades, unidadeComissoes, tipoComissaoBanco } = useSelector(state => state.tipoComissao);

    useEffect(() => {
        if(tipoComissaoBanco.bancoId && tipoComissaoBanco.bancoId !== '0')
        comissaoController.getProdutosBancoII(tipoComissaoBanco.bancoId);
    }, [tipoComissaoBanco.bancoId]);

    useEffect(()=>{
        comissaoController.getAllProdutosBanco()
    },[])


    function onChangeItem(value, field) {
        comissaoActions.cleanInvalid(field);
        comissaoActions.setTipoComissaoBanco(field, value);
    }

    function addTipoComissaoBanco(e) {
        e.preventDefault()
        if(dados.unidadeComissaoId === '0'){
            return notifyWarn('Preencha o campo "Tipo de comissionamento"')
        }
        if(dados.periodicidadeId === '0'){
            return notifyWarn('Preencha o campo "Periodicidade"')
        }
        if(dados.baseCalculoId === '0'){
            return notifyWarn('Preencha o campo "Base de cálculo"')
        }
        if(dados.bancoId === '0'){
            return notifyWarn('Preencha o campo "Instituição"')
        }
        if(dados.produtoBancoId === '0'){
            return notifyWarn('Preencha o campo "Produto Instituição"')
        }
        var hasProdutoBanco = item.tipoComissaoBanco.find(element => element.produtoBancoId === tipoComissaoBanco.produtoBancoId)
        if(hasProdutoBanco === undefined){
            comissaoActions.addTipoComissaoBanco(tipoComissaoBanco)
            comissaoActions.cleanTipoComissaoBanco()
        }else{
            notifyWarn("Nâo é possível adicionar mais de um tipo de comissão para o mesmo produto instituição")
        }
    }

    async function removeTipoComissaoBanco(e) {
        e.preventDefault()
        if(dados.id){
            var isSuccessed = await comissaoController.deleteTipoComissao(item.id, dados.id)
            if(isSuccessed){
                comissaoActions.removeTipoComissaoBanco(index);
            }
        }else{
            comissaoActions.removeTipoComissaoBanco(index);
            notifySuccess("Comissão removida")
        }
    }

    return (
        <>
            <div style={{ background: ' var(--color-quase-branco)', minHeight: '166px', width: '100%', border: '1px solid #CCCFDE', borderRadius: '5px', padding: '15px 20px', color: 'var(--color-preto)' }}>
                <Row style={{ margin: '5px 0 0 15px' }}>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="associacao" style={{ marginTop: '20px', color: 'var(--color-preto)' }}>
                                Tipo de Comissionamento
                            </Label>
                            <Select
                                label="descricao"
                                onChange={e => onChangeItem(e.value, "unidadeComissaoId")}
                                value={dados.unidadeComissaoId}
                                options={unidadeComissoes}
                                disabled={seeRegister || index !== undefined}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="associacao" style={{ marginTop: '20px', color: 'var(--color-preto)' }}>
                                Periodicidade
                            </Label>
                            <Select
                                label="descricao"
                                onChange={e => onChangeItem(e.value, "periodicidadeId")}
                                value={dados.periodicidadeId}
                                options={periodicidades}
                                disabled={seeRegister || index !== undefined}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for="associacao" style={{ marginTop: '20px', color: 'var(--color-preto)' }}>
                                Base de Cálculo
                            </Label>
                            <Select
                                label="descricao"
                                onChange={e => onChangeItem(e.value, "baseCalculoId")}
                                value={dados.baseCalculoId}
                                options={basesCalculo}
                                disabled={seeRegister || index !== undefined}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="instituicao" style={{ marginTop: '20px', color: 'var(--color-preto)' }}>
                                Instituição Financeira
                            </Label>
                            <Select
                                label="nome"
                                onChange={e => {onChangeItem(e.value, "bancoId"); onChangeItem('0', "produtoBancoId")}}
                                value={dados.bancoId}
                                options={instituicoes}
                                disabled={seeRegister || index !== undefined}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label style={{ marginTop: '20px', color: 'var(--color-preto)' }}>
                                Produto Instituição
                            </Label>
                            <Select
                                label="descricao"
                                onChange={e => onChangeItem(e.value, "produtoBancoId")}
                                value={dados.produtoBancoId}
                                options={index !== undefined ? allProdutosBanco : produtosBancoII}
                                disabled={seeRegister || index !== undefined}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                { !seeRegister &&
                    <Row>
                    { index === undefined ?
                        <Col style={{ marginTop: '50px' }}>
                            <button style={{ background: 'transparent', border: 'none' }} onClick={(e) => { addTipoComissaoBanco(e) }}>
                                <AiOutlinePlusCircle style={{ color: 'var(--color-verde)', fontSize: '20px', marginRight: '5px' }} />Adicionar um tipo de comissão
                            </button>
                        </Col>
                        :
                        <Col style={{ marginTop: '50px' }}>
                            <button style={{ background: 'transparent', border: 'none' }} onClick={(e) => { removeTipoComissaoBanco(e)}}>
                                <AiOutlineCloseCircle style={{ color: 'red', fontSize: '20px', marginRight: '5px' }} />Excluir tipo de comissão
                            </button>
                        </Col>
                    }
                </Row>
                }
            </div>
        </>
    )
}

export default CardTipoComissaoBanco
