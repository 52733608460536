import api from "../../util/api";
import * as documentacaoActions from "../../store/modules/personas/documentacao_store/actions";

export async function getTiposDocumento() {
    const data = await api.get("tipoDocumento");

    if (!data.success) {
        return;
    }

    documentacaoActions.getItemsTiposDocumentos(data.content);
}