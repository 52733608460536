import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Col, Row, Form, FormGroup, Input, Label } from "reactstrap";

import { Select } from '../../../components/Inputs/Input'
import { removeAccents } from "../../../util/masks";

import * as actions from "../../../store/modules/tipoFormalizacaoInstituicao/actions";
import * as controller from "../../../controllers/tipoFormalizacaoInstituicaoController";

const Register = () => {

    const { item, instituicoes, tiposFormalizacoes, copy, invalid, seeRegister } = useSelector(state => state.tipoFormalizacaoInst);

    useEffect(() => {
        controller.getInstituicoes();
        controller.getTiposFormalizacoes();
    }, []);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                controller.create(item);
            } else {
                controller.update(item, copy);
            };
        }
    }

    function onChange(value, field) {
        actions.cleanInvalid(field);
        actions.setItem(field, value);
    }

    return (
        <>
            <Col>
                <Card className="radius p-4 pb-5">
                    <Col className="pt-2 mb-4">
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>
                            Preencha os campos para cadastrar um novo Tipo de Formalização Instituição
                        </p>
                    </Col>
                    <Form>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="convenio" style={{ fontWeight: "700", color: 'var(--color-preto)' }}>
                                            Descrição
                                        </Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={item.descricao}
                                            onChange={e => onChange(removeAccents(e.target.value), "descricao")}
                                            onKeyPress={e => handleKeyPress(e)}
                                            className={invalid.descricao ? "is-invalid" : ""}
                                            disabled={seeRegister ? true : false}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label style={{ fontWeight: "700", color: 'var(--color-preto)' }}>
                                            Instituição Financeira
                                        </Label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: item.instituicaoId }}
                                            select={{ value: "id", label: "nome" }}
                                            options={instituicoes}
                                            onChange={(e) => onChange(e.value, "instituicaoId")}
                                            isSearchable={true}
                                            autoComplete="off"    
                                            disabled={seeRegister ? true : false}                                       
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="convenio" style={{ fontWeight: "700", color: 'var(--color-preto)' }}>
                                            Tipo formalização
                                        </Label>
                                        <Select
                                            first="Selecione"
                                            value={{ value: item.tipoFormalizacaoId }}
                                            select={{ value: "id", label: "descricao" }}
                                            options={tiposFormalizacoes}
                                            onChange={(e) => onChange(e.value, "tipoFormalizacaoId")}
                                            isSearchable={true}
                                            autoComplete="off"               
                                            disabled={seeRegister ? true : false}                            
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Form>
                </Card>
            </Col>
        </>
    );
}

export default Register;