const STORE_ID = "INCONSISTENCIA_PRODUCAO";

const TYPES = {
    SET_PERMITIONS: "SET_PERMITIONS",
    SET_TAB: "SET_TAB",
    SET_TABS: "SET_TABS",
    SET_USUARIOS: "SET_USUARIOS",
    SET_PRODUTOS: "SET_PRODUTOS",
    SET_TIPOS_OPERACAO: "SETIPOS_OPERACAOBS",
    SET_STATUS: "SET_STATUS",
    SET_CONVENIO: "SET_CONVENIO",
    SET_FORMALIZACOES: "SET_FORMALIZACOES",
    SET_CLIENTES: "SET_CLIENTES",
    SET_LOJAS: "SET_LOJAS",
    CLEAN_TABS: "CLEAN_TABS"
}

export { STORE_ID, TYPES }