import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function pesquisar(campo, value) {
    dispatch({
        type: TYPES.PESQUISAR,
        campo,
        value
    })

}

export function item(campo, value) {
    dispatch({
        type: TYPES.ITEM,
        campo,
        value
    })

}

export function modal(campo, value) {
    dispatch({
        type: TYPES.MODAL,
        campo,
        value
    })

}

export function setItem(value) {
    dispatch({
        type: TYPES.SET_ITEM,
        value
    })

}

export function setImpressao(value) {
    dispatch({
        type: TYPES.SET_IMPRESSAO,
        value
    })

}

export function setEtiqueta(value) {
    dispatch({
        type: TYPES.SET_ETIQUETA,
        value
    })

}

export function setFormalizacoes(value) {
    dispatch({
        type: TYPES.SET_FORMALIZACOES,
        value
    })

}


export function setInstituicoes(value) {
    dispatch({
        type: TYPES.SET_INSTITUICOES,
        value
    })

}

export function setDestinatarios(value) {
    dispatch({
        type: TYPES.SET_DESTINATARIOS,
        value
    })

}

export function setLojas(value) {
    dispatch({
        type: TYPES.SET_LOJAS,
        value
    })

}

export function cleanSearch() {
    dispatch({
        type: TYPES.CLEAN_SEARCH
    })
}

export function cleanEtiqueta() {
    dispatch({
        type: TYPES.CLEAN_ETIQUETA
    })
}

export function cleanImpressao() {
    dispatch({
        type: TYPES.CLEAN_IMPRESSAO
    })
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function getAllItems(value) {
    dispatch({
        type: TYPES.GET_ALL_ITEMS,
        value
    });
}