import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    search: {
        cpfCnpj: "",
        razaoSocialNome: "",
        criacaoNascimento: "",
        cidade: "",
        ramal: '',
        uf: "",
        ufId: "0",
        codigoPapel: "0",
        usuarioInstituicao: "",
        canalId: '0',
        tipoLojaId: '0',
        codigoLojaBanco: ""
    },
    copy: {},
    items: [],
    canais: [],
    tiposLoja: [],
    estados: [],
    nascimentos: [],
    papeis: [],
    activeTab: "Search",
    getAllItems:{quantidade: '0',
                quantidadeTotal: '0',
                totalPaginas: '0'},
    tab: "1",
    permitions: {
        criar: false,
        deletar: false,
        autorizar: false,
    }
}

export default function personas(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.PERSONAS:
                    return { ...state, items: action.value }
                case TYPES.PERSONAS_COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_SEARCH:
                    return {
                        ...state,
                        search: INITIAL_STATE.search,
                        copy: INITIAL_STATE.copy,
                    }
                case TYPES.CLEAN_ALL_SEARCH:
                    return {
                        ...state,
                        search: INITIAL_STATE.search,
                        copy: INITIAL_STATE.copy,
                        items: INITIAL_STATE.items,
                        getAllItems: INITIAL_STATE.getAllItems
                    }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SEARCH:
                    return { ...state, search: action.value }
                case TYPES.ESTADOS:
                    return { ...state, estados: action.value }
                case TYPES.CANAIS:
                    return { ...state, canais: action.value }
                case TYPES.TIPOS_LOJA:
                    return { ...state, tiposLoja: action.value }
                case TYPES.NASCIMENTOS:
                    return { ...state, nascimentos: action.value }
                case TYPES.GET_ALL_ITEMS:
                    return { ...state, getAllItems: action.value }
                case TYPES.PAPEIS:
                    return { ...state, papeis: action.value }
                case TYPES.SET_TAB:
                    return { ...state, tab: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}