import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as actions from "../store/modules/pendenciaRibercred/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("tipo-pendencia");
    setLoading(false)

    if (!data.success) {
        return;
    }

    actions.getItems(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`tipo-pendencia/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    actions.copy(data.content);
    actions.item(data.content);
}

export async function create(item) {

    if (item.descricao.trim() === "") {
        actions.setInvalid("descricao");
        notifyWarn("Descrição não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao,
    }

    setLoading(true)
    const data = await api.post("tipo-pendencia", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Pendência ribercred cadastrada");
    actions.cleanRegister();
    get();
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (item.descricao === "") {
        actions.setInvalid("descricao");
        notifyWarn("Descrição não pode estar em branco");
        return;
    }


    const body = {
        descricao: item.descricao,
    }

    setLoading(true)
    const data = await api.put(`tipo-pendencia/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Pendência ribercred atualizada");
    actions.cleanRegister();
    actions.setActiveTab("Search");
    get();
}


export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`tipo-pendencia/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Pendência ribercred excluída");
    get();
}