import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function item(value) {
    dispatch({
        type: TYPES.TIPOCONVENIO,
        value
    });
}

export function copy(value) {
    dispatch({
        type: TYPES.TIPOCONVENIO_COPY,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_TIPOCONVENIO,
        field
    });
}

export function getItems(value) {
    dispatch({
        type: TYPES.TIPOCONVENIOS_INSTITUICOES_FINANCEIRAS,
        value
    });
}

export function setInstituicoes(value) {
    dispatch({
        type: TYPES.SET_INSTITUICOES,
        value
    });
}

export function setConvenios(value) {
    dispatch({
        type: TYPES.SET_CONVENIOS,
        value
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_TIPOCONVENIO,
        field
    })
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}
