import api from '../util/api'
import * as actions from '../store/modules/contasReceber/actions'
import { maskCNPJ, maskMoeda, removeMaskCNPJ, removeMaskCPF, removeMaskMoeda } from '../util/masks'
import { notifyInfo, notifySuccess, notifyWarn } from '../components/message/message'
import { setLoading } from '../store/modules/globalLoading/actions'

export async function get(pesquisa, pageSize, page) {
    let url = `contaReceber?pagina=${page}&registros=${pageSize}&limiteMaximoRegistros=100`

    let cnpj = removeMaskCNPJ(pesquisa.cnpjPesquisa)

    if (cnpj && cnpj !== '') url = url + `&cnpj=${cnpj}`
    if (pesquisa.titulo && pesquisa.titulo !== '') url = url + `&titulo=${pesquisa.titulo}`
    if (pesquisa.empresaPesquisa && pesquisa.empresaPesquisa !== '') url = url + `&empresa=${pesquisa.empresaPesquisa}`
    if ( pesquisa.fornecedor && pesquisa.fornecedor !== '') url = url + `&fornecedor=${pesquisa.fornecedor}`
    if (pesquisa.dataEntrada && pesquisa.dataEntrada !== '') url = url + `&dataEntrada=${pesquisa.dataEntrada.substr(0, 10)}`
    if (pesquisa.dataEmissao && pesquisa.dataEmissao !== '') url = url + `&dataEmissao=${pesquisa.dataEmissao.substr(0, 10)}`
    if (pesquisa.dataVencimento && pesquisa.dataVencimento !== '') url = url + `&dataVencimento=${pesquisa.dataVencimento.substr(0, 10)}`

    setLoading(true)
    const data = await api.get(url)
    setLoading(false)

    if (!data.success) {
        return
    }

    data.content.registros.forEach(regs => {
        regs.dataEntrada = regs.dataEntrada.substr(0, 10).split('-').reverse().join('/')
        regs.cnpj = maskCNPJ(regs.cnpj)
    })

    actions.getItems(data.content.registros)
    actions.setAllItens({
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas,
        tamanhoPagina: data.content.tamanhoPagina
    })
}

export async function getFiliais(search, page, pageSize) {
    var url= `Pessoa?pagina=${page}&registros=${pageSize}&limiteMaximoRegistros=100`

    if (search.cnpj !== '') url = url + `&cpfCnpj=${removeMaskCNPJ(search.cnpj)}`
    if (search.nome !== '') url = url + `&nome=${search.nome}`

    actions.setLoadingFilial(true)
    const data = await api.get(url);
    actions.setLoadingFilial(false)

    if (!data.success) {
        return;
    }
    actions.setFiliais(data.content.registros);
    actions.setAllItensFilial({
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas
    })
}

export async function getFornecedores(search, page, pageSize) {

    let url = `pessoa/pessoafornecedor?pagina=${page}&registros=${pageSize}&limiteMaximoRegistros=100`

    let cnpj = removeMaskCNPJ(search.cnpj)
    search.filtro = ''

    if (cnpj !== '') url = url + `&cnpj=${cnpj}`
    if (search.nome !== '') url = url + `&nome=${search.nome}`

    actions.setLoadingFornecedor(true)
    const data = await api.get(url)
    actions.setLoadingFornecedor(false)

    if (!data.success) {
        return
    }

    actions.setFornecedores(data.content.registros)
    actions.setAllItensFornecedor({
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas
    })
}

export async function getFavorecidos(search, page, pageSize) {

    let url = `pessoa/pessoafavorecida?pagina=${page}&registros=${pageSize}&limiteMaximoRegistros=100`

    search.filtro = ''
    
    if (search.cpfCnpj && search.cpfCnpj !== '') {
        if(search.cpfCnpj.length === 14 || search.cpfCnpj.length === 18){
            let cnpj = search.cpfCnpj.length === 14 ? removeMaskCPF(search.cpfCnpj) : removeMaskCNPJ(search.cpfCnpj) 
            url = url + `&cpfCnpj=${cnpj}`
        }else{
            return notifyWarn('CPF/CNPJ inválido')
        }
    }
    if (search.nome && search.nome !== '') url = url + `&nome=${search.nome}`
    if (search.filtro && search.filtro !== '') url = url + `&nome=${search.nome}`

    actions.setLoadingFavorecido(true)
    const data = await api.get(url)
    actions.setLoadingFavorecido(false)

    if (!data.success) {
        return
    }

    actions.setFavorecidos(data.content.registros)
    actions.setAllItensFavorecido({
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas
    })
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`contareceber/${id}`)
    setLoading(false)

    if (!data.success) {
        return
    }

    const dataUpdate = {
        id: id,
        empresa: data.content.empresa,
        pessoaId: data.content.pessoaId,
        filial: data.content.filial,
        cnpjFilial: maskCNPJ(data.content.cnpjFilial),
        titulo: data.content.titulo,
        tipoTitulo: data.content.tipoTitulo,
        cnpj: maskCNPJ(data.content.cnpj),
        fornecedor: data.content.fornecedor,
        favorecido: data.content.favorecido,
        valor: maskMoeda(data.content.valor.toFixed(2)),
        formaPagamento: data.content.formaPagamento,
        transacao: data.content.transacao,
        dataEntrada: data.content.dataEntrada.substring(0, 10),
        dataEmissao: data.content.dataEmissao.substring(0, 10),
        dataVencimento: data.content.dataVencimento.substring(0, 10),
        considerarJuros: data.content.consideraJuros,
        percentualJuros: data.content.percentualJuros,
        percentualMulta: data.content.percentualMulta,
        portador: data.content.portador,
        carteira: data.content.carteira,
        observacao: data.content.observacao,
        agencia: data.content.agencia,
        conta: data.content.conta,
        contaFinanceira: data.content.contaFinanceira,
        contaContabil: data.content.contaContabil,
        projeto: data.content.projeto,
        orcamento: data.content.orcamento,
        percentualRateio: data.content.percentualRateio,
        rateio: data.content.rateio,
        centroCusto: data.content.centroCusto,
        valorRateioCentroCusto: data.content.valorRateioCentroCusto,
        percentualCentroCusto: data.content.percentualCentroCusto,
    }

    actions.copy(dataUpdate)
    actions.setItem(dataUpdate)
}

export async function create(item) {

    if (item.filial === '') {
        actions.setInvalid('filial')
        return notifyWarn('Informe a filial')
    }

    if (item.favorecido === '') {
        actions.setInvalid('favorecido')
        return notifyWarn('Informe o favorecido')
    }

    if (item.valor === '') {
        actions.setInvalid('valor')
        return notifyWarn('Informe o valor')
    }

    if (item.formaPagamento === '') {
        actions.setInvalid('formaPagamento')
        return notifyWarn('Informe a forma de pagamento')
    }

    if (item.dataEntrada === '') {
        actions.setInvalid('dataEntrada')
        return notifyWarn('Informe a data de entrada')
    }

    if (item.dataEmissao === '') {
        actions.setInvalid('dataEmissao')
        return notifyWarn('Informe a data de emissão')
    }

    if (item.dataVencimento === '') {
        actions.setInvalid('dataVencimento')
        return notifyWarn('Informe a data de vencimento')
    }

    if (item.consideraJuros) {
        item.consideraJuros = 'S'
        if (item.percentualJuros === '') {
            actions.setInvalid('percentualJuros')
            return notifyWarn('Informe o percentual de juros')
        }

        if (item.percentualMulta === '') {
            actions.setInvalid('percentualMulta')
            return notifyWarn('Informe o percentual de multa')
        }
    } else {
        item.consideraJuros = 'N'
        item.percentualJuros = 0
        item.percentualMulta = 0
    }

    const body = {
        pessoaId: item.pessoaId,
        regraRepasseId: null,
        empresa: item.empresa,
        filial: item.filial,
        cnpjFilial: removeMaskCNPJ(item.cnpjFilial),
        titulo: item.titulo,
        tipoTitulo: item.tipoTitulo,
        fornecedor: item.fornecedor,
        cnpj: removeMaskCNPJ(item.cnpj),
        favorecido: item.favorecido,
        valor: removeMaskMoeda(item.valor),
        formaPagamento: item.formaPagamento,
        transacao: item.transacao,
        dataEmissao: item.dataEmissao,
        dataEntrada: item.dataEntrada,
        dataVencimento: item.dataVencimento,
        consideraJuros: item.considerarJuros,
        juros: '',
        percentualJuros: removeMaskMoeda(item.percentualJuros),
        percentualMulta: removeMaskMoeda(item.percentualMulta),
        percentualDesconto: 0,
        valorDesconto: 0,
        portador: item.portador,
        carteira: item.carteira,
        moeda: "R",
        observacao: item.observacao,
        agencia: item.agencia,
        conta: item.conta,
        contaFinanceira: item.contaFinanceira,
        contaContabil: item.contaContabil,
        projeto: item.projeto,
        orcamento: item.orcamento,
        percentualRateio: item.percentualRateio,
        rateio: item.rateio,
        centroCusto: item.centroCusto,
        percentualCentroCusto: item.percentualCentroCusto,
        valorRateioCentroCusto: item.valorRateioCentroCusto
    }

    setLoading(true)
    const data = await api.post('contareceber', body)
    setLoading(false)

    if (!data.success) {
        return notifyWarn('Não foi possível salvar o registro')
    }

    notifySuccess("Conta a receber cadastrada");
    actions.cleanRegister();
    actions.setActiveTab('Search')
    get({
        cnpj: '',
        titulo: '',
        empresa: '',
        fornecedor: '',
        dataEntrada: '',
        dataEmissao: '',
        dataVencimento: ''
    }, 10, 1);
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        actions.setActiveTab('Search')
        return notifyInfo('Nada foi alterado')
    }

    if (item.filial === '') {
        actions.setInvalid('filial')
        return notifyWarn('Informe a filial')
    }

    if (item.favorecido === '') {
        actions.setInvalid('favorecido')
        return notifyWarn('Informe o favorecido')
    }

    if (item.valor === '') {
        actions.setInvalid('valor')
        return notifyWarn('Informe o valor')
    }

    if (item.formaPagamento === '') {
        actions.setInvalid('formaPagamento')
        return notifyWarn('Informe a forma de pagamento')
    }

    if (item.dataEntrada === '') {
        actions.setInvalid('dataEntrada')
        return notifyWarn('Informe a data de entrada')
    }

    if (item.dataEmissao === '') {
        actions.setInvalid('dataEmissao')
        return notifyWarn('Informe a data de emissão')
    }

    if (item.dataVencimento === '') {
        actions.setInvalid('dataVencimento')
        return notifyWarn('Informe a data de vencimento')
    }

    if (item.consideraJuros) {
        item.consideraJuros = 'S'
        if (item.percentualJuros === '') {
            actions.setInvalid('percentualJuros')
            return notifyWarn('Informe o percentual de juros')
        }


        if (item.percentualMulta === '') {
            actions.setInvalid('percentualMulta')
            return notifyWarn('Informe o percentual de multa')
        }
    } else {
        item.consideraJuros = 'N'
        item.percentualJuros = 0
        item.percentualMulta = 0
    }

    const body = {
        pessoaId: item.pessoaId,
        regraRepasseId: null,
        empresa: item.empresa,
        filial: item.filial,
        cnpjFilial: removeMaskCNPJ(item.cnpjFilial),
        titulo: item.titulo,
        tipoTitulo: item.tipoTitulo,
        fornecedor: item.fornecedor,
        cnpj: removeMaskCNPJ(item.cnpj),
        favorecido: item.favorecido,
        valor: removeMaskMoeda(item.valor),
        formaPagamento: item.formaPagamento,
        transacao: item.transacao,
        dataEmissao: item.dataEmissao,
        dataEntrada: item.dataEntrada,
        dataVencimento: item.dataVencimento,
        consideraJuros: item.considerarJuros,
        juros: '',
        percentualJuros: removeMaskMoeda(item.percentualJuros),
        percentualMulta: removeMaskMoeda(item.percentualMulta),
        percentualDesconto: 0,
        valorDesconto: 0,
        portador: item.portador,
        carteira: item.carteira,
        moeda: "R",
        observacao: item.observacao,
        agencia: item.agencia,
        conta: item.conta,
        contaFinanceira: item.contaFinanceira,
        contaContabil: item.contaContabil,
        projeto: item.projeto,
        orcamento: item.orcamento,
        percentualRateio: item.percentualRateio,
        rateio: item.rateio,
        centroCusto: item.centroCusto,
        percentualCentroCusto: item.percentualCentroCusto,
        valorRateioCentroCusto: item.valorRateioCentroCusto
    }

    setLoading(true)
    const data = await api.put(`contareceber/${item.id}`, body)
    setLoading(false)

    if (!data.success) {
        return notifyWarn('Não foi possível salvar o registro')
    }

    notifySuccess("Conta a receber atualizada");
    actions.cleanRegister();
    actions.setActiveTab('Search')
    get({
        cnpj: '',
        titulo: '',
        empresa: '',
        fornecedor: '',
        dataEntrada: '',
        dataEmissao: '',
        dataVencimento: ''
    }, 10, 1);
}

export async function remove(id) {
    setLoading(true)
    const data = await api.delete(`contareceber?id=${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Conta a pagar removida");
    get({
        cnpj: '',
        titulo: '',
        empresa: '',
        fornecedor: '',
        dataEntrada: '',
        dataEmissao: '',
        dataVencimento: ''
    }, 10, 1);
}