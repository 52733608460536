import moment from 'moment';
export const COLUMNS = [
    {
        Header: "Nome Arquivo",
        accessor: "nomeArquivo",
    }
]

export const COLUMNS_REGISTER = [
    {
        Header: "Banco",
        accessor: "banco",
    },
    {
        Header: "Conta Corrente",
        accessor: "contaCorrente",
    },
    {
        Header: "Centro Custo",
        accessor: "centroCusto",
    },
    {
        Header: "Produto",
        accessor: "produto"
    },
    {
        Header: "Tipo Comissao",
        accessor: "tipoComissao",
    },
    {
        Header: "Loja",
        accessor: "loja",
    },
    {
        Header: "Loja Banco",
        accessor: "codigoLojaBanco",
    },
    {
        Header: "Data Agendamento",
        accessor: "dataVencimento",
        Cell: ({ value }) => {
            return moment(value).format("DD/MM/YYYY");
        },
        sortType: "datetime",

    },
    {
        Header: "Data Emissão",
        accessor: "dataEmissao",
        Cell: ({ value }) => {
            return moment(value).format("DD/MM/YYYY");
        },
        sortType: "datetime",
    },
    {
        Header: "Valor Bruto",
        accessor: "valorBruto",
    },
    {
        Header: "Valor IR",
        accessor: "valorIr",
    },
    {
        Header: "Valor Liquido",
        accessor: "valorLiquido",
    },
    {
        Header: "Conta Espelho",
        accessor: "contaEspelho",
    },
]

