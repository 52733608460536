import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Collapse,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap";
import { useSelector } from "react-redux";

import * as actions from "../../../store/modules/usuario/actions";
import * as controller from "../../../controllers/usuarioController";
import { InputPassword, Select } from "../../../components/Inputs/Input";
import { CheckBoxChangePass } from "../components/input";
import {
  removeAccentsAndUppercase,
  formatarDocumento,
} from "../../../util/masks";

import "./register.css";

const Register = () => {
  const {
    item,
    perfis,
    invalid,
    seeRegister,
    verifyPass,
    seeUpdate,
    changePass,
    passwords,
  } = useSelector((state) => state.usuario);

  const [modalOpen, setModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    number: false,
    upper: false,
    lower: false,
    special: false,
  });

  useEffect(() => {
    controller.getPerfis();
  }, []);

  useEffect(() => {
    if (!modalOpen) {
      actions.cleanRegister();
    }
  }, [modalOpen]);

  async function onChangeItem(value, field) {
    actions.cleanInvalid(field);
    actions.setItem({ ...item, [field]: value });

    if ((value.length === 14 || value.length === 18) && field === "cpf") {
      await controller.getPessoa(value, item);
    }
  }

  function onChangePass(value, field) {
    actions.cleanInvalid(field);
    actions.setPass({ ...passwords, [field]: value });
    if (field === "senha") {
      validatePassword(value);
    }
  }

  const validatePassword = (password) => {
    const validations = {
      length: password.length >= 10,
      number: /\d/.test(password),
      upper: /[A-Z]/.test(password),
      lower: /[a-z]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordValidations(validations);
    return Object.values(validations).every(Boolean);
  };

  const onRePass = (pass, field) => {
    if (field === "reSenha") {
      if (passwords.senha === pass) {
        actions.setVerifyPass({ ...verifyPass, misMatching: false });
      } else {
        actions.setVerifyPass({ ...verifyPass, misMatching: true });
      }
    } else {
      if (passwords.reSenha === pass) {
        actions.setVerifyPass({ ...verifyPass, misMatching: false });
      } else {
        actions.setVerifyPass({ ...verifyPass, misMatching: true });
      }
    }
  };

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  const buttonPassword = () => {
    return (
      <>
        <Row style={{ margin: "40px 0 0 15px" }}>
          <Col md="6">
            <FormGroup>
              <Label>Senha:</Label>
              <InputPassword
                type={showPassword ? "text" : "password"}
                name="senha"
                id="senha"
                placeholder="Digite aqui..."
                value={passwords.senha}
                onChange={(e) => {
                  onChangePass(e.target.value, "senha");
                }}
                disabled={seeRegister}
                invalid={invalid.senha}
              />
              <ul className="password-criteria">
                <li className={passwordValidations.length ? "valid" : ""}>
                  Senha deve conter no mínimo 10 caracteres
                </li>
                <li className={passwordValidations.number ? "valid" : ""}>
                  Senha deve conter no mínimo 1 número
                </li>
                <li className={passwordValidations.upper ? "valid" : ""}>
                  Senha deve conter no mínimo 1 letra maiúscula
                </li>
                <li className={passwordValidations.lower ? "valid" : ""}>
                  Senha deve conter no mínimo 1 letra minúscula
                </li>
                <li className={passwordValidations.special ? "valid" : ""}>
                  Senha deve conter no mínimo 1 caractere especial
                </li>
              </ul>
              <br />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Confirmar Senha:</Label>
              <InputPassword
                type={showConfirmPassword ? "text" : "password"}
                name="reSenha"
                id="reSenha"
                placeholder="Digite aqui..."
                value={passwords.reSenha}
                onChange={(e) => {
                  onChangePass(e.target.value, "reSenha");
                }}
                disabled={seeRegister}
                invalid={invalid.reSenha}
              />
              <br />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <div className="modal">
        <Modal
          isOpen={modalOpen}
          toggle={() => setModalOpen(!modalOpen)}
          backdrop={true}
          onClosed={() => actions.cleanRegister()}
        >
          <ModalHeader
            toggle={() => setModalOpen(!modalOpen)}
            style={{ height: "1px", border: "none" }}
          />
          <ModalBody>{/* Conteúdo do Modal */}</ModalBody>
        </Modal>
      </div>
      <Col>
        <Card className="radius pb-5">
          <Col className="pt-2" style={{ margin: "25px 0 0 25px" }}>
            <p style={{ fontSize: "18px", color: "var(--color-preto)" }}>
              Preencha os campos para cadastrar um novo Usuário
            </p>
          </Col>
          <Col className="pt-2">
            <Form>
              <Row style={{ margin: "40px 0 0 15px" }}>
                <Col md="6">
                  <FormGroup>
                    <Label for="cpf">CPF / CNPJ:</Label>
                    <Input
                      placeholder="Digite aqui..."
                      value={item.cpf}
                      onChange={(e) =>
                        onChangeItem(formatarDocumento(e.target.value), "cpf")
                      }
                      disabled={item.id !== "" ? true : seeRegister}
                      invalid={invalid.cpf}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="nome">Nome:</Label>
                    <Input
                      type="text"
                      name="nome"
                      id="nome"
                      placeholder="Digite aqui..."
                      value={item.nome}
                      onChange={(e) =>
                        onChangeItem(
                          removeAccentsAndUppercase(e.target.value),
                          "nome"
                        )
                      }
                      className={invalid.nome ? "is-invalid" : ""}
                      disabled={item.id !== "" ? true : seeRegister}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ margin: "40px 0 0 15px" }}>
                <Col md="6">
                  <FormGroup>
                    <Label for="email">E-mail:</Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Digite aqui..."
                      value={item.email}
                      onChange={(e) => onChangeItem(e.target.value, "email")}
                      className={invalid.email ? "is-invalid" : ""}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="perfil">Perfil:</Label>
                    <Select
                      first="Selecione"
                      onChange={(e) => onChangeItem(e.value, "perfilAcessoId")}
                      options={perfis}
                      select={{ value: "id", label: "descricao" }}
                      value={{ value: item.perfilAcessoId }}
                      invalid={invalid.perfil}
                      isSearchable={true}
                      disabled={seeRegister}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/* Mostrar inputs de senha se não estiver em modo de atualização */}
              {!seeUpdate
                ? buttonPassword()
                : !seeRegister && (
                    <>
                      <Row style={{ margin: "40px 0 0 15px" }}>
                        <CheckBoxChangePass
                          checked={changePass}
                          isDisabled={seeRegister}
                          onChange={() => actions.setChangePass(!changePass)}
                          label={"Alterar senha do usuário"}
                        />
                      </Row>
                      <Row>
                        <Collapse
                          isOpen={changePass}
                          style={{ width: "90rem" }}
                        >
                          {buttonPassword()}
                        </Collapse>
                      </Row>
                    </>
                  )}
            </Form>
          </Col>
        </Card>
      </Col>
    </>
  );
};

export default Register;
