const STORE_ID = "LOGIN";

const TYPES = {
    CLEAN_INVALID_LOGIN: "CLEAN_INVALID_LOGIN",
    INVALID_LOGIN: "INVALID_LOGIN",
    LOGIN: "LOGIN",
    CLEAN_ALL: "CLEAN_ALL",
    SET_USER_CONTROLL_KEYS: "SET_USER_CONTROLL_KEYS",
    SET_REQUEST: "SET_REQUEST",
    SET_LOADING_LOGIN: "SET_LOADING_LOGIN"
}

export { STORE_ID, TYPES } 