import api from "../util/api";
import * as actions from "../store/modules/vinculoLoja/actions";
import { setLoading } from "../store/modules/globalLoading/actions";
import { notifySuccess, notifyWarn } from "../components/message/message";

function verifyRequiredFields(item) {
  if (item.bancoId === "0") {
    notifyWarn("O campo Banco é um campo obrigatório");
    return false;
  }
  if (item.lojaId === "0") {
    notifyWarn("O campo Loja Arrecadadora é um campo obrigatório");
    return false;
  }
  if (item.contaCorrenteId === "0") {
    notifyWarn("O campo Conta é um campo obrigatório");
    return false;
  }
  if (item.tiposComissaoId === "0" || item.tipoComissaoId === "0") {
    notifyWarn("O campo Tipo Comissão é um campo obrigatório");
    return false;
  }

  return true;
}

export async function create(item) {
  const isAllRequiredFieldsFilled = verifyRequiredFields(item);

  if (!isAllRequiredFieldsFilled) {
    return;
  }

  setLoading(true);
  const data = await api.post("conta-bancaria-loja", {
    ...item
  });
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Cadastrado com sucesso");
  return true;
}

export async function update(item) {
  const isAllRequiredFieldsFilled = verifyRequiredFields(item);

  if (!isAllRequiredFieldsFilled) {
    return;
  }

  setLoading(true);
  const data = await api.put(`conta-bancaria-loja/${item.id}`, {
    ...item
  });
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Atualizado com sucesso");
  return true;
}

export async function remove(id) {
  setLoading(true);
  const data = await api.delete(`conta-bancaria-loja/${id}`);
  setLoading(false);

  if (!data.success) {
    return false;
  }

  notifySuccess("Item deletado com sucesso");
  return true;
}

export async function get(page, pageSize, search) {
  setLoading(true);

  let url = `conta-bancaria-loja?pagina=${page+1}&registros=${pageSize}`;

  if (search) {
    if (search?.bancoId !== "0") url += `&idBanco=${search.bancoId}`;
    if (search?.lojaId !== "0") url += `&idLoja=${search.lojaId}`;
    if (search?.tiposComissaoId !== "0")
      url += `&idTipoComissao=${search.tiposComissaoId}`;
    if (search?.contaCorrenteId !== "0")
      url += `&numeroConta=${search.contaCorrenteId}`;
  }

  const data = await api.get(url);

  setLoading(false);

  if (!data.success) {
    return;
  }

  actions.setItems(data.content.registros);
  actions.getAllItems({ quantidade: data.content.quantidade,
    quantidadeTotal: data.content.quantidadeTotal,
    totalPaginas: data.content.totalPaginas,
    tamanhoPagina: data.content.tamanhoPagina })
}

export async function getById(id) {
  setLoading(true);
  const data = await api.get(`conta-bancaria-loja/${id}`);
  setLoading(false);

  if (!data.success) {
    return;
  }
  const item = data.content;

  await getLojasArrecadadoras(item.bancoId);
  await getTiposComissao(item.bancoId);
  await getContaCorrente(item.lojaId);
  actions.setItem(item);
}

export async function getBancos() {
  const data = await api.get("bancos");

  if (!data.success) {
    return;
  }

  actions.setBancos(data.content);
}

export async function getLojasArrecadadoras(idBanco) {
  const data = await api.get(`lojas/matriz/${idBanco}`);

  if (!data.success) {
    return;
  }

  actions.setLojasArrecadadoras(data.content);
}

export async function getContaCorrente(lojaId) {
  const data = await api.get(`conta-corrente/loja/${lojaId}`);

  if (!data.success) {
    return;
  }

  actions.setContas(data.content);
}

export async function getTiposComissaoNaoVinculados(lojaId, bancoId) {
  const data = await api.get(
    `tipocomissao/sem-vinculo/${lojaId}/conta-banco/${bancoId}`
  );

  if (!data.success) {
    return;
  }

  actions.setTiposComissao(data.content);
}

export async function getTiposComissao(bancoId) {
  const data = await api.get(`TipoComissao/GetByInstituicao?idInstituicao=${bancoId}`);

  if (!data.success) {
    return;
  }

  actions.setTiposComissao(data.content);
}
