import React, { useEffect, useMemo, useState } from 'react'
import { usePagination, useTable } from 'react-table';
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Modal, ModalBody, Col, Button, Input, Label, Row, Navbar, FormGroup } from "reactstrap";

import * as actions from '../../../store/modules/grupos/actions';
import * as controller from '../../../controllers/gruposController';
import { maskCNPJ, maskCPF } from '../../../util/masks';
import LoadingIcon from '../../../components/Loading/LoadingIcon'

export default function ModalPessoa({ modalPessoa, toggle, isJuridica }) {
    const { modal, pessoasPesquisa, loadingModal, getAll } = useSelector(state => state.grupo);

    const columns = useMemo(() => [{
        Header: isJuridica ? 'CNPJ' : 'CPF'
    },{
        Header: isJuridica ? 'Razão Social' : "NOME",
    },],
    // eslint-disable-next-line react-hooks/exhaustive-deps
     []); 
    const data = pessoasPesquisa

    const {
        page,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
       columns,
       data
    },
        usePagination);

    const { pageSize } = state;
    const [rows, setRows] = useState(0);
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    useEffect(() => {
        setRows(page.length);
    }, [page])

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);

        controller.getPessoasModal(isJuridica, modal, pageSelected+1, pageSize,true)
    }

    function handleInitialPage() {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }

    useEffect(() => {
        actions.setPessoasModal([])
    }, [modalPessoa])

    function onChangeItem(value, campo) {
        actions.setPessoa(value, campo)
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            controller.getPessoasModal(isJuridica, modal, 1, pageSize,true);
        }
    }
  
    function handleLimpar() {
      actions.setPessoasModal([])
      actions.setModal('', "cpfCnpj")
      actions.setModal('', "nome")
    }
    useEffect(() =>{
        controller.getPessoasModal(isJuridica, modal,1, pageSize,false)
     // eslint-disable-next-line react-hooks/exhaustive-deps
     },[isJuridica, modal.nome, pageSize])   
  
    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalPessoa}
                toggle={toggle}
                backdrop={true}
                size="lg"
            >
                <Navbar expand="md" style={{ width: "100%", height: "40px", background: 'var(--color-gradiente-verde)', marginBottom: '20px' }}>
                    <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2" >Pesquisa de Pessoas</h5>
                </Navbar>
                <ModalBody >
                    <Row>
                        <Col md={4}>
                            <Label className="float-left">CPF/CNPJ</Label>
                            <Input
                                type="text"
                                placeholder="Digite aqui..."
                                value={modal.cpfCnpj}
                                onChange={e => actions.setModal( isJuridica ? maskCNPJ(e.target.value) : maskCPF(e.target.value), 'cpfCnpj')}
                                onKeyPress={e => handleKeyPress(e)}
                                maxLength="18"
                            />
                        </Col>
                        <Col>
                            <FormGroup>
                            <Label className="float-left">Nome</Label>
                            <Input
                                type="text"
                                placeholder="Digite aqui..."
                                value={modal.nome}
                                onChange={e => actions.setModal(e.target.value, 'nome')}
                                onKeyPress={e => handleKeyPress(e)}
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                    <fieldset className={pessoasPesquisa.length > 0 ? "fieldset-socio" : "fieldset-socio-no-results"}>
                        <legend style={{ textAlign: "left", fontSize: "20px" }}>Resultados</legend>
                        { loadingModal &&
                            <LoadingIcon />
                        }
                        <table>
                            <tr>
                                <th style={{ width: "150px" }}>CPF/CNPJ</th>
                                <th>Nome</th>
                            </tr>
                            {pessoasPesquisa && pessoasPesquisa.map(row => {
                                return (
                                  <tr onDoubleClick={() => { onChangeItem(row.nome, 'nome');onChangeItem(isJuridica ? maskCNPJ(row.cpfCnpj) : maskCPF(row.cpfCnpj, true), 'cpfCnpj'); onChangeItem(row.id, 'idPessoa'); toggle() }}>
                                        <td index={row.id}>{row.cpfCnpj.indexOf('x') === -1 ? (row.cpfCnpj.length === 11 ? maskCPF(row.cpfCnpj): maskCNPJ(row.cpfCnpj)) : maskCPF(row.cpfCnpj, true)}</td>
                                        <td index={row.id}>{row.nome}</td>
                                    </tr>
                                )
                            })}

                        </table>
                        <br />
                    </fieldset>
                    {pessoasPesquisa && <Row className="pt-4">
                            <Col md={3}>
                                <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {rows} de {getAll.quantidadeTotal}</span>
                            </Col>
                            <Col md={8} className="float-right">
                                <Row >
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={getAll.totalPaginas}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { setPageSize(Number(e.target.value)); handleInitialPage();controller.getPessoasModal(isJuridica,modal, 1, e.target.value,true) }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>}
                </ModalBody>
                <div className="m-3">
                    <Button
                        onClick={() => handleLimpar()}
                        className="mr-4"
                        style={{
                            width: "150px",
                            background: "var(--color-branco)",
                            borderColor: "var(--color-laranja)",
                            color: "var(--color-laranja)",
                            borderRadius: "7px"
                        }}
                    >
                        Limpar
                    </Button>
                    <Button
                        onClick={() => controller.getPessoasModal(isJuridica, modal,1, pageSize,true)}
                        color="danger"
                        className="purpleButton"
                        style={{ width: "150px" }}
                    >
                        Pesquisar
                    </Button>
                </div>
            </Modal>
        </>
    )
}