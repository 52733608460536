import moment from 'moment';
export const COLUMNS = [
    {
        Header: "CNPJ",
        accessor: "cnpj"
    },
    {
        Header: "Título",
        accessor: "titulo"
    },
    {
        Header: "Empresa",
        accessor: "empresa"
    },
    {
        Header: "Fornecedor",
        accessor: "fornecedor"
    },
    {
        Header: "Data de Entrada",
        accessor: "dataEntrada",
        Cell: ({ value }) => {
            return moment(value).format("DD/MM/YYYY");
        },
        sortType: "datetime",
    },        
]
