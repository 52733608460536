const STORE_ID = "ANT_CONTAS_RECEBER";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    ANT_CONTA_RECEBER: "ANT_CONTA_RECEBER",
    ANT_CONTA_RECEBER_PRODUTO: "ANT_CONTA_RECEBER_PRODUTO",
    ANT_CONTA_RECEBER_LOJA: "ANT_CONTA_RECEBER_LOJA",
    ANT_CONTA_RECEBER_COPY: "ANT_CONTA_RECEBER_COPY",
    INVALID_ANT_CONTA_RECEBER: "INVALID_ANT_CONTA_RECEBER",
    ANT_CONTAS_RECEBER: "ANT_CONTAS_RECEBER",
    CLEAN_INVALID_ANT_CONTA_RECEBER: "CLEAN_INVALID_ANT_CONTAS_RECEBER",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    ITEM: "ITEM",
    CLEAN_SEARCH: "CLEAN_SEARCH",
    CLEAN_ALL_SEARCH: "CLEAN_ALL_SEARCH",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    SEARCH: "SEARCH",
    ADD_PRODUTO: "ADD_PRODUTO",
    REMOVE_PRODUTO: "REMOVE_PRODUTO",
    ADD_LOJA: "ADD_LOJA",
    REMOVE_LOJA: "REMOVE_LOJA",
    SET_INSTITUICOES: "SET_INSTITUICOES",
    SET_INSTITUICOES_PESQUISA: "SET_INSTITUICOES_PESQUISA",
    SET_PRODUTOS: "SET_PRODUTOS",
    CLEAN_PRODUTOS: "CLEAN_PRODUTOS",
    SET_LOJAS: "SET_LOJAS",
    CLEAN_LOJAS: "CLEAN_LOJAS",
    SET_MODAL_FILIAL: "SET_MODAL_FILIAL",
    SET_FILIAIS: "SET_FILIAIS",
    SET_GET_FILIAL: "SET_GET_FILIAL",
    SET_LOJAS_PESQUISA: "SET_LOJAS_PESQUISA",
    SET_PRODUTOS_PESQUISA: "SET_PRODUTOS_PESQUISA",
    SET_PAGE_SIZE: "SET_PAGE_SIZE",
    SET_QUANTIDADE_TOTAL: "SET_QUANTIDADE_TOTAL",
    SET_TOTAL_PAGINAS: "SET_TOTAL_PAGINAS",
    SET_PAGE_SELECTED: "SET_PAGE_SELECTED"
}

export { STORE_ID, TYPES }