/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import {
  useTable,
  useSortBy,
  usePagination,
} from "react-table";

import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, Col, Button, Input, Label, Row, Navbar } from 'reactstrap'

import * as  divisaoComissaoController from '../../../controllers/divisaoComissaoController'
import * as  divisaoComissaoActions from '../../../store/modules/divisaoComissao/actions'
import { removeAccentsAndUppercase } from '../../../util/masks'
import LoadingIcon from '../../../components/Loading/LoadingIcon'

import * as BsIcons from 'react-icons/bs'

export default function ModalLojaMatriz({ isOpen, toggle }) {
  const { search, modalLojaMatriz, lojasMatrizes, modalLojaMatrizLoading } = useSelector(state => state.divisaoComissao)
  const [isFirstSearch, setIsFirstSearch] = useState(true)

    const columns = useMemo(() => [{
      Header: 'Nome Fantasia',
      accessor: 'nomeFantasia'
    }, {
      Header: 'Nome',
      accessor: 'nome'
    },],[])
    const data = lojasMatrizes ?? []

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      gotoPage,
      state,
      rows,
      setPageSize,
    } = useTable({
        columns,
        data
    },
      useSortBy,
      usePagination,
      usePagination
    )

    const { pageSize } = state
    const [initialPage, setInitialPage] = useState(false)
    const [selectedPage, setSelectedPage] = useState(null)

    const handlePageClick = (e) => {
        const pageSelected = e.selected
        setInitialPage(false)
        setSelectedPage(pageSelected)
        gotoPage(pageSelected)
    }

    function handleInitialPage() {
        setInitialPage(false)
        setSelectedPage(0)
        gotoPage(0)
        divisaoComissaoController.getLojasMatrizes({nomeFantasia: modalLojaMatriz.nome, codLoja: modalLojaMatriz.codLojaMatriz, bancoId: search.banco})
    }

    function onChangeSearch(id, nome) {
        divisaoComissaoActions.setSearch({ ...search, lojaMatrizId: id, lojaMatriz: nome })
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            divisaoComissaoController.getLojasMatrizes({nomeFantasia: modalLojaMatriz.nome, codLoja: modalLojaMatriz.codLojaMatriz, bancoId: search.banco})
        }
    }

    useEffect(() => {
        divisaoComissaoActions.cleanModalLojaMatriz()
    }, [isOpen])
    
    useEffect(() => {
      if (isOpen && isFirstSearch) {
        divisaoComissaoController.getLojasMatrizes({nomeFantasia: modalLojaMatriz.nome, codLoja: modalLojaMatriz.codLojaMatriz, bancoId: search.banco})
        setIsFirstSearch(false)
      }
    }, [isOpen])
  

    return (
        <>
            <Modal
                className='text-center'
                isOpen={isOpen}
                toggle={toggle}
                backdrop
                size='lg'
            >
                <Navbar expand='md' style={{ width: '100%', height: '40px', background: 'var(--color-gradiente-verde)', marginBottom: '20px' }}>
                    <h5 style={{ color: 'white' }} className='ml-auto mr-auto mt-2' >Pesquisa de Lojas Matrizes</h5>
                </Navbar>
                <ModalBody >
                    <Row>
                        <Col md={4}>
                            <Label className='float-left'>Cod. loja</Label>
                            <Input
                                type='text'
                                placeholder='Digite aqui...'
                                value={modalLojaMatriz.codLojaMatriz}
                                onChange={e => divisaoComissaoActions.setModalLojaMatriz('codLojaMatriz', e.target.value)}
                                onKeyPress={e => handleKeyPress(e)}
                            />
                        </Col>
                        <Col>
                            <Label className='float-left' for='nome'>Nome fantasia</Label>
                            <Input
                                type='text'
                                placeholder='Digite aqui...'
                                value={modalLojaMatriz.nome}
                                onChange={e => divisaoComissaoActions.setModalLojaMatriz('nome', removeAccentsAndUppercase(e.target.value))}
                                onKeyPress={e => handleKeyPress(e)}
                            />
                        </Col>
                    </Row>
                    <fieldset className={lojasMatrizes.length > 0 ? 'fieldset-socio' : 'fieldset-socio-no-results'}>
                        <legend style={{ textAlign: 'left', fontSize: '20px' }}>Resultados</legend>
                        {modalLojaMatrizLoading &&
                            <LoadingIcon />
                        }
                      <table {...getTableProps()}>
                          <thead>
                            {headerGroups?.map((headerGroup) => (
                              <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers?.map((column) => (
                                  <th key={column.id}>
                                    {column.render("Header")}
                                    <span className="float-right">
                                      {column.isSorted ? (
                                        column.isSortedDesc ? (
                                          <BsIcons.BsArrowDown
                                            {...column.getHeaderProps(
                                              column.getSortByToggleProps({
                                                title: undefined,
                                              })
                                            )}
                                          />
                                        ) : (
                                          <BsIcons.BsArrowUp
                                            id="OrdenarAsc"
                                            {...column.getHeaderProps(
                                              column.getSortByToggleProps({
                                                title: undefined,
                                              })
                                            )}
                                          />
                                        )
                                      ) : (
                                        <BsIcons.BsArrowDownUp
                                          id="Ordenar"
                                          {...column.getHeaderProps(
                                            column.getSortByToggleProps({
                                              title: undefined,
                                            })
                                          )}
                                        />
                                      )}
                                    </span>
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody {...getTableBodyProps()}>
                            {page?.map((row) => {
                              prepareRow(row);
                              return (
                                <tr className="text-center" {...row.getRowProps()} onDoubleClick={() => {
                                  onChangeSearch(row.original.id, row.nomeFantasia)
                                  toggle()
                                }}>
                                  {row?.cells?.map((cell) => {
                                    return (
                                      <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        <br />
                    </fieldset>
                    {lojasMatrizes && <Row className='pt-4'>
                        <Col md={3}>
                            <span style={{ color: 'var(--color-cinza-medio)' }}>Mostrando {page.length} de {data.length}</span>
                        </Col>
                        <Col className='float-right'>
                            <Row>
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil(rows.length / pageSize)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                forcePage={initialPage ? 0 : selectedPage}
                              />
                              <Input
                                type="select"
                                className="pageSize"
                                value={pageSize}
                                onChange={(e) => {
                                  setPageSize(Number(e.target.value));
                                  handleInitialPage();
                                }}
                              >
                                {[10, 25, 100].map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </Input>
                            </Row>
                        </Col>
                    </Row>}
                </ModalBody>
                <div className='m-3'>
                    <Button
                        onClick={() => {
                            divisaoComissaoActions.setLojasMatrizes([])
                            divisaoComissaoActions.cleanModalLojaMatriz()
                            divisaoComissaoActions.cleanGetAllLojaMatriz()
                        }}
                        className='mr-4'
                        style={{
                            width: '150px',
                            background: 'var(--color-branco)',
                            borderColor: 'var(--color-laranja)',
                            color: 'var(--color-laranja)',
                            borderRadius: '7px'
                        }}
                    >
                        Limpar
                    </Button>
                    <Button
                        onClick={() => divisaoComissaoController.getLojasMatrizes({nomeFantasia: modalLojaMatriz.nome, codLoja: modalLojaMatriz.codLojaMatriz, bancoId: search.banco})}
                        color='danger'
                        className='purpleButton'
                        style={{ width: '150px' }}
                    >
                        Pesquisar
                    </Button>
                </div>
            </Modal>
        </>
    )
}