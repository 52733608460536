import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
  data = { ...data, storeId: STORE_ID };
  store.dispatch(data);
}

export function setPermitions(value) {
  dispatch({
    type: TYPES.SET_PERMITIONS,
    value,
  });
}

export function setPermitionsTabs(label, { visualizar }) {
  dispatch({
    type: TYPES.SET_PERMITIONS_TAB,
    label,
    value: !!visualizar,
  });
}

export function setTab(value) {
  dispatch({
    type: TYPES.SET_TAB,
    value,
  });
}

export function pesquisar(value) {
  dispatch({
    type: TYPES.PESQUISAR,
    value,
  });
}
export function setPropostas(value) {
  dispatch({
    type: TYPES.PROPOSTAS,
    value,
  });
}
export function setItem(value) {
  dispatch({
    type: TYPES.ITEM,
    value,
  });
}

export function setInstituicoes(value) {
  dispatch({
    type: TYPES.INSTITUICOES,
    value,
  });
}

export function setProdutos(value) {
  dispatch({
    type: TYPES.PRODUTOS,
    value,
  });
}

export function setConvenios(value) {
  dispatch({
    type: TYPES.CONVENIOS,
    value,
  });
}
export function setStatus(value) {
  dispatch({
    type: TYPES.STATUS,
    value,
  });
}
export function setTipoOperacao(value) {
  dispatch({
    type: TYPES.TIPO_OPERACAO,
    value,
  });
}
export function setDataEntrega(value) {
  dispatch({
    type: TYPES.DATA_ENTRADA,
    value,
  });
}
export function setDataStatus(value) {
  dispatch({
    type: TYPES.DATA_STATUS,
    value,
  });
}
export function setTipoData(value) {
  dispatch({
    type: TYPES.TIPO_DATA,
    value,
  });
}

export function cleanSearch() {
  dispatch({
    type: TYPES.CLEAN_SEARCH,
  });
}

export function getPropostas(value) {
  dispatch({
    type: TYPES.SET_GET_ALL,
    value,
  });
}

export function cleanRegister() {
  dispatch({
    type: TYPES.CLEAN_REGISTER,
  });
}

export function setActiveTab(value) {
  dispatch({
    type: TYPES.SET_ACTIVE_TAB,
    value,
  });
}

export function setActiveDash(value) {
  dispatch({
    type: TYPES.SET_ACTIVE_DASH,
    value,
  });
}

export function setSeeRegister(value) {
  dispatch({
    type: TYPES.SET_SEE,
    value,
  });
}
export function setFiltros(campo, value) {
  dispatch({
      type: TYPES.SET_FILTROS,
      campo,
      value
  });
}
export function setFiltrosSelecionados(value) {
  dispatch({
      type: TYPES.SET_FILTROS_SELECIONADOS,
      value
  })
}
export function cleanFiltros() {
  dispatch({
    type: TYPES.CLEAN_FILTROS,
  });
}
