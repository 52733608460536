export const COLUMNS = [
    {
        Header: "Ordem",
        accessor: "ordem"
    },
    {
        Header: "Descrição",
        accessor: "descricao"
    },
    {
        Header: "Canal de venda",
        accessor: "canal.nome"
    },
]


