import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

import * as actions from '../../../store/modules/campanhaPremiacao/campanha/actions';
import * as controller from '../../../controllers/premiacaoController/eventosController'

import { Select } from '../../../components/Inputs/Input';
import { maskMoeda } from "../../../util/masks";
import {  AiOutlinePlusCircle } from "react-icons/ai";
import { useEffect, useMemo, useState } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import { COLUMNS_REGRA_QUALIDADE } from "../constants/columns";
import { BsArrowDown, BsArrowDownUp, BsArrowUp } from "react-icons/bs";
import { BiTrash } from "react-icons/bi";
import ReactPaginate from "react-paginate";

export function RegraQualidade(){
    const { regraQualidade, item, seeRegister, tiposPendencia, periodos } = useSelector(state => state.campanhaPremiacao)

    async function handleAddRegra(){
        const isSuccess = await controller.createRegraQualidade(regraQualidade, item.id);
        if(isSuccess.success){
            regraQualidade.id = isSuccess.id
            let tipoPendencia = tiposPendencia.find((tipoPendencia) => tipoPendencia.id === regraQualidade.tipoPendenciaId) ?? ''
            let periodo = periodos.find((periodo) => periodo.id === regraQualidade.periodoId) ?? '' 

            const arrString = JSON.stringify(item.campanhaPremiacaoRegraQualidade)
            const arr = JSON.parse(arrString)
            arr.push({
                ...regraQualidade,
                tipoPendencia: tipoPendencia.descricao,
                periodo: periodo.descricao
            })
            actions.setItem('campanhaPremiacaoRegraQualidade', arr);
            actions.cleanRegraQualidade()
        }
    }

    async function handleDeleteRegra(id, index){
        if(id){
            const isSuccess = await controller.deleteRegraQualidade(id);
            if(isSuccess){
            const arrString = JSON.stringify(item.campanhaPremiacaoRegraQualidade)
            const arr = JSON.parse(arrString)
            arr.splice(index,1)
            actions.setItem('campanhaPremiacaoRegraQualidade', arr)
            }
        }
    }

    return(
        <div>
            { (!seeRegister && !item.hasAproved) && (
            <Row className='pr-5 pl-5 mt-3' style={{ width: '85%'}}>
                <Col md={4}>
                    <FormGroup>
                        <Label for='apuracao'>Tipo de pendência <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                        <Select
                            first='Selecione'
                            value={{ value: regraQualidade.tipoPendenciaId }}
                            select={{ value: 'id', label: 'descricao' }}
                            options={tiposPendencia}
                            onChange={e => actions.setRegraQualidade('tipoPendenciaId', e.value)}
                            isSearchable={true}
                            disabled={seeRegister}
                            autoComplete='off'
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label for='apuracao'>Período <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                        <Select
                            first='Selecione'
                            value={{ value: regraQualidade.periodoId }}
                            select={{ value: 'id', label: 'descricao' }}
                            options={periodos}
                            onChange={e => actions.setRegraQualidade('periodoId', e.value)}
                            isSearchable={true}
                            disabled={seeRegister}
                            autoComplete='off'
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <Label>Valor máximo % <span style={{color: 'var(--color-laranja)', fontSize: '16px'}}>*</span></Label>
                    <FormGroup>
                        <Input
                            type='text'
                            placeholder='Digite aqui...'
                            value={regraQualidade.valorMaximo}
                            onChange={e => actions.setRegraQualidade('valorMaximo', maskMoeda(e.target.value).replaceAll(',', '.') > 100 ? "100,00" : maskMoeda(e.target.value))}
                            disabled={seeRegister}
                            maxLength='6'
                        />
                    </FormGroup>
                </Col>
                {
                   !seeRegister && !item.usuarioAprovadorId && (
                        <button
                            style={{ border: 'none', background: 'transparent', margin: '5px 0 0 0', color: 'var(--color-preto)' }}
                            onClick={(e) => handleAddRegra()}>
                            <AiOutlinePlusCircle
                                style={{ color: 'var(--color-verde)', marginRight: '5px', fontSize: '20px' }}
                            />
                        </button>
                    )
                }
            </Row>
            )}

            <TabelaRegraQualidade content={item.campanhaPremiacaoRegraQualidade} handleDeleteRegra={handleDeleteRegra}/>
        </div>
    )
}

function TabelaRegraQualidade({content, handleDeleteRegra}){
    const { item, seeRegister } = useSelector(state => state.campanhaPremiacao)
    const columns = useMemo(() => COLUMNS_REGRA_QUALIDADE, []);
    let data = content;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
        rows
    } = useTable({
        columns,
        data
    },
        useSortBy,
        usePagination);

    const {  pageSize } = state;
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    useEffect(()=>{
        setPageSize(10)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
    }


    function handleInitialPage() {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }
    const pageCount = Math.ceil(rows.length / pageSize);
    return(
        <div style={{padding: '30px',width: '80%'}} className='pr-5 pl-5 mt-3'>
        <div style={{overflowX: "auto"}}>
        <table {...getTableProps()}>
        <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th style={column.id === 'metaGlobal' ?  {background: '#22c071', color: 'white', height: '40px', border: '1px solid #b9cbdb', padding: '10px', textAlign: 'center', minWidth: '150px'}:
                            {background: '#22c071', color: 'white', height: '40px', border: '1px solid #b9cbdb', padding: '10px', textAlign: 'center', minWidth: '250px'}}>
                                {column.render("Header")}
                                <span className="float-right">
                                    {column.isSorted ?
                                        (column.isSortedDesc ?
                                            <BsArrowDown
                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                            />
                                            :
                                            <BsArrowUp
                                                id="OrdenarAsc"
                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                            />)
                                        :
                                        <BsArrowDownUp
                                            id="Ordenar"
                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                        />}
                                </span>
                            </th>
                        ))}
                        <th style={{background: '#22c071', color: 'white', height: '40px', border: '1px solid #b9cbdb', padding: '10px', textAlign: 'center'}}>
                            Ações
                 </th>
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                    prepareRow(row)
                    return (
                        <tr key={`tableRegraProdutividade${index}`} style={{ height: '40px', border: '1px solid #b9cbdb', padding: '10px', textAlign: 'center'}} {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (<td {...cell.getCellProps()} style={{ textAlign: "center"}}>{cell.render("Cell")}</td>)
                            })}
                            <td style={{ textAlign: "center" }}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    
                                        <>
                                            {
                                               !item.usuarioAprovadorId && !seeRegister && (
                                                <BiTrash
                                                style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA" }}
                                                onClick={() => handleDeleteRegra(row.original.id, index)}
                                                disabled={seeRegister}
                                                />
                                                )
                                            }
                                        </>
                                </div>                               
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
        </div>
        <Row className="pt-4">
            <Col>
            <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {page.length} de {content.length}</span>
            </Col>
            <Col md={8}>
                <Row className="float-right">
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        initialPage={0}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={initialPage ? 0 : selectedPage}
                    />
                    <Input
                        type="select"
                        className="pageSize"
                        value={pageSize}
                        onChange={e => { setPageSize(Number(e.target.value)); handleInitialPage() }}>
                        {
                            [ 10, 25, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))
                        }
                    </Input>
                </Row>
            </Col>
        </Row>
        </div>
    )
}