import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import * as BiIcons from "react-icons/bi"
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import ReactPaginate from "react-paginate";
import {useTable, useSortBy, usePagination, useGlobalFilter} from "react-table";
import {Button, Card, Col, Input, Label, Modal, ModalHeader, ModalBody, Row, Form, FormGroup} from "reactstrap";
import {Select, Switch} from '../../../components/Inputs/Input'
import {COLUMNS} from "../constants/columns";
import * as actions from '../../../store/modules/comissao/actions'
import * as  comissaoController from "../../../controllers/comissaoController";

const Search = () => {
    const {
        pesquisar,
        comissoes,
        getAllItems,
        instituicoes,
        produtosRiber,
        convenios,
        tiposOperacao,
        permitions
    } = useSelector(state => state.comissao);
    const columns = useMemo(() => COLUMNS, []);
    const data = comissoes;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
            columns,
            data
        },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const {pageSize} = state;
    const [itemSelected, setItem] = useState(null);
    const [modalDelete, setModalDelete] = useState(false);
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    useEffect(() => {
        comissaoController.getConvenios();
        comissaoController.getInstituicoes();
        comissaoController.getProdutos();
        comissaoController.getProdutosRiber();
        comissaoController.getTiposOperacao();
    }, [])

    useEffect(() => {
        if (getAllItems.tamanhoPagina) {
            setPageSize(getAllItems.tamanhoPagina);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllItems])

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        comissaoController.get(pesquisar, pageSize, pageSelected + 1)
    }


    async function handleUpdate(id) {
        await comissaoController.getById(id);
        actions.setActiveTab("Register");
    }

    function seeRegister(id) {
        actions.setSeeRegister(true);
        handleUpdate(id);

    }

    function handleDelete(id) {
        setItem(id);
        toggleModalDelete();
    }

    function toggleModalDelete() {
        setModalDelete(!modalDelete);
    }

    function deleteComissao() {
        comissaoController.remove(itemSelected);
    }

    function handleInitialPage(pageSize) {
        const pageSelected = 0;
        setInitialPage(true);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        comissaoController.get(pesquisar, pageSize, pageSelected + 1)
    }

    function onChange(value, field) {
        if (field === 'dataFimVigencia') {
            actions.pesquisar({...pesquisar, ['statusTabela']: false, [field]: value});
        } else {
            actions.pesquisar({...pesquisar, [field]: value});
        }
    }

    function handleInstituicaoFinanceiraChange(inputValue) {
        actions.pesquisar({...pesquisar, codConvenio: '0', codInstituicao: inputValue});
    }

    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalDelete}
                toggle={toggleModalDelete}
                backdrop={false}
            >
                <ModalHeader toggle={toggleModalDelete} style={{height: '10px', border: 'none'}}/>
                <ModalBody>
                    <p style={{fontSize: '18px', color: 'var(--color-preto)'}}>Tem certeza que deseja <strong
                        style={{fontSize: '17px'}}>remover</strong> esse item?</p>
                </ModalBody>
                <div className="m-3">
                    <Button onClick={() => toggleModalDelete()} color="danger"
                            style={{width: "100px", marginRight: "15px",}}> Cancelar </Button>
                    <Button onClick={() => {
                        deleteComissao();
                        toggleModalDelete()
                    }} style={{
                        width: "100px",
                        marginLeft: "15px",
                        background: 'var(--color-verde)',
                        border: 'none'
                    }}> Confirmar </Button>
                </div>
            </Modal>

            <Col>
                <Card className="pt-3 pb-2 mb-5">
                    <Col>
                        <div style={{width: "25%"}} className="pb-3">
                            <Label style={{
                                fontWeight: '400',
                                fontSize: '18px',
                                margin: '5px 0 8px 15px'
                            }}>Pesquisar</Label>
                        </div>
                        <Form style={{margin: '0px 0px 15px 15px'}}>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Descrição</Label>
                                        <Input
                                            type="text"
                                            placeholder="Digite aqui..."
                                            value={pesquisar.descricao}
                                            onChange={e => onChange(e.target.value, 'descricao')}
                                            style={{height: '35px'}}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Instituição Financeira</Label>
                                        <Select
                                            first="SELECIONE"
                                            value={{value: pesquisar.codInstituicao}}
                                            select={{value: "id", label: "nome"}}
                                            options={instituicoes}
                                            onChange={(e) => handleInstituicaoFinanceiraChange(e.value)}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Produto Riber</Label>
                                        <Select
                                            first="SELECIONE"
                                            value={{value: pesquisar.produtoRiberId}}
                                            select={{value: "id", label: "nome"}}
                                            options={produtosRiber}
                                            onChange={(e) => onChange(e.value, 'produtoRiberId')}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Convênio Riber</Label>
                                        <Select
                                            first="SELECIONE"
                                            value={{value: pesquisar.codConvenio}}
                                            select={{value: "id", label: "descricao"}}
                                            options={convenios}
                                            onChange={(e) => onChange(e.value, 'codConvenio')}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Tipo de Operação Riber</Label>
                                        <Select
                                            first="SELECIONE"
                                            value={{value: pesquisar.codOperacao}}
                                            select={{value: "id", label: "descricao"}}
                                            options={tiposOperacao}
                                            onChange={(e) => onChange(e.value, 'codOperacao')}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>De</Label>
                                        <Input
                                            type="date"
                                            value={pesquisar.dataInicioVigencia}
                                            max="9999-12-31"
                                            onChange={e => onChange(e.target.value, 'dataInicioVigencia')}
                                            style={{height: '35px'}}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup className="papel">
                                        <Label>Até</Label>
                                        <Input
                                            type="date"
                                            value={pesquisar.dataFimVigencia}
                                            max="9999-12-31"
                                            onChange={e => onChange(e.target.value, 'dataFimVigencia')}
                                            style={{height: '35px'}}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                        <Label>Status Tabela: {pesquisar.statusTabela ? 'ATIVO' : 'INATIVO'}</Label>
                                        <Switch
                                            className="switch"
                                            checked={pesquisar.statusTabela}
                                            onChange={() => onChange(!pesquisar.statusTabela, 'statusTabela')}
                                            disabled={pesquisar.dataFimVigencia !== '' ? true : false}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Button className="mr-4"
                                            style={{
                                                width: "150px",
                                                background: "var(--color-branco)",
                                                borderColor: "var(--color-laranja)",
                                                color: "var(--color-laranja)",
                                                borderRadius: "7px"
                                            }}
                                            onClick={actions.cleanSearch}>
                                        Limpar
                                    </Button>
                                    <Button
                                        className="purpleButton"
                                        style={{width: "150px"}}
                                        onClick={() => {
                                            comissaoController.get(pesquisar, pageSize, 1);
                                            gotoPage(0);
                                            setInitialPage(true);
                                            setSelectedPage(0);
                                        }}>
                                        Buscar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        <table {...getTableProps()}>
                            <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th>
                                            {column.render("Header")}
                                            <span className="float-right">
                                                    {column.isSorted ?
                                                        (column.isSortedDesc ?
                                                            <BsIcons.BsArrowDown
                                                                {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
                                                            />
                                                            :
                                                            <BsIcons.BsArrowUp
                                                                id="OrdenarAsc"
                                                                {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
                                                            />)
                                                        :
                                                        <BsIcons.BsArrowDownUp
                                                            {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
                                                        />}
                                                </span>
                                        </th>
                                    ))}
                                    <th style={{textAlign: "center"}}>
                                        Ações
                                    </th>
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <tr className="text-center" {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                        })}
                                        <td style={{textAlign: "center", width: "10%"}}>
                                            <>
                                                {
                                                    permitions.criar && (
                                                        <BiIcons.BiEdit
                                                            style={{
                                                                fontSize: "1.2rem",
                                                                cursor: "pointer",
                                                                color: "#C4C7CA",
                                                                marginRight: "7px"
                                                            }}
                                                            onClick={() => handleUpdate(row.original.id)}
                                                        />
                                                    )
                                                }
                                                <AiIcons.AiOutlineEye
                                                    style={{
                                                        fontSize: "1.2rem",
                                                        cursor: "pointer",
                                                        color: "#C4C7CA",
                                                        marginRight: "7px"
                                                    }}
                                                    onClick={() => seeRegister(row.original.id)}
                                                />
                                                {
                                                    permitions.deletar && (
                                                        <BiIcons.BiTrash
                                                            style={{
                                                                fontSize: "1.2rem",
                                                                cursor: "pointer",
                                                                color: "#C4C7CA"
                                                            }}
                                                            onClick={() => handleDelete(row.original.id)}
                                                        />
                                                    )
                                                }
                                            </>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        <Row className="pt-4">
                            <Col>
                                <span
                                    style={{color: "var(--color-cinza-medio)"}}>Mostrando {getAllItems.quantidade !== undefined ? getAllItems.quantidade : "0"} de {getAllItems.quantidadeTotal !== undefined ? getAllItems.quantidadeTotal : "0"}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={getAllItems.totalPaginas}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => {
                                            handleInitialPage(e.target.value);
                                            setPageSize(Number(e.target.value))
                                        }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Card>
            </Col>
        </>
    );
}


export default Search;
