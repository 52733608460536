import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    search: {
        nomeUsuario: "",
        descricao: "0"
    },
    gruposId: [],
    usuarioLogin: [],
    item: {
        usuarioLogin: [],
        idGrupo: "0",
        id: "",
    },
    copy: {},
    invalid: {
        id: false,
    },
    getAllItems: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'

    },
    items: [],
    activeTab: "Search",
    seeRegister: false,
    checkUsuarios: false,
    usuariosSearch: [],
    usuariosAssociadosSearch: [],
    loadingUsuarios: false
}

export default function assocUsuarioGrupo(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_ALL_ITEMS:
                    return { ...state, getAllItems: action.value }
                case TYPES.ASSOC_USUARIO_GRUPOS:
                    return { ...state, items: action.value }
                case TYPES.ASSOC_USUARIO_GRUPO:
                    if (action.value.id !== "" && action.value.idGrupo === undefined) {
                        let aux = action.value.id
                        action.value["idGrupo"] = aux
                    }
                    return { ...state, item: action.value }
                case TYPES.SET_GRUPOS:
                    return { ...state, gruposId: action.value }
                case TYPES.INVALID_ASSOC_USUARIO_GRUPO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_ASSOC_USUARIO_GRUPO:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.CLEAN_ALL_SEARCH:
                    return {
                        ...state,
                        search: INITIAL_STATE.search,
                        items: INITIAL_STATE.items,
                        copy: INITIAL_STATE.copy,
                        getAllItems: INITIAL_STATE.getAllItems
                    }
                case TYPES.GET_ALL_ITEMS:
                    return {
                        ...state,
                        getAllItems: action.value
                    }
                case TYPES.ASSOC_USUARIO_GRUPO_COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state,
                        item: {
                            usuarioLogin: [],
                            idGrupo: "0",
                            id: "",
                        },
                        invalid: INITIAL_STATE.invalid,
                        checkUsuarios: INITIAL_STATE.checkUsuarios
                    }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                case TYPES.SET_USUARIOS:
                    return { ...state, usuarioLogin: action.value }
                case TYPES.SET_USUARIO:
                    return {
                        ...state, item: {
                            ...state.item,
                            usuarioLogin: action.value
                        }
                    }
                case TYPES.SEARCH:
                    return {
                        ...state,
                        search: action.value
                    }
                case TYPES.SET_CHECK_USUARIOS:
                    return { ...state, checkUsuarios: action.value }
                case TYPES.SET_USUARIOS_SEARCH:
                    return { ...state, usuariosSearch: action.value }
                case TYPES.SET_USUARIOS_ASSOCIADOS_SEARCH:
                    return { ...state, usuariosAssociadosSearch: action.value }
                case TYPES.SET_LOADING_USUARIOS:
                    return { ...state, loadingUsuarios: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}