const STORE_ID = 'CONTA_GERENCIAL';

const TYPES = {
    SET_ITEM: 'SET_ITEM',
    CLEAN_PRODUTO_CONTA_GERENCIAL: 'CLEAN_PRODUTO_CONTA_GERENCIAL',
    SET_PRODUTO_CONTA_GERENCIAL: 'SET_PRODUTO_CONTA_GERENCIAL',
    CLEAN_DESCRICAO_CONTA_GERENCIAL: 'CLEAN_DESCRICAO_CONTA_GERENCIAL',
    SET_DESCRICAO_CONTA_GERENCIAL: 'SET_DESCRICAO_CONTA_GERENCIAL',
    SET_TIPOS_COMISSAO_CONTA_GERENCIAL: 'SET_TIPOS_COMISSAO_CONTA_GERENCIAL',
    SET_PRODUTOS_RIBER_CONTA_GERENCIAL: 'SET_PRODUTOS_RIBER_CONTA_GERENCIAL',
    SET_ACTIVE_TAB_CONTA_GERENCIAL: 'SET_ACTIVE_TAB_CONTA_GERENCIAL',
    SET_PERMITIONS_CONTA_GERENCIAL: 'SET_PERMITIONS_CONTA_GERENCIAL',
    SET_SEE_REGISTER_CONTA_GERENCIAL: 'SET_SEE_REGISTER_CONTA_GERENCIAL',
    SET_CONTAS_GERENCIAIS: 'SET_CONTAS_GERENCIAIS',
    SET_IS_PRODUTO: 'SET_IS_PRODUTO',
    SET_IS_DESCRICAO: 'SET_IS_DESCRICAO',
    SET_GET_ALL: 'SET_GET_ALL',
    CLEAN_CONTA_GERENCIAL: 'CLEAN_CONTA_GERENCIAL',
    SET_IS_DESPESA: 'SET_IS_DESPESA',
    CONTA_GERENCIAL_COPY: 'CONTA_GERENCIAL_COPY',
    SET_TIPO_IS_PRODUTO: 'SET_TIPO_IS_PRODUTO',
    SET_IS_UPDATE: 'SET_IS_UPDATE',
    SET_IS_CREATE: 'SET_IS_CREATE',
    SET_TIPOS_CONTA: 'SET_TIPOS_CONTA',
    SET_SEARCH: 'SET_SEARCH',
    CLEAN_SEARCH: 'CLEAN_SEARCH',
}

export { STORE_ID, TYPES }