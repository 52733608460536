import React from 'react'
import { BsArrowUp, BsArrowDown } from 'react-icons/bs'

import { maskMoeda } from '../../../../util/masks'

export function BigNumber({element, type}){
    if(element.crescimento > 0){
        return(
        <div style={{background: 'rgba(151, 224, 79, 0.25)',  borderRadius: '20px',width: '100%', maxWidth: '500px',  height: '130px', marginRight: '20px', padding: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '20px'}}>
            <div style={{display: 'flex', gap: '20px', width: '100%', justifyContent: 'center' , alignItems: 'start'}}>
                <div>
                    <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                        {`R$${maskMoeda(Number(element.valor).toFixed(2))}`}
                    </p>
                    <span style={{fontSize: '11px'}}>{type === 'comissaoRecebida' ? 'Total Comissão Recebida' : 'Total Comissão Prevista'}</span>
                </div>
                <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                {`(${element.qtde})`}
                </p>
                <p style={{textAlign: 'center', color: 'var(--color-verde-claro)', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                    <BsArrowUp style={{fontSize: '20px'}}/> {`${element.crescimento}%`}
                </p>
            </div>
            <div style={{display: 'flex', gap: '20px', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <div>
                    <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                        {`${maskMoeda(Number(element.media).toFixed(2))}%`}
                    </p>
                    <span style={{fontSize: '11px'}}>
                    %Comissão Média {' '}{type === 'comissaoRecebida' ? 'Recebida':  'Prevista'}
                    </span>
                </div>
                <div>
                    <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                        {`R$${maskMoeda(Number(element.totalPago).toFixed(2))}`}
                    </p>
                    <span style={{fontSize: '11px'}}>
                      Total Produção {' '} {type === 'comissaoRecebida' ? 'Paga':  'Prevista'}
                    </span>
                </div>

            </div>
        </div>
        )
    }
    return(
                <div style={{background: 'rgba(253, 105, 130, 0.2)',  borderRadius: '20px',width: '100%',maxWidth: '500px',height: '130px', marginRight: '20px', padding: '15px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px'}}>
                    <div style={{display: 'flex', gap: '20px', width: '100%', justifyContent: 'center', alignItems: 'start'}}>
                        <div>
                            <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                                {`R$${maskMoeda(Number(element.valor).toFixed(2))}`}
                            </p>
                            <span style={{fontSize: '11px'}}>{type === 'comissaoRecebida' ? 'Total Comissão Recebida' : 'Total Comissão Prevista'}</span>
                        </div>
                        <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                        {`(${element.qtde})`}
                        </p>
                        <p style={{textAlign: 'center', color: '#F43656', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                            <BsArrowDown style={{fontSize: '20px'}}/> {`${element.crescimento}%`}
                        </p>
                    </div>
                    <div style={{display: 'flex', gap: '20px', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                    <div>
                        <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                            {`${maskMoeda(Number(element.media).toFixed(2))}%`}
                        </p>
                        <span style={{fontSize: '11px'}}>
                            %Comissão Média{' '}{type === 'comissaoRecebida' ? 'Recebida':  'Prevista'}
                        </span>
                    </div>
                    <div>
                        <p style={{textAlign: 'center', fontWeight: '700', fontsize: '18px', marginBottom: '3px'}}>
                            {`R$${maskMoeda(Number(element.totalPago).toFixed(2))}`}
                        </p>
                        <span style={{fontSize: '11px'}}>
                        Total Produção {' '} {type === 'comissaoRecebida' ? 'Paga':  'Prevista'}
                        </span>
                    </div>

                    </div>
                </div>
        )
}