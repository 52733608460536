import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    password: {
        currentPassword: "",
        newPassword: "",
        checkPassword: ""
    },
    invalid: {
        currentPassword: false,
        newPassword: false,
        checkPassword: false
    },
    validPassword: false,
}

export default function alterarSenha(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SENHA:
                    return { ...state, password: action.value }
                case TYPES.VALID_SENHA:
                    return { ...state, validPassword: action.value }
                case TYPES.INVALID_SENHA:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_SENHA:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.CLEAN_REGISTER:
                    return {
                        ...state, password: {
                            currentPassword: "",
                            newPassword: "",
                            checkPassword: ""
                        }, invalid: INITIAL_STATE.invalid
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}