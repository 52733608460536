import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
  data = { ...data, storeId: STORE_ID };
  store.dispatch(data);
}

export function setPermitions(value) {
  dispatch({
    type: TYPES.SET_PERMITIONS,
    value,
  });
}

export function setActiveTab(value) {
  dispatch({
    type: TYPES.SET_ACTIVE_TAB,
    value,
  });
}

export function setSearch(field, value) {
  dispatch({
    type: TYPES.SEARCH,
    field,
    value,
  });
}

export function setLojas(value) {
  dispatch({
    type: TYPES.SET_LOJAS,
    value,
  });
}

export function setGetAllLojas(value) {
  dispatch({
    type: TYPES.SET_ALL_LOJA,
    value,
  });
}
export function setLojasMatrizes(value) {
  dispatch({
    type: TYPES.SET_LOJAS_MATRIZES,
    value,
  });
}
export function setAllCanal(value) {
  dispatch({
    type: TYPES.SET_CANAL_ID,
    value,
  });
}

export function setCanaisAll(value) {
  dispatch({
    type: TYPES.SET_CANAL_ALL,
    value,
  });
}

export function setAllContaCorrente(value) {
  dispatch({
    type: TYPES.SET_All_CONTA_CORRENTE,
    value,
  });
}

export function setInstituicoes(value) {
  dispatch({
    type: TYPES.SET_INSTITUICOES,
    value,
  });
}

export function setCentroscusto(value) {
  dispatch({
    type: TYPES.SET_CENTROS_CUSTO,
    value,
  });
}

export function setTiposComissao(value) {
  dispatch({
    type: TYPES.SET_TIPOS_COMISSAO,
    value,
  });
}

export function setContasBancaria(value) {
  dispatch({
    type: TYPES.SET_CONTAS_BANCARIA,
    value,
  });
}

export function setComissoesAgrupado(value) {
  dispatch({
    type: TYPES.SET_COMISSOES_AGRUPADO,
    value,
  });
}

export function setAllAgrupado(value) {
  dispatch({
    type: TYPES.SET_ALL_AGRUPADO,
    value,
  });
}

export function setComissoes(value) {
  dispatch({
    type: TYPES.SET_COMISSOES,
    value,
  });
}

export function setDownload(value) {
  dispatch({
    type: TYPES.SET_DOWNLOAD,
    value,
  });
}

export function setDownloadCP(value) {
  dispatch({
    type: TYPES.SET_DOWNLOAD_CP,
    value,
  });
}

export function setAll(value) {
  dispatch({
    type: TYPES.SET_ALL,
    value,
  });
}

export function cleanSearch() {
  dispatch({
    type: TYPES.CLEAN_SEARCH,
  });
}

export function cleanAllSearch() {
  dispatch({
    type: TYPES.CLEAN_ALL_SEARCH,
  });
}

export function setStatusComissaoRecebida(value) {
  dispatch({
    type: TYPES.SET_STATUS_COMISSAO_RECEBIDA,
    value,
  });
}

export function setIntegrado(value) {
  dispatch({
    type: TYPES.INTEGRADO,
    value,
  });
}
