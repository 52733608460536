const STORE_ID = "FORMALIZCAOMATRIZ";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    PESQUISAR: 'PESQUISAR',
    SET_ALL_ITEMS: 'SET_ALL_ITEMS',
    ITEM: 'ITEM',
    MODAL: 'MODAL',
    SET_ITEM: 'SET_ITEM',
    SET_IMPRESSAO: 'SET_IMPRESSAO',
    SET_ETIQUETA: 'SET_ETIQUETA',
    SET_FORMALIZACOES: 'SET_FORMALIZACOES',
    SET_INSTITUICOES: 'SET_INSTITUICOES',
    SET_DESTINATARIOS: 'SET_DESTINATARIOS',
    SET_LOJAS: 'SET_LOJAS',
    CLEAN_SEARCH: 'CLEAN_SEARCH',
    CLEAN_REGISTER: 'CLEAN_REGISTER',
    CLEAN_ETIQUETA: 'CLEAN_ETIQUETA',
    CLEAN_IMPRESSAO: 'CLEAN_IMPRESSAO',
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    GET_ALL_ITEMS: "GET_ALL_ITEMS",
}

export { STORE_ID, TYPES }