import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    item: {
        id: '',
        descricao: '',
        bancoId: '0',
        pendenciaRiber: '0',
    },
    copy: {},
    invalid: {
        descricao: false,
        bancoId: false,
        pendenciaRiber: false
    },
    items: [],
    bancos: [],
    pendenciasRiber: [],
    activeTab: 'Search',
    seeRegister: false,
    permitions: {
        criar: false,
        deletar: false,
    }
}

export default function pendenciasInstituicao(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.PENDENCIAS:
                    return { ...state, items: action.value }
                case TYPES.PENDENCIA:
                    return { ...state, item: action.value }
                case TYPES.SET_PENDENCIA:
                    return { ...state, item: {...state.item, [action.field]: action.value} }
                case TYPES.BANCOS:
                    return { ...state, bancos: action.value }
                case TYPES.PENDENCIAS_RIBER:
                    return { ...state, pendenciasRiber: action.value }
                case TYPES.INVALID_PENDENCIA:
                    return { ...state, invalid: { ...state.invalid, [action.field]: true } }
                case TYPES.CLEAN_INVALID_PENDENCIA:
                    return { ...state, invalid: { ...state.invalid, [action.field]: false } }
                case TYPES.PENDENCIA_COPY:
                    return { ...state, copy: action.value }
                case TYPES.CLEAN_REGISTER:
                    return { ...state, item: INITIAL_STATE.item, invalid: INITIAL_STATE.invalid }
                case TYPES.SET_ACTIVE_TAB:
                    return { ...state, activeTab: action.value }
                case TYPES.SET_SEE:
                    return { ...state, seeRegister: action.value }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}