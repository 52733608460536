import { STORE_ID, TYPES } from './types'
import store from '../../index'

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data)
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function pesquisar(value) {
    dispatch({
        type: TYPES.PESQUISAR,
        value
    })

}

export function setComissoes(value) {
    dispatch({
        type: TYPES.COMISSOES,
        value
    })

}

export function setProdutosInstituicao(value) {
    dispatch({
        type: TYPES.SET_PRODUTOS_INSTITUICAO,
        value
    })

}

export function setConveniosInstituicao(value) {
    dispatch({
        type: TYPES.SET_CONVENIOS_INSTITUICAO,
        value
    })

}

export function setTiposInstituicao(value) {
    dispatch({
        type: TYPES.SET_TIPO_INSTITUICAO,
        value
    })

}

export function setRegistros(value) {
    dispatch({
        type: TYPES.REGISTROS,
        value
    })
}

export function setEventos(value) {
    dispatch({
        type: TYPES.EVENTOS,
        value
    })
}

export function setCopia(value) {
    dispatch({
        type: TYPES.COPIA,
        value
    })
}

export function setInstituicoes(value) {
    dispatch({
        type: TYPES.INSTITUICOES,
        value
    })
}

export function setProdutos(value) {
    dispatch({
        type: TYPES.PRODUTOS,
        value
    })
}

export function setAllItems(value) {
    dispatch({
        type: TYPES.SET_ALL_ITEMS,
        value
    })
}

export function setProdutosRiber(value) {
    dispatch({
        type: TYPES.PRODUTOS_RIBER,
        value
    })
}

export function setConvenios(value) {
    dispatch({
        type: TYPES.CONVENIOS,
        value
    })
}

export function setTiposOperacao(value) {
    dispatch({
        type: TYPES.TIPOS_OPERACAO,
        value
    })
}

export function setTiposComissao(value) {
    dispatch({
        type: TYPES.TIPOS_COMISSAO,
        value
    })
}

export function setTiposComissaoBanco(value) {
    dispatch({
        type: TYPES.TIPOS_COMISSAO_BANCO,
        value
    })
}

export function cleanTiposComissaoBanco() {
    dispatch({
        type: TYPES.CLEAN_TIPOS_COMISSAO_BANCO,
    })
}

export function cleanSearch() {
    dispatch({
        type: TYPES.CLEAN_SEARCH
    })
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function cleanEventos(value) {
    dispatch({
        type: TYPES.CLEAN_EVENTOS,
        value
    })
}
export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function setTiposOperacaoCadastro(value) {
    dispatch({
        type: TYPES.OPERACOES_CADASTRO,
        value
    })
}

export function setTiposConveniosCadastro(value) {
    dispatch({
        type: TYPES.CONVENIOS_CADASTRO,
        value
    })
}

export function setProdutosCadastro(value) {
    dispatch({
        type: TYPES.PRODUTOS_CADASTRO,
        value
    })
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID,
        field
    })
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID,
        field
    })
}

