import React, { useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import {  Card, Col, Input, Label, Row } from "reactstrap";
import { useSelector } from "react-redux";
import * as  controller from "../../../controllers/agrupamentoPropostasCentroCustoController";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { COLUMNS } from "../constants/columns";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as  actions from "../../../store/modules/agrupamentoPropostaCentroCusto/actions";
import GlobalFilter from "../../../components/filter/filter";

const Search = () => {
    const { search, permitions } = useSelector(state => state.agrupamentoPropostasCentroCusto);
    const columns = useMemo(() => COLUMNS, []);
    const data = search;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
        setGlobalFilter,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const { globalFilter, pageSize } = state;
    const [rows, setRows] = useState(0);
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    useEffect(() => {
        controller.get();
    }, []);

    useEffect(() => {
        setRows(page.length);
    }, [page])

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
    }

    async function handleUpdate(id) {
        await controller.getById(id);
        actions.setActiveTab("Register");
    }

    function handleInitialPage() {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }

    const pageCount = Math.ceil(data.length / pageSize);
    return (
        <>          
            <Col>
                <Card className="pt-3 pb-2 mb-5">
                    <Col>
                        <div style={{ width: "25%" }} className="pb-3">
                            <Label style={{ fontWeight: '700' }}>Pesquisar</Label>
                            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        </div>
                        <table {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th>
                                                {column.render("Header")}
                                                <span className="float-right">
                                                    {column.isSorted ?
                                                        (column.isSortedDesc ?
                                                            <BsIcons.BsArrowDown
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />
                                                            :
                                                            <BsIcons.BsArrowUp
                                                                id="OrdenarAsc"
                                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                            />)
                                                        :
                                                        <BsIcons.BsArrowDownUp
                                                            id="Ordenar"
                                                            {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                        />}
                                                </span>
                                            </th>
                                        ))}
                                        <th style={{ textAlign: "center" }}>
                                            Ações
                                 </th>
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr className="text-center" {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                            })}
                                            <td style={{ textAlign: "center", width: "10%" }}>
                                                <>
                                                    {
                                                        permitions.criar && (
                                                            <AiIcons.AiOutlineEye
                                                            style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}
                                                            onClick={() => handleUpdate(row.original.id)}
                                                        />
                                                        )
                                                    }                                                                                                  
                                                </>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Row className="pt-4">
                            <Col>
                                <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {rows} de {search.length}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { setPageSize(Number(e.target.value)); handleInitialPage() }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Card >
            </Col >
        </>
    );
}


export default Search;