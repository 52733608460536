import React, { useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { useSelector } from 'react-redux';
import { Col, Row, FormGroup, Label, Input, Button, Collapse } from 'reactstrap'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

import { Select } from '../../../components/Inputs/Input'

import ModalConsultores from './Components/ModalConsultores'

import * as actions from '../../../store/modules/dashboard/campanha/actions'
import * as controller from '../../../controllers/dashboardController/campanhaController';
import { GrFormClose } from 'react-icons/gr';
import BigNumbers from './Components/BigNumbers';
import Table from './Components/Table';
// import TableProduto from './Components/TableProduto';

export default function Campanha(){
    // const { filtros, isConsultor, tiposParceria, canais, grupos, produtos, campanhas, centrosCusto, campanha, tabela} = useSelector(state => state.dashboardCampanha);
    const { filtros, campanhas, campanha, tabela} = useSelector(state => state.dashboardCampanha);

    useEffect(()=>{
        controller.getCampanhas()
    },[])

    const [modalConsultor, setModalConsultor] = useState(false)
    const [isTabelaOpen, setIsTabelaOpen] = useState(false)
    // const [isTabelaProdutoOpen, setIsTabelaProdutoOpen] = useState(false)
    const [isBigNumberOpen, setIsBigNumberOpen] = useState(false)

    const toggleConsultor = () => setModalConsultor(!modalConsultor)

    async function onChangeFiltros(value, campo){
        actions.setFiltros(campo, value)
    }
    
    return(
        <>
            <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '0 20px 0 20px', padding: '20px'}}>
                <p style={{fontSize: '16px', fontWeight: '700'}}>Filtros</p>
                <div>
                    <Row style={{marginRight: '-45px'}}>
                        <Col md={3}>
                                <FormGroup>
                                    <Label style={{fontWeight: '700'}}>Campanha</Label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: filtros.campanhaId }}
                                        select={{ value: "id", label: "descricao" }}
                                        options={campanhas}
                                        onChange={(e) => {onChangeFiltros(e.value, 'campanhaId')}}
                                        isSearchable={true}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label style={{fontWeight: '700'}}>Hierarquia</Label>
                                    <Input
                                    type="text"
                                    placeholder="Busque um consultor..."
                                    value={filtros.consultor}
                                    disabled
                                    />
                                </FormGroup>
                            </Col>
                            <GrFormClose
                            style={{color: '#C4C7CA', marginTop: '30px', fontSize: '20px', cursor: 'pointer', marginLeft: '-10px'}}
                            onClick={()=> {onChangeFiltros('', 'consultor');onChangeFiltros('0', 'consultorId');}}
                            />
                            <AiOutlineSearch
                                style={{color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer'}}
                                onClick={()=> toggleConsultor()}
                            />         
                        {/* <Col md={3}>
                            <FormGroup>
                                <Label style={{fontWeight: '700'}}>Canal</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.canalId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={canais}
                                    onChange={(e) => {onChangeFiltros(e.value, 'canalId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label style={{fontWeight: '700'}}>Tipo Parceria</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.tipoParceriaId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={tiposParceria}
                                    onChange={(e) => {onChangeFiltros(e.value, 'tipoParceriaId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                       
                        <Col md={2}>
                            <FormGroup>
                                <Label style={{fontWeight: '700'}}>Grupo</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.grupoId }}
                                    select={{ value: "id", label: "descricao" }}
                                    options={grupos}
                                    onChange={(e) => {onChangeFiltros(e.value, 'grupoId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col> */}
                    </Row>
                    <Row style={{marginRight: '-45px'}}>
                        {/* <Col md={3}>
                            <FormGroup>
                                <Label style={{fontWeight: '700'}}>Centro de Custo</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.centroCustoId }}
                                    select={{ value: "id", label: "descricao" }}
                                    options={centrosCusto}
                                    onChange={(e) => {onChangeFiltros(e.value, 'centroCustoId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col> */}
                                      
                    </Row>
                </div>
                <div style={{paddingTop: '20px'}}>
                    <Row>                    
                        <Col>
                        <Button
                                className="purpleButton"
                                style={{ width: "150px", float: 'right' }}
                                onClick={() => controller.get(filtros)}>
                                Aplicar
                            </Button>
                            <Button className="mr-4"
                                style={{
                                    width: "150px",
                                    background: "var(--color-branco)",
                                    borderColor: "var(--color-laranja)",
                                    color: "var(--color-laranja)",
                                    borderRadius: "7px",
                                    float: 'right'
                                }}
                                onClick={() => actions.cleanFiltros()}>
                                Limpar Filtros
                                </Button>
                        </Col>
                        </Row>
                </div>
            </div>
            <div>
            <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px'}}>
                <p onClick={()=> setIsBigNumberOpen(!isBigNumberOpen)} style={{fontSize: '16px', fontWeight: '700', cursor: 'pointer'}}>
                {isBigNumberOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine/>}  Big Numbers
                </p>
                <div>
                    <Collapse isOpen={isBigNumberOpen}>    
                        <BigNumbers data={campanha}/>
                    </Collapse>
                </div>
            </div>
                <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px'}}>
                <p onClick={()=> setIsTabelaOpen(!isTabelaOpen)} style={{fontSize: '16px', fontWeight: '700', cursor: 'pointer'}}>
                {isTabelaOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine/>}  Metas de campanha
                </p>
                <div>
                    <Collapse isOpen={isTabelaOpen}>               
                        <Table data={tabela}/>
                    </Collapse>
                </div>
            </div>
            {/* <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px'}}>
                <p onClick={()=> setIsTabelaProdutoOpen(!isTabelaProdutoOpen)} style={{fontSize: '16px', fontWeight: '700', cursor: 'pointer'}}>
                {isTabelaProdutoOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine/>}  Tabela de campanha Produto
                </p>
                <div>
                    <Collapse isOpen={isTabelaProdutoOpen}>               
                        <TableProduto data={produtos}/>
                    </Collapse>
                </div>
            </div> */}
            </div>
                 {
                    <ModalConsultores
                        isOpen={modalConsultor}
                        toggle={toggleConsultor} />
                }
            </>
    )
}