import React, { useEffect } from "react";
import { Card, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Select } from "../../../components/Inputs/Input";
import { useSelector } from "react-redux";
import * as convenioActions from "../../../store/modules/convenioInstituicao/actions";
import * as convenioController from "../../../controllers/convenioInstituicaoController";
import { removeAccents, removeAccentsAndUppercase } from "../../../util/masks";

const Register = () => {

    const { item, conveniosRc, bancos, invalid, copy, seeRegister } = useSelector(state => state.convenioInstituicao);

    useEffect(() => {
        convenioController.getConveniosRc();
    }, []);

    useEffect(() => {
        convenioController.getBancos();
    }, []);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                convenioController.create(item);
            } else {
                convenioController.update(item, copy);
            }
        }
    }

    function onChangeItem(value, field) {
        convenioActions.cleanInvalid(field);
        convenioActions.item({ ...item, [field]: value });
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2" style={{ margin: '25px 0 0 25px' }}>
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>
                            Preencha os campos para cadastrar um novo Convênio Instituição
                        </p>
                    </Col>
                    <Col className="pt-2" style={{margin: '10px 0 0 25px'}}>
                            <Row>
                                <Col md={4}>
                                        <Label for="convenio" style={{ color: 'var(--color-preto)' }}>
                                            Descrição do Convênio Instituição
                                        </Label>
                                        <Input
                                            type="text"
                                            name="convenio"
                                            id="convenio"
                                            placeholder="Digite aqui..."
                                            value={item.descricao}
                                            onChange={e => onChangeItem(removeAccents(e.target.value), "descricao")}
                                            onKeyPress={e => handleKeyPress(e)}
                                            className={invalid.descricao ? "is-invalid" : ""}
                                            disabled={seeRegister}
                                        />
                                </Col>
                                <Col md={4}>
                                        <Label for="instituicao" style={{color: 'var(--color-preto)' }}>
                                            Instituição Financeira
                                        </Label>
                                        <Select
                                            first="SELECIONE"
                                            value={{ value: item.bancoId }}
                                            select={{ value: "id", label: "nome" }}
                                            onChange={e => onChangeItem(e.value, "bancoId")}
                                            options={bancos}
                                            isSearchable={true}
                                            autoComplete="off"
                                            disabled={seeRegister}
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor: invalid.bancoId ? "red" : "lightgrey"
                                                })
                                            }}
                                        />
                                </Col>
                            </Row>
                            <Row style={{marginTop: '20px'}}>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="associacao" style={{color: 'var(--color-preto)' }}>
                                            Associação Convênio Ribercred
                                        </Label>
                                        <Select
                                            first="SELECIONE"
                                            value={{ value: item.convenioRcId }}
                                            select={{ value: "id", label: "descricao" }}
                                            onChange={e => onChangeItem(e.value, "convenioRcId")}
                                            options={conveniosRc}
                                            isSearchable={true}
                                            autoComplete="off"
                                            disabled={seeRegister}
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor: invalid.convenioRcId ? "red" : "lightgrey",
                                                    minHeight: "35px",
                                                    height: "35px"
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label style={{ color: 'var(--color-preto)' }}>
                                            Código convênio
                                        </Label>
                                        <Input
                                            type="text"                                         
                                            placeholder="Digite aqui..."
                                            value={item.codConvenio}
                                            onChange={e => onChangeItem(removeAccentsAndUppercase(e.target.value), "codConvenio")}
                                            onKeyPress={e => handleKeyPress(e)}
                                            className={invalid.codConvenio ? "is-invalid" : ""}
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                    </Col>
                </Card>
            </Col>
        </>
    );
}

export default Register;