import api from "../../util/api";
import * as contaActions from "../../store/modules/personas/conta_corrente_store/actions";
import * as papeisActions from "../../store/modules/personas/papeis_store/actions";
import { setLoading } from "../../store/modules/globalLoading/actions";
import {notifySuccess} from "../../components/message/message";

export async function getBancos() {

    const data = await api.get("dados-bancario");

    if (!data.success) {
        return;
    }

    contaActions.setBancos(data.content)
}

export async function getTiposPix() {

    const data = await api.get("tipoPix");

    if (!data.success) {
        return;
    }

    contaActions.setTiposPix(data.content)
}

export async function getTiposConta() {

    const data = await api.get("tipoConta");

    if (!data.success) {
        return;
    }

    contaActions.setTiposConta(data.content)
}

export async function removeMatricula(id) {
    setLoading(true);
    const data = await api.delete(`Pessoa/${id}/desvincular-contas`);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    return true
}

export async function removeContaCorrente(id) {
    setLoading(true);
    const data = await api.delete(`Pessoa/${id}/deletar-contacorrente`);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    let contas = []
    data.content.contasCorrente.forEach((e) => {
        e.matriculas.forEach((e) => {
            e.nome = e.numero
        })
        contas.push({
            id: e.id,
            instituicaoFinanceiraId: e.bancoDadosBancarioId,
            nomeAgencia: e.nomeAgencia,
            chavePix: e.chavePix,
            tipoConta: e.tipoConta.id,
            digitoAgencia: e.digitoAgencia,
            digitoConta: e.digitoConta,
            tipoPixId: e.tipoPixId,
            numero: e.numero,
            matricula: '0',
            matriculasContaCorrente: e.matriculas,
            flagPadrao: e.flagPadrao,
            cdContaCorrenteW3ERP: e.codigoContaCorrenteW3ERP
        });
    });

    contaActions.contaCorrente(contas);

    notifySuccess("Conta corrente removida com sucesso!");
    return {valid: true};
}

export async function buscaMatriculas(idPessoa){
    if (idPessoa !== null){
        setLoading(true);
        const data = await api.get(`ClienteMatricula/${idPessoa}/busca-matriculas`);
        setLoading(false);

        papeisActions.setMatriculasSelectBox(data.content);
    }
}
