import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    routes: [],
}

export default function routes(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_ROUTES:
                    return { ...state, routes: action.value }
                case TYPES.CLEAN_ROUTES:
                    return {
                        ...state,routes: INITIAL_STATE.routes
                    }
                default:
                    return { ...state }
            }
        default:
            return { ...state }
    }
}