import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function password(value) {
    dispatch({
        type: TYPES.SENHA,
        value
    });
}

export function validPassword(value) {
    dispatch({
        type: TYPES.VALID_SENHA,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_SENHA,
        field
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_SENHA,
        field
    })
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}
