import React from 'react'

import { maskMoeda } from '../../../../util/masks'

export default function BigNumber({element}){
    return(
            <div style={{background: 'rgba(151, 224, 79, 0.25)',  borderRadius: '20px',width: '100%', height: '110px', marginRight: '20px', paddingTop: '20px'}}>
                <p style={{textAlign: 'center', fontsize: '22px', marginBottom: '5px', color: 'var(--color-verde-claro)'}}>
                    {`${element.quantidade}/${element.quantidadeTotal}`}
                </p>
                <p style={{textAlign: 'center', fontWeight: '700', fontsize: '22px', marginBottom: '5px'}}>
                    {`R$${maskMoeda(element.valorTotal.toFixed(2))}`}
                </p>
                <p style={{textAlign: 'center', fontSize: '15px'}}>
                    {element.statusBanco}
                </p>
            </div>
    )
}