import { formatarDocumento } from "../../../util/masks";
export const COLUMNS = [

    {
        Header: 'CPF/CNPJ',
        accessor: 'cpf',
        Cell: ({ value }) => {
            return formatarDocumento(value);
        }
        
    },
    {
        Header: "Nome",
        accessor: "nome",
    },
    {
        Header: "E-mail",
        accessor: "email"
    },
    {
        Header: "Perfil",
        accessor: "perfil"
    },
    {
        Header: "Status",
        accessor: "ativo",
        Cell: ({ value }) => { return value ? "Ativo" : "Inativo" }
    }
]