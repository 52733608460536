const STORE_ID = "VINCULOLOJA";

const TYPES = {
  SET_ITEMS: "SET_ITEMS",
  SET_ITEM: "SET_ITEM",
  SET_PERMITIONS: "SET_PERMITIONS",
  SET_BANCOS: "SET_BANCOS",
  SET_LOJAS_ARRECADADORAS: "SET_LOJAS_ARRECADADORAS",
  SET_CONTAS: "SET_CONTA",
  SET_TIPOS_COMISSAO: "SET_TIPOS_COMISSAO",
  CLEAN_REGISTER: "CLEAN_REGISTER",
  CLEAN_SEARCH: "CLEAN_SEARCH",
  SET_SEARCH: "SET_SEARCH",
  CLEAN_SEARCH_FIELD: "CLEAN_SEARCH_FIELD",
  CLEAN_ITEM_FIELD: "CLEAN_ITEM_FIELD",
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  SET_SEE_REGISTER: "SET_SEE_REGISTER",
  GET_ALL_ITEMS: "GET_ALL_ITEMS"
};

export { STORE_ID, TYPES };
