import { STORE_ID, TYPES } from "./types";

const INITIAL_STATE = {
  tab: "1",
  pesquisar: {
    numeroProposta: "",
    codInstituicao: "0",
    codProduto: "0",
    codConvenio: "0",
    nomeCliente: "",
    dataEntrada: "",
    dataStatus: "",
    tipoData: "0",
    cpf: "",
    produto: "0",
    statusPropostaId: "0",
    tipoOperacaoId: "0",

    
    
  },
  item: {
    id: "",
    proposta: "",
    loja: "",
    convenio: "",
    cpfCliente: "",
    nomeCliente: "",
    instituicao: "",
    cpfDigitador: "",
    codDigitador: "",
    nomeDigitador: "",
    produtos: [],
    comissoes: [],
    contasReceber: [],
    contasPagar: [],
  },
  getAll: {
    quantidade: "0",
    quantidadeTotal: "0",
    totalPaginas: "0",
  },
  instituicoes: [],
  produtos: [],
  convenios: [],
  propostas: [],
  tipoOperacao: [],
  status: [],
  activeTab: "Search",
  activeDash: false,
  seeRegister: false,
  permitions: {
    visualizar: false,
  },
  permitionsTabs: {
    comissao: false,
    cp: false,
    cr: false,
  },
};

export default function propostas(state = INITIAL_STATE, action) {
  switch (action.storeId) {
    case STORE_ID:
      switch (action.type) {
        case TYPES.SET_PERMITIONS:
          return { ...state, permitions: action.value };
        case TYPES.SET_PERMITIONS_TAB:
          return {
            ...state,
            permitionsTabs: {
              ...state.permitionsTabs,
              [action.label]: action.value,
            },
          };
        case TYPES.PESQUISAR:
          return { ...state, pesquisar: action.value };
        case TYPES.SET_TAB:
          return { ...state, tab: action.value };
        case TYPES.PROPOSTAS:
          return { ...state, propostas: action.value };
        case TYPES.ITEM:
          return { ...state, item: action.value };
        case TYPES.INSTITUICOES:
          return { ...state, instituicoes: action.value };
        case TYPES.PRODUTOS:
          return { ...state, produtos: action.value };
        case TYPES.SET_GET_ALL:
          return { ...state, getAll: action.value };
        case TYPES.CONVENIOS:
          return { ...state, convenios: action.value };
        case TYPES.CLEAN_SEARCH:
          return { ...state, pesquisar: INITIAL_STATE.pesquisar };
        case TYPES.CLEAN_REGISTER:
          return { ...state, item: INITIAL_STATE.item };
        case TYPES.SET_ACTIVE_TAB:
          return { ...state, activeTab: action.value };
        case TYPES.SET_ACTIVE_DASH:
          return { ...state, activeDash: action.value };
        case TYPES.SET_SEE:
          return { ...state, seeRegister: action.value };
        case TYPES.TIPO_OPERACAO:
          return { ...state, tipoOperacao: action.value };
        case TYPES.STATUS:
          return { ...state, status: action.value };
        case TYPES.DATA_ENTRADA:
          return { ...state, dataInicio: action.value };
        case TYPES.DATA_STATUS:
          return { ...state, dataStatus: action.value };
        case TYPES.TIPO_DATA:
          return { ...state, tipoData: action.value };
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
}
