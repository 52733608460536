import React, { useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import * as AiIcons from "react-icons/ai";
import * as informacoesActions from "../../../../../../store/modules/personas/informacoes_store/actions";
import * as informacaoController from "../../../../../../controllers/personasController/informacoesController";
import { useSelector } from 'react-redux';
import '../../../../styles/modalSocios.css';
import { maskCPF } from '../../../../../../util/masks';
import { InputCpfCnpj } from '../../../../../../components/Inputs/Input';
import { notifyWarn } from '../../../../../../components/message/message';

const CardItem = ({ socio, index }) => {

    const { invalid, seeRegister, socios, pesquisa, sociosPesquisa } = useSelector(state => state.personasInformacoes);

    function onChangeItem(nome, id) {
        informacoesActions.cleanInvalid();
        const i = { ...socio, "socio": nome, "pessoaSocioId": id }
        const arrSocios = socios;
        arrSocios[index] = i;
        informacoesActions.setSocios(arrSocios);
    }

    function onChangePesquisa(value, field) {
        informacoesActions.setPesquisa({ ...pesquisa, [field]: value });
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            informacaoController.getSocios(pesquisa);
        }
    }

    async function removeItem() {
        if(socios.length > 1){
            if(socio.id){
                await informacaoController.removeSocios(socio.id)
            }
            informacoesActions.removeSocio(index)
        }else{
            notifyWarn('Não é possível excluir todos os sócios.')
        }
    }

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);


    return (
        <>
            <Modal
                className="text-center"
                isOpen={modal}
                toggle={toggle}
                backdrop={true}
            >
                <ModalHeader toggle={toggle} >Pesquisa de Sócios</ModalHeader>
                <ModalBody >
                    <Row>
                        <Col md={4}>
                            <Label className="float-left" for="cpf">CPF</Label>
                            <InputCpfCnpj
                                type="text"
                                id="cpf"
                                tipo="cpf"
                                placeholder="Digite aqui..."
                                value={pesquisa.cpf}
                                onChange={e => onChangePesquisa(e.target.value, "cpf")}
                                onKeyPress={e => handleKeyPress(e)}
                                maxLength="14"
                            />
                        </Col>
                        <Col>
                            <Label className="float-left" for="nome">Nome</Label>
                            <Input
                                type="text"
                                id="nome"
                                placeholder="Digite aqui..."
                                value={pesquisa.nome}
                                onChange={e => onChangePesquisa(e.target.value, "nome")}
                                onKeyPress={e => handleKeyPress(e)}
                                maxLength="50"
                            />
                        </Col>
                    </Row>
                    <fieldset className={sociosPesquisa?.length > 0 ? "fieldset-socio" : "fieldset-socio-no-results"}>
                        <legend className={'fieldset-socio-legend'}>Resultados</legend>
                        <table>
                            <tr>
                                <th style={{ width: "110px" }}>CPF</th>
                                <th>Nome</th>
                            </tr>
                            {sociosPesquisa?.map(row => {
                                return (
                                    <tr onDoubleClick={() => { onChangeItem(row.nome, row.id); toggle() }}>
                                        <td index={row.id}>{maskCPF(row.cpfCnpj)}</td>
                                        <td index={row.id}>{row.nome}</td>
                                    </tr>
                                )
                            })}

                        </table>
                        <br />
                    </fieldset>
                </ModalBody>
                <div className="m-3">
                    <Button
                        onClick={() => informacoesActions.cleanSearch()}
                        className="mr-4"
                        style={{
                            width: "150px",
                            background: "var(--color-branco)",
                            borderColor: "var(--color-laranja)",
                            color: "var(--color-laranja)",
                            borderRadius: "7px"
                        }}
                    >
                        Limpar
                    </Button>
                    <Button
                        onClick={() => informacaoController.getSocios(pesquisa)}
                        color="danger"
                        className="purpleButton"
                        style={{ width: "150px" }}
                    >
                        Pesquisar
                    </Button>
                </div>
            </Modal>
            <Row>
                <Col md={5}>
                    <FormGroup className="mt-3">
                        <Label for="socio" style={{ fontWeight: '700', fontSize: '13px', color: '#4F585A' }}>Persona (Sócio)</Label>
                        <Input
                            value={socio.socio}
                            className={invalid.socio ? "is-invalid" : ""}
                            disabled={true}
                        />
                    </FormGroup>
                </Col>
                <Button
                    style={{ height: "33.5px", marginTop: "37px" }}
                    onClick={() => {
                        toggle()
                        informacoesActions.cleanSearch()
                    }}
                    disabled={seeRegister}
                >
                    <AiIcons.AiOutlineSearch />
                </Button>
            </Row>
            <Col>
                {socios && !seeRegister &&
                    <Label>
                        <AiIcons.AiOutlineCloseCircle
                            style={{ fontSize: "1.5rem", marginRight: "7px", color: "red", cursor: "pointer" }}
                            onClick={() => removeItem()}
                        />
                           Remover sócio
                        </Label>
                }
            </Col>
        </>
    );
}

export default CardItem;