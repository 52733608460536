import React, { useEffect } from "react";
import "../../style.css";
import { Col, TabContent, TabPane } from "reactstrap";
import Search from "./tabs/search";
import { useSelector } from "react-redux";
import * as actions from "../../store/modules/conferenciaCr/actions";
import Breadcrumb from "../../components/breadcrumbs";

export default function UploadComissao(){
    const { activeTab } = useSelector(state => state.conferenciaCr);

    useEffect(() => {
        actions.setActiveTab("Search")
    }, [])


    return (
        <>
                <Col>
                    <Breadcrumb activeTab={activeTab} />
                </Col>
                <TabContent style={{ width: "100%" }} activeTab={activeTab}>
                    <TabPane tabId="Search">
                        <br />
                        <Search />
                    </TabPane>
                </TabContent>
        </>
    );
}

