import {STORE_ID, TYPES} from "./types";

const INITIAL_STATE = {
    item: {
        produto: {
            id: null,
            produtoRiber: '0',
            tipoComissao: '0',
            codigoContaGerencial: '',
            isProduto: true
        },
        descricao: {
            id: null,
            informacao: '',
            classificacaoReceita: '',
            codigoContaGerencial: '',
            isDescricao: false
        }
    },
    search: {
        codigoContaGerencial: '',
        produtoRiber: '0',
        tipoComissao: '0',
        informacao: '',
        classificacaoReceita: '',
        tipoContaGerencial: '0',
        isProduto: false,
        isDescricao: false,
    },
    isDespesa: false,
    isProdutoSelecionado: false,
    items: [],
    tiposConta: [],
    copy: {},
    activeTab: "Search",
    tiposComissao: [],
    produtosRiber: [],
    descricoes: [],
    seeRegister: false,
    isUpdate: false,
    isCreate: true,
    permitions: {
        criar: false,
        deletar: false,
        visualizar: false
    },
    getAll: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    }
}
export default function contaGerencial(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.CLEAN_PRODUTO_CONTA_GERENCIAL:
                    return {
                        ...state, item: {
                            ...state.item, produto: INITIAL_STATE.item.produto
                        }
                    }
                case TYPES.CLEAN_DESCRICAO_CONTA_GERENCIAL:
                    return {
                        ...state, item: {
                            ...state.item, descricao: INITIAL_STATE.item.descricao
                        }
                    }
                case TYPES.SET_PRODUTO_CONTA_GERENCIAL:
                    return {
                        ...state, item: {
                            ...state.item, produto: action.value
                        }
                    }
                case TYPES.SET_DESCRICAO_CONTA_GERENCIAL:
                    return {
                        ...state, item: {
                            ...state.item, descricao: action.value
                        }
                    }
                case TYPES.SET_PRODUTOS_RIBER_CONTA_GERENCIAL:
                    return {...state, produtosRiber: action.value}
                case TYPES.SET_TIPOS_COMISSAO_CONTA_GERENCIAL:
                    return {...state, tiposComissao: action.value}
                case TYPES.SET_ACTIVE_TAB_CONTA_GERENCIAL:
                    return {...state, activeTab: action.value}
                case TYPES.SET_PERMITIONS_CONTA_GERENCIAL:
                    return {...state, permitions: action.value}
                case TYPES.SET_SEE_REGISTER_CONTA_GERENCIAL:
                    return {...state, seeRegister: action.value}
                case TYPES.SET_CONTAS_GERENCIAIS:
                    return {...state, items: action.value}
                case TYPES.SET_IS_PRODUTO:
                    return {
                        ...state, item: {
                            ...state.item, produto: {
                                ...state.item.produto, isProduto: true
                            },
                            ...state.item, descricao: {
                                ...state.item.descricao, isDescricao: false
                            }
                        }
                    }
                case TYPES.SET_IS_DESCRICAO:
                    return {
                        ...state, item: {
                            ...state.item, produto: {
                                ...state.item.produto, isProduto: false
                            },
                            ...state.item, descricao: {
                                ...state.item.descricao, isDescricao: true
                            }
                        }
                    }
                case TYPES.SET_GET_ALL:
                    return {...state, getAll: action.value}
                case TYPES.CLEAN_CONTA_GERENCIAL:
                    return {...state, contaGerencial: INITIAL_STATE.contaGerencial}
                case TYPES.SET_IS_DESPESA:
                    return {...state, isDespesa: action.value}
                case TYPES.CONTA_GERENCIAL_COPY:
                    return {...state, copy: action.value}
                case TYPES.SET_ITEM:
                    return {...state, item: action.value}
                case TYPES.SET_TIPO_IS_PRODUTO:
                    return {...state, isProdutoSelecionado: action.value}
                case TYPES.SET_IS_UPDATE:
                    return {...state, isUpdate: action.value}
                case TYPES.SET_IS_CREATE:
                    return {...state, isCreate: action.value}
                case TYPES.SET_TIPOS_CONTA:
                    return {...state, tiposConta: action.value}
                case TYPES.SET_SEARCH:
                    return {...state, search: action.value}
                case TYPES.CLEAN_SEARCH:
                    return {...state, search: {
                            ...state.search,
                            produtoRiber: '0',
                            tipoComissao: '0',
                            informacao: '',
                            classificacaoReceita: '',
                        }}
                default:
                    return {...state}
            }
        default:
            return {...state}
    }
}