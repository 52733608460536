const STORE_ID = "VINCULO_CENTRO_CUSTO";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    CENTROCUSTO: "CENTROCUSTO",
    CENTROCUSTO_COPY: "CENTROCUSTO_COPY",
    INVALID_CENTROCUSTO: "INVALID_CENTROCUSTO",
    CENTROS: "CENTROS",
    SET_LOJAS: "SET_LOJAS",
    SET_CENTROS_CUSTO: "SET_CENTROS_CUSTO",
    CLEAN_INVALID_CENTROCUSTO: "CLEAN_INVALID_CENTROCUSTO",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    SET_PERSONA: "SET_PERSONA"
}

export { STORE_ID, TYPES }