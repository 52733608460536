const STORE_ID = "HIERARQUIA";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    SET_NIVEIS_HIERARQUICOS: 'SET_NIVEIS_HIERARQUICOS',
    HIERARQUIA: "HIERARQUIA",
    HIERARQUIA_COPY: "HIERARQUIA_COPY",
    INVALID_HIERARQUIA: "INVALID_HIERARQUIA",
    HIERARQUIAS: "HIERARQUIAS",
    SET_CANAIS_VENDA: "SET_CANAIS_VENDA",
    CLEAN_INVALID_HIERARQUIA: "CLEAN_INVALID_HIERARQUIA",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE"
}

export { STORE_ID, TYPES }