import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import * as BsIcons from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Modal, Col, Input, Row, Navbar } from "reactstrap";

import { COLUMNS_DETALHAMENTO } from "../constants/columns";
import * as  controller from "../../../controllers/conferenciaCrController";

const ModalDetalhamento = ({ isOpen, toggle, id }) => {
    const { search, comissoesRecebida, getAll} = useSelector(state => state.conferenciaCr);
    const columns = useMemo(() => COLUMNS_DETALHAMENTO, []);
    const data = comissoesRecebida;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const { pageSize } = state;
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        controller.getComissao(search, pageSize, pageSelected + 1, id);
    }

    useEffect(() => {
        if(getAll.tamanhoPagina){
            setPageSize(getAll.tamanhoPagina);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAll])


    function handleInitialPage(pageSize) {
        const pageSelected = 0;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        controller.getComissao(search, pageSize, pageSelected + 1, id);
    }

    return (
        <Modal
                className="text-center"
                isOpen={isOpen}
                toggle={toggle}
                backdrop={true}
                size="lg"
                style={{maxWidth: '1600px', width: '80%'}}
        >
                 <Navbar expand="md" style={{ width: "100%", height: "40px", background: 'var(--color-gradiente-verde)', marginBottom: '20px' }}>
                    <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2" >Detalhamento Comissão</h5>
                </Navbar>
            <Col>                
                <div style={{width: '99%', overflow: `auto`}}>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th style={{minWidth: '150px'}}>
                                        {column.render("Header")}
                                        <span className="float-right">
                                            {column.isSorted ?
                                                (column.isSortedDesc ?
                                                    <BsIcons.BsArrowDown
                                                        {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                    />
                                                    :
                                                    <BsIcons.BsArrowUp
                                                        id="OrdenarAsc"
                                                        {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                    />)
                                                :
                                                <BsIcons.BsArrowDownUp
                                                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                />}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr className="text-center" {...row.getRowProps()}>                 
                                    {row.cells.map((cell) => {
                                        return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                    })}                                         
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                </div>
                <Row className="pt-4">
                    <Col>
                        <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {getAll.quantidade ? getAll.quantidade : "0"} de {getAll.quantidadeTotal ? getAll.quantidadeTotal : "0"}</span>
                    </Col>
                    <Col md={8}>
                        <Row className="float-right">
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                initialPage={0}
                                pageCount={getAll.totalPaginas}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                forcePage={initialPage ? 0 : selectedPage}
                            />
                            <Input
                                type="select"
                                className="pageSize"
                                value={pageSize}
                                onChange={e => { handleInitialPage(e.target.value); setPageSize(Number(e.target.value)) }}>
                                {
                                    [10, 25, 100].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))
                                }
                            </Input>
                        </Row>
                    </Col>
                </Row>
            </Col>         
        </Modal>
    );
}


export default ModalDetalhamento;