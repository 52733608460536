import React from "react";
import { useSelector } from "react-redux";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Col } from 'reactstrap';

import classnames from 'classnames';

import * as actions from '../../store/modules/dashboard/actions';
import Breadcrumb from "../../components/breadcrumbs";
import Producao from "./producao/Producao";
import EsteiraProducao from "./esteiraProducao/EsteiraProducao";
import Receita from "./receita/Receita";
import Campanha from "./campanha/Campanha";
import Formalizacao from "./formalizacao/Formalizacao";
import Previsao  from "./previsão/Previsão";

const Dashboard = () => {
    const { tab, permitions } = useSelector(state => state.dashboard);

    function changeTab(value){
        actions.setTab(value)
    }
    return (
        <Col>
         <Breadcrumb activeTab={"Search"} />
            <Card className="pt-3 pb-2 mb-5">
                <div>
                    <Nav style={{ marginLeft: '25px', marginBottom: '30px' }}>
                        {permitions.producao && (
                            <NavItem style={{ padding: '0 5px 0 5px' }}>
                                <NavLink
                                    className={classnames({ active: tab === '1' })}
                                    onClick={() => { changeTab('1'); }}
                                    style={
                                        tab === '1' ?
                                            { color: 'var(--color-preto', fontWeight: '700', borderBottom: '3px solid var(--color-verde-claro)', transition: '0.1s', cursor: 'pointer' }
                                            : { color: 'var(--color-preto', fontWeight: '700', cursor: 'pointer' }}
                                >
                                    Produção
                                </NavLink>                
                            </NavItem>
                        )}
                       {
                        permitions.esteira && (
                            <NavItem style={{ padding: '0 15px 0 15px' }}>
                                <NavLink
                                    className={classnames({ active: tab === '2' })}
                                    onClick={() => { changeTab('2'); }}
                                    style={
                                        tab === '2' ?
                                            { color: 'var(--color-preto', fontWeight: '700', cursor: 'pointer', borderBottom: '3px solid #10A9FF', transition: '0.1s' }
                                            : { color: 'var(--color-preto', cursor: 'pointer', fontWeight: '700' }}
                                >
                                    Esteira de produção
                                </NavLink>
                            </NavItem>
                        )
                       }
                       {
                        permitions.formalizacao && (
                            <NavItem style={{ padding: '0 15px 0 15px' }}>
                                <NavLink
                                    className={classnames({ active: tab === '3' })}
                                    onClick={() => { changeTab('3'); }}
                                    style={
                                        tab === '3' ?
                                            { color: 'var(--color-preto', cursor: 'pointer', fontWeight: '700', borderBottom: '3px solid #7559FF', transition: '0.1s' }
                                            : { color: 'var(--color-preto', cursor: 'pointer', fontWeight: '700' }}
                                >
                                    Formalização
                                </NavLink>
                            </NavItem>
                        )
                       }
                       {
                        permitions.receita && (
                            <NavItem style={{ padding: '0 15px 0 15px' }}>
                                <NavLink
                                    className={classnames({ active: tab === '4' })}
                                    onClick={() => { changeTab('4'); }}
                                    style={
                                        tab === '4' ?
                                            { color: 'var(--color-preto', cursor: 'pointer', fontWeight: '700', borderBottom: '3px solid #FFAA00', transition: '0.1s' }
                                            : { color: 'var(--color-preto', cursor: 'pointer', fontWeight: '700' }}
                                >
                                    Receita
                                </NavLink>
                            </NavItem>
                        )
                       }
                       {
                        permitions.campanha && (
                            <NavItem style={{ padding: '0 15px 0 15px' }}>
                                <NavLink
                                    className={classnames({ active: tab === '5' })}
                                    onClick={() => { changeTab('5'); }}
                                    style={
                                        tab === '5' ?
                                            { color: 'var(--color-preto', cursor: 'pointer', fontWeight: '700', borderBottom: '3px solid var(--color-roxo)', transition: '0.1s' }
                                            : { color: 'var(--color-preto', cursor: 'pointer', fontWeight: '700' }}
                                >
                                    Campanha
                                </NavLink>
                            </NavItem>
                        )
                       }
                       {permitions.previsao && 
                         (
                            <NavItem style={{ padding: '0 15px 0 15px' }}>
                                <NavLink
                                    className={classnames({ active: tab === '6' })}
                                    onClick={() => { changeTab('6'); }}
                                    style={
                                        tab === '6' ?
                                            { color: 'var(--color-preto', cursor: 'pointer', fontWeight: '700', borderBottom: '3px solid var(--color-verde-claro)', transition: '0.1s' }
                                            : { color: 'var(--color-preto', cursor: 'pointer', fontWeight: '700' }}
                                >
                                    Provisionamento
                                </NavLink>
                            </NavItem>
                        )
                       }
                    </Nav>
                    <TabContent activeTab={tab}>
                        <TabPane tabId="1">
                           <Producao/>
                        </TabPane>
                        <TabPane tabId="2">
                           <EsteiraProducao/>
                        </TabPane>
                        <TabPane tabId="3">
                           <Formalizacao/>
                        </TabPane>
                        <TabPane tabId="4">
                            <Receita/>
                        </TabPane>
                        <TabPane tabId="5">
                            <Campanha/>
                        </TabPane>
                        <TabPane tabId="6">
                            <Previsao/>
                        </TabPane>
                    </TabContent>
                </div>
            </Card>
        </Col>

    );
}

export default Dashboard;