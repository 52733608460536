const STORE_ID = "PERSONAS_ENDERECO";

const TYPES = {
    ENDERECOS: "ENDERECOS",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    SET_SEE: "SET_SEE",
    GET_TIPOS_ENDERECO: "GET_TIPOS_ENDERECO",
    SET_ESTADOS: "SET_ESTADOS",
    ENDERECO: 'ENDERECO',
    SET_ENDERECO: 'SET_ENDERECO',
    CLEAN_ENDERECO: 'CLEAN_ENDERECO',
    SET_IS_DELETING: 'SET_IS_DELETING',
    SET_IS_EDITANDO: 'SET_IS_EDITANDO'
}

export { STORE_ID, TYPES }