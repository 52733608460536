import React, { useEffect } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { Card, Col, Form, Row, Button, TabContent, TabPane } from 'reactstrap'
import Search from './tabs/search'
import Register from './tabs/register'
import { useSelector } from 'react-redux'
import * as divisaoComissaoController from '../../controllers/divisaoComissaoController'
import * as divisaoComissaoActions from '../../store/modules/divisaoComissao/actions'
import Breadcrumb from '../../components/breadcrumbs'

const DivisaoComissao = () => {

    const { item, activeTab, copy, search, seeRegister, permitions } = useSelector(state => state.divisaoComissao)

    useEffect(() => {
        divisaoComissaoController.getBancos()
        divisaoComissaoActions.setActiveTab('Search')
    }, [])

    async function saveItem() {
        if (item.id === '') {
            await divisaoComissaoController.create(item, search)
        } else {
            await divisaoComissaoController.update(item, copy, search)
        }
    }

    function changeTab(tab) {
        divisaoComissaoActions.cleanSearch()
        divisaoComissaoActions.setActiveTab(tab)
        divisaoComissaoActions.cleanRegister()
    }

    function seeRegisterF() {
        if (item.id !== '') {
            divisaoComissaoActions.cleanRegister()
        }
        divisaoComissaoActions.setSeeRegister(false)
    }

    return (
        <>
            <Col>
                <Breadcrumb activeTab={activeTab} />
                <Card>
                    <Form>
                        {
                            activeTab === 'Search'
                                ?
                                permitions.criar && (
                                    <Col md={12}>
                                        <Row className='pt-2 pb-2'>
                                            <Col md={8}>
                                                <Button onClick={() => changeTab('Register')} style={{ width: '250px' }} className='purpleButton'>Cadastrar Parametrização de Divisão de Comissão</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                                :
                                <Col className='pt-2 pb-2'>
                                    <Button
                                        onClick={() => {
                                            changeTab('Search')
                                            seeRegisterF()
                                        }}
                                        style={{ width: '150px', color: 'white' }}
                                        className='purpleButton'
                                    >
                                        <IoIosArrowBack style={{ marginTop: '3px', float: 'left' }} /> Voltar</Button>
                                    {!seeRegister ? <Button onClick={() => { saveItem() }} style={{ width: '240px', float: 'right' }} className='purpleButton'>Salvar Cadastro</Button> : null}
                                </Col>
                        }
                    </Form>
                </Card>
            </Col>

            <TabContent style={{ width: '100%' }} activeTab={activeTab}>
                <TabPane tabId='Search'>
                    <br />
                    <Search />
                </TabPane>
                <TabPane tabId='Register'>
                    <br />
                    <Register />
                </TabPane>
            </TabContent>
        </>
    )
}

export default DivisaoComissao