const STORE_ID = "PERSONAS";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    PERSONAS_COPY: "PERSONAS_COPY",
    PERSONAS: "PERSONAS",
    CLEAN_SEARCH: "CLEAN_SEARCH",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SEARCH: "SEARCH",
    ESTADOS: "ESTADOS",
    CANAIS: "CANAIS",
    TIPOS_LOJA: "TIPOS_LOJA",
    NASCIMENTOS: "NASCIMENTOS",
    GET_ALL_ITEMS: "GET_ALL_ITEMS",
    PERSONA: "PERSONA",
    PAPEIS: "PAPEIS",
    SET_TAB: "SET_TAB",
    CLEAN_ALL_SEARCH: "CLEAN_ALL_SEARCH"
}

export { STORE_ID, TYPES }