import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function setItem( value) {
    dispatch({
        type: TYPES.ITEM,
        value
    });
}

export function item(field, value) {
    dispatch({
        type: TYPES.ANT_CONTA_RECEBER,
        field,
        value
    });
}

export function itemProduto(value) {
    dispatch({
        type: TYPES.ANT_CONTA_RECEBER_PRODUTO,
        value
    });
}

export function itemLoja(value) {
    dispatch({
        type: TYPES.ANT_CONTA_RECEBER_LOJA,
        value
    });
}

export function copy(value) {
    dispatch({
        type: TYPES.ANT_CONTA_RECEBER_COPY,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_ANT_CONTA_RECEBER,
        field
    });
}

export function getItems(value) {
    dispatch({
        type: TYPES.ANT_CONTAS_RECEBER,
        value
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_ANT_CONTA_RECEBER,
        field
    });
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function cleanSearch() {
    dispatch({
        type: TYPES.CLEAN_SEARCH
    });
}

export function cleanAllSearch() {
    dispatch({
        type: TYPES.CLEAN_ALL_SEARCH
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function setSearch(value) {
    dispatch({
        type: TYPES.SEARCH,
        value
    });
}

export function addProduto(value) {
    dispatch({
        type: TYPES.ADD_PRODUTO,
        value
    })

}
export function removeProduto(index) {
    dispatch({
        type: TYPES.REMOVE_PRODUTO,
        index
    })
}

export function addLoja(value) {
    dispatch({
        type: TYPES.ADD_LOJA,
        value
    })

}
export function removeLoja(index) {
    dispatch({
        type: TYPES.REMOVE_LOJA,
        index
    })
}

export function setInstituicoes(value){
    dispatch({
        type: TYPES.SET_INSTITUICOES,
        value
    })
}

export function setInstituicoesPesquisa(value){
    dispatch({
        type: TYPES.SET_INSTITUICOES_PESQUISA,
        value
    })
}

export function setProdutos(value){
    dispatch({
        type: TYPES.SET_PRODUTOS,
        value
    })
}

export function cleanProdutos(){
    dispatch({
        type: TYPES.CLEAN_PRODUTOS,
    })
}

export function setLojas(value){
    dispatch({
        type: TYPES.SET_LOJAS,
        value
    })
}

export function cleanLojas(){
    dispatch({
        type: TYPES.CLEAN_LOJAS,
    })
}

export function setModalFilial(campo, value) {
    dispatch({
        type: TYPES.SET_MODAL_FILIAL,
        campo,
        value
    })
}

export function setFiliais(value) {
    dispatch({
        type: TYPES.SET_FILIAIS,
        value
    })
}

export function getFiliais(value) {
    dispatch({
        type: TYPES.SET_GET_FILIAL,
        value
    })
}

export function setLojasPesquisa(value) {
    dispatch({
        type: TYPES.SET_LOJAS_PESQUISA,
        value
    })
}

export function setProdutosPesquisa(value) {
    dispatch({
        type: TYPES.SET_PRODUTOS_PESQUISA,
        value
    })
}

export function setConfigTable(value){
    dispatch({
        type: TYPES.SET_CONFIG_TABLE,
        value
    })
}

export function setPageSize(value){
    dispatch({
        type: TYPES.SET_CONFIG_TABLE,
        value
    })
}

export function setQuantidadeTotal(value){
    dispatch({
        type: TYPES.SET_QUANTIDADE_TOTAL,
        value
    })
}

export function setTotalPaginas(value){
    dispatch({
        type: TYPES.SET_TOTAL_PAGINAS,
        value
    })
}

export function setPageSelected(value){
    dispatch({
        type: TYPES.SET_PAGE_SELECTED,
        value
    })
}