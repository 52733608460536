import api from '../util/api'
import * as actions from '../store/modules/inconsistenciaFormalizacao/actions'
import { setLoading } from '../store/modules/globalLoading/actions'

export async function getQuantidade(inconsistencias) {

    setLoading(true)
    const data = await api.get(`inconsistencia-sistema-formalizacao/quantidades/possivel-corrigir`)
    setLoading(false)

    if (!data.success) {
        return
    }

    inconsistencias.forEach(inconsistencia =>{
        let inconsistenciaFound = data.content.find((e) => inconsistencia.id === e.id)
        if(inconsistenciaFound){
            inconsistencia.quantidade = inconsistenciaFound.quantidade
        }else{
            inconsistencia.quantidade = 0
        }
    })

    actions.setTabs(inconsistencias)
};


export async function getInconsistenciasPaginado(numeroProposta,page){

    setLoading(true)
    const data = await api.get(`inconsistencia-sistema-formalizacao/proposta-paginada?pagina=${page}${numeroProposta !== '' ? `&numeroProposta=${numeroProposta}` : ''}`)
    setLoading(false)
    if (!data.success) {
        return 
    }
    const propostas = data.content.registros.map((item)=>{
        return ({
            id: item.id,
            descricao: item.descricao,
            bancoId: item.bancoId,
            nomeBanco: item.nomeBanco,
            nomeArquivo: item.nomeArquivo,
            parametro: item.parametro
        })
    });
    if(page === 1){
        actions.setIsFirstPage(true)
    }else{
        actions.setIsFirstPage(false)
    }
    actions.setNumerosPropostas(propostas)
    actions.getAllItems({ quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas,
        tamanhoPagina: data.content.tamanhoPagina })
    return
}

export async function getInconsistencias(tab){

    setLoading(true)
    const data = await api.get(`inconsistencia-sistema-formalizacao/tipo-inconsistencia/${tab.id}`)

    if (!data.success) {
        return setLoading(false)
    }

    if(tab.tab === "1"){
        const propostas = data.content.map((item)=>{
            return ({
                id: item.id,
                descricao: item.descricao,
                bancoId: item.bancoId,
                nomeBanco: item.nomeBanco,
                nomeArquivo: item.nomeArquivo
            })
        });
        actions.setCodConsistencias(propostas)
        return setLoading(false)
    }
}

export async function correcaoInconsistencia(inconsistencia, tab) {
    let body = {
        tipoInconsistenciaId: tab.id,
        descricao: inconsistencia.descricao,
        bancoId: inconsistencia.bancoId,
        parametro: inconsistencia.parametro
    }
   

    setLoading(true)
    const data = await api.post("inconsistencia-sistema-formalizacao/correcao", body);
    setLoading(false)

    if (!data.success) {
    return;
    }

    getInconsistencias(tab)
}