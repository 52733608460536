import React, { useMemo, useState } from "react";
import * as BiIcons from "react-icons/bi";
import * as papeisActions from "../../../../../../../store/modules/personas/papeis_store/actions";
import * as personasController from "../../../../../../../controllers/personasController/personasController";
import {useSelector} from "react-redux";
import {useTable, useSortBy} from "react-table";
import {RiArrowDownSLine, RiArrowUpSLine} from "react-icons/ri";
import {BsArrowDown, BsArrowUp, BsArrowDownUp} from 'react-icons/bs';
import {Button, Collapse, Modal, ModalBody, ModalHeader, Row, Col, FormGroup, Label, Input} from "reactstrap";
import {COLUMNS_PESSOAS} from "../../../../../constants/columns";
import {maskDate} from "../../../../../../../util/masks";
import * as AiIcons from "react-icons/ai";
import {Select} from "../../../../../../../components/Inputs/Input";

function TablePersona({pessoas}) {

    const data = pessoas;

    return (
        <>
            <div style={{display: 'flex', width: '100%', marginTop: '20px'}}>
                <div style={{
                    background: '#909394',
                    color: '#edf1f3',
                    padding: '10px 1px 5px 14px',
                    height: '35px',
                    width: '2%'
                }}>
                </div>
                <div style={{
                    background: '#909394',
                    color: '#edf1f3',
                    padding: '10px 1px 5px 14px',
                    height: '35px',
                    fontWeight: 700,
                    width: '38%'
                }}>
                    Nível Hierárquico
                </div>
                <div style={{
                    background: '#909394',
                    color: '#edf1f3',
                    padding: '10px 1px 5px 14px',
                    height: '35px',
                    fontWeight: 700,
                    width: '40%'
                }}>
                    Funcionário
                </div>
                <div style={{
                    background: '#909394',
                    color: '#edf1f3',
                    padding: '10px 1px 5px 14px',
                    height: '35px',
                    fontWeight: 700,
                    width: '20%'
                }}>
                    Headcount
                </div>
                <div style={{
                    background: '#909394',
                    color: '#edf1f3',
                    padding: '10px 1px 5px 14px',
                    height: '35px',
                    fontWeight: 700,
                    width: '20%'
                }}>
                    Status
                </div>
                <div style={{
                    background: '#909394',
                    color: '#edf1f3',
                    padding: '10px 1px 5px 14px',
                    height: '35px',
                    fontWeight: 700,
                    width: '38%'
                }}>
                    Alterar Hierarquia
                </div>
            </div>
            {data.map((e, i) => {
                return (
                    <CollapsedRow pessoa={e} index={i} pessoas={data}/>
                )
            })}
        </>
    )
}

export default TablePersona

function CollapsedRow({pessoa, index, pessoas}) {
    const {
        niveisHierarquicos,
        usuariosFuncionarios,
        hierarquiaParaAlterar,
        tipoLoja,
        personasSelected
    } = useSelector(state => state.personasPapeis);
    const {informacao} = useSelector(state => state.personasInformacoes)
    const [isRowOpen, setIsRowOpen] = useState(false)
    const [centroCusto, setCentroCusto] = useState('');

    async function handleRemoverItem(row, index) {
        let valid = await personasController.deletePersonas(row.id)
        if (valid) {
            if (pessoa.body.length === 1) {
                const arrPersona = pessoas;
                arrPersona.splice(index, 1);
                papeisActions.setPersonas(arrPersona);
            } else {
                const arrPersona = pessoas
                arrPersona[index].body = arrPersona[index].body.filter((body) => body.id !== row.id);
                papeisActions.setPersonas(arrPersona);
            }
        }
    }

    function setIsEditable(row) {
        row.isEditable = true
        let pessoa = usuariosFuncionarios.find(e => e.id === row.pessoaId)
        papeisActions.pessoa({
            dataVigenciaFinal: row.dataVigenciaFinal ? maskDate(row.dataVigenciaFinal, 'YYYY-MM-DD') : "",
            dataVigenciaInicial: maskDate(row.dataVigenciaInicial, 'YYYY-MM-DD'),
            headCount: row.headCount,
            hierarquiaId: row.hierarquiaId,
            id: row.id,
            pessoa: pessoa.nome,
            pessoaId: row.pessoaId,
            usuarioBancoId: row.usuarioBanco?.id ?? null,
            usuarioBanco: row.usuarioBanco?.usuarioInstituicao ?? '',
            centroCustoId: row.centroCustoId ?? '0',
            centroCusto: row.centroCusto ?? '',
            isEditable: row.isEditable,
            isDataFinalEditable: row.dataVigenciaFinal ? true : row?.usuarioBanco?.possuiDataFim ?? false
        });
    }

    const columns = useMemo(() => COLUMNS_PESSOAS, []);
    let data = pessoa.body;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
            columns,
            data
        },
        useSortBy);

    const [modalHierarquia, setModalHierarquia] = useState(false);
    const [modalConfirmacaoHierarquia, setModalConfirmacaoHierarquia] = useState(false);

    function handleActiveRow() {
        setIsRowOpen(!isRowOpen)
    }

    function toggleModalConfirmacaoHierarquia() {
        setModalConfirmacaoHierarquia(!modalConfirmacaoHierarquia);
    }

    function toggleModalHierarquia() {
        setModalHierarquia(!modalHierarquia);
    }

    function getStatusLojaPessoaPorHierarquia() {
        if (data.some(d => !d.dataVigenciaFinal)) {
            return 'Ativo'
        }

        data.some(d => {
            const actualDate = new Date()
            const targetDate = new Date(d.dataVigenciaFinal);

            return targetDate > actualDate ? 'Ativo' : 'Inativo'
        })

        return 'Inativo'
    }

    function alteraHierarquia(pessoa) {
        let centroCustoParaSetar = pessoa.body.find(b => b.centroCustoId !== undefined);
        let hierarquiaAntigaParaSetar = pessoa.body.find(b => b.hierarquiaId !== undefined);

        onChangeHierarquia(hierarquiaAntigaParaSetar.hierarquiaId, 'hierarquiaAntigaId');
        onChangeHierarquia(tipoLoja.id, 'lojaId');
        onChangeHierarquia(hierarquiaAntigaParaSetar.pessoaId, 'pessoaId');

        if (centroCustoParaSetar !== undefined && centroCustoParaSetar.centroCusto !== undefined) {
            setCentroCusto(centroCustoParaSetar.centroCusto);
        } else {
            setCentroCusto('');
        }
        toggleModalHierarquia();
    }

    function onChangeHierarquia(value, field) {
        papeisActions.setAlteraHierarquia(value, field);
    }

    async function handleVerificaHierarquia() {
        let retorno = await personasController.verificaHierarquia(hierarquiaParaAlterar);

        if (retorno.jaPossuiLojaPessoaComHierarquiaEnviada
            && !retorno.alteracaoConfirmada) {
            toggleModalConfirmacaoHierarquia();
        } else {
            await handleUpdateHierarquia();
            toggleModalHierarquia();
        }
    }


    async function handleUpdateHierarquia() {
        onChangeHierarquia(true, 'alteracaoConfirmada');
        let isSuccess = await personasController.updateHierarquia(
            {
                ...hierarquiaParaAlterar,
                alteracaoConfirmada: true
            });

        if (isSuccess) {

            let personasSelectRegistro = personasSelected;
            papeisActions.cleanPersonas();

            pessoa.header.hierarquiaId =  hierarquiaParaAlterar.hierarquiaNovaId;

            pessoa.body.forEach((pessoa) => {
                pessoa.hierarquiaId = hierarquiaParaAlterar.hierarquiaNovaId;
            });

            let personasNovaHierarquiaBody = [];
            let personasNovaHierarquiaHeader = null

            personasSelectRegistro.forEach((persona) => {
                persona.body.forEach((personaBody) => {
                    if (personaBody.hierarquiaId === hierarquiaParaAlterar.hierarquiaNovaId) {
                        personasNovaHierarquiaBody.push(personaBody);
                        personasNovaHierarquiaHeader = persona.header;
                    }
                });
            });

            let personasAtualizar =
                {
                    header: personasNovaHierarquiaHeader,
                    body: personasNovaHierarquiaBody
                };

            let novaPersonaSelect = personasSelectRegistro.filter((persona) => {
                return !persona.body.some((personaBody) => {
                    return personaBody.hierarquiaId === hierarquiaParaAlterar.hierarquiaNovaId;
                })
            });

            novaPersonaSelect.push(personasAtualizar);
            papeisActions.setPersonas(novaPersonaSelect)

            data = pessoa.body;
        }

        papeisActions.cleanPersona();
    }

    function fadeToggleModals() {
        papeisActions.cleanAlteraHierarquia();
        toggleModalConfirmacaoHierarquia();
        toggleModalHierarquia();
    }

    var nivelHierarquico = niveisHierarquicos.find(e => e.id === pessoa.header.hierarquiaId);
    var usuario = usuariosFuncionarios.find(e => e.id === pessoa.header.pessoaId);
    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalConfirmacaoHierarquia}
                toggle={toggleModalConfirmacaoHierarquia}
                backdrop={false}
            >
                <ModalHeader toggle={toggleModalConfirmacaoHierarquia} style={{height: '10px', border: 'none'}}/>
                <ModalBody>
                    <p style={{fontSize: '18px', color: 'var(--color-preto)'}}>Essa pessoa já possui a hierarquia
                        escolhida! Deseja <strong
                            style={{fontSize: '17px'}}>realmente alterar</strong> essa pessoa?</p>
                </ModalBody>
                <div className="m-3">
                    <Button onClick={() => {
                        fadeToggleModals();
                    }}
                            color="danger"
                            style={{width: "100px", marginRight: "15px",}}>
                        Cancelar
                    </Button>
                    <Button onClick={() => {
                        handleUpdateHierarquia()
                            .then(() => fadeToggleModals())
                    }}
                            style={{
                                width: "100px",
                                marginLeft: "15px",
                                background: 'var(--color-verde)',
                                border: 'none'
                            }}>
                        Confirmar
                    </Button>
                </div>
            </Modal>
            <Modal
                className="text-center"
                isOpen={modalHierarquia}
                toggle={toggleModalHierarquia}
                backdrop={false}
            >
                <ModalHeader toggle={toggleModalHierarquia} style={{height: '10px', border: 'none'}}/>
                <ModalBody>
                    <p style={{fontSize: '18px', color: 'var(--color-preto)'}}>Alteração de Hierarquia</p>
                    <Row>
                        <Col md={10} style={{margin: '0 0 0 25px'}}>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>Loja</Label>
                                        <Input
                                            type='text'
                                            value={informacao.nomeFantasia}
                                            disabled={true}
                                            placeholder={informacao.nomeFantasia}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label>Centro de Custo</Label>
                                        <Input
                                            type='text'
                                            value={centroCusto}
                                            disabled={true}
                                            placeholder={centroCusto}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col md={10} style={{margin: '0 0 0 25px'}}>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>Nível Hierárquico Antigo</Label>
                                        <Select
                                            first="Selecione"
                                            value={{value: hierarquiaParaAlterar.hierarquiaAntigaId}}
                                            select={{value: "id", label: "descricao"}}
                                            options={niveisHierarquicos}
                                            isSearchable={false}
                                            autoComplete="off"
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Label>Novo Nível Hierárquico</Label>
                                        <Select
                                            first="Selecione"
                                            value={{value: hierarquiaParaAlterar.hierarquiaNovaId}}
                                            select={{value: "id", label: "descricao"}}
                                            options={niveisHierarquicos}
                                            onChange={(e) => onChangeHierarquia(e.value, "hierarquiaNovaId")}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <div className="m-3">
                    <Button onClick={() => {
                        papeisActions.cleanAlteraHierarquia();
                        toggleModalHierarquia()
                    }
                    }
                            color="danger"
                            style={{width: "100px", marginRight: "15px",}}>
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => handleVerificaHierarquia()}
                        style={{
                            width: "100px",
                            marginLeft: "15px",
                            background: 'var(--color-verde)',
                            border: 'none'
                        }}>
                        Confirmar
                    </Button>
                </div>
            </Modal>
            <div>
                <div style={{display: 'flex', cursor: 'pointer'}} onClick={() => handleActiveRow()} key={index}>
                    <div style={{
                        padding: '15px 7px',
                        width: '2%',
                        minHeight: '45px',
                        borderBottom: '1px solid #CBD0D1',
                        cursor: 'pointer'
                    }}>
                        {isRowOpen ? <RiArrowUpSLine/> : <RiArrowDownSLine/>}
                    </div>
                    <div style={{
                        padding: '15px 7px',
                        width: '38%',
                        minHeight: '45px',
                        borderBottom: '1px solid #CBD0D1',
                        color: '#81888A'
                    }}>
                        {nivelHierarquico !== undefined && nivelHierarquico.descricao}
                    </div>
                    <div style={{
                        padding: '15px 7px',
                        width: '40%',
                        minHeight: '45px',
                        borderBottom: '1px solid #CBD0D1',
                        color: '#81888A'
                    }}>
                        {usuario !== undefined && usuario.nome}
                    </div>
                    <div style={{
                        padding: '15px 7px',
                        width: '20%',
                        minHeight: '45px',
                        borderBottom: '1px solid #CBD0D1',
                        color: '#81888A'
                    }}>
                        {pessoa.header.headCount === true ? 'Sim' : 'Não'}
                    </div>
                    <div style={{
                        padding: '15px 7px',
                        width: '20%',
                        minHeight: '45px',
                        borderBottom: '1px solid #CBD0D1',
                        color: '#81888A'
                    }}>
                        {getStatusLojaPessoaPorHierarquia()}
                    </div>
                    <div style={{
                        padding: '15px 7px',
                        width: '38%',
                        minHeight: '45px',
                        borderBottom: '1px solid #CBD0D1',
                        color: '#81888A'
                    }}>
                        <Button
                            style={{
                                width: "186px",
                                background: "var(--color-branco)",
                                borderColor: "var(--color-verde)",
                                color: "var(--color-verde)",
                                borderRadius: "7px",
                            }}
                            onClick={() => {
                                alteraHierarquia(pessoa)
                            }}>
                            <AiIcons.AiOutlineArrowsAlt
                                style={{
                                    fontSize: "1.5rem",
                                    marginRight: "7px",
                                    color: "var(--color-verde)",
                                    cursor: "pointer",
                                }}
                            />
                            Alterar Hierarquia
                        </Button>
                    </div>
                </div>
                <Collapse isOpen={isRowOpen}>
                    <table style={{width: '100%'}} {...getTableProps()}>
                        <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th style={{background: '#909394', color: '#edf1f3', height: '30px'}}>
                                        {column.render("Header")}
                                        <span className="float-right">
                                                    {column.isSorted ?
                                                        (column.isSortedDesc ?
                                                            <BsArrowDown
                                                                {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
                                                            />
                                                            :
                                                            <BsArrowUp
                                                                id="OrdenarAsc"
                                                                {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
                                                            />)
                                                        :
                                                        <BsArrowDownUp
                                                            {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
                                                        />}
                                                </span>
                                    </th>
                                ))}
                                <th style={{
                                    background: '#909394',
                                    color: '#edf1f3',
                                    height: '30px',
                                    textAlign: 'center',
                                    width: "59px"
                                }}>
                                    Ações
                                </th>
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr className="text-center" {...row.getRowProps()}
                                    style={{fontSize: '10px', background: '#d4d8da'}}>
                                    {row.cells.map((cell) => {
                                        return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                    })}
                                    <td>
                                        <>
                                            <BiIcons.BiEdit
                                                style={{
                                                    fontSize: "1.2rem",
                                                    cursor: "pointer",
                                                    color: "#717375",
                                                    marginRight: "7px"
                                                }}
                                                onClick={() => setIsEditable(row.original)}
                                            />
                                            <BiIcons.BiTrash
                                                style={{fontSize: '1.2rem', cursor: 'pointer', color: '#717375'}}
                                                onClick={() => handleRemoverItem(row.original, index)}
                                            />
                                        </>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </Collapse>
            </div>
        </>
    )

}
