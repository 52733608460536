import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function item(value) {
    dispatch({
        type: TYPES.COMISSAO,
        value
    });
}

export function setItem(field, value) {
    dispatch({
        type: TYPES.SET_COMISSAO,
        field,
        value
    });
}

export function copy(value) {
    dispatch({
        type: TYPES.COMISSAO_COPY,
        value
    });
}

export function setInvalid(field) {
    dispatch({
        type: TYPES.INVALID_COMISSAO,
        field
    });
}

export function getItems(value) {
    dispatch({
        type: TYPES.COMISSOES,
        value
    });
}

export function setInstituicoes(value) {
    dispatch({
        type: TYPES.SET_INSTITUICOES,
        value
    });
}

export function setAllProdutosBanco(value) {
    dispatch({
        type: TYPES.SET_ALL_PRODUTOS_BANCO,
        value
    });
}

export function setProdutosBanco(value) {
    dispatch({
        type: TYPES.SET_PRODUTOS_BANCO,
        value
    });
}

export function setProdutosBancoII(value) {
    dispatch({
        type: TYPES.SET_PRODUTOS_BANCO_II,
        value
    });
}

export function getItemsPeriodicidades(value) {
    dispatch({
        type: TYPES.PERIODICIDADES,
        value
    });
}

export function getItemsBasesCalculos(value) {
    dispatch({
        type: TYPES.BASES_CALCULOS,
        value
    });
}

export function getItemsUnidadeComissao(value) {
    dispatch({
        type: TYPES.UNIDADE_COMISSAO,
        value
    });
}

export function cleanInvalid(field) {
    dispatch({
        type: TYPES.CLEAN_INVALID_COMISSAO,
        field
    })
}

export function cleanRegister() {
    dispatch({
        type: TYPES.CLEAN_REGISTER
    })
}

export function setActiveTab(value) {
    dispatch({
        type: TYPES.SET_ACTIVE_TAB,
        value
    })
}

export function setSeeRegister(value) {
    dispatch({
        type: TYPES.SET_SEE,
        value
    })
}

export function setTipoComissaoBanco(field, value) {
    dispatch({
        type: TYPES.SET_TIPO_COMISSAO_BANCO,
        field,
        value
    })
}

export function cleanTipoComissaoBanco() {
    dispatch({
        type: TYPES.CLEAN_TIPO_COMISSAO_BANCO,
    })
}

export function addTipoComissaoBanco(value) {
    dispatch({
        type: TYPES.ADD_TIPO_COMISSAO_BANCO,
        value
    })
}

export function removeTipoComissaoBanco(index) {
    dispatch({
        type: TYPES.REMOVE_TIPO_COMISSAO_BANCO,
        index
    })
}

export function cleanProdutosBanco() {
    dispatch({
        type: TYPES.CLEAN_PRODUTOS_BANCO
    })
}

export function setFiltros(field, value) {
    dispatch({
        type: TYPES.SET_FILTROS,
        field,
        value
    })
}