import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';

export default function Select({ options, disabled, onFocus, onChange, label, value, invalid }) {

     /***
     * @param options: array[{value: string, label: string}] - Opções do select
     * @param disabled: boolean - Desabilita o Select | Default: false
     * @param onFocus: Function - Dispara função ao clicar no Select
     * @param onChange: Function - Dispara funções ao mudar o valor do Select
     * @param label: string - Indica o valor que deve ficar visível ao usuário | Deve-se inserir o nome da chave do objeto
     * @param value: string - Indica o valor que será salvo
     * @param invalid: boolean - Indica visualmente se o campo está válido
     */

     
    const [optionsSelect, setOptionsSelect] = useState([{ value: '0', label: 'Selecione' }]);
    const [optionSelected, setOptionSelected] = useState({ value: '0', label: 'Selecione' });

    useEffect(() => {
        setOptionsSelect([{ value: '0', label: 'Selecione' }])
        setOptionSelected({ value: '0', label: 'Selecione' })
        options.map(option => setOptionsSelect(state => [...state, { value: option.id, label: option[label] }]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    useEffect(() => {
        if(!!optionsSelect){
            optionsSelect.forEach((option) => {
                if (option.value === value) {
                    setOptionSelected(option)
                }
            })
        }else{
            setOptionSelected([{ value: '0', label: 'Selecione' }])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsSelect]);

    useEffect(() => {
        if (value === '0') {
            setOptionSelected({ value: '0', label: 'Selecione' })
        }else{
            setOptionSelected(optionsSelect.find((option) => option.value === value))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    function onSelect(option) {
        setOptionSelected(option);
    }

    return (
        <>
            <ReactSelect
                defaultValue={optionsSelect[0]}
                value={optionSelected}
                options={optionsSelect}
                onFocus={onFocus}
                onChange={(e) => { onChange(e); onSelect(e) }}
                autoComplete="off"
                isDisabled={disabled}
                styles={{
                    control: (base) => ({
                        ...base,
                        borderColor: invalid ? "#ff0000" : "#d3d3d3",
                        color: invalid ? "#ff0000" : "#000000",
                        minHeight: "35px",
                        height: "35px"
                    })
                }}
            />
        </>
    )
}
