import React, { useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { useSelector } from 'react-redux';
import { Col, Row, FormGroup, Label, Input, Button, Collapse } from 'reactstrap'

import { Select, Switch } from '../../../components/Inputs/Input'
import BigNumber from './components/BigNumbers';
import ModalLoja from './components/ModalLoja.jsx';
import ModalConsultores from './components/ModalConsultores';
import Grafico from './components/Grafico';
import Tabela from './components/Tabela'
import Tabela2 from './components/Tabela2'

import * as actions from '../../../store/modules/dashboard/receita/actions'
import * as controller from '../../../controllers/dashboardController/receitaController'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';


export default function Receita(){
    const { filtros, bigNumbers, graficoProjetado, graficoProduto, tabelaProducao, tabelaProd, instituicoes, tiposOperacao, parcerias, convenios, grupos } = useSelector(state => state.dashboardReceita);

    useEffect(()=>{
        controller.getConvenios()
        controller.getGrupos()
        controller.getInstituicoes()
        controller.getParcerias()
        controller.getTiposOperacao()
    },[])

    const [modalLoja, setModalLoja] = useState(false)
    const [modalConsultor, setModalConsultor] = useState(false)
    const [isTabelaOpen, setIsTabelaOpen] = useState(false)
    const [isGraficoOpen, setIsGraficoOpen] = useState(false)

    const toggleLoja = () => setModalLoja(!modalLoja)
    const toggleConsultor = () => setModalConsultor(!modalConsultor)

    async function onChangeFiltros(value, campo){
        actions.setFiltros(campo, value)
    }

    async function handleOnChangePeriodo(value){
        actions.setFiltros('periodo', value)
        filtros.periodo = value
        controller.getGraficoProjetado(filtros)
        controller.getGraficoProduto(filtros)
    }

    async function handleOnChangePeriodoTabela(value){
        actions.setFiltros('periodoTabela', value)
        filtros.periodoTabela = value
        controller.getTabelaReceitaBancos(filtros)
        controller.getTabelaReceitaProdutos(filtros)
    }
    return(
        <>
            <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '0 20px 0 20px', padding: '20px'}}>
                <p style={{fontSize: '16px', fontWeight: '700'}}>Filtros</p>
                <div>
                    <Row style={{marginRight: '-45px'}}>
                        <Col md={3}>
                            <FormGroup>
                                <Label style={{fontWeight: '700'}}>Instituição Financeira</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.instituicaoId }}
                                    select={{ value: "id", label: "nome" }}
                                    options={instituicoes}
                                    onChange={(e) => {onChangeFiltros(e.value, 'instituicaoId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label style={{fontWeight: '700'}}>Loja</Label>
                                <Input
                                 type="text"
                                 placeholder="Busque um favorecido..."
                                 value={filtros.loja}
                                 disabled
                                />
                            </FormGroup>
                        </Col>
                        <AiOutlineSearch
                            style={{color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer'}}
                            onClick={()=> toggleLoja()}
                        />
                          <Col md={2}>
                            <FormGroup>
                                <Label style={{fontWeight: '700'}}>Grupo</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: filtros.grupoId }}
                                    select={{ value: "id", label: "descricao" }}
                                    options={grupos}
                                    onChange={(e) => {onChangeFiltros(e.value, 'grupoId')}}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                                <FormGroup>
                                    <Label style={{fontWeight: '700'}}>Consultor</Label>
                                    <Input
                                    type="text"
                                    placeholder="Busque um consultor..."
                                    value={filtros.consultor}
                                    disabled
                                    />
                                </FormGroup>
                            </Col>
                            <AiOutlineSearch
                                style={{color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer'}}
                                onClick={()=> toggleConsultor()}
                            />
                    </Row>
                    <Row style={{marginRight: '-45px'}}>
                    {filtros.filtrosAvancados && (
                        <>
                            <Col md={3}>
                                <FormGroup>
                                    <Label style={{fontWeight: '700'}}>Tipo de Operação</Label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: filtros.tipoOperacaoId }}
                                        select={{ value: "id", label: "descricao" }}
                                        options={tiposOperacao}
                                        onChange={(e) => {onChangeFiltros(e.value, 'tipoOperacaoId')}}
                                        isSearchable={true}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label style={{fontWeight: '700'}}>Parceria</Label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: filtros.parceriaId }}
                                        select={{ value: "id", label: "nome" }}
                                        options={parcerias}
                                        onChange={(e) => {onChangeFiltros(e.value, 'parceriaId')}}
                                        isSearchable={true}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label style={{fontWeight: '700'}}>Cônvenio</Label>
                                    <Select
                                        first="Selecione"
                                        value={{ value: filtros.convenioId }}
                                        select={{ value: "id", label: "descricao" }}
                                        options={convenios}
                                        onChange={(e) => {onChangeFiltros(e.value, 'convenioId')}}
                                        isSearchable={true}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            </Col>
                        </>
                        )}
                    </Row>
                </div>
                <div style={{borderTop: '1px solid #C4C7CA', paddingTop: '20px'}}>
                    <Row>
                        <Col md={2}>
                            <Label style={{fontWeight: '700'}}>Exibir filtros avançados</Label>
                            <div style={{ marginTop: '-20px', marginLeft: '-10px' }}>
                                <Switch
                                    className='switch mt-4'
                                    checked={filtros.filtrosAvancados}
                                    onClick={() => onChangeFiltros(!filtros.filtrosAvancados, 'filtrosAvancados')}
                                />
                            </div>
                        </Col>
                        <Col>
                        <Button
                                className="purpleButton"
                                style={{ width: "150px", float: 'right' }}
                                onClick={() => controller.get(filtros)}>
                                Aplicar
                            </Button>
                            <Button className="mr-4"
                                style={{
                                    width: "150px",
                                    background: "var(--color-branco)",
                                    borderColor: "var(--color-laranja)",
                                    color: "var(--color-laranja)",
                                    borderRadius: "7px",
                                    float: 'right'
                                }}
                                onClick={() => actions.cleanFiltros()}>
                                Limpar Filtros
                                </Button>
                        </Col>
                        </Row>
                    </div>
                </div>
                <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px'}}>
                    <p style={{fontSize: '16px', fontWeight: '700'}}>Receita </p>
                    <div style={{display: 'flex'}}>
                        {bigNumbers.map((e)=>{
                            return(
                                <BigNumber element={e}/>
                            
                            )
                        })}
                    </div>
                </div>
                <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px'}}>
                    <p onClick={() => setIsTabelaOpen(!isTabelaOpen)} style={{fontSize: '16px', fontWeight: '700', cursor: 'pointer'}}>
                    {isTabelaOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine/>} Gráfico de receita
                    </p>
                    <Collapse isOpen={isTabelaOpen}>
                        <Row>
                                <Col md={3} style={{marginLeft: '75%'}}>
                                    <FormGroup>
                                        <Label style={{fontWeight: '700'}}>Período</Label>
                                        <Select
                                            value={{ value: filtros.priodo }}
                                            select={{ value: "id", label: "descricao" }}
                                            first='3 meses'
                                            options={[{id: '6', descricao: '6 meses'},{id: '12', descricao: '12 meses'}]}
                                            onChange={(e) => handleOnChangePeriodo(e.value)}
                                            isSearchable={true}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {graficoProjetado.length > 1 && <Grafico data={graficoProjetado}/>}
                            {graficoProduto.length > 1 && <Grafico data={graficoProduto}/>}
                    </Collapse>
                </div>
                <div style={{border: '1px solid #C4C7CA', borderRadius: '8px',margin: '20px 20px 0 20px', padding: '20px'}}>
                    <p onClick={() => setIsGraficoOpen(!isGraficoOpen)} style={{fontSize: '16px', fontWeight: '700', cursor: 'pointer'}}>
                        {isGraficoOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine/>} Tabela de receita por tipo de produção 
                    </p>
                   <Collapse isOpen={isGraficoOpen}>
                   <Row>
                        <Col md={3} style={{marginLeft: '75%'}}>
                            <FormGroup>
                                <Label style={{fontWeight: '700'}}>Período</Label>
                                <Select
                                    value={{ value: filtros.priodo }}
                                    select={{ value: "id", label: "descricao" }}
                                    first='3 meses'
                                    options={[{id: '6', descricao: '6 meses'},{id: '12', descricao: '12 meses'}]}
                                    onChange={(e) => handleOnChangePeriodoTabela(e.value)}
                                    isSearchable={true}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {tabelaProducao.length > 0 && <Tabela data={tabelaProducao}/>}
                    {tabelaProd.length > 0 && <Tabela2 data={tabelaProd}/>}
                    </Collapse>
                </div>
            {
                <ModalLoja
                    modalPessoa={modalLoja}
                    toggle={toggleLoja} />
            }
            {
                <ModalConsultores
                    isOpen={modalConsultor}
                    toggle={toggleConsultor} />
            }
            </>
    )
}