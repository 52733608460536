export const COLUMNS = [
    {
        Header: "Arquivo",
        accessor: "nomeAutomacao"
    },
    {
        Header: "Instituição Financeira",
        accessor: "instituicao"
    },
    {
        Header: "Layout",
        accessor: "descricao"
    }
]

export const COLUMNS_LOG = [
    {
        Header: "Data da tentativa",
        accessor: "dataCriacao"
    },
    {
        Header: "Status",
        accessor: "status"
    },
    {
        Header: "Detalhes",
        accessor: "detalhes"
    }
]