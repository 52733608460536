import React from 'react'
import './style.css'

const TopLoading = () => {
    return (
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    )
}

export default TopLoading
