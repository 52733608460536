import React, { useEffect, useMemo, useState } from "react";
import Dropzone from 'react-dropzone'
import { AiFillFolderAdd } from 'react-icons/ai';
import { HiDownload } from 'react-icons/hi';
import { RiFolderUploadFill } from 'react-icons/ri'
import { Card, Col, Row, Label, Input } from "reactstrap";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import * as BsIcons from "react-icons/bs";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { COLUMNS_INCONSISTENCIAS } from "../constants/columns"

import { uniqueId } from 'lodash'
import filesize from 'filesize'

import { Select } from '../../../components/Inputs/Input'
import * as actions from "../../../store/modules/uploadComissao/actions";
import * as controller from "../../../controllers/uploadComissao";

import { DropContainer, FileInfo, ListContainer, UploadMessage } from '../styles'

const Register = () => {
    const { tiposArquivo, pesquisa, bancos, getAllItemsInconsistencia, item, seeRegister, layouts, item: { arquivos }, layoutsPlanilha, possuiInconsistencia } = useSelector(state => state.uploadComissao)
    const columns = useMemo(() => COLUMNS_INCONSISTENCIAS, [])
    const data = item.inconsistencias;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    const { pageSize } = state;
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        controller.getByIdPaginado(item.id, pageSelected + 1, pageSize);
    }

    useEffect(() => {
        if (getAllItemsInconsistencia.tamanhoPagina) {
            setPageSize(getAllItemsInconsistencia.tamanhoPagina);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllItemsInconsistencia])

    const renderDragMessage = (dragActive, dragReject) => {
        if (!dragActive) {
            return (
                <>
                    <RiFolderUploadFill style={{ fontSize: '60px', marginLeft: '47%' }} />
                    <UploadMessage>Arraste e solte aqui ou clique para Selecionar</UploadMessage>
                </>
            )
        }
        if (dragReject) {
            return <UploadMessage type='error' style={{ marginLeft: '40%', fontSize: '18px', marginTop: '3%' }}>Arquivo não suportado</UploadMessage>
        }

        return (
            <>
                <AiFillFolderAdd style={{ fontSize: '60px', marginLeft: '47%', color: 'var(--color-verde)' }} />
                <UploadMessage type='success' style={{ marginLeft: '43%', fontSize: '18px' }}>Solte os arquivos aqui</UploadMessage>
            </>
        )
    }

    const handleUpload = files => {
        const uploadFiles = files.map(file => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
        }))
        actions.setItem('arquivos', arquivos.concat(uploadFiles))
    }

    var extensao = ''
    if (item.nome) {
        extensao = item.nome.substring(item.nome.indexOf('.') + 1, item.nome.length)
    }
    useEffect(() => {
        controller.getTiposArquivo()
        controller.getBancos()
        if (item.tipoArquivo !== "0") {
            controller.getLayoutsPlanilha(item.tipoArquivo)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        async function getLayoutPlanilha() {
            await controller.getLayoutsPlanilha(item.tipoArquivo)
        }
        if (item.tipoArquivo !== "0") {
            getLayoutPlanilha()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.tipoArquivo, item.layoutPlanilhaId])

    function handleDelete(i) {
        arquivos.splice(i, 1)
        actions.setItem('arquivos', arquivos)
    }

    function handleInitialPage(pageSize) {
        const pageSelected = 0;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        controller.getByIdPaginado(item.id, pageSelected + 1, pageSize);
    }

    useEffect(() => {
        console.log(item, possuiInconsistencia)

    }, [item, possuiInconsistencia])

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2">
                        <Row>
                            <Col md={4}>
                                <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Tipo de Arquivo</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: item.tipoArquivo }}
                                    select={{ value: "id", label: "descricao" }}
                                    options={tiposArquivo}
                                    onChange={(e) => {
                                        actions.setItem('tipoArquivo', e.value)
                                        actions.setItem('layoutPlanilhaId', '0')
                                        actions.setItem('instituicao', '0')
                                    }}
                                    autoComplete="off"
                                    disabled={seeRegister}
                                />
                            </Col>
                            <Col md={4}>
                                <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Layout de Parametrização</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: item.layoutPlanilhaId }}
                                    select={{ value: "id", label: "descricao" }}
                                    options={layouts}
                                    onChange={(e) => {
                                        if (item.tipoArquivo !== "0" && item.id === '') {
                                            actions.setItem('layoutPlanilhaId', e.value)
                                            actions.setItem('instituicao', '0')
                                            let found = layouts.find(layout => layout.id === e.value)
                                            actions.setItem('instituicao', found.bancoId)
                                        }

                                    }}
                                    autoComplete="off"
                                    disabled={seeRegister ? true : item.tipoArquivo === "0"}
                                />
                            </Col>
                            <Col md={4}>
                                <Label style={{ fontWeight: '700', color: 'var(--color-preto)' }}>Instituição Financeira</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: item.instituicao }}
                                    select={{ value: "id", label: "nome" }}
                                    options={bancos}
                                    autoComplete="off"
                                    disabled
                                />
                            </Col>
                        </Row>
                        {!possuiInconsistencia && (
                            <Col>
                                <Row>
                                    <Dropzone style={{ width: "100% !important", margin: "20px 1rem" }} disabled={seeRegister} accept='text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' onDropAccepted={handleUpload}>
                                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                            <DropContainer
                                                {...getRootProps()}
                                                isDragActive={isDragActive}
                                                isDragReject={isDragReject}
                                            >
                                                <input {...getInputProps()} />
                                                {renderDragMessage(isDragActive, isDragReject)}
                                            </DropContainer>
                                        )}
                                    </Dropzone>
                                </Row>
                                {!!arquivos.length && (
                                    <Row>
                                        <ListContainer>
                                            {arquivos.map((uploadedFile, i) => (
                                                <li key={uploadedFile.id}>
                                                    <FileInfo>
                                                        <div>
                                                            <strong>{uploadedFile.name}</strong>
                                                            <span>
                                                                {uploadedFile.readableSize}
                                                                <button onClick={() => handleDelete(i)}>
                                                                    Excluir
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </FileInfo>
                                                </li>
                                            ))}
                                        </ListContainer>
                                    </Row>
                                )}
                                {!!item.arquivo64 && (
                                    <ListContainer>
                                        <li key={item.id}>
                                            <FileInfo>
                                                <div>
                                                    <strong>{item.nome}</strong>
                                                </div>
                                                <a href={`data:application/${extensao};base64,${item.arquivo64}`} download={item.nome}>
                                                    <HiDownload style={{ color: 'var(--color-laranja)', height: '20px', width: '20px' }} />
                                                </a>
                                            </FileInfo>
                                        </li>
                                    </ListContainer>
                                )}
                            </Col>
                        )
                        }
                        {possuiInconsistencia && (
                            <Col style={{ marginTop: "25px" }}>
                                <table {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th>
                                                        {column.render("Header")}
                                                        <span className="float-right">
                                                            {column.isSorted ?
                                                                (column.isSortedDesc ?
                                                                    <BsIcons.BsArrowDown
                                                                        {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                                    />
                                                                    :
                                                                    <BsIcons.BsArrowUp
                                                                        id="OrdenarAsc"
                                                                        {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                                    />)
                                                                :
                                                                <BsIcons.BsArrowDownUp
                                                                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                                />}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map(row => {
                                            prepareRow(row)
                                            return (
                                                <tr className="text-center" {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <Row className="pt-4">
                                    <Col>
                                        <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {getAllItemsInconsistencia.quantidade ? getAllItemsInconsistencia.quantidade : "0"} de {getAllItemsInconsistencia.quantidadeTotal ? getAllItemsInconsistencia.quantidadeTotal : "0"}</span>
                                    </Col>
                                    <Col md={8}>
                                        <Row className="float-right">
                                            <ReactPaginate
                                                previousLabel={'<'}
                                                nextLabel={'>'}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                initialPage={0}
                                                pageCount={getAllItemsInconsistencia.totalPaginas}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"}
                                                forcePage={initialPage ? 0 : selectedPage}
                                            />
                                            <Input
                                                type="select"
                                                className="pageSize"
                                                value={pageSize}
                                                onChange={e => { handleInitialPage(e.target.value); setPageSize(Number(e.target.value)) }}>
                                                {
                                                    [10, 25, 100].map(pageSize => (
                                                        <option key={pageSize} value={pageSize}>
                                                            {pageSize}
                                                        </option>
                                                    ))
                                                }
                                            </Input>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                        )}

                    </Col>
                </Card>
            </Col>
        </>
    );
}

export default Register;