import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as produtoRcActions from "../store/modules/produtosRibercred/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function Get() {

    setLoading(true)
    const data = await api.get("produtos");
    setLoading(false)

    if (!data.success) {
        return;
    }

    produtoRcActions.getItems(data.content);
}

export async function GetById(id) {

    setLoading(true)
    const data = await api.get(`produtos/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    produtoRcActions.copy(data.content);
    produtoRcActions.item('id', data.content.id);
    produtoRcActions.item('nome',data.content.nome);
}


export async function Create(item) {

    if (item.nome.trim() === "") {
        produtoRcActions.setInvalid("nome");
        return notifyWarn("Descrição do produto ribercred não pode estar em branco");
    }

    const body = {
        nome: item.nome,
    }

    setLoading(true)
    const data = await api.post("produtos", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Produto ribercred cadastrado");
    produtoRcActions.cleanRegister();
    Get();
}

export async function Delete(id) {

    setLoading(true)
    const data = await api.delete(`produtos/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Produto ribercred removido");
    Get();
}

export async function DeleteTipoComissao(id, idComissao) {

    setLoading(true)
    const data = await api.delete(`produtos/${id}/tipos-comissao/${idComissao}`);
    setLoading(false)

    if (!data.success) {
        return false;
    }

    notifySuccess("Tipo Comissão removida");
    Get();
    return true
}

export async function Update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        return notifyInfo("Nada foi alterado");
    }

    if (item.nome.trim() === "") {
        produtoRcActions.setInvalid("nome");
        return notifyWarn("Descrição do produto não pode estar em branco");
    }

    const body = {
        nome: item.nome,
    }

    setLoading(true)
    const data = await api.put(`produtos/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Produto ribercred atualizado");
    produtoRcActions.cleanRegister();
    produtoRcActions.setActiveTab("Search");
    Get();
}
