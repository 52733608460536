const STORE_ID = "GRUPOSTELAS";

const TYPES = {
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  CLEAR_PERFIL_DE_ACESSO_BEING_CREATED_DATA:
    "CLEAR_PERFIL_DE_ACESSO_BEING_CREATED_DATA",
  CLEAR_PERFIL_DE_ACESSO_BEING_EDITED_DATA:
    "CLEAR_PERFIL_DE_ACESSO_BEING_EDITED_DATA",
  CLEAR_PERFIL_DE_ACESSO_BEING_VISUALIZED_DATA:
    "CLEAR_PERFIL_DE_ACESSO_BEING_VISUALIZED_DATA",
  SET_PERFIL_DE_ACESSO_BEING_EDITED_ID: "SET_PERFIL_DE_ACESSO_BEING_EDITED_ID",
  SET_PERFIL_DE_ACESSO_BEING_VISUALIZED_ID:
    "SET_PERFIL_DE_ACESSO_BEING_VISUALIZED_ID",
  SET_IS_IN_EDIT_MODE: "SET_IS_IN_EDIT_MODE",
  SET_IS_IN_VISUALIZE_MODE: "SET_IS_IN_VISUALIZE_MODE",
  SET_PERFIL_DE_ACESSO_BEING_CREATED_DATA:
    "SET_PERFIL_DE_ACESSO_BEING_CREATED_DATA",
  SET_PERFIL_DE_ACESSO_BEING_EDITED_DATA:
    "SET_PERFIL_DE_ACESSO_BEING_EDITED_DATA",
  SET_PERFIL_DE_ACESSO_BEING_VISUALIZED_DATA:
    "SET_PERFIL_DE_ACESSO_BEING_VISUALIZED_DATA",
  SET_PERFIS_DE_ACESSO: "SET_PERFIS_DE_ACESSO",
  SET_TELAS_DISPONIVEIS: "SET_TELAS_DISPONIVEIS",
  SET_LOJAS_DISPONIVEIS: "SET_LOJAS_DISPONIVEIS",
  SET_TELAS_DISPONIVEIS_INITIAL_VALUE_BACKUP:
    "SET_TELAS_DISPONIVEIS_INITIAL_VALUE_BACKUP",
  SET_LOJAS_DISPONIVEIS_INITIAL_VALUE_BACKUP:
    "SET_LOJAS_DISPONIVEIS_INITIAL_VALUE_BACKUP",
  SET_TELAS_SELECIONADAS: "SET_TELAS_SELECIONADAS",
  SET_LOJAS_SELECIONADAS: "SET_LOJAS_SELECIONADAS",
  SET_TELAS_ASSOCIADAS: "SET_TELAS_ASSOCIADAS",
  SET_LOJAS_ASSOCIADAS: "SET_LOJAS_ASSOCIADAS",
  SET_TELAS_DISPONIVEIS_SEARCH_TERM: "SET_TELAS_DISPONIVEIS_SEARCH_TERM",
  SET_LOJAS_DISPONIVEIS_SEARCH_TERM: "SET_LOJAS_DISPONIVEIS_SEARCH_TERM",
  SET_TELAS_DISPONIVEIS_SEARCH_RESULTS: "SET_TELAS_DISPONIVEIS_SEARCH_RESULTS",
  SET_LOJAS_DISPONIVEIS_SEARCH_RESULTS: "SET_LOJAS_DISPONIVEIS_SEARCH_RESULTS",
  SET_TELAS_DISPONIVEIS_SELECTED_SEARCH_RESULTS:
    "SET_TELAS_DISPONIVEIS_SELECTED_SEARCH_RESULTS",
  SET_LOJAS_DISPONIVEIS_SELECTED_SEARCH_RESULTS:
    "SET_LOJAS_DISPONIVEIS_SELECTED_SEARCH_RESULTS",
  SET_TELAS_ASSOCIADAS_SEARCH_TERM: "SET_TELAS_ASSOCIADAS_SEARCH_TERM",
  SET_LOJAS_ASSOCIADAS_SEARCH_TERM: "SET_LOJAS_ASSOCIADAS_SEARCH_TERM",
  SET_TELAS_ASSOCIADAS_SEARCH_RESULTS: "SET_TELAS_ASSOCIADAS_SEARCH_RESULTS",
  SET_LOJAS_ASSOCIADAS_SEARCH_RESULTS: "SET_LOJAS_ASSOCIADAS_SEARCH_RESULTS",
  SET_TELAS_ASSOCIADAS_SELECTED_SEARCH_RESULTS:
    "SET_TELAS_ASSOCIADAS_SELECTED_SEARCH_RESULTS",
  SET_LOJAS_ASSOCIADAS_SELECTED_SEARCH_RESULTS:
    "SET_LOJAS_ASSOCIADAS_SELECTED_SEARCH_RESULTS",
  SET_TELAS_ASSOCIADAS_SELECIONADAS: "SET_TELAS_ASSOCIADAS_SELECIONADAS",
  SET_LOJAS_ASSOCIADAS_SELECIONADAS: "SET_LOJAS_ASSOCIADAS_SELECIONADAS",
  SET_LOADING_TELAS: "SET_LOADING_TELAS",
  SET_LOADING_LOJAS: "SET_LOADING_LOJAS",
  SET_PERMITIONS: "SET_PERMITIONS",
};

export { STORE_ID, TYPES };
