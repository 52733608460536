import api from "../util/api";
import * as actions from "../store/modules/alterarSenha/actions";
import history from "../util/history";
import { notifySuccess } from "../components/message/message";

export async function update(password, validPassword) {

    if (password.currentPassword.trim() === "") {
        return actions.setInvalid("currentPassword");
    }

    if (password.newPassword.trim() === "") {
        return actions.setInvalid("newPassword");
    }

    if (password.checkPassword.trim() === "") {
        return actions.setInvalid("checkPassword");
    }

    if (validPassword) {
        actions.setInvalid("newPassword");
        actions.setInvalid("checkPassword");
        return;
    }

    if (password.currentPassword === password.newPassword) {
        return;
    }

    const body = {
        id: localStorage.getItem("id"),
        senhaAtual: password.currentPassword,
        novaSenha: password.newPassword
    }

    const data = await api.put(`autenticacao/alterar-senha/`, body);

    if (!data.success) {
        return;
    }

    notifySuccess("Senha atualizada");
    actions.cleanRegister();
    history.push("/login");
    localStorage.removeItem("TOKEN_KEY");
    localStorage.removeItem("id");
}

