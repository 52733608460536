import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap'
import * as contaActions from '../../../../../../store/modules/personas/conta_corrente_store/actions'
import * as BiIcons from "react-icons/bi";
import * as AiIcons from "react-icons/ai";
import * as contaController from "../../../../../../controllers/personasController/contaCorrenteController";
import * as contaCorrenteController from "../../../../../../controllers/personasController/contaCorrenteController";


const TableContasCorrente = () => {

    const {
        contasCorrenteSelected,
        seeRegister,
        bancos,
        tiposPix,
        tiposConta,
        conta
    } = useSelector(state => state.contaCorrente);

    const {informacao} = useSelector(state => state.personasInformacoes);

    function cleanDados() {
        contaActions.clean();
        contaActions.setIsEditando(false);
        contaActions.setIsExcluindo(false);
    }

    function setContaCorrente(row) {
        const contaCorrenteSetar = {
            id: row.id,
            instituicaoFinanceiraId: row.instituicaoFinanceiraId,
            tipoConta: row.tipoConta,
            nomeAgencia: row.nomeAgencia,
            digitoAgencia: row.digitoAgencia,
            numero: row.numero,
            digitoConta: row.digitoConta,
            tipoPixId: row.tipoPixId,
            chavePix: row.chavePix,
            cdContaCorrenteW3ERP: row.cdContaCorrenteW3ERP,
            matricula: row.matriculasContaCorrente.length === 0 ? '0' : row.matriculasContaCorrente[0].id,
            matriculaNome: row.matriculasContaCorrente.length === 0 ? '' : row.matriculasContaCorrente[0].nome,
            flagPadrao: false
        }
        contaActions.setConta(contaCorrenteSetar);
    }

    function setaIdContaCorrente(row) {
        const contaCorrenteSetar = {
            id: row.id
        }
        contaActions.setConta(contaCorrenteSetar);
    }

    async function handleRemoveItem(row) {
        await contaActions.clean();
        contaActions.setIsExcluindo(true);
        contaActions.setIsEditando(false);
        toggleModalDelete();
        setaIdContaCorrente(row)
    }

    function handleEdit(row) {
        setContaCorrente(row);
        contaActions.setIsExcluindo(false);
        contaActions.setIsEditando(true);
    }

    const [modalDelete, setModalDelete] = useState(false);

    function toggleModalDelete() {
        setModalDelete(!modalDelete);
    }

    async function removeContaCorrente(id) {
        await contaController.removeContaCorrente(id);
        cleanDados();
        toggleModalDelete();
        await contaCorrenteController.buscaMatriculas(informacao.id);
    }

    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalDelete}
                toggle={toggleModalDelete}
                backdrop={false}
            >
                <ModalHeader toggle={toggleModalDelete} style={{height: '10px', border: 'none'}}/>
                <ModalBody>
                    <p style={{fontSize: '18px', color: 'var(--color-preto)'}}>Tem certeza que deseja <strong
                        style={{fontSize: '17px'}}>remover</strong> essa Conta Corrente?</p>
                </ModalBody>
                <div className="m-3">
                    <Button onClick={() => {
                        toggleModalDelete();
                        cleanDados();
                    }}
                            color="danger"
                            style={{width: "100px", marginRight: "15px",}}>
                        Cancelar
                    </Button>
                    <Button onClick={() => removeContaCorrente(conta.id)}
                            style={{
                                width: "100px",
                                marginLeft: "15px",
                                background: 'var(--color-verde)',
                                border: 'none'
                            }}>
                        Confirmar
                    </Button>
                </div>
            </Modal>
            <table style={{marginTop: '20px', width: '95%', marginLeft: '2%'}}>
                <thead>
                <tr>
                    <th>Banco</th>
                    <th>Tipo de Conta</th>
                    <th>Agência</th>
                    <th>DV</th>
                    <th>Conta Corrente</th>
                    <th>DV</th>
                    <th>Tipo de Chave</th>
                    <th>Chave Pix</th>
                    <th>Id Conta Corrente W3ERP</th>
                    <th>Matrícula</th>
                    <th style={{textAlign: 'center'}}>Ações</th>
                </tr>
                </thead>
                <tbody>
                {contasCorrenteSelected.map((row) => {
                    return (
                        <>
                            <tr className="text-center" role="row">
                                <td>{bancos.map((element) => {
                                    return element.id === row.instituicaoFinanceiraId ? element.descricao : ''
                                })}</td>
                                <td>{tiposConta.map((element) => {
                                    return element.id === row.tipoConta ? element.descricaoTipoConta : ''
                                })}</td>
                                <td>{row.nomeAgencia}</td>
                                <td>{row.digitoAgencia}</td>
                                <td>{row.numero}</td>
                                <td>{row.digitoConta}</td>
                                <td>{tiposPix.map((element) => {
                                    return element.id === row.tipoPixId ? element.nome : ''
                                })}</td>
                                <td>{row.chavePix}</td>
                                <td>{row.cdContaCorrenteW3ERP}</td>
                                <td>{row.matriculasContaCorrente.length === 0 ? '' : row.matriculasContaCorrente[0].nome}</td>
                                <td style={{textAlign: 'center'}}>
                                    <>
                                        {
                                            seeRegister &&
                                            <AiIcons.AiOutlineEye
                                                style={{
                                                    fontSize: "1.2rem",
                                                    cursor: "pointer",
                                                    color: "#C4C7CA"
                                                }}
                                                onClick={() => handleEdit(row)}
                                            />
                                        }
                                        {
                                            !seeRegister && (
                                                <>
                                                    <BiIcons.BiEdit
                                                        style={{
                                                            fontSize: "1.2rem",
                                                            cursor: "pointer",
                                                            color: "#C4C7CA",
                                                            marginRight: "1px"
                                                        }}
                                                        onClick={() => handleEdit(row)}
                                                    />
                                                    <BiIcons.BiTrash
                                                        style={{fontSize: '1.2rem', cursor: 'pointer', color: '#C4C7CA'}}
                                                        onClick={() => handleRemoveItem(row)}
                                                    />
                                                </>
                                            )
                                        }
                                    </>
                                </td>
                            </tr>
                        </>
                    )
                })

                }
                </tbody>
            </table>
        </>
    )
}
export default TableContasCorrente
