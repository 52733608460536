import React, { useEffect, useMemo, useState } from 'react'
import { usePagination, useTable } from 'react-table';
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Modal, ModalBody, Col, Button, Input, Label, Row, Navbar } from "reactstrap";

import { InputCpfCnpj } from '../../../components/Inputs/Input'
import * as actions from '../../../store/modules/contasAPagar/actions';
import * as controller from '../../../controllers/contasAPagarController';
import { maskCNPJ } from '../../../util/masks';
import LoadingIcon from '../../../components/Loading/LoadingIcon';

export default function ModalFornecedor({ modalPessoa, toggle, search }) {
    const { modalFornecedor, fornecedores, getAllFornecedor, modalFornecedorLoading } = useSelector(state => state.contasAPagar);

    const columns = useMemo(() => [{
        Header: "CNPJ"
    },{
        Header: "NOME",
    },], []); 
    const data = fornecedores

    const {
        page,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
       columns,
       data
    },
        usePagination);

    const { pageSize } = state;
    const [rows, setRows] = useState(0);
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    useEffect(() => {
        setRows(page.length);
    }, [page])

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);

        controller.getFornecedores(modalFornecedor, pageSelected+1, pageSize);
    }

    function handleInitialPage() {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }

    function onChangeItem(value, campo) {
        search ? actions.setItem(campo, value) : actions.pesquisar(campo, value)
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            controller.getFornecedores(modalFornecedor, 1, pageSize);
        }
    }
    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalPessoa}
                toggle={toggle}
                backdrop={true}
                size="lg"
            >
                <Navbar expand="md" style={{ width: "100%", height: "40px", background: 'var(--color-gradiente-verde)', marginBottom: '20px' }}>
                    <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2" >Pesquisa de Fornecedores</h5>
                </Navbar>
                <ModalBody >
                    <Row>
                        <Col md={4}>
                            <Label className="float-left" for="cpf">CNPJ</Label>
                            <InputCpfCnpj
                                type="text"
                                placeholder="Digite aqui..."
                                tipo="cnpj"
                                value={modalFornecedor.cnpj}
                                onChange={e => actions.setModalFornecedor('cnpj', e.target.value)}
                                onKeyPress={e => handleKeyPress(e)}
                                maxLength="18"
                            />
                        </Col>
                        <Col>
                            <Label className="float-left" for="nome">Nome</Label>
                            <Input
                                type="text"
                                placeholder="Digite aqui..."
                                value={modalFornecedor.nome}
                                onChange={e => actions.setModalFornecedor('nome', e.target.value)}
                                onKeyPress={e => handleKeyPress(e)}
                            />
                        </Col>
                    </Row>
                    <fieldset className={fornecedores.length > 0 ? "fieldset-socio" : "fieldset-socio-no-results"}>
                        <legend style={{ textAlign: "left", fontSize: "20px" }}>Resultados</legend>
                        {
                            modalFornecedorLoading &&
                            <LoadingIcon />
                        }
                        <table>
                            <tr>
                                <th style={{ width: "150px" }}>CNPJ</th>
                                <th>Nome</th>
                            </tr>
                            {fornecedores && fornecedores.map(row => {
                                return (
                                    <tr onDoubleClick={() => { onChangeItem(row.nome, 'fornecedor'); onChangeItem(maskCNPJ(row.cpfCnpj), 'cnpjFornecedor'); onChangeItem(row.id, 'fornecedorId'); toggle() }}>
                                        <td index={row.id}>{maskCNPJ(row.cpfCnpj)}</td>
                                        <td index={row.id}>{row.nome}</td>
                                    </tr>
                                )
                            })}

                        </table>
                        <br />
                    </fieldset>
                    {fornecedores && <Row className="pt-4">
                            <Col md={3}>
                                <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {rows} de {fornecedores.length}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={getAllFornecedor.totalPaginas}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => { setPageSize(Number(e.target.value)); handleInitialPage() }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>}
                </ModalBody>
                <div className="m-3">
                    <Button
                        onClick={() => actions.setFornecedores([])}
                        className="mr-4"
                        style={{
                            width: "150px",
                            background: "var(--color-branco)",
                            borderColor: "var(--color-laranja)",
                            color: "var(--color-laranja)",
                            borderRadius: "7px"
                        }}
                    >
                        Limpar
                    </Button>
                    <Button
                        onClick={() => controller.getFornecedores(modalFornecedor, selectedPage, pageSize)}
                        color="danger"
                        className="purpleButton"
                        style={{ width: "150px" }}
                    >
                        Pesquisar
                    </Button>
                </div>
            </Modal>
        </>
    )
}