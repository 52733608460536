import { STORE_ID, TYPES } from "./types";
import store from "../../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}

export function setFiltros(campo, value) {
    dispatch({
        type: TYPES.SET_FILTROS,
        campo,
        value
    });
}

export function setModalConsultores(campo, value) {
    dispatch({
        type: TYPES.SET_MODAL_CONSULTORES,
        campo,
        value
    });
}

export function setGetAllConsultores(value) {
    dispatch({
        type: TYPES.SET_GET_ALL_CONSULTORES,
        value
    });
}

export function setCampanha(value) {
    dispatch({
        type: TYPES.SET_CAMPANHA,
        value
    });
}

export function setProdutos(value) {
    dispatch({
        type: TYPES.SET_PRODUTOS,
        value
    })
}

export function setTabela(value) {
    dispatch({
        type: TYPES.SET_TABELA,
        value
    })
}

export function setTiposParceria(value) {
    dispatch({
        type: TYPES.SET_TIPOS_PARCERIA,
        value
    })
}

export function setCampanhas(value) {
    dispatch({
        type: TYPES.SET_CAMPANHAS,
        value
    })
}

export function setGrupos(value) {
    dispatch({
        type: TYPES.SET_GRUPOS,
        value
    })
}

export function setCentrosCusto(value) {
    dispatch({
        type: TYPES.SET_CENTROS_CUSTO,
        value
    })
}

export function setHierarquias(value) {
    dispatch({
        type: TYPES.SET_HIERARQUIAS,
        value
    })
}

export function setConsultores(value) {
    dispatch({
        type: TYPES.SET_CONSULTORES,
        value
    })
}

export function setCanais(value) {
    dispatch({
        type: TYPES.SET_CANAIS,
        value
    })
}

export function setStatus(value) {
    dispatch({
        type: TYPES.SET_STATUS,
        value
    })
}

export function cleanFiltros(value) {
    dispatch({
        type: TYPES.CLEAN_FILTROS,
        value
    })
}

export function setIsConsultor(value) {
    dispatch({
        type: TYPES.SET_IS_CONSULTOR,
        value
    })
}

export function setLoadingLojas(value) {
    dispatch({
        type: TYPES.SET_LOADING_LOJAS,
        value
    });
}

export function setLoadingConsultores(value) {
    dispatch({
        type: TYPES.SET_LOADING_CONSULTORES,
        value
    });
}