import React from "react";
import Sidebar from "./components/sidebar/sidebar";
import { Redirect, Route, Switch, Router } from "react-router-dom";
import Login from "./views/login/login";
import { isAuthenticated } from "./util/auth";
import history from "./util/history";
import "./style.css";
import EsqueceuSenha from "./views/esqueceuSenha/esqueceuSenha";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest}
    render={
      props =>
        isAuthenticated() ? (
          <>
            <Sidebar />
          </>
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
    }
  />
)

const App = () => {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/nova-senha/:guid" component={EsqueceuSenha}/>
          <PrivateRoute />
          <Redirect to="/" />
        </Switch>
      </Router >
    </>
  );
}

export default App;
