import api from "../util/api";
import { notifyWarn, notifySuccess, notifyInfo } from "../components/message/message";
import * as actions from "../store/modules/centroCusto/actions";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("centrosCusto");
    setLoading(false)

    if (!data.success) {
        return;
    }

    actions.getItems(data.content);
}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`centrosCusto/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    data.content.headCountOrcadoLojasCentroCusto.forEach((item)=>{
      item.periodo = `${item.dataInicial.substring(5,7)}/${item.dataInicial.substring(0,4)}`
    })

    actions.copy(data.content);
    actions.item(data.content);
}

export async function create(item) {

    if (item.descricao.trim() === "") {
        notifyWarn("Descrição do centro de custo não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao,
        codCentroCustoW3Erp: item.codCentroCustoW3Erp
    }

    setLoading(true)
    const data = await api.post("centrosCusto", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Centro de custo cadastrado");
    actions.cleanRegister();
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`centrosCusto/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Centro de custo removido");
    get();
}

export async function deleteHeadcountOrcado(id) {

    setLoading(true)
    const data = await api.delete(`head-count-orcado-centro-custo/${id}`);
    setLoading(false)

    if (!data.success) {
        return false;
    }

    notifySuccess("Headcount removido");
    return true
}

export async function update(item, copy) {

    if (JSON.stringify(item) === JSON.stringify(copy)) {
        notifyInfo("Nada foi alterado");
        return;
    }

    if (item.descricao.trim() === "") {
        notifyWarn("Descrição do centro de custo não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao,
        codCentroCustoW3Erp: item.codCentroCustoW3Erp
    }

    setLoading(true)
    const data = await api.put(`centrosCusto/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Centro de custo atualizado");
    actions.cleanRegister();
    actions.setActiveTab("Search");
    get();
}

export async function editHeadcountOrcado(id, headcountOrcado) {

    if (headcountOrcado.qtde === "") {
        return notifyWarn("Quantidade do headcount não pode estar em branco")
      }

      if (headcountOrcado.periodo === "") {
        return notifyWarn("Informe o período de vigência")
      }

      let dataFormated = {
        qtde: headcountOrcado.qtde,
        centroCustoId: headcountOrcado.centroCustoId,
        dataInicial:`${headcountOrcado.periodo.substring(3,7)}-${headcountOrcado.periodo.substring(0,2)}-01`,
        dataFinal: headcountOrcado.periodo.substring(0,2) !== '02' ? `${headcountOrcado.periodo.substring(3,7)}-${headcountOrcado.periodo.substring(0,2)}-30` :  `${headcountOrcado.periodo.substring(3,7)}-${headcountOrcado.periodo.substring(0,2)}-28`,
      }

      setLoading(true);
      const data = await api.put(`head-count-orcado-centro-custo/${id}/centro-custo`, dataFormated);
      setLoading(false);

      actions.cleanHeadcountOrcado();

      if (!data.success) {
        return false;
      }


      notifySuccess("Headcount Orçado alterado");
      return true
}

export async function salveHeadcountOrcado( headcountOrcado) {

    if (headcountOrcado.qtde === "") {
      return notifyWarn("Quantidade do headcount não pode estar em branco")
    }

    if (headcountOrcado.periodo === "") {
      return notifyWarn("Informe o período de vigência")
    }

    let dataFormated = {
      qtde: headcountOrcado.qtde,
      centroCustoId: headcountOrcado.centroCustoId,
      dataInicial:`${headcountOrcado.periodo.substring(3,7)}-${headcountOrcado.periodo.substring(0,2)}-01`,
        dataFinal: headcountOrcado.periodo.substring(0,2) !== '02' ? `${headcountOrcado.periodo.substring(3,7)}-${headcountOrcado.periodo.substring(0,2)}-30` :  `${headcountOrcado.periodo.substring(3,7)}-${headcountOrcado.periodo.substring(0,2)}-28`,
    }

    setLoading(true);
    const data = await api.post(`head-count-orcado-centro-custo`, dataFormated);
    setLoading(false);

    if (!data.success) {
      return false;
    }

    actions.cleanHeadcountOrcado();
    actions.setHeadcount('id', data.content.id);

    notifySuccess("Headcount Orçado salvo");
    return true
  }
