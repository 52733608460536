import React, { useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Card, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { SelectUnity, Select} from '../../../components/Inputs/Input'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import * as  divisaoComissaoController from '../../../controllers/divisaoComissaoController'
import * as  divisaoComissaoActions from '../../../store/modules/divisaoComissao/actions'
import { notifyWarn } from '../../../components/message/message'
import './style.css'
import { maskMoeda } from '../../../util/masks'
import TableLoja from '../components/TableLoja'

const Register = () => {
    const { item, bancos, divisaoComissaoCard, tiposComissao, lojasBanco, seeRegister } = useSelector(state => state.divisaoComissao)

    const [modalWarning, setModalWarning] = useState(false)
    const [idBanco, setIdBanco] = useState(false)

    const toggleModalWarning = () => setModalWarning(!modalWarning)

    function onChange(value, field) {
        divisaoComissaoActions.item({ ...item, [field]: value })
    }

    function onChangeLojaBanco(value, master) {
        divisaoComissaoActions.item({ ...item, lojaBancoId: value, matriz: master })   
    }

    function onChangeBanco(value) {
        setIdBanco(value)
        if (item.bancoId !== '0' && value !== '0') {
            toggleModalWarning()
        } else {
            divisaoComissaoActions.cleanDivisaoComissaoCard()
            divisaoComissaoActions.item({ ...item, bancoId: value, divisaoComissaoItens: [], lojaBancoId: '0', matriz: '' })
            if (value !== '0') {
                divisaoComissaoController.getLojasSemDivisaoRegister(value)
                divisaoComissaoController.getTiposComissao(value)
            }
        }
    }

    function onChangeDivisaoComissaoCard(value, field) {
        divisaoComissaoActions.setDivisaoComissaoCard({ ...divisaoComissaoCard, [field]: value })
    }

    useLayoutEffect(() => {
        if (item.bancoId !== '0' && item.bancoId && item.id) {
            divisaoComissaoController.getLojasSemDivisaoRegister(item.bancoId)
            divisaoComissaoController.getTiposComissao(item.bancoId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.id])

    return (
        <>
            <Modal
                className='text-center'
                isOpen={modalWarning}
                toggle={toggleModalWarning}
                backdrop={false}
            >
                <ModalHeader toggle={toggleModalWarning} style={{ height: '10px', border: 'none' }} />
                <ModalBody>
                    <p style={{ fontSize: '18px', color: 'var(--color-preto)' }}>Tem certeza que deseja <strong style={{ fontSize: '17px' }}>trocar</strong> a instituição financeira?</p>
                    <p style={{ fontSize: '18px', color: 'var(--color-preto)' }}>Obs: Esta ação irá remover, se houver, todas as lojas adicionadas</p>
                </ModalBody>
                <div className='m-3'>
                    <Button onClick={() => toggleModalWarning()} color='danger' style={{ width: '100px', marginRight: '15px', border: 'none' }}> Cancelar </Button>
                    <Button
                        onClick={() => {
                            divisaoComissaoActions.cleanDivisaoComissaoCard()
                            divisaoComissaoActions.item({ ...item, bancoId: idBanco, divisaoComissaoItens: [], lojaBancoId: '0', matriz: '' })
                            toggleModalWarning()
                            if (idBanco !== '0') {
                                divisaoComissaoController.getLojasSemDivisaoRegister(idBanco)
                                divisaoComissaoController.getTiposComissao(idBanco)
                            }
                        }}
                        style={{ width: '100px', marginLeft: '15px', background: 'var(--color-verde)', border: 'none' }}
                    > Confirmar </Button>
                </div>
            </Modal>
            <Col>
                <Card className='pt-3 pb-2 mb-5'>
                    <Col>
                        <div className='pb-3'>
                            <Label style={{ fontWeight: '700' }}>Preencha os campos para cadastrar um parâmetro de divisão de comissão</Label>
                            <Form className='mt-4 mb-2'>
                                <Row>
                                    <Col md={4}>
                                        <Label>Instituição Financeira*</Label>
                                        <Select
                                            first='SELECIONE'
                                            value={{ value: item.bancoId }}
                                            options={bancos}
                                            select={{ value: 'id', label: 'nome' }}
                                            onChange={(e) => { onChangeBanco(e.value) }}
                                            disabled={seeRegister || item.id}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Label>Loja Banco*</Label>
                                        <SelectUnity
                                            first='SELECIONE'
                                            value={{ value: item.lojaBancoId }}
                                            options={lojasBanco}
                                            select={{ value: 'id', label: 'nome', unity: 'nomeMaster' }}
                                            onChange={(e) => { onChangeLojaBanco(e.value, e.unity) }}
                                            disabled={seeRegister || item.id}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Label>Loja master</Label>
                                        <Input
                                            value={item.matriz}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Label style={{ fontSize: '1.2rem', marginTop: '15px' }}>Vigência</Label>
                                <Row className='mb-4'>
                                    <Col md={3}>
                                        <Label>Início*</Label>
                                        <Input
                                            type='date'
                                            value={item.dataInicio}
                                            max="9999-12-31"
                                            onChange={(e) => { onChange(e.target.value, 'dataInicio') }}
                                            disabled={seeRegister}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Label>Fim*</Label>
                                        <Input
                                            type='date'
                                            max="9999-12-31"
                                            value={item.dataFim}
                                            onChange={(e) => { onChange(e.target.value, 'dataFim') }}
                                            disabled={seeRegister}
                                        />
                                    </Col>
                                </Row>
                                {!seeRegister &&
                                    <Row>
                                        <Col>
                                            <Card className='p-4' style={{ backgroundColor: 'var(--color-quase-branco)', position: 'relative' }}>
                                                {
                                                    item.bancoId === "0" &&
                                                    <Label style={{ fontWeight: '700', textAlign: 'center' }}>Selecione uma instituição financeira para prosseguir</Label>
                                                }
                                                <div className={item.bancoId === "0" && 'div-blocker'}>
                                                    <Col>                      
                                                        <Row style={{width: '95%'}}>
                                                            <Col className='col-custom'>
                                                                <Label>Tipo Comissão</Label>
                                                                <Select
                                                                    first='Selecione'
                                                                    value={{ value: divisaoComissaoCard.tiposComissaoId }}
                                                                    options={tiposComissao}
                                                                    select={{ value: 'id', label: 'descricao' }}
                                                                    onChange={(e) => onChangeDivisaoComissaoCard(e.value, 'tiposComissaoId')}
                                                                    disabled={seeRegister}
                                                                />
                                                            </Col>
                                                            <Col className='col-custom'>
                                                                <Label>Percentual de comissão</Label>
                                                                <Input
                                                                    placeholder='Digite aqui...'
                                                                    value={divisaoComissaoCard.percentual}
                                                                    onChange={(e) => {
                                                                      onChangeDivisaoComissaoCard(maskMoeda(e.target.value).replaceAll(',', '.') > 100 ? "100,00" : maskMoeda(e.target.value), 'percentual')
                                                                    }}
                                                                    disabled={seeRegister}
                                                                    maxLength="6"
                                                                />
                                                            </Col>                                
                                                        </Row>
                                                        <div style={{ textAlign: 'right', marginTop: '-30px' }}>
                                                            <AiOutlinePlusCircle
                                                                onClick={async () => {
                                                                  if (divisaoComissaoCard.tiposComissaoId !== '0' && divisaoComissaoCard.percentual) {
                                                                      let newDivisaoComissao;
                                                                      if (item.id) {
                                                                        newDivisaoComissao = await divisaoComissaoController.adicionaItemDivisaoComissao(divisaoComissaoCard, item.id)
                                                                      } else {
                                                                        newDivisaoComissao = divisaoComissaoCard
                                                                      }
                                                                      if (newDivisaoComissao) {
                                                                        var tipoComissao = tiposComissao.find((tipoComissao)=> tipoComissao.id === divisaoComissaoCard.tiposComissaoId)
                                                                        const percentual = maskMoeda(divisaoComissaoCard.percentual)
                                                                        divisaoComissaoActions.addLoja({ ...newDivisaoComissao, percentual, tipoComissao : tipoComissao?.descricao})
                                                                        divisaoComissaoActions.cleanDivisaoComissaoCard()
                                                                      }
                                                                  } else {
                                                                      notifyWarn('Preencha os campos corretamente')
                                                                  }
                                                              }}
                                                                style={{ fontSize: '1.2rem', cursor: 'pointer', color: 'green', marginRight: '7px' }}
                                                            />
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                }
                                {item.divisaoComissaoItens &&
                                    <TableLoja
                                    items={item.divisaoComissaoItens}
                                />
                                }
                            </Form>
                        </div>
                    </Col>
                </Card>
            </Col>
        </>
    )
}

export default Register
