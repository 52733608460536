import {useSelector} from "react-redux";
import {Card, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Select, Switch} from "../../../components/Inputs/Input";
import * as actions from "../../../store/modules/contaGerencial/actions"
import React, {useEffect, useState} from "react";
import * as contaGerencialController from "../../../controllers/contaGerencialController";

const Register = () => {
    const [stateSelecionado, setStateSelecionado] = useState(false);
    const [stateDespesa, setStateDespesa] = useState(false);
    const [stateReceita, setStateReceita] = useState(false);
    const [stateProduto, setStateProduto] = useState(true);
    const [stateDescricao, setStateDescricao] = useState(false);
    const {item, seeRegister, produtosRiber, tiposComissao, isDespesa, isProdutoSelecionado, isUpdate, isCreate} =
        useSelector(state => state.contaGerencial);

    useEffect(() => {
        if (stateProduto === true) {
            changeIsProduto();
        }
        if (stateDescricao === true) {
            changeIsDescricao();
        }

    }, [stateProduto, stateDescricao])

    useEffect(() => {
        if (stateDespesa === true) {
            setDespesa();
        }
        if (stateReceita === true) {
            setReceita();
        }
    }, [stateDespesa, stateReceita])

    useEffect(() => {
        if (isCreate === true) {
            initialState();
        } else if (seeRegister === true && isUpdate === false || (seeRegister === false && isUpdate === true)) {
            if (isDespesa === true) {
                stateSetDespesa();
            } else if (isDespesa === false) {
                stateSetReceita();
            }
        } else {
            initialState();
        }
    }, [isDespesa, seeRegister, isUpdate, isCreate])

    useEffect(() => {
        if (isCreate === true) {
            initialState();
        } else if (seeRegister === true && isUpdate === false || (seeRegister === false && isUpdate === true)) {
            if (isProdutoSelecionado === true) {
                stateSetProduto();
            } else {
                stateSetDescricao();
            }
        } else {
            initialState();
        }
    }, [isProdutoSelecionado, seeRegister, isUpdate, isCreate])

    function stateSetProduto() {
        setStateProduto(true);
        setStateDescricao(false);
    }

    function stateSetDescricao() {
        setStateDescricao(true);
        setStateProduto(false);
    }

    function stateSetDespesa() {
        setStateSelecionado(true)
        setStateDespesa(true);
        setStateReceita(false);
    }

    function stateSetReceita() {
        setStateSelecionado(true);
        setStateReceita(true);
        setStateDespesa(false);
    }

    function initialState() {
        setStateSelecionado(false);
        setStateDespesa(false);
        setStateReceita(false);
        setStateProduto(false);
        setStateDescricao(false);
    }

    function changeIsDescricao() {
        actions.setIsDescricao();
    }

    function changeIsProduto() {
        actions.setIsProduto();
        contaGerencialController.getProdutosRiber();
        contaGerencialController.getTiposComissao();
    }

    function setDespesa() {
        actions.setIsDespesa(true);
    }

    function setReceita() {
        actions.setIsDespesa(false);
    }

    function setaProduto(value, field) {
        actions.setProdutoContaGerencial({...item.produto, [field]: value});
    }

    function setaDescricao(value, field) {
        actions.setDescricaoContaGerencial({...item.descricao, [field]: value});
    }

    return (
        <>
            <Col>
                <Card className="pt-3 pb-2 mb-5">
                    <Col className="pt-2" style={{margin: '10px 0 20px 25px'}}>
                        <p style={{fontSize: "18px", color: 'var(--color-preto)'}}>
                            Selecione um tipo de Conta Gerencial e Preencha os campos para realizar o cadastro
                        </p>
                    </Col>
                    <Form style={{margin: '0px 0px 15px 15px'}}>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Col>
                                        <Switch
                                            className="switch"
                                            checked={stateDespesa}
                                            onChange={() => {
                                                stateSetDespesa();
                                            }}
                                            componentTop="Despesa"
                                            disabled={seeRegister === true}
                                        />
                                        <Switch
                                            className="switch"
                                            checked={stateReceita}
                                            onChange={() => {
                                                stateSetReceita();
                                            }}
                                            componentTop="Receita"
                                            disabled={seeRegister === true}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                    {
                        stateSelecionado && (
                            <Form style={{margin: '0px 0px 15px 15px'}}>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Col>
                                                <Switch
                                                    className="switch"
                                                    checked={stateProduto}
                                                    onChange={() => {
                                                        stateSetProduto();
                                                    }}
                                                    componentTop="Produto"
                                                    disabled={seeRegister === true}
                                                />
                                                <Switch
                                                    className="switch"
                                                    checked={stateDescricao}
                                                    onChange={() => {
                                                        stateSetDescricao();
                                                    }}
                                                    componentTop="Descrição"
                                                    disabled={seeRegister === true}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }
                    {
                        stateProduto && stateSelecionado && (
                            <Form style={{margin: '-15px 10px 15px 35px'}}>
                                <Row>
                                    <Col md={12} id="formProduto">
                                        <Row>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Produto Riber</Label>
                                                    <Select
                                                        first="Selecione"
                                                        value={{value: item.produto.produtoRiber}}
                                                        select={{value: "id", label: "nome"}}
                                                        onChange={e => setaProduto(e.value, 'produtoRiber')}
                                                        options={produtosRiber}
                                                        isSearchable={true}
                                                        autoComplete="off"
                                                        disabled={seeRegister === true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Tipo Comissão</Label>
                                                    <Select
                                                        first="Selecione"
                                                        value={{value: item.produto.tipoComissao}}
                                                        select={{value: "id", label: "descricao"}}
                                                        onChange={e => setaProduto(e.value, 'tipoComissao')}
                                                        options={tiposComissao}
                                                        isSearchable={true}
                                                        autoComplete="off"
                                                        disabled={seeRegister === true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Código Conta Gerencial W3ERP</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Digite aqui..."
                                                        style={{height: '35px'}}
                                                        value={item.produto.codigoContaGerencial}
                                                        onChange={e => setaProduto(e.target.value, 'codigoContaGerencial')}
                                                        disabled={seeRegister === true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }
                    {
                        stateDescricao && stateSelecionado && (
                            <Form style={{margin: '-15px 10px 15px 35px'}}>
                                <Row>
                                    <Col md={12} id="formDescricao">
                                        <Row>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Informação</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Digite aqui..."
                                                        style={{height: '35px'}}
                                                        value={item.descricao.informacao}
                                                        onChange={e => setaDescricao(e.target.value, 'informacao')}
                                                        disabled={seeRegister === true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Classificação da Receita</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Digite aqui..."
                                                        style={{height: '35px'}}
                                                        value={item.descricao.classificacaoReceita}
                                                        onChange={e => setaDescricao(e.target.value, 'classificacaoReceita')}
                                                        disabled={seeRegister === true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Código Conta Gerencial</Label>
                                                    <Input
                                                        type="text"
                                                        placeholder="Digite aqui..."
                                                        style={{height: '35px'}}
                                                        value={item.descricao.codigoContaGerencial}
                                                        onChange={e => setaDescricao(e.target.value, 'codigoContaGerencial')}
                                                        disabled={seeRegister === true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }
                </Card>
            </Col>
        </>
    )
}

export default Register