import moment from "moment";

export function maskCNPJ(value) {
  if (value !== undefined) {
    let newValue = value
      .toString()
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
    return newValue;
  }
}
export function formatarDocumento(value) {
  const numeros = value.replace(/\D/g, "");
  if (numeros.length === 14) {
    return numeros.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  } else if (numeros.length === 11) {
    return numeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else {
    return value;
  }
}

export function maskCPF(value, keepLetters = false) {
  if (value !== undefined) {
    let newValue;
    if (keepLetters) {
      newValue = value
        .toString()
        .replace(/(\w{3})(\w)/, "$1.$2")
        .replace(/(\w{3})(\w)/, "$1.$2")
        .replace(/(\w{3})(\w{1,2})/, "$1-$2")
        .replace(/(-\w{2})\w+?$/, "$1");
    } else {
      newValue = value
        .toString()
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1");
    }
    return newValue;
  }
}

export function removeMaskCPF(value) {
  if (value !== undefined) {
    var newValue = value
      .toString()
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1$2")
      .replace(/(\d{3})(\d)/, "$1$2")
      .replace(/(\d{3})(\d{1,2})/, "$1$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    return newValue;
  }
}

export function removeMaskCNPJ(value) {
  if (value !== undefined) {
    var newValue = value
      .toString()
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1$2$3$4$5");
    return newValue;
  }
}

export function maskMoeda(value) {
  if (value !== undefined && value !== null) {
    let newValue = value.toString().replace(/\D/g, "");
    newValue = (parseFloat(newValue) / 100).toFixed(2);
    newValue = newValue.replace(".", ",");
    newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return newValue;
  }
  return value;
}

export function maskMoeda4(value) {
  if (value !== undefined && value !== null) {
    var newValue = value.toString().replace(/\D/g, "");
    newValue = (newValue / 10000).toFixed(4) + "";
    newValue = newValue.replace(".", ",");
    newValue = newValue.replace(/(\d)(\d{3})(\d{3})(\d{3}),/g, "$1.$2.$3.$4,");
    newValue = newValue.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");

    return newValue;
  }
}

export function formatDecimal4(value) {
  if (value !== undefined && value !== null) {
    var newValue = value.toString().replace(/\D/g, "");
    newValue = (newValue / 10000).toFixed(4) + "";
    newValue = newValue.replace(".", ",");
    newValue = newValue.replace(/(\d)(\d{3})(\d{3})(\d{3}),/g, "$1.$2.$3.$4,");
    newValue = newValue.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");

    return newValue;
  }
}

export function maskMoeda4new(value) {
  if (value !== undefined && value !== null) {
    const stringValue = value.toString();

    const [parteInteira, parteDecimal = ""] = stringValue.split(".");

    const decimalPadded = parteDecimal.padEnd(4, "0").slice(0, 4);

    const parteInteiraFormatted = parteInteira.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      "."
    );

    const formattedValue = `${parteInteiraFormatted},${decimalPadded}`;

    return formattedValue;
  }
}

export function maskCEP(value) {
  var newValue = value.toString().replace(/\D/g, "");
  newValue = newValue.replace(/(\d{5})(\d{3})/, "$1-$2");
  return newValue;
}

export function removeMaskCEP(value) {
  var newValue = value.toString();
  newValue = newValue.replace("-", "");
  return newValue;
}

export function maskNumber(value) {
  var newValue = value.toString().replace(/\D/g, "");
  return newValue;
}

export function maskTelefone(value) {
  var newValue = value.toString().replace(/\D/g, "");
  newValue = newValue.replace("-", "");
  var isCelular = newValue.length === 9 ? true : false;

  if (isCelular) {
    newValue = newValue.replace(/(\d{5})(\d{4})/, "$1-$2");
    return newValue;
  } else {
    newValue = newValue.replace(/(\d{4})(\d{4})/, "$1-$2");
    return newValue;
  }
}

export function removeMaskTelefone(value) {
  var newValue = value.toString();
  newValue = newValue.replace("-", "");
  return newValue;
}

export function removeMaskMoeda(value) {
  var newValue = value.toString();
  newValue = newValue.replace(/\./g, "");
  newValue = newValue.replace(",", ".");
  return newValue;
}

/**
 * @param value - string
 * @param format
 *  para ser usado em input 'YYYY-MM-DD' | visualizar em label = 'DD/MM/YYYY'
 **/

export function maskDate(value, format) {
  if (!(value === null) && !(value === undefined)) {
    return moment(value).format(format);
  } else {
    return "";
  }
}

export function removeAccentsPunctuationAndUpperCase(value) {
  if (value)
    return value
      .normalize("NFD")
      .replace(/[^-_,./| a-zA-Z0-9-]/g, "")
      .toUpperCase();
  else return "";
}

export function removeAccentsAndUppercase(value) {
  if (value) {
    const sanitizedValue = value
      .normalize("NFD")
      .replace(/[^ a-zA-Z0-9-,]/g, "")
      .toUpperCase();
    return sanitizedValue;
  } else {
    return "";
  }
}

export function removeAccents(value) {
  if (value)
    return value
      .normalize("NFD")
      .replace(/[^ a-zA-Z0-9-,]/g, "")
      .toUpperCase();
}

export function removeAccentsAndComma(value) {
  if (value)
    return value
      .normalize("NFD")
      .replace(/[^-_,./| a-zA-Z0-9-]/g, "")
      .toUpperCase();
}

export function maskMonthYear(value) {
  if (value) {
    const numbersOnly = value.replace(/\D/g, "");
    const month = numbersOnly.slice(0, 2);
    const year = numbersOnly.slice(2, 6);

    return `${month}/${year}`;
  } else {
    return "";
  }
}

export function maskDayMonthYear(value) {
  if (!value) return "";
  const numbersOnly = value.replace(/\D/g, "");

  let maskedValue = "";

  if (numbersOnly.length > 0) {
    maskedValue += numbersOnly.slice(0, 2);
  }
  if (numbersOnly.length > 2) {
    maskedValue += "/" + numbersOnly.slice(2, 4);
  }
  if (numbersOnly.length > 4) {
    maskedValue += "/" + numbersOnly.slice(4, 8);
  }

  return maskedValue;
}