import React, {useEffect, useMemo, useState} from "react";
import ReactPaginate from "react-paginate";
import {Button, Card, Col, Input, Label, Modal, ModalHeader, ModalBody, Row, Form, FormGroup} from "reactstrap";
import {useSelector} from "react-redux";
import GlobalFilter from "../../../components/filter/filterWithAccent";
import {useTable, useSortBy, usePagination, useGlobalFilter} from "react-table";
import {COLUMNS} from "../constants/columns";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";
import * as actions from "../../../store/modules/contaGerencial/actions"
import * as contaGerencialController from "../../../controllers/contaGerencialController";
import {Select, Switch} from "../../../components/Inputs/Input";

const Search = ({selectedPage, setSelectedPage}) => {
    const {items, permitions, tiposConta, search, produtosRiber, tiposComissao, getAll} =
        useSelector(state => state.contaGerencial)
    const columns = useMemo(() => COLUMNS, []);
    const data = items;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        state,
        setPageSize,
        setGlobalFilter
    } = useTable({
            columns,
            data
        },
        useGlobalFilter,
        useSortBy,
        usePagination);

    useEffect(() => {
        contaGerencialController.getAll(
            selectedPage !== null
                ? selectedPage > 1
                    ? 1
                    : selectedPage
                : 1,
            pageSize);
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.pageSize]);

    useEffect(() => {
        actions.cleanSearch();
    }, [search.isProduto, search.isDescricao])

    const {globalFilter, pageSize} = state;
    const [itemSelected, setItem] = useState(null);
    const [modalDelete, setModalDelete] = useState(false);
    const [initialPage, setInitialPage] = useState(false);

    async function handleUpdate(id) {
        await contaGerencialController.getById(id)
        actions.setActiveTabContaGerencial("Register");
    }

    function handleEdit(id) {
        actions.setIsUpdate(true);
        actions.setIsCreate(false);
        handleUpdate(id);
    }

    function seeRegister(id) {
        actions.setSeeRegisterContaGerencial(true);
        actions.setIsCreate(false);
        handleUpdate(id)
    }

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);

        if (search.codigoContaGerencial !== "" || search.produtoRiber !== '0' || search.tipoComissao !== '0' || search.informacao !== "" ||
        search.classificacaoReceita !== "" || search.tipoContaGerencial !== '0' || search.isProduto !== false || search.isDescricao !== false) {
            contaGerencialController.getWithFilter(search.codigoContaGerencial, search.produtoRiber, search.tipoComissao, search.informacao,
                search.classificacaoReceita, search.tipoContaGerencial, search.isProduto, search.isDescricao, pageSelected + 1, pageSize)
        } else {
            contaGerencialController.getAll(pageSelected + 1, pageSize);
        }
    }

    function handleInitialPage() {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
        contaGerencialController.getAll(0, pageSize);
    }

    function toggleModalDelete() {
        setModalDelete(!modalDelete);
    }

    function handleDelete(id) {
        setItem(id)
        toggleModalDelete();
    }

    async function deleteItem() {
        await contaGerencialController.removeContaGerencial(itemSelected);
        setModalDelete(false);
    }

    function setaSearch(value, field) {
        if (field === 'isDescricao') {
            const states = {
                isDescricao: !search.isDescricao,
                isProduto: search.isProduto ? false : search.isProduto
            }
            actions.setSearch({...search, ...states});
        } else if (field === 'isProduto') {
            const states = {
                isProduto: !search.isProduto,
                isDescricao: search.isDescricao ? false : search.isDescricao
            }
            actions.setSearch({...search, ...states});
        } else {
            actions.setSearch({...search, [field]: value});
        }
    }

    function pesquisaContaGerencial(e) {
        const pageSelected = e.selected;
        setInitialPage(true);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);

        contaGerencialController.getWithFilter(search.codigoContaGerencial, search.produtoRiber, search.tipoComissao, search.informacao,
            search.classificacaoReceita, search.tipoContaGerencial, search.isProduto, search.isDescricao, 0, pageSize);
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalDelete}
                toggle={toggleModalDelete}
                backdrop={false}
            >
                <ModalHeader toggle={toggleModalDelete} style={{height: '10px', border: 'none'}}/>
                <ModalBody>
                    <p style={{fontSize: '18px', color: 'var(--color-preto)'}}>Tem certeza que deseja <strong
                        style={{fontSize: '17px'}}>remover</strong> esse item?</p>
                </ModalBody>
                <div className="m-3">
                    <Button onClick={() => toggleModalDelete()}
                            color="danger"
                            style={{width: "100px", marginRight: "15px",}}>
                        Cancelar
                    </Button>
                    <Button onClick={() => deleteItem()}
                            style={{
                                width: "100px",
                                marginLeft: "15px",
                                background: 'var(--color-verde)',
                                border: 'none'
                            }}>
                        Confirmar
                    </Button>
                </div>
            </Modal>
            <Col>
                <Card className="pt-3 pb-2 mb-5">
                    <Col>
                        <Form style={{margin: '0px 0px 0px 0px'}}>
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={12}>
                                            <Row>
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <Label>Código conta gerencial W3ERP</Label>
                                                        <Input
                                                            type="text"
                                                            placeholder="Digite aqui..."
                                                            style={{height: '35px'}}
                                                            value={search.codigoContaGerencial}
                                                            onChange={(e) => setaSearch(e.target.value, "codigoContaGerencial")}
                                                            onKeyDown={e => handleKeyPress(e)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <Label>Tipo Conta Gerencial</Label>
                                                        <Select
                                                            first="Selecione"
                                                            value={{value: search.tipoContaGerencial}}
                                                            select={{value: "id", label: "tipoContaGerencial"}}
                                                            onChange={e => setaSearch(e.value, 'tipoContaGerencial')}
                                                            options={tiposConta}
                                                            isSearchable={true}
                                                            autoComplete="off"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Col>
                                                            <Switch
                                                                className="switch"
                                                                checked={search.isProduto}
                                                                onChange={() => {
                                                                    setaSearch("", "isProduto");
                                                                }}
                                                                componentTop="Produto"
                                                            />
                                                            <Switch
                                                                className="switch"
                                                                checked={search.isDescricao}
                                                                onChange={() => {
                                                                    setaSearch("", "isDescricao");
                                                                }}
                                                                componentTop="Descrição"
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {
                                                search.isProduto && (
                                                    <Row md={12}>
                                                        <Col md={12} style={{margin: '0px 0px 10px 0px'}}>
                                                            <FormGroup>
                                                                <Row>
                                                                    <Col md={2}>
                                                                        <Label>Produto Riber</Label>
                                                                        <Select
                                                                            first="Selecione"
                                                                            value={{value: search.produtoRiber}}
                                                                            select={{value: "id", label: "nome"}}
                                                                            onChange={e => setaSearch(e.value, 'produtoRiber')}
                                                                            options={produtosRiber}
                                                                            isSearchable={true}
                                                                            autoComplete="off"
                                                                        />
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <Label>Tipo Comissão</Label>
                                                                        <Select
                                                                            first="Selecione"
                                                                            value={{value: search.tipoComissao}}
                                                                            select={{value: "id", label: "descricao"}}
                                                                            onChange={e => setaSearch(e.value, 'tipoComissao')}
                                                                            options={tiposComissao}
                                                                            isSearchable={true}
                                                                            autoComplete="off"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                            {
                                                search.isDescricao && (
                                                    <Row md={12}>
                                                        <Col md={12} style={{margin: '0px 0px 0px 0px'}}>
                                                            <FormGroup>
                                                                <Row>
                                                                    <Col md={3}>
                                                                        <FormGroup>
                                                                            <Label>Informação</Label>
                                                                            <Input
                                                                                type="text"
                                                                                placeholder="Digite aqui..."
                                                                                style={{height: '35px'}}
                                                                                value={search.informacao}
                                                                                onChange={e => setaSearch(e.target.value, 'informacao')}
                                                                                onKeyDown={e => handleKeyPress(e)}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <FormGroup>
                                                                            <Label>Classificação da Receita</Label>
                                                                            <Input
                                                                                type="text"
                                                                                placeholder="Digite aqui..."
                                                                                style={{height: '35px'}}
                                                                                value={search.classificacaoReceita}
                                                                                onChange={e => setaSearch(e.target.value, 'classificacaoReceita')}
                                                                                onKeyDown={e => handleKeyPress(e)}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                            <Row>
                                                <Col md={8} style={{margin: '0px 0px 10px 0px'}}>
                                                    <FormGroup>
                                                        <Button
                                                            style={{width: "200px", float: 'left'}}
                                                            className="purpleButton"
                                                            onClick={e => pesquisaContaGerencial(e)}
                                                        >
                                                            Pesquisar Conta Gerencial
                                                        </Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                        <div style={{width: "25%"}} className="pb-3">
                            <Label style={{fontWeight: '700'}}>Pesquisar</Label>
                            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                        </div>
                        <table {...getTableProps()}>
                            <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th>
                                            {column.render("Header")}
                                            <span className="float-right">
                                            {column.isSorted ?
                                                (column.isSortedDesc ?
                                                    <BsIcons.BsArrowDown
                                                        {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
                                                    />
                                                    :
                                                    <BsIcons.BsArrowUp
                                                        id="OrdenarAsc"
                                                        {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
                                                    />)
                                                :
                                                <BsIcons.BsArrowDownUp
                                                    id="Ordenar"
                                                    {...column.getHeaderProps(column.getSortByToggleProps({title: undefined}))}
                                                />}
                                        </span>
                                        </th>
                                    ))}
                                    <th style={{textAlign: "center"}}>
                                        Ações
                                    </th>
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <tr className="text-center" {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (<td {...cell.getCellProps()}>{cell.render("Cell")}</td>)
                                        })}
                                        <td>
                                            <>
                                                {
                                                    permitions.criar && (
                                                        <BiIcons.BiEdit
                                                            style={{
                                                                fontSize: "1.2rem",
                                                                cursor: "pointer",
                                                                color: "#C4C7CA",
                                                                marginRight: "7px"
                                                            }}
                                                            onClick={() => handleEdit(row.original.id)}
                                                        />
                                                    )
                                                }
                                                {
                                                    permitions.visualizar && (
                                                        <AiIcons.AiOutlineEye
                                                            style={{
                                                                fontSize: "1.2rem",
                                                                cursor: "pointer",
                                                                color: "#C4C7CA",
                                                                marginRight: "7px"
                                                            }}
                                                            onClick={() => seeRegister(row.original.id)}
                                                        />
                                                    )
                                                }
                                                {
                                                    permitions.deletar && (
                                                        <BiIcons.BiTrash
                                                            style={{
                                                                fontSize: "1.2rem",
                                                                cursor: "pointer",
                                                                color: "#C4C7CA"
                                                            }}
                                                            onClick={() => handleDelete(row.original.id)}
                                                        />
                                                    )
                                                }
                                            </>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <Row className="pt-4">
                            <Col>
                                <span
                                    style={{color: "var(--color-cinza-medio)"}}>Mostrando {getAll.quantidade !== undefined ? getAll.quantidade : "0"} de {getAll.quantidadeTotal !== undefined ? getAll.quantidadeTotal : "0"}</span>
                            </Col>
                            <Col md={8}>
                                <Row className="float-right">
                                    <ReactPaginate
                                        previousLabel={'<'}
                                        nextLabel={'>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        initialPage={0}
                                        pageCount={getAll.totalPaginas}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={e => handlePageClick(e)}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={initialPage ? 0 : selectedPage}
                                    />
                                    <Input
                                        type="select"
                                        className="pageSize"
                                        value={pageSize}
                                        onChange={e => {
                                            setPageSize(Number(e.target.value));
                                            handleInitialPage(Number(e.target.value))
                                        }}>
                                        {
                                            [10, 25, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))
                                        }
                                    </Input>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Card>
            </Col>
        </>
    );
}


export default Search;