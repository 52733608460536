import React, { useState } from "react";
import { AiOutlineSearch } from 'react-icons/ai'
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useSelector } from "react-redux";

import { Switch } from '../../../components/Inputs/Input'
import { maskMoeda, maskNumber } from "../../../util/masks";

import * as actions from "../../../store/modules/contasAPagar/actions";

import ModalFornecedor from '../components/modalFornecedor'
import ModalFilial from '../components/modalFilial'
import ModalFavorecido from '../components/modalFavorecido'
import { removeAccentsAndUppercase } from "../../../util/masks";

const Register = () => {
    const { item,  seeRegister } = useSelector(state => state.contasAPagar);

    const [modalFornecedor, setModalFornecedor] = useState(false)
    const [modalFilial, setModalFilial] = useState(false)
    const [modalFavorecido, setModalFavorecido] = useState(false)
    
    const toggleFornecedor = () => setModalFornecedor(!modalFornecedor)
    const toggleFilial = () => setModalFilial(!modalFilial)
    const toggleFavorecido = () => setModalFavorecido(!modalFavorecido)

    function onChange(campo, value){
        actions.setItem(campo, value)
    }
    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2">
                        <p style={{ fontSize: "20px" }}>Preencha os campos para cadastrar uma conta a pagar</p>
                        <p>* Campos obrigatórios</p>
                    </Col>
                    <Col md={12} className="pt-2">
                        <Form>
                            <Row>
                            <Col md={4}>
                                <FormGroup>
                                <Label >Empresa <span style={{color: 'red'}}>*</span></Label>
                                <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.empresaId}
                                        onChange={e => onChange('empresaId', e.target.value)}
                                        disabled={true}
                                    />
                                </FormGroup>
                            </Col>                         
                            <Col md={5}>
                                <FormGroup>
                                <Label >Filial <span style={{color: 'red'}}>*</span></Label>
                                <Input
                                    type="text"
                                    placeholder="Busque uma filial..."
                                    value={item.filial}
                                    onChange={e => onChange('filial', e.target.value)}
                                    disabled={true}
                                />
                                </FormGroup>
                            </Col>
                            <AiOutlineSearch
                                 style={{color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer'}}
                                 onClick={() => toggleFilial()}
                                />
                            <Col md={2}>
                                <Label> CNPJ Filial</Label>
                                <p style={{marginTop: '5px'}}>
                                    {item.cnpjFilial}
                                </p>
                            </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Título <span style={{color: 'red'}}>*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.titulo}
                                        onChange={e => onChange('titulo', removeAccentsAndUppercase(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Tipo do Título <span style={{color: 'red'}}>*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.tipoTitulo}
                                        onChange={e => onChange('tipoTitulo', removeAccentsAndUppercase(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                    <Label>Fornecedor</Label>
                                    <Input
                                        type="text"
                                        placeholder="Busque um fornecedor..."
                                        value={item.fornecedor}
                                        onChange={e => onChange('fornecedor', e.target.value)}
                                        disabled={true}
                                    />
                                    </FormGroup>
                                </Col>
                                <AiOutlineSearch
                                 style={{color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer'}}
                                 onClick={() => toggleFornecedor()}
                                />
                                <Col md={2}>
                                    <Label> CNPJ Fornecedor</Label>
                                    <p style={{marginTop: '5px'}}>
                                        {item.cnpjFornecedor}
                                    </p>
                                </Col>
                                <Col md={5}>
                                    <FormGroup>
                                        <Label>Favorecido <span style={{color: 'red'}}>*</span></Label>
                                        <Input
                                        type="text"
                                        placeholder="Busque um favorecido..."
                                        value={item.favorecido}
                                        onChange={e => onChange('favorecido', e.target.value)}
                                        disabled={true}
                                    />
                                    </FormGroup>
                                </Col>
                                <AiOutlineSearch
                                 style={{color: 'var(--color-verde)', marginTop: '30px', fontSize: '20px', cursor: 'pointer'}}
                                 onClick={()=> toggleFavorecido()}
                                />
                            </Row>
                            <Row style={{marginTop: '20px'}}>
                                <Col md={1}>
                                    <p style={{fontSize: '17px', color: 'var(--color-preto)'}}> Valores</p>
                                </Col>
                                <div style={{width: '91%', height: '1px', borderTop: '1px solid #CFCFCF', marginTop: '10px'}}></div>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Valor <span style={{color: 'red'}}>*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.valor}
                                        onChange={e => onChange('valor', maskMoeda(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Forma de pagamento <span style={{color: 'red'}}>*</span>
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.formaPagamento}
                                        onChange={e => onChange('formaPagamento', removeAccentsAndUppercase(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Transação
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.transacaoValor}
                                        onChange={e => onChange('transacaoValor', removeAccentsAndUppercase(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Data de entrada <span style={{color: 'red'}}>*</span>
                                    </Label>
                                    <Input
                                        type="date"
                                        value={item.dataEntrada}
                                        max="9999-12-31"
                                        onChange={e => onChange('dataEntrada', e.target.value)}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Data de emissão <span style={{color: 'red'}}>*</span>
                                    </Label>
                                    <Input
                                        type="date"
                                        value={item.dataEmissao}
                                        max="9999-12-31"
                                        onChange={e => onChange('dataEmissao', e.target.value)}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Vencimento <span style={{color: 'red'}}>*</span>
                                    </Label>
                                    <Input
                                        type="date"
                                        value={item.dataVencimento}
                                        max="9999-12-31"
                                        onChange={e => onChange('dataVencimento', e.target.value)}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={1}>
                                    <Label>
                                        Considerar Juros
                                    </Label>
                                    <div style={{marginTop: '-20px', marginLeft: '-10px'}}>
                                        <Switch
                                            className='switch mt-4'
                                            checked={!!item.considerarJuros}
                                            onChange={e => onChange('considerarJuros', item.considerarJuros === 0 ? 1 : 0)}
                                            disabled={seeRegister}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                <FormGroup>
                                    <Label>
                                        Percentual de juros
                                    </Label>
                                    <Input
                                        type="text"
                                        value={item.percJuros}
                                        onChange={e => onChange('percJuros', maskNumber(e.target.value))}
                                        disabled={seeRegister ? true : !!!item.considerarJuros}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                <FormGroup>
                                    <Label>
                                        Percentual de multa
                                    </Label>
                                    <Input
                                        type="text"
                                        value={item.percMulta}
                                        onChange={e => onChange('percMulta', maskNumber(e.target.value))}
                                        disabled={seeRegister ? true : !!!item.considerarJuros}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '20px'}}>
                                <Col md={1}>
                                    <p style={{fontSize: '17px', color: 'var(--color-preto)'}}> Informações</p>
                                </Col>
                                <div style={{width: '91%', height: '1px', borderTop: '1px solid #CFCFCF', marginTop: '10px'}}></div>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                    <Label>
                                        Portador
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.portador}
                                        onChange={e => onChange('portador', maskNumber(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                    <Label>
                                        Carteira
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.carteira}
                                        onChange={e => onChange('carteira', maskNumber(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                    <Label>
                                        Observação
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.observacao}
                                        onChange={e => onChange('observacao', removeAccentsAndUppercase(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '20px'}}>
                                <Col md={1}>
                                    <p style={{fontSize: '17px', color: 'var(--color-preto)'}}> Conta</p>
                                </Col>
                                <div style={{width: '91%', height: '1px', borderTop: '1px solid #CFCFCF', marginTop: '10px'}}></div>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Agência
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.agencia}
                                        onChange={e => onChange('agencia', removeAccentsAndUppercase(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Conta
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.conta}
                                        onChange={e => onChange('conta', removeAccentsAndUppercase(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Conta financeira
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.contaFinanceira}
                                        onChange={e => onChange('contaFinanceira', removeAccentsAndUppercase(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Conta contábil
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.contaContabil}
                                        onChange={e => onChange('contaContabil', removeAccentsAndUppercase(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '20px'}}>
                                <Col md={2}>
                                    <p style={{fontSize: '17px', color: 'var(--color-preto)'}}> Informações do projeto</p>
                                </Col>
                                <div style={{width: '82%', height: '1px', borderTop: '1px solid #CFCFCF', marginTop: '10px'}}></div>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Projeto
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.projeto}
                                        onChange={e => onChange('projeto', e.target.value)}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Orçamento
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.orcamento}
                                        onChange={e => onChange('orcamento', e.target.value)}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '20px'}}>
                                <Col md={2}>
                                    <p style={{fontSize: '17px', color: 'var(--color-preto)'}}> Outras informações</p>
                                </Col>
                                <div style={{width: '82%', height: '1px', borderTop: '1px solid #CFCFCF', marginTop: '10px'}}></div>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Percentual de rateio
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.percRateio}
                                        onChange={e => onChange('percRateio', maskNumber(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Rateio
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.rateio}
                                        onChange={e => onChange('rateio', maskNumber(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>
                                        Centro de custo
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.centroCusto}
                                        onChange={e => onChange('centroCusto', removeAccentsAndUppercase(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>Valor rateio centro de custo</Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.valorRateioCentroCusto}
                                        onChange={e => onChange('valorRateioCentroCusto', maskNumber(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                    <Label>Percentual centro de custo</Label>
                                    <Input
                                        type="text"
                                        placeholder="Digite aqui..."
                                        value={item.percRateioCentroCusto}
                                        onChange={e => onChange('percRateioCentroCusto', maskNumber(e.target.value))}
                                        disabled={seeRegister}
                                    />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Card>
            </Col>
            {
                <ModalFilial
                    modalPessoa={modalFilial}
                    toggle={toggleFilial} />
            }
            {
                <ModalFavorecido
                    modalPessoa={modalFavorecido}
                    toggle={toggleFavorecido} />
            }
            {
                <ModalFornecedor
                    modalPessoa={modalFornecedor}
                    toggle={toggleFornecedor}
                    search="false" />
            }
        </>
    );
}

export default Register;