const STORE_ID = 'USUARIO'

const TYPES = {
    SET_SISTEMAS: 'SET_SISTEMAS',
    CLEAN_REGISTER: 'CLEAN_REGISTER',
    INITIAL_REGISTER: 'INITIAL_REGISTER',
    SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
    SET_SEE: 'SET_SEE',
    SET_PERFIS: 'SET_PERFIS',
    SET_PERMITIONS: 'SET_PERMITIONS',
    SET_ITEMS: 'SET_ITEMS',
    SET_ITEM: 'SET_ITEM',
    USUARIO_COPY: 'USUARIO_COPY',
    INVALID_USUARIO: 'INVALID_USUARIO',
    CLEAN_INVALID_USUARIO: 'CLEAN_INVALID_USUARIO',
    SET_BLOCK: 'SET_BLOCK',
    SEARCH: 'SEARCH',
    CLEAN_SEARCH: 'CLEAN_SEARCH',
    VERIFYPASS: 'VERIFYPASS',
    SEEUPDATE: 'SEEUPDATE',
    CHANGEPASS: 'CHANGEPASS',
    SETSENHA: 'SETSENHA',
    GET_ALL_ITEMS: 'GET_ALL_ITEMS'
}

export { STORE_ID, TYPES }