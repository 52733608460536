const STORE_ID = "TIPODOCUMENTOS";

const TYPES = {
    DOCUMENTO: "DOCUMENTO",
    SET_PERMITIONS: 'SET_PERMITIONS',
    DOCUMENTO_COPY: "DOCUMENTO_COPY",
    INVALID_DOCUMENTO: "INVALID_DOCUMENTO",
    DOCUMENTOS: "DOCUMENTOS",
    CLEAN_INVALID_DOCUMENTO: "CLEAN_INVALID_DOCUMENTO",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    INITIAL_REGISTER: "INITIAL_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    SET_PERSONA: "SET_PERSONA"
}

export { STORE_ID, TYPES }