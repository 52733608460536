import React from "react";
import { Card, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { useSelector } from "react-redux";

import * as produtoRcActions from "../../../store/modules/produtosRibercred/actions";
import * as produtosRcController from "../../../controllers/produtosRcController";
import { removeAccentsAndUppercase } from "../../../util/masks";

const Register = () => {

    const { item, invalid, copy, seeRegister } = useSelector(state => state.produtoRc);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                produtosRcController.Create(item);
            } else {
                produtosRcController.Update(item, copy);
            };
        }
    }

    function onChangeItem(value, field) {
        produtoRcActions.cleanInvalid(field);
        produtoRcActions.item(field,  value );
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2" style={{ margin: "25px 0 0 19px" }}>
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>Preencha os campos para cadastrar um novo Produto Ribercred</p>
                    </Col>
                    <Col md={10} className="pt-2">
                        <Form>
                            <Col md={6} style={{ marginTop: '25px' }}>
                                <FormGroup>
                                    <Label for="productDescription" style={{ color: 'var(--color-preto)', fontWeight: '700' }}>
                                        Descrição do Produto Ribercred
                                        </Label>
                                    <Input
                                        type="text"
                                        name="product"
                                        id="productDescription"
                                        placeholder="Digite aqui..."
                                        value={item.nome}
                                        onChange={e => onChangeItem(removeAccentsAndUppercase(e.target.value), "nome")}
                                        className={invalid.nome ? "is-invalid" : ""}
                                        disabled={seeRegister ? true : false}
                                        onKeyPress={e => handleKeyPress(e)}
                                    />
                                </FormGroup>
                            </Col>
                        </Form>
                    </Col>
                </Card>
            </Col>
        </>
    );
}

export default Register;

