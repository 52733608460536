const STORE_ID = "SENHA";

const TYPES = {
    SENHA: "SENHA",
    INVALID_SENHA: "INVALID_SENHA",
    CLEAN_INVALID_SENHA: "CLEAN_INVALID_SENHA",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    VALID_SENHA: "VALID_SENHA",
}

export { STORE_ID, TYPES }