import { STORE_ID, TYPES } from "./types";

const INITIAL_STATE = {
  item: {
    canalId: "0",
    campanhaId: "0",
    pessoaId: "0",
    pessoa: "",
    hierarquiaId: "0",
    centroCustoId: "0",
    empresaFuncionarioId: "0",
    campanhaTipo: "0",
    statusIntegracao: "0",
    statusFavorecido: "0",
    sistema: "0",
    dataPagamento: "",
    dataIntegracao: "",
    aceito: false,
    sistemaId: "0",
    statusId: "0",
  },
  modalConsultores: {
    id: "",
    nome: "",
    cpf: "",
    hierarquiaId: "0",
    canalId: "0",
  },
  getAllConsultores: {
    quantidade: "0",
    quantidadeTotal: "0",
    totalPaginas: "0",
  },
  allItems: {
    quantidade: "0",
    quantidadeTotal: "0",
    totalPaginas: "0",
  },
  canais: [],
  campanhas: [],
  hierarquias: [],
  pessoas: [],
  consultores: [],
  centrosCusto: [],
  empresasFuncionario: [],
  campanhaTipo: [
    { id: "1", descricao: "Produtividade " },
    { id: "2", descricao: "Superacao  " },
    { id: "3", descricao: "RegraRepasse  " },
  ],
  statusIntegracao: [],
  statusFavorecido: [
    {
      id: true,
      descricao: "Admitido",
    },
    {
      id: false,
      descricao: "Demitido",
    },
  ],
  sistema: [],
  items: [],
  activeTab: "Search",
  seeRegister: false,
  permitions: {
    criar: false,
    deletar: false,
  },
};

export default function contasReceber(state = INITIAL_STATE, action) {
  switch (action.storeId) {
    case STORE_ID:
      switch (action.type) {
        case TYPES.SET_PERMITIONS:
          return { ...state, permitions: action.value };
        case TYPES.SET_MODAL_CONSULTORES:
          return {
            ...state,
            modalConsultores: {
              ...state.modalConsultores,
              [action.campo]: action.value,
            },
          };
        case TYPES.SET_GET_ALL_CONSULTORES:
          return { ...state, getAllConsultores: action.value };
        case TYPES.GET_ALL_ITENS:
          return { ...state, allItems: action.value };
        case TYPES.ITEM:
          return {
            ...state,
            item: { ...state.item, [action.field]: action.value },
          };
        case TYPES.ITEMS:
          return { ...state, items: action.value };
        case TYPES.SET_CANAIS:
          return { ...state, canais: action.value };
        case TYPES.SET_CAMPANHA:
          return { ...state, campanhas: action.value };
          case TYPES.SET_HIERARQUIAS:
            return { ...state, hierarquias: action.value }
        case TYPES.SET_CONSULTORES:
          return { ...state, consultores: action.value };
        case TYPES.SET_CENTROS_CUSTO:
          return { ...state, centrosCusto: action.value };
        case TYPES.SET_EMPRESAS_FUNCIONARIO:
          return { ...state, empresasFuncionario: action.value };
        case TYPES.SET_CAMPANHA_TIPO:
          return { ...state, campanhaTipo: action.value };
        case TYPES.SET_STATUS_INTEGRACAO:
          return { ...state, statusIntegracao: action.value };
        case TYPES.STATUS_FAVORECIDO:
          return { ...state, statusFavorecido: action.value };
        case TYPES.SET_SISTEMAS:
          return { ...state, sistemas: action.value };
        case TYPES.CLEAN_SEARCH:
          return {
            ...state,
            item: INITIAL_STATE.item,
          
          };
        case TYPES.CLEAN_REGISTER:
          return {
            ...state,
            item: INITIAL_STATE.item
           
          };
        case TYPES.SET_ACTIVE_TAB:
          return { ...state, activeTab: action.value };
        case TYPES.SET_SEE:
          return { ...state, seeRegister: action.value };
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
}
