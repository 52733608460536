import api from "../util/api";
import { notifyWarn, notifySuccess } from "../components/message/message";
import * as gruposActions from "../store/modules/grupos/actions";
import { removeMaskCNPJ, removeMaskCPF } from "../util/masks";
import { setLoading } from "../store/modules/globalLoading/actions";

export async function get() {

    setLoading(true)
    const data = await api.get("grupos");
    setLoading(false)

    if (!data.success) {
        return;
    }

    gruposActions.getItems(data.content);
}

export async function getPessoas(cpfCnpj) {

    let data = "";

    if (cpfCnpj.length === 14) {
        setLoading(true)
        data = await api.get(`pessoa/${removeMaskCPF(cpfCnpj)}`);
        setLoading(false)
    }

    if (cpfCnpj.length === 18) {
        setLoading(true)
        data = await api.get(`pessoa/${removeMaskCNPJ(cpfCnpj)}`);
        setLoading(false)
    }

    if (!data.success) {
        return;
    }

    gruposActions.setPessoa(data.content.nome, "nome");
    gruposActions.setPessoa(data.content.id, "idPessoa");

}

export async function getPessoasModal(isJuridica, store,page, pageSize,cpfcnpj) {

    var url = `Pessoa?pagina=${page}&registros=${pageSize}&flagJuridica=${isJuridica}`
    if (store.nome !== ''){
        url = `Pessoa/busca-com-nome/${store.nome}?pagina=${page}&registros=${pageSize}&flagJuridica=${isJuridica}`
    } 

    else if (store.cnpj !== '' && cpfcnpj === true){
        if (store.cpfCnpj.length === 14) {
        url = url + `&cpfCnpj=${removeMaskCPF(store.cpfCnpj)}`
        }
        if (store.cpfCnpj.length === 18) {
            url = url + `&cpfCnpj=${removeMaskCNPJ(store.cpfCnpj)}`
        }
    }

    gruposActions.setLoadingModal(true)
    const data = await api.get(url);
    gruposActions.setLoadingModal(false)

    if (!data.success) {
        return;
    }

    gruposActions.setPessoasModal(data.content.registros);
    gruposActions.setGetAll({
        quantidade: data.content.quantidade, 
        quantidadeTotal: data.content.quantidadeTotal, 
        totalPaginas: data.content.totalPaginas
    })

}

export async function getById(id) {

    setLoading(true)
    const data = await api.get(`grupos/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

  gruposActions.copy(data.content);
  gruposActions.item({...data.content, flagJuridica: false});
}

export async function create(item) {

    if (item.descricao.trim() === "") {
        gruposActions.setInvalid("descricao");
        notifyWarn("Descrição do grupo não pode estar em branco");
        return;
    }
    if (item.pessoas.length === "") {
        notifyWarn("Não é possível salvar o grupo sem pessoas");
        return;
    }

    const body = {
        descricao: item.descricao,
        pessoas: item.pessoas
    }

    setLoading(true)
    const data = await api.post("grupos", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Grupo cadastrado");
    gruposActions.cleanRegister();
    get();
}

export async function remove(id) {

    setLoading(true)
    const data = await api.delete(`grupos/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Grupo removido");
    get();
}

export async function update(item) {

    if (item.descricao.trim() === "") {
        gruposActions.setInvalid("descricao");
        notifyWarn("Descrição do grupo não pode estar em branco");
        return;
    }

    const body = {
        descricao: item.descricao,
        pessoas: item.pessoas
    }

    setLoading(true)
    const data = await api.put(`grupos/${item.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Grupo atualizado");
    gruposActions.cleanRegister();
    gruposActions.setActiveTab("Search");
    get();
}
