import React, { useEffect } from "react";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Select } from "../../../components/Inputs/Input";
import { useSelector } from "react-redux";
import * as statusActions from "../../../store/modules/statusInstituicao/actions";
import * as statusController from "../../../controllers/statusInstituicaoController";
import { removeAccents } from "../../../util/masks";

const Register = () => {

    const { item, statusRcAll, bancos, invalid, copy, seeRegister } = useSelector(state => state.statusInstituicao);

    useEffect(() => {
        statusController.getStatusRc();
    }, []);

    useEffect(() => {
        statusController.getBancos();
    }, []);

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item.id === "") {
                statusController.create(item);
            } else {
                statusController.update(item, copy);
            }
        }
    }

    function onChangeItem(value, field) {
        statusActions.cleanInvalid(field);
        statusActions.item({ ...item, [field]: value });
    }

    return (
        <>
            <Col>
                <Card className="radius pb-5">
                    <Col className="pt-2" style={{ margin: '25px 0 0 25px' }}>
                        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>Preencha os campos para cadastrar um novo Status da Instituição</p>
                    </Col>
                    <Col className="pt-2">
                        <Form>
                            <Row style={{ width: "25%" }}>
                                <Col style={{ margin: '25px 0 0 25px' }}>
                                    <FormGroup>
                                        <Label for="convenio" style={{ fontWeight: '700', color: 'var(--color-preto)' }}>
                                            Descrição do Status da Instituição
                                        </Label>
                                        <Input
                                            type="text"
                                            name="convenio"
                                            id="convenio"
                                            placeholder="Digite aqui..."
                                            value={item.descricao}
                                            onChange={e => onChangeItem(removeAccents(e.target.value), "descricao")}
                                            onKeyPress={e => handleKeyPress(e)}
                                            className={invalid.descricao ? "is-invalid" : ""}
                                            disabled={seeRegister}
                                        />
                                    </FormGroup>
                                    <FormGroup style={{ marginTop: '25px' }}>
                                        <Label for="instituicao" style={{ fontWeight: '700', color: 'var(--color-preto)' }}>
                                            Instituição Financeira
                                        </Label>
                                        <Select
                                            first="SELECIONE"
                                            value={{ value: item.bancoId }}
                                            select={{ value: "id", label: "nome" }}
                                            onChange={(e) => onChangeItem(e.value, "bancoId")}
                                            options={bancos}
                                            isSearchable={true}
                                            autoComplete="off"
                                            disabled={seeRegister}
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor: invalid.bancoId ? "red" : "lightgrey",
                                                    minHeight: "35px",
                                                    height: "35px"
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup style={{ marginTop: '25px' }}>
                                        <Label for="associacao" style={{ fontWeight: '700', color: 'var(--color-preto)' }}>
                                            Associação Status Ribercred
                                        </Label>
                                        <Select
                                            first="SELECIONE"
                                            value={{ value: item.statusRcId }}
                                            select={{ value: "id", label: "descricao" }}
                                            onChange={(e) => onChangeItem(e.value, "statusRcId")}
                                            options={statusRcAll}
                                            isSearchable={true}
                                            autoComplete="off"
                                            disabled={seeRegister}
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor: invalid.statusRcId ? "red" : "lightgrey",
                                                    minHeight: "35px",
                                                    height: "35px"
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Card>
            </Col>
        </>
    );
}

export default Register;