const STORE_ID = "LAYOUT";

const TYPES = {
  CLEAR_LAYOUT_BEING_CREATED: "CLEAR_LAYOUT_BEING_CREATED",
  SET_LAYOUT_BEING_CREATED: "SET_LAYOUT_BEING_CREATED",
  CLEAR_LAYOUT_BEING_EDITED: "CLEAR_LAYOUT_BEING_EDITED",
  SET_LAYOUT_BEING_EDITED: "SET_LAYOUT_BEING_EDITED",
  SET_LAYOUT_BEING_EDITED_INITIAL_VALUE_BACKUP:
    "SET_LAYOUT_BEING_EDITED_INITIAL_VALUE_BACKUP",
  SET_LAYOUT_BEING_VISUALIZED: "SET_LAYOUT_BEING_VISUALIZED",
  CLEAR_LAYOUT_BEING_VISUALIZED: "CLEAR_LAYOUT_BEING_VISUALIZED",
  SET_LAYOUT_BEING_EDITED_ID: "SET_LAYOUT_BEING_EDITED_ID",
  SET_LAYOUT_BEING_VISUALIZED_ID: "SET_LAYOUT_BEING_VISUALIZED_ID",
  SET_IS_IN_EDIT_MODE: "SET_IS_IN_EDIT_MODE",
  SET_IS_IN_VISUALIZE_MODE: "SET_IS_IN_VISUALIZE_MODE",
  CLEAR_MAPEAMENTO: "CLEAR_MAPEAMENTO",
  CLEAR_DETALHAMENTO: "CLEAR_DETALHAMENTO",
  CLEAR_STATUS: "CLEAR_STATUS",
  SET_IS_MODAL_DETALHAMENTO_OPEN: "SET_IS_MODAL_DETALHAMENTO_OPEN",
  SET_IS_MODAL_STATUS_OPEN: "SET_IS_MODAL_STATUS_OPEN",

  SET_PERMITIONS: "SET_PERMITIONS",
  TIPOS_COMISSAO: "TIPOS_COMISSAO",
  SET_LAYOUT_PARAMETROS: "SET_LAYOUT_PARAMETROS",
  SET_MAPEAMENTO: "SET_MAPEAMENTO",
  SET_DETALHAMENTO: "SET_DETALHAMENTO",
  SET_STATUS: "SET_STATUS",
  SET_LAYOUTS: "SET_LAYOUTS",
  SET_TIPOS_ARQUIVO: "SET_TIPOS_ARQUIVO",
  SET_INSTITUICOES: "SET_INSTITUICOES",
  SET_LAYOUTS_CAMPOS_EQUIVALENTES: "SET_LAYOUTS_CAMPOS_EQUIVALENTES",
  SET_ALL_LAYOUTS_CAMPOS_EQUIVALENTES: "SET_ALL_LAYOUTS_CAMPOS_EQUIVALENTES",
  CLEAN_LAYOUTS_CAMPOS_EQUIVALENTES: "CLEAN_LAYOUTS_CAMPOS_EQUIVALENTES",
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  ADD_LAYOUT_PLANILHA_PARAMETROS: "ADD_LAYOUT_PLANILHA_PARAMETROS",
  REMOVE_LAYOUT_PLANILHA_PARAMETROS: "REMOVE_LAYOUT_PLANILHA_PARAMETROS",
};

export { STORE_ID, TYPES };
