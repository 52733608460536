const STORE_ID = "FORMALIZCAOLOJA";

const TYPES = {
    ITEM: 'ITEM',
    SET_ITEM: 'SET_ITEM',
    GET_PARAMETROS: 'GET_PARAMETROS',
    GET_RECORRENCIAS: 'GET_RECORRENCIAS',
    GET_LAYOUTES: 'GET_LAYOUTES',
    CLEAN_REGISTER: 'CLEAN_REGISTER',
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    GET_ALL_ITEMS: "GET_ALL_ITEMS",
}

export { STORE_ID, TYPES }