const STORE_ID = "RCPRODUCTS";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    PESQUISAR: 'PESQUISAR',
    ITEM: 'ITEM',
    SET_CONTAS: 'SET_CONTAS',
    SET_ITEM: 'SET_ITEM',
    SET_MODAL_FORNECEDOR: 'SET_MODAL_FORNECEDOR',
    SET_MODAL_FILIAL: 'SET_MODAL_FILIAL',
    SET_MODAL_FAVORECIDO: 'SET_MODAL_FAVORECIDO',
    SET_EMPRESAS: 'SET_EMPRESAS',
    SET_FILIAIS: 'SET_FILIAIS',
    SET_FORNECEDORES: 'SET_FORNECEDORES',
    SET_FAVORECIDOS: 'SET_FAVORECIDOS',
    SET_GET_FAVORECIDO: 'SET_GET_FAVORECIDO',
    SET_GET_FILIAL: 'SET_GET_FILIAL',
    SET_ALL_ITENS: 'SET_ALL_ITENS',
    SET_GET_FORNECEDOR: 'SET_GET_FORNECEDOR',
    SET_COPIA: 'SET_COPIA',
    CLEAN_SEARCH: 'CLEAN_SEARCH',
    CLEAN_REGISTER: 'CLEAN_REGISTER',
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    SET_LOADING_FILIAL: 'SET_LOADING_FILIAL',
    SET_LOADING_FAVORECIDO: 'SET_LOADING_FAVORECIDO',
    SET_LOADING_FORNECEDOR: 'SET_LOADING_FORNECEDOR'
}

export { STORE_ID, TYPES }