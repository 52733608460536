import React, { useEffect, useMemo, useState } from 'react'
import { usePagination, useTable } from 'react-table';
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux"
import { Modal, ModalBody, Col, Button, Input, Label, Row, Navbar, FormGroup } from "reactstrap"
import { InputCpfCnpj, Select } from '../../../components/Inputs/Input'
import { maskCNPJ, maskCPF } from '../../../util/masks'
import * as actions from "../../../store/modules/contasReceber/actions"
import * as controller from '../../../controllers/contasReceberController'
import LoadingIcon from '../../../components/Loading/LoadingIcon'

export default function ModalFavorecido({ modalPessoa, toggle }) {
    const { modalFavorecido, favorecidos, item , allItemsFavorecido, modalFavorecidoLoading} = useSelector(state => state.contasReceber)

    const columns = useMemo(() => [{
        Header: "CPF/CNPJ"
    },{
        Header: "NOME",
    },], []); 
    const data = favorecidos
    
    const {
        page,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
       columns,
       data
    },
        usePagination);
    
    const { pageSize } = state;
    const [rows, setRows] = useState(0);
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);
    
    useEffect(() => {
        setRows(page.length);
    }, [page])
    
    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);
        controller.getFavorecidos(modalFavorecido, pageSelected+1, pageSize)
    }
    
    function handleInitialPage(pageSize) {
        setInitialPage(false);
        setSelectedPage(0);
        gotoPage(0);
        controller.getFavorecidos(modalFavorecido, 1, pageSize)
    }
    
    function onChangeItem(value, campo) {
        actions.cleanInvalid(campo)
        actions.setItem({ ...item, [campo]: value })
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault()
            controller.getFavorecidos(modalFavorecido, 1, pageSize)
        }
    }

    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalPessoa}
                toggle={toggle}
                backdrop={true}
                size="lg"
            >
                <Navbar expand="md" style={{ width: "100%", height: "40px", background: 'var(--color-gradiente-verde)', marginBottom: '20px' }}>
                    <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2" >Pesquisa de Favorecidos</h5>
                </Navbar>
                <ModalBody >
                    <Row>
                        <Col md={4}>
                            <Label className="float-left">CPF/CNPJ</Label>
                            <InputCpfCnpj
                                type="text"
                                placeholder="Digite aqui..."
                                tipo={modalFavorecido.cpfCnpj.length <= 14 ? 'cpf' : 'cnpj'}
                                value={modalFavorecido.cpfCnpj}
                                onChange={e => actions.setModalFavorecido('cpfCnpj', e.target.value)}
                                onKeyPress={e => handleKeyPress(e)}
                                maxLength="18"
                            />
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label className="float-left">Nome</Label>
                                <Input
                                    type="text"
                                    placeholder="Digite aqui..."
                                    value={modalFavorecido.nome}
                                    onChange={e => actions.setModalFavorecido('nome', e.target.value)}
                                    onKeyPress={e => handleKeyPress(e)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label className="float-left" style={{ marginRight: '90%' }}>Filtro</Label>
                                <Select
                                    first="Selecione"
                                    value={{ value: modalFavorecido.filtro }}
                                    select={{ value: 'id', label: "descricao" }}
                                    options={[{ id: 1, descricao: "CPF" }, { id: 2, descricao: "CNPJ" }, { id: 3, descricao: "LOJAS" }]}
                                    onChange={e => actions.setModalFavorecido('filtro', e.label)}
                                    isSearchable={false}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <fieldset className={favorecidos.length > 0 ? "fieldset-socio" : "fieldset-socio-no-results"}>
                        <legend style={{ textAlign: "left", fontSize: "20px" }}>Resultados</legend>
                        {
                            modalFavorecidoLoading &&
                            <LoadingIcon />
                        }
                        <table>
                            <tr>
                                <th style={{ width: "150px" }}>CPF/CNPJ</th>
                                <th>Nome</th>
                            </tr>
                            {
                                favorecidos.map(row => {
                                    return (
                                        <tr onDoubleClick={() => { onChangeItem(row.nome, 'favorecido'); toggle(); actions.cleanSearch() }}>
                                            <td index={row.id}>{row.cpfCnpj.length <= 11 ? maskCPF(row.cpfCnpj) : maskCNPJ(row.cpfCnpj)}</td>
                                            <td index={row.id}>{row.nome}</td>
                                        </tr>
                                    )
                                })
                            }

                        </table>
                        <br />
                    </fieldset>
                    {favorecidos && <Row className="pt-4">
                        <Col md={3}>
                            <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {rows} de {allItemsFavorecido.quantidadeTotal}</span>
                        </Col>
                        <Col md={8}>
                            <Row className="float-right">
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    initialPage={0}
                                    pageCount={allItemsFavorecido.totalPaginas}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={initialPage ? 0 : selectedPage}
                                />
                                <Input
                                    type="select"
                                    className="pageSize"
                                    value={pageSize}
                                    onChange={e => { handleInitialPage(e.target.value);setPageSize(Number(e.target.value)); }}>
                                    {
                                        [10, 25, 100].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize}
                                            </option>
                                        ))
                                    }
                                </Input>
                            </Row>
                        </Col>
                        </Row>}
                </ModalBody>
                <div className="m-3">
                    <Button
                        onClick={() => { actions.setFavorecidos([]); actions.cleanSearch() }}
                        className="mr-4"
                        style={{
                            width: "150px",
                            background: "var(--color-branco)",
                            borderColor: "var(--color-laranja)",
                            color: "var(--color-laranja)",
                            borderRadius: "7px"
                        }}
                    >
                        Limpar
                    </Button>
                    <Button
                        onClick={() => controller.getFavorecidos(modalFavorecido, selectedPage + 1, pageSize)}
                        color="danger"
                        className="purpleButton"
                        style={{ width: "150px" }}
                    >
                        Pesquisar
                    </Button>
                </div>
            </Modal>
        </>
    )
}