import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import {
    Card,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from 'classnames';
import { COLUMNS } from "../constants/columns";
import * as actionsH from '../../../store/modules/propostas/httpRequest/actions';

import * as  propostasController from "../../../controllers/propostasController";
import SearchP from "./panels/search/searchP";

import LogHttp from "./panels/logHttp/LogHttp";

const Search = () => {
    const { propostas, getAll } = useSelector(state => state.propostas);
    const columns = useMemo(() => COLUMNS, []);
    const { tab } = useSelector(state => state.httpRequest);
    const data = propostas;
    const {
        setPageSize,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination);

    useEffect(() => {
        propostasController.getConvenios();
        propostasController.getInstituicoes();
        propostasController.getProdutos();
    }, [])


    useEffect(() => {
        if(getAll.tamanhoPagina){
            setPageSize(getAll.tamanhoPagina);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAll])

    function changeTab(value) {
        actionsH.setTab(value)
    }

    return (
        <>
            <Col>
                <Card className="pt-3 pb-2 mb-5">
                    <Nav>
                        <NavItem style={{marginLeft: '25px'}}>
                            <NavLink
                                className={classnames({ active: tab === '1' })}
                                onClick={() => { changeTab('1'); }}
                                style={
                                    tab === '1' ?
                                        { color: 'var(--color-preto', fontWeight: '700', borderBottom: '3px solid var(--color-verde-claro)', transition: '0.1s', cursor: 'pointer' }
                                        : { color: 'var(--color-preto', fontWeight: '700', cursor: 'pointer' }}
                            >
                                Pesquisar
                            </NavLink>
                        </NavItem>
                        <NavItem style={{ padding: '0 15px 0 15px' }}>
                            <NavLink
                                className={classnames({ active: tab === '2' })}
                                onClick={() => { changeTab('2'); }}
                                style={
                                    tab === '2' ?
                                        { color: 'var(--color-preto', fontWeight: '700', cursor: 'pointer', borderBottom: '3px solid var(--color-verde-claro)', transition: '0.1s' }
                                        : { color: 'var(--color-preto', cursor: 'pointer', fontWeight: '700' }}
                            >
                                Logs
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={tab}>
                        <TabPane tabId="1">
                            <SearchP/>
                        </TabPane>
                        <TabPane tabId="2">
                            <LogHttp/>
                        </TabPane>
                    </TabContent>
                </Card >
            </Col >
        </>
    );
}


export default Search;
