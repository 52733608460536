import React from 'react'
import BarCode from 'react-barcode'

import logo from '../../../assets/images/logo.svg'
export default class  ModalImpressao extends React.Component{
    render(){
        let date = new Date()
        let dataAtual = `${String(date.getDay()).padStart(2,'0')}/${String(date.getMonth()+1).padStart(2,'0')}/${date.getFullYear()}  ${date.getHours()}:${date.getMinutes()}`

        let dados = this.props.data
        return(
            <>
            <div
              
                style={{maxWidth: '1100px', width: '100%'}}
            > 
                <div style={{margin:'0 0 0px 20px'}}>
                        <div style={{display: 'flex', marginTop: '30px'}}>
                            <img src={logo} alt="logo" style={{width: '30px'}}/>
                            <h1 style={{fontSize: '20px', marginLeft: '35%'}}>
                                PROTOCOLO DE REMESSA
                            </h1>
                        </div>
                        <div style={{display: 'flex', border: '1px solid #DBDBDB', height: '123px', width: '100%', marginTop: '23px'}}>
                            <div style={{width: '20%', borderRight: '1px solid #DBDBDB', paddingLeft: '20px', textAlign: 'center'}}>
                                <p style={{margin: '10px 0 5px 0'}}>                                   
                                    Código de barras
                                </p>
                                <BarCode value={dados.codBarras} height={45} width={1.5}/>
                            </div>
                            <div style={{display: 'flex', width: '80%'}}>
                                <div style={{margin: '20px 0 0 20px', textAlign: 'left', width: '60%'}}>
                                    <p>{`Parceiro: ${dados.parceiro}`}</p>
                                    <p>{`Usuário: ${dados.usuario}`}</p>
                                    <p>{`Etiqueta: ${dados.etiqueta}`}</p>
                                </div>
                                <div style={{margin: '20px 0 0 20px', textAlign: 'left', width: '40%'}}>
                                    <p>{`Gerado em: ${dados.dataGerado}`}</p>
                                    <p>{`Impresso em: ${dataAtual}`}</p>
                                    <p>{`Total de propostas: ${dados.totalPropostas}`}</p>
                                </div>
                            </div>
                        </div>
                        <table style={{marginTop: '45px'}}>
                            <thead>
                                <tr>
                                    <td style={{border: 'none', color: 'white', background: '#595959'}}>Cliente</td>
                                   <td style={{border: 'none', color: 'white', background: '#595959'}}>Proposta</td>
                                   <td style={{border: 'none', color: 'white', background: '#595959'}}>Digitação</td>
                                   <td style={{border: 'none', color: 'white', background: '#595959'}}>Produto</td>
                                   <td style={{border: 'none', color: 'white', background: '#595959'}}>Operação</td>
                                   <td style={{border: 'none', color: 'white', background: '#595959'}}>Instituição Financeira</td>
                                </tr>
                            </thead>
                            <tbody>
                              { dados.propostas.map(e=>{
                                  return(
                                      <tr>
                                          <td style={{border:'none', borderBottom: '1px solid #DBDBDB'}}>
                                            {e.cliente}
                                          </td>
                                          <td style={{border:'none', borderBottom: '1px solid #DBDBDB'}}>
                                            {e.numProposta}
                                          </td>
                                          <td style={{border:'none', borderBottom: '1px solid #DBDBDB'}}>
                                            {e.digitacao}
                                          </td>
                                          <td style={{border:'none', borderBottom: '1px solid #DBDBDB'}}>
                                            {e.produto}
                                          </td>
                                          <td style={{border:'none', borderBottom: '1px solid #DBDBDB'}}>
                                            {e.operacao}
                                          </td>
                                          <td style={{border:'none', borderBottom: '1px solid #DBDBDB'}}>
                                            {e.instituicao}
                                          </td>
                                      </tr>
                                  )
                               })}
                            </tbody>
                        </table>
                </div>
            </div>
            </>
        )
    }
}