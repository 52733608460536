const STORE_ID = 'EVENTOS_CAMPANHA';

const TYPES = {
    ITEM: 'ITEM',
    INVALID_ITEM: 'INVALID_ITEM',
    CLEAN_INVALID_ITEM: 'CLEAN_INVALID_ITEM',
    CLEAN_REGISTER: 'CLEAN_REGISTER',
    HIERARQUIAS_PROPRIO: 'HIERARQUIAS_PROPRIO',
    HIERARQUIAS_TERCEIRO: 'HIERARQUIAS_TERCEIRO',
    CONVENIOS: 'CONVENIOS',
    BANCOS: 'BANCOS',
    CANAIS: 'CANAIS',
    GRUPOS: 'GRUPOS',
    PRODUTOS: 'PRODUTOS',
    TIPOS_PARCERIA: 'TIPOS_PARCERIA',
    SET_MEDIDAS_PREMIACAO: 'SET_MEDIDAS_PREMIACAO',
    SET_PARAMETROS_MEDIDA: 'SET_PARAMETROS_MEDIDA',
    META_PRODUTIVIDADE: 'META_PRODUTIVIDADE',
    SET_META_PRODUTIVIDADE: 'SET_META_PRODUTIVIDADE',
    CLEAN_META_PRODUTIVIDADE: 'CLEAN_META_PRODUTIVIDADE',
    TIPOS_OPERACAO: 'TIPOS_OPERACAO',
    ALL_HIERARQUIAS: 'ALL_HIERARQUIAS',
    REGRAS_PREMIACAO: 'REGRAS_PREMIACAO',
    CLEAN_ALL_REGISTER: 'CLEAN_ALL_REGISTER',
    COPY: 'COPY',
    SET_ID_REGRA: 'SET_ID_REGRA',
    SET_REGRAS_ELEGIVEIS: 'SET_REGRAS_ELEGIVEIS',
    SET_REGRAS_VINCULADAS: 'SET_REGRAS_VINCULADAS',
    SET_ALL_REGRAS: 'SET_ALL_REGRAS'
}

export { STORE_ID, TYPES }