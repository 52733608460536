import { STORE_ID, TYPES } from "./types"

const INITIAL_STATE = {
    tab: {
        tab: '1',
        id: '32fca66d-8069-458c-88da-85fd0d3e4fdf'
    },
    tabs:[
        {
            tab: '1',
            id: '32fca66d-8069-458c-88da-85fd0d3e4fdf',
            quantidade: '0'
        },
        {
            tab: '2',
            id: 'a3b5e1b9-f863-4389-93ff-d11126e27928',
            quantidade: '0'
        }
    ],
    codConsistencias: [],
    numerosProposta: [],
    permitions:{
        criar: false
    },
    isFirstPage: false,
    getAllItems: {
        quantidade: '0',
        quantidadeTotal: '0',
        totalPaginas: '0'
    },
}

export default function inconsistenciaFormalizacao(state = INITIAL_STATE, action) {
    switch (action.storeId) {
        case STORE_ID:
            switch (action.type) {
                case TYPES.SET_TABS:
                    return { ...state, tabs: action.value }
                case TYPES.SET_TAB:
                    return { ...state, tab: action.value }
                case TYPES.SET_PERMITIONS:
                    return { ...state, permitions: action.value }
                case TYPES.SET_COD_CONSISTENCIAS:
                    return { ...state, codConsistencias: action.value }
                case TYPES.SET_NUMEROS_PROPOSTA:
                    return { ...state, numerosProposta: action.value }       
                case TYPES.GET_ALL_ITEMS:
                    return { ...state, getAllItems: action.value }                    
                case TYPES.SET_IS_FIRST_PAGE:
                    return { ...state, isFirstPage: action.value }                    
                case TYPES.CLEAN_TABS:
                return { ...state,
                    propostas: INITIAL_STATE.numerosProposta,
                     lojasBanco: INITIAL_STATE.codConsistencias}
            default:
                    return { ...state }
                }
                default:
                    return { ...state }
            }
        }