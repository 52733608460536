import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Card, Col, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { Select } from '../../../components/Inputs/Input';
import { COLUMNS } from '../constants/columns';
import * as BiIcons from "react-icons/bi"
import * as AiIcons from "react-icons/ai";
import * as antContasReceberActions from "../../../store/modules/antecipacaoContasReceber/actions"
import * as antContasReceberController from "../../../controllers/antecipacaoContasReceberController"
import Table from '../../../components/tables/PaginateTable/Table'

const Search = () => {

    const { items, instituicoesPesquisa, produtosPesquisa, lojasPesquisa, search, pageSize, quantidadeTotal, totalPaginas, pageSelected, permitions } = useSelector(state => state.antContasReceber);

    useEffect(() => {
        antContasReceberController.getLojasPesquisa()
        antContasReceberController.getProdutosPesquisa()
        antContasReceberController.getInstituicoesPesquisa()
    }, [])

    
    useEffect(()=>{
        antContasReceberController.get(search, 1, 10)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const [itemSelected, setItem] = useState(null);
    const [modalDelete, setModalDelete] = useState(false);

    function onChange(value, campo) {
        antContasReceberActions.setSearch({ ...search, [campo]: value })
    }

    async function handlePageClick(e) {
        await antContasReceberController.get(search, e, pageSize);
    }

    async function handleUpdate(id) {
        await antContasReceberController.getById(id);
        antContasReceberActions.setActiveTab("Register");
    }

    function seeRegister(id) {
        antContasReceberActions.setSeeRegister(true);
        handleUpdate(id);

    }
    function handleDelete(id) {
        setItem(id);
        toggleModalDelete();
    }

    function toggleModalDelete() {
        setModalDelete(!modalDelete);
    }

    function deleteAntecipacao() {
        antContasReceberController.remove(itemSelected)
    }

    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalDelete}
                toggle={toggleModalDelete}
                backdrop={false}
            >
                <ModalHeader toggle={toggleModalDelete} style={{ height: '10px', border: 'none' }} />
                <ModalBody>
                    <p style={{ fontSize: '18px', color: 'var(--color-preto)' }}>Tem certeza que deseja <strong style={{ fontSize: '17px' }}>remover</strong> esse item?</p>
                </ModalBody>
                <div className="m-3">
                    <Button onClick={() => toggleModalDelete()} color="danger" style={{ width: "100px", marginRight: "15px", }}> Cancelar </Button>
                    <Button onClick={() => { deleteAntecipacao(); toggleModalDelete() }} style={{ width: "100px", marginLeft: "15px", background: 'var(--color-verde)', border: 'none' }}> Confirmar </Button>
                </div>
            </Modal>

            <Col>
                <Card className="pt-3 pb-2 mb-5">
                    <Col>
                        <div className="pb-3">
                            <Label style={{ fontWeight: '400', fontSize: '18px' }}>Pesquisar</Label>
                            <Form>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Instituição Financeira</Label>
                                            <Select
                                                first="SELECIONE"
                                                value={{ value: search.bancoId }}
                                                onChange={(e) => { onChange(e.value, 'bancoId') }}
                                                select={{ value: "id", label: "nome" }}
                                                options={instituicoesPesquisa}
                                                isSearchable
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Produtos</Label>
                                            <Select
                                                first="SELECIONE"
                                                value={{ value: search.produtoId }}
                                                onChange={(e) => { onChange(e.value, 'produtoId') }}
                                                select={{ value: "id", label: "nome" }}
                                                options={produtosPesquisa}
                                                isSearchable
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Lojas</Label>
                                            <Select
                                                first="SELECIONE"
                                                value={{ value: search.lojaId }}
                                                onChange={(e) => { onChange(e.value, 'lojaId') }}
                                                select={{ value: "id", label: "nome" }}
                                                options={lojasPesquisa}
                                                isSearchable
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button className="mr-4"
                                            style={{
                                                width: "150px",
                                                background: "var(--color-branco)",
                                                borderColor: "var(--color-laranja)",
                                                color: "var(--color-laranja)",
                                                borderRadius: "7px"
                                            }}
                                            onClick={() => { antContasReceberActions.cleanAllSearch() }}>
                                            Limpar
                                        </Button>
                                        <Button
                                            className="purpleButton"
                                            style={{ width: "150px" }}
                                            onClick={() => {
                                                antContasReceberController.get(search, 1, 10)
                                            }}>
                                            Buscar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <Table
                            data={items}
                            columns={COLUMNS}
                            totalPaginas={totalPaginas}
                            totalRegistros={quantidadeTotal}
                            setPageSelected={() => { antContasReceberActions.setPageSelected() }}
                            pageSelected={pageSelected}
                            onPageChange={handlePageClick}
                            handleInitialPage={(e) => { antContasReceberController.get(search, 1, e) }}
                            actions={(id) => {
                                return (
                                    <>
                                        {
                                                        permitions.criar && (
                                                            <BiIcons.BiEdit
                                                                style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}
                                                                onClick={() => handleUpdate(id)}
                                                            />
                                                        )
                                                    }
                                                    <AiIcons.AiOutlineEye
                                                        style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA", marginRight: "7px" }}
                                                        onClick={() => seeRegister(id)}
                                                    />
                                                    {
                                                        permitions.deletar && (
                                                            <BiIcons.BiTrash
                                                                style={{ fontSize: "1.2rem", cursor: "pointer", color: "#C4C7CA" }}
                                                                onClick={() => handleDelete(id)}
                                                            />
                                                        )
                                                    }
                                    </>
                                )
                            }}
                        />
                    </Col>
                </Card>
            </Col>
        </>
    )
}

export default Search
