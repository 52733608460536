/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { Card, Col, Form, Input, Label, Row, Button } from "reactstrap";

import { IoIosArrowBack } from "react-icons/io";

import { useSelector } from "react-redux";
import * as actions from "../../../store/modules/layoutParametrizacao/actions";
import * as controller from "../../../controllers/layoutParametrizacaoController";

import { Mapeamento } from "../components/Mapeamento";
import { Select, Switch } from "../../../components/Inputs/Input";
import Breadcrumb from "../../../components/breadcrumbs";

import "../styles.css";

export function Visualize() {
  const {
    layoutBeingVisualized,
    layoutBeingVisualizedId,
    tiposArquivo,
    tiposComissao,
    instituicoesFinanceira,
    activeTab,
  } = useSelector((state) => state.layoutParametrizacao);

  async function handleTipoArquivoChange(tipoArquivoId) {
    actions.setLayoutBeingVisualized({
      ...layoutBeingVisualized,
      tipoArquivoId: tipoArquivoId,
    });

    if (tipoArquivoId !== "0") {
      const allLayoutsCamposEquivalentes =
        await controller.getAllLayoutsCamposEquivalentes(tipoArquivoId);
      const camposEquivalentes = await controller.getLayoutsCamposEquivalentes(
        tipoArquivoId
      );

      actions.setLayoutsCamposEquivalentes(camposEquivalentes ?? []);
      actions.setAllLayoutsCamposEquivalentes(allLayoutsCamposEquivalentes ?? []);
    }
  }

  function clearPageData() {
    actions.clearLayoutBeingVisualized();
    actions.clearMapeamento();
    actions.clearDetalhamento();
    actions.clearStatus();
  }

  function handleVoltar() {
    clearPageData();
    actions.setActiveTab("Search");
  }

  useEffect(() => {
    actions.clearMapeamento();
    actions.clearLayoutBeingVisualized();
    actions.clearDetalhamento();
    actions.clearStatus();
  }, []);

  useEffect(() => {
    (async () => {
      const layoutBeingVisualized = await controller.getLayoutById(
        layoutBeingVisualizedId
      );

      handleTipoArquivoChange(layoutBeingVisualized.tipoArquivoId);
      actions.setLayoutBeingVisualized(layoutBeingVisualized);
    })();
  }, [layoutBeingVisualizedId]);

  useEffect(() => {
    (async () => {
      const tiposArquivo = await controller.getTiposArquivo();
      const instituicoesFinanceiras =
        await controller.getInstituicoesFinanceira();
      const tiposComissao = await controller.getTiposComissao();

      actions.setTipoArquivos(tiposArquivo);
      actions.setInstituicoes(instituicoesFinanceiras);
      actions.setTiposComissao(tiposComissao);
    })();
  }, []);

  return (
    <>
      <Col>
        <Breadcrumb activeTab={activeTab} />
        <Card className="mb-2 mt-2">
          <Form>
            <Col className="pt-2 pb-2">
              <Button
                onClick={() => {
                  handleVoltar();
                }}
                style={{ width: "127px", color: "white" }}
                className="purpleButton"
              >
                <IoIosArrowBack
                  style={{
                    marginTop: "3px",
                    float: "left",
                  }}
                />
                Voltar
              </Button>
            </Col>
          </Form>
        </Card>
      </Col>
      <Col>
        <Card className="radius pb-5">
          <Col>
            <Col className="pt-2 mt-4 mb-4 ">
              <p style={{ fontSize: "18px", color: "var(--color-preto)" }}>
                Preencha os campos para cadastrar um novo Layout de
                parametrização
              </p>
            </Col>
            <Form>
              <Col>
                <Row>
                  <Col md={4}>
                    <Label
                      for="convenio"
                      style={{ fontWeight: "700", color: "var(--color-preto)" }}
                    >
                      Descrição do layout
                    </Label>
                    <Input
                      type="text"
                      placeholder="Digite aqui..."
                      value={layoutBeingVisualized.descricao.toUpperCase()}
                      disabled
                      style={{ height: "37px" }}
                    />
                  </Col>
                  <Col md={4}>
                    <Label
                      for="convenio"
                      style={{ fontWeight: "700", color: "var(--color-preto)" }}
                    >
                      Tipo do arquivo
                    </Label>
                    <Select
                      first="Selecione"
                      value={{ value: layoutBeingVisualized.tipoArquivoId }}
                      select={{ value: "id", label: "descricao" }}
                      options={tiposArquivo}
                      autoComplete="off"
                      disabled
                    />
                  </Col>
                  <Col md={4}>
                    <Label
                      for="convenio"
                      style={{ fontWeight: "700", color: "var(--color-preto)" }}
                    >
                      Instituição Financeira
                    </Label>
                    <Select
                      first="Selecione"
                      value={{ value: layoutBeingVisualized.bancoId }}
                      select={{ value: "id", label: "nome" }}
                      options={instituicoesFinanceira}
                      autoComplete="off"
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  {layoutBeingVisualized.tipoArquivoId.toUpperCase() ===
                    "4898DDC8-C0CB-49D8-BB72-EA946FB7963D" && (
                    <Col md={4}>
                      <Label
                        for="convenio"
                        style={{
                          fontWeight: "700",
                          color: "var(--color-preto)",
                        }}
                      >
                        Tipo Comissão
                      </Label>
                      <Select
                        first="Selecione"
                        value={{
                          value: layoutBeingVisualized.tipoComissaoBancoId,
                        }}
                        select={{ value: "id", label: "descricao" }}
                        options={tiposComissao}
                        autoComplete="off"
                        disabled
                      />
                    </Col>
                  )}
                  <Col md={4}>
                    <Label
                      for="convenio"
                      style={{ fontWeight: "700", color: "var(--color-preto)" }}
                    >
                      Número Aba
                    </Label>
                    <Input
                      placeholder="Digite aqui..."
                      value={layoutBeingVisualized.nomeAba}
                      disabled
                      style={{ height: "37px" }}
                    />
                  </Col>
                  {layoutBeingVisualized.tipoArquivoId.toUpperCase() ===
                    "4898DDC8-C0CB-49D8-BB72-EA946FB7963D" && (
                    <Col
                      md={4}
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginLeft: "auto",
                      }}
                    >
                      <>
                      <Label style={{
                          fontWeight: "700",
                          color: "var(--color-preto)",
                          whiteSpace: "nowrap",
                        }}>
                          Valida Produto
                        </Label>
                        <div style={{marginLeft: "-50px", marginTop: "25px"}}>
                          <Switch
                              className="switch"
                              checked={layoutBeingVisualized.validaProduto}
                              disabled
                          />
                        </div>
                      </>
                      <>
                        <Label style={{
                          fontWeight: "700",
                          color: "var(--color-preto)",
                          whiteSpace: "nowrap",
                        }}>
                          Valida CPF
                        </Label>
                        <div style={{marginLeft: "-50px", marginTop: "25px"}}>
                          <Switch
                              className="switch"
                              checked={layoutBeingVisualized.validaCpf}
                              disabled
                          />
                        </div>
                      </>
                      <>
                        <Label
                          style={{
                            fontWeight: "700",
                            color: "var(--color-preto)",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Individual
                        </Label>
                        <div style={{ marginLeft: "-50px", marginTop: "25px" }}>
                          <Switch
                            className="switch"
                            checked={layoutBeingVisualized.isIndividual}
                            disabled
                          />
                        </div>
                      </>
                      <>
                        <Label
                          style={{
                            fontWeight: "700",
                            color: "var(--color-preto)",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Quitação
                        </Label>
                        <div style={{ marginLeft: "-50px", marginTop: "25px" }}>
                          <Switch
                            className="switch"
                            checked={layoutBeingVisualized.isQuitacao}
                            disabled
                          />
                        </div>
                      </>
                      <>
                        <Label
                          style={{
                            fontWeight: "700",
                            color: "var(--color-preto)",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Negativo
                        </Label>
                        <div style={{ marginLeft: "-50px", marginTop: "25px" }}>
                          <Switch
                            className="switch"
                            checked={layoutBeingVisualized.isNegativo}
                            disabled
                          />
                        </div>
                      </>
                    </Col>
                  )}
                </Row>
                <Mapeamento />
              </Col>
            </Form>
          </Col>
        </Card>
      </Col>
    </>
  );
}
