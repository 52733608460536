import React, {useEffect, useState} from 'react';
import * as enderecoActions from "../../../../../../store/modules/personas/endereco_store/actions";
import * as enderecoController from "../../../../../../controllers/personasController/enderecoController";
import * as AiIcons from "react-icons/ai";
import {useSelector} from 'react-redux';
import { Modal, ModalHeader, ModalBody, Button} from 'reactstrap';
import * as BiIcons from "react-icons/bi";

const TableEndereco = () => {

    const {enderecos, seeRegister, tiposEnderecos, endereco} = useSelector(state => state.personasEndereco);

    useEffect(() => {
        enderecoController.getTiposEndereco();
    }, []);

    useEffect(() => {
        enderecoController.getEstados();
    }, []);

    function cleanDados(){
        enderecoActions.cleanEndereco();
        enderecoActions.setIsDeleting(false);
        enderecoActions.setIsEditando(false);
    }

    const [modalDelete, setModalDelete] = useState(false);

    function toggleModalDelete() {
        setModalDelete(!modalDelete);
    }

    function setaEndereco(row){
        const enderecoSetar = {
            id: row.id,
            tipoEnderecoId: row.tipoEnderecoId,
            principal: row.principal,
            cep: row.cep,
            estadoId: row.estado.id,
            cidade: row.cidade,
            bairro: row.bairro,
            logradouro:  row.logradouro,
            numero: row.numero,
            complemento: row.complemento,
        }
        enderecoActions.setEndereco(enderecoSetar);
    }

    function setaIdEndereco(row){
        const enderecoSetar = {
            id: row.id
        }
        enderecoActions.setEndereco(enderecoSetar);
    }
    async function handleRemoveItem(row){
        await enderecoActions.cleanEndereco();
        enderecoActions.setIsDeleting(true);
        enderecoActions.setIsEditando(false);
        toggleModalDelete();
        setaIdEndereco(row);
    }

    function handleEdit(row){
        enderecoActions.setIsEditando(true);
        enderecoActions.setIsDeleting(false);
        setaEndereco(row)
    }

    async function removeItem(id) {
        await enderecoController.removeEndereco(id)
        toggleModalDelete();
    }

    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalDelete}
                toggle={toggleModalDelete}
                backdrop={false}
            >
                <ModalHeader toggle={toggleModalDelete} style={{height: '10px', border: 'none'}}/>
                <ModalBody>
                    <p style={{fontSize: '18px', color: 'var(--color-preto)'}}>Tem certeza que deseja <strong
                        style={{fontSize: '17px'}}>remover</strong> esse endereço?</p>
                </ModalBody>
                <div className="m-3">
                    <Button onClick={() => {
                        toggleModalDelete();
                        cleanDados();
                    }}
                            color="danger"
                            style={{width: "100px", marginRight: "15px",}}>
                        Cancelar
                    </Button>
                    <Button onClick={() => removeItem(endereco.id)}
                            style={{
                                width: "100px",
                                marginLeft: "15px",
                                background: 'var(--color-verde)',
                                border: 'none'
                            }}>
                        Confirmar
                    </Button>
                </div>
            </Modal>
            <table style={{marginTop: '20px', width: '95%', marginLeft: '2%'}}>
                <thead>
                <tr>
                    <th>Tipo de Endereço</th>
                    <th>Endereço Principal</th>
                    <th>Cep</th>
                    <th>UF</th>
                    <th>Cidade</th>
                    <th>Bairro</th>
                    <th>Logradouro</th>
                    <th>Número</th>
                    <th>Complemento</th>
                    <th style={{textAlign: 'center'}}>Ações</th>
                </tr>
                </thead>
                <tbody>
                {enderecos.map((row, index) => {
                    return (
                        <>
                            <tr>
                                <td>{tiposEnderecos.map((e) => {
                                    return e.id === row.tipoEnderecoId ? e.descricao : '';
                                })}</td>
                                <td >{row.principal === true ? 'Sim' : 'Não'}</td>
                                <td>{row.cep}</td>
                                <td>{row.estado.nome}</td>
                                <td>{row.cidade}</td>
                                <td>{row.bairro}</td>
                                <td>{row.logradouro}</td>
                                <td>{row.numero}</td>
                                <td>{row.complemento === null ? '' : row.complemento}</td>
                                <td style={{textAlign: 'center'}}>
                                    <>
                                        {
                                            seeRegister &&
                                            <AiIcons.AiOutlineEye
                                                style={{
                                                    fontSize: "1.2rem",
                                                    cursor: "pointer",
                                                    color: "#C4C7CA"
                                                }}
                                                onClick={() => handleEdit(row)}
                                            />
                                        }
                                        {
                                            !seeRegister && (
                                                <>
                                                    <BiIcons.BiEdit
                                                        style={{
                                                            fontSize: "1.2rem",
                                                            cursor: "pointer",
                                                            color: "#C4C7CA",
                                                            marginRight: "1px"
                                                        }}
                                                        onClick={() => handleEdit(row)}
                                                    />
                                                    <BiIcons.BiTrash
                                                        style={{fontSize: '1.2rem', cursor: 'pointer', color: '#C4C7CA'}}
                                                        onClick={() => handleRemoveItem(row)}
                                                    />
                                                </>
                                            )
                                        }
                                    </>
                                </td>
                            </tr>
                        </>
                    )
                })}
                </tbody>
            </table>
        </>
    );
}

export default TableEndereco;
