const STORE_ID = "DASHBOARD_PREVISAO";

const TYPES = {
    SET_FILTROS: "SET_FILTROS",
    SET_BIG_NUMBERS: "SET_BIG_NUMBERS",
    SET_TABELA_BANCO: "SET_TABELA_BANCO",
    SET_TABELA_CANAL: "SET_TABELA_CANAL",
    SET_TABELA_CENTRO_CUSTO: "SET_TABELA_CENTRO_CUSTO",
    SET_TABELA_PRODUTO: "SET_TABELA_PRODUTO",
    SET_LOJAS: "SET_LOJAS",
    SET_GET_ALL_LOJAS: "SET_GET_ALL_LOJAS",
    SET_MODAL_LOJA: "SET_MODAL_LOJA",
    SET_CENTROS_CUSTO: "SET_CENTROS_CUSTO",
    SET_BANCOS: "SET_BANCOS",
    SET_TIPOS_COMISSAO: "SET_TIPOS_COMISSAO",
    SET_PRODUTOS: "SET_PRODUTOS",
    SET_CONVENIOS: "SET_CONVENIOS",
    SET_CANAIS: "SET_CANAIS",
    SET_GRUPOS: "SET_GRUPOS",
    CLEAN_SEARCH: "CLEAN_SEARCH",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    SET_LOADING_LOJAS: "SET_LOADING_LOJAS",
}

export { STORE_ID, TYPES }