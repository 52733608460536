import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as BsIcons from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import {
  Button,
  Card,
  Col,
  Input,
  Label,
  Row,
  Form,
  FormGroup,
} from "reactstrap";

import { Select, Switch } from "../../../components/Inputs/Input";
import { COLUMNS } from "../constants/columns";

import * as actions from "../../../store/modules/aceiteCp/actions";
import * as controller from "../../../controllers/aceiteCpController";

import ModalEditar from "../components/ModalEditar";
import { Checkbox } from "@material-ui/core";

import ModalPessoas from "../components/ModalPessoas";

const Aceite = () => {
  const dispatch = useDispatch();
  const {
    item,
    items,
    canais,
    campanhas,
    hierarquias,
    centrosCusto,
    empresasFuncionario,
    campanhaTipo,
    statusIntegracao,
    sistemas,
    permitions,
    allItems,
    statusFavorecido,
  } = useSelector((state) => state.aceiteCp);
  const columns = useMemo(() => COLUMNS, []);
  const data = items;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageSize } = state;
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  const handlePageClick = (e) => {
    const pageSelectedD = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelectedD);
    gotoPage(pageSelectedD);
    const { sortBy } = state;

    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];
      controller.get(pageSelectedD + 1, pageSize, item, id, desc);
    } else {
      controller.get(pageSelectedD + 1, pageSize, item);
    }
  };

  function handleInitialPage(pageSizeD) {
    setInitialPage(true);
    setSelectedPage(0);
    setSelectedItems([]); 
    gotoPage(0);
    controller.get(0 + 1, pageSizeD, item);
  }

  const [modalPessoa, setModalPessoa] = useState(false);
  const togglePessoa = () => setModalPessoa(!modalPessoa);

  const [isModalAceiteOpen, setIsModalAceiteOpen] = useState(false);

  const toggleAceiteModal = () => setIsModalAceiteOpen(!isModalAceiteOpen);

  useEffect(() => {
    if (item.canalId !== "0") {
      controller.getCampanhas(item.canalId);
    }
  }, [item.canalId]);
  useEffect(() => {
    if (item.canalId === "0") {
      actions.item("hierarquiaId", "0");
      actions.setHierarquias([]);
    } else {
      controller.getHierarquia(
        item.canalId,
        item.campanhaId !== "0" ? item.campanhaId : null
      );
    }
  }, [item.canalId, item.campanhaId]);

  useEffect(() => {
    if (item.campanhaId !== "0" && item.canalId !== "0") {
      controller.getCentrosCusto(item.canalId, item.campanhaId);
    }
  }, [item.canalId, item.campanhaId]);

  function onChange(field, value) {
    actions.item(field, value);
  }

  useEffect(() => {
    controller.getCanais();
    controller.getCentrosCusto();
    controller.getRazaoSocialEmpresa();
    controller.getStatus();
    controller.getSistemas();
  }, []);

  const [selectedItems, setSelectedItems] = useState([]);

  const toggleItemSelection = (id) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems.filter((itemId) => itemId !== id);
      } else {
        return [...prevSelectedItems, id];
      }
    });
  };

  const isItemSelected = (id) => selectedItems.includes(id);

  const [isAllSelected, setIsAllSelected] = useState(false);

  return (
    <>
      <Col className="ml-3">
        <Card className="py-5 pr-5 mb-5">
          <Form
            style={{
              margin: "30px 15px",
              border: "1px solid #ccc",
              padding: "15px",
              borderRadius: "7px",
              marginBottom: "10px",
            }}
          >
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>Canal:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.canalId }}
                    select={{ value: "id", label: "nome" }}
                    options={canais}
                    onChange={(e) => onChange("canalId", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Campanha:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.campanhaId }}
                    select={{ value: "id", label: "descricao" }}
                    options={campanhas}
                    onChange={(e) => onChange("campanhaId", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Hierarquia:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.hierarquiaId }}
                    select={{ value: "hierarquiaId", label: "descricao" }}
                    options={hierarquias}
                    onChange={(e) => onChange("hierarquiaId", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label style={{ fontWeight: "700" }}>Persona:</Label>
                  <Input
                    type="text"
                    placeholder="Busque um consultor..."
                    value={item.pessoa}
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={1}>
                <GrFormClose
                  style={{
                    color: "#C4C7CA",
                    marginTop: "30px",
                    fontSize: "20px",
                    cursor: "pointer",
                    marginLeft: "-10px",
                  }}
                  onClick={() => {
                    onChange("pessoaId", "0");
                    onChange("pessoa", "");
                  }}
                />
                <AiOutlineSearch
                  style={{
                    color: "var(--color-verde)",
                    marginTop: "30px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => togglePessoa()}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>Centro de custo:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.centroCustoId }}
                    select={{ value: "id", label: "descricao" }}
                    options={centrosCusto}
                    onChange={(e) => onChange("centroCustoId", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Razão social empresa:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.empresaFuncionarioId }}
                    select={{ value: "id", label: "razaoSocialEmpresa" }}
                    options={empresasFuncionario}
                    onChange={(e) => onChange("empresaFuncionarioId", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Tipo Campanha:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.campanhaTipo }}
                    select={{ value: "id", label: "descricao" }}
                    options={campanhaTipo}
                    onChange={(e) => onChange("campanhaTipo", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Status:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.statusId }}
                    select={{ value: "id", label: "descricao" }}
                    options={statusIntegracao}
                    onChange={(e) => onChange("statusId", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Sistema:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.sistemaId }}
                    select={{ value: "id", label: "nome" }}
                    options={sistemas}
                    onChange={(e) => onChange("sistemaId", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Data Pagamento:</Label>
                  <Input
                    type="date"
                    value={item.dataPagamento}
                    onChange={(e) => onChange("dataPagamento", e.value)}
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Data Integração:</Label>
                  <Input
                    type="date"
                    value={item.dataIntegracao}
                    onChange={(e) => onChange("dataIntegracao", e.value)}
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Status do Favorecido:</Label>
                  <Select
                    first="Selecione"
                    value={{ value: item.statusFavorecido }}
                    select={{ value: "id", label: "descricao" }}
                    options={statusFavorecido}
                    onChange={(e) => onChange("statusFavorecido", e.value)}
                    isSearchable={true}
                    autoComplete="off"
                    disabled={item.canalId === "0" || item.campanhaId === "0"}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="purpleButton"
                  style={{ width: "150px", marginRight: "10px" }}
                  onClick={() => {
                    controller.get(1, pageSize, item);
                    gotoPage(0);
                    setInitialPage(true);
                    setSelectedPage(0);
                  }}
                >
                  Buscar
                </Button>
                <Button
                  style={{
                    width: "150px",
                    backgroundColor: "var(--color-branco)",
                    borderColor: "var(--color-laranja)",
                    color: "var(--color-laranja)",
                  }}
                  onClick={() => {
                    actions.cleanRegister();
                  }}
                >
                  Limpar
                </Button>
              </Col>
            </Row>
          </Form>
          <div
            style={{ overflowX: "auto", marginLeft: "15px", marginTop: "30px" }}
          >
            {items.length === 0 ? (
              <div className="text-center">
                <p>Não existem dados para essa pesquisa</p>
              </div>
            ) : (
          <div
            style={{ overflowX: "auto", marginLeft: "15px", marginTop: "30px" }}
          >
            <table
              {...getTableProps()}
              style={{ width: "100%", borderCollapse: "collapse" }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th
                      style={{
                        width: "10%",
                        padding: "7px",
                        textAlign: "center",
                      }}
                    >
                      <Checkbox
                        checked={page.every((row) =>
                          selectedItems.includes(row.original.id)
                        )}
                        style={{ color: "green" }}
                        onClick={() => {
                          if (page.length > 0) {
                            const currentPageIds = page.map(
                              (row) => row.original.id
                            );
                            setSelectedItems((prevSelectedItems) => {
                              const newSelectedItems = prevSelectedItems.filter(
                                (itemId) => !currentPageIds.includes(itemId)
                              );
                              return newSelectedItems.length !==
                                prevSelectedItems.length
                                ? newSelectedItems
                                : [...prevSelectedItems, ...currentPageIds];
                            });
                          }
                        }}
                      />
                    </th>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{
                          minWidth: "250px",
                          padding: "1px",
                          textAlign: "left",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ marginRight: "1px" }}>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <BsIcons.BsArrowDown />
                              ) : (
                                <BsIcons.BsArrowUp />
                              )
                            ) : (
                              <BsIcons.BsArrowDownUp />
                            )}
                          </span>
                          <span>{column.render("Header")}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} style={{ verticalAlign: "top" }}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      style={{ borderBottom: "1px solid #ddd" }}
                    >
                      <td style={{ textAlign: "center", padding: "7px" }}>
                        <Checkbox
                          checked={isItemSelected(row.original.id)}
                          style={{ color: "green" }}
                          size={"small"}
                          onClick={() => toggleItemSelection(row.original.id)}
                        />
                      </td>
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          style={{ padding: "15px", textAlign: "left" }}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          
            </div>
          )}
          </div>

          <Row className="pt-4">
            <Col>
              <span style={{ color: "var(--color-cinza-medio)" }}>
                Mostrando{" "}
                {allItems.quantidade !== undefined ? allItems.quantidade : "0"}{" "}
                de{" "}
                {allItems.quantidadeTotal !== undefined
                  ? allItems.quantidadeTotal
                  : "0"}
              </span>
            </Col>
            <Col md={8}>
              <Row className="float-right">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  initialPage={0}
                  pageCount={allItems.totalPaginas}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(e) => handlePageClick(e)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={initialPage ? 0 : selectedPage}
                />
                <Input
                  type="select"
                  className="pageSize"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    handleInitialPage(Number(e.target.value));
                  }}
                >
                  {[10, 25, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Input>
              </Row>
            </Col>
          </Row>
          <Col>
            {items.length > 0 && (
              <Button
                onClick={() => toggleAceiteModal()}
                style={{ width: "232px", float: "right", marginLeft: "auto" }}
                className="purpleButton"
              >
                Aceitar itens selecionados
              </Button>
            )}
          </Col>
        </Card>
      </Col>
      {modalPessoa && (
        <ModalPessoas
          isOpen={modalPessoa}
          toggle={togglePessoa}
          isSearch={false}
        />
      )}
      {isModalAceiteOpen && (
        <ModalEditar
          isOpen={isModalAceiteOpen}
          toggle={toggleAceiteModal}
          pagination={selectedPage + 1}
        />
      )}
    </>
  );
};

export default Aceite;
