import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
    data = { ...data, storeId: STORE_ID }
    store.dispatch(data);
}
export function setTab(value) {
    dispatch({
        type: TYPES.SET_TAB,
        value
    })
}

export function setPermitions(value) {
    dispatch({
        type: TYPES.SET_PERMITIONS,
        value
    })
}

export function setIsFirstPage(value) {
    dispatch({
        type: TYPES.SET_IS_FIRST_PAGE,
        value
    })
}


export function getAllItems(value) {
    dispatch({
        type: TYPES.GET_ALL_ITEMS,
        value
    });
}

export function setTabs(value) {
    dispatch({
        type: TYPES.SET_TABS,
        value
    })
}

export function setNumerosPropostas(value) {
    dispatch({
        type: TYPES.SET_NUMEROS_PROPOSTA,
        value
    })
}

export function setCodConsistencias(value) {
    dispatch({
        type: TYPES.SET_COD_CONSISTENCIAS,
        value
    })
}

export function cleanTabs() {
    dispatch({
        type: TYPES.CLEAN_TABS
    })
}