const STORE_ID = "INCONSISTENCIA_FORMALIZACAO";

const TYPES = {
    SET_PERMITIONS: "SET_PERMITIONS",
    SET_TAB: "SET_TAB",
    SET_TABS: "SET_TABS",
    SET_LOJAS_BANCO: "SET_LOJAS_BANCO",
    SET_COD_CONSISTENCIAS: "SET_COD_CONSISTENCIAS",
    SET_NUMEROS_PROPOSTA: "SET_NUMEROS_PROPOSTA",
    CLEAN_TABS: "CLEAN_TABS",
    GET_ALL_ITEMS: "GET_ALL_ITEMS",
    SET_IS_FIRST_PAGE: "SET_IS_FIRST_PAGE",
}

export { STORE_ID, TYPES }