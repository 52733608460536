/* eslint-disable default-case */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { Search } from "./tabs/search";
import { Register } from "./tabs/register";
import { Edit } from "./tabs/edit";
import { Visualize } from "./tabs/visualize";

import * as actions from "../../store/modules/perfilAcesso/actions";

import "../../style.css";

function renderActiveTab(activeTab) {
  switch (activeTab) {
    case "Search":
      return <Search />;
    case "Register":
      return <Register />;
    case "Edit":
      return <Edit />;
    case "Visualize":
      return <Visualize />;
  }
}

export default function PerfilAcesso() {
  const { activeTab } = useSelector((state) => state.perfilAcesso);

  useEffect(() => {
    actions.setActiveTab("Search");
  }, []);

  return <>{renderActiveTab(activeTab)}</>;
}
