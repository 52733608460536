import React, { useEffect, useMemo, useState } from 'react'
import { usePagination, useTable } from 'react-table';
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { Modal, ModalBody, Col, Button, Input, Label, Row, Navbar, FormGroup } from "reactstrap";

import { InputCpfCnpj } from '../../../../components/Inputs/Input'
import * as actions from '../../../../store/modules/dashboard/esteiraProducao/actions';
import * as controller from '../../../../controllers/dashboardController/esteiraProducaoController';
import { maskCNPJ } from '../../../../util/masks';
import LoadingIcon from '../../../../components/Loading/LoadingIcon'

export default function ModalLoja({ modalPessoa, toggle, canalId }) {
    const { modalLojas, lojas, getAllLojas, loadingLojas } = useSelector(state => state.dashboardEsteiraProducao);

    const columns = useMemo(() => [{
        Header: "CNPJ"
    }, {
        Header: "NOME",
    },], []);
    const data = lojas

    const {
        page,
        gotoPage,
        state,
        setPageSize,
    } = useTable({
        columns,
        data
    },
        usePagination);

    const { pageSize } = state;
    const [rows, setRows] = useState(0);
    const [initialPage, setInitialPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    useEffect(() => {
        setRows(page.length);
    }, [page])

    const handlePageClick = (e) => {
        const pageSelected = e.selected;
        setInitialPage(false);
        setSelectedPage(pageSelected);
        gotoPage(pageSelected);

        controller.getLojas(modalLojas, pageSelected + 1, pageSize, canalId)
    }

    function handleInitialPage() {
        gotoPage(0);
        setInitialPage(true);
        setSelectedPage(0);
    }

    function onChangeItem(value, campo) {
        actions.setFiltros(campo, value)
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            controller.getLojas(modalLojas, 1, pageSize, canalId);
        }
    }
    return (
        <>
            <Modal
                className="text-center"
                isOpen={modalPessoa}
                toggle={toggle}
                backdrop={true}
                size="lg"
            >
                <Navbar expand="md" style={{ width: "100%", height: "40px", background: 'var(--color-gradiente-verde)', marginBottom: '20px' }}>
                    <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2" >Pesquisa de Centro de Custo</h5>
                </Navbar>
                <ModalBody >
                    <Row>
                        {/* <Col md={4}>
                            <Label className="float-left">CNPJ</Label>
                            <InputCpfCnpj
                                type="text"
                                placeholder="Digite aqui..."
                                tipo="cnpj"
                                value={modalLojas.cnpj}
                                onChange={e => actions.setModalLojas('cnpj', e.target.value)}
                                onKeyPress={e => handleKeyPress(e)}
                                maxLength="18"
                            />
                        </Col> */}
                        <Col>
                            <FormGroup>
                                <Label className="float-left">Nome</Label>
                                <Input
                                    type="text"
                                    placeholder="Digite aqui..."
                                    value={modalLojas.nome}
                                    onChange={e => actions.setModalLojas('nome', e.target.value)}
                                    onKeyPress={e => handleKeyPress(e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <fieldset className={lojas.length > 0 ? "fieldset-socio" : "fieldset-socio-no-results"}>
                        <legend style={{ textAlign: "left", fontSize: "20px" }}>Resultados</legend>
                        {loadingLojas &&
                            <LoadingIcon />
                        }
                        <table>
                            <tr>
                                {/* <th style={{ width: "150px" }}>CNPJ</th> */}
                                <th>Nome</th>
                            </tr>
                            {lojas && lojas.map(row => {
                                return (
                                    <tr onDoubleClick={() => { onChangeItem(row.descricao, 'loja'); onChangeItem(row.id, 'lojaId'); toggle() }}>
                                        <td index={row.id}>{row.descricao}</td>
                                    </tr>
                                )
                            })}

                        </table>
                        <br />
                    </fieldset>
                    {lojas && <Row className="pt-4">
                        <Col md={3}>
                            <span style={{ color: "var(--color-cinza-medio)" }}>Mostrando {rows} de {getAllLojas.quantidadeTotal}</span>
                        </Col>
                        <Col md={8} className="float-right">
                            <Row >
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    initialPage={0}
                                    pageCount={getAllLojas.totalPaginas}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={initialPage ? 0 : selectedPage}
                                />
                                <Input
                                    type="select"
                                    className="pageSize"
                                    value={pageSize}
                                    onChange={e => { setPageSize(Number(e.target.value)); handleInitialPage() }}>
                                    {
                                        [10, 25, 100].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize}
                                            </option>
                                        ))
                                    }
                                </Input>
                            </Row>
                        </Col>
                    </Row>}
                </ModalBody>
                <div className="m-3">
                    <Button
                        onClick={() => { actions.setLojas([]); actions.setModalLojas('cnpj', ''); actions.setModalLojas('nome', '') }}
                        className="mr-4"
                        style={{
                            width: "150px",
                            background: "var(--color-branco)",
                            borderColor: "var(--color-laranja)",
                            color: "var(--color-laranja)",
                            borderRadius: "7px"
                        }}
                    >
                        Limpar
                    </Button>
                    <Button
                        onClick={() => controller.getLojas(modalLojas, 1, pageSize, canalId)}
                        color="danger"
                        className="purpleButton"
                        style={{ width: "150px" }}
                    >
                        Pesquisar
                    </Button>
                </div>
            </Modal>
        </>
    )
}