import React, {useEffect} from 'react';
import {Label, Card, Row, Col, Input} from 'reactstrap';
import * as AiIcons from "react-icons/ai";
import {useSelector} from 'react-redux';
import * as enderecoActions from "../../../../../store/modules/personas/endereco_store/actions";
import TableEndereco from "./components/tableEndereco";
import {FormGroup} from '@material-ui/core';
import {Select, Switch} from "../../../../../components/Inputs/Input";
import {maskCEP, maskNumber} from '../../../../../util/masks';
import * as enderecoController from "../../../../../controllers/personasController/enderecoController";

const Endereco = () => {
    const {
        endereco,
        enderecos,
        seeRegister,
        tiposEnderecos,
        estados,
        isDeleting,
        isEditando
    } = useSelector(state => state.personasEndereco);
    const {informacao} = useSelector(state => state.personasInformacoes);
    let dateBR;

    useEffect(() => {
        enderecoController.getTiposEndereco()
        enderecoController.getEstados()
    }, []);

    function onChangeItem(value, field) {
        enderecoActions.setEndereco({...endereco, [field]: value});
    }

    async function validateCep() {
        await enderecoController.getEnderecoByCEP(endereco, estados);
    }

    async function handleKeyPress(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            await enderecoController.getEnderecoByCEP(endereco, estados);
        }
    }

    async function salvarEndereco() {
        await enderecoController.salveEndereco(informacao.id, endereco)
    }

    function handleLimpaCampos() {
        enderecoActions.setIsEditando(false);
        enderecoActions.setIsDeleting(false);
        enderecoActions.cleanEndereco();
    }

    return (
        <>
            <Card className="p-3 m-4" style={{backgroundColor: "var(--color-quase-branco)"}}>
                <div className="mt-4"></div>
                <Col>
                    <Row>
                        <FormGroup style={{width: "200px"}}>
                            <Label style={{fontWeight: '700', fontSize: '13px', color: '#4F585A'}}>Tipo de
                                Endereço</Label>
                            <Select
                                first="Selecione"
                                select={{value: "id", label: "descricao"}}
                                value={{value: endereco.tipoEnderecoId}}
                                options={tiposEnderecos}
                                onChange={e => onChangeItem(e.value, "tipoEnderecoId")}
                                isSearchable={false}
                                disabled={seeRegister}
                            />
                        </FormGroup>
                        <FormGroup style={{width: "200px"}}>
                            <Switch
                                componentTop="Endereço principal"
                                checked={endereco.principal}
                                onChange={e => onChangeItem(!endereco.principal, "principal")}
                                className="switch"
                                disabled={seeRegister}
                            />
                        </FormGroup>
                    </Row>
                </Col>
                <br/>
                <Col>
                    <Row>
                        <FormGroup style={{width: "200px"}}>
                            <Label for="cep" style={{fontWeight: '700', fontSize: '13px', color: '#4F585A'}}>CEP</Label>
                            <Input
                                name="cep"
                                id="cep"
                                placeholder="Digite aqui..."
                                value={endereco.cep}
                                onKeyPress={e => handleKeyPress(e)}
                                onBlur={() => validateCep()}
                                onChange={e => onChangeItem(maskCEP(e.target.value), "cep")}
                                disabled={seeRegister}
                                maxLength="9"
                                className={endereco.cepInvalid ? 'is-invalid' : ''}
                            >
                            </Input>
                            <p style={{color: "var(--color-cinza-medio)", fontStyle: "italic"}}>*Tecle 'enter' para
                                buscar cep</p>
                        </FormGroup>
                        <FormGroup style={{width: "80px", marginLeft: "25px"}}>
                            <Label for="estado"
                                   style={{fontWeight: '700', fontSize: '13px', color: '#4F585A'}}>UF</Label>
                            <Select
                                first="UF"
                                onChange={e => onChangeItem(e.value, "estadoId")}
                                options={estados}
                                select={{value: "id", label: "sigla"}}
                                value={{value: endereco.estadoId}}
                                isSearchable={true}
                                disabled={seeRegister}
                                className={endereco.estadoIdInvalid ? 'is-invalid' : ''}

                            />
                        </FormGroup>
                        <FormGroup style={{width: "200px", marginLeft: "25px"}}>
                            <Label for="cidadeEndereco"
                                   style={{fontWeight: '700', fontSize: '13px', color: '#4F585A'}}>Cidade</Label>
                            <Input
                                name="cidade"
                                id="cidadeEndereco"
                                placeholder="Digite aqui..."
                                value={endereco.cidade}
                                onChange={e => onChangeItem(e.target.value, "cidade")}
                                disabled={seeRegister}
                                maxLength="150"
                                className={endereco.cidadeInvalid ? 'is-invalid' : ''}

                            />
                        </FormGroup>
                        <FormGroup style={{width: "315px", marginLeft: "25px"}}>
                            <Label for="bairro"
                                   style={{fontWeight: '700', fontSize: '13px', color: '#4F585A'}}>Bairro</Label>
                            <Input
                                name="bairro"
                                id="bairro"
                                placeholder="Digite aqui..."
                                value={endereco.bairro}
                                onChange={e => onChangeItem(e.target.value, "bairro")}
                                disabled={seeRegister}
                                maxLength="150"
                                className={endereco.bairroInvalid ? 'is-invalid' : ''}

                            />
                        </FormGroup>
                    </Row>
                </Col>
                <Col>
                    <Row style={{marginTop: "-15px"}}>
                        <FormGroup style={{width: "400px"}}>
                            <Label for="logradouro"
                                   style={{fontWeight: '700', fontSize: '13px', color: '#4F585A'}}>Logradouro</Label>
                            <Input
                                name="logradouro"
                                id="logradouro"
                                placeholder="Digite aqui..."
                                value={endereco.logradouro}
                                onChange={e => onChangeItem(e.target.value, "logradouro")}
                                disabled={seeRegister}
                                maxLength="150"
                                className={endereco.logradouroInvalid ? 'is-invalid' : ''}

                            >
                            </Input>
                        </FormGroup>
                        <FormGroup style={{width: "150px", marginLeft: "25px"}}>
                            <Label for="numero"
                                   style={{fontWeight: '700', fontSize: '13px', color: '#4F585A'}}>Número</Label>
                            <Input
                                name="numero"
                                id="numero"
                                placeholder="Digite aqui..."
                                value={endereco.numero}
                                onChange={e => onChangeItem(maskNumber(e.target.value), "numero")}
                                disabled={seeRegister}
                                maxLength="20"
                                className={endereco.numeroInvalid ? 'is-invalid' : ''}

                            >
                            </Input>
                        </FormGroup>
                        <FormGroup style={{width: "270px", marginLeft: "25px"}}>
                            <Label for="complemento"
                                   style={{fontWeight: '700', fontSize: '13px', color: '#4F585A'}}>Complemento</Label>
                            <Input
                                name="complemento"
                                id="complemento"
                                placeholder="Digite aqui..."
                                value={endereco.complemento}
                                onChange={e => onChangeItem(e.target.value, "complemento")}
                                maxLength="150"
                                disabled={seeRegister}
                            >
                            </Input>
                        </FormGroup>
                    </Row>
                    <p style={{
                        color: "var(--color-cinza-medio)",
                        fontStyle: "italic"
                    }}>{dateBR !== undefined && `*Data da última alteração ${dateBR}`}</p>
                    {
                        !seeRegister && !isEditando && !isDeleting ? (
                            <Row>
                                <Col md={3}>
                                    <Label
                                        style={{marginRight: "7px", cursor: "pointer"}}
                                        onClick={() => salvarEndereco()}
                                    >
                                        <AiIcons.AiOutlinePlusCircle
                                            style={{
                                                fontSize: "1.5rem",
                                                marginRight: "7px",
                                                color: "green",
                                                cursor: "pointer"
                                            }}
                                        /> Adicionar Endereço</Label>
                                </Col>
                            </Row>
                        ) : null
                    }
                    {
                        (isEditando && !seeRegister) && (
                            <Row>
                                {
                                    isEditando && (
                                        <Col md={3}>
                                            <Label
                                                style={{cursor: "pointer"}}
                                                onClick={() => salvarEndereco()}
                                            >
                                                <AiIcons.AiOutlinePlusCircle
                                                    style={{
                                                        fontSize: "1.5rem",
                                                        marginRight: "7px",
                                                        color: "green",
                                                        cursor: "pointer"
                                                    }}
                                                />
                                                Atualizar Endereço
                                            </Label>
                                        </Col>
                                    )
                                }
                                <Col md={3}>
                                    <Label
                                        style={{cursor: "pointer"}}
                                        onClick={() => handleLimpaCampos()}
                                    >
                                        <AiIcons.AiFillEdit
                                            style={{
                                                fontSize: "1.5rem",
                                                marginRight: "7px",
                                                color: "green",
                                                cursor: "pointer"
                                            }}
                                        />
                                        Limpar Campos
                                    </Label>
                                </Col>
                            </Row>
                        )
                    }
                </Col>
            </Card>
            {
                enderecos.length > 0 &&
                <TableEndereco/>
            }
        </>
    );
}

export default Endereco;
